import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import FormUser from './FormUser';
import FormQualityProgram from './FormQualityProgram';
import TruckItem from './TruckItem';
import FormTransport from './FormTransport';
import { userActions } from '../../../../actions';
import UserItem from './UserItem';
import Card from '../../../../components/Card';
import { FormattedMessage } from 'react-intl';
import {
  getCompany,
  getDocCompany,
  uploadFileQualityProgram,
  editCompanyNewVersion,
} from '../../../../services/company.service';
import extractData from '../../../../services/data.processing';
import messages from '../../../../components/Admin/messages';
import { getAllTruck, deleteTruck } from '../../../../services/company.service';
import ListQuality from './ListQuality';
import {
  qualitie,
  specificRoles,
  countries,
  states,
} from '../../../../constants';
import './form.css';
import cookie from '../../../CookiePolicy/mock';

const optionsTypesUse = [
  {
    value: 'RM1 - Mastkaelber bis 8 Mo, ggf. mitteilungspfl.',
    label: 'RM1 - Mastkaelber bis 8 Mo, ggf. mitteilungspfl.',
  },
  {
    value: 'RM2 - Mast ab 8 Mo, ggf. mitteilungspfl.',
    label: 'RM2 - Mast ab 8 Mo, ggf. mitteilungspfl.',
  },
  {
    value: 'RN3 - sonstige, nicht mitteilungspflichtig.',
    label: 'RN3 - sonstige, nicht mitteilungspflichtig.',
  },
];

class TabUsers extends React.Component {
  constructor(props) {
    super(props);
    this.countries = countries;
    this.states = states;
    this.qualitesProgram = qualitie;
    this.state = {
      show: false,
      showTruck: false,
      confirm: false,
      confirmTruck: false,
      users: [],
      tabPhotos: [],
      selectedUser: {},
      selectedTruck: {},
      page: 0,
      limit: 10,
      data: this.props.users.users,
      allTrucks: [],
      qualite_program: [],
      selectedOptionTypeUser: [],
      business_type: 0,
      company: [],
      showProgramQualityEdit: false,
      qualityProgram: '',
    };
  }

  componentDidMount() {
    this.fetchTrucks();
    this.fetchUsers();
    this.getCurrentCompany();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.users.users !== this.props.users.users) {
      this.setState({
        data: nextProps.users.users,
      });
      this.renderListQualities();
      this.getCurrentCompany();
    }
  }

  fetchUsers = async () => {
    const { getAllUsers } = this.props;
    getAllUsers(this.state.page, this.state.limit);
  };

  async getCurrentCompany() {
    let token = this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const user = await extractData(value);
      if (typeof user !== 'undefined') {
        this.setState({
          role: this.props.users.user.role,
          business_type:
            typeof user !== 'undefined' ? parseInt(user.business_type) : '',
          company: user,
          company_owner: user.owner,
          company_adress: user.address,
          agriculturist_extra: user.agriculturist_extra,
          qualite_program: user.program_quality,
          selectedOptionTypeUser: optionsTypesUse.filter(
            (e) => user.usage.indexOf(e.label) > -1,
          ),
        });
        const docs = user.program_quality.map(
          async (i) => await getDocCompany(id, token, i.certificate_id),
        );
        this.setState({ tabPhotos: [] });
        for (let index = 0; index < docs.length; index++) {
          const element = await docs[index];
          await this.setState((prevState) => ({
            tabPhotos: [...prevState.tabPhotos, element],
          }));
        }
      }
    }
  }
  fetchTrucks = async () => {
    // const { getAllUsers } = this.props;
    // getAllUsers(this.state.page, this.state.limit);
    let token = this.props.users.accessToken;
    // let company_id = this.props.users.user.session.company_id;
    const value = await getAllTruck(token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const truck = await extractData(value);
      this.setState({ allTrucks: truck });
    }
  };

  listUsers = () =>
    typeof this.state.data !== 'undefined' &&
    this.state.data.map((user, i) => (
      <UserItem
        key={i}
        edit={() => this.editUser(user)}
        del={() => this.confirmDeletUser(user)}
        data={user}
        language={this.props.language}
      />
    ));

  listTrucks = () =>
    typeof this.state.allTrucks !== 'undefined' &&
    this.state.allTrucks.map((truck, i) => (
      <TruckItem
        key={i}
        edit={() => this.editTruck(truck)}
        del={() => this.confirmDeletTruck(truck)}
        allTrucks={truck}
      />
    ));

  editUser = (user) => {
    this.setState({ selectedUser: user, show: true });
  };

  editProgramQuality = (qp) => {
    this.setState({ qualityProgram: qp, showProgramQualityEdit: true });
  };

  confirmDeletUser = (user) => {
    this.setState({ selectedUser: user, confirm: true });
  };

  deleteUser = () => {
    this.props.deleteUser(this.state.selectedUser._id);
    this.setState({ selectedUser: {}, confirm: false });
  };

  editTruck = (truck) => {
    this.setState({ selectedTruck: truck, showTruck: true });
  };

  confirmDeletTruck = (truck) => {
    this.setState({ selectedTruck: truck, confirmTruck: true });
  };

  deleteTruck = async () => {
    await deleteTruck(this.state.selectedTruck._id);
    this.setState({ selectedTruck: {}, confirmTruck: false });
    this.fetchTrucks();
  };

  findIndexQuality = (val) => {
    let find = false;
    let index = 0;
    this.state.qualite_program.forEach(async (element, i) => {
      if (element.name === val) {
        find = true;
        index = i;
      }
    });
    return { find: find, index: index };
  };

  renderListQualities = () => {
    return (
      <div className="quality-program-content">
        {[1, 2, 3, 4].indexOf(this.state.business_type) !== -1 && (
          <ListQuality
            edit={this.editProgramQuality}
            qualities={this.state.qualite_program}
            photos={this.state.tabPhotos}
            deleteFile={this.deleteFile}
          />
        )}
      </div>
    );
  };

  updateQualityProgram = async (qps, companyId, accessToken) => {
    this.setState({ qualite_program: qps.program_quality });
    await editCompanyNewVersion(qps, companyId, accessToken);
    const value = await getCompany(companyId, accessToken);
    const user = await extractData(value);
    if (typeof user !== 'undefined') {
      const docs = user.program_quality.map(
        async (i) =>
          await getDocCompany(companyId, accessToken, i.certificate_id),
      );
      this.setState({ tabPhotos: [] });
      for (let index = 0; index < docs.length; index++) {
        const element = await docs[index];
        await this.setState((prevState) => ({
          tabPhotos: [...prevState.tabPhotos, element],
        }));
      }
    }
  };

  deleteFile = (index) => {
    let tab = this.state.qualite_program;
    tab.splice(index, 1);
    let accessToken = this.props.users.accessToken;
    let id_company = this.props.users.user.session.company_id;
    this.setState({ qualite_program: tab });
    let company = {
      program_quality: this.state.qualite_program,
    };
    editCompanyNewVersion(company, id_company, accessToken);
  };

  render() {
    const { users } = this.props;
    let { role } = this.props.users.user;
    return (
      <Container fluid>
        {specificRoles.ROLE_FOR_ROLE_COMPANY_OWNER.indexOf(role) > -1 && (
          <Row className="setting-users">
            <Col md={12}>
              <Card
                title={
                  <Col md={6} className="card-title">
                    <FormattedMessage {...messages.UsersSideMessage} />
                  </Col>
                }
                content={
                  <Row className="text-center">
                    <Col md={12}>
                      <div className="user-cards-helper">
                        {this.listUsers()}
                        <Col md={2} sm={6}>
                          <div
                            className="box wow fadeInUp"
                            data-wow-duration=".5s"
                            onClick={() =>
                              this.setState({ selectedUser: {}, show: true })
                            }
                          >
                            <span className=" add-icon" />
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Modal
                      className="form-user-modal"
                      show={this.state.show}
                      onHide={() => this.setState({ show: false })}
                      style={{ opacity: 1 }}
                      id="modal-user-add"
                    >
                      <Modal.Header closeButton>
                        <div className="Modal-add-user-title">
                          <h5 className="card-title ">
                            <FormattedMessage {...messages.AddUser} />
                          </h5>
                        </div>
                        <Modal.Title />
                      </Modal.Header>
                      <Modal.Body>
                        <FormUser
                          handleModal={() => this.setState({ show: false })}
                          company={users.user.companies[0].company_type}
                          user={this.state.selectedUser}
                          updateUser={this.updateUser}
                        />
                      </Modal.Body>
                    </Modal>
                    <Modal
                      className="form-user-modal edit-quality"
                      show={this.state.showProgramQualityEdit}
                      onHide={() =>
                        this.setState({ showProgramQualityEdit: false })
                      }
                      style={{ opacity: 1 }}
                      id="modal-user-add"
                    >
                      <Modal.Header closeButton>
                        <div className="Modal-add-user-title">
                          <h5 className="card-title ">
                            <FormattedMessage
                              {...messages.EditProgramQuality}
                            />
                          </h5>
                        </div>
                        <Modal.Title />
                      </Modal.Header>
                      <Modal.Body>
                        <FormQualityProgram
                          closeModal={() =>
                            this.setState({ showProgramQualityEdit: false })
                          }
                          currentQuality={this.state.qualite_program.filter(
                            (qp) => qp.name === this.state.qualityProgram,
                          )}
                          updateQualityProgram={this.updateQualityProgram}
                          photos={this.state.tabPhotos}
                          qualities={this.state.qualite_program}
                          qualityProgram={this.state.qualityProgram}
                          company={users.user.companies[0].company_type}
                          user={this.state.selectedUser}
                        />
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={this.state.confirm}
                      onHide={() => this.setState({ confirm: false })}
                      style={{ opacity: 1 }}
                    >
                      <Modal.Body>
                        <h4>
                          Are you sure you want to delete{' '}
                          {this.state.selectedUser.name}
                        </h4>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={() => this.setState({ confirm: false })}
                        >
                          Close
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => this.deleteUser()}
                        >
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Row>
                }
              />
            </Col>
          </Row>
        )}
        {/* Quality program Management */}
        {(this.state.business_type === 1 || this.state.business_type === 2) &&
          specificRoles.ROLE_FOR_ROLE_COMPANY_OWNER.indexOf(role) > -1 && (
            <Card
              style={{ width: '100%' }}
              title={<FormattedMessage {...messages.qualityProgram} />}
              content={this.renderListQualities()}
            />
          )}
        {/* Fleet Management */}
        {(this.state.business_type === 4 || this.state.business_type === 2) &&
          specificRoles.ROLE_FOR_SETTINGS_NOT_DRIVER.indexOf(role) > -1 && (
            <Row className="setting-users">
              <Col md={12}>
                <Card
                  title={
                    <Col md={6} className="card-title">
                      <FormattedMessage {...messages.FleetSideMessage} />
                    </Col>
                  }
                  content={
                    <Row className="text-center">
                      <Col md={12}>
                        <div className="user-cards-helper">
                          {this.listTrucks()}
                          <Col md={2} sm={6}>
                            <div
                              className="box wow fadeInUp"
                              data-wow-duration=".5s"
                              onClick={() =>
                                this.setState({
                                  selectedTruck: {},
                                  showTruck: true,
                                })
                              }
                            >
                              <span className=" add-icon" />
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Modal
                        show={this.state.showTruck}
                        onHide={() => this.setState({ showTruck: false })}
                        style={{ opacity: 1 }}
                        id="modal-transporter"
                      >
                        <Modal.Header closeButton>
                          <div className="Modal-add-user-title">
                            <h5 className="card-title ">
                              <FormattedMessage {...messages.AddTransports} />
                            </h5>
                          </div>
                          <Modal.Title />
                        </Modal.Header>
                        <Modal.Body>
                          <FormTransport
                            handleModal={() =>
                              this.setState({ showTruck: false })
                            }
                            truck={this.state.selectedTruck}
                            updateUser={this.updateTruck}
                            fetchTrucks={this.fetchTrucks}
                          />
                        </Modal.Body>
                      </Modal>
                      <Modal
                        show={this.state.confirmTruck}
                        onHide={() => this.setState({ confirmTruck: false })}
                        style={{ opacity: 1 }}
                      >
                        <Modal.Body>
                          <h4>
                            Are you sure you want to delete{' '}
                            {this.state.selectedUser.name}
                          </h4>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.setState({ confirmTruck: false })
                            }
                          >
                            Close
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => this.deleteTruck()}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Row>
                  }
                />
              </Col>
            </Row>
          )}
      </Container>
    );
  }
}

TabUsers.propTypes = {
  getAllUsers: PropTypes.func,
  users: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    getAllUsers: (page, limit, token) =>
      dispatch(userActions.getAllUsers(page, limit, token)),
    deleteUser: (userId) => dispatch(userActions.deleteUser(userId)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabUsers);
