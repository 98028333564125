import React from 'react';
import Table from 'react-bootstrap/Table';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../static/css/datepicker.css';
import { renameBreed } from './utils';
const getDaysAbr = day => {
  var res = day.substring(0, 2);
  var daySub = res + '.';
  return daySub;
};

const BuyingsTable = ({ days, buyingsStock, showDetails }) => {
  if (!buyingsStock.buyingsStock) return null;
  const stocks = buyingsStock.buyingsStock
    .map(e => ({
      ...e,
      breed: renameBreed(e.breed),
    }))
    .sort((a, b) => (a.breed > b.breed ? 1 : -1));
  return (
    <div className="buying-table">
      {stocks.length > 0 && (
        <Table responsive hover>
          <thead>
            <tr className="title-row">
              <th colSpan="1" rowSpan="1" className="no-border"></th>
              {days.map((data, index) => (
                <th key={data + index} value={data} className="no-border">
                  <button
                    className="btn-day"
                    onClick={evt => showDetails(index)}
                    value={data}
                  >
                    {getDaysAbr(data)}
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {stocks.map(({ breed, total, days }, id) => (
              <tr key={breed + id} className="tr-table buyings-overview-table">
                <td className="no-border">
                  <div className="breed-left-section">
                    <span className="breed">{breed}</span>
                    <span className="total-breed">{total}</span>
                  </div>
                </td>
                {days.map((day, j) => (
                  <td key={breed + j}>{day.number} </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default BuyingsTable;
