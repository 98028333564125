export const breedm = [
  {
    id: 'A',
    value: 'Kalb (m)',
    label: 'Kalb (m)',
  },
  {
    id: 'B',
    value: 'Kalb (w)',
    label: 'Kalb (w)',
  },
];
