import React, { Component, createRef } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import * as ELG from 'esri-leaflet-geocoder';
import start from '../../../../static/images/map/start.png';
import end from '../../../../static/images/map/end.png';
import gps_location from '../../../../static/images/map/gps_location.png';

import 'leaflet/dist/leaflet.css';

class AppMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
      driver: this.props.driver,
      locations: this.props.locations,
      paramEnd: this.props.paramEnd,
      param_gps_location: this.props.params_gps_location,
      gpsLat:
        this.props.params_gps_location &&
        this.props.params_gps_location.substring(
          0,
          this.props.params_gps_location.indexOf('_'),
        ),
      gpsLng:
        this.props.params_gps_location &&
        this.props.params_gps_location.substring(
          this.props.params_gps_location.indexOf('_') + 1,
        ),
      is_gps_updated: this.props.is_gps_updated,
      mapRef: createRef(null),
      centerLng: 0,
      centerLat: 0,
      seller: this.props.seller,
      buyer: this.props.buyer,
      markers: [],
    };
  }
  getMarkers = () => {
    return (
      this.state.locations.map((item) => {
        this.setState({
          markers: typeof item.address !=='undefined' &&this.state.markers.push(
            item.address.street
              .concat(', ')
              .concat(item.address.zip_code)
              .concat(' ')
              .concat(item.address.city),
          ),
        });
      }),
      this.setState({
        markers: this.state.markers.push(this.state.paramEnd),
      })
    );
  };
  componentDidMount() {
    const map = this.state.mapRef.current.leafletElement;
    this.getMarkers();
    let StartIcon = new L.Icon({
      iconUrl: start,
      iconSize: [40, 40],
    });

    let EndIcon = new L.Icon({
      iconUrl: end,
      iconSize: [40, 40],
    });
    let finalAddress = this.state.markers[this.state.markers.length - 1];
    this.state.markers.forEach((address) => {
      ELG.geocode()
        .text(address)
        .run((err, results, response) => {
          const { lat, lng } = results && results.results[0].latlng;
          address === finalAddress &&
            this.setState({
              centerLat: lat,
              centerLng: lng,
            });
          L.marker([lat, lng], {
            icon: address === finalAddress ? EndIcon : StartIcon,
          })
            .addTo(map)
            .bindPopup(
              address === this.state.paramEnd
                ? this.state.buyer
                : this.state.seller,
            );
          // .openPopup();
        });
    });
  }

  render() {
    let gps_location_Icon = new L.Icon({
      iconUrl: gps_location,
      iconSize: [35, 40],
    });
    return (
      <div>
        <Map
          center={[this.state.centerLat, this.state.centerLng]}
          zoom={6.5}
          ref={this.state.mapRef}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          />
          {!!this.state.is_gps_updated && (
            <Marker
              position={[this.state.gpsLat, this.state.gpsLng]}
              icon={gps_location_Icon}
            >
              <Popup>{this.state.driver}</Popup>
            </Marker>
          )}
        </Map>
      </div>
    );
  }
}

export default AppMap;
