import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { alertActions } from '../../../../../actions';
import statusHandle from './statusHandle';
import { FormattedMessage } from 'react-intl';
import NormalButton from '../../../../Shared/components/buttons/NormalButton/NormalButton';
import { svgPaths } from '../../../../../constants';
import messages from '../../../messages';
import SvgIcon from '../../../../Shared/components/icons/SvgIcon/SvgIcon';
import ToggleMenuButton from '../../../../Shared/components/buttons/ToggleMenuButton/ToggleMenuButton';

class OptionalButtonProducder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: 'text',
      received_status: '',
      menuItems: [
        {
          id: 0,
          title: <FormattedMessage {...messages.ownStock} />,
          selected: false,
          path: (
            <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
          ),
          func: 'getOwnStock',
        },
        {
          id: 1,
          title: <FormattedMessage {...messages.openBuyings} />,
          selected: false,
          path: (
            <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
          ),
          func: 'getOpenBuyings',
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    // Load new data when the dataSource property changes.
    if (prevProps.order_id !== this.props.order_id) {
      let token = this.props.users.accessToken;
      const { order_id, receiver_id, is_buyer, topic } = this.props;

      const message = statusHandle(
        order_id,
        receiver_id,
        is_buyer,
        topic,
        token,
      );

      message.then((successMessage) => {
        this.setState({
          text: typeof successMessage.message !== 'undefined' ? successMessage.message : 'update',
          received_status: successMessage.rejectCancel,
        });
      });
    }
  }

  componentDidMount() {
    const token = this.props.users.accessToken;
    const { order_id, receiver_id, is_buyer, topic } = this.props;
    const message = statusHandle(order_id, receiver_id, is_buyer, topic, token);
    message.then((successMessage) => {
      this.setState({
        text: typeof successMessage.message !== 'undefined' ? successMessage.message : 'update',
        received_status: successMessage.rejectCancel,
      });
    });
  }

  handleSelectedAction = (e, func) => {
    if (func === 'getOwnStock') this.props.getSubmitMessage(e, 'AcceptABT');
    if (func === 'getOpenBuyings')
      this.props.getSubmitMessage(e, 'AcceptABTOpenBuyings');
  };

  render() {
    const { is_buyer, status } = this.props;
    const companyType = this.props.users.user.session.company_type;
    return (
      <div className="order-details-button-group">
        {this.props.status === 'REQUESTED' && companyType === '3' && (
          <NormalButton
            type="submit"
            onClick={(e) =>
              this.props.getSubmitMessage(e, 'continueAsExternal')
            }
          >
            <FormattedMessage {...messages.ContinueAsExternal} />
          </NormalButton>
        )}
        {this.state.text === 'Cancelable' && (
          <NormalButton
            grey
            type="submit"
            onClick={(e) => this.props.getSubmitMessage(e, this.state.text)}
          >
            <SvgIcon color="white" size="22" viewBox="0 0 550 550">
              {svgPaths['cancel']}
            </SvgIcon>
            <FormattedMessage {...messages.Cancel} />
          </NormalButton>
        )}
        {this.state.text === 'Rejectable' && (
          <div className="order-details-button-group">
            <NormalButton
              grey
              type="submit"
              onClick={(e) => this.props.getSubmitMessage(e, this.state.text)}
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['cancel']}
              </SvgIcon>
              <FormattedMessage {...messages.Cancel} />
            </NormalButton>
          </div>
        )}
        {!this.state.text &&  (
          <div className="order-details-button-group">
            <NormalButton
              grey
              type="submit"
              onClick={(e) => this.props.getSubmitMessage(e, 'Cancelable')}
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['cancel']}
              </SvgIcon>
              <FormattedMessage {...messages.Cancel} />
            </NormalButton>
          </div>
        )}
        {/* status === Reconfirm */}
        {this.state.text === 'Reconfirm' && this.props.status !== 'BOUGHT' && (
          <div className="order-details-button-group">
            {this.props.topic === 'BUY_STOCK' &&
              is_buyer === 1 &&
              status === 'TO_BE_RECONFIRMED' && (
                <NormalButton
                  type="submit"
                  onClick={(e) =>
                    this.props.getSubmitMessage(e, this.state.text)
                  }
                >
                  <FormattedMessage {...messages.Reconfirm} />
                </NormalButton>
              )}
            {(this.props.topic === 'SELL_STOCK' ||
              this.props.topic === 'RESALE_STOCK') &&
              is_buyer === 0 &&
              status === 'TO_BE_RECONFIRMED' && (
                <NormalButton
                  type="submit"
                  onClick={(e) =>
                    this.props.getSubmitMessage(e, this.state.text)
                  }
                >
                  <FormattedMessage {...messages.Reconfirm} />
                </NormalButton>
              )}

            <NormalButton
              grey
              type="submit"
              onClick={(e) =>
                this.props.getSubmitMessage(
                  e,
                  this.props.topic === 'BUY_STOCK' &&
                    is_buyer === 1 &&
                    status === 'TO_BE_RECONFIRMED'
                    ? 'Cancelable'
                    : (this.props.topic === 'SELL_STOCK' ||
                        this.props.topic === 'RESALE_STOCK') &&
                      is_buyer === 0 &&
                      status === 'TO_BE_RECONFIRMED'
                    ? 'Cancelable'
                    : 'Rejectable',
                )
              }
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['trash']}
              </SvgIcon>
              <FormattedMessage {...messages.Delete} />
            </NormalButton>
          </div>
        )}
        {/* status === request */}
        {this.state.text === 'Request' && (
          <div className="order-details-button-group">
            {this.props.topic === 'BUY_STOCK' && is_buyer === 0 && (
              <NormalButton
                type="submit"
                onClick={(e) => this.props.getSubmitMessage(e, 'Request')}
              >
                <FormattedMessage {...messages.Accept} />
              </NormalButton>
            )}
            {(this.props.topic === 'SELL_STOCK' ||
              this.props.topic === 'RESALE_STOCK') && (
              <NormalButton
                type="submit"
                onClick={(e) => this.props.getSubmitMessage(e, 'Request')}
              >
                <FormattedMessage {...messages.Accept} />
              </NormalButton>
            )}

            <NormalButton
              grey
              type="submit"
              onClick={(e) =>
                this.props.getSubmitMessage(
                  e,
                  this.props.topic === 'SELL_STOCK' ||
                    'BUY_STOCK' ||
                    ('RESALE_STOCK' &&
                      this.props.users.user.companies['0'].company_type !== '1')
                    ? 'Rejectable'
                    : 'Cancelable',
                )
              }
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['trash']}
              </SvgIcon>
              <FormattedMessage {...messages.Delete} />
            </NormalButton>
          </div>
        )}
        {/* status === Accept */}
        {this.state.text === 'Accept' && (
          <div className="order-details-button-group">
            {this.props.topic === 'BUY_STOCK' && is_buyer === 0 && (
              <NormalButton
                type="submit"
                onClick={(e) => this.props.getSubmitMessage(e, 'Accept')}
              >
                <FormattedMessage {...messages.Accept} />
              </NormalButton>
            )}
            {(this.props.topic === 'SELL_STOCK' ||
              this.props.topic === 'RESALE_STOCK') && (
              <NormalButton
                type="submit"
                onClick={(e) => this.props.getSubmitMessage(e, 'Accept')}
              >
                <FormattedMessage {...messages.Accept} />
              </NormalButton>
            )}

            <NormalButton
              grey
              type="submit"
              onClick={(e) =>
                this.props.getSubmitMessage(
                  e,
                  this.props.topic === 'SELL_STOCK' ||
                    'BUY_STOCK' ||
                    ('RESALE_STOCK' &&
                      this.props.users.user.companies['0'].company_type !== '1')
                    ? 'Rejectable'
                    : 'Cancelable',
                )
              }
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['trash']}
              </SvgIcon>
              <FormattedMessage {...messages.Delete} />
            </NormalButton>
          </div>
        )}
        {/* status === Accept confirm */}
        {this.state.text === 'Confirm' && (
          <div className="order-details-button-group">
            {this.state.received_status && (
              <NormalButton
                type="submit"
                onClick={(e) => this.props.getSubmitMessage(e, this.state.text)}
              >
                <FormattedMessage {...messages.Confirm} />
              </NormalButton>
            )}

            <NormalButton
              grey
              type="submit"
              onClick={(e) => this.props.getSubmitMessage(e, 'Rejectable')}
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['trash']}
              </SvgIcon>
              <FormattedMessage {...messages.Delete} />
            </NormalButton>
          </div>
        )}
        {/* status === toAccept  */}
        {this.state.text === 'toAccept' && (
          <div className="order-details-button-group">
            {this.props.topic === 'BUY_STOCK' &&
              is_buyer === 0 &&
              companyType !== '2' && (
                <NormalButton
                  type="submit"
                  onClick={(e) => this.props.getSubmitMessage(e, 'Accept')}
                >
                  <FormattedMessage {...messages.Accept} />
                </NormalButton>
              )}
            {this.props.topic === 'BUY_STOCK' &&
              is_buyer === 0 &&
              companyType === '2' && (
                <ToggleMenuButton
                  title={<FormattedMessage {...messages.Options} />}
                  width="100%"
                  green
                >
                  {this.state.menuItems.map((item) => (
                    <Dropdown.Item
                      type="submit"
                      key={item.id}
                      onClick={(e) => this.handleSelectedAction(e, item.func)}
                    >
                      {item.path && (
                        <SvgIcon size="20" viewBox="0 0 600 600">
                          {item.path}
                        </SvgIcon>
                      )}
                      <div className="menu-item-text">{item.title}</div>
                    </Dropdown.Item>
                  ))}
                </ToggleMenuButton>
              )}
            {(this.props.topic === 'SELL_STOCK' ||
              this.props.topic === 'RESALE_STOCK') &&
              is_buyer === 1 && (
                <NormalButton
                  type="submit"
                  onClick={(e) =>
                    this.props.getSubmitMessage(e, this.state.text)
                  }
                >
                  <FormattedMessage {...messages.Accept} />
                </NormalButton>
              )}

            <NormalButton
              grey
              type="submit"
              onClick={(e) => this.props.getSubmitMessage(e, 'Cancelable')}
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['trash']}
              </SvgIcon>
              <FormattedMessage {...messages.Delete} />
            </NormalButton>
          </div>
        )}
        {/* rest of RequestTrd */}
        {this.state.text === 'RequestTrd' && (
          <div className="order-details-button-group">
            {this.props.topic === 'BUY_STOCK' &&
              is_buyer === 0 &&
              companyType !== '2' && (
                <NormalButton
                  type="submit"
                  onClick={(e) => this.props.getSubmitMessage(e, 'Accept')}
                >
                  <FormattedMessage {...messages.Confirm} />
                </NormalButton>
              )}
            {this.props.topic === 'BUY_STOCK' &&
              is_buyer === 0 &&
              companyType === '2' && (
                <ToggleMenuButton
                  title={<FormattedMessage {...messages.Options} />}
                  width="100%"
                  green
                >
                  {this.state.menuItems.map((item) => (
                    <Dropdown.Item
                      type="submit"
                      key={item.id}
                      onClick={(e) => this.handleSelectedAction(e, item.func)}
                    >
                      {item.path && (
                        <SvgIcon size="20" viewBox="0 0 600 600">
                          {item.path}
                        </SvgIcon>
                      )}
                      <div className="menu-item-text">{item.title}</div>
                    </Dropdown.Item>
                  ))}
                </ToggleMenuButton>
              )}
            {(this.props.topic === 'SELL_STOCK' ||
              this.props.topic === 'RESALE_STOCK') &&
              is_buyer === 1 && (
                <NormalButton
                  type="submit"
                  onClick={(e) => this.props.getSubmitMessage(e, 'Accept')}
                >
                  <FormattedMessage {...messages.Confirm} />
                </NormalButton>
              )}

            <NormalButton
              grey
              type="submit"
              onClick={(e) => this.props.getSubmitMessage(e, 'Rejectable')}
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['cancel']}
              </SvgIcon>
              <FormattedMessage {...messages.Cancel} />{' '}
            </NormalButton>
          </div>
        )}
        {/* rest of states */}
        {this.state.text === 'RequestAbt' && (
          <div className="order-details-button-group">
            {this.props.topic === 'BUY_STOCK' &&
              is_buyer === 0 &&
              companyType !== '2' && (
                <NormalButton
                  type="submit"
                  onClick={(e) => this.props.getSubmitMessage(e, 'AcceptABT')}
                >
                  <FormattedMessage {...messages.Accept} />
                </NormalButton>
              )}

            {this.props.topic === 'BUY_STOCK' &&
              is_buyer === 0 &&
              companyType === '2' && (
                <ToggleMenuButton
                  title={<FormattedMessage {...messages.Options} />}
                  width="100%"
                  green
                >
                  {this.state.menuItems.map((item) => (
                    <Dropdown.Item
                      type="submit"
                      key={item.id}
                      onClick={(e) => this.handleSelectedAction(e, item.func)}
                    >
                      {item.path && (
                        <SvgIcon size="20" viewBox="0 0 600 600">
                          {item.path}
                        </SvgIcon>
                      )}
                      <div className="menu-item-text">{item.title}</div>
                    </Dropdown.Item>
                  ))}
                </ToggleMenuButton>
              )}
            {(this.props.topic === 'SELL_STOCK' ||
              this.props.topic === 'RESALE_STOCK') &&
              is_buyer === 1 && (
                <NormalButton
                  type="submit"
                  onClick={(e) =>
                    this.props.getSubmitMessage(e, this.state.text)
                  }
                >
                  <FormattedMessage {...messages.Accept} />
                </NormalButton>
              )}

            <NormalButton
              grey
              type="submit"
              onClick={(e) => this.props.getSubmitMessage(e, 'Rejectable')}
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['trash']}
              </SvgIcon>
              <FormattedMessage {...messages.Delete} />
            </NormalButton>
          </div>
        )}
        {this.props.status === 'BOUGHT' &&
          // ['SELL_STOCK', 'BUY_STOCK'].indexOf(this.props.topic) > -1 && (
            (
            <div className="order-details-button-group">
          
               <NormalButton
                  type="submit"
                  onClick={(e) => this.props.getSubmitMessage(e, 'Updatenotes')}
                >
                 <FormattedMessage {...messages.updateNote} />
                </NormalButton>
                {!this.props.isRight &&  <NormalButton
                grey
                type="submit"
                onClick={(e) => this.props.getSubmitMessage(e, 'Delete')}
              >
                <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                  {svgPaths['trash']}
                </SvgIcon>
                <FormattedMessage {...messages.CancelViehan} />
              </NormalButton>  }
            </div>
          )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionalButtonProducder);
