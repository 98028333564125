import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import success from '../../../../static/images/admin/member_ship/success.png';
import check from '../../../../static/images/admin/member_ship/checkSuccess.png';

import '../invoice.css';

const translateMembership = (text) => {
  if (['business', 'basic'].indexOf(text) > -1) return 'BASIC';
  if (['premium', 'pro'].indexOf(text) > -1) return 'PRO';
  else return 'FREE';
};

const PaiementSuccess = ({ subscriptionPlan }) => {
  return (
    <div className="container-success">
      <Row>
        <div className="success-subscription">
          <FormattedMessage {...messages.SuccessPart1} />{' '}
          <a href=" " className="special-discription">
            Agrabiz{' '}
            <span style={{ textTransform: 'uppercase' }}>
              {translateMembership(subscriptionPlan)}
            </span>
          </a>{' '}
          <FormattedMessage {...messages.SuccessPart2} />{' '}
        </div>
      </Row>
      <Row>
        <Col md={12}>
          <div className="success-under-subscription">
            <FormattedMessage {...messages.SuccessUnder} />{' '}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="success-images">
            <Image src={success} />
            <Image src={check} style={{ margin: '9px 0px 0 -105px' }} />
          </div>
        </Col>
      </Row>
      <Row>
        <div className="success-footer">
          <FormattedMessage {...messages.SuccessFooterPart1} />{' '}
          <a href=" " className="special-discription">
            +498912267791
          </a>{' '}
          <FormattedMessage {...messages.SuccessFooterPart2} />{' '}
          <a href=" " className="special-discription">
            support@agrabiz.de
          </a>{' '}
          .
        </div>
      </Row>
    </div>
  );
};

export default PaiementSuccess;
