import React from 'react';
import { companyConstants, alertConstants, userConstants } from '../constants';
import * as companyService from '../services/company.service';
import { userActions } from './user.actions';
import extractData from '../services/data.processing';
import { history } from '../helpers';
import messages from '../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
export function editPassword(current_password, password, useHitPassword) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    const companyId = getState().toJS().users.user.session.company_id;
    const companyUserRole = getState().toJS().users.user.role;
    try {
      const response = await companyService.editPassword(
        current_password,
        password,
        useHitPassword,
        companyId,
        accessToken,
      );
      if (response.status === 200) {
        await extractData(response);
        await dispatch({
          type: alertConstants.SUCCESS,
          message: <FormattedMessage {...messages.DefaultSuccessMessgage} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      } else {
        if (companyUserRole === userConstants.ROLE_COMPANY_OWNER) {
          dispatch({
            type: alertConstants.ERROR,
            message: <FormattedMessage {...messages.WrongPassword} />,
          });
        } else {
          dispatch({
            type: alertConstants.ERROR,
            message: (
              <FormattedMessage {...messages.ChangePasswordNotOwnerMessgage} />
            ),
          });
        }
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      }
    } catch (error) {
      if (companyUserRole === userConstants.ROLE_COMPANY_OWNER) {
        dispatch({
          type: alertConstants.ERROR,
          message: <FormattedMessage {...messages.WrongPassword} />,
        });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: (
            <FormattedMessage {...messages.ChangePasswordNotOwnerMessgage} />
          ),
        });
      }
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

export function editUseOrderReconfirmation(useOrderReconfirmation) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    const companyId = getState().toJS().users.user.session.company_id;
    try {
      const response = await companyService.editUseOrderReconfirmation(
        useOrderReconfirmation,
        companyId,
        accessToken,
      );
      if (response.status === 200) {
        await extractData(response);
        await dispatch({
          type: alertConstants.SUCCESS,
          message: <FormattedMessage {...messages.DefaultSuccessMessgage} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: <FormattedMessage {...messages.DefaultErrorMessage} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: <FormattedMessage {...messages.DefaultErrorMessage} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

export function editPin(current_pin, new_pin, useHitPassword) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    const companyId = getState().toJS().users.user.session.company_id;
    try {
      const response = await companyService.editPIn(
        current_pin,
        new_pin,
        useHitPassword,
        companyId,
        accessToken,
      );
      if (response.status === 200) {
        await extractData(response);
        await dispatch({
          type: alertConstants.SUCCESS,
          message: <FormattedMessage {...messages.DefaultSuccessMessgage} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: <FormattedMessage {...messages.WrongPassword} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: <FormattedMessage {...messages.WrongPassword} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

export function usePassword(useHitPassword) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    const companyId = getState().toJS().users.user.session.company_id;
    try {
      const response = await companyService.usePassword(
        useHitPassword,
        companyId,
        accessToken,
      );
      if (response.status === 200) {
        await extractData(response);
        await dispatch({
          type: alertConstants.SUCCESS,
          message: <FormattedMessage {...messages.DefaultSuccessMessgage} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      } else {
        dispatch({
          type: alertConstants.ERROR,
          message: <FormattedMessage {...messages.WrongPassword} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      }
    } catch (error) {
      dispatch({
        type: alertConstants.ERROR,
        message: <FormattedMessage {...messages.WrongPassword} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

export function newRegisterCompany(data) {
  return async (dispatch, getState) => {
    try {
      const response = await companyService.newRegisterCompany(data);
      const user = await extractData(response);
      await dispatch({ type: userConstants.LOGIN_SUCCESS, user });
      await dispatch({
        type: companyConstants.CHANGE_STEP,
        step: 1,
        company: {},
      });
    } catch (error) {
      dispatch({ type: alertConstants.ERROR, error });
    }
  };
}

export function addRegisterCompany(data) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;

    try {
      await companyService.addRegisterCompany(data, accessToken);
      await dispatch({
        type: companyConstants.CHANGE_STEP,
        step: 1,
        company: {},
      });

      await dispatch(userActions.getAllUsers(0, 20, accessToken));
      history.push('/admin/home');
    } catch (error) {
      dispatch({ type: alertConstants.ERROR, error });
    }
  };
}

export function getCompany() {
  return async (dispatch, getState) => {};
}

export function editCompany(data) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    const id_company = getState().toJS().users.user.session.company_id;
    try {
      const response = await companyService.editCompany(
        data,
        id_company,
        accessToken,
      );
      if (response.status === 200) {
      const company = await extractData(response);
      if (typeof company !== 'undefined') {
        await dispatch({ type: userConstants.COMPANY_EDIT, company });
        await dispatch({
          type: alertConstants.SUCCESS,
          message: <FormattedMessage {...messages.DefaultSuccessMessgage} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      } else {
        await dispatch({
          type: alertConstants.ERROR,
          message: <FormattedMessage {...messages.DefaultErrorMessage} />,
        });
        await dispatch({ type: alertConstants.CLEAR, message: '' });
      }
    }
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}
