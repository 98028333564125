import { configConstants } from '../constants';
import { get, post, put, del, getFileContent } from './request';

async function login(email, password, subscription) {
  try {
    let data = {
      email: email,
      password: password,
      platform: 'web',
      device_id: JSON.stringify(subscription),
    };
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';

    return await post(`${configConstants.User}/login`, contentType, null, data);
  } catch (error) { }
}

async function getUser(id, token) {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(
      `${configConstants.Company}/${id}`,
      requestOptions,
    );
    return response;
  } catch (error) { }
}

async function getUserOwner(userId, accessToken) {
  try {
    const contentType = 'image/png';
    const resp = await get(
      `${configConstants.User}/company/${userId}/owner`,
      accessToken,
      contentType,
    );

    return resp;
  } catch (error) { }
}


async function getDocUser(id, accessToken, certificate_id) {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'image/png',
        'Accept-Encoding': 'image/png',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const resp = await getFileContent(
      `${configConstants.Company}/${id}/certificates/${certificate_id}`,
      requestOptions,
      'arraybuffer',
    );
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch (error) { }
}

function logout(accessToken) {
  try {
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    return post(`${configConstants.User}/logout`, contentType, accessToken);
  } catch (error) { }
}

async function fetchUsers(page = 0, limit = 20, accessToken) {
  try {
    return get(
      `${configConstants.User}?page=${page}&limit=${limit}`,
      accessToken,
    );
  } catch (error) { }
}

async function add(data, accessToken) {
  try {
    const contentType = 'application/json';
    return post(`${configConstants.User}`, contentType, accessToken, data);
  } catch (error) { }
}

async function edit(data, userId, accessToken) {
  try {
    const contentType = 'application/json';
    return put(
      `${configConstants.User}/${userId}`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) { }
}

async function newsLetter(email) {
  try {
    const data = { email };
    const contentType = 'application/json';
    return post(`${configConstants.User}/newsletter`, contentType, null, data);
  } catch (error) { }
}

async function remove(userId, accessToken) {
  try {
    return del(`${configConstants.User}/${userId}`, accessToken);
  } catch (error) { }
}

function verification(number, pin) {
  try {
    // const contentType = 'application/json';
    const formattedPin = pin.replace(/\+/g, '%2B')
    return get(
      `${configConstants.Company}/check?number=${number}&pin=${formattedPin}`,
      null,
    );
  } catch (error) { }
}

function verifEmailUser(email) {
  try {
    // const contentType = 'application/json';
    return get(`${configConstants.User}/is_email_exist?email=${email}`, null);
  } catch (error) { }
}

// eslint-disable-next-line no-unused-vars
function b64toBlob(b64Data, contentType, sliceSize) {
  try {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  } catch (error) { }
}

async function getSignature(userId, accessToken) {
  try {
    const contentType = 'image/png';
    const resp = await get(
      `${configConstants.User}/${userId}/signature`,
      accessToken,
      contentType,
      'arraybuffer',
    );
    if (
      resp !== 'null' &&
      typeof resp !== 'undefined' &&
      typeof resp.status !== 'undefined' &&
      resp.status === 200
    ) {
      return Buffer.from(resp.data, 'binary').toString('base64');
    }
    else {
      return ''
    }
  } catch (error) { }
}

async function deleteSignature(userId, accessToken) {
  try {
    // const contentType = 'image/png';
    const resp = await del(
      `${configConstants.User}/${userId}/signature`,
      accessToken
    );
    return resp;
  } catch (error) { }
}

async function addSignature(userId, accessToken, data) {
  try {
    const contentType = 'multipart/form-data';
    const d = { file: data };
    return post(
      `${configConstants.User}/${userId}/signature`,
      contentType,
      accessToken,
      d,
      true,
    );
  } catch (error) { }
}

async function initPassword(email) {
  try {
    const data = { email };
    const contentType = 'application/x-www-form-urlencoded';
    return post(`${configConstants.User}/forgot`, contentType, null, data);
  } catch (error) { }
}

async function resetPassword(password, token) {
  try {
    return get(`${configConstants.User}/reset/${token}?password=${password}`);
  } catch (error) { }
}

async function setPassword(password, appId) {
  try {
    const data = { password };
    const contentType = 'application/x-www-form-urlencoded';
    return post(
      `${configConstants.User}/${appId}/password`,
      contentType,
      null,
      data,
    );
  } catch (error) { }
}

async function refreshToken(
  grant_type,
  client_id,
  client_secret,
  refresh_token,
) {
  try {
    let data = {
      grant_type: grant_type,
      client_id: client_id,
      client_secret: client_secret,
      refresh_token: refresh_token,
    };
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    return post(`${configConstants.Auth}/token`, contentType, null, data);
  } catch (error) { }
}

async function testToken(accessToken) {
  try {
    return get(`${configConstants.Auth}/secure`, accessToken);
  } catch (error) { }
}

async function updateLanguage(accessToken, userId, deviceId, language) {
  try {
    const data = { language: language };
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    return put(
      `${configConstants.User}/${userId}/devices/${deviceId}/language`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) { }
}

async function getPolicy() {
  try {
    const contentType = 'application/pdf';
    const resp = await get('/api/v1/policy', null, contentType, 'arraybuffer');
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch (error) { }
}

async function getDataProtection() {
  try {
    const contentType = 'application/pdf';
    const resp = await get(
      '/api/v1/data-protection',
      null,
      contentType,
      'arraybuffer',
    );
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch (error) { }
}

async function changeUserSession(session, accessToken) {
  try {
    const companyId = session.company_id;
    const deviceId = session.device_id;
    const userId = session.user_id;
    const data = { company_id: companyId, device_id: deviceId };
    const contentType = 'application/x-www-form-urlencoded';
    return put(
      `${configConstants.User}/${userId}/session`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) { }
}

export const userService = {
  login,
  fetchUsers,
  add,
  edit,
  remove,
  newsLetter,
  logout,
  getSignature,
  addSignature,
  getUser,
  getDocUser,
  verification,
  verifEmailUser,
  initPassword,
  resetPassword,
  setPassword,
  refreshToken,
  testToken,
  updateLanguage,
  getPolicy,
  getDataProtection,
  changeUserSession,
  getUserOwner,
  deleteSignature
};
