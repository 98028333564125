import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './section.scss';

const MembershipTypeSection = ({ membership, ...props }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div id="m-information-section">
      <div className="i-section">
        <div className="first-section">
          <img alt="" src={membership.image} />
        </div>
        {membership.sectionOne}
        {showMore && <div className="description">{membership.sectionTwo}</div>}
        {showMore ? (
          <div className="show-more" onClick={() => setShowMore(false)}>
            <span>Weniger Details</span> <span>-</span>
          </div>
        ) : (
          <div className="show-more" onClick={() => setShowMore(true)}>
            <span>Mehr Details </span> <span>+</span>
          </div>
        )}
      </div>
      {membership.id === 1 && <div className="separation"></div>}
    </div>
  );
};

MembershipTypeSection.propTypes = {
  membership: PropTypes.object,
  children: PropTypes.node,
};

export default MembershipTypeSection;
