import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../../../Shared/components/icons/SvgIcon/SvgIcon';
import { svgPaths } from '../../../../../constants';
import clsx from 'clsx';
import './membership-section.scss';

const formatDateToGerman = str => {
  const dates = str.split('-');
  return [dates[2], dates[1], dates[0]].join('.');
};

const addDays = (date, days) => {
  let dt = new Date(date);
  dt.setDate(dt.getDate() + days);
  return dt.toISOString();
};

const MembershipTypeSection = ({
  membershipType,
  companyType,
  renderPayement,
  showMembershipConfirm,
  isNewRegistered,
  subscriptionType,
  nextSubscriptionType,
  expirationDate,
  ...props
}) => {
  const { priceNumber, status } = membershipType.price;
  const [numberBeforeComma, numberAfterComma] = [
    priceNumber && priceNumber.split(',')[0],
    priceNumber && priceNumber.split(',')[1].replace(/^/, ','),
  ];
  const changeSubscriptionPlan = () => {
    renderPayement(membershipType.plan);
  };
  const handleMembershipCancel = () => {
    showMembershipConfirm(membershipType.displayType);
  };

  const isDowngrade = () => {
    if (subscriptionType === 'pro' && nextSubscriptionType !== 'free')
      return true;
    if (subscriptionType === 'basic' && nextSubscriptionType === 'free')
      return true;
    return false;
  };
  const isSubscriptionDowngrade = isDowngrade();
  const getSubscriptionInfotext = () => {
    if (
      isNewRegistered &&
      priceNumber &&
      membershipType.type === subscriptionType &&
      nextSubscriptionType === 'free'
    )
      return 'Aktiv bis ' + formatDateToGerman(expirationDate.substring(0, 10));
    if (isSubscriptionDowngrade) {
      if (subscriptionType === membershipType.type)
        return (
          'Aktiv bis ' + formatDateToGerman(expirationDate.substring(0, 10))
        );
      if (nextSubscriptionType === membershipType.type)
        return (
          'Aktiv ab ' +
          formatDateToGerman(addDays(expirationDate, 1).substring(0, 10))
        );
    }
    return subscriptionType === membershipType.type ? 'Aktiv' : '';
  };

  const getSubscriptionBtntext = () => {
    if (
      isNewRegistered &&
      membershipType.type === subscriptionType &&
      nextSubscriptionType === 'free'
    )
      return <span>Plan behalten</span>;
    if (isSubscriptionDowngrade) {
      if (subscriptionType === membershipType.type)
        return <span>Plan behalten</span>;
      if (nextSubscriptionType === membershipType.type)
        return <span className="cancel-plan">Plan kündigen</span>;
    }

    return subscriptionType !== membershipType.type ? (
      <span>Jetzt starten</span>
    ) : (
      <span className="cancel-plan">Plan kündigen</span>
    );
  };

  const getSubscriptionBtnAction = () => {
    if (
      isNewRegistered &&
      membershipType.type === subscriptionType &&
      nextSubscriptionType === 'free'
    )
      return changeSubscriptionPlan;
    if (isSubscriptionDowngrade) {
      if (subscriptionType === membershipType.type)
        return changeSubscriptionPlan;
      if (nextSubscriptionType === membershipType.type)
        return handleMembershipCancel;
    }

    return subscriptionType !== membershipType.type
      ? changeSubscriptionPlan
      : handleMembershipCancel;
  };

  return (
    <div id="membership-type">
      <div className="m-header">
        <div className="header-text">
          <span>{membershipType.displayType}</span>
          <span>{getSubscriptionInfotext()}</span>
        </div>
        <img alt="" src={membershipType.image} />
      </div>
      <div className="separator" />
      <div className="m-description">{membershipType.description}</div>
      <div className="separator" />
      <div className="m-footer">
        <div className={clsx(priceNumber ? 'price' : 'status')}>
          {(priceNumber && (
            <div className="price-box">
              <div className="digits">
                <div
                  className={clsx(
                    'price-right',
                    companyType === '1' && 'p-r-agr',
                  )}
                >
                  {companyType === '2' && <div className="price-tag">ab</div>}
                  <SvgIcon size="20" color="#808080" viewBox="0 0 580 580">
                    {svgPaths['euro']}
                  </SvgIcon>
                </div>
                <span className="price-before-comma">{numberBeforeComma}</span>
                <span className="price-after-comma">{numberAfterComma}</span>
                <div className="price-notice">*</div>
              </div>
              <div className="per-month">pro Monat</div>
            </div>
          )) ||
            status}
        </div>
        {priceNumber && (
          <button
            className="start-now-btn"
            onClick={getSubscriptionBtnAction()}
          >
            {getSubscriptionBtntext()}
          </button>
        )}
      </div>
    </div>
  );
};

MembershipTypeSection.propTypes = {
  membershipType: PropTypes.object,
  companyType: PropTypes.string,
};

export default MembershipTypeSection;
