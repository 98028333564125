/* eslint-disable */
import moment from 'moment-es6';
import React, { Component } from 'react';
import { Col, Card, Row } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce, uniqBy } from 'lodash';

import { connect } from 'react-redux';
import { alertActions } from '../../../actions';
import messages from '../../../components/Admin/messages';
import extractData from '../../../services/data.processing';
import { asRead, getArchives } from '../../../services/notifications.service';
import img1 from '../../../static/images/admin/Icons/Push messages/1.png';
import img10 from '../../../static/images/admin/Icons/Push messages/10.png';
import img11 from '../../../static/images/admin/Icons/Push messages/11.png';
import img12 from '../../../static/images/admin/Icons/Push messages/12.png';
import img13 from '../../../static/images/admin/Icons/Push messages/13.png';
import img14 from '../../../static/images/admin/Icons/Push messages/14.png';
import img15 from '../../../static/images/admin/Icons/Push messages/15.png';
import img16 from '../../../static/images/admin/Icons/Push messages/16.png';
import img17 from '../../../static/images/admin/Icons/Push messages/17.png';
import img18 from '../../../static/images/admin/Icons/Push messages/18.png';
import img19 from '../../../static/images/admin/Icons/Push messages/19.png';
import img2 from '../../../static/images/admin/Icons/Push messages/2.png';
import img20 from '../../../static/images/admin/Icons/Push messages/20.png';
import img21 from '../../../static/images/admin/Icons/Push messages/21.png';
import img22 from '../../../static/images/admin/Icons/Push messages/22.png';
import img23 from '../../../static/images/admin/Icons/Push messages/23.png';
import img24 from '../../../static/images/admin/Icons/Push messages/24.png';
import img3 from '../../../static/images/admin/Icons/Push messages/3.png';
import img4 from '../../../static/images/admin/Icons/Push messages/4.png';
import img5 from '../../../static/images/admin/Icons/Push messages/5.png';
import img6 from '../../../static/images/admin/Icons/Push messages/6.png';
import img7 from '../../../static/images/admin/Icons/Push messages/7.png';
import img8 from '../../../static/images/admin/Icons/Push messages/8.png';
import img9 from '../../../static/images/admin/Icons/Push messages/9.png';
import img40 from '../../../static/images/admin/Icons/Push messages/26.png';
import './message.css';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      page: 0,
      itemBackground: null,
      hasMore: true,
      sizeOfItems: 0,
    };
    // this.myRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.getArchives();
  }
  getArchives = async () => {
    let token = this.props.users.accessToken;
    let lg = this.props.language.locale;

    const value = await getArchives(
      token,
      lg.toLowerCase(),
      this.state.page + 1,
    );
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contacts = await extractData(value);
      this.setState(function (prevState) {
        return {
          page: prevState.page + 1,
        };
      });
      
      if (typeof contacts !== 'undefined') {
        this.setState({
          items: this.state.items.concat(contacts.messages),
          sizeOfItems: Number(contacts.stats.all),
        });
      }
    }
  };

  fetchMoreDataNew = async () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    // this.setState({ page: this.state.page + 1 })
    if (
      this.state.items.length >= this.state.sizeOfItems ||
      this.state.items.length === 0
    ) {
      this.setState({ hasMore: false });
      return;
    } else {
      this.getArchives();
    }
  };

  asRead = async (item) => {
    try {
      let tab = [item._id];
      let token = this.props.users.accessToken;
      const value = await asRead(token, tab);
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        await extractData(value);
        let tabItems = this.state.items;
        tabItems[tabItems.indexOf(item)].is_read = 1;
        this.setState({
          items: tabItems,
        });
      } else {
      }
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  componentDidMount() {}

  allAsRead = async () => {
    try {
      let tab = this.state.items.filter((user) => {
        return user.is_read === 0;
      });
      let tab_id = tab.map(function (item) {
        return item._id;
      });
      tab_id = uniqBy(tab_id);
      let token = this.props.users.accessToken;
      const value = await asRead(token, tab_id);
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        await extractData(value);
        this.setState({ page: 0 });
        this.setState({ items: [] });
        this.getArchives();
      }
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  fetchMoreData = () => {
    this.getArchives();
  };

  getSrcImg = (id) => {
    switch (id) {
      case 1:
        return img1;
        break;
      case 2:
        return img2;
        break;
      case 3:
        return img3;
        break;
      case 4:
        return img4;
        break;
      case 5:
        return img5;
        break;
      case 6:
        return img6;
        break;
      case 7:
        return img7;
        break;
      case 8:
        return img8;
        break;
      case 9:
        return img9;
        break;
      case 10:
        return img10;
        break;
      case 11:
        return img11;
        break;
      case 12:
        return img12;
        break;
      case 13:
        return img13;
        break;
      case 14:
        return img14;
        break;
      case 15:
        return img15;
        break;
      case 16:
        return img16;
        break;
      case 17:
        return img17;
        break;
      case 18:
        return img18;
        break;
      case 19:
        return img19;
        break;
      case 20:
        return img20;
        break;
      case 21:
        return img21;
        break;
      case 22:
        return img22;
        break;
      case 23:
        return img23;
        break;
      case 24:
        return img24;
        break;
      case 40:
        return img40;
        break;
      case 41:
        return img21;
        break;
    }
  };

  toggleBackground = (position) => {
    if (this.state.itemBackground === position) {
      this.setState({ itemBackground: null });
    } else {
      this.setState({ itemBackground: position });
    }
  };

  backgroundChange = (position) => {
    if (this.state.itemBackground === position) {
      return '#f0fae3';
    }
    return '';
  };

  renderList = () => {
    return this.state.items.map((i, index) => (
      <div
        className="list-group-item"
        key={index}
        style={{ background: this.backgroundChange(index) }}
        onClick={() => this.toggleBackground(index)}
      >
        <div className="flexGen">
          <div className="flexGen-block1">
            {i.message && (
              <img
                className={!i.is_read ? 'imageSize' : ' disabled imageSize'}
                src={this.getSrcImg(i.message.push_id)}
              />
            )}
          </div>
          <div className="flexGen-block2">
            {' '}
            <div>
              <p
                className={
                  !i.is_read ? 'green Notifexample' : ' disabled Notifexample'
                }
              >
                {' '}
                {i.message && i.message.title}{' '}
              </p>
            </div>
            <div md={7}>
              {' '}
              <p
                className={
                  !i.is_read ? 'Notifexample' : ' disabled Notifexample'
                }
              >
                {' '}
                {i.message && i.message.body}{' '}
              </p>
            </div>
          </div>

          <div className="flexGen-block3">
            <div>
              <p
                className={
                  !i.is_read
                    ? 'sender Notifexample'
                    : ' sender disabled Notifexample'
                }
              >
                {' '}
                {i.message && i.message.sender}
              </p>
            </div>
            <div>
              {' '}
              <p className={!i.is_read ? 'date' : 'date disabled'}>
                {moment(i.created_at, 'YYYYMMDD HH:mm').format('ll HH:mm ')}{' '}
              </p>
            </div>

            {!i.is_read && (
              <div>
                {' '}
                <span className="orange" onClick={(evt) => this.asRead(i)}>
                  <FormattedMessage {...messages.AsRead} />
                </span>
              </div>
            )}
          </div>
        </div>
        <hr className="style-border" />
      </div>
    ));
  };

  render() {
    return (
      <Row className="messages">
        <Col md={6} sm={12} className="message-col">
          <Card className="card-box">
            <div className="notif-messages-header">
              <div className="orange" onClick={(evt) => this.allAsRead()}>
                <FormattedMessage {...messages.AllAsRead} />
              </div>
            </div>
            <Card.Body>
              <Row className="messages-list">
                <div
                  xs={12}
                  md={12}
                  id="content"
                  ref={(ref) => (this.scrollParentRef = ref)}
                  className="messages-list message-box-br"
                >
                  <div>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={debounce(this.fetchMoreDataNew, 500)}
                      hasMore={this.state.hasMore}
                      loader={
                        <div key={0} className="loader-wrapper">
                          <div className="loader" key={0}></div>
                        </div>
                      }
                      useWindow={false}
                      getScrollParent={() => this.scrollParentRef}
                      threshold={250}
                    >
                      {this.renderList()}
                    </InfiniteScroll>
                  </div>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} sm={12} />
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
