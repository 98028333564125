import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Image } from 'react-bootstrap';
import ImgTop from '../../../static/images/admin/ChevronTop.png';
import ImgBottom from '../../../static/images/admin/ChevronBottom.png';
import messages from '../messages';
import { FormattedMessage } from 'react-intl';
const ModalTruck = ({
  isShowing,
  togglePopupNumber,
  increaseNumber,
  decreaseNumber,
  NumberEartags,
  splitOrder,
  maxValue,
}) => {
  return isShowing
    ? ReactDOM.createPortal(
        <>
          <Modal
            className="Number-Popup-modal"
            show={isShowing}
            onHide={togglePopupNumber}
            style={{ opacity: 1 }}
          >
            <Modal.Header style={{ height: '15%' }} closeButton>
              <div className="Modal-add-truck-title">
                <h5 className="card-title new-transport">
                  {' '}
                  <FormattedMessage {...messages.NumberOfEartags} />
                </h5>
              </div>
              <Modal.Title />
            </Modal.Header>
            <Modal.Body className="">
              <div className="Number-Flex">
                <div className="Number-Flex-b1">
                  <div className="inputNumber">
                    <input
                      name="number"
                      className="inputNumberStyle"
                      type="number"
                      value={NumberEartags}
                      max={maxValue}
                      onChange={e => {}}
                    ></input>
                  </div>
                  <div className="Number-Flex-b1-arrow">
                    <div className="Block-arrow" onClick={increaseNumber}>
                      <Image src={ImgTop} alt="" />
                    </div>
                    <div className="Block-arrow">
                      <Image src={ImgBottom} alt="" onClick={decreaseNumber} />
                    </div>
                  </div>
                </div>
                <div className="Number-Flex-b2">
                  <button
                    className="add-Number"
                    onClick={splitOrder}
                    disabled={NumberEartags === 0}
                  >
                    <FormattedMessage {...messages.Save} />
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>,
        document.body,
      )
    : null;
};

export default ModalTruck;
