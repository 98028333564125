import React from 'react';
import { qualitie } from '../../../../constants';
import QualityItem from './QualityItem';
import '../setting.css';

const ListQuality = ({ photos, qualities, edit, deleteFile }) => {
  const findIndexQuality = (val) => {
    let find = false;
    let index = 0;
    qualities.forEach(async (element, i) => {
      if (element.name === val) {
        find = true;
        index = i;
      }
    });
    return { find: find, index: index };
  };
  const renderQuality = () =>
    qualitie.map((val, i) => {
      return (
        <QualityItem
          key={i}
          edit={edit}
          delete={deleteFile}
          find={findIndexQuality(val).find}
          quality={val}
          photo={photos[findIndexQuality(val).index]}
          index={findIndexQuality(val).index}
        />
      );
    });

  return <div className="row text-center">{renderQuality()}</div>;
};

export default ListQuality;
