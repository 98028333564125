/* eslint-disable react/jsx-no-duplicate-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { editCompany } from '../../../../actions/company.actions';
import messages from '../../../../components/Admin/messages';
import { countries, qualities, states } from '../../../../constants';
import Card from '../../../../components/Card';
import {
  getCompany,
  getDocCompany,
  uploadFileQualityProgram,
} from '../../../../services/company.service';
import extractData from '../../../../services/data.processing';
import ListQuality from './ListQuality';
import ProfileDetails from './ProfileDetails';
import VeterinaryDetails from './VeterinaryDetails';
import VeterinaryOfficeDetails from './VeterinaryOfficeDetails';
import { isVETDetailsChanged } from './utils';
import '../setting.css';
import { isEmpty } from 'lodash';
import { specificRoles } from '../../../../constants';

const optionsTypesUse = [
  {
    value: 'RM1 - Mastkaelber bis 8 Mo, ggf. mitteilungspfl.',
    label: 'RM1 - Mastkaelber bis 8 Mo, ggf. mitteilungspfl.',
  },
  {
    value: 'RM2 - Mast ab 8 Mo, ggf. mitteilungspfl.',
    label: 'RM2 - Mast ab 8 Mo, ggf. mitteilungspfl.',
  },
  {
    value: 'RN3 - sonstige, nicht mitteilungspflichtig.',
    label: 'RN3 - sonstige, nicht mitteilungspflichtig.',
  },
];
class TabCompany extends React.Component {
  constructor(props) {
    super(props);
    this.countries = countries;
    this.states = states;
    this.qualitesProgram = qualities;
    this.state = {
      role: '',
      company: {},
      company_owner: {},
      company_adress: {},
      agriculturist_extra: {},
      docs: {},
      qualite_program: [],
      tabPhotos: [],
      submitted: false,
      selectedOptionTypeUser: [],
      business_type: 0,
    };
  }

  componentDidMount() {
    this.getCompany();
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevProps !== this.props) {
      this.getCompany();
    }
  }

  async getCompany() {
    let token = this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;

    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const user = await extractData(value);

      if (typeof user !== 'undefined') {
        this.setState({
          role: this.props.users.user.role,
          business_type:
            typeof user !== 'undefined' ? parseInt(user.business_type) : '',
          company: user,
          company_owner: user.owner,
          company_adress: user.address,
          agriculturist_extra: user.agriculturist_extra,
          qualite_program: user.program_quality,
          selectedOptionTypeUser: optionsTypesUse.filter(
            (e) => user.usage.indexOf(e.label) > -1,
          ),
        });

        const docs = user.program_quality.map(
          async (i) => await getDocCompany(id, token, i.certificate_id),
        );

        this.setState({ tabPhotos: [] });
        for (let index = 0; index < docs.length; index++) {
          const element = await docs[index];
          await this.setState((prevState) => ({
            tabPhotos: [...prevState.tabPhotos, element],
          }));
        }
      }
    }
  }

  uploadQualities = async (selectorFiles, quality) => {
    let accessToken = this.props.users.accessToken;
    let id_company = this.props.users.user.session.company_id;
    await uploadFileQualityProgram(
      id_company,
      accessToken,
      selectorFiles[0],
      quality,
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState((prevState) => ({
          qualite_program: [
            ...prevState.qualite_program,
            { certificate_id: data.data._id, name: data.data.program_quality },
          ],
        }));
      });
  };

  deleteFile = (index) => {
    let tab = this.state.qualite_program;
    tab.splice(index, 1);
    this.setState({ qualite_program: tab });
  };

  renderListQualities = () => {
    return (
      <div className="quality-program-content">
        {[1, 2, 3, 4].indexOf(this.state.business_type) !== -1 && (
          <ListQuality
            uploadQualities={this.uploadQualities}
            qualities={this.state.qualite_program}
            photos={this.state.tabPhotos}
            deleteFile={this.deleteFile}
          />
        )}
      </div>
    );
  };

  // functions from FormCompany
  handleSubmit = async (e, formData) => {
    e.preventDefault();
    const owner = {
      firstname: this.state.company.owner.firstname,
      surname: this.state.company.owner.surname,
      email: this.state.company.owner.email,
      company_password: '',
    };

    let address = {
      city: formData.city,
      street: formData.street,
      zip_code: formData.zip_code,
      federal_state: this.state.company.address.federal_state,
      country: this.state.company.address.country,
    };

    let company = {
      ...this.state.company,
      owner,
      address,
      name: formData.name,
      phone_number: this.state.company.phone_number,
      fax_number: this.state.company.fax_number,
      program_quality: this.state.qualite_program,
      agriculturist_extra: this.state.company.agriculturist_extra,
    };
    company.agriculturist_extra.veterinary_id = '';
    if ([2, 3, 4].indexOf(this.state.business_type) !== -1) {
      company = {
        ...company,
        approval_number: formData.approval_number,
      };
    }
    if ([1].indexOf(this.state.business_type) !== -1) {
      let usage = this.state.selectedOptionTypeUser.map((e) => e.value);
      company = {
        ...company,
        usage,
      };
    }
    delete company.update_pin;
    await this.props.editCompany(company);
  };

  // functions from FormCompany
  handleSubmitVet = async (e, formData) => {
    e.preventDefault();
    let company = {
      ...this.state.company,
    };
    company.owner.company_password = '';

    if ([1].indexOf(this.state.business_type) !== -1) {
      let usage = this.state.selectedOptionTypeUser.map((e) => e.value);
      let isVetUpdated = isVETDetailsChanged(
        this.state.company.agriculturist_extra,
        formData,
      );
      let agriculturist_extra = {
        veterinary_address: formData.veterinary_address,
        veterinary_city: formData.veterinary_city,
        veterinary_firstname: formData.veterinary_firstname,
        veterinary_lastname: formData.veterinary_lastname,
        veterinary_practitioner: formData.veterinary_practitioner,
        veterinary_zip_code: formData.veterinary_zip_code,
        veterinary_id: isVetUpdated
          ? ''
          : this.state.company.agriculturist_extra.veterinary_id,
      };
      company = {
        ...company,
        usage,
        agriculturist_extra,
      };
    }

    delete company.update_pin;
    await this.props.editCompany(company);
  };

  // functions from FormCompany
  handleSubmitVetOffice = async (e, formData) => {
    e.preventDefault();
    let company = {
      ...this.state.company,
    };
    company.agriculturist_extra.veterinary_practitioner =
      formData.veterinary_practitioner;
    company.owner.company_password = '';

    delete company.update_pin;
    await this.props.editCompany(company);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  renderOptions = (list, selected) =>
    list.map((val, i) => (
      <option
        key={i}
        value={val.value}
        selected={val.value === selected ? true : false}
      >
        {val.label}
      </option>
    ));

  onChange = (selectedOptionTypeUser) => {
    this.setState({ selectedOptionTypeUser });
  };

  render() {
    let { role } = this.props.users.user;
    return (
      <div className="new-company-user">
        <div className="new-company-user-block">
          <Container fluid>
            <Row>
              <Col md={6} md={12}>
                <ValidationForm onSubmit={this.handleSubmit}>
                  <div
                    className={
                      specificRoles.ROLE_FOR_COMP_SETTING.indexOf(role) > -1
                        ? 'hidden'
                        : ''
                    }
                  >
                    <Card
                      title={
                        <Col className="card-title">
                          <FormattedMessage
                            {...messages.GeneralInformationMessage}
                          />
                        </Col>
                      }
                      content={
                        <Row style={{ height: '100%' }}>
                          <Col
                            style={{ width: '100%' }}
                            className={
                              specificRoles.ROLE_FOR_COMP_PROFILE_DETAILS.indexOf(
                                role,
                              ) > -1
                                ? 'hidden'
                                : 'form-info'
                            }
                          >
                            {!isEmpty(this.state.company_adress) && (
                              <ProfileDetails
                                company={this.state.company}
                                businessType={this.state.business_type}
                                companyOwner={this.state.company_owner}
                                companyAdress={this.state.company_adress}
                                renderOptions={this.renderOptions}
                                selectedOptionTypeUser={
                                  this.state.selectedOptionTypeUser
                                }
                                optionsTypesUse={optionsTypesUse}
                                onChange={this.onChange}
                              />
                            )}
                          </Col>
                        </Row>
                      }
                    />
                  </div>
                </ValidationForm>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="new-company-user-block">
          {this.state.business_type === 1 && (
            <Container fluid>
              <div>
                <Col md={6} md={12}>
                  <ValidationForm onSubmit={this.handleSubmitVet}>
                    <div
                      className={
                        specificRoles.ROLE_FOR_COMP_SETTING.indexOf(role) > -1
                          ? 'hidden'
                          : ''
                      }
                    >
                      <Card
                        title={
                          <Col md={6} className="card-title">
                            <FormattedMessage
                              {...messages.vetPractitionerTitle}
                            />
                          </Col>
                        }
                        content={
                          <Row style={{ height: '100%' }}>
                            <Col
                              style={{ width: '100%' }}
                              className="form-vet"
                              className={
                                specificRoles.ROLE_FOR_COMP_PROFILE_DETAILS.indexOf(
                                  role,
                                ) > -1
                                  ? 'hidden'
                                  : 'form-info'
                              }
                            >
                              {!isEmpty(this.state.company_adress) && (
                                <VeterinaryDetails
                                  company={this.state.company}
                                  businessType={this.state.business_type}
                                  selectedOptionTypeUser={
                                    this.state.selectedOptionTypeUser
                                  }
                                  optionsTypesUse={optionsTypesUse}
                                  onChange={this.onChange}
                                />
                              )}
                            </Col>
                          </Row>
                        }
                      />
                    </div>
                  </ValidationForm>
                </Col>
              </div>
            </Container>
          )}
          {this.state.business_type === 3 && (
            <Container fluid>
              <div>
                <Col md={6} md={12}>
                  <ValidationForm onSubmit={this.handleSubmitVetOffice}>
                    <div
                      className={
                        specificRoles.ROLE_FOR_COMP_SETTING.indexOf(role) > -1
                          ? 'hidden'
                          : ''
                      }
                    >
                      <Card
                        title={
                          <Col md={6} className="card-title">
                            <FormattedMessage
                              {...messages.VeterinarianOffice}
                            />
                          </Col>
                        }
                        content={
                          <Row style={{ height: '100%' }}>
                            <Col
                              style={{ width: '100%' }}
                              className="form-vet"
                              className={
                                specificRoles.ROLE_FOR_COMP_PROFILE_DETAILS.indexOf(
                                  role,
                                ) > -1
                                  ? 'hidden'
                                  : 'form-info'
                              }
                            >
                              {!isEmpty(this.state.company_adress) && (
                                <VeterinaryOfficeDetails
                                  company={this.state.company}
                                />
                              )}
                            </Col>
                          </Row>
                        }
                      />
                    </div>
                  </ValidationForm>
                </Col>
              </div>
            </Container>
          )}
        </div>
      </div>
    );
  }
}

TabCompany.propTypes = {
  users: PropTypes.object,
  getCompany: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    editCompany: (data) => dispatch(editCompany(data)),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabCompany);
