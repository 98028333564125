//const BASE_URL = 'http://localhost:8080';
const BASE_URL = process.env.REACT_APP_BASE_URL
// 
export const configConstants = {
  Company: BASE_URL + '/api/v1/companies',
  CompanyNewVersion: BASE_URL + '/api/v2/companies',
  Auth: BASE_URL + '/api/v1/auths',
  User: BASE_URL + '/api/v1/users',
  Notification: BASE_URL + '/api/v1/notifications',
  Order: BASE_URL + '/api/v1/orders',
  OrderNewVersion: BASE_URL + '/api/v2/orders',
  Stock: BASE_URL + '/api/v1/stocks',
  Invoices: BASE_URL + '/api/v1/invoices',

  ROLE_AGR: [
    { value: 'ROLE_AGR_MANAGER', label: 'Manager' },
    { value: 'ROLE_AGR_STABLE_WORKER', label: 'Stable worker' },
  ],

  ROLE_TRD: [
    { value: 'ROLE_TRD_MANAGER', label: 'Manager' },
    { value: 'ROLE_TRD_OFFICE_WORKER', label: 'Office worker' },
    { value: 'ROLE_TRD_DRIVER', label: 'Driver' },
  ],

  ROLE_ABA: [
    { value: 'ROLE_ABA_PURCHASER', label: 'Purchaser' },
    { value: 'ROLE_ABA_EMPLOYEE', label: 'Employee' },
  ],

  ROLE_VET: [
    { value: 'ROLE_VET_MANAGER', label: 'Manager' },
    { value: 'ROLE_VET_STABLE_WORKER', label: 'Stable worker' },
    { value: 'ROLE_VET_EMPLOYEE', label: 'Employee' },
  ],

  ROLE_AGR_DE: [
    { value: 'ROLE_AGR_MANAGER', label: 'Einkäufer' },
    { value: 'ROLE_AGR_STABLE_WORKER', label: 'Stallmitarbeiter' },
  ],

  ROLE_TRD_DE: [
    { value: 'ROLE_TRD_MANAGER', label: 'Einkäufer' },
    { value: 'ROLE_TRD_OFFICE_WORKER', label: 'Büroangestellter' },
    { value: 'ROLE_TRD_DRIVER', label: 'Fahrer' },
  ],

  ROLE_ABA_DE: [
    { value: 'ROLE_ABA_PURCHASER', label: 'Einkäufer' },
    { value: 'ROLE_ABA_EMPLOYEE', label: 'Mitarbeiter' },
  ],

  ROLE_VET_DE: [
    { value: 'ROLE_VET_MANAGER', label: 'Arbeitnehmer' },
    { value: 'ROLE_VET_STABLE_WORKER', label: 'Stallmitarbeiter' },
    { value: 'ROLE_VET_EMPLOYEE', label: 'Mitarbeiter' },
  ],

  BUSINESS_TYPE: [
    { value: '1', label: 'Agriculturist' },
    { value: '2', label: 'Trader' },
    { value: '3', label: 'Abbatoir' },
    { value: '4', label: 'Transporter' },
    { value: '5', label: 'Veterinarian' },
    { value: '6', label: 'Private Veterinarian' },
  ],

  BUSINESS_TYPE_DE: [
    { value: '1', label: 'Landwirt' },
    { value: '2', label: 'Händler' },
    { value: '3', label: 'Schlachthof' },
    { value: '4', label: 'Spedition' },
    { value: '5', label: 'Veterinäramt' },
    { value: '6', label: 'Hoftierarzt' },
  ],

  // ROLE_ABA: [
  //   { value: 'ROLE_TRT_BOOKER', label: 'Booker' },
  //   { value: 'ROLE_TRT_DRIVER', label: 'Driver' },
  // ],
};
