import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'formik';
import { Col, Row } from 'react-bootstrap';
import messages from '../../../../../components/Admin/messages';
import RegularInput from '../../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../../Shared/components/buttons/NormalButton/NormalButton';
import './form.css';

class ToBeLoadedForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeOfSale: '',
    };
  }

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      transportCompany,
      vehicleNumber,
      driverName,
    } = this.props;
    return (
      <Form>
        {/* <hr className="style-border" /> */}
        <Row className="to-be-loaded-form">
          <Col md={12}>
            <RegularInput
              id="transportCompany"
              name="transportCompany"
              onChange={handleChange}
              value={transportCompany}
              onBlur={handleBlur}
              required
              label={<FormattedMessage {...messages.TransporterName} />}
              type="text"
            />
          </Col>
          <Col md={12}>
            <RegularInput
              id="vehicleNumber"
              name="vehicleNumber"
              onChange={handleChange}
              value={
                vehicleNumber !== '' ? vehicleNumber : values.vehicleNumber
              }
              onBlur={handleBlur}
              required
              label={<FormattedMessage {...messages.VehicleNumber} />}
              type="text"
            />
          </Col>
          <Col md={12}>
            <RegularInput
              id="driverName"
              name="driverName"
              onChange={handleChange}
              value={driverName !== '' ? driverName : values.driverName}
              onBlur={handleBlur}
              required
              label={<FormattedMessage {...messages.DriverName} />}
              type="text"
            />
          </Col>
          <div className="button-block-field">
            <NormalButton type="submit">
              <FormattedMessage {...messages.Next} />
            </NormalButton>
          </div>
        </Row>
      </Form>
    );
  }
}

export default ToBeLoadedForm;

// eslint-disable-next-line no-lone-blocks
{
  /* <Button className={isValid ? `valid-next` : `truck-complete`} type="submit" onClick={() => this.handleSubmit()}></Button> */
}
