import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Link } from 'react-router-dom';
const Footer = () => (
  <div className="footer-section section">
    <Container>
      <Row>
        <Col md={12}>
          <Col md={4}>
            <p
              style={{
                color: '#6f6f6f',
                fontSize: '22px',
                marginBottom: '25px',
                fontWeight: 'bold',
              }}
            >
              Navigieren
            </p>
            <ul className="footer-navigation">
              <a className="nav-item" eventKey={1} href="home">
                <FormattedMessage {...messages.homeMenuMessage} />
              </a>
              <a className="nav-item" eventKey={2} href="about">
                <FormattedMessage {...messages.aboutMenuMessage} />
              </a>
              <a className="nav-item" eventKey={3} href="features">
                <FormattedMessage {...messages.featuresMenuMessage} />
              </a>
            </ul>
          </Col>

          <Col md={4}>
            <p
              style={{
                color: '#6f6f6f',
                fontSize: '22px',
                marginBottom: '25px',
                fontWeight: 'bold',
              }}
            >
              Kontakt:
            </p>
            <ul className="footer-navigation">
              <li>kontakt@agrabiz.de</li>
            </ul>
          </Col>
        </Col>
        <Col md={12} style={{ marginTop: 50 }}>
          <Col md={6}>
            <ul className="footer-links inline-list">
              <li>
                <FormattedMessage {...messages.copyright} />
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <ul className="footer-links inline-list">
              <li>
                <a
                  target="_blank"
                  href="/datenschutz"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.policies} />
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <ul className="footer-links inline-list">
              <li>
                <Link target="_blank" to="/impressum" rel="noopener noreferrer">
                  <FormattedMessage {...messages.impressum} />
                </Link>
              </li>
            </ul>
          </Col>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
