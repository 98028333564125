import React from 'react';
import { connect } from 'react-redux';
import './tourManagementInfo.css';
import TourListAsPopup from './TourListAsPopup';
import { alertActions, OrderActions } from '../../../../actions';
import { Modal } from 'react-bootstrap';

const TourManagementPopup = (props) => {
  const {
    orderDetail,
    setShowForm,
    orderType,
    type,
    handleClosePanel,
    assigned,
    ordersTab,
    pushNotification,
    getToursDate,
    changeTab,
    changeDate,
    getOrders,
    displayBuyRequestEdit,
    showDetail,
  } = props;
  return (
    <Modal show={showDetail} onHide={handleClosePanel} style={{ opacity: 1 }}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ width: '820px', height: '720px' }}>
        {typeof assigned === 'undefined' ? (
          <TourListAsPopup
            orderDetail={orderDetail}
            setShowForm={setShowForm}
            token={props.users.accessToken}
            company_id={props.users.user.session.company_id}
            orderType={orderType}
            typeOrder={type}
            handleClosePanel={handleClosePanel}
            usersInfo={props.users}
            changeTab={changeTab}
            changeDate={changeDate}
            getOrders={getOrders}
            displayBuyRequestEdit={displayBuyRequestEdit}
          />
        ) : (
          <TourListAsPopup
            orderDetail={orderDetail}
            ordersTab={ordersTab}
            setShowForm={setShowForm}
            token={props.users.accessToken}
            company_id={props.users.user.session.company_id}
            orderType={orderType}
            typeOrder={type}
            handleClosePanel={handleClosePanel}
            assigned={true}
            usersInfo={props.users}
            pushNotification={
              typeof pushNotification !== 'undefined' ? 'true' : ''
            }
            getToursDate={getToursDate}
            changeTab={changeTab}
            changeDate={changeDate}
            getOrders={getOrders}
            displayBuyRequestEdit={displayBuyRequestEdit}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    // getOrders: (orderType, type, page, date) =>
    //   dispatch(OrderActions.getOrders(orderType, type, page, date)),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TourManagementPopup);
