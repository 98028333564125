import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from '../../Admin/messages';
import './confirm-move-modal.scss';

const ConfirmCancel = ({
  openMoveModal,
  hideMoveAction,
  moveToNextBoxConfirm,
  ...props
}) => {
  return (
    <Modal
      show={openMoveModal}
      onHide={hideMoveAction}
      aria-labelledby="contained-modal-title"
      className="confirm-move-modal"
      style={{ opacity: 1 }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <FormattedMessage {...messages.ConfirmMoveToNextBoxModal} />
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-btn" onClick={hideMoveAction}>
          <FormattedMessage {...messages.CancelMove} />
        </button>
        <button className="confirm-btn" onClick={moveToNextBoxConfirm}>
          <FormattedMessage {...messages.ConfirmMove} />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmCancel.propTypes = {
  openMoveModal: PropTypes.bool,
  hideMoveAction: PropTypes.func,
  moveToNextBoxConfirm: PropTypes.func,
  children: PropTypes.node,
};
export default ConfirmCancel;
