import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../components/Admin/messages';

export default class UserItem extends Component {
  render() {
    const { data, edit, del, language } = this.props;

    const getwork = (label) => {
      if (label === 'ROLE_AGR_MANAGER') return language.locale === 'EN' ? `Manager  ` : `Einkäufer`;
      if (label === 'ROLE_AGR_STABLE_WORKER') return language.locale === 'EN' ? `Stable worker` : `Stallmitarbeiter`;

      if (label === 'ROLE_TRD_MANAGER') return language.locale === 'EN' ? `Manager  ` : `Einkäufer`;
      if (label === 'ROLE_TRD_OFFICE_WORKER') return language.locale === 'EN' ? `Office worker` : `Büroangestellter`;;
      if (label === 'ROLE_TRD_DRIVER')
        return language.locale === 'EN' ? `Driver  ` : `Fahrer`;

      if (label === 'ROLE_ABA_PURCHASER') return language.locale === 'EN' ? `Purchaser` : `Einkäufer`;;
      if (label === 'ROLE_ABA_EMPLOYEE') return language.locale === 'EN' ? `Employee` : `Mitarbeiter`;;

      if (label === 'ROLE_VET_MANAGER') return language.locale === 'EN' ? `Manager  ` : `Einkäufer`;
      if (label === 'ROLE_VET_OFFICE_WORKER') return language.locale === 'EN' ? `Office worker` : `Büroangestellter`;;
      if (label === 'ROLE_VET_DRIVER')
        return language.locale === 'EN' ? `Driver  ` : `Fahrer`;
      else return '';
    };

    return (
      <div className="col-md-2 user-item" style={{ width: '256px' }}>
        <div className="box-action">
          <div className="row">
            <div className="col-sm-6 cursor-pointer">
              <div className="box-icon1">
                <div className=" update-icon padding-icon" onClick={edit}></div>
              </div>
            </div>
            <div className="col-sm-6 cursor-pointer">
              <div className="box-icon2">
                <div className=" delete-icon" onClick={del}></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="box wow fadeInUp active"
          data-wow-duration=".5s"
          data-wow-delay=".3s"
        >
          <div className="contact-info">
            <div className="row person-title-container">
              <div className="col-sm-4 ">
                <span className="person-icon"></span>
              </div>
              <div className="col-sm-8 person-role-txt">
                <p>{data && getwork(data.role)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
              <h4 className='person-txt3'>{data && data.name}</h4>

                <p className="person-txt">
                  {' '}
                  <FormattedMessage {...messages.UserPhoneNbr} />:
                </p>
                <p className="person-txt">{data && data.phone_number}</p>
                <p className="person-txt">
                  {' '}
                  <FormattedMessage {...messages.UserEmailNbr} />:
                </p>
                <p className="person-txt">{data && data.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserItem.propTypes = {
  data: PropTypes.object.isRequired,
  edit: PropTypes.func,
  del: PropTypes.func,
};
