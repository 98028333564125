import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cow from '../../../../static/images/cow.svg';
import messages from '../../messages';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import './cattle-group.scss';

const CattleAdd = ({ toggleAddForm, language, ...props }) => {
  return (
    <div className="add-cattle-card">
      <div className="cattle-add-container">
        <div className="cattle-add-icon">
          <img alt="" src={Cow} />
        </div>
      </div>
      <div
        className={clsx(
          language.locale === 'DE' && 'german-text',
          'add-cattle-button',
        )}
        onClick={e => toggleAddForm(e)}
      >
        <FormattedMessage {...messages.AddMoreCattles} />
      </div>
    </div>
  );
};

CattleAdd.propTypes = {
  truckDetails: PropTypes.object,
};

const mapStateToProps = state => {
  const { language } = state.toJS();
  return {
    language,
  };
};

export default connect(
  mapStateToProps,
  null,
)(CattleAdd);
