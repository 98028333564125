import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { MdAddCircle, MdNavigateNext } from 'react-icons/md';
import { Col, Row } from 'react-bootstrap';

const getStatus = (box, passport) => {
  if (box > 0 && passport !== '' && typeof passport !== 'undefined') {
    return 'avaible';
  }
  return 'not-avaible';
};
const formatEarTag = tag => {
  var output =
    tag.substr(0, 2) +
    ' ' +
    tag.substr(2, 2) +
    ' ' +
    tag.substr(3, 3) +
    ' ' +
    tag.substr(6, 5);
  return output;
};

const StockItem = props => (
  <ListGroupItem>
    <Row onClick={props.editItem}>
      <Col xs={2} md={2}>
        <nav className="icon-list-group">
          <MdAddCircle
            className={getStatus(props.data.box, props.data.passport)}
          />
        </nav>
      </Col>
      <Col xs={8} md={8}>
        <nav className={getStatus(props.data.box, props.data.passport)}>
          {formatEarTag(props.data.ear_tag)}
        </nav>
        <nav>Box {props.data.box}</nav>
      </Col>
      <Col xs={2} md={2}>
        <nav className="icon-list-group">
          <MdNavigateNext />
        </nav>
      </Col>
    </Row>
  </ListGroupItem>
);

export default StockItem;
