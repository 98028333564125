/* eslint-disable */
import React from 'react';
import Bought from '../../../static/images/admin/Icons/Status icons/Bought.png';
import Delivered from '../../../static/images/admin/Icons/Status icons/Delivered.png';
import Loaded from '../../../static/images/admin/Icons/Status icons/Loaded.png';
import NegotiationBuyRequest from '../../../static/images/admin/Icons/Status icons/NegotiationBuyRequest.png';
import NegotiationSaleOffer from '../../../static/images/admin/Icons/Status icons/NegotiationSaleOffer.png';
import Offer from '../../../static/images/admin/Icons/Status icons/Offer.png';
import Offered from '../../../static/images/admin/Icons/Status icons/Offered.png';
import Ontheway from '../../../static/images/admin/Icons/Status icons/Ontheway.png';
import Planned from '../../../static/images/admin/Icons/Status icons/Planned.png';
import Rejected from '../../../static/images/admin/Icons/Status icons/Rejected.png';
import Request from '../../../static/images/admin/Icons/Status icons/Request.png';
import Resold from '../../../static/images/admin/Icons/Status icons/Resold.png';
import Slaughered from '../../../static/images/admin/Icons/Status icons/Slaughered.png';
import Sold from '../../../static/images/admin/Icons/Status icons/Sold.png';
import Tobeaccepted from '../../../static/images/admin/Icons/Status icons/Tobeaccepted.png';
import Tobeaccepted2 from '../../../static/images/admin/Icons/Status icons/Tobeaccepted2.png';
import Tobeloaded from '../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import Tobereconfirmed2 from '../../../static/images/admin/Icons/Status icons/Tobereconfirmed2.png';
import Tourplanning from '../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import Unloaded from '../../../static/images/admin/Icons/Status icons/Unloaded.png';
import Orders from '../Orders';

const getSrcImg = (status, receivers_size, topic, is_buyer) => {
  var res = status.split('_').join('');
  res = res.toLowerCase();
  switch (res) {
    case 'bought':
      return Bought;
      break;
    case 'canceled':
      return Rejected;
      break;
    case 'delivered':
      return Delivered;
      break;
    case 'loaded':
      return Loaded;
      break;
    case 'negotiationbuyrequest':
      return NegotiationBuyRequest;
      break;
    case 'negotiation':
      return NegotiationBuyRequest;
      break;
    case 'negotiationsaleoffer':
      return NegotiationSaleOffer;
      break;
    case 'offer':
      return Offer;
      break;
    case 'offered':
      return Offered;
      break;
    case 'ontheway':
      return Ontheway;
      break;
    case 'rejected':
      return Rejected;
      break;
    case 'requested':
      if (receivers_size > 1) return NegotiationBuyRequest;
      else return Request;
      break;
    case 'resold':
      return Resold;
      break;
    case 'slaughtered':
      return Slaughered;
      break;
    case 'sold':
      return Sold;
      break;
    case 'tobeaccepted':
      return Tobeaccepted;
      break;
    case 'tobeaccepted2':
      return Tobeaccepted2;
      break;
    case 'tobeloaded':
      return Tobeloaded;
      break;
    case 'tobereconfirmed':
      if (is_buyer === 1 && topic !== 'BUY_STOCK') return Tobereconfirmed2;
      else return Tobeaccepted;
      break;
    case 'tobereconfirmed2':
      return Tobereconfirmed2;
      break;
    case 'tourplanning':
      return Tourplanning;
      break;
    case 'tourassigned':
      return Tourplanning;
      break;
    case 'unloaded':
      return Unloaded;
      break;
    case 'planned':
      return Planned;
      break;
  }
};

const Buyings = props => <Orders getSrcImg={getSrcImg} orderType={'buyings'} />;

export default Buyings;
