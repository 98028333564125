import { configConstants } from '../constants';
import { put, post, get, del } from './request';

async function getOrders(orderType, accessToken, type, page, date) {
  try {
    const contentType = 'application/json';
    const orders =
      date === ''
        ? await get(
            `${configConstants.Order}/${orderType}?filter=${type}&page=${page}&isWeb=${true}`,
            accessToken,
            contentType,
          )
        : await get(
            `${configConstants.Order}/${orderType}?filter=${type}&page=${page}&date=${date}&isWeb=${true}`,
            accessToken,
            contentType,
          );
    return orders;
  } catch (error) {}
}

async function getOrderDetails(accessToken, orderId, receiverId) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}`,
      token,
      contentType,
    );
    if (orders.status === 200) return orders;
    // eslint-disable-next-line no-throw-literal
    else throw 'COMPANY NOT FOUND';
  } catch (error) {
    throw error;
  }
}

async function getOrderReceivers(accessToken, orderId, filter) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/${orderId}/receivers?filter=${filter}`,
      token,
      contentType,
    );
    return orders;
  } catch (error) {}
}

async function confirmOrder(orderId, accessToken, receiverId, data) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}/confirm`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}

async function getOrderForOneBidder(orderId, accessToken, receiverId) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}`,
      token,
      contentType,
    );
    if (orders.status === 200) return orders;
    // eslint-disable-next-line no-throw-literal
    else throw 'COMPANY NOT FOUND';
  } catch (error) {
    throw error;
  }
}

async function getOpenBuyings(accessToken) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const buyings = await get(
      `${configConstants.Order}/buyings/bought`,
      token,
      contentType,
    );
    if (buyings.status === 200) return buyings;
    // eslint-disable-next-line no-throw-literal
    else throw 'OPEN BUYINGS NOT FOUND';
  } catch (error) {
    throw error;
  }
}

async function getReport(accessToken) {
  try {
    const contentType = 'application/json';
    const file = await get(
      `${configConstants.Order}/slaughter_history`,
      accessToken,
      contentType,
    );
    return file;
  } catch (error) {}
}

async function setReport(accessToken, file) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'multipart/form-data';
    let data = { file: file };
    return await post(
      `${configConstants.Order}/slaughter_information`,
      contentType,
      token,
      data,
    );
  } catch (error) {}
}

async function cancelOrder(accessToken, orderId, receiverId) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}/cancel`,
      contentType,
      accessToken,
      {},
    );
    return orders;
  } catch (error) {}
}

async function rejectOrder(accessToken, orderId, receiverId) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}/reject`,
      contentType,
      accessToken,
      {},
    );
    return orders;
  } catch (error) {}
}

async function buyStock(accessToken, data) {
  try {
    const contentType = 'application/json';
    const order = await post(
      `${configConstants.Order}/buy_stock`,
      contentType,
      accessToken,
      data,
    );
    return order;
  } catch (error) {}
}
async function setDeliveriesPDF(accessToken, date, time) {
  try {
    const contentType = 'application/pdf';
    const resp = await get(
      `${configConstants.Order}/buyings/pdf?date=${date}&time=${time}`,
      accessToken,
      contentType,
      'arraybuffer',
    );
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch (error) {
  }
}

async function editBuyStock(accessToken, orderId, data) {
  try {
    const contentType = 'application/json';
    const order = await put(
      `${configConstants.Order}/${orderId}/buy_stock`,
      contentType,
      accessToken,
      data,
    );
    return order;
  } catch (error) {}
}

async function sellStock(accessToken, data) {
  const contentType = 'application/json';
  const order = await post(
    `${configConstants.Order}/sell_stock`,
    contentType,
    accessToken,
    data,
  );
  return order;
}

async function acceptOrder(orderId, accessToken, receiverId, data) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}/accept`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}

async function continueAsExternal(orderId, receiverId, accessToken) {
  try {
    const contentType = 'application/json';
    const order = await put(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}/external`,
      contentType,
      accessToken,
    );
    return order;
  } catch (error) {}
}

async function reconfirmOrder(orderId, accessToken, receiverId, data) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/receivers/${receiverId}/reconfirm`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}

async function getTourPlanning(accessToken, orderId, isCattleModuleRelated = 0) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/${orderId}/tour_planning?isCattleModuleRelated=${isCattleModuleRelated}`,
      token,
      contentType,
    );
    return orders;
  } catch (error) {}
}

async function getTourPlanningById(accessToken, orderId, truckId) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/${orderId}/tour_planning?truckId=${truckId}`,
      token,
      contentType,
    );
    return orders;
  } catch (error) {}
}

async function editTourPlanning(accessToken, orderId, data) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/tour_planning`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}

async function editTourPlanningByNumber(accessToken, orderId, data) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.OrderNewVersion}/${orderId}/tour_planning`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}

async function getLoadingData(accessToken, orderId) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/${orderId}/loading_data`,
      token,
      contentType,
    );
    return orders;
  } catch (error) {}
}

async function editLoadingData(accessToken, orderId, data) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/loading_data`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}

async function postLoadingDataPrepareDocumentation(accessToken, orderId, data) {
  try {
    const contentType = 'application/json';
    const res = await post(
      `${configConstants.Order}/${orderId}/loading_data/prepare_documents`,
      contentType,
      accessToken,
      data,
    );
    return res;
  } catch (error) {}
}

async function getPDFLoad(accessToken, fileId) {
  try {
    const contentType = 'application/pdf';
    const resp = await get(
      `${configConstants.Order}/pdf/${fileId}`,
      accessToken,
      contentType,
      'arraybuffer',
    );
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch (error) {}
}

async function deleteOrder(accessToken, orderId) {
  try {
    return await del(`${configConstants.Order}/${orderId}`, accessToken);
  } catch (error) {}
}

async function addToOwnStock(accessToken, orderId) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = { orders: JSON.stringify(orderId) };
    return put(
      `${configConstants.Order}/add_to_own_stock`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

async function Resale(accessToken, data) {
  try {
    const contentType = 'application/json';
    return post(
      `${configConstants.Order}/resale_stock`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

async function getTours(accessToken, orderId, truckId, users) {
  try {
    if ((users.user.session.company_type === '5' || users.user.session.company_type === '6') && typeof users.user.companies['0'] !== "undefined" && typeof users.user.companies['0'].company_id !== "undefined") {
      let abbatoirId = users.user.companies['0'].company_id;

      let token = `${accessToken}`.replace(/['"]+/g, '');
      const contentType = 'application/json';
      const filter = 'completed';
      const orders = await get(
        `${configConstants.Order}/tours?filter=${filter}&orderId=${orderId}&abbatoirId=${abbatoirId}`,
        token,
        contentType,
      );
      return orders;

    }
    else {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const filter = 'completed';
    const orders = await get(
      `${configConstants.Order}/tours?filter=${filter}&orderId=${orderId}`,
      token,
      contentType,
    );
    return orders;
  }
  } catch (error) {}
}

async function getToursDate(accessToken, filter, date, users) {
  try {
    if ((users.user.session.company_type === '5' || users.user.session.company_type === '6') && typeof users.user.companies['0'] !== "undefined" && typeof users.user.companies['0'].company_id !== "undefined") {

      let abbatoirId = users.user.companies['0'].company_id;

      let token = `${accessToken}`.replace(/['"]+/g, '');
      const contentType = 'application/json';
      const orders = await get(
        `${configConstants.OrderNewVersion}/tours?filter=${filter}&date=${date}&abbatoirId=${abbatoirId}`,
        token,
        contentType,
      );
      return orders;
    }
    else {
      let token = `${accessToken}`.replace(/['"]+/g, '');
      const contentType = 'application/json';
      const orders = await get(
        `${configConstants.OrderNewVersion}/tours?filter=${filter}&date=${date}`,
        token,
        contentType,
      );
      return orders;
    }

  } catch (error) { }
}

async function getTourInfo(accessToken, orderId, truckId) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/${orderId}/tour?truckId=${truckId}`,
      token,
      contentType,
    );
    return orders;
  } catch (error) {}
}

async function getResaleOverview(orderId, accessToken) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json';
    const resales = await get(
      `${configConstants.Order}/${orderId}/resale_overview`,
      token,
      contentType,
    );
    return resales;
  } catch (error) {}
}

async function goToTourPlanning(orderId, accessToken) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/${orderId}/go_to_tour_planning`,
      contentType,
      accessToken,
    );
    return orders;
  } catch (error) {}
}

async function editTour(accessToken, orderId, truckId, locations, time, notes) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = {
      truckId: truckId,
      locations: JSON.stringify(locations),
    };
    const editTour = await put(
      `${configConstants.Order}/${orderId}/tour`,
      contentType,
      accessToken,
      data,
    );
    return editTour;
  } catch (error) {}
}

async function editTourCattles(
  accessToken,
  orderId,
  cattleDeliveryDate,
  cattleDeliveryTime,
  cattles,
) {
  try {
    const contentType = 'application/json';
    const data = {
      deliveryDate: cattleDeliveryDate,
      deliveryTime: cattleDeliveryTime,
      cattle_group: JSON.stringify(cattles),
    };
    return await put(
      `${configConstants.Order}/${orderId}/tour/cattles`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

async function editTourDelivery(accessToken, orderId, truckId, time, signature = '') {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = {
      truckId: truckId,
      time: time,
      signature: signature
    };
    const editTour = await put(
      `${configConstants.Order}/${orderId}/tour`,
      contentType,
      accessToken,
      data,
    );
    return editTour;
  } catch (error) {}
}

async function editTourTimes(
  accessToken,
  orderId,
  truckId,
  deliveryTime,
  unloadingTime,
) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = {
      truckId: truckId,
      deliveryTime: deliveryTime,
      unloadingTime: unloadingTime,
    };
    const editTour = await put(
      `${configConstants.Order}/${orderId}/tour/times`,
      contentType,
      accessToken,
      data,
    );
    return editTour;
  } catch (error) {}
}

async function editTourDeliveryAndNotes(
  accessToken,
  orderId,
  truckId,
  time,
  notes,
) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = {
      truckId: truckId,
      time: time,
      notes: notes || '',
    };
    const editTour = await put(
      `${configConstants.Order}/${orderId}/tour`,
      contentType,
      accessToken,
      data,
    );
    return editTour;
  } catch (error) {}
}

async function getToursManagement(accessToken, date) {
  try {
    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/tours_management?date=${date}`,
      accessToken,
      contentType,
    );

    return orders;
  } catch (error) {}
}

async function UpdateNotes(
  accessToken,
  orderId,
  notes,
  delivery_date,
  delivery_time,
) {
  try {
    const contentType = 'application/x-www-form-urlencoded';

    const data = {
      notes: notes,
      delivery_date: delivery_date,
      delivery_time: delivery_time,
    };
    const editTour = await put(
      `${configConstants.Order}/${orderId}`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

async function SaveTourPlanning(accessToken, date, save, data) {
  try {
    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/tours_management/finalize?date=${date}&save=${save}`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}

async function getPDFTour(accessToken, orderId, truckId) {
  try {
    const contentType = 'application/json';
        const resp = await get(
      `${configConstants.Order}/tours_management/pdf?orderId=${orderId}&truckId=${truckId}`,
      accessToken,
      contentType,
      'arraybuffer',
    );
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch (error) {}
}

async function sendEmail(accessToken, date, email) {
  try {
    const data = {};

    const contentType = 'application/json';
    const orders = await put(
      `${configConstants.Order}/tours_management/send_email?date=${date}&email=${email}`,
      contentType,
      accessToken,
      data,
    );
    return orders;
  } catch (error) {}
}


async function getCvsFile(accessToken, min_date, max_date) {
  try {
    const data = {};

    const contentType = 'application/json';
    const orders = await get(
      `${configConstants.Order}/reports/csv?min_date=${min_date}&max_date=${max_date}`,
      accessToken,
      contentType,
      data,
    );
    return orders;
  } catch (error) {}
}

async function getPDFSlaugther(accessToken, orderId, truckId) {
  try {
    const contentType = 'application/json';
        const resp = await get(
      `${configConstants.Order}/slaughter_report/pdf?orderId=${orderId}&truckId=${truckId}`,
      accessToken,
      contentType,
      'arraybuffer',
    );
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch (error) {}
}


async function getBioCertificate(accessToken, orderId, truckId) {
  try {
    const contentType = 'application/json';
        const resp = await get(
      `${configConstants.Order}/bio_certificate?orderId=${orderId}&truckId=${truckId}`,
      accessToken,
      contentType,

    );
    return resp;
  } catch (error) {}
}

async function getSecondSignateurData(accessToken, orderId, truckId) {
  try {
    const contentType = 'application/json';
        const resp = await get(
      `${configConstants.Order}/getTourSignateurData?orderId=${orderId}&truckId=${truckId}`,
      accessToken,
      contentType,

    );
    return resp;
  } catch (error) {}
}

export const OrderService = {
  getOrders,
  getOpenBuyings,
  continueAsExternal,
  getOrderDetails,
  getOrderReceivers,
  confirmOrder,
  getOrderForOneBidder,
  getReport,
  setReport,
  cancelOrder,
  reconfirmOrder,
  rejectOrder,
  buyStock,
  editBuyStock,
  sellStock,
  acceptOrder,
  getTourPlanning,
  getTourPlanningById,
  editTourPlanning,
  editTourPlanningByNumber,
  getLoadingData,
  editLoadingData,
  postLoadingDataPrepareDocumentation,
  getPDFLoad,
  deleteOrder,
  addToOwnStock,
  Resale,
  getTours,
  getTourInfo,
  getResaleOverview,
  goToTourPlanning,
  editTour,
  editTourDelivery,
  editTourDeliveryAndNotes,
  editTourCattles,
  editTourTimes,
  getToursDate,
  setDeliveriesPDF,
  getToursManagement,
  UpdateNotes,
  SaveTourPlanning,
  getPDFTour,
  sendEmail,
  getCvsFile,
  getPDFSlaugther,
  getBioCertificate,
  getSecondSignateurData
};
