import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, ListGroup } from 'react-bootstrap';
import ReactGA from 'react-ga';
import {
  sendStocksToHit,
  getStockFromHit,
} from '../../../services/stock.service';
import extractData from '../../../services/data.processing';
import { connect } from 'react-redux';
import StockItem from './StockItem';
import AddStock from './AddStock';
import Form from './Form';
import { alertActions } from '../../../actions';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';
import './addStock.css';

class NewStock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stocks: [],
      stockSelected: {},
      ear_tag: '',
      type: 'birth',
      showForm: false,
      indexSelected: null,
      file: null,
      race: '',
      breed: '',
    };
  }

  handleChangeEarTag = value => {
    this.setState({ ear_tag: value });
  };

  handleChangeEarTagFianl = value => {
    this.setState({ ear_tag: value });
  };
  handleChangeRace = value => {
    this.setState({ race: value });
  };
  handleChangeBreed = value => {
    this.setState({ breed: value });
  };

  UNSAFE_componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  getStockFromHit = async () => {
    try {
      let token = this.props.users.accessToken;
      const value = await getStockFromHit(token, this.state.ear_tag);

      if (value.status === 200) {
        const data = await extractData(value);

        this.setState({
          stockSelected: data,
        });
        this.setState({
          showForm: !this.state.showForm,
        });
      }
      if (value.status === 400) {
        this.props.error(<FormattedMessage {...messages.EarTagNotFound} />);
        this.props.clear();
 
      }
    } catch (error) {
      this.setState({
        stockSelected: {},
      });
      this.props.error(error);
      this.props.clear();
    }
  };

  setShowForm = async () => {
    if (!this.state.showForm) {
      if (this.state.type === 'incoming') {
        await this.getStockFromHit();
      } else {
        this.setState({
          showForm: !this.state.showForm,
        });
      }
    } else {
      this.setState({
        showForm: !this.state.showForm,
        stockSelected: {},
      });
    }
  };

  addToListToHit = async animal => {
    let tab = this.state.stocks;
    if (this.state.indexSelected != null) {
      tab[this.state.indexSelected] = animal;
      this.setState({
        indexSelected: null,
      });
    } else {
      tab.push(animal);
    }
    this.setState({
      stocks: tab,
    });
    this.setState({
      showForm: !this.state.showForm,
    });
  };

  editItem = async i => {
    this.setState({
      showForm: false,
      indexSelected: i,
    });

    let animal = Object.create(this.state.stocks[i]);
    animal.mother_ear_tag = animal.mother_ear_tag_output_format;
    this.setState({ ear_tag: animal.ear_tag });
    await this.setState({ stockSelected: animal });
    await this.setState({ type: animal.type });

    this.setState({
      showForm: true,
    });
  };

  choseType = e => {
    this.setState({ type: e });
  };

  sendToHit = async () => {
    try {
      let token = this.props.users.accessToken;
      let dataToHit = this.state.stocks;
      let files = [];
      for (let index = 0; index < dataToHit.length; index++) {
        delete dataToHit[index].mother_ear_tag_output_format;
        delete dataToHit[index].ear_tag_output_format;
        delete dataToHit[index].is_available;
        delete dataToHit[index].maxLengthEarTagMother;
        if (dataToHit[index].file != null) {
          files.push(dataToHit[index].file);
        }
        delete dataToHit[index].file;
      }
      const value = await sendStocksToHit(token, dataToHit, files);
      if (value.status !== 401) {
        if (value.status === 200) {
          const data = await extractData(value);
          if (data.error_lines.length > 0) {
            this.props.error(
              <FormattedMessage {...messages.InvalidEarTagError} />,
            );
            this.props.clear();
            this.setState({
              stocks: [],
            });
          }
          if (data.success_lines.length > 0) {
            this.props.success(<FormattedMessage {...messages.NewSEndToHit} />);
            this.props.clear();
            this.setState({
              stocks: [],
            });
          }
        } else {
          this.props.error(<FormattedMessage {...messages.InvalidDataError} />);
          this.props.clear();
        }
      }
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  renderListStocks = () =>
    this.state.stocks.map(
      (animal, i) =>
        !isNaN(animal.box) && (
          <StockItem
            editItem={() => this.editItem(i)}
            index={i}
            key={i}
            data={animal}
          />
        ),
    );

  render() {
    return (
      <Row className="add-stock left-side">
        <Col style={{ height: '100%' }} className="stock-col" md={6} sm={12}>
          <div className="card-box add-stock-left-box panel panel-default">
            <div className="panel-body">
              <ListGroup
                id="send-stock"
                className="list-stock send-stock-top-part"
              >
                {this.state.stocks.length > 0 && this.renderListStocks()}
              </ListGroup>
              <div className="btn-send-stock send-stock-bottom-part">
                <NormalButton
                  onClick={this.sendToHit}
                  type="submit"
                  nature="green"
                  disabled={!this.state.stocks.length > 0}
                >
                  <FormattedMessage {...messages.NewSEndToHit} />
                </NormalButton>
              </div>
            </div>
          </div>
        </Col>
        <Col style={{ height: '100%' }} className="stock-panel" md={6} sm={12}>
          {this.state.showForm ? (
            <Form
              showAlert={msg => this.props.error(msg)}
              addToListToHit={this.addToListToHit}
              setShowForm={this.setShowForm}
              data={this.state.stockSelected}
              type={this.state.type}
              ear_tag={this.state.ear_tag}
              race={this.state.race}
              breed={this.state.breed}
              handleChangeRace={this.handleChangeRace}
              handleChangeBreed={this.handleChangeBreed}
            />
          ) : (
            <AddStock
              handleChangeEarTag={this.handleChangeEarTag}
              handleChangeEarTagFianl={this.handleChangeEarTagFianl}
              choseType={this.choseType}
              type={this.state.type}
              setShowForm={this.setShowForm}
            />
          )}
        </Col>
      </Row>
    );
  }
}

NewStock.propTypes = {
  users: PropTypes.object,
  renderListStocks: PropTypes.func,
  sendToHit: PropTypes.func,
  choseType: PropTypes.func,
  factoryEarTagInverse: PropTypes.func,
  editItem: PropTypes.func,
  addToListToHit: PropTypes.func,
  setShowForm: PropTypes.func,
  getStockFromHit: PropTypes.func,
  handleChangeEarTag: PropTypes.func,
  handleChangeEarTagFianl: PropTypes.func,
  handleChangeRace: PropTypes.func,
  handleChangeBreed: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    success: msg => dispatch(alertActions.success(msg)),
    error: error => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = state => {
  const { users } = state.toJS();
  return { users };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewStock);
