/* eslint-disable array-callback-return */
/* eslint-disable no-fallthrough */
/* eslint-disable */

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { MdNavigateBefore } from 'react-icons/md';
import { Col, Image, Card, ButtonGroup, Button, Modal } from 'react-bootstrap';
import FileSaver from 'file-saver';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import loaded from '../../../../static/images/admin/Icons/Push messages/1.png';
import markergDrapo from '../../../../static/images/admin/icons_final/2.png';
import greenTarget from '../../../../static/images/admin/icons_final/1.png';
import grayLoc from '../../../../static/images/admin/icons_final/marker_gray.png';
import greenLoc from '../../../../static/images/admin/icons_final/marker.png';
import yellowTarget from '../../../../static/images/admin/icons_final/yellow.png';
import arrowImg from '../../../../static/images/admin/arrow-copie-2.png';
import searchImg from '../../../../static/images/admin/search.png';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import { isEmpty } from 'lodash';
import { alertActions, OrderActions } from '../../../../actions';
import { OrderService } from '../../../../services/order.service';
import { userService } from '../../../../services/user.service';
import {
  getPassportStock,
  getStockByIds,
} from '../../../../services/stock.service';
import {
  getcontactByNumber,
  getDocCompany,
} from '../../../../services/company.service';
import extractData from '../../../../services/data.processing';
import messages from '../../messages';
import TruckItem from './TruckItem';
import LocationItem from './LocationItem';
import SlaughterList from './slaughter';
import { capitalize } from '../../../../helpers/order';
import TourManagement from '../tourMangement';
import CattleGroup from './CattleGroup';
import { svgPaths } from '../../../../constants';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import truckToBeLoaded from '../../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import truckLoaded from '../../../../static/images/admin/Icons/Status icons/Loaded.png';
import truckOnTheWay from '../../../../static/images/admin/Icons/Status icons/Ontheway.png';
import truckDelivered from '../../../../static/images/admin/Icons/Status icons/Delivered.png';
import truckSlaughered from '../../../../static/images/admin/Icons/Status icons/Slaughered.png';
import truckUnloaded from '../../../../static/images/admin/Icons/Status icons/Unloaded.png';
import Tourplanning from '../../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import Planned from '../../../../static/images/admin/Icons/Status icons/Planned.png';
import reload from '../../../../static/images/admin/reload.svg';
import moment from 'moment-es6';
import AppMap from './map';
import SignatureSection from './AddSignature';

import EartagsList from './EartagList';

import './tour.css';

class TourInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showTourInfo: true,
      showDocInfo: false,
      showLocInfo: false,
      showAnimalsInfo: false,
      showDoc: false,
      showLocForm: false,
      trucks: [],
      orderId: this.props.orderDetail.order_id,
      data: {},
      isValid: false,
      ear_tags: [],
      vehicleNumber: null,
      driver: '',
      showForm: false,
      showTruckList: false,
      formData: {},
      truck: {},
      truckDetail: {},
      loadingTime: '',
      departureTime: '',
      showDetail: false,
      togglePopupPassword: false,
      passport: '',
      id: '',
      idDoc: '',
      srcTarget: '',
      editLocForm: true,
      editTargetForm: true,
      truckIcon: '',
      unLoadingTime: '',
      deliveryTime: '',
      showTargetForm: false,
      showConfirm: false,
      enableDeliveryTime: false,
      enableDepartureTime: false,
      enableUnloadingTimeAndNotes: false,
      note: '',
      unloadToast: '',
      deliverToast: '',
      departureTimeEdit: true,
      deliveryTimeEdit: true,
      loadingTimeAndNotes: true,
      buttonText: '',
      DepartureId: '',
      displaySlaugher: false,
      pdfEncoded: '',
      cattleGroups: [],
      checkAddCattleForm: true,
      hour: 0,
      min: 0,
      cattleDeliveryDate: new Date(),
      cattleDeliveryTime: '',
      showPopup: false,
      has_slaughter_informations: 0,
      showPopupSlaughter: false,
      showPopupCertificate: false,
      certificateList: [],
      certifcatetype: 'picture',
      showAddSiganture: false,
      signaturebase64: '',
      showAddSigantureTwo: false,
      signaturebase64Two: '',
      verficationStep: '',
      verficationStepSecond: '',
      secondSignteurInformation: {},
    };
  }

  ColorIcon = () => {
    if (typeof this.state.truck !== 'undefined') {
      if (
        this.state.truck.status === 'LOADED' ||
        this.state.truck.status === 'TO_BE_LOADED' ||
        this.state.truck.status === 'TOUR_ASSIGNED'
      )
        return 'tourInfo-title-span-texttwo';
      if (this.state.truck.status === 'ON_THE_WAY')
        return 'tourInfo-title-span-textthree';
      else {
        return 'tourInfo-title-span-text';
      }
    }
  };

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  handleCloseSlautherPdf = () => {
    this.setState({ showPopupSlaughter: false });
  };

  openPopUpSlautherPdf = () => {
    this.setState({ showPopupSlaughter: true });
  };

  handleCloseCertificate = () => {
    this.setState({ showPopupCertificate: false });
  };

  openPopUpCertificate = () => {
    this.setState({ showPopupCertificate: true });
  };

  handleCloseAddSignateur = () => {
    this.setState({ showAddSiganture: false });
  };

  openPopUpCloseAddSignateur = () => {
    this.setState({ showAddSiganture: true });
  };

  updateSignateur = async (signateur) => {
    await this.setState({ signaturebase64: signateur });
    await this.setState({ verficationStep: 'no' });

    await this.setState({ verficationStepSecond: 'no' });
    await this.handleSubmit();
  };

  handleCloseAddSignateurTwo = () => {
    this.setState({ showAddSigantureTwo: false });
  };

  openPopUpCloseAddSignateur = () => {
    this.setState({ showAddSigantureTwo: true });
  };

  updateSignateurTwo = async (signateur) => {
    await this.setState({ signaturebase64Two: signateur });
    let signateurImg = this.dataURItoBlob(this.state.signaturebase64Two);
    const response = await userService.addSignature(
      this.state.truckDetail.truck.driver_id,
      this.props.users.accessToken,
      signateurImg,
    );
    await this.setState({ showAddSigantureTwo: false });
    await this.setState({ verficationStep: 'no' });
    await this.handleSubmit();
  };

  getSignateurTwo = async (truckDetail) => {
  // first
    let secondSignteurInformation = await OrderService.getSecondSignateurData(
      this.props.users.accessToken,
      this.state.orderId,
      this.state.truckDetail.truck._id,
    );

    if (
      secondSignteurInformation !== 'null' &&
      typeof secondSignteurInformation !== 'undefined' &&
      typeof secondSignteurInformation.status !== 'undefined' &&
      secondSignteurInformation.status === 200
    ) {
      const secondSignteurData = await extractData(secondSignteurInformation);
      if (
        typeof  secondSignteurData !== 'undefined' && 
        typeof  secondSignteurData.secondsignateur !== 'undefined' && 
        secondSignteurData.secondsignateur === true &&
        secondSignteurData.openPopup === true &&
        secondSignteurData.signature === '' &&
        secondSignteurData.Deliverytime === false
      ) {

        await this.setState({
          verficationStep: 'yes',
          secondSignteurInformation: secondSignteurData,
        });
      } else {
        await this.setState({
          verficationStep: 'no',
          secondSignteurInformation: secondSignteurData || '',
        });
      }
    }
  };

  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);

    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);

    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  getEncodedCertificate = async (certificateId, companyId) => {
    // let img = await await getDocCompany(
    //   companyId,
    //   this.props.users.accessToken,
    //   certificateId,
    // );
    // if (img.substring(0,25).includes('png')===true) {
    //   this.setState({ certifcatetype: 'picture' });
    //   this.setState({ certifcate: 'data:image/png;base64' + img });
    // } else if (img.substring(0,25).includes('jpeg')) {
    //   this.setState({ certifcatetype: 'picture' });
    //   this.setState({ certifcate: 'data:image/jpeg;base64' + img });
    // } else if (img.substring(0,25).includes('pdf')) {
    //   this.setState({ certifcatetype: 'pdf' });
    //   this.setState({ certifcate: 'data:application/pdf;base64' + img });
    // }
  };

  getEncodedPdf = async () => {
    let img = await OrderService.getPDFTour(
      this.props.users.accessToken,
      this.props.orderDetail.order_id,
      this.state.truckDetail.truck._id,
    );
    this.setState({ pdfId: 'data:application/pdf;base64,' + img });
  };

  getEncodedPdfSlaugther = async () => {
    let img = await OrderService.getPDFSlaugther(
      this.props.users.accessToken,
      this.props.orderDetail.order_id,
      this.state.truckDetail.truck._id,
    );
    this.setState({ pdfSlau: 'data:application/pdf;base64,' + img });
  };

  ColorTxt = () => {
    if (typeof this.state.truck !== 'undefined') {
      if (
        this.state.truck.status === 'LOADED' ||
        this.state.truck.status === 'TO_BE_LOADED' ||
        this.state.truck.status === 'TOUR_ASSIGNED'
      )
        return 'tourInfo-title-span-texttwo';
      else {
        return 'tourInfo-title-span-text';
      }
    }
  };

  componentDidMount() {
    this.setState({
      orderId: this.props.orderDetail.order_id,
      show: true,
      departureTime: '',
      loadingTime: '',
      note: '',
      unloadToast: '',
      deliverToast: '',
      showTruckList: false,
      showLocForm: false,
      showTargetForm: true,
      showConfirm: false,
    });
    this.getTours(this.state.orderId);
  }

  componentWillUnmount() {
    this.setState({
      orderId: this.props.orderDetail.order_id,
      show: true,
      departureTime: '',
      loadingTime: '',
      note: '',
      unloadToast: '',
      deliverToast: '',
      showTruckList: false,
      showLocForm: false,
      showTargetForm: false,
      showConfirm: false,
    });
    // this.getTours(this.state.orderId);
  }

  timeConvert = (time) => {
    var hours = time / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    this.setState({ hour: rhours, min: rminutes });
  };
  getTourInfoTimeUpdates = () => {
    const { truck, orderId } = this.state;
    var starting_counter = new Date(truck.gps_updated_at);
    this.getTourInfo(orderId, truck._id);
    var diff = moment().toDate() - starting_counter;
    if (diff > 60e3) {
      return this.timeConvert(Math.floor(diff / 60e3));
    }
  };
  GetKey = (id) => {
    this.setState({ DepartureId: id });
  };

  getContactsById = async () => {
    let token = this.props.users.accessToken;
    if (
      this.props.users.user.session.company_type !== '3' &&
      this.props.users.user.session.company_type !== '2'
    ) {
      const value = await getcontactByNumber(
        this.state.truckDetail.buyer_company,
        this.state.truckDetail.seller_number,
        token,
      );
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        const contacts = await extractData(value);
        this.setState({
          has_slaughter_informations: contacts.has_slaughter_informations,
        });
      }
    } else {
      this.setState({
        has_slaughter_informations: 1,
      });
    }
  };

  getBioCertificateList = async (orderId, truckId) => {
    let token = this.props.users.accessToken;
    if (
      this.props.users.user.session.company_type === '3' ||
      this.props.users.user.session.company_type === '2'
    ) {
      const value = await OrderService.getBioCertificate(
        token,
        orderId,
        truckId,
      );
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        const certificateListes = await extractData(value);
        this.setState({
          certificateList: certificateListes,
        });
      }
    } else {
      this.setState({
        certificateList: [],
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.orderDetail !== this.props.orderDetail) {
      this.setState({
        orderId: nextProps.orderDetail.order_id,
        show: true,
        departureTime: '',
        loadingTime: '',
        note: '',
        unloadToast: '',
        deliverToast: '',
        showTruckList: false,
        showLocForm: false,
        showTargetForm: false,
        showConfirm: false,
      });
      this.getTours(nextProps.orderDetail.order_id);
    }
  }

  togglePopupPassword = () => {
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
  };

  showMoreOption = () => {
    this.setState({
      showDoc: !this.state.showDoc,
    });
  };

  showLocForm = () => {
    this.setState({
      showLocForm: !this.state.showLocForm,
    });
  };

  getTours = async (orderId) => {
    const token = this.props.users.accessToken;
    const value = await OrderService.getTours(token, orderId, "", this.props.users);
    if (value.status === 200) {
      const data = await extractData(value);
      if (data.length > 1) {
        if (typeof this.props.truckId === 'undefined') {
          this.setState({
            trucks: data,
            showTruckList: true,
            show: false,
          });
        }
        if (typeof this.props.truckId !== 'undefined') {
          let dataEx = data.filter((e) => e._id === this.props.truckId);
          this.setState({
            trucks: dataEx[0],
          });
          if (typeof dataEx[0] !== 'undefined')
            await this.getTourInfo(orderId, dataEx[0]._id);
        }
      } else {
        this.setState({
          truck: data[0],
        });
        if (typeof data[0] !== 'undefined')
          await this.getTourInfo(orderId, data[0]._id);
      }
    }
  };

  getTourInfo = async (orderId, truckId) => {
    const token = this.props.users.accessToken;
    const value = await OrderService.getTourInfo(token, orderId, truckId);
    if (value.status === 200) {
      const data = await extractData(value);
      this.handleTargetLocation(data.truck.status);
      this.handleEditLocForm(data);
      this.handleEditDepartureTime(data);
      this.handleEditDeliveryTime(data);
      this.handleEditLoadingTimeAndNotes(data);
      this.handleConfirmBtn(data);
      this.setState({
        cattleDeliveryDate: data.truck.delivery_date,
        cattleDeliveryTime: data.truck.delivery_until,
        truckDetail: data,
        cattleGroups: data.cattle_group,
        truck: data.truck,
        locations: data.truck.locations,
      });
    }

    await this.getContactsById(orderId, truckId);
    // await this.getBioCertificateList(orderId, truckId);
  };

  showMoreOptions = (idDoc) => {
    idDoc !== this.state.idDoc && this.state.showDoc === true
      ? this.setState({
          idDoc,
        })
      : this.setState({
          showDoc: !this.state.showDoc,
          idDoc,
        });
  };

  showLocForms = (id) => {
    id !== this.state.id && this.state.showLocForm === true
      ? this.setState({
          id,
        })
      : this.setState({
          showLocForm: !this.state.showLocForm,
          id,
        });
    this.state.showTargetForm && this.setState({ showTargetForm: true });
  };

  showTargetLocForm = () => {
    this.state.truckDetail.status === 'PLANNED'
      ? this.setState({ showTargetForm: true })
      : this.setState({ showTargetForm: !this.state.showTargetForm });
  };

  showTruckDetail = (truck) => {
    this.getTourInfo(this.state.orderId, truck._id);
    this.setState({ showTruckList: false, show: true });
  };

  handleTargetLocation = (status) => {
    if (status === 'ON_THE_WAY') {
      this.setState({ srcTarget: yellowTarget });
    } else if (['LOADED', 'TO_BE_LOADED', 'TOUR_ASSIGNED'].includes(status)) {
      this.setState({ srcTarget: markergDrapo });
    } else {
      this.setState({ srcTarget: greenTarget });
    }
  };

  handleEditLocForm = (truckDetail) => {
    truckDetail.loading_time === '' &&
      truckDetail.status === 'TO_BE_LOADED' &&
      truckDetail.is_tour_planning_responsible === 1 &&
      this.setState({ editLocForm: false });
    truckDetail.status === 'TO_BE_LOADED' &&
      truckDetail.is_tour_planning_responsible === 1 &&
      this.setState({ editTargetForm: false });
  };

  handleEditDepartureTime = (truckDetail) => {
    if (
      truckDetail.status === 'TO_BE_LOADED' ||
      truckDetail.status === 'LOADED'
    ) {

      if (
        typeof truckDetail.truck.locations[0] !== 'undefined' &&
        typeof truckDetail.truck.locations[0].loading_time !== 'undefined' &&
        truckDetail.truck.locations[0].loading_time !== '' &&
        truckDetail.truck.is_transporter === 1 &&
        this.props.users.user.role !== 'ROLE_VET_EMPLOYEE' &&
        typeof depart === 'undefined' &&
        ['LOADED', 'TO_BE_LOADED'].includes(truckDetail.truck.status)
      )
        this.setState({ departureTimeEdit: false });
      else this.setState({ departureTimeEdit: true });
    }
  };

  handleEditDeliveryTime = (truckDetail) => {
    if (
      (truckDetail.truck.is_transporter === 1 &&
        truckDetail.truck.status === 'ON_THE_WAY') ||
      truckDetail.status === 'PLANNED'
    )
      this.setState({
        deliveryTimeEdit: false,
        showTargetForm: !this.state.showTargetForm,
      });
    else this.setState({ deliveryTimeEdit: true });
  };

  handleEditLoadingTimeAndNotes = (truckDetail) => {
    let enableUnloadingTimeAndNotes = false;
    if (
      truckDetail.truck.status === 'DELIVERED' ||
      truckDetail.status === 'PLANNED'
    ) {
      switch (this.props.users.user.session.company_type) {
        case '5':
          enableUnloadingTimeAndNotes =
            this.props.users.user.role !== 'ROLE_VET_EMPLOYEE';
        case '3':
          enableUnloadingTimeAndNotes = true;
        default:
          enableUnloadingTimeAndNotes =
            truckDetail.truck.is_buyer === 1 ||
            !truckDetail.receiver_is_registered;
      }

      if (
        this.props.users.user.session.company_number ===
          truckDetail.seller_number &&
        !!truckDetail.isCattleModuleRelated
      ) {
        enableUnloadingTimeAndNotes = true;
      }
    }
    this.setState({ loadingTimeAndNotes: !enableUnloadingTimeAndNotes });
  };

  handleTargetLocation = (status) => {
    if (status === 'ON_THE_WAY') {
      this.setState({ srcTarget: yellowTarget });
    } else if (['LOADED', 'TO_BE_LOADED', 'TOUR_ASSIGNED'].includes(status)) {
      this.setState({ srcTarget: markergDrapo });
    } else {
      this.setState({ srcTarget: greenTarget });
    }
  };

  handleCloseToBeload = () => {
    const { show, showDetail, trucks } = this.state;
    const { showPassportList, showPassportForTourInfo } = this.props;
    // this.props.handleClosePanel();
    if (show) {
      if (trucks.length > 1) {
        this.setState({ showTruckList: true });
        this.setState({
          show: false,
        });
      } else if (showPassportList) {
        this.setState({ show: true });
        showPassportForTourInfo(false);
      } else {
        this.setState({
          show: false,
        });
        this.props.handleClosePanel();
      }
    } else if (showDetail) {
      this.setState({ showDetail: false, show: true });
    } else {
      this.props.handleClosePanel();
    }
  };
  //btn
  enableBtnDepTime = (truckDetail) => {
    let btnEnable = false;
    truckDetail.truck.locations.map((item) => {
      if (
        item.loading_time !== undefined &&
        item.loading_time !== '' &&
        item.departure_time === ''
      ) {
        btnEnable = true;
        return btnEnable;
      }
    });
    return btnEnable;
  };

  enableBtn = (truckDetail) => {
    if (
      this.enableBtnDepTime(truckDetail) &&
      truckDetail.truck.is_transporter === 1 &&
      this.props.users.user.role !== 'ROLE_VET_EMPLOYEE' &&
      typeof depart === 'undefined' &&
      ['LOADED', 'TO_BE_LOADED'].includes(truckDetail.truck.status)
    ) {
      return true;
    } else return false;
  };

  //text
  enableDepTimetext = (item) => {
    let btnEnable = false;
    if (item.loading_time !== undefined && item.loading_time !== '') {
      btnEnable = true;
    }

    return btnEnable;
  };

  enableDepTime = (item) => {
    if (
      this.enableDepTimetext(item) &&
      this.state.buttonText === 'Confirm departure time'
    ) {
      return false;
    } else return true;
  };

  handleConfirmBtn = (truckDetail) => {
    let enableDeliveryTime =
      (truckDetail.truck.is_transporter === 1 &&
        truckDetail.truck.status === 'ON_THE_WAY') ||
      truckDetail.status === 'PLANNED';

    let enableDepartureTime = this.enableBtn(truckDetail);

    let enableUnloadingTimeAndNotes = false;
    if (
      truckDetail.truck.status === 'DELIVERED' ||
      truckDetail.status === 'PLANNED'
    ) {
      switch (this.props.users.user.session.company_type) {
        case '5':
          enableUnloadingTimeAndNotes =
            this.props.users.user.role !== 'ROLE_VET_EMPLOYEE';
        case '3':
          enableUnloadingTimeAndNotes = true;
        default:
          enableUnloadingTimeAndNotes =
            truckDetail.truck.is_buyer === 1 ||
            !truckDetail.receiver_is_registered;
      }
      if (
        this.props.users.user.session.company_number ===
          truckDetail.seller_number &&
        !!truckDetail.isCattleModuleRelated
      ) {
        enableUnloadingTimeAndNotes = true;
      }
    }

    let buttonCompleteVisibility =
      enableDeliveryTime || enableDepartureTime || enableUnloadingTimeAndNotes;
    this.setState({ showConfirm: buttonCompleteVisibility });
    if (enableDepartureTime) {
      this.setState({ buttonText: 'Confirm departure time' });
    }
    if (enableDeliveryTime) {
      this.setState({ buttonText: 'Confirm delivery time' });
    }
    if (enableUnloadingTimeAndNotes) {
      this.setState({ buttonText: 'Confirm unloading time' });
    }
  };

  getInfoAction = () => {
    this.setState({
      showTourInfo: true,
      showDocInfo: false,
      showLocInfo: false,
    });
  };
  getDocAction = () => {
    this.setState({
      showTourInfo: false,
      showDocInfo: true,
      showLocInfo: false,
    });
  };
  getLocAction = () => {
    this.setState({
      showTourInfo: false,
      showDocInfo: false,
      showLocInfo: true,
    });
  };
  renderMap = () => {};

  handleChangeBlur = async (event) => {
    if (
      this.state.deliveryTime !== ''
    ) {



      let secondSignteurInformation = await OrderService.getSecondSignateurData(
        this.props.users.accessToken,
        this.state.truckDetail.order_id,
        this.state.truckDetail.truck._id,
      );

      if (
        secondSignteurInformation !== 'null' &&
        typeof secondSignteurInformation !== 'undefined' &&
        typeof secondSignteurInformation.status !== 'undefined' &&
        secondSignteurInformation.status === 200
      ) {
        const secondSignteurData = await extractData(secondSignteurInformation);
        if (
          typeof  secondSignteurData !== 'undefined' && 
         typeof  secondSignteurData.secondsignateur !== 'undefined' && 
          secondSignteurData.secondsignateur === true &&
          secondSignteurData.openPopup === true &&
          secondSignteurData.Deliverytime === true
        ) {
          await   this.setState({
            verficationStepSecond: 'yes', showAddSiganture: true, secondSignteurInformation: secondSignteurData,
          });

          return true;
        } else  {
          await   this.setState({
            verficationStepSecond: 'no', showAddSiganture: false, secondSignteurInformation: secondSignteurData || {},
          });
          return false;
        }
      }
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    // eslint-disable-next-line default-case
    switch (name) {
      case 'loadingTime':
        this.setState({ loadingTime: value });
        break;
      case 'departureTime':
        this.setState({ departureTime: value });
        break;
      case 'deliveryTime':
        this.setState({ deliveryTime: value });
        break;
      case 'unLoadingTime':
        this.setState({ unLoadingTime: value });
        break;
      case 'notes':
        this.setState({ notes: value });
        break;
    }
  };

  showOrderDetail = () => {
    const { truck, truckDetail } = this.state;
    this.props.showDetailForTour(truck, truckDetail);
    this.setState({ showDetail: true, show: false });
  };

  closeSlaugher = () => {
    this.setState({
      show: true,
      displaySlaugher: false,
      showDocInfo: true,
    });
  };

  pdfRename = (r) => {
    const res = r.split('+');
    return res[1];
  };

  downloadPDF = async (fileId) => {
    let img = await OrderService.getPDFLoad(
      this.props.users.accessToken,
      fileId,
    );
    FileSaver.saveAs(
      'data:application/pdf;base64,' + img,
      `${this.pdfRename(fileId)}.pdf`,
    );
  };
  displayPDF = async (fileId) => {
    let img = await OrderService.getPDFLoad(
      this.props.users.accessToken,
      fileId,
    );
    this.setState({ pdfEncoded: 'data:application/pdf;base64,' + img });
  };

  handlePassports = () => {
    const { showPassportForTourInfo } = this.props;
    showPassportForTourInfo(true);
  };

  handlePassports = () => {
    const { showPassportForTourInfo } = this.props;
    showPassportForTourInfo(true);
  };

  handlePassportsIcon = (pass) => {};

  // renderPassportsEartags =  (pass, index) => {
  //   return (
  //     <div key={index} className="passport">
  //     <div className="eartag">
  //       <div className="">{pass.ear_tag_output_format}</div>
  //     </div>
  //     <div>
  //       <Image
  //         className=""
  //         src={
  //           (typeof pass.passport !== 'undefined' &&
  //             pass.passport.length > 0) ||
  //           typeof pass.passport == 'undefined'
  //             ? searchImg
  //             : searchImgNotFound
  //         }
  //         onClick={() => {

  //             this.getPassport(pass);
  //         }}
  //       />
  //     </div>
  //   </div>

  //   );
  // };

  renderPassports = (truckDetail) => {
    let passportList = [];

    truckDetail.truck.locations.map(async (passport) => {
      passportList.push({
        _id: passport._id,
        passport: passport.passport,
        pick_up_time: passport.pick_up_time,
        seller: passport.seller,
        loading_time: passport.loading_time,
        address: passport.address,
        ear_tags: passport.ear_tags,
      });
    });

    return (
      <div className="passports-container passport-handler">
        {passportList.map((item, index) => (
          <div key={index} className="passports-by-seller">
            <div className="pass">
              <div className="pass-inner-image">
                <Image
                  className="status-image-icon-marker"
                  src={item.departure_time ? greenLoc : grayLoc}
                />
              </div>
              <div className="pass-inner-content">
                <div className={item.departure_time ? 'green' : 'gray'}>
                  {item.pick_up_time} {item.seller}
                </div>
                <div>{`${item.address.street}, ${item.address.zip_code} ${item.address.city}`}</div>
              </div>
            </div>
            <div className="passport-list">
              {item.ear_tags.map((pass, index) => (
                <EartagsList
                  pass={pass}
                  index={index}
                  getPassport={this.getPassport}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  changeDe = (deliveries) => {
    this.setState({ departureTime: deliveries });
  };

  getNewPassports = async (eartag) => {
    const value2 = await getStockByIds(
      this.props.users.accessToken,
      eartag._id,
    );

    if (value2.status === 200) {
      const data = await extractData(value2);

      if (typeof data.passport !== 'undefined') {
        return data.passport;
      } else {
        return '';
      }
    }

    return '';
  };

  getPassport = async (eartag) => {
    let passportId = await this.getNewPassports(eartag);
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
    const token = this.props.users.accessToken;
    if (passportId !== '') {
      let passport = await getPassportStock(eartag._id, token, passportId);
      if (passport) {
        this.setState({
          ear_tag: eartag.ear_tag,
          ear_tag_output_format: eartag.ear_tag_output_format,
        });

        if (passport) {
          this.setState({ passport: 'data:image/png;base64,' + passport });
          this.setState({
            file: eartag.passport === '' ? null : eartag.passport,
          });
        }
      }
    }
  };

  deleteTourPlannedOrder = async (e, orderId) => {
    await this.props.deleteOrder(orderId);
    this.props.handleClosePanel();
    await this.props.getOrders();
    this.props.success(
      this.props.language === 'EN'
        ? `${this.props.orderDetail.name}   successfully updated`
        : `${this.props.orderDetail.name}  erfolgreich aktualisiert`,
    );
    this.props.clear();
  };

  //logic of confirm
  handleSubmit = async () => {
    const { deliveryTime, departureTime, unLoadingTime, truckDetail } =
      this.state;
    const { accessToken } = this.props.users;

    if (this.state.buttonText === 'Confirm departure time') {
      if (this.state.departureTime === '') {
        this.setState({ departureToast: 'Please enter departure time' });
      } else {
        console.log('-------------------------');
        this.state.verficationStep !== 'no' && await       this.getSignateurTwo();
      

          if (this.state.verficationStep === 'no') {
            const value = await OrderService.editTour(
              accessToken,
              this.state.orderId,
              truckDetail.truck._id,
              [
                {
                  _id: this.state.DepartureId,
                  departure_time: departureTime,
                },
              ],
            );
            if (value.status === 200) {
              this.props.handleClosePanel();
              this.props.success(
                <FormattedMessage {...messages.DepTimeUpdated} />,
              );
              this.props.clear();
              this.props.getOrders();
            } else {
              this.props.error(
                <FormattedMessage {...messages.DepTimeNotUpdated} />,
              );
              this.props.clear();
            }

          } else {

            this.setState({ showAddSigantureTwo: true});
            
          }
        
      }
    }
    if (this.state.buttonText === 'Confirm delivery time') {
      if (deliveryTime === '' || typeof deliveryTime === 'undefined') {
        this.setState({ deliverToast: 'Please enter delivery time' });
      } else {

        this.state.verficationStepSecond !== 'no' && await this.handleChangeBlur();

        if (this.state.verficationStepSecond === 'no') {

        const value = await OrderService.editTourDelivery(
          accessToken,
          this.state.orderId,
          truckDetail.truck._id,
          deliveryTime,
          this.state.signaturebase64,
        );
        if (value.status === 200) {
          this.props.handleClosePanel();
          this.props.success(
            <FormattedMessage {...messages.DeliveryTimeUpdated} />,
          );
          this.props.clear();
          this.props.getOrders();
        } else {
          this.props.error(
            <FormattedMessage {...messages.DeliveryTimeNotUpdated} />,
          );
          this.props.clear();
        }

    
    }
         
    else {
      this.setState({ showAddSiganture: true });
    } 
    
    }
    }
    if (this.state.buttonText === 'Confirm unloading time') {
      if (unLoadingTime === '') {
        this.setState({ unloadToast: 'Please enter unloading time' });
      } else {
        const value = await OrderService.editTourDeliveryAndNotes(
          accessToken,
          this.state.orderId,
          truckDetail.truck._id,
          unLoadingTime,
          this.state.notes,
        );
        if (value.status === 200) {
          this.props.handleClosePanel();
          this.props.success(
            <FormattedMessage {...messages.UnloadingTimeUpdated} />,
          );
          this.props.clear();
          this.props.getOrders();
        } else {
          this.props.error(
            <FormattedMessage {...messages.UnloadingTimeNotUpdated} />,
          );
          this.props.clear();
        }
      }
    } else {
      this.props.clear();
    }
  };

  handleConfirmTimes = async () => {
    const { accessToken } = this.props.users;
    const { deliveryTime, unLoadingTime, truckDetail } = this.state;
    const truckId = truckDetail.truck._id;

    if (deliveryTime === '' && !this.state.truck.delivery_time) {
      this.setState({ deliverToast: 'Please enter delivery time' });
    }
    if (deliveryTime !== '' || this.state.truck.delivery_time) {
      const value = await OrderService.editTourTimes(
        accessToken,
        this.state.orderId,
        truckId,
        deliveryTime || this.state.truck.delivery_time,
        unLoadingTime,
      );
      if (value.status === 200) {
        this.props.handleClosePanel();
        this.props.success(
          <FormattedMessage {...messages.TimeUpdateSuccess} />,
        );
        this.props.clear();
        this.props.getOrders();
      } else {
        this.props.error(<FormattedMessage {...messages.TimeUpdateError} />);
        this.props.clear();
      }
    }
  };

  handleConfirmCattles = async () => {
    const { accessToken } = this.props.users;
    const value = await OrderService.editTourCattles(
      accessToken,
      this.state.orderId,
      this.state.cattleDeliveryDate,
      this.state.cattleDeliveryTime,
      this.state.cattleGroups,
    );
    if (value.status === 200) {
      this.props.handleClosePanel();
      this.props.success(
        <FormattedMessage {...messages.CuttleUpdateSuccess} />,
      );
      this.props.clear();
      this.props.getOrders();
    } else {
      this.props.error(<FormattedMessage {...messages.CuttleUpdateError} />);
      this.props.clear();
    }
  };

  removeCattleGroup = (evt, cattleIndex) => {
    this.setState({
      cattleGroups: this.state.cattleGroups.filter((cattle, index) => {
        return index !== cattleIndex;
      }),
    });
  };

  checkForm = (formStatus) => {
    this.setState({ checkAddCattleForm: formStatus });
  };

  getCattle = (cattleIndex) => {
    const { cattleGroups } = this.state;
    return cattleGroups[cattleIndex];
  };

  editCattleGroup = (cattleIndex, cattle) => {
    const { cattleGroups } = this.state;
    let cattles = [...cattleGroups];
    cattles[cattleIndex] = cattle;
    this.setState({ cattleGroups: cattles });
  };

  addCattleGroup = (cattle) => {
    this.setState({ cattleGroups: [...this.state.cattleGroups, cattle] });
  };

  renderTruckList = () => {
    return (
      <Col
        id="trucks-list-container"
        className={'to-be-loaded'}
        style={{ height: '100%' }}
        md={12}
        sm={6}
      >
        <Card id="to-be-loaded-trucks" className="card-box to-be-loaded-panel">
          <div className="tobeloade-title-block">
            <h5 className="card-title first-block">
              <FormattedMessage {...messages.Tours} />
            </h5>
          </div>
          <div className="navigate-before">
            <div md={3}>
              <MdNavigateBefore
                className="before"
                onClick={() => this.props.handleClosePanel()}
              />
            </div>
          </div>
          <Col>
            <hr className="style-border" />
          </Col>
          {this.state.trucks.map((item, i) => (
            <TruckItem
              key={i}
              truck={item}
              showTruckDetail={this.showTruckDetail}
              tourDetails={this.props.toursDetails}
            />
          ))}
        </Card>
      </Col>
    );
  };

  renderTargetLocForm = () => {
    return (
      <div className="tour-info-form-container">
        <div className="tourInfo-title-pf">
          <div className="tourInfo-title-b1-form"></div>
          <div className="tourInfo-title-btn-form">
            <div className="tourInfo-title-btn-form-row">
              <div
                className={clsx(
                  'dep-arr-text',
                  !this.state.deliveryTimeEdit && 'tourInfo-edit',
                )}
              >
                <FormattedMessage {...messages.DeliveryTime} />
              </div>
              <div className="dep-arr-time-input">
                <input
                  id="deliveryTime"
                  className="form-input"
                  name="deliveryTime"
                  type="time"
                  // onBlur={(e) => {
                  //   this.handleChangeBlur(e);
                  // }}
                  onChange={this.handleChange}
                  value={
                    this.state.truck.delivery_time
                      ? this.state.truck.delivery_time
                      : this.state.deliveryTime
                  }
                  disabled={this.state.deliveryTimeEdit}
                />
              </div>
            </div>
            <input
              style={{ color: 'red' }}
              value={this.state.deliverToast}
              disabled
            />
          </div>
        </div>
        <div className="tourInfo-title-pf">
          <div className="tourInfo-title-b1-form"></div>
          <div className="tourInfo-title-btn-form">
            <div className="tourInfo-title-btn-form-row">
              <div
                className={clsx(
                  'dep-arr-text',
                  !this.state.loadingTimeAndNotes && 'tourInfo-edit',
                )}
              >
                <FormattedMessage {...messages.Unloading} />
              </div>
              <div className="dep-arr-time-input">
                <input
                  className="form-input"
                  id="unLoadingTime"
                  name="unLoadingTime"
                  type="time"
                  onChange={this.handleChange}
                  value={
                    this.state.truck.unloading_time
                      ? this.state.truck.unloading_time
                      : this.state.unLoadingTime
                  }
                  disabled={this.state.loadingTimeAndNotes}
                />
              </div>
            </div>
            <input
              style={{ color: 'red' }}
              value={this.state.unloadToast}
              disabled
            />
          </div>
        </div>
        <div className="tourInfo-title-pf">
          <div className="tourInfo-title-b1-form"></div>
          <div className="tourInfo-title-btn-form">
            <textarea
              name="notes"
              className="form-input"
              placeholder={
                this.props.language.locale === 'DE'
                  ? 'Zusätzliche Bemerkungen...'
                  : 'Additional notes...'
              }
              style={{ border: '1px solid #bbbbbb' }}
              cols={18}
              rows={4}
              value={
                this.state.truck.notes
                  ? this.state.truck.notes
                  : this.state.notes
              }
              onChange={this.handleChange}
              disabled={this.state.loadingTimeAndNotes}
            />
          </div>
        </div>
        <input value={this.state.t1} disabled />
      </div>
    );
  };

  renderLocations = () => {
    const { locations, idDoc, id, showDoc, showLocForm, seller, truckDetail } =
      this.state;
    const truckStatus = truckDetail.status;
    return locations.map((item, index) => (
      <div key={item.order_id} className="locations-container">
        <hr className="style-border" />
        <LocationItem
          key={index}
          idDoc={idDoc}
          id={id}
          showDoc={showDoc}
          showLocForm={showLocForm}
          showLocForms={this.showLocForms}
          showMoreOptions={this.showMoreOptions}
          item={item}
          seller={seller}
          loc={'info'}
          truckStatus={truckStatus}
          renderDocForm={this.renderDocForm}
          renderLocForm={this.renderLocForm}
          handleChange={this.handleChange}
          loadingTime={this.state.loadingTime}
          departureTime={this.state.departureTime}
          edit={this.enableDepTime(item)}
          changeDe={this.changeDe}
          GetKey={this.GetKey}
        />
      </div>
    ));
  };

  getIcon = () => {
    if (typeof this.state.truck !== 'undefined') {
      if (this.state.truck.status === 'LOADED') return truckLoaded;
      if (this.state.truck.status === 'ON_THE_WAY') return truckOnTheWay;
      if (this.state.truck.status === 'DELIVERED') return truckDelivered;
      if (this.state.truck.status === 'UNLOADED') return truckUnloaded;
      if (this.state.truck.status === 'SLAUGHTERED') return truckSlaughered;
      if (this.state.truck.status === 'SLAUGHTERED') return truckSlaughered;
      if (this.state.truck.status === 'TOUR_ASSIGNED') return Tourplanning;
      if (this.state.truck.status === 'PLANNED') return Planned;
      else return truckToBeLoaded;
    }
    return truckToBeLoaded;
  };

  displaySlaughterDetail = () => {
    this.setState({
      displaySlaugher: true,
      // showDocInfo: false,
      // showDoc: true,
    });
  };

  toggleFilterPopup = () => {
    this.setState({
      displaySlaugher: false,
    });
  };
  renderSpendedTime = () => {
    const { status } = this.state.truck;
    this.checkRenderedTiming();

    return (
      <div>
        {['LOADED', 'ON_THE_WAY', 'TO_BE_LOADED'].includes(status) && (
          <div className="tracking-info">
            <div className="track-info-left-box">
              <FormattedMessage {...messages.lastTrackingUpdates} />
              {this.state.hour > 0 && ' ' + this.state.hour + ' h'}
              {' ' + this.state.min}
              <FormattedMessage {...messages.trackingTime} />
            </div>

            <Image
              className="reload-btn"
              src={reload}
              onClick={() => this.getTourInfoTimeUpdates()}
            />
          </div>
        )}
      </div>
    );
  };
  checkRenderedTiming = () => {
    return (
      this.state.truck.is_gps_updated &&
      this.state.min === 0 &&
      this.getTourInfoTimeUpdates()
    );
  };
  renderInfoList = (truckDetail) => {
    const { is_gps_updated, is_driver, gps_updated_at } = this.state.truck;

    return (
      <div>
        {is_gps_updated === 1 && is_driver === 1 && this.renderSpendedTime()}
        <div className="tourInfo-title t-info">
          <div className="tourInfo-title-b1">
            <Image className="status-image-iconTour" src={this.getIcon()} />
          </div>
          <div className="tourInfo-title-b2">
            <div className="tourInfo-position-time">
              <div className={`${this.ColorTxt()} tour-time-from`}>
                {truckDetail.truck.departure_time}
              </div>
              <div className="tourInfo-title-span-text-points">.......</div>
              <div className={`${this.ColorIcon()} tour-time-until`}>
                {truckDetail.truck.delivery_time.length > 0
                  ? truckDetail.truck.delivery_time
                  : truckDetail.truck.delivery_until}
              </div>
            </div>
            <div className="block-time">
              <div className="text-Hr">
                {truckDetail.truck.vehicle_number} | {truckDetail.truck.driver}
              </div>
              {truckDetail.truck.estimated_gps_distance && (
                <div className="distance">
                  {`${truckDetail.truck.estimated_gps_distance} Km `}
                  {/* <FormattedMessage {...messages.More} /> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.renderLocations()}
        <hr className="style-border" />
        <div
          className="tourInfo-title t-info-title"
          onClick={() => this.showTargetLocForm()}
        >
          <div className="tourInfo-title-b1">
            <Image
              className="status-image-icon-marker"
              src={this.state.srcTarget}
            />
          </div>
          <div className="tourInfo-title-btn tour-info-list-cattle">
            <div className={`${this.ColorIcon()} truck-delivery-buyer`}>
              {truckDetail.delivery_until} {truckDetail.buyer}
            </div>
            <div className="truck-address">{truckDetail.buyer_place}</div>
          </div>
          {truckDetail.status !== 'PLANNED' && (
            <div className="arrow-down-more">
              <Image
                className=""
                src={arrowImg}
                onClick={() => this.showTargetLocForm()}
              />
            </div>
          )}
        </div>
        {!this.state.showTargetForm && this.renderTargetLocForm()}
        {truckDetail.status !== 'PLANNED' && <hr className="style-border" />}
      </div>
    );
  };

  renderDocuments = () => {
    const {
      locations,
      idDoc,
      id,
      showDoc,
      showLocForm,
      seller,
      truckDetail,
      certificateList,
    } = this.state;
    return (
      <div className="doc-inner">
        <Modal
          show={this.state.showPopup === true}
          onHide={this.handleClose}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdf ">
            <embed
              width="100%"
              height="90%"
              frameBorder="0"
              src={this.state.pdfId}
              type="application/pdf"
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showPopupSlaughter === true}
          onHide={this.handleCloseSlautherPdf}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdf ">
            <embed
              width="100%"
              height="90%"
              frameBorder="0"
              src={this.state.pdfSlau}
              type="application/pdf"
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showPopupCertificate === true}
          onHide={this.handleCloseCertificate}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          {this.state.certifcatetype === 'pdf' ? (
            <Modal.Body className="modal-pdf ">
              <embed
                width="100%"
                height="90%"
                frameBorder="0"
                src={this.state.certifcate}
                type="application/pdf"
              />
            </Modal.Body>
          ) : (
            <Modal.Body>
              <img
                style={{ objectFit: 'cover' }}
                width="100%"
                height="100%"
                src={this.state.certifcate}
              />
            </Modal.Body>
          )}
        </Modal>
        {(this.props.users.user.session.company_type === '2' ||
          this.props.users.user.session.company_type === '3' ||
          this.props.users.user.session.company_type === '4') && (
          <div className="t-info-pass-sl first">
            <div className="tourInfo-view-cattle">
              <div
                className="text"
                onClick={() => {
                  this.openPopUp();
                  this.getEncodedPdf();
                }}
              >
                <FormattedMessage {...messages.HomeManageViehanmeldung} />:{' '}
                {truckDetail.delivery_date}
              </div>
              <div className="view-cattle">
                <Image
                  className=""
                  src={searchImg}
                  onClick={() => {
                    this.openPopUp();
                    this.getEncodedPdf();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="t-info-pass-sl first">
          <div className="tourInfo-view-cattle">
            <div
              className="text"
              onClick={() => {
                this.handlePassports(truckDetail);
              }}
            >
              <FormattedMessage {...messages.Passport} />
            </div>
            <div className="view-cattle">
              <Image
                className=""
                src={searchImg}
                onClick={() => {
                  this.handlePassports(truckDetail);
                }}
              />
            </div>
          </div>
        </div>
        {locations.map((item, index) => (
          <div key={item.order_id}>
            <hr className="style-border" />
            <LocationItem
              idDoc={idDoc}
              id={id}
              showDoc={showDoc}
              showLocForm={showLocForm}
              showLocForms={this.showLocForms}
              showMoreOptions={this.showMoreOptions}
              item={item}
              seller={seller}
              loc={''}
              renderDocForm={this.renderDocForm}
              renderLocForm={this.renderLocForm}
              downloadPDF={this.downloadPDF}
              displayPDF={this.displayPDF}
              pdfEncoded={this.state.pdfEncoded}
            />
            {locations.length === 1 && <hr className="style-border" />}
          </div>
        ))}
        {/* {truckDetail.status === 'SLAUGHTERED' &&
          this.state.has_slaughter_informations === 1 && (
            <div className="t-info-pass-sl second">
              <div className="tourInfo-view-cattle">
                <div className="text">
                  <FormattedMessage {...messages.Slaughterreport} />
                </div>
                <div className="view-cattle">
                  <Image
                    className=""
                    src={searchImg}
                    onClick={this.displaySlaughterDetail}
                  />
                </div>
              </div>
            </div>
          )} */}
        {truckDetail.status === 'SLAUGHTERED' &&
          this.state.has_slaughter_informations === 1 && (
            <div
              onClick={() => {
                this.openPopUpSlautherPdf();
                this.getEncodedPdfSlaugther();
              }}
              className="t-info-pass-sl second"
            >
              <div className="tourInfo-view-cattle">
                <div className="text">
                  <FormattedMessage {...messages.Slaughterreport} />
                </div>
                <div className="view-cattle">
                  <Image
                    className=""
                    src={searchImg}
                    // onClick={this.displaySlaughterDetail}
                  />
                </div>
              </div>
            </div>
          )}

        {/* {this.props.users.user.session.company_type === '3' &&
          certificateList.length > 0 && (
            <div>
              {certificateList.map((e) => (
                <div
                style={{height:'10px !important'}}

                  key={e.certificate_id}
                  onClick={() => {
                    this.openPopUpCertificate();
                    this.getEncodedCertificate(e.certificate_id, e.companyId);
                  }}
                  className="t-info-pass-sl secondpx"
                >
                  <div className="tourInfo-view-cattle">
                    <div className="text">
                      {e.name} <FormattedMessage {...messages.Certificate} />
                    </div>
                    <div className="view-cattle">
                      <Image
                        className=""
                        src={searchImg}
                      // onClick={this.displaySlaughterDetail}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )} */}
      </div>
    );
  };

  getCattleDeliveryDate = (deliveryDate) => {
    this.setState({ cattleDeliveryDate: deliveryDate });
  };

  getCattleDeliveryTime = (deliveryTime) => {
    this.setState({ cattleDeliveryTime: deliveryTime });
  };

  renderCattleGroups = (truckDetail) => {
    return (
      <CattleGroup
        status={truckDetail.truck.status}
        cattles={this.state.cattleGroups}
        getCattle={this.getCattle}
        getCattleDeliveryDate={this.getCattleDeliveryDate}
        getCattleDeliveryTime={this.getCattleDeliveryTime}
        cattleDeliveryDate={truckDetail.truck.delivery_date}
        cattleDeliveryTime={truckDetail.truck.delivery_until}
        addCattleGroup={this.addCattleGroup}
        editCattleGroup={this.editCattleGroup}
        removeCattleGroup={this.removeCattleGroup}
        checkForm={this.checkForm}
      />
    );
  };

  getName = (name) => {
    if (this.props.language.locale === 'DE') {
      if (name === 'Confirm departure time') return 'Abfahrtszeit bestätigen';
      if (name === 'Confirm delivery time') return 'Lieferzeit bestätigen';
      if (name === 'Confirm unloading time') return 'Entladezeit bestätigen';
    } else return name;
  };
  upperCaseToNomalAndTraduction = (str) => {
    let newText = str;
    if (this.props.language.locale === 'DE') {
      if (str === 'SOLD') {
        newText = 'Verkauft';
      }
      if (str === 'RESOLD') {
        newText = 'Weiterverkauft';
      }
      if (str === 'BOUGHT') {
        newText = 'Gekauft';
      }
    }
    return capitalize(newText.toLowerCase()).replace(/_/g, ' ');
  };

  finedAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  render() {
    const {
      truckDetail,
      show,
      showConfirm,
      showTruckList,
      displaySlaugher,
      showDocInfo,
      showTourInfo,
      checkAddCattleForm,
      orderId,
      showLocInfo,
    } = this.state;
    const { showPassportList } = this.props;
    let $imagePreview = null;
    if (this.state.passport) {
      // eslint-disable-next-line jsx-a11y/alt-text
      $imagePreview = <img src={this.state.passport} />;
    } else {
      $imagePreview = <div className="previewText">No passport image!</div>;
    }
    if (
      typeof truckDetail.truck !== 'undefined' &&
      truckDetail.truck.is_transporter === 1 &&
      truckDetail.truck.status === 'TOUR_ASSIGNED'
    ) {
      truckDetail.truck.order_id = truckDetail.order_id;
    }

    return typeof truckDetail.truck !== 'undefined' &&
      truckDetail.truck.is_transporter === 1 &&
      truckDetail.truck.status === 'TOUR_ASSIGNED' ? (
      <React.Fragment>
        <TourManagement
          orderDetail={truckDetail.truck}
          setShowForm={this.setShowForm}
          orderType={this.props.orderType}
          type={this.state.type}
          handleClosePanel={this.props.handleClosePanel}
          getOrders={this.props.getOrders}
          assigned={true}
          getToursDate={this.props.getToursDate}
        />
      </React.Fragment>
    ) : (
      <React.Fragment>
        {showTruckList && (
          <div className="tour-info-insider" style={{ height: '100%' }}>
            <InfiniteScroll
              style={{ height: '815px !important' }}
              dataLength={this.state.trucks.length}
              hasMore={true}
              loader={''}
            >
              {this.renderTruckList()}
            </InfiniteScroll>
          </div>
        )}
        {displaySlaugher && (
          <Modal
            show={displaySlaugher}
            onHide={this.toggleFilterPopup}
            id="slaughter"
            style={{ opacity: 1 }}
          >
            <Modal.Header closeButton>
              <Modal.Title />
            </Modal.Header>
            <Modal.Body className="search-company-slau">
              <SlaughterList
                eartags={truckDetail.truck.ear_tags}
                closeSlaugher={this.closeSlaugher}
              />
            </Modal.Body>
          </Modal>
        )}
        {!displaySlaugher && !isEmpty(truckDetail) && show && (
          <Col
            className={'to-be-loaded'}
            style={{ height: '100%' }}
            md={6}
            sm={12}
          >
            <Card className="card-box to-be-loaded-panel loaded-to-be-loaded">
              <Modal
                show={this.state.togglePopupPassword}
                onHide={this.togglePopupPassword}
                style={{ opacity: 1 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center">{$imagePreview}</Modal.Body>
              </Modal>
              <div className="navigate-before">
                <div md={3}>
                  <MdNavigateBefore
                    className="before"
                    onClick={() => this.handleCloseToBeload()}
                  />
                </div>
              </div>
              <div className="tour-info-block t-info-sub">
                <div
                  className="tourInfo-title first"
                  onClick={this.showOrderDetail}
                >
                  <Col md={3} className="status">
                    <Image className="status-image" src={loaded} />
                    <div className="status-name">
                      {this.upperCaseToNomalAndTraduction(
                        truckDetail.sale_status,
                      )}
                    </div>
                  </Col>
                  <div className="tourInfo-title-b2 sub">
                    <div>
                      <div className="tourInfo-title-span-text">
                        {typeof this.props.truckId === 'undefined'
                          ? this.finedAndReplace(truckDetail.name)
                          : this.finedAndReplace(truckDetail.truck.name)}
                      </div>
                      <div className="inner-text">
                        {truckDetail.delivery_date} | {truckDetail.recipient}
                      </div>
                    </div>
                  </div>
                </div>
                {/* {(showTourInfo || showDocInfo || showPassportList) && (
                  <hr className="style-border" />
                )} */}
                {!showPassportList && (
                  <div>
                    <ButtonGroup
                      value={this.state.type}
                      onClick={this.handleChange}
                      className="tour-btn-groupe"
                    >
                      <div className="button-tour-groupe-wrapper">
                        <button
                          className={
                            this.state.showTourInfo
                              ? 'Tour-Doc-class'
                              : 'Tour-Information-class'
                          }
                          value={'open'}
                          onClick={this.getInfoAction}
                        >
                          <FormattedMessage {...messages.information} />
                        </button>
                        <button
                          className={
                            this.state.showDocInfo
                              ? 'Tour-Doc-class'
                              : 'Tour-Doc-class-active'
                          }
                          value={'deliveries'}
                          onClick={this.getDocAction}
                        >
                          {truckDetail.status === 'PLANNED' ||
                          (truckDetail.business_type === '3' &&
                            truckDetail.receiver_is_registered === 0) ? (
                            <FormattedMessage {...messages.DeliveryDetails} />
                          ) : (
                            <FormattedMessage {...messages.Documents} />
                          )}
                        </button>
                        {truckDetail.status !== 'PLANNED' &&
                          truckDetail.status !== 'SLAUGHTERED' &&
                          truckDetail.status !== 'UNLOADED' && (
                            <button
                              className={
                                this.state.showLocInfo
                                  ? 'Tour-Doc-class'
                                  : 'Tour-Doc-class-active'
                              }
                              value={'deliveries'}
                              onClick={this.getLocAction}
                            >
                              <FormattedMessage {...messages.Localization} />
                            </button>
                          )}
                      </div>
                    </ButtonGroup>
                    <hr className="style-border custom-nav" />
                  </div>
                )}

                <div className="tour-info-div cattle">
                  <div>
                    <Modal
                      show={this.state.showAddSiganture}
                      onHide={this.handleCloseAddSignateur}
                      style={{ opacity: 1 }}
                    >
                      <Modal.Header
                        className="Modal-add-truck-title"
                        style={{ height: '0%' }}
                        closeButton
                      >
                        <h5
                          class="card-title new-transport"
                          style={{ width: '80%' }}
                        >
                          <FormattedMessage {...messages.AddSigantureTwo} />{' '}
                          {this.state.secondSignteurInformation &&
                          this.state.secondSignteurInformation.buyer_name
                            ? this.state.secondSignteurInformation.buyer_name
                            : ''}
                        </h5>
                      </Modal.Header>
                      <Modal.Body>
                        <SignatureSection
                          togglePopup={this.handleCloseAddSignateur}
                          showNote={true}
                          addSignature={this.updateSignateur}
                        />
                      </Modal.Body>
                    </Modal>
                  </div>
                  <div>
                    <Modal
                      show={this.state.showAddSigantureTwo}
                      onHide={this.handleCloseAddSignateurTwo}
                      style={{ opacity: 1 }}
                    >
                      <Modal.Header
                        className="Modal-add-truck-title"
                        style={{ height: '0%' }}
                        closeButton
                      >
                        <h5
                          class="card-title new-transport"
                          style={{ width: '80%' }}
                        >
                          <FormattedMessage {...messages.AddSiganture} />{' '}
                          {this.state.secondSignteurInformation &&
                          this.state.secondSignteurInformation.driver_name
                            ? this.state.secondSignteurInformation.driver_name
                            : ''}
                        </h5>
                      </Modal.Header>
                      <Modal.Body>
                        <SignatureSection
                          togglePopup={this.handleCloseAddSignateurTwo}
                          showNote={true}
                          addSignature={this.updateSignateurTwo}
                        />
                      </Modal.Body>
                    </Modal>
                  </div>
                  {showTourInfo &&
                    !showPassportList &&
                    this.renderInfoList(truckDetail)}
                  {showDocInfo &&
                    !showPassportList &&
                    !displaySlaugher &&
                    truckDetail.status !== 'PLANNED' &&
                    (truckDetail.receiver_is_registered === 1 ||
                      (truckDetail.business_type !== '3' &&
                        truckDetail.receiver_is_registered === 0)) &&
                    this.renderDocuments(truckDetail)}
                  {showPassportList && this.renderPassports(truckDetail)}
                  {showDocInfo &&
                    (truckDetail.status === 'PLANNED' ||
                      (truckDetail.business_type === '3' &&
                        truckDetail.receiver_is_registered === 0)) &&
                    this.renderCattleGroups(truckDetail)}
                  {showLocInfo &&
                    this.renderMap(truckDetail.truck.gps_location)}
                  {showLocInfo &&
                    truckDetail.status !== 'PLANNED' &&
                    truckDetail.status !== 'SLAUGHTERED' &&
                    truckDetail.status !== 'UNLOADED' && (
                      <AppMap
                        locations={truckDetail.truck.locations}
                        paramEnd={truckDetail.buyer_place}
                        params_gps_location={truckDetail.truck.gps_location}
                        is_gps_updated={truckDetail.truck.is_gps_updated}
                        status={truckDetail.status}
                        seller={truckDetail.truck.locations[0].seller}
                        buyer={truckDetail.buyer}
                        driver={truckDetail.truck.driver}
                      />
                    )}
                </div>
              </div>
              {truckDetail.truck.status === 'PLANNED' ? (
                <>
                  {showTourInfo && (
                    <div className="button-truck-field btn-complete-outer btn-complete-outer-position">
                      <div className="btn-complete-inner">
                        <NormalButton
                          type="submit"
                          onClick={this.handleConfirmTimes}
                        >
                          <FormattedMessage {...messages.ConfirmTimes} />
                        </NormalButton>
                        <NormalButton
                          grey
                          type="submit"
                          onClick={(e) =>
                            this.deleteTourPlannedOrder(e, orderId)
                          }
                        >
                          <SvgIcon
                            color="white"
                            size="22"
                            viewBox="0 0 550 550"
                          >
                            {svgPaths['trash']}
                          </SvgIcon>
                          <FormattedMessage {...messages.Delete} />
                        </NormalButton>
                      </div>
                    </div>
                  )}
                  {showDocInfo && checkAddCattleForm && (
                    <div className="button-truck-field btn-complete-outer btn-complete-outer-position">
                      <div className="btn-complete-inner">
                        <Button
                          className="truck-complete truck-completed"
                          type="submit"
                          onClick={this.handleConfirmCattles}
                        >
                          <FormattedMessage {...messages.Save} />
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                showConfirm &&
                truckDetail.status !== 'PLANNED' && (
                  <div className="button-truck-field btn-complete-outer btn-complete-outer-position">
                    <div className="btn-complete-inner">
                      <Button
                        className="truck-complete truck-completed"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        {this.getName(this.state.buttonText)}
                      </Button>
                    </div>
                  </div>
                )
              )}
            </Card>
          </Col>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    deleteOrder: (orderId) => dispatch(OrderActions.deleteOrder(orderId)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourInfo);
