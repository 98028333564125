import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Image } from 'react-bootstrap';
import addTruckImg from '../../../static/images/admin/Icons/Status icons/addTruck.png';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import Form from './form/FormInput';
import FormComp from './form/FormInputCompany';

const ModalTruck = ({
  isShowing,
  hide,
  values,
  deleteTruck,
  type,
  setContact,
  editTruck,
  addTruck,
  index,
  deliveryDate,
  deliveryUntil,
  togglePopup,
  isBlockedItem
}) => {
  return isShowing
    ? ReactDOM.createPortal(
      <>
        <Modal
          className="tour-management-loading-modal"
          show={isShowing}
          onHide={hide}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <div className="modal-t-m-header">
              <Image width="120px" height="90px" src={addTruckImg} />
              <h5 className="card-title new-transport">
                <FormattedMessage {...messages.speditionsplanung} />
              </h5>
            </div>
            <Modal.Title />
          </Modal.Header>
          <Modal.Body>
            {values !== null ? (
              type === 'OWN_CARPOOL' || typeof type === 'undefined' ? (
                <Form
                  deleteTruckData={deleteTruck}
                  licence_number={values.licence_number}
                  delivery_date={values.delivery_date}
                  driver_name={values.driver_name}
                  delivery_until={values.delivery_until}
                  capacity={values.capacity}
                  pick_up_date={values.pick_up_date}
                  iconType={values.iconType}
                  togglePopup={togglePopup}
                  editeTruck={true}
                  editTransportTruck={editTruck}
                  type={type}
                  index={index}
                  open_request={values.open_request}
                  isBlockedItem={isBlockedItem}

                />
              ) : (
                <FormComp
                  deleteTruckData={deleteTruck}
                  licence_number={values.licence_number}
                  delivery_date={values.delivery_date}
                  delivery_until={values.delivery_until}
                  capacity={values.capacity}
                  transport_company_id={values.transport_company_id}
                  pick_up_date={values.pick_up_date}
                  iconType={values.iconType}
                  togglePopup={togglePopup}
                  editeTruck={true}
                  editTransportTruck={editTruck}
                  type={type}
                  index={index}
                  setContact={setContact}
                  open_request={values.open_request}
                  isBlockedItem={isBlockedItem}

                />
              )
            ) : type === 'OWN_CARPOOL' || typeof type === 'undefined' ? (
              <Form
                addTruckData={addTruck}
                togglePopup={togglePopup}
                editeTruck={false}
                delivery_date={deliveryDate}
                delivery_until={deliveryUntil}
                type={type}
                index={index}
                isBlockedItem={isBlockedItem}
              />
            ) : (
              <FormComp
                addTruckData={addTruck}
                togglePopup={togglePopup}
                editeTruck={false}
                delivery_date={deliveryDate}
                delivery_until={deliveryUntil}
                type={type}
                index={index}
                setContact={setContact}
                isBlockedItem={isBlockedItem}
              />
            )}
          </Modal.Body>
        </Modal>
      </>,
      document.body,
    )
    : null;
};

export default ModalTruck;
