import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Col,
  Row,
  Card,
  ButtonGroup,
  Button,
  FormGroup,
  ListGroupItem,
} from 'react-bootstrap';
import messages from '../../../components/Admin/messages';
import { getMedication } from '../../../services/stock.service';
import sordIcon from '../../../static/images/arrow_copie_2.svg';

import { alertActions } from '../../../actions';
import './drug.css';

class Drug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'active',
      eartagSorted: false,
      drugSorted: false,
      qteSorted: false,
      dateSorted: false,
    };
  }

  componentDidMount() {
    this.getAllMedications();
  }

  async getAllMedications() {
    let token = this.props.users.accessToken;
    const medication = await getMedication(token, 0);
    if (medication.status === 200) {
      // traitement
    } else {
      this.props.error(<FormattedMessage {...messages.NetworkProblem} />);
      this.props.clear();
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      type: e.target.value,
    });
  };

  backgroundChange = position => {
    if (this.state.itemBackground === position) {
      return '#f0fae3';
    }
    return '';
  };

  handleDateChangeRaw = e => {
    e.preventDefault();
  };

  render() {
    return (
      <Row className="orders view-drug">
        <Col className="orders-col" md={6} sm={12}>
          <Card className="card-box left-box">
            <Card.Body>
              <Row className="panel-body-row">
                <Col className="panel-body-col" xs={12} md={12}>
                  <ButtonGroup
                    value={this.state.type}
                    onClick={this.handleChange}
                    className="order-btn-groupe"
                  >
                    <div className="button-groupe-wrapper">
                      <Button
                        className={
                          this.state.type === 'active'
                            ? 'active active-tab-icon'
                            : ''
                        }
                        value={'active'}
                      >
                        Antibiotics
                      </Button>
                      <Button
                        className={
                          this.state.type === 'completed' ? 'active' : ''
                        }
                        value={'completed'}
                      >
                        Medication
                      </Button>
                    </div>
                  </ButtonGroup>
                  <FormGroup
                    controlId="formInlineName"
                    className="user-input-wrp date-time"
                  >
                    {this.state.type === 'active' && (
                      <ListGroupItem className="title-list-content">
                        <Row>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: !this.state.eartagSorted,
                                drugSorted: false,
                                qteSorted: false,
                                dateSorted: false,
                                arrowA: true,
                              });
                            }}
                            md={3}
                            className={
                              this.state.eartagSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            Eartag
                            <span>
                              {this.state.eartagSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: false,
                                drugSorted: !this.state.drugSorted,
                                qteSorted: false,
                                dateSorted: false,
                              });
                            }}
                            md={3}
                            className={
                              this.state.drugSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            <FormattedMessage {...messages.Drug} />
                            <span>
                              {this.state.drugSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: false,
                                drugSorted: false,
                                qteSorted: !this.state.qteSorted,
                                dateSorted: false,
                              });
                            }}
                            md={3}
                            className={
                              this.state.qteSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            <FormattedMessage {...messages.menge} />
                            <span>
                              {this.state.qteSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: false,
                                drugSorted: false,
                                qteSorted: false,
                                dateSorted: !this.state.dateSorted,
                              });
                            }}
                            md={3}
                            className={
                              this.state.dateSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            <FormattedMessage {...messages.datum} />
                            <span>
                              {this.state.dateSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                    {this.state.type === 'completed' && (
                      <ListGroupItem className="title-list-content">
                        <Row>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: !this.state.eartagSorted,
                                drugSorted: false,
                                qteSorted: false,
                                dateSorted: false,
                                arrowA: true,
                              });
                            }}
                            md={3}
                            className={
                              this.state.eartagSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            Eartag
                            <span>
                              {this.state.eartagSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: false,
                                drugSorted: !this.state.drugSorted,
                                qteSorted: false,
                                dateSorted: false,
                              });
                            }}
                            md={3}
                            className={
                              this.state.drugSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            <FormattedMessage {...messages.Drug} />
                            <span>
                              {this.state.drugSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: false,
                                drugSorted: false,
                                qteSorted: !this.state.qteSorted,
                                dateSorted: false,
                              });
                            }}
                            md={3}
                            className={
                              this.state.qteSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            <FormattedMessage {...messages.menge} />
                            <span>
                              {this.state.qteSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={3}
                            onClick={e => {
                              this.setState({
                                eartagSorted: false,
                                drugSorted: false,
                                qteSorted: false,
                                dateSorted: !this.state.dateSorted,
                              });
                            }}
                            md={3}
                            className={
                              this.state.dateSorted
                                ? 'title content-center borderBo'
                                : 'title content-center '
                            }
                          >
                            <FormattedMessage {...messages.datum} />
                            <span>
                              {this.state.dateSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: msg => dispatch(alertActions.success(msg)),
    error: error => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = state => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Drug));
