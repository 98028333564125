import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import SvgIcon from '../../icons/SvgIcon/SvgIcon';
import Icon from '../../icons/Icon/Icon';
import options from '../../../../../static/images/admin/options.svg';
import { svgPaths } from '../../../../../constants';
import './toggleMenuButton.scss';

const propTypes = {
  id: PropTypes.string,
  nature: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.node.isRequired,
  menuItems: PropTypes.array,
  menuRole: PropTypes.string,
  rootCloseEvent: PropTypes.string,
  href: PropTypes.string,
  block: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  selectNumber: PropTypes.bool,
  widthDeutsch: PropTypes.string,
  green: PropTypes.bool,
};

const defaultProps = {
  nature: 'green',
  active: false,
  disabled: false,
  id: 'dropdown',
};

const ToggleMenuButton = React.forwardRef(
  (
    {
      id,
      title,
      icon,
      nature,
      size,
      width,
      active,
      className,
      disabled,
      href,
      block,
      menuItems,
      children,
      menuRole,
      rootCloseEvent,
      lang,
      animateListButton,
      selectNumber,
      widthDeutsch,
      green,
      ...props
    },
    ref,
  ) => {
    const prefix = 'toggle-menu-btn';
    const classes = classNames(
      className,
      prefix,
      active && 'active',
      nature && `${prefix}-${nature}`,
      block && `${prefix}-block`,
      lang && lang === 'DE' && `${prefix}-deutsch`,
      disabled,
      selectNumber && 'select-number',
      href,
      animateListButton,
      width && 'dropdown-width',
      green && 'green-color',
    );

    if (ref) {
      props.ref = ref;
    }

    const dropDownStyles = {
      width: lang === 'DE' ? widthDeutsch : width,
    };

    return (
      <div id="toggle-dropup" className={classes}>
        <div className="dropup btn-group">
          <Dropdown
            id={id}
            ref={ref}
            drop="up"
            {...props}
            style={dropDownStyles}
          >
            <Dropdown.Toggle
              href={href}
              size={size}
              nature={nature}
              disabled={disabled}
              className={animateListButton ? 'animate-list' : null}
            >
              <div className="btn-content">
                <SvgIcon
                  color={green ? 'white' : '#7ad810'}
                  size="25"
                  viewBox="0 0 600 600"
                >
                  {svgPaths['settings']}
                </SvgIcon>
                <div className="btn-title">{title}</div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu role={menuRole} rootCloseEvent={rootCloseEvent}>
              {children}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  },
);

ToggleMenuButton.displayName = 'ToggleMenuButton';
ToggleMenuButton.propTypes = propTypes;
ToggleMenuButton.defaultProps = defaultProps;

export default ToggleMenuButton;
