/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  homeMenuMessage: {
    id: 'agrabiz.components.Menu.home',
    defaultMessage: 'Home',
  },
  aboutMenuMessage: {
    id: 'agrabiz.components.Menu.about',
    defaultMessage: 'About',
  },
  featuresMenuMessage: {
    id: 'agrabiz.components.Menu.features',
    defaultMessage: 'Features',
  },
  screenshotsMenuMessage: {
    id: 'agrabiz.components.Menu.screenshots',
    defaultMessage: 'Screenshots',
  },
  pricingMenuMessage: {
    id: 'agrabiz.components.Menu.pricing',
    defaultMessage: 'Pricing',
  },
  teamMenuMessage: {
    id: 'agrabiz.components.Menu.team',
    defaultMessage: 'Team',
  },
  testimonialMenuMessage: {
    id: 'agrabiz.components.Menu.testimonial',
    defaultMessage: 'Testimonial',
  },
  contactsMenuMessage: {
    id: 'agrabiz.components.Menu.contacts',
    defaultMessage: 'Contacts',
  },
  accountMenuMessage: {
    id: 'agrabiz.components.Menu.account',
    defaultMessage: 'Account',
  },
});
