import { orderConstants, alertConstants } from '../constants';
import { OrderService } from '../services';
import extractData from '../services/data.processing';
export const OrderActions = {
  getOrders,
  confirmOrder,
  acceptOrder,
  getResales,
  deleteOrder,
  resetBuy,
  resetSell,
};

function getOrders(orderType, type, page, date) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      const response = await OrderService.getOrders(
        orderType,
        accessToken,
        type,
        page,
        date,
      );
      if (orderType === 'buyings') {
        const getBuyings = await extractData(response);
        const buyings = {
          items: getBuyings.items,
          stats: getBuyings.stats,
          dates: getBuyings.dates,
        };

        await dispatch({ type: orderConstants.BUYINGS_GET_ALL, buyings });
      } else if (orderType === 'sellings') {
        const getSellings = await extractData(response);
        const sellings = {
          items: getSellings.items,
          stats: getSellings.stats,
          dates: getSellings.dates,
        };
        await dispatch({ type: orderConstants.SELLINGS_GET_ALL, sellings });
      }
    } catch (error) {
      let errorMsg = 'Network Error' + error;
      dispatch({ type: alertConstants.ERROR, errorMsg });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function getResales(orderId) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      const response = await OrderService.getResaleOverview(
        orderId,
        accessToken,
      );
      const resaleInformations = await extractData(response);
      return resaleInformations;
    } catch (error) {}
  };
}

function confirmOrder(orderId, recieverId, data, isBuyer) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      await OrderService.confirmOrder(orderId, accessToken, recieverId, data);
      const response = await OrderService.getOrderForOneBidder(
        orderId,
        accessToken,
        recieverId,
      );
      const dataa = await extractData(response);
      const order = dataa.order;
      isBuyer
        ? await dispatch({ type: orderConstants.BUY_CONFIRM, order })
        : await dispatch({ type: orderConstants.SELL_CONFIRM, order });
    } catch (error) {}
  };
}

function acceptOrder(orderId, recieverId, data, isBuyer) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      await OrderService.acceptOrder(orderId, accessToken, recieverId, data);
      const response = await OrderService.getOrderForOneBidder(
        orderId,
        accessToken,
        recieverId,
      );
      const dataa = await extractData(response);
      const order = dataa.order;
      isBuyer
        ? await dispatch({ type: orderConstants.BUY_ACCEPTED, order })
        : await dispatch({ type: orderConstants.SELL_ACCEPTED, order });
    } catch (error) {}
  };
}
function deleteOrder(orderId) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      await OrderService.deleteOrder(accessToken, orderId);
      await dispatch({ type: orderConstants.BUY_DELETE, orderId });
    } catch (error) {}
  };
}

function resetBuy() {
  return async (dispatch) => {
    const resetBuyings = [];
    await dispatch({ type: orderConstants.RESET_BUY, resetBuyings });
  };
}

function resetSell() {
  return async (dispatch) => {
    const resetSellings = [];
    await dispatch({ type: orderConstants.RESET_SELL, resetSellings });
  };
}
