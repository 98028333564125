import { orderConstants } from '../constants';

const initialState = {
  buyings: {},
};

export function buyings(state = initialState, action) {
  switch (action.type) {
    case orderConstants.BUYINGS_GET_ALL:
      return {
        ...state,
        buyings: action.buyings,
      };
    case orderConstants.BUY_CONFIRM:
      const buyConfirmed = action.order;
      return {
        ...state,
        buyings: {
          items: [...state.buyings.items, buyConfirmed],
          stats: state.buyings.stats,
        },
      };
    case orderConstants.BUY_ACCEPTED:
      const sellAccepted = action.order;
      return {
        ...state,
        buyings: {
          items: [...state.buyings.items, sellAccepted],
          stats: state.buyings.stats,
        },
      };
    case orderConstants.BUY_DELETE:
      const buyToDelete = action.orderId;
      const prevState = [...state.buyings.items];
      prevState.splice(
        prevState.findIndex(e => e.order_id === buyToDelete),
        1,
      );
      return {
        ...state,
        buyings: {
          items: [...state.buyings.items],
          stats: state.buyings.stats,
        },
      };

    case orderConstants.RESET_BUY:
      return {
        ...state,
        buyings: action.resetBuyings,
      };

    default:
      return state;
  }
}
