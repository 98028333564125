import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import TourManagementIcon from '../../../../static/images/admin/Icons/Status icons/TourManagementIcon.png';
import { connect } from 'react-redux';
import menu from '../../../../static/images/admin/icons_final/menu.svg';

class TourInformation extends Component {
  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  renderListTour = () => {
    const { item, isToBeLoaded } = this.props;
    return (
      <Row className="tours-top-container">
        {!isToBeLoaded && (
          <Col>
            <hr className="style-border" />
          </Col>
        )}
        <Col md={3} className="tours-image">
          <Image style={{ width: '80px' }} src={TourManagementIcon} />
        </Col>
        <Col className="tour-text-groupe add-eartag" md={6}>
          <span className="tour-name">
            {this.findAndReplace(item && item.name)}
          </span>
          <span className="tour-item">
            {item.delivery_date} |{' '}
            {isToBeLoaded ? item.recipient : item.business_partner}{' '}
          </span>
        </Col>
        {!isToBeLoaded && (
          <Col md={3} className="tour-image menu">
            <Image width="20%" src={menu} />
          </Col>
        )}
      </Row>
    );
  };

  render() {
    return <React.Fragment>{this.renderListTour()}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  const { language } = state.toJS();
  return { language };
};

export default connect(mapStateToProps, null)(TourInformation);
