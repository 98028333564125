import React from 'react';
import { connect } from 'react-redux';
import './tourManagementInfo.css';
import TourList from './TourList';
import { alertActions, OrderActions } from '../../../../actions';

const TourManagement = (props) => {
  const {
    orderDetail,
    setShowForm,
    orderType,
    type,
    handleClosePanel,
    assigned,
    ordersTab,
    pushNotification,
    getToursDate,
    changeTab,
    changeDate,
    getOrders,
    displayBuyRequestEdit,
  } = props;
  return (
    <React.Fragment>
      {typeof assigned === 'undefined' ? (
        <TourList
          orderDetail={orderDetail}
          setShowForm={setShowForm}
          token={props.users.accessToken}
          company_id={props.users.user.session.company_id}
          orderType={orderType}
          typeOrder={type}
          handleClosePanel={handleClosePanel}
          usersInfo={props.users}
          changeTab={changeTab}
          changeDate={changeDate}
          getOrders={getOrders}
          displayBuyRequestEdit={displayBuyRequestEdit}
        />
      ) : (
        <TourList
          orderDetail={orderDetail}
          ordersTab={ordersTab}
          setShowForm={setShowForm}
          token={props.users.accessToken}
          company_id={props.users.user.session.company_id}
          orderType={orderType}
          typeOrder={type}
          handleClosePanel={handleClosePanel}
          assigned={true}
          usersInfo={props.users}
          pushNotification={
            typeof pushNotification !== 'undefined' ? 'true' : ''
          }
          getToursDate={getToursDate}
          changeTab={changeTab}
          changeDate={changeDate}
          getOrders={getOrders}
          displayBuyRequestEdit={displayBuyRequestEdit}
        />
      )}
    </React.Fragment>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getOrders: (orderType, type, page, date) =>
      dispatch(OrderActions.getOrders(orderType, type, page, date)),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourManagement);
