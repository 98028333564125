export const races = [
  {
    value: 'SBT',
    label: 'Holstein-Sbt',
  },
  {
    value: 'RBT',
    label: 'Holstein-Rbt',
  },
  {
    value: 'JER',
    label: 'Jersey',
  },
  {
    value: 'BV',
    label: 'Braunvieh',
  },
  {
    value: 'RVA',
    label: 'Angler',
  },
  {
    value: 'RV',
    label: 'Rotvieh alter Angler Zuchtrichtung',
  },
  {
    value: 'RDN',
    label: 'Doppelnutzung Rotbunt',
  },
  {
    value: 'DSN',
    label: 'Deutsches Schwarzbuntes Niederungsrind',
  },
  {
    value: 'FL',
    label: 'Fleckvieh',
  },
  {
    value: 'GV',
    label: 'Gelbvieh',
  },
  {
    value: 'PIN',
    label: 'Pinzgauer',
  },
  {
    value: 'HIN',
    label: 'Hinterwälder',
  },
  {
    value: 'MW',
    label: 'Murnau-Werdenfelser',
  },
  {
    value: 'VW',
    label: 'Vorderwälder',
  },
  {
    value: 'LMP',
    label: 'Limpurger',
  },
  {
    value: 'BVA',
    label: 'Braunvieh alter Zuchtrichtung',
  },
  {
    value: 'AS',
    label: 'Ayrshire',
  },
  {
    value: 'VR',
    label: 'Vogesen-Rind',
  },
  {
    value: 'CHA',
    label: 'Charolais',
  },
  {
    value: 'LIM',
    label: 'Limousin',
  },
  {
    value: 'WBB',
    label: 'Weißblaue Belgier',
  },
  {
    value: 'BA',
    label: "Blonde d'Aquitaine",
  },
  {
    value: 'MA',
    label: 'Maine-Anjou',
  },
  {
    value: 'SAL',
    label: 'Salers',
  },
  {
    value: 'MON',
    label: 'Montbeliard',
  },
  {
    value: 'AU',
    label: 'Aubrac',
  },
  {
    value: 'PIE',
    label: 'Piemonteser',
  },
  {
    value: 'CHI',
    label: 'Chianina',
  },
  {
    value: 'ROM',
    label: 'Romagnola',
  },
  {
    value: 'MAR',
    label: 'Marchigiana',
  },
  {
    value: 'WP',
    label: 'White Park',
  },
  {
    value: 'DA',
    label: 'Angus',
  },
  {
    value: 'AA',
    label: 'Angus/AA',
  },
  {
    value: 'HE',
    label: 'Hereford',
  },
  {
    value: 'SH',
    label: 'Deutsches Shorthorn',
  },
  {
    value: 'HLD',
    label: 'Highland',
  },
  {
    value: 'WB',
    label: 'Welsh-Black',
  },
  {
    value: 'GAL',
    label: 'Galloway',
  },
  {
    value: 'LR',
    label: 'Lincoln Red',
  },
  {
    value: 'BGA',
    label: 'Belted Galloway',
  },
  {
    value: 'LG',
    label: 'Luing',
  },
  {
    value: 'BRA',
    label: 'Brangus',
  },
  {
    value: 'NOR',
    label: 'Normanne',
  },
  {
    value: 'UST',
    label: 'Ungarisches Steppenrind',
  },
  {
    value: 'ZEB',
    label: 'Zwerg-Zebus',
  },
  {
    value: 'GRV',
    label: 'Grauvieh',
  },
  {
    value: 'DEX',
    label: 'Dexter',
  },
  {
    value: 'DEX',
    label: 'Dexter',
  },
  {
    value: 'WGA',
    label: 'White Galloway',
  },
  {
    value: 'LH',
    label: 'White Galloway',
  },
  {
    value: 'SD',
    label: 'South Devon',
  },
  {
    value: 'FR',
    label: 'Fjäll-Rind',
  },
  {
    value: 'TUX',
    label: 'Tuxer',
  },
  {
    value: 'TLM',
    label: 'Telemark',
  },
  {
    value: 'FLF',
    label: 'Fleckvieh Fleischnutzung',
  },
  {
    value: 'UCK',
    label: 'Uckermärker',
  },
  {
    value: 'BLA',
    label: 'Blaarkop',
  },
  {
    value: 'WIT',
    label: 'Witrug',
  },
  {
    value: 'LAK',
    label: 'Lakenfelder',
  },
  {
    value: 'RHV',
    label: 'Rotes Höhenvieh',
  },
  {
    value: 'AT',
    label: 'Ansbach-Triesdorfer',
  },
  {
    value: 'GR',
    label: 'Glanrind',
  },
  {
    value: 'PIF',
    label: 'Pinzgauer Fleischnutzung',
  },
  {
    value: 'PS',
    label: 'Pustertaler Schecken',
  },
  {
    value: 'GVF',
    label: 'Gelbvieh Fleischnutzung',
  },
  {
    value: 'BVF',
    label: 'Braunvieh Fleischnutzung',
  },
  {
    value: 'RBF',
    label: 'Rotbunt Fleischnutzung',
  },
  {
    value: 'HWF',
    label: 'Hinterwälder Fleischnutzung',
  },
  {
    value: 'MWF',
    label: 'Murnau-Werdenfelser Fleischnutzung',
  },
  {
    value: 'VWF',
    label: 'Vorderwälder Fleischnutzung',
  },
  {
    value: 'LPF',
    label: 'Limpurger Fleischnutzung',
  },
  {
    value: 'BRN',
    label: 'Brahman',
  },
  {
    value: 'BAZ',
    label: 'Bazadaise',
  },
  {
    value: 'AO',
    label: 'Auerochse (Heckrind, Rückkreuzung Auerochse)',
  },
  {
    value: 'BE',
    label: 'Beefalo',
  },
  {
    value: 'WL',
    label: 'Wasserbüffel',
  },
  {
    value: 'BIS',
    label: 'Bison/Wisent',
  },
  {
    value: 'YAK',
    label: 'Yak',
  },
  {
    value: 'SON',
    label: 'Sonstige Rassen',
  },
  {
    value: 'TAU',
    label: 'Sonstige taurine Rinder (Bos taurus)',
  },
  {
    value: 'IND',
    label: 'Sonstige Zebu-Rinder (Bos indicus)',
  },
  {
    value: 'TIN',
    label: 'Sonstige taur-indicus Rinder',
  },
  {
    value: 'WAG',
    label: 'Wagyu',
  },
  {
    value: 'XFF',
    label: 'Kreuzung Fleischrind x Fleischrind',
  },
  {
    value: 'XFM',
    label: 'Kreuzung Fleischrind x Milchrind',
  },
  {
    value: 'XMM',
    label: 'Kreuzung Milchrind x Milchrind',
  },
];
