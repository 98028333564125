import React, { Component } from 'react';
import { MdNavigateBefore } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import cercleGreen from '../../../static/images/cerclegreen.jpg';
import cercleGris from '../../../static/images/cerclegris.png';
import cercleOrange from '../../../static/images/cercleOrange.png';

import './cv.css';

class Cv extends Component {
  getImage = item => {
    if (item === 'ZUGANG' || item === 'GEBURT') {
      return cercleGreen;
    }
    if (item === 'SLAUGHTERED') {
      return cercleOrange;
    } else {
      return cercleGris;
    }
  };
  renderInfo = (item, index) => {
    return (
      <div className="stages " key={index}>
        <div className="blocBirth newBlock-cv">
          <div className="birthCv newBlock-cv-b1" md={1}>
            <img
              className="newBlock-cv-img"
              src={this.getImage(item.type)}
              alt=""
            />
          </div>
          <div className=" text-noir-report-cv newBlock-cv-b2" md={3}>
            {item.type}
          </div>
          <div md={3} className=" text-noir-report-cv newBlock-cv-b2">
            {item.date}
          </div>
          <div
            md={3}
            className=" text-noir-report-cv newBlock-cv-b3 text-right-cv"
          >
            <div className="">{item.county_code_and_federal_state}</div>
            <div className="">{item.company_number_or_name}</div>
          </div>
        </div>
        <hr className="style-border" />
      </div>
    );
  };
  render() {
    const { data } = this.props;
    return (
      <div>
        <div style={{ padding: '20px 30px' }}>
          <div
            className="tobeloade-title-block"
            style={{ marginBottom: '20px' }}
          >
            <h5 className="card-title first-block">
              {' '}
              <FormattedMessage {...messages.TierlebenslaufAnzeigen} />
            </h5>
          </div>
          <div className="navigate-before">
            <div md={3}>
              <MdNavigateBefore
                className="before"
                onClick={() => this.props.showCv(false)}
              />
            </div>
          </div>
        </div>
        <div className="cv-b1-flex">
          {data.map((item, index) => this.renderInfo(item, index))}
        </div>
      </div>
    );
  }
}

export default Cv;
