import React from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../../components/CustomSelect';
import './select.css';

const customStyles = {
  singleValue: (provided, state) => {
    return { ...provided, color: '#7bd812' };
  },
  menu: (provided, state) => ({
    ...provided,
    color: '#7bd812',
    width: '70px',
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: '',
    width: '70px',
    borderColor: 'rgba(209, 209, 209, 0.97)',
    '&:hover': { borderColor: '#7bd812' },
  }),
};

export class Toggle extends React.PureComponent {
  renderListOptions = (list) => {
    let SelectedLangage = this.props.value;
    let options = [];
    list.map((val) => options.push({ value: val, label: val }));
    return {
      selected: {
        value: SelectedLangage,
        label: SelectedLangage,
      },
      options: options,
    };
  };

  render() {
    return (
      <CustomSelect
        className="selectCompany "
        defaultValue={this.renderListOptions(this.props.values).selected}
        onChange={this.props.onToggle}
        options={this.renderListOptions(this.props.values).options}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 3,
          colors: {
            ...theme.colors,
            primary25: '#F1FFDE',
            primary: '#7bcd10',
          },
        })}
      />
    );
  }
}

Toggle.propTypes = {
  onToggle: PropTypes.func,
  values: PropTypes.array,
  value: PropTypes.string,
  messages: PropTypes.object,
};
export default Toggle;
