import { companyConstants } from '../constants';
const initialState = {
  company: {},
  step_register: 1,
};
export function company(state = initialState, action) {
  switch (action.type) {
    case companyConstants.CHANGE_STEP:
      return {
        ...state,
        ...action.company,
        step_register: action.step,
        business_type: action.business_type,
        producer: action.producer,
        company: action.company,
      };

    default:
      return state;
  }
}
