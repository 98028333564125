import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function SkeletonLoader() {
  const [windowDimensions] = useState(getWindowDimensions());

  const SKELETON_VALUES = {
    element1Y: 35,
    element2Y: 60,
    element3Y: 55,
  };

  return (
    <ContentLoader
      speed={2}
      width={1000}
      height={450}
      viewBox="0 0 1000 450"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {[...Array(5)].map((_, i) => {
        if (i !== 0) {
          SKELETON_VALUES.element1Y += 85;
          SKELETON_VALUES.element2Y += 85;
          SKELETON_VALUES.element3Y += 85;
        }
        return (
          <>
            <rect
              x={windowDimensions.width < 1460 ? '110' : '145'}
              y={SKELETON_VALUES.element1Y}
              rx="3"
              ry="3"
              width={windowDimensions.width < 1460 ? '280' : '350'}
              height="7"
            />
            <rect
              x={windowDimensions.width < 1460 ? '110' : '145'}
              y={SKELETON_VALUES.element2Y}
              rx="3"
              ry="3"
              width="234"
              height="7"
            />
            <circle
              cx={windowDimensions.width < 1460 ? '45' : '75'}
              cy={SKELETON_VALUES.element3Y}
              r="25"
            />
          </>
        );
      })}
    </ContentLoader>
  );
}

export default SkeletonLoader;