import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import moment from 'moment-es6';

class StockItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCheck = (e) => {
    if (e.target.checked) {
      this.props.checkAnimal();
    } else {
      this.props.removeAnimalFromListChecked();
    }
  };

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  monthDiff(startDate, endDate) {
    const diffInMs = new Date(endDate) - new Date(startDate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return isNaN(diffInDays)
      ? '-'
      : this.round(diffInDays / 30.5, 1)
          .toString()
          .replace('.', ',');
  }

  getText(text) {
    if (text.length > 7) {
      return text.slice(0, 8);
    } else return text;
  }

  getWeek() {
    var myarr = this.props.data.birth_date.split('.');
    //get todays date
    var current_date = new Date();
    var currentY = current_date.getFullYear().toString().substr(2, 4);
    let past_date;
    if (parseInt(myarr[2]) > parseInt(currentY))
      past_date = new Date(
        `${'19' + myarr[2] + '/' + myarr[1] + '/' + myarr[0]}`,
      );
    else
      past_date = new Date(
        `${'20' + myarr[2] + '/' + myarr[1] + '/' + myarr[0]}`,
      );
    var difference = this.monthDiff(past_date, current_date);

    return difference;
  }

  getItem = (box, passport) => {
    var id = 'checkbox' + this.props.data.ear_tag_output_format;
    const { editItem } = this.props;
    const passpord = passport !== '' && typeof passport !== 'undefined';
    const sellOffer = this.props.data.is_available;
    return (
      <div>
        {((passport && sellOffer === 0) || (!passport && sellOffer === 0)) && (
          <div onClick={editItem} className="view-Stock-line">
            <div xs={1} md={1} className="view-Stock-line-b1 ">
              <div className="round"></div>
            </div>
            <div
              xs={3}
              md={3}
              className=" view-Stock-line-b2 content-center not-available"
            >
              {this.formatEarTag(this.props.data.ear_tag_output_format)}
            </div>
            <div
              xs={2}
              md={2}
              className=" view-Stock-line-b3 content-center not-available"
            >
              {this.getWeek()} m
            </div>
            <div
              xs={2}
              md={2}
              className=" view-Stock-line-b4 content-center not-available"
            >
              {typeof this.props.data.race !== 'undefined' &&
                this.props.data.race}
            </div>
            <div
              xs={2}
              md={2}
              className=" view-Stock-line-b5 content-center not-available"
            >
              {typeof this.props.data.breed !== 'undefined' &&
                this.props.data.breed}
            </div>
            <div
              xs={1}
              md={1}
              className="view-Stock-line-b6 blockB content-center not-available "
            >
              {typeof this.props.data.box !== 'undefined' &&
                this.props.data.box}
            </div>
          </div>
        )}
        {passpord && sellOffer === 1 && (
          <div className="view-Stock-line">
            <div xs={1} md={1} className="">
              <div className="round view-Stock-line-b1">
                <input
                  type="checkbox"
                  checked={this.props.checked === -1 ? false : true}
                  id={id}
                  onChange={this.handleCheck}
                  disabled={this.props.hande}
                />
                <label htmlFor={id} />
              </div>
            </div>
            <div
              onClick={editItem}
              xs={3}
              md={3}
              className="view-Stock-line-b2 with-passport content-center"
            >
              {this.formatEarTag(this.props.data.ear_tag_output_format)}
            </div>
            <div
              onClick={editItem}
              xs={2}
              md={2}
              className=" view-Stock-line-b3 with-passport content-center"
            >
              {this.getWeek()} m
            </div>
            <div
              onClick={editItem}
              xs={2}
              md={2}
              className=" view-Stock-line-b4 with-passport content-center"
            >
              {typeof this.props.data.race !== 'undefined' &&
                this.props.data.race}
            </div>
            <div
              onClick={editItem}
              xs={2}
              md={2}
              className=" view-Stock-line-b5 with-passport content-center"
            >
              {typeof this.props.data.breed !== 'undefined' &&
                this.props.data.breed}
            </div>
            <div
              onClick={editItem}
              xs={1}
              md={1}
              className="view-Stock-line-b6 blockB with-passport content-center"
            >
              {typeof this.props.data.box !== 'undefined' &&
                this.props.data.box}
            </div>
          </div>
        )}

        {!passpord && sellOffer === 1 && (
          <div className="view-Stock-line">
            <div xs={1} md={1} className="">
              <div className="view-Stock-line-b1 round">
                <input
                  type="checkbox"
                  checked={this.props.checked === -1 ? false : true}
                  id={id}
                  onChange={this.handleCheck}
                  disabled={this.props.hande}
                />
                <label htmlFor={id} />
              </div>
            </div>
            <div
              onClick={editItem}
              xs={3}
              md={3}
              className="view-Stock-line-b2 content-center"
            >
              {this.formatEarTag(this.props.data.ear_tag_output_format)}
            </div>
            <div
              onClick={editItem}
              xs={2}
              md={2}
              className="view-Stock-line-b3 content-center"
            >
              {this.getWeek()} m
            </div>
            <div
              onClick={editItem}
              xs={2}
              md={2}
              className="view-Stock-line-b4 content-center"
            >
              {typeof this.props.data.race !== 'undefined' &&
                this.props.data.race}
            </div>
            <div
              onClick={editItem}
              xs={2}
              md={2}
              className="view-Stock-line-b5 content-center"
            >
              {typeof this.props.data.breed !== 'undefined' &&
                this.props.data.breed}
            </div>
            <div
              onClick={editItem}
              xs={1}
              md={1}
              className="view-Stock-line-b6 blockB content-center"
            >
              {typeof this.props.data.box !== 'undefined' &&
                this.props.data.box}
            </div>
          </div>
        )}
      </div>
    );
  };

  formatEarTag = (tag) => {
    tag = tag.replace(/\s/g, '');
    var output =
      tag.substr(0, 2) +
      ' ' +
      tag.substr(2, 2) +
      ' ' +
      tag.substr(4, 3) +
      ' ' +
      tag.substr(7, tag.length - 6);
    return output;
  };

  render() {
    const { passport, box } = this.props.data;
    return (
      <React.Fragment>
        <ListGroupItem>{this.getItem(box, passport)}</ListGroupItem>
      </React.Fragment>
    );
  }
}

export default StockItem;
