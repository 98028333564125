import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  Card,
  ListGroup,
  ButtonGroup,
  FormControl,
  FormGroup,
  InputGroup,
  ListGroupItem,
  Modal,
  Jumbotron,
} from 'react-bootstrap';
import clsx from 'clsx';
import { MdNavigateBefore } from 'react-icons/md';
import ReactGA from 'react-ga';
import InfiniteScroll from 'react-infinite-scroller';
import Slider from 'rc-slider';
import { uniqBy, intersection, debounce, map, includes, remove } from 'lodash';
import {
  getStocks,
  getPassportStock,
  getLastUpdateDate,
} from '../../../services/stock.service';
import extractData from '../../../services/data.processing';
import { connect } from 'react-redux';
import StockItem from './StockItem';
// import moment from 'moment-es6';
import { MdSearch, MdClose } from 'react-icons/md';
import { alertActions } from '../../../actions';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import sordIcon from '../../../static/images/arrow_copie_2.svg';
import { stocksActions } from '../../../actions/stock.actions';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';
import '../Orders/toBeLoaded/toBeLoaded.css';
import './viewStockLoaded.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class ViewStockLoaded extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'active',
      ear_tag_search: '',
      box_search: '',
      stocks: [],
      listChecked: [],
      stockSelected: {},
      displayChecked: [],
      ear_tag: '',
      ear_tag_output_format: '',
      sort_ear_tag: '1',
      sort_race: '1',
      sort_breed: '1',
      sort_birth_date: '1',
      sort_box: '1',
      showForm: false,
      showFilter: false,
      showEartagDetails: false,
      passport: '',
      box_number: 0,
      checkedItems: new Map(),
      checkedItemsBreed: new Map(),
      searchEart: '',
      page: 0,
      allStocks: [],
      pageMax: 0,
      eartagSorted: false,
      ageSorted: false,
      raceSorted: false,
      breedSorted: false,
      boxSorted: false,
      newBlock: false,
      detailStock: false,
      block_api: false,
      stocksPagination: [],
      items: [],
      hasMoreItem: true,
      cursor: 0,
      Update: false,
      addBloqued: false,
      ListLoaded:
        typeof props.toBeLoadedEartags !== 'undefined'
          ? props.toBeLoadedEartags
          : [],
      AllListLoaded:
        typeof props.toBeLoadedEartags !== 'undefined'
          ? props.toBeLoadedEartags
          : [],

      toBeLoadedCheckedNumber: 0,
      assignedCheckedNumber: 0,
    };
  }
  lastUpdate = async () => {
    let token = this.props.users.accessToken;
    const vals = await getLastUpdateDate(token);
    let date = await extractData(vals);
    if (this.props.allstock.dateUpdate < new Date(date)) {
      this.setState({ Update: true });
      this.getAllStocks();
    }
  };

  UNSAFE_componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidMount() {
    this.lastUpdate();
    if (this.props.allstock.allstock.length === 0) {
      this.getAllStocks();
    } else {
      this.getAllStocks();
    }
  }

  fetchMoreData = async () => {
    if (this.props.allstock.allstock.length > 2) {
      this.setState({
        items: this.state.items.concat(
          this.props.allstock.allstock.slice(
            this.state.cursor * 100,
            this.state.cursor * 100 + 100,
          ),
        ),
        cursor: this.state.cursor + 1,
      });
      if (this.state.cursor * 100 + 100 > this.props.allstock.allstock.length) {
        this.setState({ hasMoreItem: false });
      }
    }
  };

  handleChange = (e) => {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  handleChangeVal = (type) => {
    this.setState({
      type: type,
    });
  };

  handleCheckboxChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  handleCheckboxChangeBreed = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItemsBreed: prevState.checkedItemsBreed.set(item, isChecked),
    }));
  };

  resetFormFilter = async () => {
    var page = 0;
    var stocksPagination = [];
    var block_api = false;
    var stocks = [];
    await this.props.setStocks(page, stocksPagination, block_api, stocks);
    await this.setState({
      stocks: this.props.allstock.allstock.filter((e) => e.is_available === 1),
      items: this.props.allstock.allstock
        .filter((e) => e.is_available === 1)
        .slice(0, 100),
      box_number: 0,
      checkedItems: new Map(),
      checkedItemsBreed: new Map(),
      selectAll: false,
      ListLoaded:
        typeof this.props.toBeLoadedEartags !== 'undefined'
          ? this.props.toBeLoadedEartags
          : [],
    });
  };

  resetBox = () => {
    this.setState({
      newBlock: false,
    });
  };

  async getAllStocks() {
    this.getStocksAll();
  }

  async getStocksAll() {
    var page = 0;
    var stocksPagination = [];
    var block_api = false;
    var stocks = [];
    if (!this.props.sellOffer) {
      if (this.props.allstock.allstock.length === 0 || this.state.Update) {
        await this.props.setStocks(page, stocksPagination, block_api, stocks);
        this.setState({
          stocks: this.props.allstock.allstock,
          allStocks: this.props.allstock.allstock,
          items: this.props.allstock.allstock.slice(0, 100),
        });
      } else {
        this.setState({
          stocks: this.props.allstock.allstock,
          allStocks: this.props.allstock.allstock,
          items: this.props.allstock.allstock.slice(0, 100),
        });
      }
    } else {
      if (this.props.allstock.allstock.length === 0 || this.state.Update) {
        await this.props.setStocks(page, stocksPagination, block_api, stocks);
        this.setState({
          stocks: this.props.allstock.allstock.filter(
            (e) => e.is_available === 1,
          ),
          allStocks: this.props.allstock.allstock.filter(
            (e) => e.is_available === 1,
          ),
          items: this.props.allstock.allstock
            .filter((e) => e.is_available === 1)
            .slice(0, 100),
        });
        if (
          typeof this.props.breeds !== 'undefined' &&
          this.props.breeds.length > 0 &&
          typeof this.props.races !== 'undefined' &&
          this.props.races.length > 0
        ) {
          this.setState({
            stocks: this.props.allstock.allstock
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  e.breed === this.props.breeds[0] &&
                  e.race === this.props.races[0],
              ),
            allStocks: this.props.allstock.allstock
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  e.breed === this.props.breeds[0] &&
                  e.race === this.props.races[0],
              ),
            items: this.props.allstock.allstock
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  e.breed === this.props.breeds[0] &&
                  e.race === this.props.races[0],
              )
              .slice(0, 100),
          });
        }
      } else {
        this.setState({
          stocks: this.props.allstock.allstock.filter(
            (e) => e.is_available === 1,
          ),
          allStocks: this.props.allstock.allstock.filter(
            (e) => e.is_available === 1,
          ),
          items: this.props.allstock.allstock
            .filter((e) => e.is_available === 1)
            .slice(0, 100),
        });
        if (
          typeof this.props.breeds !== 'undefined' &&
          this.props.breeds.length > 0 &&
          typeof this.props.races !== 'undefined' &&
          this.props.races.length > 0
        ) {
          this.setState({
            stocks: this.props.allstock.allstock
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  e.breed === this.props.breeds[0] &&
                  e.race === this.props.races[0],
              ),
            allStocks: this.props.allstock.allstock
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  e.breed === this.props.breeds[0] &&
                  e.race === this.props.races[0],
              ),
            items: this.props.allstock.allstock
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  e.breed === this.props.breeds[0] &&
                  e.race === this.props.races[0],
              )
              .slice(0, 100),
          });
        }
      }
    }
  }

  async getStocks(filter = 'all', page = 0) {
    let token = this.props.users.accessToken;
    let company_number = this.props.users.user.session.company_number;
    var sort_filter;
    // eslint-disable-next-line default-case
    switch (filter) {
      case 'ear_tag':
        this.setState({ sort_ear_tag: this.state.sort_ear_tag * -1 });
        sort_filter = { ear_tag: this.state.sort_ear_tag };
        break;
      case 'race':
        sort_filter = { race: '-1' };
        break;
      case 'breed':
        this.setState({ sort_breed: this.state.sort_breed * -1 });
        sort_filter = { breed: this.state.sort_breed };
        break;
      case 'box':
        this.setState({ sort_box: this.state.sort_box * -1 });
        sort_filter = { box: this.state.sort_box };
        break;
      case 'birth_date':
        this.setState({ sort_birth_date: this.state.sort_birth_date * -1 });
        sort_filter = { birth_date: this.state.sort_birth_date };
        break;
      case 'all':
        sort_filter = {
          ear_tag: this.state.sort_ear_tag,
          race: this.state.sort_race,
          breed: this.state.sort_breed,
          box: this.state.sort_box,
          birth_date: this.state.sort_birth_date,
        };
        break;
    }
    const value = this.props.sellOffer
      ? await getStocks(
          JSON.stringify(sort_filter),
          token,
          company_number,
          0,
          this.props.breeds,
          this.props.races,
        )
      : await getStocks(
          JSON.stringify(sort_filter),
          token,
          company_number,
          0,
          this.props.breeds,
          this.props.races,
        );
    const stocks = await extractData(value);
    this.setState({
      stocks: stocks,
      allStocks: stocks,
    });
  }

  searchStocksFilter = async () => {
    const { box_number, checkedItems, checkedItemsBreed } = this.state;

    try {
      let allStocksRace = [];
      let allStocksRace2 = [];
      let temp = {};
      let temp2 = {};
      this.toggleFilterPopup();
      let allStocksState = this.props.allstock.allstock;
      let allStocks = this.props.allstock.allstock;
      if (!this.props.sellOffer) {
        allStocksState = this.props.allstock.allstock;
        allStocks = this.props.allstock.allstock;
      } else {
        allStocksState = this.props.allstock.allstock.filter(
          (e) => e.is_available === 1,
        );
        allStocks = this.props.allstock.allstock.filter(
          (e) => e.is_available === 1,
        );
      }
      let obj1 = JSON.parse(JSON.stringify([...checkedItems]));
      let obj2 = JSON.parse(JSON.stringify([...checkedItemsBreed]));

      if (obj1.length > 0) {
        // eslint-disable-next-line array-callback-return
        obj1.map((e) => {
          temp = allStocksState.filter((w) => w.race.localeCompare(e[0]) === 0);
          if (temp) allStocksRace = [...allStocksRace, ...temp];
        });
      }
      if (obj2.length > 0) {
        // eslint-disable-next-line array-callback-return
        obj2.map((e) => {
          temp2 = allStocksState.filter(
            (w) => w.breed.localeCompare(e[0]) === 0,
          );
          if (temp2) allStocksRace2 = [...allStocksRace2, ...temp2];
        });
      }
      if (obj1.length > 0 && obj2.length > 0)
        allStocks = intersection(allStocksRace, allStocksRace2);
      if (obj1.length > 0 && obj2.length === 0) allStocks = allStocksRace;
      if (obj1.length === 0 && obj2.length > 0) allStocks = allStocksRace2;
      // eslint-disable-next-line no-self-assign
      if (obj1.length === 0 && obj2.length === 0) allStocks = allStocks;

      if (box_number > 0) {
        allStocks = allStocks.filter((w) => w.box === box_number);
      }
      allStocks = this.removeSelectedStock(allStocks);

      if (!this.props.sellOffer) {
        this.setState({
          allStocks: allStocks,
        });
      } else {
        this.setState({
          allStocks: allStocks.filter((e) => e.is_available === 1),
        });

        if (
          typeof this.props.breeds !== 'undefined' &&
          this.props.breeds.length > 0 &&
          typeof this.props.races !== 'undefined' &&
          this.props.races.length > 0
        ) {
          this.setState({
            allStocks: allStocks
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  e.breed === this.props.breeds[0] &&
                  e.race === this.props.races[0],
              ),
          });
        }
      }
      this.setState({
        stocks: allStocks,
      });
    } catch (error) {
      this.props.error(error);
      this.props.clear();
      this.resetFormFilter();
    }
  };

  removeSelectedStock = (stock) => {
    const { listChecked } = this.state;
    let listCheckedIs = map(listChecked, '_id');
    let allStocksIds = map(stock, '_id');
    let commonStock = intersection(allStocksIds, listCheckedIs);
    stock = remove(stock, function (e) {
      return !includes(commonStock, e._id);
    });
    return stock;
  };

  clearFilterBox = async () => {
    try {
      this.setState({ box_search: '' });
      this.getStocks('all');
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  searchStocksByEarTag = async (event) => {
    try {
      let allStocks = this.props.allstock.allstock;
      this.setState({ searchEart: event.target.value.toLowerCase() });
      if (event.target.value.length > 2) {
        let valErtag = event.target.value.toLowerCase();
        allStocks = allStocks.filter((w) =>
          w.ear_tag
            .substr(w.ear_tag.length - 5)
            .replace(/\s/g, '')
            .toLowerCase()
            .includes(valErtag.replace(/\s/g, '')),
        );
      }
      this.setState({
        stocks: allStocks.filter((e) => e.is_available === 1),
      });
      if (
        typeof this.props.breeds !== 'undefined' &&
        this.props.breeds.length > 0 &&
        typeof this.props.races !== 'undefined' &&
        this.props.races.length > 0
      ) {
        this.setState({
          stocks: allStocks
            .filter((e) => e.is_available === 1)
            .filter(
              (e) =>
                e.breed === this.props.breeds[0] &&
                e.race === this.props.races[0],
            ),
        });
      }
      if (event.target.value.length <= 2) {
        this.resetFormFilter();
        this.setState({
          stocks: allStocks.filter((e) => e.is_available === 1),
        });
      }
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  editItem = async (type, i) => {
    try {
      let animal =
        type === 'all'
          ? Object.create(this.state.stocks[i])
          : type === 'selected'
          ? Object.create(this.state.displayChecked[i])
          : Object.create(this.props.toBeLoadedEartags[i]);
      let token = this.props.users.accessToken;
      // eslint-disable-next-line no-unused-vars
      let id = this.props.users.user.session.company_id;
      let passport = await getPassportStock(animal._id, token, animal.passport);
      this.setState({
        ear_tag: animal.ear_tag,
        ear_tag_output_format: animal.ear_tag_output_format,
      });
      if (passport) this.setState({ passport: passport });
      this.setState({ stockSelected: animal });
      this.setState({
        indexSelected: i,
      });
      this.setState({
        showForm: true,
      });
      this.props.handleStock(animal);
    } catch {}
  };

  checkAnimal = async (animal, idx) => {
    const { capacity } = this.props;
    const {
      listChecked,
      displayChecked,
      stocks,
      assignedCheckedNumber,
    } = this.state;

    if (capacity === listChecked.length) {
      await this.props.error(<FormattedMessage {...messages.FullTruck} />);
      await this.props.clear();
      return;
    }

    listChecked && this.setState({ newBlock: true });

    const clone = { ...animal, fromList: 'others' };
    let tab2 = [...displayChecked];
    tab2.push(clone);
    let tab = [...listChecked];
    // let myarr = clone.birth_date.split('.');
    // //get todays date
    // let today = moment();
    // //get birth date
    // let birthday = moment(
    //   '20' + myarr[2] + '/' + myarr[1] + '/' + myarr[0],
    //   'YYYY-MM-DD',
    // );
    // clone.birth_date =
    //   parseInt(today.diff(birthday, 'day') / 7) === 0
    //     ? 1
    //     : parseInt(today.diff(birthday, 'day') / 7);
    tab.push(clone);
    let newStock = [...stocks];
    newStock.splice(idx, 1);
    const assignedNumber = assignedCheckedNumber + 1;
    this.setState({
      displayChecked: tab2,
      stocks: newStock,
      listChecked: tab,
      allStocks: newStock,
      items: newStock,
      hasMoreItem: true,
      assignedCheckedNumber: assignedNumber,
    });
  };

  checkAnimalTobLoaded = async (animal, idx) => {
    const { capacity } = this.props;
    const {
      listChecked,
      displayChecked,
      ListLoaded,
      toBeLoadedCheckedNumber,
    } = this.state;
    if (capacity === listChecked.length) {
      await this.props.error(<FormattedMessage {...messages.FullTruck} />);
      await this.props.clear();
      return;
    }

    const clone = { ...animal, fromList: 'assigned' };
    let tab2 = [...displayChecked];
    tab2.push(clone);
    let tab = [...listChecked];
    // let myarr = clone.birth_date.split('.');
    // //get todays date
    // let today = moment();
    // //get birth date
    // let birthday = moment(
    //   '20' + myarr[2] + '/' + myarr[1] + '/' + myarr[0],
    //   'YYYY-MM-DD',
    // );
    // clone.birth_date =
    //   parseInt(today.diff(birthday, 'day') / 7) === 0
    //     ? 1
    //     : parseInt(today.diff(birthday, 'day') / 7);
    tab.push(clone);
    const loadedStock = [...ListLoaded];
    loadedStock.splice(idx, 1);
    const checkedNumber = toBeLoadedCheckedNumber + 1;
    this.setState({
      displayChecked: tab2,
      ListLoaded: loadedStock,
      listChecked: tab,
      hasMoreItem: true,
      toBeLoadedCheckedNumber: checkedNumber,
    });
  };

  animalExistInList = (list, removedAnimal) => {
    return list.some((animal) => animal._id === removedAnimal._id);
  };
  removeAnimalFromListChecked = async (animal, idx) => {
    const {
      stocks,
      ListLoaded,
      toBeLoadedCheckedNumber,
      assignedCheckedNumber,
      listChecked,
    } = this.state;
    let newListChecked = [...listChecked];
    newListChecked.splice(idx, 1);
    if (animal.fromList === 'assigned') {
      const newLoadedList = [...ListLoaded];
      newLoadedList.push(animal);
      const newTobeLoadedNbr = toBeLoadedCheckedNumber - 1;
      this.setState({
        ListLoaded: newLoadedList,
        toBeLoadedCheckedNumber: newTobeLoadedNbr,
        listChecked: newListChecked,
        displayChecked: newListChecked,
      });
    } else if (animal.fromList === 'others') {
      const newStock = [...stocks];
      newStock.push(animal);
      const newAssignedNbr = assignedCheckedNumber - 1;
      this.setState({
        stocks: newStock,
        assignedCheckedNumber: newAssignedNbr,
        listChecked: newListChecked,
        displayChecked: newListChecked,
      });
    }
  };

  renderListChecked = () => {
    const type = 'selected';
    return this.state.displayChecked.map(
      (animal, i) =>
        !isNaN(animal.box) && (
          <StockItem
            key={i}
            editItem={() => this.editItem(type, i)}
            checkAnimal={() => this.checkAnimal(animal, i)}
            removeAnimalFromListChecked={() =>
              this.removeAnimalFromListChecked(animal, i)
            }
            checked={this.state.listChecked.findIndex(
              (val) => animal.ear_tag === val.ear_tag,
            )}
            data={animal}
            sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
          />
        ),
    );
  };

  renderListStocks = () => {
    const type = 'all';
    if (this.state.stocks.length <= 100) {
      return this.state.stocks.map(
        (animal, i) =>
          !isNaN(animal.box) && (
            <StockItem
              key={i}
              editItem={() => this.editItem(type, i)}
              checkAnimal={() => this.checkAnimal(animal, i)}
              checked={this.state.listChecked.findIndex(
                (val) => animal.ear_tag === val.ear_tag,
              )}
              data={animal}
              sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
              capacity={
                typeof this.props.capacity !== 'undefined'
                  ? !(this.props.capacity > this.state.listChecked.length)
                  : false
              }
            />
          ),
      );
    } else {
      return this.state.items.map(
        (animal, i) =>
          !isNaN(animal.box) && (
            <StockItem
              editItem={() => this.editItem(type, i)}
              checkAnimal={() => this.checkAnimal(animal, i)}
              key={i}
              checked={this.state.listChecked.findIndex(
                (val) => animal.ear_tag === val.ear_tag,
              )}
              data={animal}
              sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
              capacity={
                typeof this.props.capacity !== 'undefined'
                  ? !(this.props.capacity > this.state.listChecked.length)
                  : false
              }
            />
          ),
      );
    }
  };

  renderListStocksLoaded = () => {
    const type = 'all';
    if (typeof this.props.toBeLoadedEartags !== 'undefined')
      return this.state.ListLoaded.map(
        (animal, i) =>
          !isNaN(animal.box) && (
            <StockItem
              editItem={() => this.editItem(type, i)}
              checkAnimal={() => this.checkAnimalTobLoaded(animal, i)}
              key={i}
              checked={this.state.listChecked.findIndex(
                (val) => animal.ear_tag === val.ear_tag,
              )}
              data={animal}
              sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
              capacity={
                typeof this.props.capacity !== 'undefined'
                  ? !(this.props.capacity > this.state.listChecked.length)
                  : false
              }
            />
          ),
      );
  };

  toggleFilterPopup = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  toggleEartagDetails = () => {
    this.setState({ showEartagDetails: !this.state.showEartagDetails });
  };

  render() {
    const { sellOffer, eartagSelect, toBeLoadedEartags } = this.props;
    const { stocks, listChecked, showForm, box_number } = this.state;
    const raceType = uniqBy(stocks, 'race');
    const BreedType = uniqBy(stocks, 'breed');
    return (
      <div style={{ height: '100%' }}>
        <div className="view view-stock-loaded">
          <Row className="search-content">
            <Col xs={8} md={8}>
              <FormGroup>
                <InputGroup>
                  <InputGroup.Prepend>
                    <MdSearch />
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    minLength={0}
                    maxLength={5}
                    name="search"
                    onChange={this.searchStocksByEarTag}
                    className="search-txt"
                    placeholder={
                      this.props.language.locale === 'DE'
                        ? 'Ohrmarke suchen'
                        : 'Search Eartag'
                    }
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={4} md={4}>
              <div
                onClick={() => {
                  this.resetFormFilter();
                  this.toggleFilterPopup();
                }}
                className="filter"
              >
                <FormattedMessage {...messages.AddFilter} />
              </div>
            </Col>
          </Row>
        </div>
        <Card.Body className="assigned-lp-panel-body">
          <Row className="panel-body-row">
            <Col className="panel-body-col" xs={12} md={12}>
              <ButtonGroup value={this.state.type} className="order-btn-groupe">
                <div className="button-groupe-wrapper">
                  <Button
                    className={this.state.type === 'active' ? 'active' : ''}
                    value={'active'}
                    onClick={() => this.handleChangeVal('active')}
                  >
                    <span className="assigned">
                      <FormattedMessage {...messages.Assigned} />
                      <br />
                      {` (${this.state.toBeLoadedCheckedNumber}/${toBeLoadedEartags.length})`}
                    </span>
                  </Button>
                  <Button
                    className={this.state.type === 'completed' ? 'active' : ''}
                    value={'completed'}
                    onClick={() => this.handleChangeVal('completed')}
                  >
                    <span className="assigned">
                      <FormattedMessage {...messages.OtherAvailable} />
                      <br />
                      {` (${this.state.assignedCheckedNumber}/${
                        this.props.allstock.allstock.filter(
                          (e) => e.is_available === 1,
                        ).length
                      })`}
                    </span>
                  </Button>
                </div>
              </ButtonGroup>
              <Row className="view-stock assigned-lp-stock">
                <Modal
                  className="search-popup stockM"
                  show={this.state.showFilter}
                  onHide={this.toggleFilterPopup}
                  style={{ opacity: 1 }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title />
                  </Modal.Header>
                  <Modal.Body className="text-center">
                    <div className="form-Flex">
                      <div className="Filter-Search-Results">
                        <FormattedMessage {...messages.FilterSearchResults} />
                      </div>
                      <div className="Clear-All" onClick={this.resetFormFilter}>
                        <FormattedMessage {...messages.ClearAll} />
                      </div>
                    </div>

                    <FormGroup>
                      <div className="sliderArea">
                        <Range
                          marks={{
                            0: `0`,
                            1000: `1000`,
                          }}
                          min={0}
                          max={1000}
                          defaultValue={[0, 1000]}
                          tipFormatter={(value) => `${value}`}
                          tipProps={{
                            placement: 'top',
                            visible: true,
                          }}
                        />
                      </div>

                      <div className="form-Flext">
                        <div className="form-FlexTaille">
                          <div className="Race-Class">
                            <FormattedMessage {...messages.NewRace} />
                          </div>
                          <div className="checkboxFlex-Block">
                            <div className="block-flex-race">
                              {raceType.map((item, key) => (
                                <div
                                  key={key}
                                  className="bloc-ch-div main-check"
                                >
                                  <input
                                    className="bloc-ch "
                                    type="checkbox"
                                    name={item.race}
                                    checked={this.state.checkedItems.get(
                                      item.name,
                                    )}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  <label
                                    className="bloc-chLabel Angle"
                                    key={item.key}
                                  >
                                    {item.race}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="form-FlexTaille new-margin">
                          <div className="Race-Class">
                            <FormattedMessage {...messages.NewBreed} />
                          </div>
                          <div className="checkboxFlex-Block">
                            <div className="block-flex-race">
                              {BreedType.map((item, key) => (
                                <div
                                  key={key}
                                  className="bloc-ch-div main-check"
                                >
                                  <input
                                    className="bloc-ch"
                                    type="checkbox"
                                    name={item.breed}
                                    checked={this.state.checkedItemsBreed.get(
                                      item.name,
                                    )}
                                    onChange={this.handleCheckboxChangeBreed}
                                  />
                                  <label
                                    className="bloc-chLabel Angle"
                                    key={item.key}
                                  >
                                    {item.breed}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-Flexone">
                        <div style={{ width: '40%' }} className="Race-Class">
                          <FormattedMessage {...messages.BoxNumber} />
                        </div>
                        <div className="numbe">
                          <input
                            className="form-control-box"
                            pattern="[0-9]{0,5}"
                            value={box_number}
                            name="box_number"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="btn-save">
                      <Button
                        className="btn-filter"
                        onClick={this.searchStocksFilter}
                      >
                        <FormattedMessage {...messages.Search} />
                      </Button>
                    </div>
                  </Modal.Footer>
                </Modal>

                {
                  <Col
                    md={!sellOffer && 6}
                    sm={!sellOffer && 12}
                    className={clsx(
                      sellOffer ? 'stockSell' : 'viewStock',
                      'assigned-stock-container',
                    )}
                  >
                    <div className="assigned-top-part">
                      {eartagSelect && (
                        <div
                          className="navigate-before-stock"
                          onClick={this.props.showSellForm}
                        >
                          <div md={3}>
                            <MdNavigateBefore className="before" />
                          </div>
                        </div>
                      )}

                      <Row className="well-content">
                        <Col xs={2} md={2}>
                          {this.state.box_search && (
                            <Jumbotron>
                              Box {this.state.box_search}
                              <MdClose onClick={this.clearFilterBox} />
                            </Jumbotron>
                          )}
                        </Col>
                      </Row>
                      <ListGroupItem className="title-list-content">
                        <div className="view-Stock-line-pri ">
                          <div xs={1} md={1} className=" view-Stock-line-b1 ">
                            <div className="main-checkAll"></div>
                          </div>
                          <div
                            xs={3}
                            onClick={(e) => {
                              this.getStocks('ear_tag');
                              this.setState({
                                eartagSorted: !this.state.eartagSorted,
                                ageSorted: false,
                                raceSorted: false,
                                breedSorted: false,
                                boxSorted: false,
                                arrowA: true,
                              });
                            }}
                            md={3}
                            className={
                              this.state.eartagSorted
                                ? 'view-Stock-line-b2  title content-center borderBo'
                                : 'view-Stock-line-b2  title content-center '
                            }
                          >
                            <FormattedMessage {...messages.EarTag} />
                            <span>
                              {this.state.eartagSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </div>

                          <div
                            xs={2}
                            md={2}
                            onClick={(e) => {
                              this.getStocks('birth_date');
                              this.setState({
                                eartagSorted: false,
                                ageSorted: !this.state.ageSorted,
                                raceSorted: false,
                                breedSorted: false,
                                boxSorted: false,
                              });
                            }}
                            className="view-Stock-line-b1  title content-center "
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            className={
                              this.state.ageSorted
                                ? 'view-Stock-line-b3  title content-center borderBo'
                                : 'view-Stock-line-b3  title content-center '
                            }
                          >
                            <FormattedMessage {...messages.Age} />
                            <span>
                              {this.state.ageSorted && (
                                <img
                                  className="imageSorttwo"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                          <div
                            xs={2}
                            md={2}
                            onClick={(e) => {
                              this.getStocks('race');
                              this.setState({
                                eartagSorted: false,
                                ageSorted: false,
                                raceSorted: !this.state.raceSorted,
                                breedSorted: false,
                                boxSorted: false,
                              });
                            }}
                            className={
                              this.state.raceSorted
                                ? 'view-Stock-line-b4  title content-center borderBo'
                                : 'view-Stock-line-b4  title content-center '
                            }
                          >
                            <FormattedMessage {...messages.NewRace} />
                            <span>
                              {this.state.raceSorted && (
                                <img
                                  className="imageSorttwo"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                          <div
                            xs={2}
                            md={2}
                            onClick={(e) => {
                              this.getStocks('breed');
                              this.setState({
                                eartagSorted: false,
                                ageSorted: false,
                                raceSorted: false,
                                breedSorted: !this.state.breedSorted,
                                boxSorted: false,
                              });
                            }}
                            className={
                              this.state.breedSorted
                                ? 'view-Stock-line-b5  title content-center borderBo'
                                : 'view-Stock-line-b5  title content-center '
                            }
                          >
                            <FormattedMessage {...messages.NewBreed} />
                            <span>
                              {this.state.breedSorted && (
                                <img
                                  className="imageSort"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                          <div
                            xs={1}
                            md={1}
                            onClick={(e) => {
                              this.getStocks('box');
                              this.setState({
                                eartagSorted: false,
                                ageSorted: false,
                                raceSorted: false,
                                breedSorted: false,
                                boxSorted: !this.state.boxSorted,
                              });
                            }}
                            className={
                              this.state.boxSorted
                                ? 'view-Stock-line-b6  title content-center borderBo'
                                : 'view-Stock-line-b6  title content-center '
                            }
                          >
                            <FormattedMessage {...messages.Box} />
                            <span>
                              {this.state.boxSorted && (
                                <img
                                  className="imageSorttwo"
                                  src={sordIcon}
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </ListGroupItem>
                      {this.state.type === 'completed' && (
                        <ListGroup
                          id="contentStock"
                          className={
                            this.state.newBlock ? 'list-groupp' : 'list-groupp'
                          }
                        >
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={debounce(this.fetchMoreData, 500)}
                            hasMore={this.state.hasMoreItem}
                            loader={
                              this.state.hasMoreItem && (
                                <div key={0} className="loader-stocks">
                                  <div className="loader-st" key={0}></div>
                                </div>
                              )
                            }
                            useWindow={false}
                            getScrollParent={() => {
                              document.getElementById('contentStock');
                            }}
                          >
                            <ListGroup>{this.renderListStocks()}</ListGroup>
                          </InfiniteScroll>
                        </ListGroup>
                      )}
                      {this.state.type === 'active' && (
                        <ListGroup
                          id="contentStock"
                          className={
                            this.state.newBlock ? 'list-groupp' : 'list-groupp'
                          }
                        >
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={debounce(this.fetchMoreData, 500)}
                            hasMore={this.state.hasMoreItem}
                            loader={
                              this.state.hasMoreItem && (
                                <div key={0} className="loader-stocks">
                                  <div className="loader-st" key={0}></div>
                                </div>
                              )
                            }
                            useWindow={false}
                            getScrollParent={() => {
                              document.getElementById('contentStock');
                            }}
                          >
                            <ListGroup>
                              {this.renderListStocksLoaded()}
                            </ListGroup>
                          </InfiniteScroll>
                        </ListGroup>
                      )}
                      {listChecked.length > 0 && (
                        <div className="selected-stock-item">
                          <div className="selected-stock-item-b1">
                            <hr className="style-border" />

                            <div className="selected-flex">
                              <div className="Eartag-selected ">
                                ({listChecked.length})
                                <FormattedMessage
                                  {...messages.SelectedAnimals}
                                />
                              </div>
                              <div
                                className="Eartag-selected "
                                onClick={() => {
                                  this.resetFormFilter();
                                  this.setState({
                                    listChecked: [],
                                    displayChecked: [],
                                    newBlock: false,
                                    assignedCheckedNumber: 0,
                                    toBeLoadedCheckedNumber: 0,
                                  });
                                }}
                              >
                                <FormattedMessage {...messages.DeselectAll} />
                              </div>
                            </div>
                          </div>
                          <div className="selected-stock-item-v view-stock-loaded-checked">
                            {this.renderListChecked()}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="assigned-bottom-part">
                      {eartagSelect && (
                        <NormalButton
                          disabled={listChecked.length > 0}
                          onClick={() =>
                            this.props.showSellForm(listChecked, showForm)
                          }
                        >
                          <FormattedMessage {...messages.ConfirmButton} />
                        </NormalButton>
                      )}
                      {!eartagSelect && (
                        <NormalButton
                          disabled={sellOffer && listChecked.length === 0}
                          active={sellOffer && listChecked.length > 0}
                          onClick={() =>
                            this.props.showSellForm(listChecked, showForm)
                          }
                        >
                          <FormattedMessage {...messages.ConfirmButton} />
                        </NormalButton>
                      )}
                    </div>
                  </Col>
                }
              </Row>
              {/* )} */}
            </Col>
          </Row>
        </Card.Body>
      </div>
    );
  }
}

ViewStockLoaded.propTypes = {
  users: PropTypes.object,
  renderListStocks: PropTypes.func,
  sendToHit: PropTypes.func,
  choseType: PropTypes.func,
  factoryEarTagInverse: PropTypes.func,
  editItem: PropTypes.func,
  addToListToHit: PropTypes.func,
  getStockFromHit: PropTypes.func,
  handleChangeEarTag: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    setStocks: (page, stocksPagination, block_api, stocks) =>
      dispatch(
        stocksActions.setStocks(page, stocksPagination, block_api, stocks),
      ),
    resetStock: () => dispatch(stocksActions.resetStock()),
  };
}
const mapStateToProps = (state) => {
  const { users, allstock, language } = state.toJS();
  return { users, allstock, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewStockLoaded);
