import { buyingsStockConstants, alertConstants } from '../constants';
import { getBuyingsStocks } from '../services/buyingsStock.service';
import extractData from '../services/data.processing';

export const buyingsStockActions = {
  getBuyingsStock,
  getBuyingsDetails,
  clearBuyingsDetails,
};

function getBuyingsStock(accessToken, programQualities, startDate, endDate) {
  return async (dispatch) => {
    try {
      const data = await getBuyingsStocks(
        accessToken,
        programQualities,
        startDate,
        endDate,
      );
      const buyingsStock = await extractData(data);
      await dispatch({
        type: buyingsStockConstants.GET_BUYINGS,
        buyingsStock,
      });
    } catch (error) {
      dispatch({ type: alertConstants.ERROR, error });
    }
  };
}

function getBuyingsDetails(
  accessToken,
  startDate,
  detailsDate,
  programQualities,
) {
  return async (dispatch) => {
    try {
      const data = await getBuyingsStocks(
        accessToken,
        programQualities,
        startDate,
      );
      const buyingsDetails = await extractData(data);
      await dispatch({
        type: buyingsStockConstants.GET_BUYINGS_DETAILS,
        data: { buyingsDetails, date: detailsDate },
      });
    } catch (error) {
      dispatch({ type: alertConstants.ERROR, error });
    }
  };
}

function clearBuyingsDetails() {
  return (dispatch) => {
    dispatch({
      type: buyingsStockConstants.CLEAR_BUYINGS_DETAILS,
    });
  };
}
