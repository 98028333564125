import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import extractData from '../../../../services/data.processing';
import { getCompany } from '../../../../services/company.service';
import { FormattedMessage } from 'react-intl';
import { editUseOrderReconfirmation } from '../../../../actions/company.actions';
import messages from '../../../../components/Admin/messages';
import '../setting.css';
class FormDisableReconfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useOrderReconfirmation: true,
      showPin: false,
      showPassword: false,
      current_Pin: '',
      new_Pin: '',
      current_password: '',
      new_password: '',
      confirm_password: '',
      passworderror: '',
    };
  }

  handleCheckbox = (checked) => {
    this.setState({
      useOrderReconfirmation: checked,
    });
    this.editOrderReconfirmationStatus(checked);
  };

  componentDidMount() {
    this.getCompany();
  }

  getCompany = async () => {
    let token = this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const company = await extractData(value);
      if (typeof company !== 'undefined') {
        this.setState({
          useOrderReconfirmation: company.use_order_reconfirmation,
        });
      }
    }
  };

  editOrderReconfirmationStatus = (checked) => {
    const reconfirmationValue = checked ? 1 : 0;
    this.props.editUseOrderReconfirmation(reconfirmationValue);
  };

  render() {
    return (
      <Form className="order-reconfirmation-container">
        <div className="new-cards-owner ">
          <div className="new-cards-owner-inner-switch">
            <div md={4} className="new-cards-owner-inner-b1-switch new-margin">
              <FormattedMessage
                {...messages.DisableReconfirmationDescription}
              />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2-switch"
            >
              <Switch
                onColor="#7bd812"
                onChange={this.handleCheckbox}
                checked={!!this.state.useOrderReconfirmation}
                className="react-switch"
                id="normal-switch"
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    editUseOrderReconfirmation: (useOrderReconfirmation) =>
      dispatch(editUseOrderReconfirmation(useOrderReconfirmation)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormDisableReconfirmation);
