import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../../../../../actions';
import { OrderService } from '../../../../../services/order.service';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import messages from '../../../messages';
class DocumentationListe extends Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: false, pdfId: '' };
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  getEncodedPdf = async (fileId) => {
    let img = await OrderService.getPDFLoad(
      this.props.users.accessToken,
      fileId,
    );
    this.setState({ pdfId: 'data:application/pdf;base64,' + img });
  };
  renderDocument = (documentType) => {
    if (documentType === 'DELIVERY_NOTE') {
      return <FormattedMessage {...messages.DeliveryNote} />;
    } else if (documentType === 'STANDARD_DECLARATION') {
      return <FormattedMessage {...messages.StandardDeclaration} />;
    } else {
      return <FormattedMessage {...messages.BioNote} />;
    }
  };
  render() {
    const { docs } = this.props;
    return (
      <div className="list-eartag-block">
        <Modal
          show={this.state.showPopup === true}
          onHide={this.handleClose}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdf ">
            <embed
              width="100%"
              height="90%"
              frameBorder="0"
              src={this.state.pdfId}
              type="application/pdf"
            />
          </Modal.Body>
        </Modal>
        <ul>
          <li>
            {docs.map((doc, index) => (
              <div className="truck-group-container" key={index}>
                <div className="truck-group-docs to-be-loaded-docs">
                  <div className="eartag">{this.renderDocument(doc.type)}</div>
                  <div
                    className="rectangle icon-eartag show-eartag"
                    onClick={() => {
                      this.openPopUp();
                      this.getEncodedPdf(doc.file_id);
                    }}
                  />
                </div>
              </div>
            ))}
          </li>
        </ul>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentationListe);
