import React from 'react';
import { connect } from 'react-redux';
import { alertActions, OrderActions } from '../../../../../actions';
import { OrderService } from '../../../../../services/order.service';
import extractData from '../../../../../services/data.processing';
import messages from '../../../messages';
import { FormattedMessage } from 'react-intl';

import './contact.css';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: [],
    };
  }
  UNSAFE_componentWillMount() {
    const { order_id, receiver_id } = this.props.orderDetail;
    this.getOrderDetails(order_id, receiver_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.orderDetail !== this.props.orderDetail) {
      this.getOrderDetails(
        nextProps.orderDetail.order_id,
        nextProps.orderDetail.receiver_id,
      );
    }
  }

  getOrderDetails = async (order_id, receiver_id) => {
    let token = this.props.users.accessToken;
    let value = await OrderService.getOrderDetails(
      token,
      order_id,
      receiver_id,
    );
    const data = await extractData(value);
    this.setState({
      contact: data.contact,
    });
  };

  renderCoordinate = (name, info) => (
    <div>
      <p className="contact-info">{name}</p>
      <p className="contact-value">{info}</p>
    </div>
  );

  render() {
    const {
      name,
      address,
      phone_number,
      email,
      mobile_number,
      fax,
    } = this.state.contact;
    return (
      <div id="contact">
        <div className="contact-details">
          {this.renderCoordinate(
            <FormattedMessage {...messages.company} />,
            name,
          )}
          {this.renderCoordinate(
            <FormattedMessage {...messages.address} />,
            address,
          )}
          {this.renderCoordinate(
            <FormattedMessage {...messages.phoneNumber} />,
            phone_number,
          )}
          {this.renderCoordinate(
            <FormattedMessage {...messages.mobileNumber} />,
            mobile_number,
          )}
          {this.renderCoordinate(
            <FormattedMessage {...messages.email} />,
            email,
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getOrders: (orderType, type, page, date) =>
      dispatch(OrderActions.getOrders(orderType, type, page, date)),
  };
}

const mapStateToProps = (state) => {
  const { users, orders } = state.toJS();
  return {
    users,
    orders,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
