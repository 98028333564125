import { userConstants } from '../constants';

const initialState = {
  plan: false,
  registering: false,
};
export function registration(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { ...state, registering: true };
    case userConstants.MEMBER_SHIP:
      return { ...state };
    default:
      return state;
  }
}
