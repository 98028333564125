import { userConstants } from '../constants';

const initialState = {
  user: {},
  client: {},
  token: {},
  device: {},
  isConnected: false,
  users: [],
  accessToken: '',
};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.user,
        isConnected: true,
        accessToken: action.user.token.access_token,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        isConnected: false,
        accessToken: '',
        client: {},
        device: {},
        token: {},
        user: {},
        buyings: {},
        sellings: {},
        allstock: [],
        users: [],
      };
    case userConstants.USER_GET_ALL:
      return {
        ...state,
        users: action.users,
      };
    case userConstants.USER_ADD:
      const user = {
        ...action.user,
        companies: state.user.companies,
      };
      return {
        ...state,
        users: [...state.users, user],
      };
    case userConstants.USER_EDIT:
      const index = state.users.findIndex(
        (user) => user._id === action.user._id,
      );
      let oldUser = state.users[index];
      let newUser = {};
      if (index !== -1) {
        newUser = {
          ...oldUser,
          ...action.user,
        };
      } else {
        newUser = oldUser;
      }

      return {
        ...state,
        users: [
          ...state.users.slice(0, index),
          newUser,
          ...state.users.slice(index + 1),
        ],
      };
    case userConstants.USER_DELETE:
      const i = state.users.findIndex((user) => user._id === action.userId);
      if (i !== -1) {
        return {
          ...state,
          users: [...state.users.slice(0, i), ...state.users.slice(i + 1)],
        };
      }
    // eslint-disable-next-line no-fallthrough
    case userConstants.USER_CHANGE_SESSION:
      return {
        ...state,
        user: {
          ...state.user,
          companies: action.companies,
          session: action.session,
        },
      };
    case userConstants.USER_CHANGE_SESSION_LAST_COMPANY:
      return {
        ...state,
        user: {
          ...state.user,
          companies: action.companies,
          session: action.session,
        },
      };
    case userConstants.USER_CHANGE_SESSION_SUB:
      return {
        ...state,
        user: {
          ...state.user,
          subscription_type: action.subscription,
        },
      };
    case userConstants.RESET_BUY:
      return {
        ...state,
        allstock: action.resetBuyings,
      };
    case userConstants.RESET_SELL:
      return {
        ...state,
        allstock: action.resetSellings,
      };
    case userConstants.REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action.NewtToken.access_token,
        token: action.NewtToken,
      };
    default:
      return state;
  }
}
