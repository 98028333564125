const handleConfirm = status => {
  let confirm;
  // eslint-disable-next-line default-case
  switch (true) {
    case status === 'REQUESTED':
      confirm = false;
      break;
    case status === 'REQUEST':
      confirm = true;
      break;
    case status === 'REJECTED':
      confirm = false;
      break;
    case status === 'OFFER':
      confirm = false;
      break;
    case status === 'OFFERED':
      confirm = false;
      break;
    case status === 'TO_BE_ACCEPTED':
      confirm = false;
      break;
    case status === 'TO_BE_RECONFIRMED':
      confirm = true;
      break;
    case status === 'TO_BE_LOADED':
      confirm = false;
      break;
  }
  return confirm;
};

export default handleConfirm;
