import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderLinks from './HeaderLinks';
import Img from '../Img';
import Logo from '../../static/images/logo.png';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { margin, isCookieAccepted, isFromCookie } = this.props;
    const marginFromHeader = isCookieAccepted ? 20 : margin;
    return (
      <Navbar style={{ marginTop: marginFromHeader }}>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="#home">
              <Img className="logo logo-light" src={Logo} alt="logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className="font-secondary" id="site-collapse-nav">
          {!isFromCookie && <HeaderLinks />}
          {isFromCookie && (
            <Nav
              className="nav-list navbar-right"
              style={{ paddingRight: 0, color: 'red' }}
            >
              <Link to="/" style={{ color: '#767676' }}>
                Home
              </Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

Menu.propTypes = {
  margin: PropTypes.number,
  isFromCookie: PropTypes.bool,
};
export default Menu;
