import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyBNyldjEt3mZOaGRZMYJz3AG-vbxpTkZfw',
  authDomain: 'agrabiz-8c47a.firebaseapp.com',
  databaseURL: 'https://agrabiz-8c47a.firebaseio.com',
  projectId: 'agrabiz-8c47a',
  storageBucket: 'agrabiz-8c47a.appspot.com',
  messagingSenderId: '117536991265',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
const db = firebase.database();

export { db };
