import { useEffect } from 'react';

const useOutsideClick = (ref, callbackInside, callbackOutside) => {
  const handleClick = (e) => {
    if (ref.current && ref.current.contains(e.target)) {
      // inside
      return callbackInside();
    }
    // outside
    return callbackOutside();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
