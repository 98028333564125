import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormControl } from 'react-bootstrap';

import { TextInput } from 'react-bootstrap4-form-validation';
import MultiSelect from '../../../Shared/components/MultiSelect';
import { countries, states } from '../../../../constants';
import messages from '../../../../components/Admin/messages';
import CustomSelect from '../../../../components/CustomSelect';

import '../setting.css';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: '#7bd812',
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: '',
    width: '100%',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    borderColor: '#c6c6c6',
    '&:hover': { borderColor: '#7bd812' },
  }),
  singleValue: (provided, state) => {
    return { ...provided, color: '#646464' };
  },
};

const ProfileDetails = (props) => {
  const {
    company,
    businessType,
    companyAdress,
    selectedOptionTypeUser,
    optionsTypesUse,
    onChange,
  } = props;

  return (
    <div>
      <div className="new-cards-owner">
        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.companyNumber} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <FormControl
              type="text"
              disabled={true}
              defaultValue={company.number}
              placeholder="Company Number"
            />
          </div>
        </div>

        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.company} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <TextInput
              type="text"
              name="name"
              id="name"
              defaultValue={company.name}
              placeholder="Company"
            />
          </div>
        </div>

        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.city} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <TextInput
              name="city"
              id="city"
              required
              successMessage=""
              defaultValue={companyAdress.city}
              errorMessage="You don't have a City?"
            />
          </div>
        </div>

        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.streetNumber} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <TextInput
              name="street"
              id="streetNumber"
              defaultValue={companyAdress.street}
              required
              successMessage=""
              errorMessage="You don't have a Street Number?"
            />
          </div>
        </div>

        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.zipCode} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <TextInput
              name="zip_code"
              id="zipCode"
              required
              successMessage=""
              errorMessage="You don't have a Zip Code?"
              defaultValue={companyAdress.zip_code}
            />
          </div>
        </div>

        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.country} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <CustomSelect
              isDisabled={true}
              defaultValue={countries.find(
                (country) => country.value === 'Deutschland',
              )}
              options={countries}
              styles={customStyles}
              required
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
          </div>
        </div>

        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.federalState} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <CustomSelect
              defaultValue={states.find(
                (address) =>
                  address.value === companyAdress.federal_state && address,
              )}
              options={states}
              styles={customStyles}
              required
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
          </div>
        </div>

        {[1].indexOf(businessType) !== -1 && (
          <div className="new-cards-owner-inner type-of-use">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.typeofuse} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <MultiSelect
                value={selectedOptionTypeUser}
                isMulti={true}
                name="typeofuse"
                options={optionsTypesUse}
                onChange={onChange}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={false}
                errorMessage={{ validator: 'choose a type of use' }}
                styles={customStyles}
              />
            </div>
          </div>
        )}

        {[2, 3, 4].indexOf(businessType) !== -1 && (
          <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.approvalNumber} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <TextInput
                name="approval_number"
                type="text"
                defaultValue={company.approval_number}
                errorMessage={{ validator: 'Approval is not valid' }}
              />
            </div>
          </div>
        )}
        <div className="new-cards-owner-inner-b2-btn">
          <button
            type="submit"
            className="form-btn-valide add-s-bottom-part-cols"
          >
            <FormattedMessage {...messages.EditMessage} />
          </button>
        </div>
      </div>
    </div>
  );
};

ProfileDetails.propTypes = {
  company: PropTypes.object,
  companyOwner: PropTypes.object,
  companyAdress: PropTypes.object,
  renderOptions: PropTypes.func,
};

export default ProfileDetails;
