import { stockConstants } from '../constants';
import { unionBy, uniqBy } from 'lodash';
import extractData from '../services/data.processing';
import { getStocks } from '../services/stock.service';
export const stocksActions = {
  setStocks,
  resetStock,
  setStocksbyNumber,
};

function setStocks(page, stocksPagination, block_api, stocks) {
  return async (dispatch, getState) => {
    try {
      const token = getState().toJS().users.accessToken;
      const company_number = getState().toJS().users.user.session
        .company_number;
      if (!block_api) {
        var sort_filter;
        sort_filter = {
          ear_tag: '1',
          race: '1',
          breed: '1',
          box: '1',
          birth_date: '1',
        };
        const value = await getStocks(
          JSON.stringify(sort_filter),
          token,
          company_number,
          page,
          [],
          [],
        );

        if (value.status !== 401) {
          let stockss = await extractData(value);
          stocks = unionBy(stockss, stocksPagination, '_id');
          stocks = uniqBy(stocks, 'ear_tag');
          stocksPagination = stocks;
          if (stockss.length >= 0 && stockss.length <= 99) {
            block_api = true;
            const allStocks = stocksPagination;
            await dispatch({ type: stockConstants.ALL_STOCK, allStocks });
          }

          page = page + 1;
          return dispatch(
            this.setStocks(page, stocksPagination, block_api, stocks),
          );
        }
      }
      block_api = false;
      page = 0;
      stocksPagination = [];
      stocks = [];
    } catch (error) {}
  };
}

function setStocksbyNumber(
  companyNumber,
  page,
  stocksPagination,
  block_api,
  stocks,
) {
  return async (dispatch, getState) => {
    try {
      const token = getState().toJS().users.accessToken;
      const company_number = companyNumber;
      if (!block_api) {
        var sort_filter;
        sort_filter = {
          ear_tag: '1',
          race: '1',
          breed: '1',
          box: '1',
          birth_date: '1',
        };
        const value = await getStocks(
          JSON.stringify(sort_filter),
          token,
          company_number,
          page,
          [],
          [],
        );
        if (value.status !== 401) {
          let stockss = await extractData(value);
          stocks = unionBy(stockss, stocksPagination, '_id');
          stocks = uniqBy(stocks, 'ear_tag');
          stocksPagination = stocks;
          if (stockss.length >= 0 && stockss.length <= 99) {
            block_api = true;
            const allStocks = stocksPagination;
            await dispatch({ type: stockConstants.ALL_STOCK, allStocks });
          }

          page = page + 1;
          return dispatch(
            this.setStocksbyNumber(
              companyNumber,
              page,
              stocksPagination,
              block_api,
              stocks,
            ),
          );
        }
      }
      block_api = false;
      page = 0;
    } catch (error) {}
  };
}

function resetStock() {
  return async dispatch => {
    const resetStocks = [];
    await dispatch({ type: stockConstants.RESET, resetStocks });
  };
}
