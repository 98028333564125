/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  FormGroup,
  InputGroup,
  ButtonGroup,
} from 'react-bootstrap';
import { MdStarBorder, MdStar } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import { filter } from 'lodash';
import messages from '../../messages';
import { getContacts } from '../../../../services/company.service';
// import { OrderService } from '../../../../services/order.service';
import { getCompany } from '../../../../services/company.service';
import extractData from '../../../../services/data.processing';
import { addLeadingZero } from '../../../../helpers/order';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import ViewStock from '../../ViewStock';
import { isEqual, uniqBy } from 'lodash';
import clsx from 'clsx';
import { svgPaths } from '../../../../constants';
import { alertActions, adminActions } from '../../../../actions';
import { withRouter } from 'react-router-dom';
import '../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import locale from '../../../../utils/DataPickerLang';
import Switch from 'react-switch';

import './resell.css';
class ReSellRequestAllContact extends Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.calendarRefUntil = React.createRef();
    this.state = {
      deliveryUntil: '',
      deliveryDate: this.renderDate( this.props.date, 'toBeStored') ,
      piece: 0,
      amount: this.props.orderDetail.amount,
      race: [],
      programQuality: [],
      orderDetail: this.props.orderDetail,
      companyId: this.props.companyId,
      contacts: [],
      agriculturists: [],
      traders: [],
      abattoirs: [],
      showTrd: false,
      showAgr: false,
      showAbt: false,
      isBuyer: this.props.orderDetail.is_buyer,
      receivers: [],
      receivedOrder: this.props.orders,
      getEmpty: this.props.getEmpty,
      showStock: true,
      earTags: [],
      src: {},
      startDate: this.props.date,
      endDate: new Date(),
      freight_included: 1,
      businessType: null,
      onDatePickerFocus: false,
      onDatePickerBlur: true,
      search_favorit: false,
      role_filter: [],
      search_alphabet: null,
      inputValue: '',
      isExternalAbattoir: false,
      isExternalAbtSelect: false,
      checkItem: {
        checkId: 0,
        checkType: '',
      },
    };
  }

  handleExternalAbtCheckbox = () => {
    this.setState((prevState) => ({
      isExternalAbattoir: !prevState.isExternalAbattoir,
    }));
  };

  UNSAFE_componentWillMount() {
    this.getFavouriteContacts();
  }

  checkDatePickerState = () => {
    this.calendarRef.current.setOpen(true);
  };

  filterByFavorit = async () => {
    const { programQuality } = this.state;
    this.setState((prevState) => ({
      search_favorit: !prevState.search_favorit,
    }));
    let updatedList = this.state.contacts;
    if (!this.state.search_favorit) {
      if (this.state.search_alphabet) {
        updatedList = updatedList.filter((item) => {
          return (
            item.is_favourite === 1 &&
            item.name.charAt(0) === this.state.search_alphabet
          );
        });
        if (
          this.props.orderType === 'buyings' &&
          this.state.role_filter.length > 0
        ) {
          updatedList = updatedList.filter((item) => {
            return item;
          });
        }
      } else {
        updatedList = updatedList.filter((item) => {
          if (
            (this.props.orderType === 'buyings',
            this.state.role_filter.length === 0)
          ) {
            return item.is_favourite === 1;
          } else {
            return item.is_favourite === 1;
          }
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else if (this.state.search_favorit) {
      if (this.state.search_alphabet === null) {
        this.props.orderType === 'buyings' &&
        this.state.role_filter.length === 0
          ? this.setState({ items: this.state.contacts })
          : (updatedList = updatedList.filter((item) => {
              let result = item;
              return result;
            }));
      } else {
        updatedList = updatedList.filter((item) => {
          return (
            this.props.orderType === 'buyings' &&
            item.name.charAt(0) === this.state.search_alphabet
          );
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    }
    await this.extractcontactName(updatedList);
  };

  componentDidMount() {
    // let currentDeliveryDate = new Date();
    this.setState({ startDate: this.props.date });
    // this.handleChangeDelivery(currentDeliveryDate);
    // this.calendarRef = React.createRef();
    // this.calendarRefUntil = React.createRef();
    this.getCompany();
  }
  handleCancel = async () => {
    await this.props.handleClosePanel();
    await this.props.getOrders();
  };
  resaleStock = async () => {
    // if (typeof this.props.executeService === 'undefined') {
    //   let token = this.props.users.accessToken;
    //   const resaleData = {
    //     delivery_date: this.state.deliveryDate,
    //     freight_included: this.state.freight_included,
    //     orders: this.props.orders,
    //     receivers: this.state.receivers,
    //   };
    //   const resold = await OrderService.Resale(token, resaleData);
    //   if (resold.status === 200) {
    //     this.props.getEmpty();
    //     this.props.success(<FormattedMessage {...messages.ResellSuccess} />);
    //     this.props.clear();
    //     await this.props.handleClosePanel();
    //     await this.props.getOrders();
    //     this.props.handleTitleChange('My Sellings');
    //     this.props.history.push('/admin/sell-stock');
    //     this.setState({ checked: false });
    //   }
    // } else {
    //   await this.props.handleCloseTwo();
    // }
  };

  getCompany = async () => {
    let token = this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
    const user = await extractData(value);
    this.setState({
      programQuality:
        typeof user.program_quality[0] !== 'undefined'
          ? user.program_quality[0].name
          : [],
    });
    this.setState({ businessType: user.business_type });
  }
  };
  filterByText = async (event) => {
    const {
      contacts,
      search_favorit,
      search_alphabet,
      role_filter,
      programQuality,
    } = this.state;
    let updatedList = contacts;
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;

    this.setState({
      inputValue: event.target.value.replace(invalid, '').toLowerCase(),
    });

    updatedList = contacts;
    var name = event.target.value.replace(invalid, '').toLowerCase(); // try "Paul", "Paul Steve"
    var first_name = name.split(' ')[0];
    var last_name = name.substring(first_name.length).trim();

    let updatedList1 = [];

    updatedList1 = updatedList.filter((item) => {
      var nameItem = item.name.replace(invalid, '').toLowerCase(); // try "Paul", "Paul Steve"
      var first_nameItem = nameItem.split(' ')[0];
      var inverseNameItem =
        nameItem.substring(first_nameItem.length).trim() +
        ' ' +
        nameItem.split(' ')[0];

      return (
        item.name.replace(invalid, '').toLowerCase().trim().includes(name) !==
          false ||
        inverseNameItem
          .replace(invalid, '')
          .toLowerCase()
          .trim()
          .includes(name) !== false
      );
    });

    let updatedList2 = [];

    if (last_name.length === 0) {
      updatedList2 = updatedList.filter((item) => {
        return (
          item.name.toLowerCase().replace(invalid, '').trim().includes(name) !==
          false
        );
      });
    }

    updatedList = uniqBy([...updatedList1, ...updatedList2], '_id');

    if (search_favorit) {
      updatedList = updatedList.filter((item) => {
        return item.is_favourite === 1;
      });
    }

    if (search_alphabet) {
      updatedList = updatedList.filter((item) => {
        return item.name.charAt(0) === search_alphabet;
      });
    }

    if (this.props.orderType === 'buyings' && role_filter.length > 0) {
      updatedList = updatedList.filter((item) => {
        let result = item.program_quality.map((a) => a.name);
        return isEqual(result, programQuality);
      });
    }
    await this.extractcontactName(updatedList);
    this.setState({ items: updatedList });
  };
  renderDate = (timeStamp, format) => {
    const toDay = new Date();
    const date = new Date(timeStamp);
    const tomorrow = new Date(date);
    tomorrow.setDate(date.getDate());
    return date.getDate() === toDay.getDate() && format === 'input'
      ? `${addLeadingZero(tomorrow.getDate())}.${addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`
      : date.getDate() === toDay.getDate() && format === 'datepicker'
      ? tomorrow
      : date.getDate() !== toDay.getDate() && format === 'input'
      ? `${addLeadingZero(date.getDate())}.${addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`
      : date.getDate() !== toDay.getDate() && format === 'datepicker'
      ? date
      : `${addLeadingZero(date.getDate())}.${date.getMonth() + 1}.${date
          .getFullYear()
          .toString()
          .slice(-2)}`;
  };

  handleChangeDelivery = (date) => {
    if (date) {
      const year = `${date.getFullYear()}`;
      const newDate = `${date.getDate()}.${date.getMonth() + 1}.${year.substr(
        2,
        3,
      )}`;
      this.setState({
        startDate: date,
        dateChange: true,
        deliveryDate: newDate,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    const { startDate } = this.state;

    this.setState({ deliveryDate: this.renderDate(startDate, 'toBeStored') });

    switch (name) {
      case 'deliveryDate':
        this.setState({ deliveryDate: value });
        break;
      case 'externalAbattoir':
        this.setState({ externalAbattoir: value });
        break;
      default:
        break;
    }
  };

  extractcontactName = (contacts) => {
    const agr = filter(contacts, (o) => {
      return o.group === '1';
    });
    const trd = filter(contacts, (o) => {
      return o.group === '2';
    });
    const abt = filter(contacts, (o) => {
      return o.group === '3';
    });

    this.setState({ agriculturists: agr });
    this.setState({ traders: trd });
    this.setState({ abattoirs: abt });
  };

  getFavouriteContacts = async () => {
    let accessToken = this.props.users.accessToken;
    const value = await getContacts(this.state.companyId, accessToken);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contacts = await extractData(value);
      await this.setState({ contacts });
      await this.extractcontactName(this.state.contacts);
    }
  };

  handleOpenClose = (e, type) => {
    e.preventDefault();
    const { showTrd, showAgr, showAbt } = this.state;
    if (type === 'trd') {
      this.setState({ showTrd: !showTrd });
    } else if (type === 'agr') {
      this.setState({ showAgr: !showAgr });
    } else {
      this.setState({ showAbt: !showAbt });
    }
  };

  handleEdit = (e, index, data) => {
    let newDate = data;
    let receiversData = this.state.receivers[0];

    let updatedList = this.state.contacts.filter((e) => {
      return e._id === receiversData;
    });
    newDate.open_request.value = updatedList[0].name;
    newDate.open_request.id = receiversData;
    newDate.open_request.label = updatedList[0].name;
    newDate.delivery_date = this.state.deliveryDate;
    this.props.editTruck(index, newDate);
    this.props.handleCloseTwo();
  };

  handleRecipient = (e, name, index, companyType, item, companyId) => {
    if (item.is_registered === 0 && item.group === '3') {
      this.setState({
        isExternalAbtSelect: true,
      });
    } else {
      this.setState({
        isExternalAbtSelect: false,
      });
    }
    const { receivers } = this.state;
    this.setState({
      checkItem: {
        checkId: companyId,
        checkType: companyType,
      },
    });
    const filtered = receivers.filter((value) => value !== name);
    const { checked } = e.target;

    if (this.props.externalRequest) {
      this.setState({ receivers: [...[], name] });
    } else {
      checked
        ? this.setState({ receivers: [...this.state.receivers, name] })
        : this.setState({ receivers: filtered });
    }
  };

  renderFav = (item, index, companyType) => {
    const { name, _id } = item;
    let companyId = _id;
    const { checkId, checkType } = this.state.checkItem;
    const { externalRequest } = this.props;
    return (
      <div key={index} className="order-group-list-recipient buy-sell">
        {externalRequest ? (
          <input
            checked={companyId === checkId && checkType === companyType}
            type="checkbox"
            id={_id}
            onChange={(e) =>
              this.handleRecipient(e, _id, index, companyType, item, companyId)
            }
          />
        ) : (
          <input
            type="checkbox"
            id={_id}
            onChange={(e) => this.handleRecipient(e, _id, index, companyType, companyId)}
          />
        )}
        <label for={_id} className="favName">{name}</label>
      </div>
    );
  };

  showSellForm = (earTagsList, showForm) => {
    this.setState({
      earTags: [...earTagsList],
      race: earTagsList.map((item) => item.race),
    });
    this.setState({ showStock: showForm });
  };
  handleFocus = (type) => {
    this.setState({ [`on${type}Blur`]: false, [`on${type}Focus`]: true });
  };

  handleBlur = (type) => {
    this.setState({ [`on${type}Blur`]: true, [`on${type}Focus`]: false });
  };

  handleDeliveryBy = (value) => {
    this.setState({
      freight_included: value,
    });
  };

  renderFormRequest = (orderType) => {
    const {
      deliveryDate,
      piece,
      traders,
      agriculturists,
      abattoirs,
      showTrd,
      showAgr,
      showAbt,
      receivers,
      freight_included,
      onDatePickerFocus,
      onDatePickerBlur,
      search_favorit,
    } = this.state;
    const { externalRequest, editTruck } = this.props;
    // const registeredAgr = agriculturists.filter((e) => e.is_registered === 1);
    // const notRegisteredAgr = agriculturists.filter(
    //   (e) => e.is_registered === 0,
    // );
    // const showAgrContatcs = !externalRequest
    //   ? registeredAgr.length > 0
    //   : notRegisteredAgr.length > 0;
    // const registeredTrd = traders.filter((e) => e.is_registered === 1);
    // const notRegisteredTrd = traders.filter((e) => e.is_registered === 0);
    // const showtrdContatcs = !externalRequest
    //   ? registeredTrd.length > 0
    //   : notRegisteredTrd.length > 0;

    // const registeredAbt = abattoirs.filter((e) => e.is_registered === 1);
    // const notRegisteredAbt = abattoirs.filter((e) => e.is_registered === 0);
    // const showAbtContatcs = !externalRequest
    //   ? registeredAbt.length > 0
    //   : notRegisteredAbt.length > 0;
    const companyType = this.props.users.user.session.company_type;

    return (
      <div
        style={{ height: '680px' }}
        className="order-by-sell resell"
        id="resale"
      >
        <div className="resale-top-part">
          <h5 className="card-title">
            <FormattedMessage {...messages.Resale} />
          </h5>
          <div className="form-group" style={{ height: '95%' }}>
            <form onSubmit={this.handleSubmit} style={{ height: '100%' }}>
              <div className="form-wrapper">
                {this.props.showAll && (
                  <div className="order-details-transported-by">
                    <div className="transported-by-text">
                      <FormattedMessage {...messages.TransportedBy} />
                    </div>

                    <ButtonGroup
                      id="delivery-button-group"
                      aria-label="Basic example"
                    >
                      <Button
                        className={`btn-white ${
                          freight_included === 0 ? 'btn-active btn-bold' : ''
                        }`}
                        onClick={(e) => this.handleDeliveryBy(0)}
                        variant="secondary"
                      >
                        <FormattedMessage {...messages.Buyer} />
                      </Button>
                      <Button
                        className={`btn-white ${
                          freight_included === 1 ? 'btn-active btn-bold' : ''
                        }`}
                        variant="secondary"
                        onClick={(e) => this.handleDeliveryBy(1)}
                      >
                        <FormattedMessage {...messages.Seller} />
                      </Button>
                    </ButtonGroup>
                  </div>
                )}
                {this.props.showAll && (
                  <div className="order-group-list order-group-calendar regular-picker">
                    <div className="last-item-calender buy-sell-calendar">
              <RegularInput
                      picker
                      label={
                        this.state.freight_included === 0 ? (
                          <FormattedMessage {...messages.DeliveryUntilBuyer} />
                        ) : (
                          <FormattedMessage {...messages.DeliveryUntil} />
                        )
                      }
                      type="text"
                      value={this.renderDate(this.state.startDate, 'input')}
                      name="deliveryDate"
                      onFocus={() => this.handleFocus('DatePicker')}
                      onBlur={() => this.handleBlur('DatePicker')}
                      onChange={(e) => this.handleChange(e)}
                      onClick={() => {
                        this.calendarRef.current.setOpen(true);
                      }}
                    />
                    <div className="date-field picker">
                      <SvgIcon
                        color={clsx(
                          onDatePickerFocus && '#7bd811',
                          onDatePickerBlur && '#9f9f9f',
                        )}
                        onClick={this.checkDatePickerState}
                        size="30"
                        viewBox="0 0 600 600"
                      >
                        {svgPaths['calendar']}
                      </SvgIcon>

                      <DatePicker
                        selected={this.renderDate(
                          this.state.startDate,
                          'datepicker',
                        )}
                        onChange={this.handleChangeDelivery}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRef}
                        locale={locale}
                      />
                    </div>
                    </div>
                  </div>
                )}

                <div className="recipient-bottom-container">
                  <div className="recipient-list">
                    <div className="first-header-part">
                      <div className="recipient-title">
                        <FormattedMessage {...messages.Recipient} />
                      </div>
                      <div className="recipient-favorite">
                        {search_favorit ? (
                          <MdStar onClick={this.filterByFavorit} />
                        ) : (
                          <MdStarBorder onClick={this.filterByFavorit} />
                        )}
                      </div>
                    </div>
                    <div className="second-header-part recipient-search">
                      <FormGroup>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <SvgIcon
                              size="20"
                              color="#b7b6b6"
                              viewBox="0 0 60 60"
                            >
                              {svgPaths['search']}
                            </SvgIcon>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            minLength={50}
                            maxLength={50}
                            style={{ textAlign: 'left' }}
                            name="search"
                            onChange={this.filterByText}
                            placeholder={
                              this.props.language.locale === 'DE'
                                ? 'Suchen'
                                : 'Search'
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="newscroll">
                    {this.props.showAll && (
                      <div className="custom-users-list">
                        <div className="order-group-list recipientList"  onClick={(e) => this.handleOpenClose(e, 'agr')}>
                          <span className="trd">
                            {<FormattedMessage {...messages.Agriculturist} />}
                            {!externalRequest
                              ? ` (${agriculturists.length})`
                              : ` (${agriculturists.length})`}
                          </span>
                          <div className="last-itemt">
                            {showAgr ? (
                              <label
                                onClick={(e) => this.handleOpenClose(e, 'agr')}
                              >
                                <SvgIcon
                                  size="20"
                                  color="#808080"
                                  viewBox="0 0 580 580"
                                >
                                  {svgPaths['arrowCircleUp']}
                                </SvgIcon>
                              </label>
                            ) : (
                              <label
                                onClick={(e) => this.handleOpenClose(e, 'agr')}
                              >
                                <SvgIcon
                                  size="20"
                                  color="#808080"
                                  viewBox="0 0 580 580"
                                >
                                  {svgPaths['arrowCircleDown']}
                                </SvgIcon>
                              </label>
                            )}
                          </div>
                        </div>
                        {showAgr &&
                          (!externalRequest
                            ? agriculturists.map((item, index) =>
                                this.renderFav(item, index, 'agr'),
                              )
                            : agriculturists.map((item, index) =>
                                this.renderFav(item, index, 'agr'),
                              ))}
                      </div>
                    )}
                    {this.props.showAll && (
                      <div className="custom-users-list">
                        <div className="order-group-list recipientList"  onClick={(e) => this.handleOpenClose(e, 'trd')}>
                          <span className="trd">
                            {<FormattedMessage {...messages.Trader} />}
                            {!externalRequest
                              ? ` (${traders.length})`
                              : ` (${traders.length})`}
                          </span>
                          <div className="last-itemt">
                            {showTrd ? (
                              <label
                                onClick={(e) => this.handleOpenClose(e, 'trd')}
                              >
                                <SvgIcon
                                  size="20"
                                  color="#808080"
                                  viewBox="0 0 580 580"
                                >
                                  {svgPaths['arrowCircleUp']}
                                </SvgIcon>
                              </label>
                            ) : (
                              <label
                                onClick={(e) => this.handleOpenClose(e, 'trd')}
                              >
                                <SvgIcon
                                  size="20"
                                  color="#808080"
                                  viewBox="0 0 580 580"
                                >
                                  {svgPaths['arrowCircleDown']}
                                </SvgIcon>
                              </label>
                            )}
                          </div>
                        </div>
                        {showTrd &&
                          (!externalRequest
                            ? traders.map((item, index) =>
                                this.renderFav(item, index, 'trd'),
                              )
                            : traders.map((item, index) =>
                                this.renderFav(item, index, 'trd'),
                              ))}
                      </div>
                    )}
                    <div className="custom-users-list">
                      <div className="order-group-list recipientList"  onClick={(e) => this.handleOpenClose(e, 'abt')}>
                        <span className="trd">
                          {<FormattedMessage {...messages.Abbaitor} />}
                          {!externalRequest
                            ? ` (${abattoirs.length})`
                            : ` (${abattoirs.length})`}
                        </span>
                        <div className="last-itemt">
                          {showAbt ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'abt')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'abt')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {this.props.showAll
                        ? showAbt &&
                          (!externalRequest
                            ? abattoirs.map((item, index) =>
                                this.renderFav(item, index, 'abt'),
                              )
                            : abattoirs.map((item, index) =>
                                this.renderFav(item, index, 'abt'),
                              ))
                        : !showAbt &&
                          (!externalRequest
                            ? abattoirs.map((item, index) =>
                                this.renderFav(item, index, 'abt'),
                              )
                            : abattoirs.map((item, index) =>
                                this.renderFav(item, index, 'abt'),
                              ))}
                    </div>
                  </div>
                </div>

                {externalRequest &&
                  (companyType === '3' || companyType === '2') && (
                    <div
                      style={{
                        pointerEvents: this.state.isExternalAbtSelect
                          ? ''
                          : 'none',
                        opacity: this.state.isExternalAbtSelect ? '1' : '0.7',
                      }}
                      className="recipient-bottom-container"
                    >
                      <div className="recipient-list">
                        <div className="first-header-part">
                          <div className="recipient-title">
                            <FormattedMessage {...messages.externalAbattoir} />
                          </div>
                        </div>
                        <div
                          className="second-header-part recipient-search"
                          style={{ textAlign: 'right' }}
                        >
                          <Switch
                            onColor="#7bd812"
                            onChange={this.handleExternalAbtCheckbox}
                            checked={!!this.state.isExternalAbattoir}
                            className="react-switch"
                            id="normal-switch"
                          />
                        </div>
                      </div>
                      <div className="custom-users-list">
                        {this.state.isExternalAbattoir && (
                          <div className="order-group-list">
                            <RegularInput
                              className="amount-input"
                              label={
                                <FormattedMessage {...messages.tabCompany} />
                              }
                              value={this.state.externalAbattoir}
                              name="externalAbattoir"
                              required={false}
                              disabled={!this.state.isExternalAbattoir}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </form>
          </div>
        </div>
        <div
          style={{ marginTop: this.props.cond ? '28px' : '35px' }}
          className="button-group buy-sell resale-bottom-part confirm"
        >
          <NormalButton
            disabled={
              !(deliveryDate !== '' && piece !== '' && receivers.length > 0)
            }
            onClick={(e) =>
              this.handleEdit(e, this.props.index, this.props.values)
            }
          >
            <FormattedMessage {...messages.ConfirmButton} />
          </NormalButton>
        </div>
      </div>
    );
  };

  renderListStocks = () => (
    <ViewStock sellOffer={true} showSellForm={this.showSellForm} />
  );

  render() {
    const { orderType } = this.props;
    const { showStock } = this.state;
    return (
      <React.Fragment>
        {(orderType === 'buyings' ||
          (orderType === 'sellings' && !showStock)) &&
          this.renderFormRequest(orderType)}
        {orderType === 'sellings' && showStock && this.renderListStocks()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    handleTitleChange: (panelTitle) =>
      dispatch(adminActions.handleTitleChange(panelTitle)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReSellRequestAllContact),
);
