import React from 'react';

function ChatList(props) {
  if (props.selected === 'null') {
    return <div></div>;
  } else {
    if (props.selected === props.idUser) {
      if (props.isUser === 'admin') {
        return (
          <div className="outgoing_msg">
            <div className="sent_msg">
              <p>{props.details.message}</p>
              <span className="time_date"> </span>{' '}
            </div>
          </div>
        );
      } else if (props.isUser === 'user') {
        return (
          <div className="incoming_msg">
            <div className="incoming_msg_img"> </div>
            <div className="received_msg">
              <div className="received_withd_msg">
                <p>{props.details.message}</p>
                <span className="time_date"></span>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  }
}
export default ChatList;
