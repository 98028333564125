import React from 'react';

export const svgPaths = {
  calendar: (
    <g>
      <path
        d="M279,364c0,22.056,17.944,40,40,40h47c22.056,0,40-17.944,40-40v-47c0-22.056-17.944-40-40-40h-47
c-22.056,0-40,17.944-40,40V364z M319,317h47l0.025,46.999c0,0-0.007,0.001-0.025,0.001h-47V317z"
      />
      <circle cx="386" cy="210" r="20" />
      <circle cx="299" cy="210" r="20" />
      <path
        d="M492,352c11.046,0,20-8.954,20-20V120c0-44.112-35.888-80-80-80h-26V20c0-11.046-8.954-20-20-20
c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20
s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312c0,44.112,35.888,80,80,80h352c44.112,0,80-35.888,80-80
c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40H80c-22.056,0-40-17.944-40-40V120
c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20
c11.046,0,20-8.954,20-20V80h91v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v212
C472,343.046,480.954,352,492,352z"
      />
      <circle cx="125" cy="384" r="20" />
      <circle cx="125" cy="210" r="20" />
      <circle cx="125" cy="297" r="20" />
      <circle cx="212" cy="297" r="20" />
      <circle cx="212" cy="210" r="20" />
      <circle cx="212" cy="384" r="20" />
    </g>
  ),
  logout: (
    <g>
      <path
        d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3
s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6
c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1
C27.9,58.95,0,86.75,0,121.05z"
      />
      <path
        d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9
c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63
C380.6,325.15,380.6,332.95,385.4,337.65z"
      />
    </g>
  ),
  search: (
    <path
      d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
	s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
	c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
	s-17-7.626-17-17S14.61,6,23.984,6z"
    />
  ),
  arrowCircleUp: (
    <g>
      <path
        d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
      s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
      C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
      S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"
      />
      <polygon points="114.411,270.524 135.589,291.772 242.5,185.209 349.411,291.772 370.589,270.524 242.5,142.852 	" />
    </g>
  ),
  arrowCircleDown: (
    <g>
      <path
        d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5
      s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026
      C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5
      S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z"
      />
      <polygon points="242.5,299.791 135.589,193.228 114.411,214.476 242.5,342.148 370.589,214.476 349.411,193.228 	" />
    </g>
  ),
  arrowUp: (
    <g>
      <path
        d="M484.132,124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86c-7.208,0-13.964,2.792-19.036,7.86l-183.84,183.848
			L62.056,108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968,2.788-19.036,7.856l-16.12,16.128
			c-10.496,10.488-10.496,27.572,0,38.06l219.136,219.924c5.064,5.064,11.812,8.632,19.084,8.632h0.084
			c7.212,0,13.96-3.572,19.024-8.632l218.932-219.328c5.072-5.064,7.856-12.016,7.864-19.224
			C491.996,136.902,489.204,130.046,484.132,124.986z"
      />
    </g>
  ),
  arrowDown: (
    <g>
      <path
        d="M484.136,328.473L264.988,109.329c-5.064-5.064-11.816-7.844-19.172-7.844c-7.208,0-13.964,2.78-19.02,7.844
			L7.852,328.265C2.788,333.333,0,340.089,0,347.297c0,7.208,2.784,13.968,7.852,19.032l16.124,16.124
			c5.064,5.064,11.824,7.86,19.032,7.86s13.964-2.796,19.032-7.86l183.852-183.852l184.056,184.064
			c5.064,5.06,11.82,7.852,19.032,7.852c7.208,0,13.96-2.792,19.028-7.852l16.128-16.132
			C494.624,356.041,494.624,338.965,484.136,328.473z"
      />
    </g>
  ),

  cancel: (
    <g>
      <path
        d="M405.6,69.6C360.7,24.7,301.1,0,237.6,0s-123.1,24.7-168,69.6S0,174.1,0,237.6s24.7,123.1,69.6,168s104.5,69.6,168,69.6
			s123.1-24.7,168-69.6s69.6-104.5,69.6-168S450.5,114.5,405.6,69.6z M386.5,386.5c-39.8,39.8-92.7,61.7-148.9,61.7
			s-109.1-21.9-148.9-61.7c-82.1-82.1-82.1-215.7,0-297.8C128.5,48.9,181.4,27,237.6,27s109.1,21.9,148.9,61.7
			C468.6,170.8,468.6,304.4,386.5,386.5z"
      />
      <path
        d="M342.3,132.9c-5.3-5.3-13.8-5.3-19.1,0l-85.6,85.6L152,132.9c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1
			l85.6,85.6l-85.6,85.6c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.6-85.6l85.6,85.6c2.6,2.6,6.1,4,9.5,4
			c3.5,0,6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.4-85.6l85.6-85.6C347.6,146.7,347.6,138.2,342.3,132.9z"
      />
    </g>
  ),
  trash: (
    <g>
      <path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
      <path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
      <path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
      <path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
    </g>
  ),
  euro: (
    <path d="m322.019 392.823c-62.154 0-116.412-33.94-145.576-84.176h146.233v-56.118h-166.476c-1.545-9.147-2.535-18.48-2.535-28.059 0-9.578.99-18.912 2.535-28.059h166.476v-56.118h-146.233c29.163-50.237 83.421-84.176 145.576-84.176 20.606 0 41.019 3.864 60.666 11.481l10.138-52.336c-26.14-10.124-43.239-15.262-70.804-15.262-93.996 0-174.561 58.138-207.944 140.294h-57.957v56.118h43.38c-1.156 9.214-1.949 18.537-1.949 28.059s.793 18.845 1.949 28.059h-43.38v56.118h57.957c33.383 82.156 113.948 140.294 207.944 140.294 27.566 0 44.664-5.138 70.805-15.262l-10.138-52.336c-19.648 7.616-40.061 11.48-60.667 11.479z" />
  ),
  edit: (
    <g>
      <polygon points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893 			" />
      <path
        d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04
				C386.027,77.92,386.027,64.373,377.707,56.053z"
      />
    </g>
  ),
  settings: (
    <g>
      <g>
        <g>
          <path
            d="M488,200h-37.6c-5.106-21.625-13.678-42.282-25.384-61.168l26.6-26.6c3.123-3.124,3.123-8.188,0-11.312L395.08,44.352
          c-3.124-3.123-8.188-3.123-11.312,0l-26.608,26.6C338.276,59.255,317.622,50.693,296,45.6V8c0-4.418-3.582-8-8-8h-80
          c-4.418,0-8,3.582-8,8v37.6c-21.624,5.103-42.278,13.675-61.16,25.384l-26.608-26.632c-3.124-3.123-8.188-3.123-11.312,0
          L44.352,100.92c-3.123,3.124-3.123,8.188,0,11.312l26.6,26.6C59.257,157.72,50.696,178.377,45.6,200H8c-4.418,0-8,3.582-8,8v80
          c0,4.418,3.582,8,8,8h37.6c5.106,21.625,13.678,42.282,25.384,61.168l-26.632,26.6c-3.123,3.124-3.123,8.188,0,11.312
          l56.568,56.568c3.174,3.001,8.138,3.001,11.312,0l26.6-26.6C157.72,436.743,178.377,445.304,200,450.4V488c0,4.418,3.582,8,8,8h80
          c4.418,0,8-3.582,8-8v-37.6c21.625-5.106,42.282-13.678,61.168-25.384l26.6,26.6c3.124,3.123,8.188,3.123,11.312,0l56.568-56.568
          c3.123-3.124,3.123-8.188,0-11.312l-26.6-26.6C436.74,338.258,445.301,317.612,450.4,296H488c4.418,0,8-3.582,8-8v-80
          C496,203.582,492.418,200,488,200z M480,280h-36c-3.802-0.001-7.08,2.675-7.84,6.4c-4.887,24.078-14.395,46.981-28,67.44
          c-2.105,3.172-1.684,7.388,1.008,10.08l25.504,25.496l-45.256,45.256l-25.496-25.504c-2.692-2.692-6.908-3.113-10.08-1.008
          c-20.459,13.605-43.362,23.113-67.44,28c-3.725,0.76-6.401,4.038-6.4,7.84v36h-64v-36c0.001-3.802-2.675-7.08-6.4-7.84
          c-24.078-4.887-46.981-14.395-67.44-28c-3.172-2.105-7.388-1.684-10.08,1.008l-25.496,25.504L61.32,389.424l25.504-25.496
          c2.692-2.692,3.113-6.908,1.008-10.08c-13.605-20.459-23.113-43.362-28-67.44c-0.757-3.726-4.03-6.404-7.832-6.408H16v-64h36
          c3.802,0.001,7.08-2.675,7.84-6.4c4.887-24.078,14.395-46.981,28-67.44c2.105-3.172,1.683-7.388-1.008-10.08L61.32,106.576
          l45.256-45.256l25.504,25.504c2.692,2.692,6.908,3.113,10.08,1.008c20.457-13.602,43.357-23.111,67.432-28
          c3.726-0.757,6.404-4.03,6.408-7.832V16h64v36c-0.001,3.802,2.675,7.08,6.4,7.84c24.075,4.889,46.975,14.398,67.432,28
          c3.172,2.105,7.388,1.684,10.08-1.008l25.504-25.504l45.256,45.256l-25.504,25.496c-2.692,2.692-3.113,6.908-1.008,10.08
          c13.605,20.459,23.113,43.362,28,67.44c0.76,3.725,4.038,6.401,7.84,6.4h36V280z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M248,160c-48.601,0-88,39.399-88,88c0,48.601,39.399,88,88,88s88-39.399,88-88C335.943,199.423,296.577,160.057,248,160z
          M248,320c-39.764,0-72-32.236-72-72c0-39.765,32.236-72,72-72c39.765,0,72,32.235,72,72C319.956,287.746,287.746,319.956,248,320
          z"
          />
        </g>
      </g>
    </g>
  ),
};
