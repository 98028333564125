import React from 'react';
import { Modal } from 'react-bootstrap';

export default class Popup extends React.Component {
  render() {
    return (
      <Modal
        show={this.props.togglePopup}
        onHide={this.props.closePopup}
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img alt="img" src={this.props.src} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
