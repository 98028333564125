import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './confirm.scss';

const ConfirmCancel = ({
  confirmCancel,
  hideMembershipConfirm,
  cancelSubscriptionPlan,
  subscriptionPlan,
  ...props
}) => {
  return (
    <Modal
      show={confirmCancel}
      onHide={hideMembershipConfirm}
      aria-labelledby="contained-modal-title"
      className="confirm-cancel-modal"
      style={{ opacity: 1 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Bestätigen Sie die Änderung des Abonnements</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Möchten Sie Ihr gewähltes Abonnement "{subscriptionPlan.toUpperCase()}"
        wirklich zum aktuellen Monatsende kündigen? Damit verlieren Sie nach
        Ablauf des Monats alle erweiterten Funktionen.
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-btn" onClick={hideMembershipConfirm}>
          Abbrechen
        </button>
        <button className="confirm-btn" onClick={cancelSubscriptionPlan}>
          Bestätigen
        </button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmCancel.propTypes = {
  confirmCancel: PropTypes.bool,
  hideMembershipConfirm: PropTypes.func,
  cancelSubscriptionPlan: PropTypes.func,
  children: PropTypes.node,
};
export default ConfirmCancel;
