import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import FormCreditCard from '../../Invoicing/Payment/FormCreditCardInvoicing';
import Mandate from '../../Invoicing/Payment/MandateInvoicing';
import MandateInfo from '../../Invoicing/Payment/MandateInfoInvoicing';
import { invoiceActions } from '../../../../actions/invoice.actions';
import { userActions } from '../../../../actions/user.actions.js';
import { alertActions } from '../../../../actions';
import { editPayment, makePayment } from '../../../../services/invoice.service';

import './change-plan.css';
import '../setting.css';

import extractData from '../../../../services/data.processing';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      type: '',
      showInfo: false,
      showStepOne: true,
      showStepTwo: false,
      showStepThree: false,
      customer: props.customer,
      defaultRadioA: false,
      imageSrc: '',
      showInUpdate: false,
      showInUpdateTwo: false,
    };
  }

  ShowCard = () => {
    return this.setState({ type: '', showInfo: false, showStepOne: false });
  };

  componentDidMount() {
    this.getAllData();
  }

  getAllData = async () => {
    if (
      this.state.customer &&
      this.state.customer.mandate &&
      this.state.customer.mandate.status === 'valid' &&
      this.state.customer.mandate.method === 'directdebit'
    ) {
      this.setState({ type: 'directdebit' });
    }
    if (
      this.state.customer &&
      this.state.customer.mandate &&
      this.state.customer.mandate.status === 'valid' &&
      this.state.customer.mandate.method !== 'directdebit'
    ) {
      this.setState({ type: 'creditcard' });
    }
  };

  renderStepOne = () => {
    return (
      <>
        {this.state.customer &&
          this.state.customer.mandate &&
          this.state.customer.mandate.status === 'valid' &&
          this.state.customer.mandate.method === 'directdebit' && (
            <Mandate
              mandate={this.state.customer.mandate}
              showForm={this.onSubmitEditPay}
              goToStepTwoDirect={this.goToStepTwoDirect}
              downloadPdf={this.props.downloadSepa}
            />
          )}
        {this.state.customer &&
          this.state.customer.mandate &&
          this.state.customer.mandate.status === 'valid' &&
          this.state.customer.mandate.method !== 'directdebit' && (
            <MandateInfo
              mandate={this.state.customer.mandate}
              showForm={this.goToStepTwo}
              onSubmitNewVersion={this.onSubmitNewVersion}
              goToStepTwoDirectBanque={this.onSubmitEditPay}
            />
          )}
      </>
    );
  };

  renderStepThree = () => {
    return (
      <>
        {this.state.customer &&
        this.state.customer.mandate &&
        this.state.customer.mandate.status === 'valid' &&
        this.state.customer.mandate.method !== 'directdebit' ? (
          <MandateInfo
            mandate={this.state.customer.mandate}
            showForm={this.goToStepTwo}
            onSubmitNewVersion={this.onSubmitNewVersion}
            goToStepTwoDirectBanque={this.goToStepTwoDirectBanque}
          />
        ) : (
          this.onSubmitCeateNew()
        )}
      </>
    );
  };

  returnToMenu = async () => {
    this.setState({ showStepOne: true });
    this.setState({ showStepTwo: false });
    this.props.showPaymentFormCancel();
    if (
      this.state.customer &&
      this.state.customer.mandate &&
      this.state.customer.mandate.status === 'valid' &&
      this.state.customer.mandate.method === 'directdebit'
    ) {
      this.setState({ type: 'directdebit' });
    }
    if (
      this.state.customer &&
      this.state.customer.mandate &&
      this.state.customer.mandate.status === 'valid' &&
      this.state.customer.mandate.method !== 'directdebit'
    ) {
      this.setState({ type: 'creditcard' });
    }
  };

  goToStepTwo = async () => {
    this.setState({ showStepOne: false });
    this.setState({ showStepThree: false, showInUpdate: true });
    this.setState({ showStepTwo: true });
  };

  goToStepTwoDirect = async () => {
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: true, showInUpdate: true });
    this.setState({ type: 'directdebit' });
  };

  goToStepTwoDirectBanque = async () => {
    this.setState({ showStepOne: true });
    this.setState({ showStepTwo: false });
    this.setState({ showStepThree: false });
    this.setState({ type: 'creditcard' });
  };

  goToStepThree = async () => {
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: false });
    this.setState({ showStepThree: true });
  };

  toggleCheckbox = async (event) => {
    this.setState({ type: event.currentTarget.value });
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: true });
    this.renderStepTwo();
  };

  toggleCheckboxTwo = async (event) => {
    this.setState({ type: event.currentTarget.value });
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: false });
    this.setState({ showStepThree: false });
    this.goToStepTwo();
  };

  checkoutCreditCard = (url) => {
    window.location.href = url;
  };

  onSubmitNewVersion = async () => {
    const { accessToken } = this.props.users;
    if (this.state.customer && this.state.customer.mandate) {
      const value = await editPayment(
        {
          method: this.state.type,
          keepPlan: 1,
          subscriptionType: this.validateToBack(this.props.type),
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    } else {
      const value = await makePayment(
        {
          method: this.state.type,
          keepPlan: 1,
          subscriptionType: this.validateToBack(this.props.type),
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    }
  };

  onSubmitCeateNew = async () => {
    const { accessToken } = this.props.users;
    if (this.state.customer && this.state.customer.mandate) {
      const value = await editPayment(
        {
          method: this.state.type,
          keepPlan: 1,
          subscriptionType: this.validateToBack(this.props.type),
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    } else {
      const value = await makePayment(
        {
          method: this.state.type,
          keepPlan: 1,
          subscriptionType: this.validateToBack(this.props.type),
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    }
  };

  onSubmitEditPay = async () => {
    const { accessToken } = this.props.users;
    const { customer } = this.state;
    if (customer && customer.mandate) {
      const data = { keepMandate: 1, method: customer.mandate.method };
      data.subscriptionType = this.validateToBack(this.props.type);
      data.keepPlan = 1;
      const value = await editPayment(data, accessToken);
      if (value.status === 200) {
        this.props.updateSubscription(data.subscriptionType);
        this.props.success(<FormattedMessage {...messages.PaymentUpdated} />);
        this.props.clear();
        await this.props.ShowSucces();
      }
    }
  };

  validate = (text) => {
    if (text === 'business') return 'Basic';
    if (text === 'premium') return 'Pro';
    else return '';
  };
  validateToBack = (text) => {
    if (text === 'business') return 'basic';
    if (text === 'premium') return 'pro';
    else return '';
  };

  renderStepTwo = () => {
    return (
      <div className="form-container">
        {this.state.type === 'directdebit' ? (
          this.state.customer &&
          this.state.customer.mandate &&
          this.state.customer.mandate.status === 'valid' &&
          this.state.customer.mandate.method === 'directdebit' &&
          !this.state.showInUpdate ? (
            <Mandate
              mandate={this.state.customer.mandate}
              showForm={this.onSubmitEditPay}
              goToStepTwoDirect={this.goToStepTwoDirect}
              downloadPdf={this.props.downloadSepa}
            />
          ) : (
            <FormCreditCard
              returnToMenu={this.returnToMenu}
              onSubmit={this.goToStepThree}
              loading={this.props.loading}
              method="directdebit"
            />
          )
        ) : this.state.customer &&
          this.state.customer.mandate &&
          this.state.customer.mandate.status === 'valid' &&
          this.state.customer.mandate.method !== 'directdebit' &&
          !this.state.showInUpdateTwo ? (
          <MandateInfo
            mandate={this.state.customer.mandate}
            showForm={this.goToStepTwo}
            onSubmitNewVersion={this.onSubmitNewVersion}
            goToStepTwoDirectBanque={this.onSubmitEditPay}
          />
        ) : (
          <FormCreditCard
            returnToMenu={this.returnToMenu}
            onSubmit={this.goToStepThree}
            loading={this.props.loading}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <>
        <div
          id="admin-index"
          className="form-container change-plan"
          style={{ margin: '15px 0px 0px 0px' }}
        >
          <Col md={6}>
            <Row>
              <Col md={12}>
                <div className="first-discription">
                  <FormattedMessage {...messages.Disc1} />
                  &nbsp; 'Agrabiz {this.validate(this.props.type)}'!
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="third-description">
                  <FormattedMessage {...messages.BankingTag} />
                </div>
              </Col>
            </Row>
            <Row
              className="payment-radio"
              style={{ margin: '15px -108px 0px 135px' }}
            >
              <div className="rad-group">
                <input
                  type="radio"
                  value="creditcard"
                  onChange={this.toggleCheckbox}
                  checked={this.state.type === 'creditcard'}
                  name="credit"
                  id="radio-type-one"
                />
                <label className="label1" htmlFor="radio-type-one">
                  <FormattedMessage {...messages.CreditCard} />
                </label>
              </div>

              <div className="rad-group">
                <input
                  type="radio"
                  value="directdebit"
                  checked={this.state.type === 'directdebit'}
                  onChange={this.toggleCheckboxTwo}
                  name="credit"
                  id="radio-type-two"
                />
                <span className="newlabel">Lastschrift / EC</span>
              </div>
            </Row>
            <Row>
              <div className="fourth-discription">
                <FormattedMessage {...messages.Disc4} />
                <a href=" " style={{ color: '#7bd812' }}>
                  {' +498912267791 '}
                </a>
                <FormattedMessage {...messages.EmailDisc} />
                <a href=" " style={{ color: '#7bd812' }}>
                  {' abo@agrabiz.de'}
                </a>
                .
              </div>
            </Row>
          </Col>
          <Col md={6} className="FlexPayementBlock">
            {this.state.showStepOne && this.renderStepOne()}
            {this.state.showStepTwo && this.renderStepTwo()}
            {this.state.showStepThree && this.renderStepThree()}
          </Col>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    showPaymentFormCancel: () =>
      dispatch(invoiceActions.showEditPaymentCancel()),
    downloadSepa: () => dispatch(invoiceActions.downloadSepaPdf()),
    updateSubscription: (sub) =>
      dispatch(userActions.changeUserSubcription(sub)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
