import React from 'react';
import { Card, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Form } from 'formik';
import { connect } from 'react-redux';
import extractData from '../../../../services/data.processing';
import { alertActions, producerActions } from '../../../../actions';
import { getContacts } from '../../../../services/company.service';
import DatePickerField from './DatePicker';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import BuySellRequest from '../../Orders/buySellRequest/BuySellRequest';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import clsx from 'clsx';


import './form.css';
import '../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

import messages from '../../../../components/Admin/messages';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactList: [],
      openBuyRequests: this.props.producer.openBuyRequests,
      delivery_until: '',
      showPopupExternalSale: false,
      firstTimeSaving: false,
      defaultBuyRequest: this.props.producer.openBuyRequests.find(
        (req) =>
          req._id ===
          (typeof this.props.values.open_request !== 'undefined'
            ? this.props.values.open_request.id
            : ''),
      ),
    };
  }
  componentDidMount() {
    this.calendarRefTour = React.createRef();
    this.calendarRefTourUntil = React.createRef();
    this.focusTextInput();
    this.getContacts();

    if (
      typeof this.props.values.delivery_until === 'undefined' ||
      this.props.values.delivery_until === ''
    ) {
      if (this.props.values.open_request !== 'undefined') {
        this.handleByRequestChangeTimeDirect(
          typeof this.state.defaultBuyRequest !== 'undefined'
            ? this.state.defaultBuyRequest.delivery_time
            : '',
        );
      }
    }
  }

  focusTextInput = () => {
    if (this.calendarRefTour && this.calendarRefTour.input) {
      this.calendarRefTour.input.readOnly = true;
    }
  };

  getContacts = async () => {
    const token = this.props.users.accessToken;
    const { session } = this.props.users.user;
    const company_id = session.company_id;
    const groups = ['2', '4'];
    let isRegistered;
    if (session.company_type !== '3') {
      isRegistered = 1;
    }
    const value = await getContacts(company_id, token, groups);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contacts = await extractData(value);
      this.setState({ contactList: contacts });
      if (typeof this.props.producer !== 'undefined') {
        let openBuyRequestsProd =
          await this.props.producer.openBuyRequests.filter((e) => {
            return e.delivery_date === this.props.producer.date;
          });

        openBuyRequestsProd.map((e) => {
          e.request_name =
            this.props.language.locale === 'DE'
              ? e.request_name.replace('pieces', 'Stück ')
              : e.request_name;
        });
        this.setState({ openBuyRequests: openBuyRequestsProd });
      }
    }
  };

  synchronizeDates = (e) => {
    const { handleBlur, values, setFieldValue } = this.props;
    handleBlur(e);
    values.delivery_date < values.pick_up_date &&
      setFieldValue('delivery_date', e.target.value);
  };

  handleTransportCompanyChange = (transportCompany) => {
    const { setFieldValue, setContact } = this.props;
    const { contactList } = this.state;
    setFieldValue('transport_company_id', transportCompany);
    const contact = contactList.find((e) => e._id === transportCompany._id);

    setContact(contact);
  };

  handleByRequestChange = async (transportCompany) => {

    const { setFieldValue } = this.props;
    setFieldValue('open_request', transportCompany);

    // const emptyOpenRequest = {
    //   id: '',
    //   _id: '',
    //   sender_name: '',
    //   value: '',
    //   request_name: '',
    //   label: ''
    // };

    const defaultRequest = this.props.producer.openBuyRequests.find(
      (req) =>
        req._id ===
        (typeof transportCompany.id !== 'undefined' ? transportCompany.id : ''),
    );

    if (this.state.openBuyRequests.length === 1) {

      this.setState({ defaultBuyRequest: defaultRequest })
    } else {

      // setFieldValue('open_request', emptyOpenRequest);
      
      this.setState({
        defaultBuyRequest: defaultRequest,
      });
    }

    if(defaultRequest.delivery_time !== ''){

    this.setState({
      delivery_until: defaultRequest.delivery_time,
    });

    this.handleByRequestChangeTimeDirect(defaultRequest.delivery_time);
    }
  };

  handleByRequestChangeTimeDirect = (data) => {
    const { setFieldValue } = this.props;
    setFieldValue('delivery_until', data);
  };

  handleByRequestChangeTime = (event) => {
    const { setFieldValue } = this.props;
    setFieldValue('delivery_until', event.target.value);
  };

  handleExternalSaleModal = (status) => {
    this.setState({ showPopupExternalSale: status });
  };

  fetchData = async (transportCompany) => {

    let openBuyRequestsProd = this.props.producer.openBuyRequests.filter((e) => {
      return e.delivery_date === this.props.producer.date;
    });


    const defaultRequest = this.props.producer.openBuyRequests.find(
      (req) =>
        req._id ===
        (typeof transportCompany.id !== 'undefined' ? transportCompany.id : ''),
    );


    const openRequest = {
      id: defaultRequest._id,
      sender_name: defaultRequest.sender_name,
      request_name: defaultRequest.request_name,
      value: defaultRequest._id,
      label: `${defaultRequest.request_name} ${defaultRequest.sender_name}`

    };



    this.setState({ openBuyRequests: openBuyRequestsProd })
    this.handleByRequestChange(openRequest)

  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      editeTruck,
      type,
      setFieldValue,
    } = this.props;
    return (
      <div>
        <Modal
          className="producer-request-modal"
          show={this.state.showPopupExternalSale}
          onHide={() => this.handleExternalSaleModal(false)}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdfrP" id="admin">
            <BuySellRequest
              handleByRequestChange={this.handleByRequestChange}
              companyId={this.props.users.user.session.company_id}
              orderType="buyings"
              handleClosePanel={() => this.handleExternalSaleModal(false)}
              externalRequest={true}
              orderTypeInterne={false}
              deliveryDate={values.delivery_date}
              buyRequestEdit={false}
              orderDetail={[]}
              isTourPlanningProducer={true}
              reloadProducerRequest={async () => await this.getContacts()}
              fetchData={this.fetchData}
            />
          </Modal.Body>
        </Modal>
        <Card.Body className="contact-panel-body">
          <Form>
            <div className="flex truck-form">
              {type === 'MANUAL_ENTRY' && (
                <div className="second-truck-form">
                  <div className="flex-container">
                    <div className="inner-flex">
                      <RegularInput
                        name="transport_company_id"
                        onChange={this.handleTransportCompanyChange}
                        onBlur={handleBlur}
                        label={
                          <FormattedMessage {...messages.TransportCompany} />
                        }
                        select
                        className={clsx(
                          values.transport_company_id && 'selected',
                        )}
                        isDisabled={
                          editeTruck && this.props.isBlockedItem ? true : false
                        }
                        defaultValue={values.transport_company_id}
                        setFieldValue={setFieldValue}
                        options={this.state.contactList.map(
                          ({ company: value, name: label, _id }) => ({
                            value,
                            label,
                            _id,
                          }),
                        )}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: '#F1FFDE',
                            primary: '#7bcd10',
                          },
                        })}
                      />
                    </div>

                    <div className="inner-flex">
                      <RegularInput
                        name="open_request"
                        onChange={this.handleByRequestChange}
                        onBlur={handleBlur}
                        label={<FormattedMessage {...messages.Kaufanfrage} />}
                        select
                        className={clsx(values.open_request && 'selected')}
                        value={
                          this.state.defaultBuyRequest !== null && this.state.defaultBuyRequest && {
                            value:
                              this.state.defaultBuyRequest._id,
                            label:
                              this.props.language.locale === 'DE'
                                ? `${this.state.defaultBuyRequest.request_name.replace(
                                    'pieces',
                                    'Stück ',
                                  )} ${
                                    this.state.defaultBuyRequest.sender_name
                                  }`
                                : `${this.state.defaultBuyRequest.request_name} 
                                ${this.state.defaultBuyRequest.sender_name}`,
                            id: this.state.defaultBuyRequest._id,
                          }
                        }
                        setFieldValue={setFieldValue}
                        isDisabled={
                          editeTruck && this.props.isBlockedItem ? true : false
                        }
                        options={this.state.openBuyRequests.map(
                          ({ request_name: label, sender_name, _id }) => ({
                            value: _id,
                            label: label + ' ' + sender_name,
                            id: _id,
                            sender_name: sender_name,
                          }),
                        )}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: '#F1FFDE',
                            primary: '#7bcd10',
                          },
                        })}
                      />
                      <span className="focus-border"></span>
                      {touched.open_request && !errors.open_request && <span />}
                      {touched.open_request && errors.open_request && (
                        <p className="error-message">Required</p>
                      )}
                      <SvgIcon
                        size="25"
                        viewBox="0 0 600 600"
                        position="absolute"
                        right="-30px"
                        top="40px"
                        onClick={() => this.handleExternalSaleModal(true)}
                      >
                        <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
                      </SvgIcon>
                    </div>
                  </div>
                  <span className="focus-border"></span>

                  <div className="flex-container">
                    <div className="inner-flex">
                      <DatePickerField
                        label={<FormattedMessage {...messages.PickUPDate} />}
                        name="pick_up_date"
                        value={values.pick_up_date}
                        onChange={setFieldValue}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRefTour}
                        onBlur={this.synchronizeDates}
                      />

                      <span className="focus-border"></span>
                      {touched.pick_up_date && errors.pick_up_date && (
                        <p className="error-message">{errors.pick_up_date}</p>
                      )}
                    </div>
                    <div className="inner-flex">
                      <DatePickerField
                        label={<FormattedMessage {...messages.DeliveryDate} />}
                        name="delivery_date"
                        value={values.delivery_date}
                        onChange={setFieldValue}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRefTourUntil}
                      />

                      <span className="focus-border"></span>
                      {touched.delivery_date && errors.delivery_date && (
                        <p className="error-message">Check Date!</p>
                      )}
                    </div>
                  </div>

                  <div className="flex-container">
                    <div className="inner-flex">
                      <RegularInput
                        type="number"
                        id="capacity"
                        name="capacity"
                        defaultValue={values.capacity || '30'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        label={<FormattedMessage {...messages.Capacity} />}
                        disabled={
                          editeTruck && this.props.isBlockedItem ? true : false
                        }
                      />
                      <span className="focus-border"></span>
                      {touched.capacity && errors.capacity && (
                        <p className="error-message">Capacity required</p>
                      )}
                    </div>
                    <div className="inner-flex">
                      <RegularInput
                        type="time"
                        id="delivery-until"
                        name="delivery_until"
                        onChange={(e) => this.handleByRequestChangeTime(e)}
                        defaultValue={
                          (typeof this.state.defaultBuyRequest !== 'undefined' && this.state.defaultBuyRequest !== null)
                            ? this.state.defaultBuyRequest.delivery_time
                            : values.delivery_until
                        }
                        onBlur={handleBlur}
                        // setFieldValue={values.delivery_until}

                        value={values.delivery_until}
                        required
                        label={<FormattedMessage {...messages.DeliveryUntil} />}
                      />
                      <span className="focus-border"></span>
                      {touched.delivery_until && errors.delivery_until && (
                        <p className="error-message">Check Time</p>
                      )}
                    </div>
                  </div>
                  <div className="flex-container last"></div>
                  <div className="tour-planning-popup-btn">
                    {!editeTruck ? (
                      <NormalButton type="submit">
                        <FormattedMessage {...messages.TruckAdd} />
                      </NormalButton>
                    ) : (
                      <React.Fragment>
                        <div className="edit-transport-company">
                          <NormalButton
                            type="submit"

                            // onClick={() =>{
                            //                              editTransportTruck(index, values)
                            // } }
                          >
                            <FormattedMessage {...messages.TruckSave} />
                          </NormalButton>

                          {/* <NormalButton
                          type="button"
                          onClick={() => deleteTruckData(index)}
                        >
                          <FormattedMessage {...messages.TruckDelete} />
                        </NormalButton> */}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Card.Body>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getProducer: (date) => dispatch(producerActions.getAllProducer(date)),
    updateProducerbByreq: () =>
      dispatch(producerActions.updateProducerbByreq()),
  };
}

const mapStateToProps = (state) => {
  const { users, producer, language } = state.toJS();
  return {
    users,
    producer,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
