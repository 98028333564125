import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  ListGroupItem,
  Image,
  Card,
  ButtonGroup,
  Button,
  FormGroup,
  ListGroup,
  Dropdown,
} from 'react-bootstrap';
import { debounce, findIndex } from 'lodash';
import ReactGA from 'react-ga';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import moment from 'moment-es6';
import DatePicker from 'react-datepicker';
import { FormattedMessage, injectIntl } from 'react-intl';
import { alertActions, OrderActions } from '../../../actions';
import extractData from '../../../services/data.processing';
import messages from '../messages';
import TabEvents from './tabEvents';
import BuySellRequest from './buySellRequest/BuySellRequest';
import ReSellRequest from './Resell/ReSellRequest';
import { OrderService } from '../../../services/order.service';
import Bidders from './bidders';
import TourManagement from './tourMangement';
import ToBeLoaded from './toBeLoaded';
import TourInfo from './tourInfo';
import './orders.css';
import '../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import ToggleMenuButton from '../../Shared/components/buttons/ToggleMenuButton/ToggleMenuButton';
import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';
import { statusOrders } from './status-orders';
import { menuItems } from './dropDownOptions';
import { Modal } from 'react-bootstrap';
import locale from '../../../utils/DataPickerLang';
import SkeletonLoader from './SkeletonLoader';

class Orders extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      type: 'open',
      checked: false,
      toResellList: [],
      ordersTab: [],
      ordersTabAll: [],
      stats: [],
      page: 0,
      date: moment().toDate(),
      companyType: this.props.users.user.session.company_type,
      companyId: this.props.users.user.session.company_id,
      companyNumber: this.props.users.user.session.company_number,
      showDetail: false,
      showBidders: false,
      showTourInformation: false,
      showResell: false,
      externalResellRequest: false,
      showTourManagment: false,
      orderDetail: {},
      buyRequest: false,
      externalBuyRequest: false,
      resellRequest: false,
      receiversTab: [],
      userId: this.props.users.client.user_id,
      bidderActivate: false,
      theDay: '',
      boughtActivate: false,
      showToBeLoaded: false,
      cursor: 0,
      hasMore: false,
      showTourInfo: false,
      truck: {},
      truckDetail: {},
      itemBackground: null,
      alldates: [],
      showPassportList: false,
      Nextscreen: '',
      toursDetails: '',
      showPassports: true,
      buyRequestEdit: false,
      showPopup: false,
      deliveryTime: '',
      showPdfPopup: false,
      pdfId: '',
      disabled: false,
    };
  }

  days = [
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.sunday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.monday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.tuesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.wednesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.thursday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.friday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.saturday' }),
  ];

  componentDidMount() {
    this._isMounted = true;
    this.calendarRef = React.createRef();
    this.setState({ theDay: this.days[moment().day()] });
    this.getAllOders();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.type !== this.state.type ||
      prevState.date !== this.state.date
    ) {
      this.getAllOders();
    }
  }

  getAllOders = async () => {
    const { orderType } = this.props;
    await this.getOrders();
    const allOrderItems = this.props[orderType][orderType].items;
    this.setState({
      ordersTab: allOrderItems?.splice(0, 20),
      ordersTabAll: allOrderItems,
      stats: this.props[orderType][orderType].stats,
      hasMore: allOrderItems?.length > 20,
    });
  };

  UNSAFE_componentWillMount() {
    this._isMounted = true;
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isMounted = true;
    let orderId =
      typeof localStorage.getItem('orderId') !== 'undefined'
        ? localStorage.getItem('orderId')
        : '';
    let orderType = localStorage.getItem('orderType') || nextProps.orderType;

    if (nextProps !== this.props) {
      this.setState({
        ordersTab: nextProps[orderType][orderType].items?.slice(0, 20),
        ordersTabAll: nextProps[orderType][orderType].items,
        stats: nextProps[orderType][orderType].stats,
        showDetail: false,
        showResell: false,
        externalResellRequest: false,
        showPopup: false,
      });

      // push Notification
      if (orderId !== '') {
        let position = findIndex(nextProps[orderType][orderType].items, (o) => {
          return o.order_id === orderId;
        });
        if (position >= 0) {
          this.showDetail(
            nextProps[orderType][orderType].items[position],
            nextProps[orderType][orderType].items[position].status,
          );
          this.toggleBackground(position);
          localStorage.setItem('orderId', '');
          localStorage.setItem('orderType', '');
        }
      }
    }
  }

  getEmpty = () => {
    this.setState({ toResellList: [] });
  };

  handleCheckedChange = (e, orderId) => {
    const checked = e.target.checked;
    const index = this.state.toResellList.indexOf(orderId);
    this.setState({ checked });
    checked === true
      ? this.setState({
          toResellList: [...this.state.toResellList, orderId],
        })
      : this.setState(() => {
          const a = [...this.state.toResellList];
          a.splice(index, 1);
          return { toResellList: a };
        });
  };

  addToOwnStock = async () => {
    const { users } = this.props;
    let token = users.accessToken;
    const ord_id = this.state.toResellList;
    const added = await OrderService.addToOwnStock(token, ord_id);

    if (added.status === 200) {
      this.props.success(<FormattedMessage {...messages.MoveTP} />);
      this.props.clear();
      this.handleClosePanel();
      this.getOrders();

      this.setState({ toResellList: [] });
    }
  };

  generateDeliveriesPdf = async () => {
    const { users } = this.props;
    let token = users.accessToken;
    let date = this.convertDate(this.state.date);
    let time = this.state.deliveryTime;
    const pdfGeneration = await OrderService.setDeliveriesPDF(
      token,
      date,
      time,
    );
    this.setState({
      pdfId: 'data:application/pdf;base64,' + pdfGeneration,
      showPdfPopup: true,
    });
  };

  convertDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = day + '.' + month + '.' + year;
    let nameSplit = formatted.split('.');
    // new date format 01.01.20
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }
    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }
    let newDate = day + '.' + month + '.' + nameSplit[2].toString().substr(-2);
    return newDate;
  };

  convertFullDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = day + '.' + month + '.' + year;

    let nameSplit = formatted.split('.');
    // new date format 01.01.20
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = day + '.' + month + '.' + nameSplit[2];

    return newDate;
  };

  getOrders = async () => {
    this.setState({
      ordersTab: [],
      ordersTabAll: [],
      hasMore: false,
      cursor: 0,
    });
    try {
      const { orderType, getOrders } = this.props;
      const { type, page } = this.state;

      // get all dates and select
      if (type === 'completed' || type === 'deliveries') {
        await getOrders(
          orderType,
          type,
          page,
          this.convertDate(this.state.date),
        );
        if (this.props[orderType][orderType].dates.length > 0) {
          let newdata = [];
          for (
            let i = 0;
            i < this.props[orderType][orderType].dates.length;
            i++
          ) {
            newdata.push(
              new Date(
                this.props[orderType][orderType].dates[i].split('-').join(', '),
              ),
            );
          }
          this.setState({ alldates: newdata });
        }
      } else {
        await getOrders(orderType, type, page, '');
      }
      this.setState({ disabled: false });
    } catch (e) {}
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  getOrdersByScroll = () => {
    const { ordersTabAll, ordersTab, cursor } = this.state;
    const hasMoreData = ordersTab?.length < ordersTabAll?.length;
    this.setState({
      ordersTab: ordersTab?.concat(
        ordersTabAll?.slice(cursor * 20, cursor * 20 + 20),
      ),
      hasMore: hasMoreData,
      cursor: hasMoreData ? cursor + 1 : cursor,
    });
  };

  getCountType = (type) => {
    let stats;
    if (
      typeof this.state !== 'undefined' &&
      typeof this.state.stats !== 'undefined'
    ) {
      stats = this.state.stats.filter(function (item) {
        return item.status === type;
      });
      if (typeof stats[0] !== 'undefined') {
        return stats[0].count;
      }
    }
    return 0;
  };

  handleChange = async (e) => {
    e.persist();
    e.stopPropagation();

    if (this.state.disabled) {
      return;
    }
    await this.setState({
      type: e.target.value,
      toResellList: [],
      page: 0,
      ordersTab: [],
      ordersTabAll: [],
      hasMore: true,
      disabled: true,
    });
    await this.getOrders();
  };

  handleTimeChange = (e) => {
    this.setState({
      deliveryTime: e.target.value,
    });
  };

  handleCalendar = () => {
    this._calendar.setOpen(true);
  };

  dateChange = async (date) => {
    await this.setState({
      date: moment(date).toDate(),
      theDay: this.days[moment(date).day()],
      page: 0,
      ordersTab: [],
    });
    await this.setState({ ordersTabAll: [] });
    await this.getOrders();
  };

  handleChangeDate = async (date, control) => {
    let tomorrow;
    if (control === 'up') {
      tomorrow = moment(date).add(1, 'days');
    } else {
      tomorrow = moment(date).add(-1, 'days');
    }
    await this.setState({
      theDay: this.days[tomorrow.day()],
      date: tomorrow.toDate(),
      page: 0,
      ordersTab: [],
      ordersTabAll: [],
    });
    await this.getOrders();
  };

  getReceivers = async (orderId, filter = 'all') => {
    let token = this.props.users.accessToken;
    let value = await OrderService.getOrderReceivers(token, orderId, filter);
    if (value.status === 200) {
      const data = await extractData(value);
      this.setState({
        receiversTab: data,
      });
    }
  };

  changeTab = (tabToBeActive) => {
    this.setState({ type: tabToBeActive });
  };

  showOrderDetail = async (item) => {
    this.setState({ orderDetail: item });
    await this.setState({
      showDetail: true,
      showResell: false,
      externalResellRequest: false,
      showBidders: false,
      buyRequest: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
      buyRequestEdit: false,
      bidderActivate: true,
    });
    await this.getReceivers(item.order_id);
  };

  handleClosePanel = () => {
    this.setState({
      showBidders: false,
      showResell: false,
      externalResellRequest: false,
      showDetail: false,
      buyRequest: false,
      buyRequestEdit: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
      showTourManagment: false,
      showToBeLoaded: false,
      showTourInfo: false,
      showPassportList: false,
      showTourInformation: false,
      itemBackground: null,
      showPopup: false,
    });
  };

  reverseClosePanel = () => {
    this.setState({
      showTourInfo: false,
      showTourInformation: false,
      showPassportList: false,
    });
  };

  displayBuyRequestEdit = (e) => {
    this.setState({
      buyRequestEdit: true,
      showTourInfo: false,
      showTourInformation: false,
      showTourManagment: false,
    });
  };

  showResell = async (item, status) => {
    this.setState({
      showBidders: false,
      showResell: false,
      externalResellRequest: false,
      showDetail: false,
      buyRequest: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
      showTourManagment: false,
      showToBeLoaded: false,
      showTourInfo: false,
      showPassportList: false,
      showTourInformation: false,
    });
    if (item.receivers_size > 1) {
      this.setState({
        showBidders: true,
        showResell: false,
        externalResellRequest: false,
        showDetail: false,
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showTourManagment: false,
        showToBeLoaded: false,
        showTourInfo: false,
        showPassportList: false,
        showTourInformation: false,
      });

      await this.getReceivers(item.order_id);
    } else if (status === 'BOUGHT') {
      this.setState({
        showTourInformation: false,
        showBidders: false,
        showDetail: false,
        showResell: true,
        externalResellRequest: false,
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showToBeLoaded: false,
        orderId: item.order_id,
      });
    }
  };

  goToTourPlanning = async (orderId) => {
    const order = this.state.ordersTab.find((e) => e.order_id === orderId);
    if (order) {
      this.setState({
        ...this.state,
        orderDetail: order,
        showTourManagment: true,
      });
    }
  };

  getToursDetails = async (item) => {
    let token = this.props.users.accessToken;
    let value = await OrderService.getTours(token, item.order_id, "", this.props.users);
    if (value.status === 200) {
      const data = await extractData(value);
      if (typeof data[0] !== 'undefined') {
        if (data.length === 1) {
          await this.setState({
            toursDetails: data,
            Nextscreen: data[0].screen,
          });
        }
        if (data.length > 1) {
          let fetchscreen = [];
          data.forEach((item) => {
            item.screen === 'LOADING_DATA' && fetchscreen.push('LOADING_DATA');
          });
          await this.setState({
            toursDetails: data,
            Nextscreen: fetchscreen.length > 0 ? 'LOADING_DATA' : 'TOUR_INFO',
          });
        }
      }
    }
  };

  getToScreen = async () => {
    this.setState({
      showBidders: true,
      showDetail: false,
      buyRequest: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
      showTourManagment: false,
      showToBeLoaded: false,
      showTourInfo: false,
    });
    if (this.state.Nextscreen === 'LOADING_DATA')
      this.setState({
        showDetail: false,
        showResell: false,
        externalResellRequest: false,
        showBidders: false,
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showTourManagment: false,
        showToBeLoaded: true,
        showTourInfo: false,
      });

    if (this.state.Nextscreen === 'TOUR_INFO')
      this.setState({
        showDetail: false,
        showResell: false,
        externalResellRequest: false,
        showBidders: false,
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showTourManagment: false,
        showToBeLoaded: false,
        showTourInfo: true,
      });
  };

  showDetail = async (item, status) => {
    if (item.receivers_size > 1) {
      this.setState({
        showBidders: true,
        showDetail: false,
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,

        showTourManagment: false,
        showToBeLoaded: false,
        showTourInfo: false,
      });
      await this.getReceivers(item.order_id);
    } else if (status === 'TOUR_PLANNING') {
      item.is_tour_planning_responsible === 1
        ? this.setState({
            showTourInformation: true,
            showResell: false,
            externalResellRequest: false,
            showTourManagment: true,
            showBidders: false,
            showDetail: false,
            buyRequest: false,
            externalBuyRequest: false,
            buyRequestEdit: false,
            showToBeLoaded: false,
            showTourInfo: false,
          })
        : this.setState({
            showTourInformation: false,
            showResell: false,
            externalResellRequest: false,
            showTourManagment: false,
            showBidders: false,
            showDetail: true,
            buyRequest: false,
            externalBuyRequest: false,
            buyRequestEdit: false,
            showToBeLoaded: false,
            showTourInfo: false,
          });
    } else if (status === 'TOUR_ASSIGNED') {
      item.is_tour_planning_responsible === 1
        ? this.setState({
            showTourInformation: true,
            showResell: false,
            externalResellRequest: false,
            showTourManagment: true,
            showBidders: false,
            showDetail: false,
            buyRequest: false,
            externalBuyRequest: false,
            buyRequestEdit: false,
            showToBeLoaded: false,
            showTourInfo: false,
          })
        : this.setState({
            showDetail: false,
            showBidders: false,
            buyRequest: false,
            externalBuyRequest: false,
            buyRequestEdit: false,
            showTourManagment: false,
            showToBeLoaded: false,
            showTourInfo: true,
          });
    } else if (status === 'TO_BE_LOADED') {
      this.getToursDetails(item).then(() => {
        this.getToScreen();
      });
    } else if (
      [
        'LOADED',
        'ON_THE_WAY',
        'DELIVERED',
        'UNLOADED',
        'SLAUGHTERED',
        'PLANNED',
      ].includes(status)
    ) {
      this.getToursDetails(item).then(() => {
        this.getToScreen();
      });
    } else {
      this.setState({
        showDetail: true,
        showResell: false,
        externalResellRequest: false,
        showBidders: false,
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showTourManagment: false,
        showToBeLoaded: false,
        showTourInfo: false,
      });
    }
    this.setState({ orderDetail: item });
  };

  showDetailForTour = (truck, truckDetail) => {
    this.setState({
      showTourInfo: false,
      showDetail: true,
      truck,
      truckDetail,
    });
  };

  emptyTruck = () => {
    var props = Object.keys(this.state.truck);
    for (var i = 0; i < props.length; i++) {
      delete this.state.truck[props[i]];
    }
    this.setState({ truck: Object.assign({}) });
  };

  addBuyRequest = async () => {
    this.setState({
      buyRequest: true,
      showDetail: false,
      showResell: false,
      externalResellRequest: false,
      showBidders: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
    });
  };
  addExternalPurchase = () => {
    this.setState({
      externalBuyRequest: true,
      buyRequest: false,
      showDetail: false,
      showResell: false,
      externalResellRequest: false,
      showBidders: false,
      showTourManagment: false,
      showToBeLoaded: false,
      showTourInfo: false,
    });
  };

  addExternalSale = () => {
    this.setState({
      externalBuyRequest: true,
      buyRequest: false,
      showDetail: false,
      showResell: false,
      externalResellRequest: false,
      showBidders: false,
    });
  };

  addSellRequest = async () => {
    this.setState({
      buyRequest: true,
      showDetail: false,
      showResell: false,
      externalResellRequest: false,
      showBidders: false,
    });
  };

  addResellRequest = async () => {
    this.setState({
      buyRequest: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
      showDetail: false,
      showResell: true,
      externalResellRequest: false,
      showBidders: false,
    });
  };
  resaleToNonAgrabizUser = async () => {
    this.setState({
      buyRequest: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
      showDetail: false,
      showResell: false,
      externalResellRequest: true,
      showBidders: false,
    });
  };

  setShowForm = (action) => {
    const { bidderActivate } = this.state;
    if (bidderActivate) {
      this.setState({
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showDetail: false,
        showResell: false,
        externalResellRequest: false,
        showBidders: true,
        bidderActivate: false,
      });
    } else if (action === 'TOUR_INFORMATION') {
      this.setState({
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showDetail: false,
        showResell: false,
        externalResellRequest: false,
        showBidders: false,
        bidderActivate: false,
        showTourManagment: false,
        itemBackground: null,
      });
    } else {
      this.setState({
        buyRequest: false,
        externalBuyRequest: false,
        buyRequestEdit: false,
        showDetail: false,
        showResell: false,
        externalResellRequest: false,
        showBidders: false,
        itemBackground: null,
      });
    }
  };

  handleBought = () => {
    this.setState({
      boughtActivate: true,
    });
  };
  handleClosePopup = () => {
    this.setState({ showPopup: false });
  };
  handlePdfGeneration = () => {
    this.setState({
      buyRequest: false,
      externalBuyRequest: false,
      buyRequestEdit: false,
      showDetail: false,
      showResell: false,
      externalResellRequest: false,
      showBidders: false,
      itemBackground: null,
      showPopup: true,
    });
  };
  handleClosePdf = () => {
    this.setState({
      showPdfPopup: false,
    });
  };

  handleCloseToBeload = () => {
    this.setState({
      showTourInfo: true,
      showDetail: false,
    });
  };

  toggleBackground = (position) => {
    this.setState({ itemBackground: position });
  };

  backgroundChange = (position) => {
    if (this.state.itemBackground === position) {
      return '#f0fae3';
    }
    return '';
  };

  showPassportForTourInfo = (value) => {
    this.setState({ showPassportList: value });
  };

  colorIcon = (item) => {
    if (
      ['LOADED', 'TO_BE_LOADED', 'TOUR_PLANNING', 'TOUR_ASSIGNED'].includes(
        item.status,
      )
    )
      return 'grey';
    if (item.status === 'ON_THE_WAY') return 'yellow';
    else {
      return 'green';
    }
  };

  getStyle = (seller) => {
    const style =
      seller > 7 ? { marginLeft: '-124px' } : { marginLeft: '-180px' };
    return style;
  };

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  renderListOrders = (getSrcImg) => {
    let { role } = this.props.users.user;

    if (
      this.state.type === 'open' ||
      this.state.type === 'completed' ||
      this.state.type === 'bought' ||
      this.state.type === 'deliveries'
    ) {
      return (
        typeof this.state !== 'undefined' &&
        typeof this.state.ordersTab !== 'undefined' &&
        typeof this.state.ordersTab === 'object' &&
        this.state.ordersTab.map((item, i) => (
          <ListGroupItem
            key={i}
            className={
              this.state.type === 'completed' ||
              this.state.type === 'deliveries'
                ? 'list-groupp'
                : 'list-group'
            }
            style={{ background: this.backgroundChange(i) }}
            onClick={() => this.toggleBackground(i)}
          >
            <Row
              onClick={(evt) =>
                role !== 'ROLE_ABA_EMPLOYEE' &&
                this.showDetail(item, item.status)
              }
            >
              <Col md={3} className="status">
                {item.status === 'BOUGHT' && item.resold === 0 && (
                  <div id="checkbox1">
                    <label key={item.key} className="container">
                      <input
                        type="checkbox"
                        key={item.order_id}
                        onClick={(e) =>
                          this.handleCheckedChange(e, item.order_id)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                )}

                <Image
                  className="status-image"
                  src={getSrcImg(
                    item.status,
                    item.receivers_size,
                    item.topic,
                    item.is_buyer,
                  )}
                />
                <p className="status-name">
                  {
                    statusOrders.find(
                      (val) =>
                        item.status.split('_').join(' ') === val.statusOrder,
                    ).label
                  }
                </p>
              </Col>
              {this.state.type === 'deliveries' ? (
                <Col md={7} className="content">
                  <p className={clsx(this.colorIcon(item))}>
                    {item.delivery_until} {item.partner}
                  </p>
                  <p className="info-item">
                    {this.findAndReplace(item.name)} | {item.vehicle_number}
                  </p>
                </Col>
              ) : (
                <Col md={7} className="content">
                  <p className="green"> {this.findAndReplace(item.name)}</p>
                  <p className="info-item">
                    {item.delivery_date} | {item.business_partner}
                  </p>
                </Col>
              )}
              <Col md={2} className="MdNavigateNext">
                <p className="shared">
                  <MdNavigateNext color="#cdcbcb" />
                </p>
              </Col>

              {/* <Col md={7} /> */}
            </Row>
            <hr className="style-border" />
          </ListGroupItem>
        ))
      );
    } else {
      return (
        typeof this.state !== 'undefined' &&
        typeof this.state.ordersTab !== 'undefined' &&
        typeof this.state.ordersTab === 'object' &&
        this.state.ordersTab.map((item, i) => (
          <ListGroupItem>
            <Row onClick={(evt) => this.showDetail(item, item.status)}>
              <Col md={3} className="status">
                <Image
                  className="status-image"
                  src={getSrcImg(item.status, item.receivers_size)}
                />
                <p>
                  {
                    statusOrders.find(
                      (val) =>
                        item.status.split('_').join(' ') === val.statusOrder,
                    ).label
                  }
                </p>
              </Col>
              <Col md={7}>
                <p
                  className={item.status !== 'ON_THE_WAY' ? '' : 'not-avaible'}
                >
                  {item.delivery_until} {item.seller}
                </p>
              </Col>
              <Col md={2} className="MdNavigateNext">
                <p className="shared">
                  <MdNavigateNext />
                </p>
              </Col>
              <Col md={7}>
                <p className="">
                  {this.findAndReplace(item && item.name)} |{' '}
                  {item.vehicle_number}
                </p>
              </Col>
              <Col md={2} />
            </Row>
            <hr className="style-border" />
          </ListGroupItem>
        ))
      );
    }
  };

  handleSelectedAction = (func) => {
    this[func]();
  };

  render() {
    const { orderType, getSrcImg, users } = this.props;
    const {
      companyType,
      showDetail,
      showResell,
      externalResellRequest,
      orderDetail,
      companyId,
      companyNumber,
      receiversTab,
      showBidders,
      buyRequest,
      userId,
      date,
      theDay,
      showTourManagment,
      showToBeLoaded,
      showTourInfo,
      truck,
      showPassports,
      truckDetail,
      showPassportList,
      toResellList,
      externalBuyRequest,
      buyRequestEdit,
      showPopup,
    } = this.state;
    return (
      <Row className="orders all-orders">
        <Col className="orders-col" md={6} sm={12}>
          <Card className="card-box left-box">
            <Card.Body>
              <Row className="panel-body-row top-part">
                <Col className="panel-body-col" xs={12} md={12}>
                  <ButtonGroup
                    value={this.state.type}
                    onClick={this.handleChange}
                    className="order-btn-groupe"
                  >
                    <div className="button-groupe-wrapper">
                      <Button
                        disabled={this.state.disabled}
                        className={
                          this.state.type === 'open'
                            ? 'active active-tab-icon'
                            : ''
                        }
                        value={'open'}
                      >
                        {this.props.language.locale === 'DE' ? 'Offen' : 'Open'}{' '}
                        ({this.getCountType('open')})
                      </Button>
                      {((companyType === '1' && orderType !== 'sellings') ||
                        companyType === '3') && (
                        <Button
                          disabled={this.state.disabled}
                          className={
                            this.state.type === 'deliveries' ? 'active' : ''
                          }
                          value={'deliveries'}
                        >
                          {this.props.language.locale === 'DE'
                            ? 'Lieferungen'
                            : 'Deliveries'}{' '}
                          ({this.getCountType('deliveries')})
                        </Button>
                      )}
                      {companyType === '2' && orderType !== 'sellings' && (
                        <Button
                          disabled={this.state.disabled}
                          className={
                            this.state.type === 'bought' ? 'active' : ''
                          }
                          value={'bought'}
                          onClick={() => this.handleBought()}
                        >
                          {this.props.language.locale === 'DE'
                            ? 'Gekauft'
                            : 'Bought'}{' '}
                          ({this.getCountType('bought')})
                        </Button>
                      )}
                      <Button
                        disabled={this.state.disabled}
                        className={
                          this.state.type === 'completed' ? 'active' : ''
                        }
                        value={'completed'}
                      >
                        {this.props.language.locale === 'DE'
                          ? 'Abgeschlossen'
                          : 'Completed'}{' '}
                        {users.user.session.company_type !== '3'
                          ? '(' + `${this.getCountType('completed')}` + ')'
                          : ''}
                      </Button>
                    </div>
                  </ButtonGroup>
                  {(this.state.type === 'completed' ||
                    this.state.type === 'deliveries') && (
                    <FormGroup
                      controlId="formInlineName"
                      className="user-input-wrp date-time"
                    >
                      <div className="date-picker-wrapper">
                        <div
                          className="date-picker-icon"
                          onClick={() => {
                            if (this.calendarRef) {
                              this.calendarRef.current.setOpen(true);
                            }
                          }}
                        ></div>
                        <div className="the-day">{theDay}</div>
                        <DatePicker
                          selected={date}
                          onChange={this.dateChange}
                          dateFormat="dd.MM.yyyy"
                          ref={this.calendarRef}
                          highlightDates={this.state.alldates}
                          onChangeRaw={this.handleDateChangeRaw}
                          locale={locale}
                        />
                        <button
                          className="button-up"
                          onClick={() => this.handleChangeDate(date, 'down')}
                        >
                          <i className="down" />
                        </button>
                        <button
                          className="button-down"
                          onClick={() => this.handleChangeDate(date, 'up')}
                        >
                          <i className="up" />
                        </button>
                      </div>
                      <hr className="style-border" />
                      {(this.state.type === 'completed' ||
                        this.state.type === 'deliveries') && (
                        <ListGroup id="buy-sell">
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={this.getOrdersByScroll}
                            hasMore={this.state.hasMore}
                            useWindow={false}
                            getScrollParent={() => {
                              document.getElementById('buy-sell');
                            }}
                            loader={<SkeletonLoader />}
                          >
                            <ul>{this.renderListOrders(getSrcImg)}</ul>
                          </InfiniteScroll>
                        </ListGroup>
                      )}
                    </FormGroup>
                  )}
                  {this.state.type !== 'completed' &&
                    this.state.type !== 'deliveries' && (
                      <ListGroup id="buy-sell-scroller">
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={this.getOrdersByScroll}
                          hasMore={this.state.hasMore}
                          useWindow={false}
                          getScrollParent={() => {
                            document.getElementById('buy-sell-scroller');
                          }}
                          loader={<SkeletonLoader />}
                        >
                          <ul>{this.renderListOrders(getSrcImg)}</ul>
                        </InfiniteScroll>
                      </ListGroup>
                    )}
                </Col>
              </Row>
              <Row className="panel-body-options bottom-part">
                <Col md={12}>
                  <ToggleMenuButton
                    title={<FormattedMessage {...messages.Options} />}
                    lang={this.props.language.locale}
                    widthDeutsch="45%"
                    className={orderType}
                  >
                    {menuItems.map(
                      (item) =>
                        ((toResellList.length === 0 &&
                          item.type === orderType) ||
                          (toResellList.length > 0 &&
                            item.type === 'resell')) &&
                        item.businessType.includes(
                          users.user.session.company_type,
                        ) &&
                        (this.state.type === 'deliveries' && item.id === 7 ? (
                          <Dropdown.Item
                            key={item.id}
                            onClick={() => this.handleSelectedAction(item.func)}
                          >
                            {item.path && (
                              <SvgIcon size="20" viewBox="0 0 600 600">
                                {item.path}
                              </SvgIcon>
                            )}
                            <div className="menu-item-text">{item.title}</div>
                          </Dropdown.Item>
                        ) : (
                          item.id !== 7 && (
                            <Dropdown.Item
                              key={item.id}
                              onClick={() =>
                                this.handleSelectedAction(item.func)
                              }
                            >
                              {item.path && (
                                <SvgIcon size="20" viewBox="0 0 600 600">
                                  {item.path}
                                </SvgIcon>
                              )}
                              <div className="menu-item-text">{item.title}</div>
                            </Dropdown.Item>
                          )
                        )),
                    )}
                  </ToggleMenuButton>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {(showDetail ||
          showBidders ||
          buyRequest ||
          showResell ||
          externalBuyRequest ||
          externalResellRequest ||
          buyRequestEdit ||
          showPopup) && (
          <Col style={{ height: '100%' }} md={6} sm={12}>
            <div className="buy-sell card-box panel panel-default">
              <div className="navigate-before">
                <div md={3}>
                  <MdNavigateBefore
                    className="before"
                    onClick={this.setShowForm}
                  />
                </div>
              </div>
              <div
                className={clsx(
                  'order-panel panel-body',
                  buyRequest === true && 'panel-body-sell-offer',
                  orderType === 'buyings' && 'buy-req-only',
                )}
              >
                {showDetail && (
                  <TabEvents
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                    orderDetail={
                      orderDetail.receiver_id !== undefined
                        ? orderDetail
                        : truckDetail
                    }
                    orderType={orderType}
                    companyId={companyId}
                    companyNumber={companyNumber}
                    companyType={companyType}
                    userId={userId}
                    getSrcImg={getSrcImg}
                    truck={truck}
                    truckDetail={truckDetail}
                    emptyTruck={this.emptyTruck}
                    handleCloseToBeload={this.handleCloseToBeload}
                  />
                )}
                {showBidders && (
                  <Bidders
                    getSrcImg={getSrcImg}
                    companyId={companyId}
                    item={orderDetail}
                    receiversTab={receiversTab}
                    getReceivers={this.getReceivers}
                    showOrderDetail={this.showOrderDetail}
                  />
                )}
                {buyRequest && (
                  <BuySellRequest
                    orderDetail={orderDetail}
                    companyId={companyId}
                    orderType={orderType}
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                  />
                )}
                {(externalBuyRequest || buyRequestEdit) && (
                  <BuySellRequest
                    buyRequestEdit={buyRequestEdit}
                    externalRequest={true}
                    orderDetail={orderDetail}
                    companyId={companyId}
                    orderType={orderType}
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                    changeTab={this.changeTab}
                    goToTourPlanning={this.goToTourPlanning}
                  />
                )}
                {showResell && (
                  <ReSellRequest
                    externalRequest={false}
                    orderDetail={orderDetail}
                    companyId={companyId}
                    orderType={orderType}
                    orders={this.state.toResellList}
                    getEmpty={this.getEmpty}
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                    checked={this.checked}
                  />
                )}
                {externalResellRequest && (
                  <ReSellRequest
                    externalRequest={true}
                    orderDetail={orderDetail}
                    companyId={companyId}
                    orderType={orderType}
                    orders={this.state.toResellList}
                    getEmpty={this.getEmpty}
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                    checked={this.checked}
                    changeTab={this.changeTab}
                  />
                )}
              </div>
            </div>
          </Col>
        )}
        {showTourInfo && (
          <>
            <TourInfo
              orderDetail={orderDetail}
              companyId={companyId}
              handleClosePanel={this.handleClosePanel}
              showDetailForTour={this.showDetailForTour}
              showPassportList={showPassportList}
              showPassportForTourInfo={this.showPassportForTourInfo}
              showPassports={showPassports}
              getOrders={this.getOrders}
              truckId={orderDetail._id}
              toursDetails={this.state.toursDetails}
            />
          </>
        )}
        {showTourManagment && (
          <TourManagement
            orderDetail={orderDetail}
            setShowForm={this.setShowForm}
            orderType={this.props.orderType}
            type={this.state.type}
            handleClosePanel={this.handleClosePanel}
            getOrders={this.getOrders}
            changeTab={this.changeTab}
            changeDate={this.dateChange}
            displayBuyRequestEdit={this.displayBuyRequestEdit}
            getToursDate={this.getToursDate}
          />
        )}
        {showToBeLoaded && (
          <ToBeLoaded
            orderDetail={orderDetail}
            handleClosePanel={this.handleClosePanel}
            getOrders={this.getOrders}
            toursDetails={this.state.toursDetails}
          />
        )}
        {showPopup && (
          <Modal
            show={this.state.showPopup === true}
            onHide={this.handleClosePopup}
            style={{ opacity: 1 }}
          >
            <Modal.Header closeButton>
              <FormattedMessage {...messages.GeneratePdf} />
            </Modal.Header>
            <Modal.Body className="modal-pdfCondition center-element">
              <FormattedMessage {...messages.GeneratePdfPopup} />
              <input
                id="deliveryTime"
                className="form-input center-timer"
                name="deliveryTime"
                type="time"
                onChange={this.handleTimeChange}
              />
            </Modal.Body>
            <Modal.Footer style={{ borderTop: 'unset', textAlign: 'center' }}>
              <Button variant="secondary" onClick={this.handleClosePopup}>
                <FormattedMessage {...messages.cancelbtn} />
              </Button>
              <Button
                variant="primary"
                className=" btn-normal-green"
                style={{ borderColor: 'unset' }}
                onClick={this.generateDeliveriesPdf}
              >
                <FormattedMessage {...messages.Confirm} />
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={this.state.showPdfPopup === true}
          onHide={this.handleClosePdf}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdf ">
            <embed
              width="100%"
              height="90%"
              src={this.state.pdfId}
              type="application/pdf"
            />
          </Modal.Body>
        </Modal>
      </Row>
    );
  }
}

Orders.propTypes = {
  getOrders: PropTypes.func,
  buyings: PropTypes.object,
  sellings: PropTypes.object,
  users: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getOrders: (orderType, type, page, date) =>
      dispatch(OrderActions.getOrders(orderType, type, page, date)),
  };
}

const mapStateToProps = (state) => {
  const { users, buyings, sellings, language } = state.toJS();
  return {
    users,
    buyings,
    sellings,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Orders));
