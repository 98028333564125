import React, { Component } from 'react';
import {
  Col,
  Row,
  Button,
  Card,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import Dropzone from 'react-dropzone';
import { OrderService } from '../../../services/order.service';
import extractData from '../../../services/data.processing';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';

import './report.css';
const baseStyle = {
  borderWidth: 2,
  borderColor: '#dadada',
  borderStyle: 'dashed',
  borderRadius: '20px',
  padding: '25%',
};
class Reports extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
    };
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.getReport();
  }

  getReport = async () => {
    let token = this.props.users.accessToken;
    const value = await OrderService.getReport(token);
    if (value.status === 200) {
      const files = await extractData(value);
      this.setState({ files: files });
    }
  };

  formatImportHistoryDate = (date) => {
    let formattedDate = new Date(date);
    let month = `${formattedDate.getMonth() + 1}`;
    let day = `${formattedDate.getDate()}`;
    const year = formattedDate.getFullYear();
    let hour = formattedDate.getHours();
    let minutes = formattedDate.getMinutes();
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (hour < 10) hour = `0${hour}`;
    return `${[year, month, day].join('.')} ${hour}:${minutes}`;
  };


  onDrop = async (file) => {
    let token = this.props.users.accessToken;
    await OrderService.setReport(token, file[0]);
    this.getReport();
  };

  renderListReport = () =>
    this.state.files.map((item, i) => (
      <ListGroupItem>
        <Row>
          <Col md={4} xs={4}>
            {item.filename}
          </Col>
          <Col md={4} xs={4}>
            {this.formatImportHistoryDate(item.created_at)}
          </Col>
          <Col md={4} xs={4}>
            {item.status === 1 ? (
              <span className="green-status">
                <FormattedMessage {...messages.Successful} />
              </span>
            ) : (
              <span>
                <FormattedMessage {...messages.Failed} />
              </span>
            )}
          </Col>
        </Row>
      </ListGroupItem>
    ));

  render() {
    return (
      <Row className="reports-abattoir">
        <Col md={6} sm={12} style={{ height: '100%' }}>
          <Card className="card-box">
            <Card.Body>
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  let styles = { ...baseStyle };

                  return (
                    <div
                      style={styles}
                      {...getRootProps()}
                      className={classNames('dropzone', {
                        'dropzone--isActive': isDragActive,
                      })}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>
                          {' '}
                          <FormattedMessage {...messages.dropFile} />
                        </p>
                      ) : (
                        <div className="text-center">
                          <p>
                            <FormattedMessage {...messages.dropFile} />
                          </p>
                          <p> or </p>
                          <p>
                            <Button
                              className="form-btn btn btn-default"
                              type="submit"
                            >
                              <FormattedMessage {...messages.selectFile} />
                            </Button>
                          </p>
                        </div>
                      )}
                    </div>
                  );
                }}
              </Dropzone>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} sm={12} style={{ height: '100%' }}>
          <Card className="card-box">
            <Card.Body>
              <h5>
                <FormattedMessage {...messages.UploadHistory} />
              </h5>
              <hr></hr>
              <ListGroup className="list-report">
                <ul className="">{this.renderListReport()}</ul>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
