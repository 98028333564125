import React, { Component } from 'react';
import '../invoice.css';
import { MdFileDownload } from 'react-icons/md';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { alertActions, invoiceActions } from '../../../../actions';
import { downloadInvoiceDetails } from '../../../../services/invoice.service';
import messages from '../../messages';
import { FormattedMessage } from 'react-intl';

class InvoiceTable extends Component {
  state = {
    page: 1,
  };

  componentDidMount() {
    this.props.getAllInvoices();
  }
  handleClick(page) {
    this.setState({ page });
    this.props.getAllInvoices(page);
  }
  renderPagination() {
    const count = this.props.invoices.data.count;
    const pages = Math.ceil(count / 10);
    let pagination = [];
    for (let step = 1; step <= pages; step++) {
      pagination.push(
        <li
          key={step}
          onClick={() => this.handleClick(step)}
          className={this.state.page === step ? 'active' : ''}
        >
          {step}
        </li>,
      );
    }
    return pagination;
  }

  downloadDetails = async (id, number) => {
    try {
      const token = this.props.users.accessToken;
      const value = await downloadInvoiceDetails(token, id);
      const title = `Agrabiz_Rechnung_Detail_${number}.pdf`;
      FileSaver.saveAs(value, title);
    } catch (error) {
      this.props.error(<FormattedMessage {...messages.SomethingWrong} />);
      this.props.clear();
    }
  };
  formatDate = (date) => {
    let day = `${date.toString().slice(8, 10)}`;
    let month = `${date.toString().slice(5, 7)}`;
    const year = `${date.toString().slice(0, 4)}`;

    return [day, month, year].join('.');
  };

  render() {
    let tableBody =
      typeof this.props.invoices.data !== 'undefined' &&
      this.props.invoices.data.items.map((row, id) => (
        <tr className="invoices-table-tr" key={id}>
          <td>{row.number}</td>
          <td>
            {
              <>
                <FormattedMessage {...messages.From} />
                {` ${this.formatDate(row.startDate)} `}
                <FormattedMessage {...messages.To} />
                {` ${this.formatDate(row.endDate)}`}
              </>
            }
          </td>
          <td>{row.amount.net}</td>
          <td>{row.amount.gross}</td>
          <td>
            {row.isPayed ? (
              <FormattedMessage {...messages.Paid} />
            ) : (
              <FormattedMessage {...messages.Open} />
            )}
          </td>
          <td onClick={() => this.downloadDetails(row._id, row.number)}>
            <MdFileDownload className="D-tag" />
          </td>
        </tr>
      ));

    if (
      typeof this.props.invoices.data !== 'undefined' &&
      this.props.invoices.data.items.length > 0
    ) {
      return (
        <div className="tab-list">
          <div className="Agr" />
          <table className="table tab-list">
            <thead>
              <tr className="tab-header">
                <td>
                  <FormattedMessage {...messages.Number} />
                </td>
                <td>
                  <FormattedMessage {...messages.Period} />
                </td>
                <td>
                  <FormattedMessage {...messages.AmountNet} />
                </td>
                <td>
                  <FormattedMessage {...messages.AmountGross} />
                </td>
                <td>
                  <FormattedMessage {...messages.PayedAt} />
                </td>
                <td>
                  <FormattedMessage {...messages.Download} />
                </td>
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
          <ul id="page-numbers">{this.renderPagination()}</ul>
        </div>
      );
    }
    return (
      <div className="tab-list">
        <div className="Agr" />

        <div>
          <FormattedMessage {...messages.try_again} />
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getAllInvoices: (page = 0) => dispatch(invoiceActions.getAllInvoices(page)),
  };
}

const mapStateToProps = (state) => {
  const { users, invoices } = state.toJS();
  return { users, invoices };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTable);
