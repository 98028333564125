import { configConstants } from '../constants';
import { put, post, get, del } from './request';

export async function editPassword(
  current_password,
  password,
  useHitPassword,
  companyId,
  accessToken,
) {
  try {
    const data = {
      password: password,
      use_hit_pin_as_password: useHitPassword,
      current_password: current_password,
    };
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    return put(
      `${configConstants.Company}/${companyId}/use_hit_pin_as_password`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function editUseOrderReconfirmation(
  useOrderReconfirmation,
  companyId,
  accessToken,
) {
  try {
    const data = {
      use_order_reconfirmation: useOrderReconfirmation,
    };
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    return put(
      `${configConstants.Company}/${companyId}/use_order_reconfirmation`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function editPIn(
  current_pin,
  password,
  useHitPassword,
  companyId,
  accessToken,
) {
  try {
    const data = {
      new_pin: password,
      use_hit_pin_as_password: useHitPassword,
      current_pin: current_pin,
    };
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    return put(
      `${configConstants.Company}/${companyId}/use_hit_pin_as_password`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function usePassword(useHitPassword, companyId, accessToken) {
  try {
    const data = {
      use_hit_pin_as_password: useHitPassword,
    };
    const contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
    return put(
      `${configConstants.Company}/${companyId}/use_hit_pin_as_password`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}
export async function newRegisterCompany(data) {
  try {
    const contentType = 'application/json';
    return await post(`${configConstants.Company}`, contentType, '', data);
  } catch (error) {}
}

export async function getContactList(accessToken) {
  try {
    const contentType = 'application/json';
    const file = await get(
      `${configConstants.Company}/contacts_history`,
      accessToken,
      contentType,
    );
    return file;
  } catch (error) {}
}

export async function setContactList(accessToken, file) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'multipart/form-data';
    let data = { file: file };
    return await post(
      `${configConstants.Company}/import_contacts`,
      contentType,
      token,
      data,
    );
  } catch (error) {}
}

export async function addRegisterCompany(data, token) {
  try {
    const contentType = 'application/json';
    return await post(
      `${configConstants.Company}/new`,
      contentType,
      token,
      data,
    );
  } catch (error) {}
}

export async function getCompany(id, token) {
  try {
    const contentType = 'application/json';
    return await get(`${configConstants.Company}/${id}`, token, contentType);
  } catch (error) {}
}

export async function getContactPreferences(token, companyId, number) {
  try {
    const contentType = 'application/json';
    return await get(
      `${configConstants.Company}/${companyId}/contact_preferences?number=${number}`,
      token,
      contentType,
    );
  } catch (error) {}
}

export async function getDocCompany(id, accessToken, certificate_id) {
  try {
    const contentType = 'image/png';
    const resp = await get(
      `${configConstants.Company}/${id}/certificates/${certificate_id}`,
      accessToken,
      contentType,
      'arraybuffer',
    );

    let contentTypee = resp.headers['content-type'];
    return (
      `data:${contentTypee};base64,` +
      Buffer.from(resp.data, 'binary').toString('base64')
    );
  } catch (error) {}
}

export async function uploadFileQualityProgram(
  id_company,
  accessToken,
  file,
  quality,
) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('program_quality', quality);
    return await fetch(
      `${configConstants.Company}/${id_company}/certificates/upload`,
      {
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}` },
        body: formData,
      },
    );
  } catch (error) {}
}

export async function editCompany(data, id_company, accessToken) {
  try {
    const contentType = 'application/json';
    return await put(
      `${configConstants.Company}/${id_company}?old_password=''`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function editCompanyNewVersion(data, id_company, accessToken) {
  try {
    const contentType = 'application/json';
    return await put(
      `${configConstants.CompanyNewVersion}/${id_company}`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function getContacts(
  id_company,
  accessToken,
  groups,
  isRegistered,
  isFavorite,
) {
  try {
    const contentType = 'application/json';
    const filter = {};
    if (groups !== undefined) {
      filter.groups = groups;
    }
    if (isFavorite !== undefined) {
      filter.is_favourite = isFavorite;
    }
    if (isRegistered !== undefined) {
      filter.is_registered = isRegistered;
    }
    return await get(
      `${
        configConstants.Company
      }/${id_company}/contacts?filter=${JSON.stringify(filter)}`,
      accessToken,
      contentType,
    );
  } catch (error) {}
}

export async function setFavorit(company_id, accessToken, id, is_favourite) {
  try {
    const data = { is_favourite };
    const contentType = 'application/x-www-form-urlencoded';
    return await put(
      `${configConstants.Company}/${company_id}/contacts/${id}/favourites`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function putResponsible(company_id, accessToken, name, id) {
  try {
    const data = { veterinary_id: id, veterinary_name: name };
    const contentType = 'application/x-www-form-urlencoded';
    return await put(
      `${configConstants.Company}/${company_id}/veterinary`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function searchContacts(id_company, accessToken, search) {
  try {
    const contentType = 'application/json';
    return await get(
      `${configConstants.Company}/${id_company}/contacts/search?filter=${search}`,
      accessToken,
      contentType,
    );
  } catch (error) {}
}

export async function addContact(id_company, accessToken, data) {
  try {
    const contentType = 'application/json';
    return await post(
      `${configConstants.Company}/${id_company}/contacts`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function deleteContact(id_company, accessToken, id_contact) {
  try {
    return await del(
      `${configConstants.Company}/${id_company}/contacts/${id_contact}`,
      accessToken,
    );
  } catch (error) {}
}

export async function updateContact(
  companyId,
  contactId,
  contactData,
  accessToken,
) {
  try {
    const contentType = 'application/json';
    return await put(
      `${configConstants.Company}/${companyId}/contacts/${contactId}`,
      contentType,
      accessToken,
      contactData,
    );
  } catch (error) {}
}

export async function inviteContact(email, accessToken, data) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    return await post(
      `${configConstants.Company}/${email}/invite`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function addTruck(accessToken, data) {
  try {
    const contentType = 'application/json';
    return await post(
      `${configConstants.Company}/trucks`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function editTruck(id_truck, accessToken, data) {
  try {
    const contentType = 'application/json';
    return await put(
      `${configConstants.Company}/trucks/${id_truck}`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function deleteTruck(id_truck, accessToken) {
  try {
    const contentType = 'application/json';
    return await del(
      `${configConstants.Company}/trucks/${id_truck}`,
      contentType,
      accessToken,
    );
  } catch (error) {}
}

export async function getAllTruck(accessToken) {
  try {
    const contentType = 'application/json; charset=utf-8 ';
    return await get(
      `${configConstants.Company}/trucks`,
      accessToken,
      contentType,
    );
  } catch (error) {}
}

export async function getTruckbyId(id_truck, accessToken) {
  try {
    const contentType = 'application/json';
    return await get(
      `${configConstants.Company}/trucks/${id_truck}`,
      contentType,
      accessToken,
    );
  } catch (error) {}
}

export async function editAgrabizcontact(contactId, contactData, accessToken) {
  try {
    const contentType = 'application/json';
    return await put(
      `${configConstants.Company}/contacts/${contactId}/`,
      contentType,
      accessToken,
      contactData,
    );
  } catch (error) {}
}

export async function getcontactById(companyId, contactId, accessToken) {
  try {
    return await get(
      `${configConstants.Company}/${companyId}/contacts/${contactId}/`,
      accessToken,
    );
  } catch (error) {}
}

export async function getcontactByNumber(companyId, number, accessToken) {
  try {
    return await get(
      `${configConstants.Company}/${companyId}/contacts/number?number=${number}`,

      accessToken,
    );
  } catch (error) {}
}
