import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../../components/Admin/messages';
import orderBy from 'lodash/orderBy';

import './slaughter.css';

class SlaughterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listErtags: this.props.eartags,
    };
  }

  componentDidMount() {
    this.getBuyingsStock();
  }

  getBuyingsStock = () => {
    const firstNameSorter = (user) => user.slaughter_number;

    let sortedList = orderBy(this.props.eartags, [firstNameSorter], ['asc']);
    this.setState({ listErtags: sortedList });
  };

  formatEarTag = (tag) => {
    tag = tag.replace(/\s/g, '');
    var output =
      tag.substr(0, 2) +
      ' ' +
      tag.substr(2, 2) +
      ' ' +
      tag.substr(4, 3) +
      ' ' +
      tag.substr(7, tag.length - 6);
    return output;
  };

  sum = () => {
    let sum = 0;
    this.props.eartags.forEach((animal, i) => {
      let nbr = animal.slaughter_weight.replace(',', '.');
      sum = sum + parseFloat(nbr);
    });
    return sum.toFixed(2).replace(/[.,](00)|0$/, '');
  };

  average = () => {
    const average = this.sum(this.props.eartags) / this.props.eartags.length;
    return average.toFixed(2).replace(/[.,](00)|0$/, '');
  };
  monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  getText(text) {
    if (text.length > 7) {
      return text.slice(0, 8);
    } else return text;
  }

  getWeek = (animal) => {
    var myarr = animal.birth_date.split('.');
    //get todays date
    var current_date = new Date();
    var currentY = current_date.getFullYear().toString().substr(2, 4);
    let past_date;
    if (parseInt(myarr[2]) > parseInt(currentY))
      past_date = new Date(
        `${'19' + myarr[2] + '/' + myarr[1] + '/' + myarr[0]}`,
      );
    else
      past_date = new Date(
        `${'20' + myarr[2] + '/' + myarr[1] + '/' + myarr[0]}`,
      );
    var difference = this.monthDiff(past_date, current_date);
    return difference;
  };

  formatEarTag = (tag) => {
    tag = tag.replace(/\s/g, '');
    var output =
      tag.substr(0, 2) +
      ' ' +
      tag.substr(2, 2) +
      ' ' +
      tag.substr(4, 3) +
      ' ' +
      tag.substr(7, tag.length - 6);
    return output;
  };

  getItem = () => {
    return (
      typeof this.state.listErtags !== 'undefined' &&
      this.state.listErtags.map((animal) => (
        <tr>
          <td className="cell2Style">{animal.slaughter_number}</td>
          <td className="eartag2Style">
            {this.formatEarTag(animal.ear_tag_output_format)}
          </td>
          <td className="cell2Style">{this.getWeek(animal)} m</td>
          <td className="cell2Style">{animal.slaughter_weight}</td>
          <td className="cell2Style">{this.getText(animal.breed)}</td>
          <td className="cell2Style">{animal.classification}</td>
          <td className="eartag2Style">{animal.program_quality}</td>
          <td className="cell2Style">{animal.remarks}</td>
        </tr>
      ))
    );
  };

  render() {
    return (
      <div className="flexBody">
        <div style={{ height: '92%' }}>
          <div
            className="flexBody-table"
            style={{
              flexDirection: 'column',
              display: 'flex',
              whiteSpace: 'nowrap',
            }}
          >
            <table style={{ minWidth: 400, borderCollapse: 'collapse' }}>
              <thead
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  display: 'flex',
                  height: '10%',
                }}
              >
                <tr>
                  <th className="cellStyle">
                    <FormattedMessage {...messages.Nr} />
                  </th>
                  <th className="eartagStyle">
                    <FormattedMessage {...messages.EarTag} />
                  </th>
                  <th className="cellStyle">
                    <FormattedMessage {...messages.Age} />
                  </th>
                  <th className="cellStyle">
                    <FormattedMessage {...messages.Weight} />
                  </th>
                  <th className="cellStyle">
                    <FormattedMessage {...messages.NewBreed} />
                  </th>
                  <th className="cellStyle">
                    <FormattedMessage {...messages.Class} />
                  </th>
                  <th className="eartagStyle">
                    <FormattedMessage {...messages.Qp} />
                  </th>
                  <th className="cellStyle">
                    <FormattedMessage {...messages.Notes} />
                  </th>
                </tr>
              </thead>

              <div
                className="slaughtoooo"
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  flexDirection: 'column',
                  display: 'flex',
                }}
              >
                {this.getItem()}
              </div>
            </table>
          </div>
        </div>
        <div
          value="all"
          className="footerSlaught"
          style={{ width: '990px !important' }}
        >
          <span
            className="btnn btn-defaulty"
            style={{ width: '326px !important' }}
          >
            <FormattedMessage {...messages.SlaughterText} />
            {this.props.eartags.length} St.
          </span>
          <span
            value="all"
            className="btnn btn-defaulty"
            style={{ width: '326px !important' }}
          >
            Ø{this.average()} kg
          </span>
          <span
            value="all"
            className="btnn btn-defaulty"
            style={{ width: '326px !important' }}
          >
            {this.sum()} kg
          </span>
        </div>
      </div>
    );
  }
}

export default SlaughterList;
