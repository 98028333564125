import React from 'react';
import SpecificationItem from './SpecificationItem';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './specification.scss';

const MembershipSpecifications = ({
  specifications,
  companyType,
  ...props
}) => {
  return (
    <div id="m-specification-section">
      {specifications.map((specification, idx) => (
        <div
          key={`membership-${idx}`}
          className={clsx('col', companyType === '1' ? 'col-md-3' : 'col-md-4')}
        >
          <SpecificationItem specification={specification} />
        </div>
      ))}
    </div>
  );
};

MembershipSpecifications.propTypes = {
  specification: PropTypes.object,
  children: PropTypes.node,
};

export default MembershipSpecifications;
