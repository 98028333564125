import React from 'react';
import success from '../../static/images/check.png';
import './register-success.scss';

const RegisterSuccessMobile = () => {
  return (
    <div className="register-success-screen">
      <div className="register-success-top">
        <div className="r-success-icon">
          <img src={success} alt=""></img>
        </div>
        <div className="first-message">Sie sind erfolgreich registriert</div>
        <div className="first-message-sub">
          Sie können jetzt mit der einrichtung ihres Profils fortsetzen
        </div>
        <a href="https://www.agrabiz.de/">
          <div className="second-message">
            Bitte laden Sie die mobile Version herunter.
          </div>
        </a>
      </div>
      <div className="register-success-bottom">
        <a href="https://www.agrabiz.de/" className="download-button">
          Herunterladen
        </a>
      </div>
    </div>
  );
};

RegisterSuccessMobile.propTypes = {};

export default RegisterSuccessMobile;
