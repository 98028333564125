import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addTruck, editTruck } from '../../../../services/company.service';
import messages from '../../../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import { Form, Col } from 'react-bootstrap';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import RegularInput from '../../../Shared/components/inputs/RegularInput';

import './form.css';

class FormTransport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      defaultV: {},
      capacity: this.props.truck.capacity,
      licence_number: this.props.truck.licence_number,
      level_number: this.props.truck.level_number,
      type: this.props.truck.type,
    };
  }

  UNSAFE_componentWillMount() {
    this.handleOptionsSelect();
  }

  handleOptionsSelect = () => {
    const { type } = this.props.truck;
    let r;
    switch (type) {
      case 'A':
        r = {
          value: 'A',
          label: <FormattedMessage {...messages.Articulated} />,
        };
        break;
      case 'B':
        r = { value: 'B', label: <FormattedMessage {...messages.Lorry} /> };
        break;
      case 'C':
        r = { value: 'C', label: <FormattedMessage {...messages.Trailer} /> };
        break;
      default:
        break;
    }
    let options = [];

    options.push({
      value: 'A',
      label: <FormattedMessage {...messages.Articulated} />,
    });
    options.push({
      value: 'B',
      label: <FormattedMessage {...messages.Lorry} />,
    });
    options.push({
      value: 'C',
      label: <FormattedMessage {...messages.Trailer} />,
    });
    this.setState({ options });

    this.setState({ defaultV: r });
  };

  handleSubmit = async event => {
    event.preventDefault();
    let token = this.props.users.accessToken;
    const data = {
      licence_number: this.state.licence_number,
      level_number: this.state.level_number,
      capacity: this.state.capacity,
      type: this.state.type,
    };
    if (this.props.truck._id) {
      await editTruck(this.props.truck._id, token, data);
      await this.props.fetchTrucks();
    } else {
      await addTruck(token, data);
      await this.props.fetchTrucks();
    }
    this.props.handleModal();
  };

  handleSelect = type => {
    this.setState({ type: type.value });
  };
  handleChangeName = event => {
    this.setState({ licence_number: event.target.value });
  };
  handleChangeEmail = event => {
    this.setState({ level_number: event.target.value });
  };
  handleChangePhone = event => {
    this.setState({ capacity: event.target.value });
  };

  render() {
    return (
      <div className="transport-form-wrapper">
        <Form
          className="form-user-settings add-transport-top-part"
          id="add-transport-form"
          onSubmit={this.handleSubmit}
        >
          <Col md={12}>
            <RegularInput
              label={<FormattedMessage {...messages.AddTransportsNumber} />}
              name="userName"
              type="name"
              defaultValue={this.state.licence_number}
              onChange={this.handleChangeName}
              required
            />
          </Col>
          <Col md={12}>
            <RegularInput
              label={<FormattedMessage {...messages.AddTransportsLevel} />}
              type="number"
              name="userEmail"
              defaultValue={this.state.level_number}
              onChange={this.handleChangeEmail}
              required
            />
          </Col>
          <Col md={12}>
            <RegularInput
              label={<FormattedMessage {...messages.AddTransportsCapacity} />}
              name="userPhone"
              type="number"
              defaultValue={this.state.capacity}
              onChange={this.handleChangePhone}
              required
            />
          </Col>
          <Col md={12}>
            <RegularInput
              defaultValue={this.state.defaultV}
              onChange={this.handleSelect}
              options={this.state.options}
              label={<FormattedMessage {...messages.AddTransportsType} />}
              select
              className={this.state.type && 'selected'}
              required
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
          </Col>
        </Form>
        <Col sm={12} className="add-transport-bottom-part">
          <NormalButton form="add-transport-form" type="submit">
            <FormattedMessage {...messages.Save} />
          </NormalButton>
        </Col>
      </div>
    );
  }
}

FormTransport.propTypes = {
  addUser: PropTypes.func,
  handleModal: PropTypes.func,
  type: PropTypes.oneOf(['A', 'B', 'C', '4', '5']),
  truck: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    // addUser: data => dispatch(userActions.addUser(data)),
    // editUser: (data, userId) => dispatch(userActions.editUser(data, userId)),
  };
}
const mapStateToProps = state => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormTransport);
