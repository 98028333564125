import React from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { history } from '../../helpers';
import PrivateRouteLogin from '../../privateRoute/PrivateRooteLogin';
import LandingPage from '../../containers/LandingPage/Loadable';
import Login from '../../containers/Login/';
import Reset from '../../containers/Reset/';
import Forget from '../../containers/Forget/';
import InitUser from '../../containers/InitUser/';
import CookiePolicy from '../../components/CookiePolicy';
import Register from '../../containers/Register';
import AddCompany from '../../containers/AddCompany';
import NotFoundPage from '../../containers/NotFoundPage';
import favicon from '../../static/images/favicon.ico';
import { connect } from 'react-redux';
import RegisterSuccessMobile from '../../components/Register/RegisterSuccessMobile';
import ScreenSupport from './ScreenSupport';
import { isIE } from './supported.browsers';
import BrowserSupport from './BrowserSupport';
import './app.scss';

const App = ({ users }) => {
  let isConnect = users.isConnected || false;
  // Google Anaylse Configuration
  ReactGA.initialize('UA-150274512-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.event({
    category: users.user.name || 'user',
    action: 'Page',
  });
  return (
    <div style={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>
      <Helmet>
        <title>Agrabiz</title>
        <link rel="icon" href={favicon} />

        <meta name="description" content="Agrabiz description" />
      </Helmet>
      {!isIE ? (
        <Router history={history}>
          <div>
            <Route path="/register/step/:step" component={Register} />
            <Route path="/add_company/step/:step" component={AddCompany} />
            <Route exact path="/cookie-richtlinie" component={CookiePolicy} />
            <Route path="/enter-password/:token" component={InitUser} />
            <Route path="/forgot" component={Forget} />
            <Route path="/reset/:token" component={Reset} />
            <Route exact path="/" component={LandingPage} />
            <PrivateRouteLogin
              path="/login"
              component={Login}
              isConnected={isConnect}
            />
            <Route path="/register/success" component={RegisterSuccessMobile} />
            <Route path="/notFoundPage" component={NotFoundPage} />
            <ScreenSupport isConnected={isConnect} />
          </div>
        </Router>
      ) : (
        <BrowserSupport />
      )}
    </div>
  );
};

App.propTypes = {
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, null)(App);
