import { defineMessages } from 'react-intl';

export default defineMessages({
  HomeNewStock: {
    id: 'agrabiz.components.home.new.stock',
    defaultMessage: 'Add new Stock',
  },
  typeofBusiness: {
    id: 'agrabiz.components.admin.typeofbusiness',
    defaultMessage: 'Senden!',
  },
  tabCompany: {
    id: 'agrabiz.components.admin.tab.company',
    defaultMessage: 'Betrieb',
  },
  company: {
    id: 'agrabiz.components.admin.company',
    defaultMessage: 'Betriebsname (bzw. Name des Besitzers)',
  },
  companyNumber: {
    id: 'agrabiz.components.admin.companynumber',
    defaultMessage: 'Betriebsnummer',
  },
  firstName: {
    id: 'agrabiz.components.admin.firstname',
    defaultMessage: 'Vorname',
  },
  DownloadFile: {
    id: 'agrabiz.components.admin.downloadFile',
    defaultMessage: 'Download',
  },
  lastName: {
    id: 'agrabiz.components.admin.lastname',
    defaultMessage: 'Nachname',
  },
  address: {
    id: 'agrabiz.components.admin.address',
    defaultMessage: 'Address',
  },
  zipCode: {
    id: 'agrabiz.components.admin.zipcode',
    defaultMessage: 'Postleitzahl',
  },
  city: {
    id: 'agrabiz.components.admin.city',
    defaultMessage: 'Stadt',
  },
  streetNumber: {
    id: 'agrabiz.components.admin.streetNumber',
    defaultMessage: 'Strasse, Nr.',
  },
  federalState: {
    id: 'agrabiz.components.admin.federalstate',
    defaultMessage: 'Bundesland',
  },
  businessType: {
    id: 'agrabiz.components.admin.businessType',
    defaultMessage: 'Unternehmensart',
  },
  country: {
    id: 'agrabiz.components.admin.country',
    defaultMessage: 'Land',
  },
  email: {
    id: 'agrabiz.components.admin.email',
    defaultMessage: 'E-mail',
  },
  phoneNumber: {
    id: 'agrabiz.components.admin.phonenumber',
    defaultMessage: 'Phone Number',
  },
  mobileNumber: {
    id: 'agrabiz.components.admin.mobilenumber',
    defaultMessage: 'Mobile Number',
  },
  vetPhoneNumber: {
    id: 'agrabiz.components.admin.vetphonenumber',
    defaultMessage: 'Veterinarian Phone Number',
  },
  faxNumber: {
    id: 'agrabiz.components.admin.faxnumber',
    defaultMessage: 'Fax-Nr.',
  },
  qualityProgram: {
    id: 'agrabiz.components.admin.qualityprogram',
    defaultMessage: 'Qualitätsprogramm-Zertifikate hochladen',
  },

  QualityProgram: {
    id: 'agrabiz.components.admin.Qualityprogram',
    defaultMessage: 'Quality Program',
  },
  QualityPrograms: {
    id: 'agrabiz.components.admin.Qualityprograms',
    defaultMessage: 'Quality Programs',
  },
  typeofuse: {
    id: 'agrabiz.components.admin.typeofuse',
    defaultMessage: 'Nutzungsart',
  },
  vetPractitioner: {
    id: 'agrabiz.components.admin.vetpractitioner',
    defaultMessage: 'Hoftierarzt',
  },

  vetPractitionerTitle: {
    id: 'agrabiz.components.admin.vetPractitionerTitle',
    defaultMessage: 'Hoftierarzt',
  },
  vetFirstName: {
    id: 'agrabiz.components.admin.vetfirstname',
    defaultMessage: 'Hoftierarzt: Vorname',
  },
  vetCompanyName: {
    id: 'agrabiz.components.admin.vetcompanyname',
    defaultMessage: 'Hoftierarzt: Betriebsname',
  },
  vetLastName: {
    id: 'agrabiz.components.admin.vetlastname',
    defaultMessage: 'Hoftierarzt: Nachname',
  },
  vetstreet: {
    id: 'agrabiz.components.admin.vetstreet',
    defaultMessage: 'Hoftierarzt: Straße, Nr.',
  },
  vetZipCode: {
    id: 'agrabiz.components.admin.vetzipcode',
    defaultMessage: 'Hoftierarzt: PLZ',
  },
  vetCity: {
    id: 'agrabiz.components.admin.vetcity',
    defaultMessage: 'Hoftierarzt: Stadt',
  },
  approvalNumber: {
    id: 'agrabiz.components.admin.approvalnumber',
    defaultMessage: 'Approval number',
  },
  newCompany: {
    id: 'agrabiz.components.admin.newCompany',
    defaultMessage: 'Neue Firma',
  },

  registerStepThree: {
    id: 'agrabiz.components.admin.registerStepThree',
    defaultMessage: 'Register',
  },
  RegisterError: {
    id: 'agrabiz.components.register.stepone.error',
    defaultMessage:
      'Passwort nicht korrekt, bitte geben Sie Ihr Passwort erneut ein',
  },
  RegisterExistError: {
    id: 'agrabiz.components.register.stepone.exist.error',
    defaultMessage: 'COMPANY ALREADY REGISTERED',
  },

  RegisterStepOneTitle1: {
    id: 'agrabiz.components.register.stepone.title1',
    defaultMessage: 'Thank you for your interest in our Agrabiz service!',
  },

  RegisterStepOneTitle2: {
    id: 'agrabiz.components.register.stepone.title2',
    defaultMessage:
      'For farmers and traders, we offer additional versions with an expanded range of functions, which you can automatically test for the first 30 days without any obligation. During this time, you can conveniently decide whether you want to opt for the free basic version or our extended range of functions.',
  },
  RegisterStepOneTitle3: {
    id: 'agrabiz.components.register.stepone.title3',
    defaultMessage:
      'For farmers and traders, we offer additional versions with an expanded range of functions, which you can automatically test for the first 30 days without any obligation. During this time, you can conveniently decide whether you want to opt for the free basic version or our extended range of functions.',
  },
  RegisterStepOneTextSignIn1: {
    id: 'agrabiz.components.register.stepone.text.signin1',
    defaultMessage: 'You are a transport company and don´t use a HI-Tier PIN?',
  },
  RegisterStepOneTransporterTextOne: {
    id: 'agrabiz.components.register.stepone.text.transporter.one',
    defaultMessage: 'You are a transport company and don´t use a HI-Tier PIN?',
  },
  RegisterStepOneTransporterTextTwo: {
    id: 'agrabiz.components.register.stepone.text.transporter.two',
    defaultMessage: 'Contact us through',
  },

  RegisterStepOneButtton: {
    id: 'agrabiz.components.register.stepone.button',
    defaultMessage: 'Fortsetzen',
  },
  RegisterStepOneTextSignIn2: {
    id: 'agrabiz.components.register.stepone.text.signin2',
    defaultMessage: 'Anmeldung',
  },
  RegisterStepTwoTitleAgriculture: {
    id: 'agrabiz.components.register.stepTwo.title.agriculture',
    defaultMessage: 'Agriculturist',
  },
  RegisterStepTwoTextAgriculture: {
    id: 'agrabiz.components.register.stepTwo.text.agriculture',
    defaultMessage:
      'Mit dem Klick auf "Weiter" bestätigen Sie, dass Sie Besitzer oder Geschäftsführer eines Landwirtschaftsbetriebs für Rinderzucht, Rindermast oder Milchvieh in Deutschland unter der von Ihnen angegebenen Betriebsnummer sind und Sie autorisiert sind, stellvertretend für den angegebenen Betrieb die Anmeldung zum Agrabiz Service durchzuführen. ',
  },
  RegisterStepTwoTitleTransporter: {
    id: 'agrabiz.components.register.stepTwo.title.transporter',
    defaultMessage: 'Transportunternehmen',
  },
  RegisterStepTwoTextTransporter: {
    id: 'agrabiz.components.register.stepTwo.text.transporter',
    defaultMessage:
      'Mit dem Klick auf "Weiter" bestätigen Sie, dass Sie Besitzer oder Geschäftsführer eines Spediteurbetriebs für Lebendviehtransporte in Deutschland unter der von Ihnen angegebenen Betriebsnummer sind und Sie autorisiert sind, stellvertretend für den angegebenen Betrieb die Anmeldung zum Agrabiz Service durchzuführen. ',
  },
  RegisterStepTwoTitleAbatoire: {
    id: 'agrabiz.components.register.stepTwo.title.abatoire',
    defaultMessage: 'Schlachthof',
  },
  RegisterStepTwoTextAbatoire: {
    id: 'agrabiz.components.register.stepTwo.text.abatoire',
    defaultMessage:
      'Mit dem Klick auf "Weiter" bestätigen Sie, dass Sie Besitzer oder Geschäftsführer eines Schlachtbetriebs in Deutschland unter der von Ihnen angegebenen Betriebsnummer sind und Sie autorisiert sind, stellvertretend für den angegebenen Betrieb die Anmeldung zum Agrabiz Service durchzuführen. ',
  },
  RegisterStepTwoTitleTrader: {
    id: 'agrabiz.components.register.stepTwo.title.trader',
    defaultMessage: 'Trader',
  },
  RegisterStepTwoTextTrader: {
    id: 'agrabiz.components.register.stepTwo.text.trader',
    defaultMessage:
      'Mit dem Klick auf "Weiter" bestätigen Sie, dass Sie Händler von Rindern im Nutz- und Schlachtviehmarkt in Deutschland unter der von Ihnen angegebenen Betriebsnummer sind und Sie autorisiert sind, stellvertretend für den angegebenen Betrieb die Anmeldung zum Agrabiz Service durchzuführen. ',
  },
  RegisterStepTwoTitleVet: {
    id: 'agrabiz.components.register.stepTwo.title.vet',
    defaultMessage: 'Vet',
  },
  RegisterStepTwoTextVet: {
    id: 'agrabiz.components.register.stepTwo.text.vet',
    defaultMessage:
      'Mit dem Klick auf "Weiter" bestätigen Sie, dass Sie Angestellter eines Veterinäramts in Deutschland unter der von Ihnen angegebenen Betriebsnummer sind und Sie autorisiert sind, stellvertretend für den angegebenen Betrieb die Anmeldung zum Agrabiz Service durchzuführen. ',
  },
  RegisterStepTwoTextPrivVet: {
    id: 'agrabiz.components.register.stepTwo.text.Privet',
    defaultMessage:
      'Mit dem Klick auf "Weiter" bestätigen Sie, dass Sie Angestellter eines Veterinäramts in Deutschland unter der von Ihnen angegebenen Betriebsnummer sind und Sie autorisiert sind, stellvertretend für den angegebenen Betrieb die Anmeldung zum Agrabiz Service durchzuführen. ',
  },
  RegisterStepThreeEmailExist: {
    id: 'agrabiz.components.register.stepThree.email.error',
    defaultMessage:
      'This email is already registered for a different company. In case you are trying to add another company number to your profile, please do so through the Side-Menu ',
  },
  RegisterStepTwoTitlePriVet: {
    id: 'agrabiz.components.register.stepTwo.title.Privet',
    defaultMessage: 'Vet',
  },
  RegisterStepThreeBuisnessType: {
    id: 'agrabiz.components.register.stepThree.buisnessType',
    defaultMessage: 'Buisness Type',
  },
  RegisterStepThreeTitle: {
    id: 'agrabiz.components.register.stepThree.title',
    defaultMessage:
      'Please take your time to provide us with your business information  ',
  },
  SettingMessage: {
    id: 'agrabiz.components.admin.Side.setting',
    defaultMessage: 'Setting',
  },
  UsersSideMessage: {
    id: 'agrabiz.components.admin.Side.users',
    defaultMessage: 'Users',
  },
  ManagementSideMessage: {
    id: 'agrabiz.components.admin.Side.Management',
    defaultMessage: 'Management',
  },
  FleetSideMessage: {
    id: 'agrabiz.components.admin.Side.fleet',
    defaultMessage: 'Fleet Management',
  },
  SecuritySideMessage: {
    id: 'agrabiz.components.admin.Side.security',
    defaultMessage: 'Security',
  },
  NewsSideMessage: {
    id: 'agrabiz.components.admin.Side.news',
    defaultMessage: 'News',
  },

  HomeSideMessage: {
    id: 'agrabiz.components.admin.Side.home',
    defaultMessage: 'Home',
  },
  MembershipSideMessage: {
    id: 'agrabiz.components.admin.Side.membership',
    defaultMessage: 'Membership',
  },
  MemberShipDetailsSideMessage: {
    id: 'agrabiz.components.admin.Side.membershipdetails',
    defaultMessage: 'Membershipdetails',
  },

  HistorySideMessage: {
    id: 'agrabiz.components.admin.Side.history',
    defaultMessage: 'History',
  },
  PasswordManagementMessage: {
    id: 'agrabiz.components.admin.security.passwordmanagement',
    defaultMessage: 'Password Management',
  },
  ManageOrdersReconfirmation: { 
    id: 'agrabiz.components.admin.security.manageOrdersReconfirmation',
    defaultMessage: 'Manage Orders Reconfirmation',
  },
  DisableReconfirmation: {
    id: 'agrabiz.components.admin.security.disableReconfirmation',
    defaultMessage: 'Disable Reconfirmation',
  },
  DisableReconfirmationDescription: {
    id: 'agrabiz.components.admin.security.disableReconfirmationDescription',
    defaultMessage: 'I would like to reconfirm the conditions again after my trading partner has confirmed my purchase request or my sales offer.',
  },
  CompanyOwner: {
    id: 'agrabiz.components.admin.security.CompanyOwner',
    defaultMessage: 'Company Owner',
  },
  SignatureMessage: {
    id: 'agrabiz.components.admin.security.signaturemanagement',
    defaultMessage: 'Signature Management',
  },
  AccessLogMessage: {
    id: 'agrabiz.components.admin.security.accesslog',
    defaultMessage: 'Access Log',
  },
  NewHitPinMessage: {
    id: 'agrabiz.components.admin.security.newhitpin',
    defaultMessage: 'New Hit Pin',
  },
  UseHitMessage: {
    id: 'agrabiz.components.admin.security.usehit',
    defaultMessage: 'Use Hit Password for Agrabiz login',
  },
  NewPasswordMessage: {
    id: 'agrabiz.components.admin.security.newpassword',
    defaultMessage: 'New Password',
  },
  ConfirmPasswordMessage: {
    id: 'agrabiz.components.admin.security.confirmpassword',
    defaultMessage: 'Confirm Password',
  },
  EditMessage: {
    id: 'agrabiz.components.admin.edit',
    defaultMessage: 'Edit',
  },
  Edit: {
    id: 'agrabiz.components.admin.edit.element',
    defaultMessage: 'Edit',
  },
  GeneralInformationMessage: {
    id: 'agrabiz.components.admin.security.generalinformation',
    defaultMessage: 'My profile',
  },
  HomeAddStock: {
    id: 'agrabiz.components.home.add.stock',
    defaultMessage: 'Add Stock',
  },
  AddFilter: {
    id: 'agrabiz.components.home.add.filter',
    defaultMessage: 'Add Filter',
  },
  HomeViewStock: {
    id: 'agrabiz.components.home.view.stock',
    defaultMessage: 'View & Sort Stock',
  },
  HomeDrugAdministration: {
    id: 'agrabiz.components.home.drug.administration',
    defaultMessage: 'Drug Administration',
  },
  HomeBuyings: {
    id: 'agrabiz.components.home.buyings',
    defaultMessage: 'My Buyings',
  },
  HomeSellings: {
    id: 'agrabiz.components.home.sellings',
    defaultMessage: 'My Sellings',
  },
  HomeReportsSearch: {
    id: 'agrabiz.components.home.reports.search',
    defaultMessage: 'Reports & Search',
  },
  HomeLiveStock: {
    id: 'agrabiz.components.home.reports.live.stock',
    defaultMessage: 'Livestock Delivery',
  },
  HomeSearchEarTag: {
    id: 'agrabiz.components.home.reports.search.ear.tag',
    defaultMessage: 'Search for ear tag',
  },
  HomeDeliveryStock: {
    id: 'agrabiz.components.home.delivery.stock',
    defaultMessage: 'Delivery & Buyings',
  },
  HomeManageTours: {
    id: 'agrabiz.components.home.manage.tours',
    defaultMessage: 'Manage Tours',
  },
  HomeManageViehanmeldung: {
    id: 'agrabiz.components.home.manage.HomeManageViehanmeldung',
    defaultMessage: 'Cattle planning',
  },
  HomeSearchLom: {
    id: 'agrabiz.components.home.manage.lom',
    defaultMessage: 'Search for LOM',
  },
  HomeMyTours: {
    id: 'agrabiz.components.home.tours',
    defaultMessage: 'My Tours',
  },
  RequestTours: {
    id: 'agrabiz.components.home.request',
    defaultMessage: 'Request/Offer tours',
  },
  HomeOfferTours: {
    id: 'agrabiz.components.home.reports.offer',
    defaultMessage: 'Offer Tours',
  },
  HomeSearchTours: {
    id: 'agrabiz.components.home.search',
    defaultMessage: 'Search Tours',
  },
  HomeShareYour: {
    id: 'agrabiz.components.home.share.your',
    defaultMessage: 'Share your',
  },
  HomeWelcomTo: {
    id: 'agrabiz.components.home.welcom.to',
    defaultMessage: 'Welcome To',
  },
  HomeTextSubscriptionPlan: {
    id: 'agrabiz.components.home.subscription.plan',
    defaultMessage:
      'Choose a subscription plan to profit from our full features',
  },
  HomeChoosePlann: {
    id: 'agrabiz.components.home.choose.plan',
    defaultMessage: 'Choose Plan',
  },
  HomeContinueYour: {
    id: 'agrabiz.components.home.continue.your',
    defaultMessage: 'Continue your',
  },
  HomeFree: {
    id: 'agrabiz.components.home.free',
    defaultMessage: 'free',
  },
  HomeTrial: {
    id: 'agrabiz.components.home.trial',
    defaultMessage: 'trial',
  },
  TypeYourEarTag: {
    id: 'agrabiz.components.new.typing',
    defaultMessage: 'Type your animal eartag',
  },

  SelectAnimal: {
    id: 'agrabiz.components.new.select.animal',
    defaultMessage: 'Select animal source',
  },

  NewBorn: {
    id: 'agrabiz.components.new.born',
    defaultMessage: 'Birth',
  },
  NewIncome: {
    id: 'agrabiz.components.new.income',
    defaultMessage: 'Income',
  },
  NewRace: {
    id: 'agrabiz.components.new.race',
    defaultMessage: 'Race',
  },
  NewBreed: {
    id: 'agrabiz.components.new.breed',
    defaultMessage: 'Breed',
  },
  NewEarTagMother: {
    id: 'agrabiz.components.new.ear.tag.mother',
    defaultMessage: 'Ear Tag Mother',
  },
  NewBirthDate: {
    id: 'agrabiz.components.new.birth.date',
    defaultMessage: 'Birth Date',
  },
  NewPreviousOwner: {
    id: 'agrabiz.components.new.previous.owner',
    defaultMessage: 'Previous Owner',
  },
  NewIncomingDate: {
    id: 'agrabiz.components.new.incoming.date',
    defaultMessage: 'Incoming Date',
  },
  NewAddToList: {
    id: 'agrabiz.components.add.to.list',
    defaultMessage: 'Add To List',
  },
  NewSEndToHit: {
    id: 'agrabiz.components.new.send.to.hit',
    defaultMessage: 'Send To Hit',
  },
  NewSuccessSend: {
    id: 'agrabiz.components.new.send.success',
    defaultMessage: 'Your stock data was successfully sent to HIT',
  },
  MyContact: {
    id: 'agrabiz.components.my.contact',
    defaultMessage: 'My contacts',
  },
  //add the invoicing to the list of the side menu
  Invoicing: {
    id: 'agrabiz.components.invoicing',
    defaultMessage: 'Invoicing',
  },
  //add banking tab
  BankDetails: {
    id: 'agrabiz.components.bank.details',
    defaultMessage: 'Bank details & SEPA agreement',
  },
  Accounting: {
    id: 'agrabiz.components.accounting',
    defaultMessage: 'Accounting',
  },
  InvoiceTag: {
    id: 'agrabiz.components.invoicetag',
    defaultMessage: 'Agrabiz invoices throughout the service period',
  },
  Invoice: {
    id: 'agrabiz.components.invoice',
    defaultMessage: 'Invoice',
  },
  CreditCard: {
    id: 'agrabiz.components.creditcard',
    defaultMessage: 'Credit Card',
  },
  Abort: {
    id: 'agrabiz.components.abort',
    defaultMessage: 'Abort',
  },
  Confirm: {
    id: 'agrabiz.components.confirm',
    defaultMessage: 'Confirm',
  },

  ConfirmOther: {
    id: 'agrabiz.components.confirmOther',
    defaultMessage: 'Confirm',
  },
  Download: {
    id: 'agrabiz.components.download',
    defaultMessage: 'Download',
  },
  BankingTag: {
    id: 'agrabiz.components.bankingtag',
    defaultMessage:
      'Thank you for choosing to use our service. In this step, you can deposit or change the payment method you choose to use to authorize us to bill our services on a monthly basis based on your chosen subscription and usage.',
  },
  AddContact: {
    id: 'agrabiz.components.add.contact',
    defaultMessage: 'Add Agrabiz contact',
  },
  AddManualContact: {
    id: 'agrabiz.components.add.manual.contact',
    defaultMessage: 'Add Manual contact',
  },
  AddCsvContact: {
    id: 'agrabiz.components.add.csv.contact',
    defaultMessage: 'Import contact (.csv)',
  },
  SendInvitation: {
    id: 'agrabiz.components.send.invitation',
    defaultMessage: 'Send Invitation',
  },

  FilterByType: {
    id: 'agrabiz.components.filter.types',
    defaultMessage: 'Filter by type',
  },

  MessageArchive: {
    id: 'agrabiz.components.message.archive',
    defaultMessage: 'Message archive',
  },

  AsRead: {
    id: 'agrabiz.components.as.read',
    defaultMessage: 'Mark as read',
  },
  AllAsRead: {
    id: 'agrabiz.components.all.as.read',
    defaultMessage: 'Mark all as read',
  },
  PreferdDT: {
    id: 'agrabiz.components.order.detail.preferedDT',
    defaultMessage: 'Prefered delivery time',
  },
  PreferdDTBuyer: {
    id: 'agrabiz.components.order.detail.preferedDTBuyer',
    defaultMessage: 'Prefered delivery time',
  },
  DeliveryUntil: {
    id: 'agrabiz.components.order.detail.DeliveryUntil',
    defaultMessage: 'Delivery until',
  },
  DeliveryUntilBuyer: {
    id: 'agrabiz.components.order.detail.DeliveryUntilBuyer',
    defaultMessage: 'Delivery until',
  },
  flateRate: {
    id: 'agrabiz.components.order.detail.flateRate',
    defaultMessage: 'Flat rate/piece (€)',
  },
  amount: {
    id: 'agrabiz.components.order.detail.amount',
    defaultMessage: 'Amount',
  },
  PricePerKg: {
    id: 'agrabiz.components.order.detail.pricePerKg',
    defaultMessage: 'Price/Kg',
  },
  CompanyNumber: {
    id: 'agrabiz.components.order.detail.CompanyNumber',
    defaultMessage: 'Company No',
  },
  ConfirmButton: {
    id: 'agrabiz.components.order.detail.confirm',
    defaultMessage: 'Confirm',
  },
  RequestOrder: {
    id: 'agrabiz.components.order.status.request',
    defaultMessage: 'Request',
  },
  RequestedOrder: {
    id: 'agrabiz.components.order.status.requested',
    defaultMessage: 'Requested',
  },
  ToBeReConfirmedOrder: {
    id: 'agrabiz.components.order.status.tobereconfirmed',
    defaultMessage: 'To be reconfirmed',
  },
  TourPlanningOrder: {
    id: 'agrabiz.components.order.status.tourplanning',
    defaultMessage: 'Tour planning',
  },
  OfferOrder: {
    id: 'agrabiz.components.order.status.offer',
    defaultMessage: 'Offer',
  },
  OfferedOrder: {
    id: 'agrabiz.components.order.status.offered',
    defaultMessage: 'Offered',
  },
  ToBeAcceptedOrder: {
    id: 'agrabiz.components.order.status.tobeaccepted',
    defaultMessage: 'To be accepted',
  },
  ToBeLoadedOrder: {
    id: 'agrabiz.components.order.status.tobeloaded',
    defaultMessage: 'To be loaded',
  },
  LoadedOrder: {
    id: 'agrabiz.components.order.status.loaded',
    defaultMessage: 'Loaded',
  },
  NegotiationOrder: {
    id: 'agrabiz.components.order.status.negotiation',
    defaultMessage: 'Negotiation',
  },
  SoldOrder: {
    id: 'agrabiz.components.order.status.sold',
    defaultMessage: 'Sold',
  },
  RejectedOrder: {
    id: 'agrabiz.components.order.status.rejected',
    defaultMessage: 'Rejected',
  },
  DeliveredOrder: {
    id: 'agrabiz.components.order.status.delivered',
    defaultMessage: 'Delivered',
  },
  OnthewayOrder: {
    id: 'agrabiz.components.order.status.ontheway',
    defaultMessage: 'On the way',
  },
  CanceledOrder: {
    id: 'agrabiz.components.order.status.canceled',
    defaultMessage: 'Canceled',
  },
  BoughtOrder: {
    id: 'agrabiz.components.order.status.bought',
    defaultMessage: 'Bought',
  },
  UnloadedOrder: {
    id: 'agrabiz.components.order.status.unloaded',
    defaultMessage: 'Unloaded',
  },
  CancelOrder: {
    id: 'agrabiz.components.orders.cancel',
    defaultMessage:
      'Do you really want to cancel this item? This is irreversible and all data will be deleted.',
  },
  Planned: {
    id: 'agrabiz.components.orders.planned',
    defaultMessage: 'Planned',
  },
  AddVetOfficeForAgrPart1: {
    id: 'agrabiz.components.contact.agr.part1.favoris.vet',
    defaultMessage: 'Do you want to register ',
  },
  AddVetOfficeForAgrPart2: {
    id: 'agrabiz.components.contact.agr.part2.favoris.vet',
    defaultMessage: ' as your responsible veterinarian?',
  },
  AddResponsibleVetForAbtPart1: {
    id: 'agrabiz.components.contact.abt.part1.favoris.vet',
    defaultMessage: 'Do you want to register ',
  },
  AddResponsibleVetForAbtPart2: {
    id: 'agrabiz.components.contact.abt.part2.favoris.vet',
    defaultMessage: ' as your responsible veterinarian?',
  },
  Yes: {
    id: 'agrabiz.yes',
    defaultMessage: 'Yes',
  },
  No: {
    id: 'agrabiz.no',
    defaultMessage: 'No',
  },
  HomeBidders: {
    id: 'agrabiz.components.admin.orders.bidders',
    defaultMessage: 'My Bidders',
  },
  Certificate: {
    id: 'agrabiz.components.contact.quality',
    defaultMessage: 'Certificate',
  },
  Agriculturist: {
    id: 'agrabiz.components.contact.agr',
    defaultMessage: 'Agriculturist',
  },
  Trader: {
    id: 'agrabiz.components.contact.trd',
    defaultMessage: 'Trader',
  },
  Abbaitor: {
    id: 'agrabiz.components.contact.abt',
    defaultMessage: 'Abbatoir',
  },
  Transporter: {
    id: 'agrabiz.components.contact.trs',
    defaultMessage: 'Transporter',
  },
  VeterinarianPrivate: {
    id: 'agrabiz.components.contact.vetP',
    defaultMessage: 'Private Veterinarian',
  },
  VeterinarianOffice: {
    id: 'agrabiz.components.contact.vetOffice',
    defaultMessage: 'Veterinarian Office',
  },
  ContactInformation: {
    id: 'agrabiz.components.contact.ContactInformation',
    defaultMessage: 'Contact Information',
  },
  ContactProfile: {
    id: 'agrabiz.components.contact.profile',
    defaultMessage: 'Profile',
  },
  TourInformation: {
    id: 'agrabiz.components.order.tourInformation',
    defaultMessage: 'Tour information',
  },
  TruckManagement: {
    id: 'agrabiz.components.order.truckmanagement',
    defaultMessage: 'Truc kManagement',
  },
  NewTransport: {
    id: 'agrabiz.components.order.truckmanagement.newTransport',
    defaultMessage: 'New Transport',
  },
  LicenceNumber: {
    id: 'agrabiz.components.order.truckmanagement.licenceNumber',
    defaultMessage: 'License number',
  },
  DeliveryDate: {
    id: 'agrabiz.components.order.truckmanagement.deliveryDate',
    defaultMessage: 'Delivery date',
  },
  capacity: {
    id: 'agrabiz.components.order.truckmanagement.capacity',
    defaultMessage: 'Capacity',
  },
  DriverName: {
    id: 'agrabiz.components.order.truckmanagement.driverName',
    defaultMessage: 'Driver name',
  },
  PickUPDate: {
    id: 'agrabiz.components.order.truckmanagement.pickUPDate',
    defaultMessage: 'Pick-up date',
  },
  Price: {
    id: 'agrabiz.components.order.truckmanagement.Price',
    defaultMessage: 'Price',
  },
  Number: {
    id: 'agrabiz.components.invoice.number',
    defaultMessage: 'Number',
  },
  Title: {
    id: 'agrabiz.components.invoice.title',
    defaultMessage: 'Title',
  },
  Year: {
    id: 'agrabiz.components.invoice.year',
    defaultMessage: 'Year',
  },
  Month: {
    id: 'agrabiz.components.invoice.month',
    defaultMessage: 'Month',
  },
  AmountNet: {
    id: 'agrabiz.components.invoice.amountnet',
    defaultMessage: ' Amount Net',
  },
  AmountGross: {
    id: 'agrabiz.components.invoice.amountgross',
    defaultMessage: ' Amount Gross',
  },
  PayedAt: {
    id: 'agrabiz.components.invoice.payedAt',
    defaultMessage: ' Paid At',
  },
  Paid: {
    id: 'agrabiz.components.invoice.paid',
    defaultMessage: ' Paid',
  },
  //creditCard paiement method messages
  CardNumber: {
    id: 'agrabiz.components.invoice.cardnumber',
    defaultMessage: ' Card Number',
  },
  DateOfExpiry: {
    id: 'agrabiz.components.invoice.dataofexpiry',
    defaultMessage: ' Date Of Expiry',
  },
  CWNumber: {
    id: 'agrabiz.components.invoice.cwnumber',
    defaultMessage: ' CW Number',
  },
  FirstNameAndLastName: {
    id: 'agrabiz.components.invoice.firstnameandlastname',
    defaultMessage: ' FirstNameAndLastName',
  },
  StreetAndHouseNumber: {
    id: 'agrabiz.components.invoice.streetandhousenumber',
    defaultMessage: ' Street And House Number',
  },
  Plz: {
    id: 'agrabiz.components.invoice.plz',
    defaultMessage: ' PLZ',
  },
  Ort: {
    id: 'agrabiz.components.invoice.ort',
    defaultMessage: ' ORT',
  },
  CreditInstitution: {
    id: 'agrabiz.components.invoice.creditinstitution',
    defaultMessage: ' Credit Institution',
  },
  ChooseEartag: {
    id: 'agrabiz.components.order.truckmanagement.ChooseEartag',
    defaultMessage: 'Choose Eartag',
  },
  Cancel: {
    id: 'agrabiz.components.cancel',
    defaultMessage: 'Cancel',
  },
  cancelbtn: {
    id: 'agrabiz.components.cancelbtn',
    defaultMessage: 'Cancel',
  },
  Tours: {
    id: 'agrabiz.components.toBeLoaded.tours',
    defaultMessage: 'Tours',
  },
  VehicleNumber: {
    id: 'agrabiz.components.toBeLoaded.vehicleNumber',
    defaultMessage: 'Vehicle number',
  },
  LoadingTime: {
    id: 'agrabiz.components.toBeLoaded.loadingTime',
    defaultMessage: 'Loading time',
  },
  TransporterName: {
    id: 'agrabiz.components.toBeLoaded.transporterName',
    defaultMessage: 'Transporter Name',
  },
  TypeOfSale: {
    id: 'agrabiz.components.toBeLoaded.typeOfSale',
    defaultMessage: 'Type Of sale',
  },
  TransportCompany: {
    id: 'agrabiz.components.transportCompany',
    defaultMessage: 'Transport Company',
  },
  BilledMounthly: {
    id: 'agrabiz.components.billedM',
    defaultMessage: 'Billed Monthly',
  },
  BilledYearly: {
    id: 'agrabiz.components.billedY',
    defaultMessage: 'Billed Yearly',
  },
  FreePlan: {
    id: 'agrabiz.components.freeplan',
    defaultMessage:
      'The right offer for your needs: Select your subscription here.',
  },
  AutoUse: {
    id: 'agrabiz.components.autouse',
    defaultMessage:
      'Automatically use our entire range of functions for 15 days free of charge and without obligation.After the trial period, you can continue to use Agrabiz free of charge with a limited "Basic" subscription or switch to our attractive "Essential" and "Professional" subscriptions.',
  },
  Ft: {
    id: 'agrabiz.components.ft',
    defaultMessage:
      'Agrabiz "Professional" 15 days free and no obligation testing',
  },
  DescFT: {
    id: 'agrabiz.components.discft',
    defaultMessage:
      ' The argument in favor of using filler text goes something like this if you use real content.',
  },
  NoCCNeeded: {
    id: 'agrabiz.components.noccneeded',
    defaultMessage:
      ' Agrabiz "Basic" for free without payment obligation. All subscriptions cancellable at any time. ',
  },
  DescNoCCNeeded: {
    id: 'agrabiz.components.descnoccneeded',
    defaultMessage:
      ' Our "Basic" membership is 100% free and does not obligate you to a paid subscription. If you want to take advantage of all our benefits and features, and if you choose to subscribe to "Essential" or "Professional", you can easily choose between monthly or yearly billing and a direct debit or credit card payment method.You can cancel our subscriptions at any time - in this case, the subscription will only run until the end of the agreed term (End of month or after 12 months according to the chosen period of use).',
  },
  Clock: {
    id: 'agrabiz.components.clock',
    defaultMessage: 'Only access to past data of the last 30 days possible',
  },
  Mobile: {
    id: 'agrabiz.components.lower',
    defaultMessage: ' Mobile App (iOS & Android) ',
  },
  ER: {
    id: 'agrabiz.components.er',
    defaultMessage: ' Inventory Management & Contacts ',
  },
  IndivPlan: {
    id: 'agrabiz.components.indivplan',
    defaultMessage: ' Automatic HIT messages ',
  },
  NoPreg: {
    id: 'agrabiz.components.nopreg',
    defaultMessage: ' Purchases & Sales ',
  },
  ShareForms: {
    id: 'agrabiz.components.shareforms',
    defaultMessage: ' Delivery documentation & slaughter dates ',
  },
  AddImg: {
    id: 'agrabiz.components.addimg',
    defaultMessage: ' Evaluations & Audit Search',
  },
  ACA: {
    id: 'agrabiz.components.aca',
    defaultMessage: ' Drug administration and notification ',
  },
  DocVisit: {
    id: 'agrabiz.components.docvisit',
    defaultMessage: ' Web version via browser ',
  },
  AnyQuestion: {
    id: 'agrabiz.components.anyquestion',
    defaultMessage: 'Any more questions?',
  },
  OwnCarpool: {
    id: 'agrabiz.components.ownCarpool',
    defaultMessage: 'Own carpool',
  },
  TruckAdd: {
    id: 'agrabiz.components.addTruck',
    defaultMessage: 'Add truck',
  },
  TruckAddCompany: {
    id: 'agrabiz.components.addTruckCompany',
    defaultMessage: 'Add truck Company',
  },
  NotAssigned: {
    id: 'agrabiz.components.notAssigned',
    defaultMessage: 'Not Assigned',
  },
  TruckSave: {
    id: 'agrabiz.components.saveTruck',
    defaultMessage: 'Save changes',
  },
  TruckDelete: {
    id: 'agrabiz.components.deleteTruck',
    defaultMessage: 'Delete truck',
  },
  Capacity: {
    id: 'agrabiz.components.capacity',
    defaultMessage: 'Capacity',
  },
  Active: {
    id: 'agrabiz.components.active',
    defaultMessage: 'Active',
  },
  CardBasic: {
    id: 'agrabiz.components.cardbasic',
    defaultMessage:
      'The free solution for pure digital inventory and contact management.',
  },
  CardEssentiel: {
    id: 'agrabiz.components.cardessentiel',
    defaultMessage:
      'The solution for smaller businesses with lower documentation requirements.',
  },
  CardBusiness: {
    id: 'agrabiz.components.cardbusiness',
    defaultMessage:
      'The professional solution for your operation with unlimited access_x0003_to all functions.',
  },
  CancelPlan: {
    id: 'agrabiz.components.cancelplan',
    defaultMessage: 'Cancel Plan',
  },
  GetStarted: {
    id: 'agrabiz.components.getstarted',
    defaultMessage: 'Start Now',
  },
  Free: {
    id: 'agrabiz.components.free',
    defaultMessage: 'Free',
  },
  PerMonth: {
    id: 'agrabiz.components.permonth',
    defaultMessage: 'Per Month',
  },
  CheckRemoveEartagsPart1: {
    id: 'agrabiz.components.tobeLoaded.checkRemoveEartags1',
    defaultMessage: 'Do you really want to remove the ear tag ',
  },
  CheckRemoveEartagsPart2: {
    id: 'agrabiz.components.tobeLoaded.checkRemoveEartags2',
    defaultMessage: ' from the list?',
  },
  CompleteLoadingProcess: {
    id: 'agrabiz.components.tobeLoaded.completeLoadingProcess',
    defaultMessage: 'Complete loading',
  },
  CheckSignature: {
    id: 'agrabiz.components.tobeLoaded.signature',
    defaultMessage: 'Do you want to add your signature to documents?',
  },
  Next: {
    id: 'agrabiz.components.button.next',
    defaultMessage: 'Next',
  },
  TourInfo: {
    id: 'agrabiz.components.tour.info',
    defaultMessage: 'Tour Information',
  },
  CheckCapacity: {
    id: 'agrabiz.components.tour.capacity.check',
    defaultMessage: 'is filled',
  },
  Ok: {
    id: 'agrabiz.components.ok',
    defaultMessage: 'Ok',
  },
  OkUnder: {
    id: 'agrabiz.components.okUnder',
    defaultMessage: 'Ok, i understand',
  },
  Documents: {
    id: 'agrabiz.components.tour.info.documents',
    defaultMessage: 'Documents',
  },
  Passport: {
    id: 'agrabiz.components.tour.info.passport',
    defaultMessage: 'Cattle Passports',
  },
  DepartureTime: {
    id: 'agrabiz.components.tour.info.departureTime',
    defaultMessage: 'Departure time',
  },
  // eslint-disable-next-line no-dupe-keys
  LoadingTime: {
    id: 'agrabiz.components.tour.info.loadingTime',
    defaultMessage: 'Loading time',
  },
  Name: {
    id: 'agrabiz.components.name',
    defaultMessage: 'Name',
  },
  AddUser: {
    id: 'agrabiz.components.adduser',
    defaultMessage: 'Add User',
  },
  EditProgramQuality: {
    id: 'agrabiz.components.editProgramQuality',
    defaultMessage: 'Please fill in the following data',
  },
  AddTransports: {
    id: 'agrabiz.components.AddTransports',
    defaultMessage: 'Add Transporter',
  },
  AddTransportsNumber: {
    id: 'agrabiz.components.AddTransportsNumber',
    defaultMessage: 'Vehicle number',
  },
  AddTransportsLevel: {
    id: 'agrabiz.components.AddTransportsLevel',
    defaultMessage: 'Number of levels',
  },
  AddTransportsCapacity: {
    id: 'agrabiz.components.AddTransportsCapacity',
    defaultMessage: 'Cubic meters',
  },
  AddTransportsType: {
    id: 'agrabiz.components.AddTransportsType',
    defaultMessage: 'Type',
  },
  Role: {
    id: 'agrabiz.components.role',
    defaultMessage: 'Role',
  },
  Save: {
    id: 'agrabiz.components.save',
    defaultMessage: 'Save',
  },
  Unloading: {
    id: 'agrabiz.components.departureTime',
    defaultMessage: 'Unloading time',
  },
  ConfirmTimes: {
    id: 'agrabiz.components.ConfirmTimes',
    defaultMessage: 'Confirm times',
  },
  DeliveryTime: {
    id: 'agrabiz.components.deliveryTime',
    defaultMessage: 'Delivery time',
  },
  Resale: {
    id: 'agrabiz.components.resale',
    defaultMessage: 'Resale',
  },
  AddToStock: {
    id: 'agrabiz.components.addtostock',
    defaultMessage: 'Add To Stock',
  },
  ResaleToNonAgrabizUser: {
    id: 'agrabiz.components.resaleToNonAgrabizUser',
    defaultMessage: 'Resale to Non-Agrabiz User',
  },
  AddExternalPurchase: {
    id: 'agrabiz.components.addExternalPurchase',
    defaultMessage: 'Add External Purchase',
  },
  AddExternalSale: {
    id: 'agrabiz.components.addExternalSale',
    defaultMessage: 'Add External Sale',
  },
  BuyRequest: {
    id: 'agrabiz.components.buyrequest',
    defaultMessage: 'Buy Request',
  },
  SellOffer: {
    id: 'agrabiz.components.selloffer',
    defaultMessage: 'Sell Offer',
  },
  GeneratePdf: {
    id: 'agrabiz.components.geneatepdf',
    defaultMessage: 'Generate PDF of the planing',
  },
  GeneratePdfPopup: {
    id: 'agrabiz.components.geneatepdfpopup',
    defaultMessage:
      'Please enter the starting time of the slaughter for the selected day',
  },
  Hi: {
    id: 'agrabiz.components.hi',
    defaultMessage: 'Hi ,',
  },
  AddCompany: {
    id: 'agrabiz.components.addcompany',
    defaultMessage: 'Add Company',
  },
  FilterSearchResults: {
    id: 'agrabiz.components.filtersearchresults',
    defaultMessage: 'Filter Search Results',
  },
  ClearAll: {
    id: 'agrabiz.components.clearall',
    defaultMessage: 'Clear All',
  },
  BoxNumber: {
    id: 'agrabiz.components.boxnumber',
    defaultMessage: 'Box Number',
  },
  Search: {
    id: 'agrabiz.components.search',
    defaultMessage: 'Box Number',
  },
  EarTag: {
    id: 'agrabiz.components.eartag',
    defaultMessage: 'Eartag',
  },
  Age: {
    id: 'agrabiz.components.age',
    defaultMessage: 'Age',
  },
  Box: {
    id: 'agrabiz.components.box',
    defaultMessage: 'Box',
  },
  DeselectAll: {
    id: 'agrabiz.components.deselectall',
    defaultMessage: 'Deselect All',
  },
  SelectedAnimals: {
    id: 'agrabiz.components.selectedanimals',
    defaultMessage: 'Selected Animals',
  },
  CreatePDF: {
    id: 'agrabiz.components.createpdf',
    defaultMessage: 'Create PDF',
  },
  MoveToNextBox: {
    id: 'agrabiz.components.moveetonextbox',
    defaultMessage: 'Move To Next Box',
  },

  RegisterLeavingAnimal: {
    id: 'agrabiz.components.registerleavinganimal',
    defaultMessage: 'Register Leaving Animal',
  },

  SyncStockWithHit: {
    id: 'agrabiz.components.syncStockWithHit',
    defaultMessage: 'Sync. Stock with Hit',
  },
  ChangeBox: {
    id: 'agrabiz.components.changeBox',
    defaultMessage: 'Change Box',
  },

  RegisterDeath: {
    id: 'agrabiz.components.registerDeath',
    defaultMessage: 'Register Death',
  },
  Options: {
    id: 'agrabiz.components.options',
    defaultMessage: 'Options',
  },
  Open: {
    id: 'agrabiz.components.open',
    defaultMessage: 'Open',
  },
  Deliveries: {
    id: 'agrabiz.components.deleveries',
    defaultMessage: 'Deliveries',
  },
  Completed: {
    id: 'agrabiz.components.completed',
    defaultMessage: 'Completed',
  },
  TransportedBy: {
    id: 'agrabiz.components.transportedby',
    defaultMessage: 'Transported By',
  },
  Buyer: {
    id: 'agrabiz.components.buyer',
    defaultMessage: 'Buyer',
  },
  Seller: {
    id: 'agrabiz.components.seller',
    defaultMessage: 'Seller',
  },
  OrderDetails: {
    id: 'agrabiz.components.orderdetail',
    defaultMessage: 'Order Details',
  },
  Contact: {
    id: 'agrabiz.components.contact',
    defaultMessage: 'Contact',
  },
  Chat: {
    id: 'agrabiz.components.chat',
    defaultMessage: 'Chat',
  },
  PreferedDeliveryDate: {
    id: 'agrabiz.components.prefereddeliverydate',
    defaultMessage: 'Prefered Delivery Date',
  },
  Recipient: {
    id: 'agrabiz.components.receipient',
    defaultMessage: 'Recipient',
  },
  externalAbattoir: {
    id: 'agrabiz.components.external.abattoir',
    defaultMessage: 'Lohnschlachtung',
  },
  ResaleOverview: {
    id: 'agrabiz.components.resaleoverview',
    defaultMessage: 'Resale Overview',
  },
  Disc1: {
    id: 'agrabiz.components.disc1',
    defaultMessage: 'Thank you for your interest in our subscription',
  },
  Disc2: {
    id: 'agrabiz.components.disc2',
    defaultMessage: 'You can look forward to the',
  },

  ConfirmMoveToNextBoxModal: {
    id: 'agrabiz.components.confirmMoveToNextBoxModal',
    defaultMessage:
      'By confirming this dialogue all animals in your stock will be moved to the next higher box which cannot be made undone. Do you want to proceed?',
  },
  CancelMove: {
    id: 'agrabiz.components.cancelMove',
    defaultMessage: 'Cancel',
  },
  ConfirmMove: {
    id: 'agrabiz.components.confirmMove',
    defaultMessage: 'Confirm',
  },
  Benefits: {
    id: 'agrabiz.components.benefits',
    defaultMessage: 'benefits',
  },
  Disc2Part2: {
    id: 'agrabiz.components.disc2part2',
    defaultMessage: 'of your new membership',
  },
  Disc3: {
    id: 'agrabiz.components.disc3',
    defaultMessage:
      'In order to authorize the payment process via credit card, you will now be redirected to our payment service provider Mollie in a final step in order to securely deposit your credit card details. Your payment details are processed through a platform that meets the highest standards of security, confidentiality and stability. After your authorization you will be directed back to our site.',
  },
  Disc4: {
    id: 'agrabiz.components.disc4',
    defaultMessage:
      'Do you have any questions about our subscriptions?Just call us at',
  },
  EmailDisc: {
    id: 'agrabiz.components.emaildisc',
    defaultMessage: 'or send an e-mail to',
  },
  Slaughtered: {
    id: 'agrabiz.components.slaughtered',
    defaultMessage: 'Slaughtered',
  },
  Slaughterreport: {
    id: 'agrabiz.components.slaughterreport',
    defaultMessage: 'Slaughter report',
  },
  Nr: {
    id: 'agrabiz.components.nr',
    defaultMessage: 'Nr.',
  },
  Weight: {
    id: 'agrabiz.components.weight',
    defaultMessage: 'Weight',
  },
  Class: {
    id: 'agrabiz.components.class',
    defaultMessage: 'Class.',
  },
  SlaughterText: {
    id: 'agrabiz.components.laughterText',
    defaultMessage: 'Total: ',
  },

  Pieces: {
    id: 'agrabiz.components.pieces',
    defaultMessage: 'Pieces: ',
  },

  StockPieces: {
    id: 'agrabiz.components.pieces',
    defaultMessage: 'Pieces: ',
  },

  Week: {
    id: 'agrabiz.components.week',
    defaultMessage: 'Week: ',
  },

  TourAssigned: {
    id: 'agrabiz.components.tourAssigned',
    defaultMessage: 'TourAssigned',
  },
  Terms: {
    id: 'agrabiz.components.terms',
    defaultMessage: 'terms & conditions',
  },
  TermsPart1: {
    id: 'agrabiz.components.termspart1',
    defaultMessage: 'I hereby accept the ',
  },
  TermsPart2: {
    id: 'agrabiz.components.termspart2',
    defaultMessage: ' of the Agrabiz service',
  },
  TermsAndConditions: {
    id: 'agrabiz.components.termsandconditions',
    defaultMessage: 'Terms And Conditions',
  },
  Delete: {
    id: 'agrabiz.components.delete',
    defaultMessage: ' Delete',
  },
  ManageTours: {
    id: 'agrabiz.components.manageTours',
    defaultMessage: 'Manage Tours',
  },
  SuccessPart1: {
    id: 'agrabiz.components.successpart1',
    defaultMessage: 'Thank you for choosing our',
  },
  SuccessPart2: {
    id: 'agrabiz.components.successpart2',
    defaultMessage: 'Subscription',
  },
  SuccessUnder: {
    id: 'agrabiz.components.successunder',
    defaultMessage: 'We are very pleased that you appreciate our service',
  },
  SuccessFooterPart1: {
    id: 'agrabiz.components.successfooterr1',
    defaultMessage:
      'Under "Service and Legal" you will find in our apps again all information about our service including a user manual.If you have any further questions on how to use your new features, just give us a call at',
  },
  SuccessFooterPart2: {
    id: 'agrabiz.components.successfooterr2',
    defaultMessage: 'or send us an email to',
  },
  MolliePayement1: {
    id: 'agrabiz.components.mollie1',
    defaultMessage:
      'Um den Zahlungsvorgang über Kreditkarte zu autorisieren, werden Sie nun in einem letzten Schritt zu unserem Zahlungsdienstleister ',
  },
  MolliePayement2: {
    id: 'agrabiz.components.mollie2',
    defaultMessage:
      ' weitergeleitet, um dort Ihre Kreditkartendetails sicher zu hinterlegen und unsere Einzugsermächtigung der monatlichen Beiträge mit einer kostenfreien Transaktion von 0,00 € einmalig zu bestätigen.',
  },
  MolliePayement3: {
    id: 'agrabiz.components.mollie3',
    defaultMessage:
      ' Ihre Zahlungsdetails werden über eine Plattform verarbeitet, die den höchsten Standards an Sicherheit, Vertraulichkeit und Stabilität genügt. Nach Ihrer Autorisierung werden Sie wieder zurück auf unsere Seite geleitet.',
  },
  MollieSEPAPayement1: {
    id: 'agrabiz.components.mollie.sepa1',
    defaultMessage:
      'Um den Zahlungsvorgang über SEPA Lastschrift zu autorisieren, werden Sie nun in einem letzten Schritt zu unserem Zahlungsdienstleister ',
  },
  MollieSEPAPayement2: {
    id: 'agrabiz.components.mollie.sepa2',
    defaultMessage:
      'weitergeleitet, um mittels Sofortüberweisung Ihre Bankkonto-Informationen sicher zu hinterlegen und unsere Einzugsermächtigung der monatlichen Beiträge per Lastschriftmandat mit einer einmaligen Transaktion von 0,10 € zu bestätigen.',
  },
  MollieSEPAPayement3: {
    id: 'agrabiz.components.mollie.sepa3',
    defaultMessage:
      'Ihre Zahlungsdetails werden über eine Plattform verarbeitet, die den höchsten Standards an Sicherheit, Vertraulichkeit und Stabilität genügt. Nach Ihrer Autorisierung werden Sie wieder zurück auf unsere Seite geleitet.',
  },
  Qp: {
    id: 'agrabiz.components.qp',
    defaultMessage: 'Quality progr.',
  },
  Notes: {
    id: 'agrabiz.components.notes',
    defaultMessage: 'Notes',
  },
  BusinessType: {
    id: 'agrabiz.components.businessType',
    defaultMessage: 'Choose business type',
  },
  FederalState: {
    id: 'agrabiz.components.federalState',
    defaultMessage: 'Choose state',
  },
  DeliveryNote: {
    id: 'agrabiz.components.deliverynote',
    defaultMessage: 'Delivery note',
  },
  StandardDeclaration: {
    id: 'agrabiz.components.standarddeclaration',
    defaultMessage: 'Standard declaration',
  },
  LieferschernAnzeigen: {
    id: 'agrabiz.components.LieferschernAnzeigen',
    defaultMessage: 'Lieferschern Anzeigen',
  },
  CattlePassports: {
    id: 'agrabiz.components.CattlePassports',
    defaultMessage: 'Cattle Passports',
  },
  TierlebenslaufAnzeigen: {
    id: 'agrabiz.components.TierlebenslaufAnzeigen',
    defaultMessage: 'Tierlebenslauf Anzeigen',
  },

  LoadingProcess: {
    id: 'agrabiz.components.loadingProcess',
    defaultMessage: 'Loading Process',
  },
  add_favorite_contact: {
    id: 'agrabiz.components.add_favorite_contact',
    defaultMessage: 'You can´t find the contact you are looking for?',
  },
  send_invitation: {
    id: 'agrabiz.components.send_invitation',
    defaultMessage: 'Send him an invitation.',
  },
  add_no_agrabiz_contact: {
    id: 'agrabiz.components.add_no_agrabiz_contact',
    defaultMessage: 'add new contact',
  },
  add_all: {
    id: 'agrabiz.components.add_All',
    defaultMessage: 'All',
  },
  InviteContact: {
    id: 'agrabiz.components.InviteContact',
    defaultMessage: 'Invite contacts',
  },
  invitation_description_trader: {
    id: 'agrabiz.components.invitation_description_trader',
    defaultMessage:
      'You can send an invitation by typing the email address of your contact in the following field. After registration, this user will be added to your contacts automatically.',
  },
  Add_btn: {
    id: 'agrabiz.components.Add_btn',
    defaultMessage: 'Add',
  },
  Add_None_Agrabiz: {
    id: 'agrabiz.components.Add_None_Agrabiz',
    defaultMessage: 'Non agrabiz contacts',
  },
  try_again: {
    id: 'agrabiz.components.try_again',
    defaultMessage: 'try again or refresh',
  },
  Status: {
    id: 'agrabiz.components.Status',
    defaultMessage: 'Status',
  },
  Number_id: {
    id: 'agrabiz.components.Number_id',
    defaultMessage: 'Number of the credit card',
  },
  pdf_download: {
    id: 'agrabiz.components.pfe_download',
    defaultMessage: 'Download pdf',
  },
  Import: {
    id: 'agrabiz.components.Import',
    defaultMessage: 'Import',
  },
  dropFile: {
    id: 'agrabiz.components.dropFile',
    defaultMessage: 'Drop files here...',
  },
  selectFile: {
    id: 'agrabiz.components.selectFile',
    defaultMessage: 'Select files',
  },
  ActiveReports: {
    id: 'agrabiz.components.ActiveReports',
    defaultMessage: 'Active Reports',
  },
  UploadHistory: {
    id: 'agrabiz.components.UploadHistory',
    defaultMessage: 'Upload History',
  },
  StockDetails: {
    id: 'agrabiz.components.StockDetails',
    defaultMessage: 'Stock Details',
  },
  eartag_search: {
    id: 'agrabiz.components.eartag_search',
    defaultMessage: 'Eartag Search',
  },
  AddPassport: {
    id: 'agrabiz.components.AddPassport',
    defaultMessage: 'Add Passport',
  },
  ViewPassport: {
    id: 'agrabiz.components.ViewPassport',
    defaultMessage: 'View Passport',
  },
  Deathregistration: {
    id: 'agrabiz.components.Deathregistration',
    defaultMessage: 'Death Registration',
  },
  Deathtxt: {
    id: 'agrabiz.components.Deathtxt',
    defaultMessage:
      'Do you want to register the death of the ear tag (s) and selected the date ?',
  },
  Deathdate: {
    id: 'agrabiz.components.Deathdate',
    defaultMessage: 'Date of death',
  },
  Registerdeath: {
    id: 'agrabiz.components.Registerdeath',
    defaultMessage: 'Register death',
  },
  Leaveregistration: {
    id: 'agrabiz.components.Leaveregistration',
    defaultMessage: 'Leave registration',
  },
  Leavetxt: {
    id: 'agrabiz.components.Leavetxt',
    defaultMessage:
      'Do you want to register the leave of the ear tag (s) and selected the date ?',
  },
  Leavedate: {
    id: 'agrabiz.components.Leavedate',
    defaultMessage: 'Date of leave',
  },
  RegisterLeave: {
    id: 'agrabiz.components.RegisterLeave',
    defaultMessage: 'Register leave',
  },
  Drug: {
    id: 'agrabiz.components.Drug',
    defaultMessage: 'Drug',
  },
  menge: {
    id: 'agrabiz.components.menge',
    defaultMessage: 'Quantity',
  },
  datum: {
    id: 'agrabiz.components.datum',
    defaultMessage: 'Date',
  },
  information: {
    id: 'agrabiz.components.information',
    defaultMessage: 'information',
  },
  complete_departuretime: {
    id: 'agrabiz.components.complete_departuretime',
    defaultMessage: 'Confirm departure time',
  },
  complete_delverytime: {
    id: 'agrabiz.components.complete_delverytime',
    defaultMessage: 'Confirm departure time',
  },
  complete_unloadingtime: {
    id: 'agrabiz.components.complete_unloadingtime',
    defaultMessage: 'Confirm unloading time',
  },
  complete_tours: {
    id: 'agrabiz.components.complete_tours',
    defaultMessage: 'Complete tour planning',
  },
  slaughter_report: {
    id: 'agrabiz.components.slaughter_report',
    defaultMessage: 'Slaughter report',
  },

  buyings_overview: {
    id: 'agrabiz.components.buyings_overview',
    defaultMessage: 'Buyings overview',
  },

  slaughter_report_big: {
    id: 'agrabiz.components.slaughter_report_big',
    defaultMessage: 'Slaughter report',
  },
  my_stock_sale: {
    id: 'agrabiz.components.my_stock_sale',
    defaultMessage: 'My stock sale',
  },
  DenialsBids: {
    id: 'agrabiz.components.DenialsBids',
    defaultMessage: 'Denials',
  },
  invite_confirmation_message: {
    id: 'agrabiz.components.invite_confirmation_message',
    defaultMessage:
      'The user you are trying to invite is already registered in Agrabiz. Press continue to show his company details and add him as a contact.',
  },
  added_contact_only: {
    id: 'agrabiz.components.added_contact_only',
    defaultMessage: 'Non-agrabiz users',
  },
  Hit_pin: {
    id: 'agrabiz.components.Hit_pin',
    defaultMessage: 'HIT PIN',
  },
  Agrabiz_Password: {
    id: 'agrabiz.components.Agrabiz_Password',
    defaultMessage: 'Agrabiz Password',
  },
  Current_HIT_PIN: {
    id: 'agrabiz.components.Current_HIT_PIN',
    defaultMessage: 'Current HIT PIN',
  },
  New_HIT_PIN: {
    id: 'agrabiz.components.New_HIT_PIN',
    defaultMessage: 'New HIT PIN',
  },
  Current_password: {
    id: 'agrabiz.components.Current_password',
    defaultMessage: 'Current password',
  },
  New_password: {
    id: 'agrabiz.components.New_password',
    defaultMessage: 'New password',
  },
  Confirm_password: {
    id: 'agrabiz.components.Confirm_password',
    defaultMessage: 'Confirm password',
  },
  Identicale_password: {
    id: 'agrabiz.components.Identicale_password',
    defaultMessage: 'Password are not identical',
  },
  Assigned: {
    id: 'agrabiz.components.Assigned',
    defaultMessage: 'Assigned',
  },
  OtherAvailable: {
    id: 'agrabiz.components.OtherAvailable',
    defaultMessage: 'Other available',
  },
  SearchEartag: {
    id: 'agrabiz.components.SearchEartag',
    defaultMessage: 'Search Eartag',
  },
  register_new_company: {
    id: 'agrabiz.components.register_new_company',
    defaultMessage: 'Register New Company ',
  },
  register_password: {
    id: 'agrabiz.components.register_password',
    defaultMessage: 'HI-Tier PIN ',
  },
  register_company_Number: {
    id: 'agrabiz.components.register_company_Number',
    defaultMessage: 'Company Number *',
  },
  register_company_producer: {
    id: 'agrabiz.components.register_company_producer',
    defaultMessage: 'I am a Producer',
  },
  accept_policies_and_usage: {
    id: 'agrabiz.components.accept_policies_and_usage',
    defaultMessage: 'I accept the',
  },
  usage_policies: {
    id: 'agrabiz.components.usage_policies',
    defaultMessage: 'Usage policies',
  },
  conditions_of_data_usage: {
    id: 'agrabiz.components.conditions_of_data_usage',
    defaultMessage: 'Conditions of data usage',
  },
  Zahlungsmethode: {
    id: 'agrabiz.components.Zahlungsmethode',
    defaultMessage: 'Change payment method',
  },
  UpdateSEPA: {
    id: 'agrabiz.components.UpdateSEPA',
    defaultMessage: 'Update SEPA mandate',
  },
  UpdateSEPACon: {
    id: 'agrabiz.components.UpdateSEPACon',
    defaultMessage:
      'Do you have any questions about our subscriptions? Just call',
  },
  UpdateSEPAMail: {
    id: 'agrabiz.components.UpdateSEPAMail',
    defaultMessage: 'or send us an e-mail to',
  },
  Meiner: {
    id: 'agrabiz.components.meiner',
    defaultMessage: 'By sumbitting',
  },
  Hinweis: {
    id: 'agrabiz.components.Hinweis',
    defaultMessage: 'Hinweis',
  },
  Kann: {
    id: 'agrabiz.components.kann',
    defaultMessage: 'I can request',
  },
  UpdateSEPAVal: {
    id: 'agrabiz.components.UpdateSEPAVal',
    defaultMessage: 'Kreditkarte aktualisieren',
  },
  Lorry: {
    id: 'agrabiz.components.Lorry',
    defaultMessage: 'Lorry',
  },
  Articulated: {
    id: 'agrabiz.components.Articulated',
    defaultMessage: 'Articulated',
  },
  Trailer: {
    id: 'agrabiz.components.Trailer',
    defaultMessage: 'Trailer',
  },
  Usemethode: {
    id: 'agrabiz.components.Usemethode',
    defaultMessage: 'Use this payment option',
  },
  From: {
    id: 'agrabiz.components.from',
    defaultMessage: 'from',
  },
  To: {
    id: 'agrabiz.components.to',
    defaultMessage: 'to',
  },
  Period: {
    id: 'agrabiz.components.invoice.period',
    defaultMessage: 'Period',
  },
  ResetPassword: {
    id: 'agrabiz.components.resetPassword',
    defaultMessage: 'Password Reset',
  },
  ResetPasswordMailText: {
    id: 'agrabiz.components.resetPasswordMailText',
    defaultMessage:
      'Please enter the email address used for the registration and we will send you an email with the instructions to reset your password.',
  },
  SendMail: {
    id: 'agrabiz.components.sendMail',
    defaultMessage: 'Send',
  },
  ResetPasswordHeadText: {
    id: 'agrabiz.components.resetPasswordHeadText',
    defaultMessage:
      'Please enter a new password to your account. After saving you will be redirected to the login screen',
  },
  BackToLogin: {
    id: 'agrabiz.components.backToLogin',
    defaultMessage: 'Back to login',
  },

  AddMoreCattles: {
    id: 'agrabiz.components.addMoreCattles',
    defaultMessage: 'Add Cattle',
  },
  UpdateCattles: {
    id: 'agrabiz.components.updateCattles',
    defaultMessage: 'Update Cattle',
  },
  DeliveryDetails: {
    id: 'agrabiz.components.tour.info.deliveryDetails',
    defaultMessage: 'Delivery details',
  },
  BioNote: {
    id: 'agrabiz.components.tour.info.bioNote',
    defaultMessage: 'Bio cover letter',
  },
  SuccessDelete: {
    id: 'agrabiz.components.contact.delete.success',
    defaultMessage: 'Successfully deleted',
  },
  ContactRegister: {
    id: 'agrabiz.components.contact.register.success',
    defaultMessage: 'Contact Register',
  },
  ContactAdded: {
    id: 'agrabiz.components.contact.added.success',
    defaultMessage: 'Contact added',
  },
  ContactExist: {
    id: 'agrabiz.components.contact.exist',
    defaultMessage: 'Contact already exists',
  },
  ContactInvalid: {
    id: 'agrabiz.components.contact.invalid',
    defaultMessage: 'Contact invited',
  },
  ContactEmailError: {
    id: 'agrabiz.components.contact.email.error',
    defaultMessage: 'this email does not reffer to any contact',
  },
  NetworkProblem: {
    id: 'agrabiz.components.drug.admin.network.error',
    defaultMessage: 'Problem in network',
  },
  SomethingWrong: {
    id: 'agrabiz.components.invoice.sth.wrong',
    defaultMessage: 'Something went wrong',
  },
  PaymentUpdated: {
    id: 'agrabiz.components.invoice.payement.updated',
    defaultMessage: 'Payment option updated',
  },
  PaymentInCardError: {
    id: 'agrabiz.components.invoice.payement.card',
    defaultMessage: 'Problem in Card',
  },
  EarTagNotFound: {
    id: 'agrabiz.components.newstock.eartag.notfound',
    defaultMessage:
      'The entered eartag was not found in Hi-Tier database. Please try again.',
  },
  InvalidEarTagError: {
    id: 'agrabiz.components.newstock.eartag.invalid',
    defaultMessage: 'The specified ear tag number is invalid.',
  },
  InvalidDataError: {
    id: 'agrabiz.components.newstock.data.invalid',
    defaultMessage: 'Invalid data',
  },
  SellSaved: {
    id: 'agrabiz.components.sell.saved',
    defaultMessage: 'Sell saved',
  },
  InvalidEarTag: {
    id: 'agrabiz.components.buysell.invalid.eartag',
    defaultMessage: 'Eartags not valid',
  },
  ResellSuccess: {
    id: 'agrabiz.components.resell.success',
    defaultMessage: 'Resell saved successfully',
  },
  DepTimeUpdated: {
    id: 'agrabiz.components.tourifo.dep.updated',
    defaultMessage: 'Departure time updated',
  },
  DepTimeNotUpdated: {
    id: 'agrabiz.components.tourifo.dep.not.updated',
    defaultMessage: 'Departure time not updated',
  },
  DeliveryTimeUpdated: {
    id: 'agrabiz.components.tourifo.delivery.updated',
    defaultMessage: 'Delivery time updated',
  },
  DeliveryTimeNotUpdated: {
    id: 'agrabiz.components.tourifo.delivery.not.updated',
    defaultMessage: 'Delivery time not updated',
  },
  UnloadingTimeUpdated: {
    id: 'agrabiz.components.tourifo.unloading.updated',
    defaultMessage: 'Unloading time updated',
  },
  UnloadingTimeNotUpdated: {
    id: 'agrabiz.components.tourifo.unloading.not.updated',
    defaultMessage: 'Unloading time not updated',
  },
  TimeUpdateSuccess: {
    id: 'agrabiz.components.tourifo.time.updated.success',
    defaultMessage: 'Times are updated successfully',
  },
  TimeUpdateError: {
    id: 'agrabiz.components.tourifo.time.updated.error',
    defaultMessage: 'Problem while updating times',
  },
  CuttleUpdateSuccess: {
    id: 'agrabiz.components.tourifo.cuttle.updated.success',
    defaultMessage: 'Cattles are updated successfully',
  },
  CuttleUpdateError: {
    id: 'agrabiz.components.tourifo.cuttle.updated.error',
    defaultMessage: 'Problem while saving cattles',
  },
  TourPlnError: {
    id: 'agrabiz.components.tourpln.error',
    defaultMessage: 'Edit Tour Planning Problem',
  },
  MoveTP: {
    id: 'agrabiz.components.tourpln.move',
    defaultMessage: 'Moved to tour planning',
  },
  RegisterSuccess: {
    id: 'agrabiz.components.viewstock.register.success',
    defaultMessage: 'Success Register',
  },
  DeathSaved: {
    id: 'agrabiz.components.viewstock.save.death',
    defaultMessage: 'Death Saved',
  },
  DeathNotSaved: {
    id: 'agrabiz.components.viewstock.not.save.death',
    defaultMessage: 'Death not Saved',
  },
  LeaveSaved: {
    id: 'agrabiz.components.viewstock.save.leave',
    defaultMessage: 'Leave Saved',
  },
  LeaveNotSaved: {
    id: 'agrabiz.components.viewstock.not.save.leave',
    defaultMessage: 'Leave not Saved',
  },
  HitSynch: {
    id: 'agrabiz.components.viewstock.hit.sync',
    defaultMessage: 'Hit Synchronized',
  },
  HitNotSynch: {
    id: 'agrabiz.components.viewstock.hit.not.synch',
    defaultMessage: 'Hit not Synchronized',
  },
  BoxChangedSuccess: {
    id: 'agrabiz.components.viewstock.box.change.success',
    defaultMessage: 'Boxes are successfully changed',
  },
  FullTruck: {
    id: 'agrabiz.components.viewstock.truck.is.full',
    defaultMessage: 'The truck is full',
  },
  BuySaved: {
    id: 'agrabiz.components.buy.saved',
    defaultMessage: 'Buy saved',
  },
  AuthError: {
    id: 'agrabiz.components.auth.error',
    defaultMessage: 'user not found ',
  },
  DefaultErrorMessage: {
    id: 'agrabiz.components.default.error',
    defaultMessage: 'Error - Please try again or contact us.',
  },
  DefaultSuccessMessgage: {
    id: 'agrabiz.components.default.success',
    defaultMessage: 'Successfully Updated ',
  },

  ChangePasswordNotOwnerMessgage: {
    id: 'agrabiz.components.default.not.owner.password.change',
    defaultMessage: 'To change your password, please log out and use the password forgot function.',
  },

  DefaultSuccessAddMessgage: {
    id: 'agrabiz.components.default.success.add',
    defaultMessage: 'Signature added successfully ',
  },
  DefaultSuccessSaveMessgage: {
    id: 'agrabiz.components.default.success.save',
    defaultMessage: 'Password saved with success ',
  },
  BadCredentiel: {
    id: 'agrabiz.components.email.password.error',
    defaultMessage:
      'E-Mail or password are not correct, please check your entries again.',
  },
  WrongPassword: {
    id: 'agrabiz.components.password.error',
    defaultMessage: 'The password is incorrect',
  },
  UseOrderReconfirmation: {
    id: 'agrabiz.components.order.userReconfirmationError',
    defaultMessage: 'Error while updating Reconfirmation Preferences',
  },
  EmailSent: {
    id: 'agrabiz.components.email.sent',
    defaultMessage: 'Email sent',
  },
  Accept: {
    id: 'agrabiz.components.order.accept',
    defaultMessage: 'Accept',
  },
  Reconfirm: {
    id: 'agrabiz.components.order.reconfirm',
    defaultMessage: 'Reconfirm',
  },
  ContinueAsExternal: {
    id: 'agrabiz.components.order.continue.as.external',
    defaultMessage: 'Continue as external',
  },
  NotLoadedOrder: {
    id: 'agrabiz.components.order.status.notloaded',
    defaultMessage: 'Not Loaded',
  },
  ConfirmDoublePassword: {
    id: 'agrabiz.components.password.double.confirm',
    defaultMessage: 'Your password and confirmation password do not match',
  },
  NewContact: {
    id: 'agrabiz.components.admin.manual.contact',
    defaultMessage: 'Manual Contact',
  },
  SearchContact: {
    id: 'agrabiz.components.admin.search.contact',
    defaultMessage: 'Search Agrabiz contact',
  },
  AddEartag: {
    id: 'agrabiz.components.admin.add.eartag',
    defaultMessage: 'Add Eartag',
  },
  EarTags: {
    id: 'agrabiz.components.admin.eartags',
    defaultMessage: 'Eartags',
  },
  FilterAfter: {
    id: 'agrabiz.components.admin.filter.after',
    defaultMessage: 'Filter After',
  },
  AddSiganture: {
    id: 'agrabiz.components.admin.add.signature',
    defaultMessage: 'Add Signature',
  },
  UserType: {
    id: 'agrabiz.components.admin.agrabiz.nonagrabiz',
    defaultMessage: 'User type',
  },
  CompanyType: {
    id: 'agrabiz.components.admin.company.type',
    defaultMessage: 'Company type',
  },
  AgrabizUsers: {
    id: 'agrabiz.components.admin.agrabiz.users',
    defaultMessage: 'Agrabiz users',
  },
  ExternalUsers: {
    id: 'agrabiz.components.admin.nonagrabiz.users',
    defaultMessage: 'External users',
  },
  NumberOfEartags: {
    id: 'agrabiz.components.admin.number.of.eartags',
    defaultMessage: 'Number of eartags',
  },
  lastTrackingUpdates: {
    id: 'agrabiz.components.admin.track-update',
    defaultMessage: 'Last LIVE update :',
  },
  trackingTime: {
    id: 'agrabiz.components.admin.track-update-time',
    defaultMessage: 'min ago',
  },
  ownStock: {
    id: 'agrabiz.components.own.stock',
    defaultMessage: 'Own stock',
  },
  openBuyings: {
    id: 'agrabiz.components.open.buyings',
    defaultMessage: 'Open buyings',
  },
  businessPartner: {
    id: 'agrabiz.components.business.partners',
    defaultMessage: 'Business partner',
  },
  senderName: {
    id: 'agrabiz.components.sender.name',
    defaultMessage: 'Sender name',
  },
  orderName: {
    id: 'agrabiz.components.order.name',
    defaultMessage: 'Order name',
  },
  SelectedOrders: {
    id: 'agrabiz.components.selectedOrders',
    defaultMessage: 'Selected orders',
  },
  More: {
    id: 'agrabiz.components.more',
    defaultMessage: 'more',
  },
  Successful: {
    id: 'agrabiz.components.admin.successful',
    defaultMessage: 'Successful',
  },
  Failed: {
    id: 'agrabiz.components.admin.failed',
    defaultMessage: 'Failed',
  },
  ExpirationDate: {
    id: 'agrabiz.components.admin.expirationDate',
    defaultMessage: 'Expiration date',
  },
  Localization: {
    id: 'agrabiz.components.admin.localization',
    defaultMessage: 'Map',
  },
  successPdfCreation: {
    id: 'agrabiz.components.admin.successpdfcreation',
    defaultMessage: 'The pdf was generated successfully',
  },
  updateNote: {
    id: 'agrabiz.components.updateNote',
    defaultMessage: 'Aktualisieren',
  },
  speditionsplanung: {
    id: 'agrabiz.components.speditionsplanung',
    defaultMessage: 'Speditionsplanung',
  },
  Kaufanfrage: {
    id: 'agrabiz.components.Kaufanfrage',
    defaultMessage: 'Kaufanfrage',
  },
  CancelViehan: {
    id: 'agrabiz.components.CancelViehan',
    defaultMessage: 'Stornieren',
  },
  UserName: {
    id: 'agrabiz.components.UserName',
    defaultMessage: 'an',
  },
  Selectreceiver: {
    id: 'agrabiz.components.Selectreceiver',
    defaultMessage: 'Empfänger wählen',
  },
  Nichtzugeordnet: {
    id: 'agrabiz.components.Nichtzugeordnet',
    defaultMessage: 'Nicht zugeordnet',
  },
  NichtTotal: {
    id: 'agrabiz.components.NichtTotal',
    defaultMessage: 'Gesamt',
  },
  hinzufgen: {
    id: 'agrabiz.components.hinzufgen',
    defaultMessage: 'LKW hinzufügen',
  },
  cattleUpdate: {
    id: 'agrabiz.components.cattleUpdate',
    defaultMessage: 'Update',
  },
  hplanning: {
    id: 'agrabiz.components.hplanning',
    defaultMessage: 'Finish tour planning',
  },
  Bemerkungen: {
    id: 'agrabiz.components.Bemerkungen',
    defaultMessage: 'Zusätzliche Bemerkungen',
  },
  Selbstfahrer: {
    id: 'agrabiz.components.Selbstfahrer',
    defaultMessage: 'Selbstfahrer',
  },
  Externaler: {
    id: 'agrabiz.components.Externaler',
    defaultMessage: 'External',
  },
  TLevels: {
    id: 'agrabiz.components.TLevels',
    defaultMessage: 'Levels',
  },
  Mailrecipient: {
    id: 'agrabiz.components.Mailrecipient',
    defaultMessage: 'Mail recipient',
  },
  Sendcurrentcattle: {
    id: 'agrabiz.components.Sendcurrentcattle',
    defaultMessage: 'Send current cattle planning to',
  },
  SendSucces: {
    id: 'agrabiz.components.SendSucces',
    defaultMessage: 'E-Mail wurde erfolgreich versendet',
  },
  SendFailure: {
    id: 'agrabiz.components.SendFailure',
    defaultMessage: 'E-Mail konnte nicht versendet werden',
  },
  SendmailSended: {
    id: 'agrabiz.components.SendmailSended',
    defaultMessage: 'Send',
  },
  EnterValidemail: {
    id: 'agrabiz.components.EnterValidemail',
    defaultMessage: 'Please enter a valid email address',
  },
  UserPhoneNbr: {
    id: 'agrabiz.components.UserPhoneNbr',
    defaultMessage: 'Telefon',
  },
  UserEmailNbr: {
    id: 'agrabiz.components.UserEmailNbr',
    defaultMessage: 'Telefon',
  },
  PreRegistationTitle: {
    id: 'agrabiz.components.PreRegistationTitle',
    defaultMessage: 'Betriebsart wählen',
  },
  PreRegistationBody: {
    id: 'agrabiz.components.PreRegistationBody',
    defaultMessage: 'Bitte wählen Sie in diesem Schritt Ihre zugehörige Betriebsart aus. Die richtige Auswahl Ihrer Betriebsart entscheidet über Ihr Funktionsangebot und kann nicht rückgängig gemacht werden.',
  },
  NextBtnReg: {
    id: 'agrabiz.components.NextBtnReg',
    defaultMessage: 'Next',
  },
  Sendslaughterdata: {
    id: 'agrabiz.components.Sendslaughterdata',
    defaultMessage: 'Send slaughter data',
  },
  Einstallgewicht: {
    id: 'agrabiz.components.Einstallgewicht',
    defaultMessage: 'Verkaufsgewicht (in kg)',
  },
  Verkaufsgewicht: {
    id: 'agrabiz.components.Verkaufsgewicht',
    defaultMessage: 'Verkaufsgewicht (in kg)',
  },
  ConfirmBack: {
    id: 'agrabiz.components.ConfirmBack',
    defaultMessage: 'Back',
  },
  ConfirmYes: {
    id: 'agrabiz.components.ConfirmYes',
    defaultMessage: 'Yes',
  }, 
  ConfirmValidation: {
    id: 'agrabiz.components.ConfirmValidation',
    defaultMessage: 'Are you sure that you want to finalize the tourplanning',
  },
  LomFrom : {
    id: 'agrabiz.components.LomFrom',
    defaultMessage: 'From',
  },
  LomTo : {
    id: 'agrabiz.components.LomTo',
    defaultMessage: 'To',
  },
  LomCsv : {
    id: 'agrabiz.components.LomCsv',
    defaultMessage: 'Download csv file ',
  },
  SelectAgrabizUsers: {
    id: 'agrabiz.components.admin.SelectAgrabizUsers',
    defaultMessage: 'Select',
  },
  Stockreport: {
    id: 'agrabiz.components.admin.Stockreport',
    defaultMessage: 'Stock report',
  },
  Medicationreport: {
    id: 'agrabiz.components.admin.Medicationreport',
    defaultMessage: 'Medication report',
  },
  ProducerBuyrequest : {
    id: 'agrabiz.components.admin.ProducerBuyrequest',
    defaultMessage: 'Buy request ',
  },
  ProducerBuyrequestFom: {
    id: 'agrabiz.components.admin.ProducerBuyrequestFom',
    defaultMessage: 'from',
  },
  DeleteValidation: {
    id: 'agrabiz.components.DeleteValidation',
    defaultMessage: 'Sind Sie sich sicher, dass Sie diesen Kontakt löschen möchten?',
  },
  DeathTodesart: {
    id: 'agrabiz.components.DeathTodesart',
    defaultMessage: 'Todesart',
  },
  BioQualityprograms: {
    id: 'agrabiz.components.admin.BioQualityprograms',
    defaultMessage: 'Todesart',
  },
  AddSigantureTwo: {
    id: 'agrabiz.components.admin.AddSigantureTwo',
    defaultMessage: 'Delivery confirmation by the recipient',
  },
  NoReportP: {
    id: 'agrabiz.components.admin.NoReportP',
    defaultMessage: 'No report for this date',
  },
  Invoiceemail: {
    id: 'agrabiz.components.admin.Invoiceemail',
    defaultMessage: 'Invoice email',
  },
});
