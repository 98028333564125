/* eslint-disable */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import StepTwo from '../../components/Register/StepTwo';
import StepOne from '../../components/Register/StepOne';
import StepThree from '../../components/Register/StepThree';

import ImageStep from '../../components/Register/ImageStep';

import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LocaleToggleText from '../../containers/LocaleToggleText';

import logo from '../../static/images/logo.png';
import imgStepOne from '../../static/images/steps-one.jpg';
import imgStepTwo from '../../static/images/steps-two.jpg';
import imgStepThree from '../../static/images/steps-three.jpg';

import './register.css';

class Register extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      step_register: this.props.company.step_register,
    };
  }

  redirectToHome = () => {
    this.props.history.push(`/`);
  };

  componentDidMount() {
    if (this.props.company.step_register === 1) {
      this.props.history.push(`/register/step/1`);
    }
  }

  imageSrc = (step) => {
    switch (step) {
      case '2':
        step = imgStepTwo;
        break;
      case '3':
        step = imgStepThree;
        break;
      default:
        step = imgStepOne;
        break;
    }
    return <ImageStep src={step} />;
  };

  render() {
    let step = this.props.match.params.step;
    let imgStep = null;
    if (step !== undefined) {
      imgStep = this.imageSrc(step);
    }
    return (
      <div className="container " id="register-page">
        <Row className="header">
          <Col xs={12} className="register-header">
            <div className="register-h-logo">
              <img onClick={this.redirectToHome} src={logo} />
            </div>
            <div className="register-h-stepper">{imgStep}</div>
          </Col>
        </Row>

        <div id="register-content">
          <Switch>
            <Route path="/register/step/1" component={StepOne} />
            <Route path="/register/step/2" component={StepTwo} />
            <Route path="/register/step/3" component={StepThree} />
            <Route path="/add_company/step/2" component={StepThree} />
          </Switch>
        </div>
        <div id="register-footer">
          <div className="footer-left-section">© Yanogo GmbH {new Date().getFullYear()}.</div>
          <div className="footer-right-section">
            <div className="language-content">
              <span className="language-text"> Language : &nbsp;</span>
            </div>
            <LocaleToggleText active={true} />
          </div>
        </div>
      </div>
    );
  }
}

StepOne.propTypes = {
  company: PropTypes.object,
};
function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  const { company } = state.toJS();
  return { company };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
