export const tab_role_enter_my_stock = [
  'ROLE_COMPANY_OWNER',
  'ROLE_AGR_MANAGER',
  'ROLE_AGR_STABLE_WORKER',
  'ROLE_TRD_MANAGER',
  'ROLE_TRD_OFFICE_WORKER',
  'ROLE_TRT_BOOKER',
  'ROLE_TRT_DRIVER',
  'ROLE_ABA_PURCHASER',
  'ROLE_ABA_EMPLOYEE',
  'ROLE_VET_MANAGER',
  'ROLE_VET_STABLE_WORKER',
  'ROLE_VET_EMPLOYEE',
  'ROLE_TRD_OFFICE_WORKER',
  'ROLE_TRD_DRIVER',
];
