import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { TextInput } from 'react-bootstrap4-form-validation';
import messages from '../../messages';

const VeterinaryDetails = (props) => {
  const { company } = props;

  return (
    <div className="new-cards-owner">
      <div className="new-cards-owner-inner">
        <div md={4} className=" new-cards-owner-inner-b1">
          <FormattedMessage {...messages.vetPractitioner} />
        </div>
        <div
          md={8}
          id="settings-inner-card"
          className="new-cards-owner-inner-b2"
        >
          <TextInput
            name="veterinary_practitioner"
            type="text"
            defaultValue={company.agriculturist_extra.veterinary_practitioner}
          />
        </div>
      </div>

      <div className="new-cards-owner-inner">
        <div md={4} className=" new-cards-owner-inner-b1">
          <FormattedMessage {...messages.vetFirstName} />
        </div>
        <div
          md={8}
          id="settings-inner-card"
          className="new-cards-owner-inner-b2"
        >
          <TextInput
            name="veterinary_firstname"
            type="text"
            defaultValue={company.agriculturist_extra.veterinary_firstname}
          />
        </div>
      </div>

      <div className="new-cards-owner-inner">
        <div md={4} className=" new-cards-owner-inner-b1">
          <FormattedMessage {...messages.vetLastName} />
        </div>
        <div
          md={8}
          id="settings-inner-card"
          className="new-cards-owner-inner-b2"
        >
          <TextInput
            name="veterinary_lastname"
            type="text"
            defaultValue={company.agriculturist_extra.veterinary_lastname}
          />
        </div>
      </div>

      <div className="new-cards-owner-inner">
        <div md={4} className=" new-cards-owner-inner-b1">
          <FormattedMessage {...messages.vetstreet} />
        </div>
        <div
          md={8}
          id="settings-inner-card"
          className="new-cards-owner-inner-b2"
        >
          <TextInput
            name="veterinary_address"
            type="text"
            defaultValue={company.agriculturist_extra.veterinary_address}
          />
        </div>
      </div>

      <div className="new-cards-owner-inner">
        <div md={4} className=" new-cards-owner-inner-b1">
          <FormattedMessage {...messages.vetZipCode} />
        </div>
        <div
          md={8}
          id="settings-inner-card"
          className="new-cards-owner-inner-b2"
        >
          <TextInput
            name="veterinary_zip_code"
            type="text"
            defaultValue={company.agriculturist_extra.veterinary_zip_code}
          />
        </div>
      </div>

      <div className="new-cards-owner-inner">
        <div md={4} className=" new-cards-owner-inner-b1">
          <FormattedMessage {...messages.vetCity} />
        </div>
        <div
          md={8}
          id="settings-inner-card"
          className="new-cards-owner-inner-b2"
        >
          <TextInput
            name="veterinary_city"
            type="text"
            defaultValue={company.agriculturist_extra.veterinary_city}
          />
        </div>
      </div>
      <div className="new-cards-owner-inner-b2-btn">
        <Button
          type="submit"
          className="form-btn-valide add-s-bottom-part-cols"
        >
          <FormattedMessage {...messages.EditMessage} />
        </Button>
      </div>
    </div>
  );
};

VeterinaryDetails.propTypes = {
  company: PropTypes.object,
  businessType: PropTypes.number,
  // selectedOptionTypeUser: PropTypes.array,
  optionsTypesUse: PropTypes.array,
  onChange: PropTypes.func,
};

export default VeterinaryDetails;
