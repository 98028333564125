import { OrderService } from '../../../../../services/order.service';
import extractData from '../../../../../services/data.processing';
import { getMessage, rejectCancelMessage } from './getMessage';

const confirmButtonText = async (
  orderId,
  receiverId,
  is_buyer,
  topic,
  token,
) => {
  let message;
  let rejectCancel;
  const value = await OrderService.getOrderDetails(token, orderId, receiverId);
  const data = await extractData(value);
  const { sender_business_type, receiver_business_type, status } = data.order;
  message = getMessage(
    sender_business_type,
    receiver_business_type,
    status,
    is_buyer,
    topic,
  );
  rejectCancel = rejectCancelMessage(is_buyer, topic);
  return { message, rejectCancel };
};

export default confirmButtonText;
