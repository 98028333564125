import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import CompanyForm from './CompanyForm';
import moment from 'moment-es6';
import { alertActions } from '../../../../actions';
import './form.css';

const FormWithFormik = withFormik({
  mapPropsToValues({
    pick_up_date,
    delivery_date,
    delivery_until,
    capacity,
    transport_company_id,
    licence_number,
    type,
    setContact,
    iconType,
    open_request,
  }) {
    let date = moment(delivery_date, 'DD.MM.YY hh:mm A').toDate();
    return {
      delivery_date: date || new Date(),
      delivery_until: delivery_until,
      pick_up_date: pick_up_date || date,
      capacity: capacity || 30,
      transport_company_id: transport_company_id,
      open_request: open_request,
      type: type || 'MANUAL_ENTRY',
      iconType: iconType,
      licence_number:
        licence_number ||
        Math.floor(Math.random() * Math.floor(100000)).toString(),
    };
  },
  validationSchema: Yup.object().shape({
    delivery_date: Yup.date().required(),
    licence_number: Yup.string(),
    delivery_until: Yup.string()
      .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'delivery until is required')
      .required(),
    capacity: Yup.number().integer().min(1).default(30).required(),
    pick_up_date: Yup.date().required(),
    transport_company_id: Yup.string().required() || Yup.object().required(),
    open_request: Yup.object().shape({
      id: Yup.string().required(),
      value: Yup.string().required(),
      label: Yup.string().required(),
    }),
  }),
  handleSubmit: (values, { props, resetForm, setErrors, setSubmitting }) => {
    props.togglePopup();
    if (props.editeTruck === false) {
      props.addTruckData(values);
    } else {
      props.editTransportTruck(props.index, values);
    }
  },
})(CompanyForm);

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWithFormik);
