import React from 'react';
import { connect } from 'react-redux';
import { Col, Tabs, Tab, Image } from 'react-bootstrap';
import { MdNavigateBefore } from 'react-icons/md';
import isEmpty from 'lodash/isEmpty';
import Loaded from '../../../../static/images/admin/Icons/Push messages/1.png';
import Chat from './Chat';
import Contact from './contact';
import { alertActions } from '../../../../actions';
import OrderDetails from './orderDetails/OrderDetails';
import ResaleOverview from './resaleOverview/ResaleOverview';
import { OrderService } from '../../../../services/order.service';
import extractData from '../../../../services/data.processing';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

import './tabEvents.css';

class TabEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      width: window.innerWidth,
      show: true,
    };
  }

  componentDidMount() {
    const { order_id } = this.props.orderDetail;
    const { receiver_id } = this.props.orderDetail;
    this.getOrderDetails(order_id, receiver_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Load new data when the dataSource property changes.
    if (nextProps.orderDetail !== this.props.orderDetail) {
      this.getOrderDetails(
        nextProps.orderDetail.order_id,
        nextProps.orderDetail.receiver_id,
      );
    }
  }

  // componentWillUnmount() {
  //   this.props.emptyTruck();
  // }

  getOrderDetails = async (order_id, receiver_id) => {
    let token = this.props.users.accessToken;
    let value = await OrderService.getOrderForOneBidder(
      order_id,
      token,
      receiver_id,
    );
    const data = await extractData(value);
    await this.setState({
      details: data,
    });
  };

  handleClick = async (val) => {
    this.setState({ activeTab: val });
  };

  handlelShow = (val) => {
    this.setState({ show: val });
  };

  render() {
    const {
      orderDetail,
      companyId,
      companyNumber,
      company_type,
      userId,
      getSrcImg,
      getOrders,
      handleClosePanel,
      truckDetail,
      truck,
      users,
      orderType,
      handleCloseToBeload,
    } = this.props;
    return (
      <div id="tab-events-container">
        {!isEmpty(truck) && (
          <div className="tab-event-top-item">
            <div className="navigate-before">
              <div md={3}>
                <MdNavigateBefore
                  className="before"
                  onClick={handleCloseToBeload}
                />
              </div>
            </div>
            <div className="tour-info-block">
              <div
                className="tourInfo-title first outer"
                onClick={this.showOrderDetail}
              >
                <Col md={3} className="status">
                  <Image className="status-image" src={Loaded} />
                </Col>
                <div className="tourInfo-title-b2 sub">
                  <div>
                    <div className="tourInfo-title-span-text">
                      {truckDetail.name}
                    </div>
                    <div className="inner-text">
                      {truckDetail.delivery_date} | {truckDetail.recipient}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="style-border-t-info" />
            </div>
          </div>
        )}
        <div id="tab-Event">
          <Tabs
            defaultActiveKey={this.state.activeTab}
            onSelect={this.handleClick}
            className={!this.state.show && 'hide-show-tabs'}
          >
            <Tab
              eventKey={1}
              title={<FormattedMessage {...messages.OrderDetails} />}
            >
              {this.state.activeTab === '1' && (
                <OrderDetails
                  orderType={this.props.orderType}
                  getSrcImg={getSrcImg}
                  handleClosePanel={handleClosePanel}
                  orderDetail={orderDetail}
                  companyId={companyId}
                  companyNumber={companyNumber}
                  company_type={company_type}
                  getOrders={getOrders}
                  truckDetail={truckDetail}
                  details={this.state.details}
                  handlelShow={this.handlelShow}
                />
              )}
            </Tab>

            {orderDetail.topic === 'RESALE_STOCK' &&
              users.user.session.company_type === '2' &&
              this.props.orderType === 'sellings' && (
                <Tab
                  eventKey={2}
                  title={<FormattedMessage {...messages.ResaleOverview} />}
                >
                  {this.state.activeTab === '2' && (
                    <ResaleOverview
                      orderType={orderType}
                      getSrcImg={getSrcImg}
                      orderDetail={orderDetail}
                      getOrders={getOrders}
                    />
                  )}
                </Tab>
              )}
            {this.state.show && (
              <Tab
                eventKey={3}
                title={<FormattedMessage {...messages.Contact} />}
              >
                {this.state.activeTab === '3' && this.state.show && (
                  <Contact orderDetail={orderDetail} />
                )}
              </Tab>
            )}
            {this.state.show && (
              <Tab eventKey={4} title={<FormattedMessage {...messages.Chat} />}>
                {this.state.activeTab === '4' && this.state.show && (
                  <Chat
                    details={this.state.details}
                    userId={userId}
                    companyId={companyId}
                  />
                )}
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabEvents);
