/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, FormControl, Image } from 'react-bootstrap';
import buyStock from '../../../static/images/buy-stock.png';
import add from '../../../static/images/600-px-plus-cm-128-svg-copie-12.png';
import { tags } from '../../../constants';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import CustomSelect from '../../../components/CustomSelect';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: '#7bd812',
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: '',
    width: '100%',
    borderWidth: '1px',
    borderColor: ' transparent transparent #eee',
    '&:hover': { borderColor: 'transparent transparent #7bd812' },
  }),
};

class AddStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      earTagFields: { maxLength: 10, iso: '276', earTag: 'DE' },
      earTagNumber: { nb: '', maxLength: 10 },
    };
  }

  handleChangeEarTagCountry = async (event) => {
    let earTagFields = this.state.earTagFields;
    earTagFields.earTag = event.label;
    earTagFields.maxLength = event.nbDigits;
    earTagFields.iso = event.iso;

    this.setState({
      earTagFields: earTagFields,
    });
  };
  handleChangeEarTag = (event) => {
    let earTagNumber = this.state.earTagNumber;
    earTagNumber.nb = event.target.value;

    this.setState({
      earTagNumber: earTagNumber,
    });
  };
  handleFinalEarTag = () => {
    let earTagNumber = this.state.earTagNumber;
    let earTagFields = this.state.earTagFields;
    earTagFields.earTag = earTagFields.earTag.concat(earTagNumber.nb);

    this.setState({
      earTagFields: earTagFields,
    });

    this.props.handleChangeEarTagFianl(
      this.completeEarTagWithZero(
        earTagFields.earTag,
        this.state.earTagFields.maxLength,
      ),
    );
  };

  completeEarTagWithZero(earTag, maxLength) {
    if (typeof earTag !== 'undefined' && earTag.length <= maxLength + 2) {
      var zero = '0';
      return earTag + zero.repeat(maxLength + 2 - earTag.length);
    }
    return '';
  }
  render() {
    return (
      <div
        className="add-stock-card-box panel panel-default"
        id="body-add-stock"
      >
        <div className="panel-body">
          <Row>
            <Col md={4}>
              <h5 className="text-center white-color title-add-stock text">
                <FormattedMessage {...messages.HomeAddStock} />
              </h5>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <Image src={buyStock} className="image" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className="text-center white-color eartag">
                <FormattedMessage {...messages.TypeYourEarTag} />
              </p>
            </Col>
          </Row>
          <Row>
            <div className="eartag-number-add-container">
              <div className="eartag-number-input-select">
                <div className="e-n-select">
                  <CustomSelect
                    defaultValue={tags.find(
                      (country) => country.value === 'Germany',
                    )}
                    options={tags}
                    styles={customStyles}
                    onChange={this.handleChangeEarTagCountry}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 8,
                      colors: {
                        ...theme.colors,
                        primary25: '#F1FFDE',
                        primary: '#7bcd10',
                      },
                    })}
                  />
                </div>
                <div className="e-n-input">
                  <FormControl
                    onChange={this.handleChangeEarTag}
                    maxLength={this.state.earTagFields.maxLength}
                    value={this.state.earTagNumber.nb}
                  />
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <p className="text-center white-color select-animal">
                <FormattedMessage {...messages.SelectAnimal} />
              </p>
            </Col>
            <Col className="text-center content-type">
              <a
                className={
                  this.props.type === 'birth'
                    ? 'label-type active'
                    : 'label-type '
                }
                onClick={() => {
                  this.props.choseType('birth');
                }}
              >
                <FormattedMessage {...messages.NewBorn} />
              </a>
              <a
                className={
                  this.props.type === 'incoming'
                    ? 'label-type active'
                    : 'label-type '
                }
                onClick={() => {
                  this.props.choseType('incoming');
                }}
              >
                <FormattedMessage {...messages.NewIncome} />
              </a>
              <a
                className={
                  this.props.type === 3 ? 'label-type active' : 'label-type '
                }
              >
                <FormattedMessage {...messages.Import} />
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center add-button">
              {this.state.earTagFields.earTag ? (
                <Image
                  style={{ width: '15%' }}
                  src={add}
                  onClick={() => {
                    this.handleFinalEarTag();
                    this.props.setShowForm();
                  }}
                />
              ) : (
                <Image style={{ width: '15%' }} src={add} />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
AddStock.propTypes = {
  handleChangeEarTagCountry: PropTypes.func,
  handleChangeEarTag: PropTypes.func,
};
export default AddStock;
