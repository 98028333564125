import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
function NavContent(props) {
  const location = useLocation();
  return (
    <li
      onClick={(e) => props.selectLinks(props.index, props.text)}
      index={props.index}
      className={props.active ? 'active' : ''}
    >
      <Link
        className={props.className}
        to={{
          pathname: props.path,
          state: {
            reloadComponent: location.pathname === props.path ? true : false,
          },
        }}
      >
        <p>{props.text}</p>
      </Link>
    </li>
  );
}
NavContent.propTypes = {
  path: PropTypes.string,
  index: PropTypes.number,
  glyphicon: PropTypes.string,
  avtive: PropTypes.bool,
  imgUrl: PropTypes.string,
};
export default NavContent;
