import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import Header from '../../components/Admin/Header/Header';
import Home from '../../components/Admin/Home/';
import Side from '../../components/Admin/SideBar/Side';
import Setting from '../../components/Admin/Setting';
import Contact from '../../components/Admin/Contact/';
import ImportContacts from '../../components/Admin/Contact/contact-import/ImportContacts';
import Messages from '../../components/Admin/Messages/';
import NewStock from '../../components/Admin/NewStock/';
import ViewStock from '../../components/Admin/ViewStock/';
import GoogleAnalytics from '../../components/Admin/GoogleAnalytics';
import ChooseSubscription from '../../components/Admin/Home/ChooseSubscription';
import BuyingsOverview from '../../components/Admin/BuyingsOverview';
import PlanningProducer from '../../components/Admin/TourPlanningProducer';

import DrugAdministration from '../../components/Admin/DrugAdministration/';
import Buyings from '../../components/Admin/Buyings/';
import Sellings from '../../components/Admin/Sellings/';
import Reports from '../../components/Admin/Reports/';
import ReportsAndSearch from '../../components/Admin/ReportsAndSearch/';
import Invoice from '../../components/Admin/Invoicing';
import Tours from '../../components/Admin/Tours';
import SettingsById from '../../components/Admin/SettingsById';
import BuyingsById from '../../components/Admin/BuyingsById';
import TourInformation from '../../components/Admin/TourInformation';
import TourPlanning from '../../components/Admin/TourPlanning';

import { style } from '../../utils/notifications';
import { FormattedMessage } from 'react-intl';
import messages from '../../components/Admin/messages';
import { adminActions } from '../../actions';
import { specificRoles } from '../../constants';
import { userService } from '../../services/user.service';

import extractData from '../../services/data.processing';

import PropTypes from 'prop-types';

import '../../static/css/admin.css';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      panelTitle: null,
      subscription_type: this.props.users.user.subscription_type,
    };
  }

  addNotification = (type, message) => {
    let level;
    switch (type) {
      case 'alert-success':
        level = 'success';
        break;
      case 'alert-warning':
        level = 'warning';
        break;
      case 'alert-danger':
        level = 'error';
        break;
      case 'alert-info':
        level = 'info';
        break;
      default:
        break;
    }
    if (level) {
      this.refs.notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-bell" />,
        message: message,
        level: level,
        position: 'tc',
        autoDismiss: 5,
      });
    }
  };

  getUserOwnerById = async () => {
    if (this.props.users.user.subscription_type === 'free') {
      let token = this.props.users.accessToken;
      const value = await userService.getUserOwner(
        this.props.users.user.session.company_number,
        token,
      );
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        const company = await extractData(value);
        this.setState({ subscription_type: company.subscription_type });
      } else {
        this.setState({
          subscription_type: this.props.users.user.subscription_type,
        });
      }
    }
  };

  componentDidMount = () => {
    let name = this.props.users.user.name;
    this.getUserOwnerById();
    this.setState({
      panelTitle: (
        <div>
          <FormattedMessage {...messages.Hi} /> <span> {name} </span>{' '}
        </div>
      ),
    });
    this.setState({ _notificationSystem: this.refs.notificationSystem });
  };

  UNSAFE_componentWillUpdate(prevProps) {
    const { alert } = this.props;
    if (alert.type) {
      this.addNotification(alert.type, alert.message);
    }
  }

  changeHeaderTitle = (title) => {
    if (typeof title.props !== 'undefined') {
      this.props.handleTitleChange(title.props.defaultMessage);
    }
  };

  render() {
    let { subscription_type } = this.state.subscription_type
      ? this.state.subscription_type
      : 'free';
    let { role } = this.props.users.user;
    let { company_type } = this.props.users.user.session;

    return (
      <div className="wrapper" id="admin">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Side
          {...this.props}
          changeHeaderTitle={this.changeHeaderTitle}
          company={this.state.subscription_type}
        />
        <Header {...this.props} panelTitle={this.props.panelTitle.panelName} />
        <div
          id="main-panel"
          className="main-panel admin-overlay"
          ref="mainPanel"
        >
          <div className="content">
            <Switch>
              <Route exact path="/admin/home/" component={Home} />
              {specificRoles.ROLE_FOR_SIDE_MENUE_MEDIUM.includes(role) ===
                false &&
                specificRoles.ROLE_FOR_SIDE_MENUE_REDUCED.includes(role) ===
                  false && (
                  <Switch>
                    <Route path="/admin/setting" component={Setting} />
                    <Route
                      path="/admin/contact/import"
                      component={ImportContacts}
                    />
                    <Route path="/admin/contact" component={Contact} />
                    <Route path="/admin/messages" component={Messages} />
                    {subscription_type !== 'free' || company_type === '1' ? (
                      <Switch>
                        <Route path="/admin/invoices" component={Invoice} />
                        {company_type !== 3 ? (
                          <Switch>
                            <Route
                              path="/admin/new-stock"
                              component={NewStock}
                            />
                            <Route
                              path="/admin/view-stock"
                              component={ViewStock}
                            />
                            <Route
                              path="/admin/google-analytics"
                              component={GoogleAnalytics}
                            />
                            <Route
                              path="/admin/drug-administration"
                              component={DrugAdministration}
                            />

                            <Route
                              path="/admin/buy-stock"
                              component={Buyings}
                            />
                            <Route
                              path="/admin/sell-stock"
                              component={Sellings}
                            />
                            <Route
                              path="/admin/reports"
                              component={ReportsAndSearch}
                            />
                            <Route
                              path="/admin/eartag-search"
                              component={ReportsAndSearch}
                            />
                            <Route
                              path="/admin/slaughter-report"
                              component={Reports}
                            />
                            <Route
                              path="/admin/tourPlanningProducer"
                              component={PlanningProducer}
                            />

                            <Route
                              path="/admin/buyings-overview"
                              component={BuyingsOverview}
                            />

                            <Route
                              path="/admin/manage-tours"
                              component={Tours}
                            />
                            <Route
                              path="/admin/sell-stocks/:orderId/:code"
                              component={SettingsById}
                            />
                            <Route
                              path="/admin/buy-stocks/:orderId/:code"
                              component={BuyingsById}
                            />
                            <Route
                              path="/admin/tour-details/:orderId/:truckId"
                              component={TourInformation}
                            />
                            <Route
                              path="/admin/tour-planning/:orderId/:receiverId"
                              component={TourPlanning}
                            />
                            <Route
                              path="/admin/choose-subscription"
                              component={ChooseSubscription}
                            />
                            <Route path="/admin/live-stock" component={Tours} />
                            <Route path="/admin/my-tours" component={Tours} />
                            <Redirect to="/admin/home" />
                            <Redirect to="/admin/home" />
                          </Switch>
                        ) : (
                          <Switch>
                            <Route
                              path="/admin/buy-stock"
                              component={Buyings}
                            />
                            <Route
                              path="/admin/eartag-search"
                              component={ReportsAndSearch}
                            />
                            <Route
                              path="/admin/slaughter-report"
                              component={Reports}
                            />

                            <Redirect to="/admin/home" />
                          </Switch>
                        )}
                      </Switch>
                    ) : (
                      <Redirect to="/admin/home" />
                    )}
                  </Switch>
                )}
              {role === 'ROLE_ABA_EMPLOYEE' && (
                <Switch>
                  <Route path="/admin/setting" component={Setting} />
                  {subscription_type !== 'free' ? (
                    <Switch>
                      <Route path="/admin/buy-stock" component={Buyings} />
                      <Route
                        path="/admin/eartag-search"
                        component={ReportsAndSearch}
                      />
                      <Route
                        path="/admin/slaughter-report"
                        component={Reports}
                      />

                      <Redirect to="/admin/home" />
                    </Switch>
                  ) : (
                    <Redirect to="/admin/home" />
                  )}
                </Switch>
              )}
              {role === 'ROLE_TRD_DRIVER' && (
                <Switch>
                  <Route path="/admin/setting" component={Setting} />
                  {subscription_type !== 'free' ? (
                    <Switch>
                      <Route path="/admin/manage-tours" component={Tours} />
                      <Route path="/admin/live-stock" component={Tours} />
                      <Route path="/admin/my-tours" component={Tours} />
                      <Redirect to="/admin/home" />
                    </Switch>
                  ) : (
                    <Redirect to="/admin/home" />
                  )}
                </Switch>
              )}
              {role === 'ROLE_TRD_OFFICE_WORKER' && (
                <Switch>
                  <Route path="/admin/setting" component={Setting} />
                  {subscription_type !== 'free' ? (
                    <Switch>
                      <Route path="/admin/new-stock" component={NewStock} />
                      <Route path="/admin/view-stock" component={ViewStock} />
                      <Route path="/admin/manage-tours" component={Tours} />
                      <Route path="/admin/live-stock" component={Tours} />
                      <Route path="/admin/my-tours" component={Tours} />
                      <Route
                        path="/admin/reports"
                        component={ReportsAndSearch}
                      />

                      <Redirect to="/admin/home" />
                    </Switch>
                  ) : (
                    <Redirect to="/admin/home" />
                  )}
                </Switch>
              )}
              {role === 'ROLE_AGR_STABLE_WORKER' && (
                <Switch>
                  <Route path="/admin/setting" component={Setting} />
                  {subscription_type !== 'free' ? (
                    <Switch>
                      <Route path="/admin/new-stock" component={NewStock} />
                      <Route path="/admin/view-stock" component={ViewStock} />
                      <Route path="/admin/manage-tours" component={Tours} />
                      <Route path="/admin/live-stock" component={Tours} />
                      <Route path="/admin/my-tours" component={Tours} />
                      <Redirect to="/admin/home" />
                    </Switch>
                  ) : (
                    <Redirect to="/admin/home" />
                  )}
                </Switch>
              )}
              {role === 'ROLE_ABA_PURCHASER' && (
                <Switch>
                  <Route path="/admin/setting" component={Setting} />
                  <Route path="/admin/contact" component={Contact} />
                  <Route path="/admin/messages" component={Messages} />
                  {subscription_type !== 'free' ? (
                    <Switch>
                      <Route path="/admin/buy-stock" component={Buyings} />
                      <Route
                        path="/admin/eartag-search"
                        component={ReportsAndSearch}
                      />
                      <Route
                        path="/admin/slaughter-report"
                        component={Reports}
                      />
                      <Redirect to="/admin/home" />
                    </Switch>
                  ) : (
                    <Redirect to="/admin/home" />
                  )}
                </Switch>
              )}
              {role === 'ROLE_TRD_MANAGER' && (
                <Switch>
                  <Route path="/admin/setting" component={Setting} />
                  <Route
                    path="/admin/contact/import"
                    component={ImportContacts}
                  />
                  <Route path="/admin/contact" component={Contact} />
                  <Route path="/admin/messages" component={Messages} />
                  {subscription_type !== 'free' ? (
                    <Switch>
                      <Route path="/admin/new-stock" component={NewStock} />
                      <Route path="/admin/view-stock" component={ViewStock} />
                      <Route path="/admin/buy-stock" component={Buyings} />
                      <Route path="/admin/sell-stock" component={Sellings} />
                      <Route
                        path="/admin/slaughter-report"
                        component={Reports}
                      />
                      <Route path="/admin/manage-tours" component={Tours} />
                      <Route path="/admin/live-stock" component={Tours} />
                      <Route path="/admin/my-tours" component={Tours} />
                      <Redirect to="/admin/home" />
                    </Switch>
                  ) : (
                    <Redirect to="/admin/home" />
                  )}
                </Switch>
              )}
              {role === 'ROLE_AGR_MANAGER' && (
                <Switch>
                  <Route path="/admin/setting" component={Setting} />
                  <Route path="/admin/contact" component={Contact} />
                  <Route path="/admin/messages" component={Messages} />
                  {subscription_type !== 'free' ? (
                    <Switch>
                      <Route path="/admin/new-stock" component={NewStock} />
                      <Route path="/admin/view-stock" component={ViewStock} />
                      <Route path="/admin/buy-stock" component={Buyings} />
                      <Route path="/admin/sell-stock" component={Sellings} />
                      <Route
                        path="/admin/reports"
                        component={ReportsAndSearch}
                      />

                      <Route
                        path="/admin/drug-administration"
                        component={DrugAdministration}
                      />
                      <Redirect to="/admin/home" />
                    </Switch>
                  ) : (
                    <Redirect to="/admin/home" />
                  )}
                </Switch>
              )}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Admin.propTypes = {
  users: PropTypes.object,
  panelTitle: PropTypes.object,
};

function mapStateToProps(state) {
  const { alert, panelTitle, users } = state.toJS();
  return { alert, panelTitle, users };
}

function mapDispatchToProps(dispatch) {
  return {
    handleTitleChange: (panelTitle) =>
      dispatch(adminActions.handleTitleChange(panelTitle)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
