import { userConstants } from '../constants';
import extractData from '../services/data.processing';
import { userService } from '../services/user.service';
import { userActions } from './user.actions';
export const refreshActions = {
  RefreshToken,
};

function RefreshToken() {
  return async (dispatch, getState) => {
    const state = getState().toJS();
    try {
      const value = await userService.refreshToken(
        'refresh_token',
        state.users.client.client_id,
        state.users.client.client_secret,
        state.users.token.refresh_token,
      );
      const NewtToken = await extractData(value);
      await dispatch({ type: userConstants.REFRESH_TOKEN, NewtToken });
    } catch (error) {
      await dispatch(userActions.logout(state.users.token.access_token));
    }
  };
}
