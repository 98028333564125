import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CattleGroupForm from './CattleGroupForm';
import CattleItem from './CattleItem';
import CattleAdd from './CattleAdd';
import DatePicker from 'react-datepicker';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import { svgPaths } from '../../../../constants';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import '../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import './cattle-group.scss';
import locale from '../../../../utils/DataPickerLang';

const CattleGroup = ({
  cattles,
  addCattleGroup,
  removeCattleGroup,
  editCattleGroup,
  cattleDeliveryDate,
  cattleDeliveryTime,
  getCattleDeliveryDate,
  getCattleDeliveryTime,
  getCattle,
  checkForm,
  status,
  ...props
}) => {
  const [showEditAddCattleForm, setShowEditCattleForm] = useState(false);
  const [cattle, setCattle] = useState({});
  const [deliveryDate, setCattleDeliveryDate] = useState(
    getStandardDate(cattleDeliveryDate),
  );
  const [deliveryTime, setCattleDeliveryTime] = useState(cattleDeliveryTime);

  const removeCattle = (evt, index) => {
    removeCattleGroup(evt, index);
  };

  const updateCattle = (index, cattle) => {
    editCattleGroup(index, cattle);
  };

  const editCattle = (evt, index) => {
    setCattle({ ...cattle, index: index, cattle_group: getCattle(index) });
    setShowEditCattleForm(true);
    checkForm(false);
  };
  const addCattle = (cattle) => {
    addCattleGroup(cattle);
  };
  const toggleAddForm = () => {
    setCattle({});
    setShowEditCattleForm(true);
    checkForm(false);
  };
  const hideEditForm = () => {
    setShowEditCattleForm(false);
    checkForm(true);
  };

  const handleDeliveryTime = (event) => {
    const { value } = event.target;
    setCattleDeliveryTime(value);
    getCattleDeliveryTime(value);
  };

  const formatToGermanDate = (date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear().toString().slice(-2);
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    return [day, month, year].join('.');
  };

  function getStandardDate(strDate) {
    var dates = strDate.split('.');
    return new Date(['20' + dates[2], dates[1], dates[0]].join('-'));
  }

  const handleDeliveryDate = (date) => {
    setCattleDeliveryDate(date);
    getCattleDeliveryDate(formatToGermanDate(date));
  };
  const datepickerRef = useRef(null);

  return (
    <>
      {showEditAddCattleForm ? (
        <CattleGroupForm
          addCattleGroup={addCattle}
          updateCattleGroup={updateCattle}
          oldCattle={cattle}
          hideEditForm={hideEditForm}
        />
      ) : (
        <div className="cattle-group-container">
          <div id="cattle-edit-times">
            <div className="last-item-calender buy-sell-calendar">
              <RegularInput
                picker
                label={<FormattedMessage {...messages.PreferedDeliveryDate} />}
                type="text"
                value={formatToGermanDate(deliveryDate)}
                name="cattleDeliveryDate"
                onChange={handleDeliveryDate}
                onClick={() => {
                  datepickerRef.current.setOpen(true);
                }}
              />
              <div className="date-field picker">
                <SvgIcon color="#9f9f9f" size="30" viewBox="0 0 600 600">
                  {svgPaths['calendar']}
                </SvgIcon>
                <DatePicker
                  selected={deliveryDate}
                  onChange={handleDeliveryDate}
                  dateFormat="dd.MM.yyyy"
                  ref={datepickerRef}
                  locale={locale}
                />
              </div>
            </div>
            <div className="cattle-date-time">
              <RegularInput
                label={<FormattedMessage {...messages.DeliveryUntil} />}
                type="time"
                value={deliveryTime}
                name="cattleDeliveryDate"
                onChange={handleDeliveryTime}
              />
            </div>
          </div>
          {cattles.map((cattle, index) => {
            return (
              <CattleItem
                key={index}
                status={status}
                cattle={cattle}
                removeCattleGroup={(evt) => removeCattle(evt, index)}
                editCattleGroup={(evt) => editCattle(evt, index)}
                index={index}
              />
            );
          })}
          {status === 'PLANNED' && <CattleAdd toggleAddForm={toggleAddForm} />}
        </div>
      )}
    </>
  );
};

CattleGroup.propTypes = {
  cattles: PropTypes.array,
  addCattleGroup: PropTypes.func,
  removeCattleGroup: PropTypes.func,
};

export default CattleGroup;
