import React, { Component } from 'react';
import InvoiceTable from './InvoiceTable';
import '../invoice.css';
import tableData from './data';

export default class InvoicePagination extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: 1,
      invoicePerPage: 10,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }
  render() {
    const { invoicePerPage } = this.state;
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(tableData.rows.length / invoicePerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
          key={number}
          id={number}
          onClick={this.handleClick}
          className={this.state.currentPage === number ? 'active' : ''}
        >
          {number}
        </li>
      );
    });

    return (
      <div>
        <InvoiceTable
          currentPage={this.state.currentPage}
          invoicePerPage={this.state.invoicePerPage}
        />
        <ul id="page-numbers">{renderPageNumbers}</ul>
      </div>
    );
  }
}
