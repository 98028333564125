import { configConstants } from '../constants';
import { put, post, get } from './request';
import isEmpty from 'lodash/isEmpty';
export async function getStocks(
  sort_filter,
  token,
  company_number,
  page = 0,
  breeds = [],
  races = [],
  filter,
) {
  try {
    const notEmptyBreeds =
      breeds.length > 0 ? breeds.filter((e) => !isEmpty(e)) : [];
    const notEmptyRaces =
      races.length > 0 ? races.filter((e) => !isEmpty(e)) : [];
    let url = `${configConstants.Stock}?sort=${sort_filter}&page=${page}&company_number=${company_number}`;
    if (notEmptyBreeds.length > 0) {
      url += `&breeds=${JSON.stringify(notEmptyBreeds)}`;
    }
    if (notEmptyRaces.length > 0) {
      url += `&races=${JSON.stringify(notEmptyRaces)}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    return get(url, token);
  } catch (error) {}
}

export async function getLastUpdateDate(token) {
  try {
    return get(`${configConstants.Stock}/last_update_date`, token);
  } catch (error) {}
}

export async function searchStocks(sort_filter, filter, token) {
  try {
    return get(
      `${configConstants.Stock}?sort=${sort_filter}&filter=${filter}`,
      token,
    );
  } catch (error) {}
}

export async function getStockFromLocal(token, ear_tag) {
  try {
    return get(`${configConstants.Stock}/eartags/${ear_tag}/search`, token);
  } catch (error) {}
}

export async function getStockFromHit(token, ear_tag) {
  try {
    return get(`${configConstants.Stock}/${ear_tag}/check`, token);
  } catch (error) {}
}

export async function sendStocksToHit(accessToken, stocks, files = []) {
  try {
    const contentType = 'multipart/form-data';
    const data = { stocks: JSON.stringify(stocks) };
    return post(
      `${configConstants.Stock}`,
      contentType,
      accessToken,
      data,
      files,
    );
  } catch (error) {}
}

export async function moveToNextStep(accessToken, ear_tags) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = { ear_tags: JSON.stringify(ear_tags) };
    return put(
      `${configConstants.Stock}/increase_box`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function createPdf(accessToken, ear_tags) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = { ear_tags: JSON.stringify(ear_tags) };
    return post(`${configConstants.Stock}/pdf`, contentType, accessToken, data);
  } catch (error) {}
}

export async function getPDFStock(accessToken, id) {
  try {
    const contentType = 'application/pdf';
    const resp = await get(
      `${configConstants.Stock}/pdf/${id}`,
      accessToken,
      contentType,
      'blob',
    );
    return new Blob([resp.data], { type: 'application/pdf;charset=utf-8' });
  } catch (error) {}
}

export async function getPassportStock(id, accessToken, passport_id) {
  try {
    if (passport_id) {
      const contentType = '';
      const resp = await get(
        `${configConstants.Stock}/${id}/passports/${passport_id}`,
        accessToken,
        contentType,
        'arraybuffer',
      );
      let contentTypee = resp.headers['content-type'];
      return (
        `data:${contentTypee};base64,` +
        Buffer.from(resp.data, 'binary').toString('base64')
      );
    }
  } catch (error) {}
}

export async function editStock(
  accessToken,
  stock_id,
  box,
  file = null,
  incoming_weight,
  selling_weight,
) {
  try {
    const contentType = 'multipart/form-data';
    let data = null;
    if (file != null) {
      data = { box: box, file: file };
    } else {
      data = {
        box: box,
        incoming_weight,
        selling_weight,
      };
    }
    return post(
      `${configConstants.Stock}/${stock_id}`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}

export async function getMedication(token, page = 0) {
  try {
    return get(
      `${configConstants.Stock}/drugs/medications?page=${page}`,
      token,
    );
  } catch (error) {}
}

export async function hitSynchronize(accessToken) {
  try {
    const contentType = 'application/json;charset=UTF-8';
    return post(`${configConstants.Stock}/sync`, contentType, accessToken);
  } catch (error) {}
}

export async function reportDeath(accessToken, ear_tags) {
  try {
    const contentType = 'application/json;charset=UTF-8';
    return put(
      `${configConstants.Stock}/eartags/death`,
      contentType,
      accessToken,
      ear_tags,
    );
  } catch (error) {}
}

export async function reportLeaving(accessToken, ear_tags) {
  try {
    const contentType = 'application/json;charset=UTF-8';
    return put(
      `${configConstants.Stock}/eartags/leave`,
      contentType,
      accessToken,
      ear_tags,
    );
  } catch (error) {}
}

export async function getCv(token, ear_tag) {
  try {
    return get(`${configConstants.Stock}/eartags/${ear_tag}/cv`, token);
  } catch (error) {}
}

export async function getdrugsPdf(accessToken, min_date, max_date) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = { min_date: min_date, max_date: max_date };
    const orders = await post(
      `${configConstants.Stock}/drugs/pdf`,
      contentType,
      accessToken,
      data,
    );

    return orders;
  } catch (error) {}
}

export async function getstockPdf(accessToken, min_date, max_date) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = { min_date: min_date, max_date: max_date };
    const orders = await post(
      `${configConstants.Stock}/stock_register`,
      contentType,
      accessToken,
      data,
    );

    return orders;
  } catch (error) {}
}

export async function getStockByIds(token, ear_tag) {
  try {
    return get(`${configConstants.Stock}/eartags/${ear_tag}/searchId`, token);
  } catch (error) {}
}
