import React from 'react';
import logo from '../../static/images/logo.png';
import './app.scss';

const BrowserSupport = () => {
  return (
    <div className="non-supported-screen">
      <div className="screen-container">
        <img className="agrabiz-logo" src={logo} alt="" />
        <div className="first-message">
          Sie verwenden für unsere Webanwendung einen nicht unterstützten
          Browser.
        </div>
        <a href="https://www.google.com/intl/de_de/chrome/">
          <div className="second-message">
            Sie können Google Chrome hier runterladen.
          </div>
        </a>
      </div>
    </div>
  );
};

export default BrowserSupport;
