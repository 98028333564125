import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from 'react-select';

import './regular-input.scss';

export const styles = {};

const propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.node,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.any,
  nature: PropTypes.string,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  requiredField: PropTypes.bool,
  theme: PropTypes.any,
  picker: PropTypes.bool,
  isMulti: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  inputGroup: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  components: PropTypes.any,
  maxLength: PropTypes.number,
  grey: PropTypes.bool,
  selectInputValue: PropTypes.any,
  inputStyle: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

const selectStyles = {
  singleValue: (provided, state) => {
    return { ...provided, color: '#646464' };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#7bd812',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#7bd812',
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: '',
    width: '100%',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    borderColor: ' transparent transparent #c6c6c6',
    '&:hover': { borderColor: 'transparent transparent #7bd812' },
  }),
};

const InputField = React.forwardRef(function InputField(props, ref) {
  const {
    autoComplete = 'off',
    autoFocus = false,
    isClearable = false,
    children,
    className,
    color = '#7bd811',
    defaultValue,
    disabled = false,
    error,
    id,
    InputProps,
    inputRef,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    rows,
    rowsMax,
    select = false,
    SelectProps,
    type,
    value,
    nature,
    options,
    isDisabled,
    requiredField,
    theme,
    picker,
    isMulti,
    onMenuOpen,
    inputGroup,
    closeMenuOnSelect,
    components,
    maxLength,
    grey,
    selectInputValue,
    touched,
    setFieldValue,
    earTags,
    showPopupEartgs,
    lang,
    ...other
  } = props;

  const prefix = select
    ? 'agrabiz-input agrabiz-select'
    : picker
    ? 'agrabiz-input agrabiz-picker'
    : 'agrabiz-input';

  const classes = clsx(prefix, isDisabled && 'disabled-select', grey && 'grey');

  const InputElement = (
    <input
      className="normal-input"
      placeholder=" "
      maxLength={maxLength}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      name={name}
      rows={rows}
      type={type}
      value={value}
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      disabled={disabled}
      {...other}
      required={required}
    />
  );

  const noop = () => {
    // no operation
  };

  const onChangeSelect = (value) => {
    onChange && onChange(value);
    setFieldValue && setFieldValue(name, value);
  };

  return (
    <div
      id="regular-input"
      className={clsx(classes, className)}
      disabled={disabled}
      color={color}
      components={components}
      {...other}
    >
      <div className="group">
        {select ? (
          <Fragment>
            <Select
              isClearable={isClearable}
              isMulti={isMulti}
              closeMenuOnSelect={closeMenuOnSelect}
              id={id}
              value={value}
              defaultValue={defaultValue}
              styles={selectStyles}
              options={options}
              required={required}
              onChange={onChangeSelect}
              onMenuOpen={onMenuOpen}
              placeholder={label ? '' : placeholder || 'Wählen'}
              isDisabled={isDisabled}
              blurInputOnSelect
              theme={theme}
              components={{
                IndicatorSeparator: () => null,
              }}
              {...InputProps}
            />
            {requiredField && (
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{
                  opacity: 0,
                  width: '100%',
                  height: 0,
                  position: 'absolute',
                }}
                value={selectInputValue}
                onChange={noop}
                required={required}
              />
            )}
            {!!error && touched && (
              <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>
            )}
          </Fragment>
        ) : (
          InputElement
        )}
        {inputGroup && <div className="group-icon">€</div>}
        {earTags && (
          <div className="group-iconGreen " onClick={showPopupEartgs}>
            {lang}
          </div>
        )}
        <span className="bar"></span>
        {label && <label>{label}</label>}
      </div>
    </div>
  );
});
InputField.displayName = 'InputField';
InputField.propTypes = propTypes;

export default InputField;
