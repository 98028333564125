import { invoiceConstants } from '../constants';

const initialState = {
  data: {
    items: [],
    count: 0,
  },
  customer: null,
  loading: false,
  error: null,
  editPayment: false,
};

export function invoices(state = initialState, action) {
  switch (action.type) {
    case invoiceConstants.INVOICE_GET_ALL:
      return {
        ...state,
        data: action.invoices,
      };

    case invoiceConstants.REQUEST_INVOICE_PAYMENT:
      return {
        ...state,
        loading: true,
        customer: null,
        error: null,
      };

    case invoiceConstants.INVOICE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.customer,
        error: null,
        editPayment: false,
      };
    case invoiceConstants.INVOICE_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        customer: null,
        loading: false,
      };

    case invoiceConstants.REQUEST_INVOICE_CUSTOMER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case invoiceConstants.INVOICE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.customer,
        error: null,
        editPayment: false,
      };
    case invoiceConstants.INVOICE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        editPayment: false,
      };
    case invoiceConstants.INVOICE_PAYMENT_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editPayment: true,
      };
    case invoiceConstants.INVOICE_PAYMENT_EDIT_CANCEL:
      return {
        ...state,
        editPayment: false,
      };

    default:
      return state;
  }
}
