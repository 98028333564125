import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { downloadSEPA } from '../../../../services/invoice.service';
import pdf from '../../../../static/images/admin/pdf.png';
import Loaded from '../../../../static/images/admin/loadIcon.png';

// export default ({ mandate, showForm, downloadPdf }) => {

class Mandate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formInvalid: true,
      showPopup: false,
      showPopupTwo: false,
      pdfId: '',
      AcceptPolices: false,
      AcceptCondition: false,
    };
  }

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  render() {
    return (
      <div className="form-container">
        <div className="mandateDiv">
          <div className="mandateDivB1">
            <div className="SEPA-Lastschriftsmandat-flex-pay">
              <div className="SEPA-Lastschriftsmandat-flex-b1-pay">
                <div className="tab-SEPA-Lastschriftsmandat-pat">
                  <div className="SEPA-text SEPA-text-pay ">
                    SEPA-Lastschriftsmandat
                  </div>
                </div>
              </div>
            </div>
            <div className="SEPA-Lastschriftsmandat-flex-pay">
              <div className="SEPA-Lastschriftsmandat-flex-b1-pay"></div>
              <div className="SEPA-Lastschriftsmandat-flex-b2">
                <div className="tab-SEPA-Lastschriftsmandat"> </div>
              </div>
            </div>
            <div
              className="SEPA-Lastschriftsmandat-flex-loaded-pay"
              onClick={(e) => this.props.goToStepTwoDirect()}
            >
              <div className="SEPA-Lastschriftsmandat-flex-b2">
                <div className="tab-SEPA-Lastschriftsmandat">
                  {' '}
                  <img src={Loaded} alt=""></img>
                </div>
              </div>
              <div className="SEPA-Lastschriftsmandat-flex-b1">
                <div className="tab-SEPA-Las-green tab-SEPA-Lastschriftsmandat">
                  {' '}
                  <FormattedMessage {...messages.UpdateSEPA} />
                </div>
              </div>
            </div>
          </div>
          <div className="mandateDivB2">
            <div className="form label credentials-btns ">
              <Col sm={9} className="form-label col-75">
                <Col sm={9}>
                  <Button
                    className="btn btn-lg btn-success btn-block"
                    onClick={(e) => this.props.showForm()}
                  >
                    <FormattedMessage {...messages.Usemethode} />
                  </Button>
                </Col>
              </Col>
            </div>
          </div>
        </div>
        {/* PDF popup*/}
        <Modal
          show={this.state.showPopup === true}
          onHide={this.handleClose}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdfCondition ">
            <embed
              width="100%"
              height="85%"
              frameBorder="0"
              src={this.state.pdfId}
              type="application/pdf"
            />
            <div className="btn-center-Accept"></div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mandate);
