/* eslint-disable no-unreachable */
/* eslint-disable default-case */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment-es6';
import {
  Col,
  Row,
  ListGroupItem,
  Image,
  Card,
  ButtonGroup,
  Button,
  FormGroup,
  ListGroup,
} from 'react-bootstrap';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import Bought from '../../../static/images/admin/Icons/Status icons/Bought.png';
import Delivered from '../../../static/images/admin/Icons/Status icons/Delivered.png';
import Loaded from '../../../static/images/admin/Icons/Status icons/Loaded.png';
import NegotiationBuyRequest from '../../../static/images/admin/Icons/Status icons/NegotiationBuyRequest.png';
import NegotiationSaleOffer from '../../../static/images/admin/Icons/Status icons/NegotiationSaleOffer.png';
import Offer from '../../../static/images/admin/Icons/Status icons/Offer.png';
import Offered from '../../../static/images/admin/Icons/Status icons/Offered.png';
import Ontheway from '../../../static/images/admin/Icons/Status icons/Ontheway.png';
import Rejected from '../../../static/images/admin/Icons/Status icons/Rejected.png';
import Request from '../../../static/images/admin/Icons/Status icons/Request.png';
import Resold from '../../../static/images/admin/Icons/Status icons/Resold.png';
import Slaughered from '../../../static/images/admin/Icons/Status icons/Slaughered.png';
import Sold from '../../../static/images/admin/Icons/Status icons/Sold.png';
import Tobeaccepted from '../../../static/images/admin/Icons/Status icons/Tobeaccepted.png';
import Tobeaccepted2 from '../../../static/images/admin/Icons/Status icons/Tobeaccepted2.png';
import Tobeloaded from '../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import Tobereconfirmed2 from '../../../static/images/admin/Icons/Status icons/Tobereconfirmed2.png';
import Tourplanning from '../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import Unloaded from '../../../static/images/admin/Icons/Status icons/Unloaded.png';
import extractData from '../../../services/data.processing';
import { OrderService } from '../../../services/order.service';
import { statusOrders } from '../Orders/status-orders';
import TabEvents from '../Orders/tabEvents';
import Bidders from '../Orders/bidders';
import TourManagement from '../Orders/tourMangement';
import ToBeLoaded from '../Orders/toBeLoaded';
import TourInfo from '../Orders/tourInfo';
import BuySellRequest from '../Orders/buySellRequest/BuySellRequest';
import ReSellRequest from '../Orders/Resell/ReSellRequest';
import { alertActions } from '../../../actions';
import './toursManagement.css';
import locale from '../../../utils/DataPickerLang';

class Tours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'active',
      date: moment().toDate(),
      theDay: '',
      ordersTab: [],
      stats: [],
      companyType: this.props.users.user.session.company_type,
      companyId: this.props.users.user.session.company_id,
      companyNumber: this.props.users.user.session.company_number,
      showDetail: false,
      showBidders: false,
      showTourInformation: false,
      showResell: false,
      showTourManagment: false,
      orderDetail: {},
      buyRequest: false,
      resellRequest: false,
      addToStockRequest: false,
      receiversTab: [],
      userId: this.props.users.client.user_id,
      bidderActivate: false,
      showStockForm: false,
      earTags: [],
      boughtActivate: false,
      showToBeLoaded: false,
      cursor: 1,
      hasMore: true,
      freight_included: 0,
      showTourInfo: false,
      truck: {},
      truckDetail: {},
      showPassports: true,
      itemBackground: null,
      alldates: [],
      showPassportList: false,
    };
  }
  days = [
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.sunday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.monday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.tuesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.wednesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.thursday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.friday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.saturday' }),
  ];

  componentDidMount() {
    this.calendarRef = React.createRef();
    this.setState({ theDay: this.days[moment().day()] });
    this.getToursDate(this.state.type);
  }

  handleChange = (e) => {
    e.preventDefault();
    this.getToursDate(e.target.value);
    this.setState({
      type: e.target.value,
    });
  };

  setShowForm = (action) => {
    const { bidderActivate } = this.state;
    if (bidderActivate) {
      this.setState({
        buyRequest: false,
        showDetail: false,
        showResell: false,
        showBidders: true,
        bidderActivate: false,
      });
    } else if (action === 'TOUR_INFORMATION') {
      this.setState({
        buyRequest: false,
        showDetail: false,
        showResell: false,
        showBidders: false,
        bidderActivate: false,
        showTourManagment: false,
      });
    } else {
      this.setState({
        buyRequest: false,
        showDetail: false,
        showResell: false,
        showBidders: false,
      });
    }
  };

  showPassportForTourInfo = (value) => {
    this.setState({ showPassportList: value });
  };

  handleCalendar = () => {
    this._calendar.setOpen(true);
  };

  dateChange = async (date) => {
    await this.setState({
      date: moment(date).toDate(),
      theDay: this.days[moment(date).day()],
      page: 0,
      ordersTab: [],
    });
    await this.setState({ ordersTabAll: [] });
    await this.getToursDate(this.state.type);
  };

  convertDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = day + '.' + month + '.' + year;

    let nameSplit = formatted.split('.');
    // new date format 01.01.20
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = day + '.' + month + '.' + nameSplit[2].toString().substr(-2);

    return newDate;
  };

  getOrders = async () => {
    try {
      const { type } = this.state;
      await this.setState({
        page: 0,
        ordersTab: [],
        ordersTabAll: [],
        hasMore: true,
      });
      // get all dates and select
      if (type === 'completed' || type === 'active') {
        if (typeof this.state.date !== 'undefined')
          await this.getToursDate(this.state.type);
      }
    } catch (e) {}
  };

  getToursDate = async (type) => {
    const data = await OrderService.getToursDate(
      this.props.users.accessToken,
      type,
      this.convertDate(this.state.date),
      this.props.users
    );
    if (
      typeof data !== 'undefined' &&
      typeof data.status !== 'undefined' &&
      data.status === 200
    ) {
      const orders = await extractData(data);
      this.setState({
        ordersTab: orders.data,
        stats: orders.stats,
      });
      if (typeof orders.dates !== 'undefined' && orders.dates.length > 0) {
        let ordersDates = [];
        for (let i = 0; i < orders.dates.length; i++) {
          ordersDates.push(new Date(orders.dates[i].split('-').join(', ')));
        }
        this.setState({
          alldates: ordersDates,
          page: 0,
        });
      }
    }
  };

  handleChangeDate = async (date, control) => {
    let tomorrow;
    if (control === 'up') {
      tomorrow = moment(date).add(1, 'days');
    } else {
      tomorrow = moment(date).add(-1, 'days');
    }
    await this.setState({
      theDay: this.days[tomorrow.day()],
      date: tomorrow.toDate(),
      page: 0,
      ordersTab: [],
      ordersTabAll: [],
    });
    await this.getToursDate(this.state.type);
  };

  getSrcImg = (status, receivers_size, topic, is_buyer) => {
    var res = status.split('_').join('');
    res = res.toLowerCase();
    switch (res) {
      case 'bought':
        return Bought;
        break;
      case 'delivered':
        return Delivered;
        break;
      case 'loaded':
        return Loaded;
        break;
      case 'negotiationbuyrequest':
        return NegotiationBuyRequest;
        break;
      case 'negotiation':
        return NegotiationBuyRequest;
        break;
      case 'negotiationsaleoffer':
        return NegotiationSaleOffer;
        break;
      case 'offer':
        return Offer;
        break;
      case 'offered':
        return Offered;
        break;
      case 'ontheway':
        return Ontheway;
        break;
      case 'rejected':
        return Rejected;
        break;
      case 'requested':
        if (receivers_size > 1) return NegotiationBuyRequest;
        else return Request;
        break;
      case 'resold':
        return Resold;
        break;
      case 'slaughtered':
        return Slaughered;
        break;
      case 'sold':
        return Sold;
        break;
      case 'tobeaccepted':
        return Tobeaccepted;
        break;
      case 'tobeaccepted2':
        return Tobeaccepted2;
        break;
      case 'tobeloaded':
        return Tobeloaded;
        break;
      case 'tobereconfirmed':
        if (is_buyer === 1 && topic !== 'BUY_STOCK') return Tobereconfirmed2;
        else return Tobeaccepted;
        break;
      case 'tobereconfirmed2':
        return Tobereconfirmed2;
        break;
      case 'tourplanning':
        return Tourplanning;
        break;
      case 'tourassigned':
        return Tourplanning;
        break;
      case 'unloaded':
        return Unloaded;
        break;
    }
  };

  toggleBackground = (position) => {
    if (this.state.itemBackground === position) {
      this.setState({ itemBackground: null });
    } else {
      this.setState({ itemBackground: position });
    }
  };

  backgroundChange = (position) => {
    if (this.state.itemBackground === position) {
      return '#f0fae3';
    }
    return '';
  };

  getToursDetails = async (item) => {
    let token = this.props.users.accessToken;
    let value = await OrderService.getTours(token, item.order_id, "", this.props.users);
    if (value.status === 200) {
      const data = await extractData(value);
      if (typeof data[0] !== 'undefined') {
        if (data.length === 1) {
          await this.setState({
            toursDetails: data,
            Nextscreen: data[0].screen,
          });
        }
        if (data.length > 1) {
          let fetchscreen = [];
          data.forEach((item) => {
            item.screen === 'LOADING_DATA' && fetchscreen.push('LOADING_DATA');
          });
          await this.setState({
            toursDetails: data,
            Nextscreen: fetchscreen.length > 0 ? 'LOADING_DATA' : 'TOUR_INFO',
          });
        }
      }
    }
  };

  getToScreen = async () => {
    this.setState({
      showBidders: true,
      showDetail: false,
      buyRequest: false,
      showTourManagment: false,
      showToBeLoaded: false,
      showTourInfo: false,
    });
    if (this.state.Nextscreen === 'LOADING_DATA')
      this.setState({
        showDetail: false,
        showResell: false,
        showBidders: false,
        buyRequest: false,
        showTourManagment: false,
        showToBeLoaded: true,
        showTourInfo: false,
      });

    if (this.state.Nextscreen === 'TOUR_INFO')
      this.setState({
        showDetail: false,
        showResell: false,
        showBidders: false,
        buyRequest: false,
        showTourManagment: false,
        showToBeLoaded: false,
        showTourInfo: true,
      });
  };

  showDetailForTour = (truck, truckDetail) => {
    this.setState({
      showTourInfo: false,
      showDetail: true,
      truck,
      truckDetail,
    });
  };

  showDetail = async (item, status) => {
    if (item.receivers_size > 1) {
      this.setState({
        showBidders: true,
        showDetail: false,
        buyRequest: false,
        showTourManagment: false,
        showToBeLoaded: false,
        showTourInfo: false,
      });
      await this.getReceivers(item.order_id);
    } else if (status === 'TOUR_PLANNING') {
      item.is_tour_planning_responsible === 1
        ? this.setState({
            showTourInformation: true,
            showResell: false,
            showTourManagment: true,
            showBidders: false,
            showDetail: false,
            buyRequest: false,
            showToBeLoaded: false,
            showTourInfo: false,
          })
        : this.setState({
            showTourInformation: false,
            showResell: false,
            showTourManagment: false,
            showBidders: false,
            showDetail: true,
            buyRequest: false,
            showToBeLoaded: false,
            showTourInfo: false,
          });
    } else if (status === 'TOUR_ASSIGNED') {
      item.is_tour_planning_responsible === 1 || item.assigned === 1
        ? this.setState({
            showTourInformation: true,
            showResell: false,
            showTourManagment: true,
            showBidders: false,
            showDetail: false,
            buyRequest: false,
            showToBeLoaded: false,
            showTourInfo: false,
          })
        : this.setState({
            showDetail: false,
            showBidders: false,
            buyRequest: false,
            showTourManagment: false,
            showToBeLoaded: false,
            showTourInfo: true,
          });
    } else if (status === 'TO_BE_LOADED') {
      this.getToursDetails(item).then(() => {
        this.getToScreen();
      });
    } else if (
      ['LOADED', 'ON_THE_WAY', 'DELIVERED', 'UNLOADED', 'SLAUGHTERED'].includes(
        status,
      )
    ) {
      this.getToursDetails(item).then(() => {
        this.getToScreen();
      });
    } else {
      this.setState({
        showDetail: true,
        showResell: false,
        showBidders: false,
        buyRequest: false,
        showTourManagment: false,
        showToBeLoaded: false,
        showTourInfo: false,
      });
    }
    this.setState({ orderDetail: item });
  };

  handleClosePanel = () => {
    this.setState({
      showBidders: false,
      showResell: false,
      showDetail: false,
      buyRequest: false,
      showTourManagment: false,
      showToBeLoaded: false,
      showTourInfo: false,
    });
  };

  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  colorIcon = (item) => {
    if (
      ['LOADED', 'TO_BE_LOADED', 'TOUR_PLANNING', 'TOUR_ASSIGNED'].includes(
        item.status,
      )
    )
      return 'grey';
    if (item.status === 'ON_THE_WAY') return 'yellow';
    else {
      return 'green';
    }
  };

  getCountType = (type) => {
    let stats;
    if (
      typeof this.state !== 'undefined' &&
      typeof this.state.stats !== 'undefined'
    ) {
      stats = this.state.stats.filter(function (item) {
        return item.status === type;
      });
      if (typeof stats[0] !== 'undefined') {
        return stats[0].count;
      }
    }
    return 0;
  };

  getStyle = (seller) => {
    const style =
      seller > 7 ? { marginLeft: '-124px' } : { marginLeft: '-180px' };
    return style;
  };

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  renderListOrders = () => {
    return (
      typeof this.state !== 'undefined' &&
      typeof this.state.ordersTab !== 'undefined' &&
      typeof this.state.ordersTab === 'object' &&
      this.state.ordersTab.map(
        (item, i) =>
          typeof item !== 'undefined' && item !== null && item.status !== null && typeof item.status !== 'undefined' && (
            <ListGroupItem
              key={i}
              className="list-groupp"
              style={{ background: this.backgroundChange(i) }}
              onClick={() => this.toggleBackground(i)}
            >
              <Row onClick={(evt) => this.showDetail(item, item.status)}>
                <Col md={3} className="status">
                  {item.status === 'BOUGHT' && item.resold === 0 && (
                    <div id="checkbox1">
                      <label key={item.key} className="container">
                        <input
                          type="checkbox"
                          key={item.order_id}
                          onClick={(e) =>
                            this.handleCheckedChange(e, item.order_id)
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}

                  <Image
                    className="status-image"
                    src={this.getSrcImg(
                      item.status,
                      item.receivers_size,
                      item.topic,
                      item.is_buyer,
                    )}
                  />
                  <p className="status-name">
                    {
                      statusOrders.find(
                        (val) =>
                          item.status.split('_').join(' ') === val.statusOrder,
                      ).label
                    }
                  </p>
                </Col>

                <Col md={7} className="content">
                  <p className={clsx(this.colorIcon(item))}>
                    {item.delivery_until} {item.partner}
                  </p>
                  <p className="info-item">
                    {this.findAndReplace(item.name)} | {item.vehicle_number} | {item.driver_name}
                  </p>
                </Col>
                <Col md={2} className="MdNavigateNext">
                  <p className="shared">
                    <MdNavigateNext color="#cdcbcb" />
                  </p>
                </Col>

                {/* <Col md={7} /> */}
              </Row>
              <hr className="style-border" />
            </ListGroupItem>
          ),
      )
    );
  };

  render() {
    const {
      companyType,
      showDetail,
      showResell,
      orderDetail,
      companyId,
      companyNumber,
      receiversTab,
      showBidders,
      buyRequest,
      userId,
      date,
      theDay,
      showTourManagment,
      showToBeLoaded,
      showTourInfo,
      truck,
      truckDetail,
      showPassports,
      ordersTab,
      showPassportList,
    } = this.state;

    orderDetail.receiver_id = truckDetail.receiver_id;

    return (
      <Row className="orders">
        <Col className="orders-col" md={6} sm={12}>
          <Card className="card-box left-box">
            <Card.Body>
              <Row className="panel-body-row">
                <Col className="panel-body-col" xs={12} md={12}>
                  <ButtonGroup
                    value={this.state.type}
                    onClick={this.handleChange}
                    className="order-btn-groupe"
                  >
                    <div className="button-groupe-wrapper">
                      <Button
                        className={this.state.type === 'active' ? 'active' : ''}
                        value={'active'}
                      >
                        {this.props.language.locale === 'DE'
                          ? 'Aktive Touren'
                          : 'Active'}{' '}
                        ({this.getCountType('active')})
                      </Button>
                      <Button
                        className={
                          this.state.type === 'completed' ? 'active' : ''
                        }
                        value={'completed'}
                      >
                        {this.props.language.locale === 'DE'
                          ? 'Abgeschlossen'
                          : 'Completed'}{' '}
                        ({this.getCountType('completed')})
                      </Button>
                    </div>
                  </ButtonGroup>
                  <FormGroup
                    controlId="formInlineName"
                    className="user-input-wrp date-time"
                  >
                    <div className="date-picker-wrapper">
                      <div
                        className="date-picker-icon"
                        onClick={() => {
                          this.calendarRef.current.setOpen(true);
                        }}
                      ></div>
                      <div className="the-day">{theDay}</div>

                      <DatePicker
                        selected={date}
                        onChange={this.dateChange}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRef}
                        highlightDates={this.state.alldates}
                        onChangeRaw={this.handleDateChangeRaw}
                        locale={locale}
                      />
                      <button
                        className="button-up"
                        onClick={() => this.handleChangeDate(date, 'down')}
                      >
                        <i className="down" />
                      </button>
                      <button
                        className="button-down"
                        onClick={() => this.handleChangeDate(date, 'up')}
                      >
                        <i className="up" />
                      </button>
                    </div>
                    <hr className="style-border" />
                    <ListGroup id="buy-sell">
                      <ul className="">{this.renderListOrders()}</ul>
                    </ListGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {(showDetail || showBidders || buyRequest || showResell) && (
          <Col style={{ height: '100%' }} md={6} sm={12}>
            <Card className="buy-sell card-box">
              <div className="navigate-before">
                <div md={3}>
                  <MdNavigateBefore
                    className="before"
                    onClick={this.setShowForm}
                  />
                </div>
              </div>
              <Card.Body className="order-panel">
                {showDetail && (
                  <TabEvents
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                    orderDetail={orderDetail}
                    companyId={companyId}
                    companyNumber={companyNumber}
                    companyType={companyType}
                    userId={userId}
                    getSrcImg={this.getSrcImg}
                    truck={truck}
                    truckDetail={truckDetail}
                    emptyTruck={this.emptyTruck}
                    handleCloseToBeload={this.handleCloseToBeload}
                  />
                )}
                {showBidders && (
                  <Bidders
                    getSrcImg={this.getSrcImg}
                    companyId={companyId}
                    item={orderDetail}
                    receiversTab={receiversTab}
                    getReceivers={this.getReceivers}
                    showOrderDetail={this.showOrderDetail}
                  />
                )}
                {buyRequest && (
                  <BuySellRequest
                    orderDetail={orderDetail}
                    companyId={companyId}
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                  />
                )}
                {showResell && (
                  <ReSellRequest
                    orderDetail={orderDetail}
                    companyId={companyId}
                    orders={this.state.toResellList}
                    getEmpty={this.getEmpty}
                    handleClosePanel={this.handleClosePanel}
                    getOrders={this.getOrders}
                    checked={this.checked}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
        {showTourInfo && (
          <TourInfo
            orderDetail={orderDetail}
            companyId={companyId}
            handleClosePanel={this.handleClosePanel}
            showDetailForTour={this.showDetailForTour}
            showPassportList={showPassportList}
            showPassportForTourInfo={this.showPassportForTourInfo}
            showPassports={showPassports}
            getOrders={this.getOrders}
            truckId={orderDetail._id}
            toursDetails={this.state.toursDetails}
            getToursDate={this.getToursDate}
          />
        )}
        {showTourManagment && (
          <TourManagement
            orderDetail={orderDetail}
            ordersTab={ordersTab}
            setShowForm={this.setShowForm}
            type={this.state.type}
            handleClosePanel={this.handleClosePanel}
            getOrders={this.getOrders}
            assigned={true}
            getToursDate={this.getToursDate}
          />
        )}
        {showToBeLoaded && (
          <ToBeLoaded
            orderDetail={orderDetail}
            handleClosePanel={this.handleClosePanel}
            getOrders={this.getOrders}
          />
        )}
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, buyings, sellings, language } = state.toJS();
  return {
    users,
    buyings,
    sellings,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Tours));
