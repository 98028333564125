import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Modal, InputGroup, FormControl, Button } from 'react-bootstrap';
import Switch from 'react-switch';
import {
  editPassword,
  editPin,
  usePassword,
} from '../../../../actions/company.actions';
import extractData from '../../../../services/data.processing';
import { getCompany } from '../../../../services/company.service';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../components/Admin/messages';
import { specificRoles } from '../../../../constants';

import '../setting.css';
import editIcon from '../../../../static/images/admin/icons_final/editIcon.svg';
class FormPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      validationState: null,
      hitPassword: 0,
      showPin: false,
      showPassword: false,
      current_Pin: '',
      new_Pin: '',
      current_password: '',
      new_password: '',
      confirm_password: '',
      passworderror: '',
    };
  }

  handleSubmitPassword = () => {
    return this.state.hitPassword === 1
      ? this.props.usePassword(0)
      : this.props.usePassword(1);
  };

  handleCheckbox = (checked) => {
    this.setState({
      checked,
      hitPassword: checked ? 1 : 0,
    });
  };

  componentDidMount() {
    this.getCompany();
  }

  getCompany = async () => {
    let token = this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const user = await extractData(value);
      if (typeof user !== 'undefined') {
        this.setState({ checked: user.use_hit_pin_as_password });
      }
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'current_Pin':
        this.setState({ current_Pin: value });
        break;
      case 'new_Pin':
        this.setState({ new_Pin: value });
        break;
      case 'current_password':
        this.setState({ current_password: value });
        break;
      case 'new_password':
        this.setState({ new_password: value });
        break;
      case 'confirm_password':
        this.setState({ confirm_password: value, passworderror: '' });
        break;
      default:
        break;
    }
  };

  // new Pin

  registerNewPin = () => {
    if (
      this.state.current_Pin !== '' &&
      this.state.new_Pin !== '' &&
      this.state.hitPassword !== ''
    )
      this.props.editPin(
        this.state.current_Pin,
        this.state.new_Pin,
        this.state.hitPassword,
      );
    this.togglePopupPin();
  };

  handlePopUpPin = () => {
    this.setState({ showPin: true });
  };

  togglePopupPin = () => {
    this.setState({ showPin: false });
  };

  renderNewPin = () => {
    return (
      <React.Fragment>
        <Modal
          className="password-Popup-modal"
          show={this.handlePopUpPin}
          onHide={this.togglePopupPin}
          style={{ opacity: 1 }}
        >
          <Modal.Header style={{ height: '5%' }} closeButton>
            {/* <div className="Modal-add-truck-title">
              <h5 className="card-title new-transport"> </h5>
            </div>
            <Modal.Title /> */}
          </Modal.Header>
          <Modal.Body className="">
            <Form onSubmit={this.registerNewPin} style={{ width: '100%' }}>
              <div className="view-date-one">
                <RegularInput
                  label={<FormattedMessage {...messages.Current_HIT_PIN} />}
                  // value={amount}
                  type="password"
                  name="current_Pin"
                  style={{ width: '100%' }}
                  required
                  onChange={(e) => this.handleChange(e)}
                />
                <RegularInput
                  label={<FormattedMessage {...messages.New_HIT_PIN} />}
                  // value={amount}
                  type="password"
                  name="new_Pin"
                  style={{ width: '100%' }}
                  required
                  onChange={(e) => this.handleChange(e)}
                />
                <div className="new-cards-owner-inner hit-use-switch">
                  <div md={4} className=" new-cards-owner-inner-b1-switch">
                    <FormattedMessage {...messages.UseHitMessage} />
                  </div>
                  <div
                    md={8}
                    id="settings-inner-card"
                    className="new-cards-owner-inner-b2-switch"
                  >
                    <Switch
                      onColor="#7bd812"
                      onChange={this.handleCheckbox}
                      checked={!!this.state.checked}
                      className="react-switch"
                      id="normal-switch-two"
                    />
                  </div>
                </div>
                <div style={{ marginBottom: '-6%' }} className="button-txt">
                  <Button
                    className="btn-filter"
                    type="submit"
                    disabled={
                      !(
                        this.state.current_Pin !== '' &&
                        this.state.new_Pin !== ''
                      )
                    }
                  >
                    <FormattedMessage {...messages.Save} />
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };
  // new password
  registerNewPassword = () => {
    if (this.state.new_password !== this.state.confirm_password) {
      this.setState({ passworderror: 'error', confirm_password: '' });
      return;
    } else {
      if (
        this.state.current_password !== '' &&
        this.state.new_password !== '' &&
        this.state.confirm_password !== ''
      ) {
        this.props.editPassword(
          this.state.current_password,
          this.state.new_password,
          this.state.hitPassword,
        );
        this.togglePopupPassword();
      }
    }
  };

  handlePopUpPassword = () => {
    this.setState({ showPassword: true });
  };

  togglePopupPassword = () => {
    this.setState({ showPassword: false });
  };

  fetchError = () => {
    this.setState({ passworderror: '' });
  };
  renderNewPassword = () => {
    return (
      <React.Fragment>
        <Modal
          className="password-Popup-modal"
          show={this.handlePopUpPassword}
          onHide={this.togglePopupPassword}
        >
          <Modal.Header style={{ height: '5%' }} closeButton></Modal.Header>
          <Modal.Body className="">
            <Form onSubmit={this.registerNewPassword} style={{ width: '100%' }}>
              <div className="view-date-one">
                <RegularInput
                  label={<FormattedMessage {...messages.Current_password} />}
                  // value={amount}
                  type="password"
                  name="current_password"
                  style={{ width: '100%' }}
                  required
                  onChange={(e) => this.handleChange(e)}
                />
                <RegularInput
                  label={<FormattedMessage {...messages.New_password} />}
                  // value={amount}
                  type="password"
                  name="new_password"
                  style={{ width: '100%' }}
                  required
                  onChange={(e) => this.handleChange(e)}
                />
                <RegularInput
                  label={<FormattedMessage {...messages.Confirm_password} />}
                  // value={amount}
                  type="password"
                  name="confirm_password"
                  style={{ width: '100%' }}
                  required
                  value={this.state.confirm_password}
                  onChange={(e) => this.handleChange(e)}
                />
                {this.state.passworderror !== '' && (
                  <div style={{ color: 'red' }} className="new-cards-owner ">
                    {' '}
                    <FormattedMessage {...messages.Identicale_password} />{' '}
                  </div>
                )}
                <div className="button-txt">
                  <Button
                    disabled={
                      !(
                        this.state.confirm_password !== '' &&
                        this.state.new_password !== '' &&
                        this.state.current_password !== ''
                      )
                    }
                    className="btn-filter"
                    type="submit"
                  >
                    <FormattedMessage {...messages.Save} />
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };
  render() {
    let { role } = this.props.users.user;
    return (
      <Form>
        {specificRoles.ROLE_FOR_ROLE_COMPANY_AGR_TRD_MAN.indexOf(role) <= -1 ? (
          <div>
            <div className="new-cards-owner ">
              <div className="new-cards-owner-inner">
                <div md={4} className=" new-cards-owner-inner-b1">
                  <FormattedMessage {...messages.Hit_pin} />
                </div>
                <div
                  md={8}
                  id="settings-inner-card"
                  className="new-cards-owner-inner-b2 password-form"
                >
                  <div className="password-Setting">
                    <div>
                      <InputGroup>
                        <FormControl
                          size="small"
                          type="password"
                          minLength={50}
                          maxLength={50}
                          name="search"
                          onChange={this.filterByText}
                          className="search"
                          placeholder="****"
                          value="****"
                          required
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                    <div>
                      <img
                        src={editIcon}
                        className="img-settingpassword"
                        alt=""
                        onClick={this.handlePopUpPin}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="new-cards-owner-inner-switch">
                <div
                  md={4}
                  className="new-cards-owner-inner-b1-switch new-margin"
                >
                  <FormattedMessage {...messages.UseHitMessage} />
                </div>
                <div
                  md={8}
                  id="settings-inner-card"
                  className="new-cards-owner-inner-b2-switch"
                >
                  <Switch
                    onColor="#7bd812"
                    onChange={this.handleCheckbox}
                    checked={!!this.state.checked}
                    className="react-switch"
                    id="normal-switch"
                  />
                </div>
              </div>
              {!this.state.checked && (
                <div className="new-cards-owner-inner">
                  <div md={4} className=" new-cards-owner-inner-b1">
                    <FormattedMessage {...messages.Agrabiz_Password} />
                  </div>
                  <div
                    md={8}
                    id="settings-inner-card"
                    className="new-cards-owner-inner-b2"
                  >
                    <div className="password-Setting">
                      <div>
                        <InputGroup>
                          <FormControl
                            size="small"
                            type="password"
                            minLength={50}
                            maxLength={50}
                            name="search"
                            onChange={this.filterByText}
                            className="search"
                            placeholder="****"
                            value="****"
                            required
                            disabled={true}
                          />
                        </InputGroup>
                      </div>
                      <div>
                        <img
                          src={editIcon}
                          className="img-settingpassword"
                          alt=""
                          onClick={this.handlePopUpPassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>{this.state.showPin && this.renderNewPin()}</div>
            <div>{this.state.showPassword && this.renderNewPassword()}</div>
          </div>
        ) : (
          <div>
            <div className="new-cards-owner ">
              <div className="new-cards-owner-inner">
                <div md={4} className=" new-cards-owner-inner-b1">
                  <FormattedMessage {...messages.Agrabiz_Password} />
                </div>
                <div
                  md={8}
                  id="settings-inner-card"
                  className="new-cards-owner-inner-b2"
                >
                  <div className="password-Setting">
                    <div>
                      <InputGroup>
                        <FormControl
                          size="small"
                          type="password"
                          minLength={50}
                          maxLength={50}
                          name="search"
                          onChange={this.filterByText}
                          className="search"
                          placeholder="****"
                          value="****"
                          required
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                    <div>
                      <img
                        src={editIcon}
                        className="img-settingpassword"
                        alt=""
                        onClick={this.handlePopUpPassword}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>{this.state.showPin && this.renderNewPin()}</div>
            <div>{this.state.showPassword && this.renderNewPassword()}</div>
          </div>
        )}
      </Form>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    editPassword: (password, useHitPassword, current_password) =>
      dispatch(editPassword(password, useHitPassword, current_password)),
    editPin: (password, useHitPassword, current_pin) =>
      dispatch(editPin(password, useHitPassword, current_pin)),
    usePassword: (useHitPassword) => dispatch(usePassword(useHitPassword)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPassword);
