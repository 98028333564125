/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Col, Button, Modal } from 'react-bootstrap';
import messages from '../../messages';
import '../invoice.css';
import docum from '../../../../static/pdf/Agrabiz Service_Terms And Conditions_DE.pdf';

class FormCreditCard extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.form = React.createRef();
    this.state = {
      showInfoForm: false,
      showPopup: false,
      showForm: false,
    };
  }

  handleClose = () => this.setState({ showPopup: false });
  handleOpen = () => this.setState({ showPopup: true });
  ShowCard = () => {
    return this.setState({ showInfoForm: true });
  };
  MollieCardPaiment = () => {
    return (
      <div>
        <FormattedMessage {...messages.MolliePayement1} />
        {'  '}
        <a
          href="https://www.mollie.com/de/impressum"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mollie B.V.
          {'  '}
        </a>
        <FormattedMessage {...messages.MolliePayement2} />
        <br />
        <br />
        <FormattedMessage {...messages.MolliePayement3} />
      </div>
    );
  };
  MollieSEPAPayement = () => {
    return (
      <div>
        <FormattedMessage {...messages.MollieSEPAPayement1} />
        {'  '}
        <a
          href="https://www.mollie.com/de/impressum"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mollie B.V.
          {'  '}
        </a>
        <FormattedMessage {...messages.MollieSEPAPayement2} />
        <br />
        <br />
        <FormattedMessage {...messages.MollieSEPAPayement3} />
      </div>
    );
  };
  render() {
    const { onSubmit, method, returnToMenu, loading, error } = this.props;
    const mollieText =
      method === 'creditcard'
        ? this.MollieCardPaiment()
        : this.MollieSEPAPayement();

    return (
      <>
        {!this.state.showInfoForm ? (
          <form
            style={{ marginTop: '100px' }}
            ref={this.form}
            onSubmit={e => {
              e.preventDefault();
              onSubmit({ method });
            }}
          >
            <div className="credentials-inp credentials-input1">
              <div className="credentials-inp-b1">
                <div className="form-description-b1">
                  <div className="form-description-b1-Bloc">{mollieText}</div>
                  <div className="form-description-b1-Bloc-chech">
                    <div>
                      <input name="tearms" type="checkbox" required />
                    </div>
                    <div>
                      <span className="tearms">
                        <FormattedMessage {...messages.TermsPart1} />{' '}
                        <a
                          onClick={() => this.handleOpen()}
                          style={{ color: '#7bd812', fontWeight: 'bold' }}
                        >
                          <FormattedMessage {...messages.Terms} />
                        </a>{' '}
                        <FormattedMessage {...messages.TermsPart2} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="credentials-inp-b2">
                <div className="form label credentials-btns ">
                  {error && <div className="text-danger">{error}</div>}
                  <Col sm={8} className="form-label col-75">
                    <Col sm={6}>
                      <Button
                        disabled={loading}
                        className="btn btn-lg btn-success btn-block"
                        onClick={e => returnToMenu()}
                      >
                        {loading ? (
                          ' Loading...'
                        ) : (
                          <FormattedMessage {...messages.Cancel} />
                        )}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <button
                        disabled={loading}
                        className="btn btn-lg btn-success btn-block"
                      >
                        {loading ? (
                          ' Loading...'
                        ) : (
                          <FormattedMessage {...messages.Confirm} />
                        )}
                      </button>
                    </Col>
                  </Col>
                </div>
                <Modal
                  show={this.state.showPopup}
                  onHide={this.handleClose}
                  style={{ opacity: 1 }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <FormattedMessage {...messages.TermsAndConditions} />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modal-pdf ">
                    <embed
                      src={docum}
                      frameBorder="0"
                      width="100%"
                      height="90%"
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
      </>
    );
  }
}

FormCreditCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  method: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

FormCreditCard.defaultProps = {
  method: 'creditcard',
  loading: false,
};
export default FormCreditCard;
