import React from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../../../CustomSelect';
import clsx from 'clsx';
import './multiselect.scss';
const styles = {
  singleValue: (provided, state) => {
    return { ...provided, color: '#646464' };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#999',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#7bd812',
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: '',
    width: '100%',
    borderWidth: '1px',
    borderColor: ' transparent transparent #eee',
    '&:hover': { borderColor: 'transparent transparent #7bd812' },
  }),
};
const MultiSelect = ({
  name,
  value,
  options,
  onChange,
  isMulti,
  isSearchable,
  isClearable,
  filled,
  label,
  closeMenuOnSelect,
  className,
  ...props
}) => (
  <div
    id="regular-input"
    className={clsx(filled && 'filled-multiselect', 'group', className)}
  >
    <CustomSelect
      label
      value={value}
      isMulti={isMulti}
      name={name}
      options={options}
      closeMenuOnSelect={closeMenuOnSelect}
      onChange={onChange}
      isSearchable={isSearchable}
      isClearable={isClearable}
      placeholder={label ? '' : 'Wählen'}
      styles={styles}
      {...props}
    />
    <span className="bar"></span>
    {label && <label>{label}</label>}
  </div>
);
MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  filled: PropTypes.bool,
  label: PropTypes.any,
  className: PropTypes.string,
};

MultiSelect.defaultProps = {
  name: '',
  isMulti: false,
  isSearchable: true,
  isClearable: true,
};

export default MultiSelect;
