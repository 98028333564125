// import fetchIntercept from 'fetch-intercept';
import axios from 'axios';
import store from '../index';
import { refreshActions } from '../actions/refreshToken.action';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // const refreshToken = window.localStorage.getItem('refreshToken');
      return new Promise(async function (resolve, reject) {
        try {
          await store.dispatch(refreshActions.RefreshToken());
          const state = store.getState().toJS();
          const isConnected = state.users.isConnected;
          if (!isConnected) {
            return;
          }
          const newToken = state.users.accessToken;
          if (
            typeof axios.defaults.headers.common !== 'undefined' &&
            typeof axios.defaults.headers.common['Authorization'] !==
              'undefined'
          ) {
            axios.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${newToken}`;
          }
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          processQueue(null, newToken);
          resolve(axios(originalRequest));
        } catch (err) {
          processQueue(err, null);
          reject(err);
        } finally {
          isRefreshing = false;
        }
      });
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return error.response;
  },
);

export async function post(
  url,
  contentType,
  accessToken,
  data = {},
  files = [],
) {
  let headers = {};
  let body;
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  if (contentType.includes('application/json')) {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    };

    body = JSON.stringify(data);
  } else if (
    contentType.includes('application/x-www-form-urlencoded;charset=UTF-8')
  ) {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
    let formBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    body = formBody.join('&');
  } else if (contentType.includes('application/x-www-form-urlencoded')) {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let formBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    body = formBody.join('&');
  } else if (contentType.includes('multipart/form-data')) {
    headers = {
      ...headers,
    };

    const formData = new FormData();
    for (var name in data) {
      formData.append(name, data[name]);
    }

    if (files.length > 0) {
      for (var index in files) {
        formData.append(
          '10101010',
          files[index],
          files[index].name.split('.')[0],
        );
      }
    }

    body = formData;
  }

  return await axios(url, {
    method: 'POST',
    headers,
    data: body,
  });
}

export async function put(url, contentType, accessToken, data) {
  let headers = {};
  let body;
  let token = `Bearer ${accessToken}`.replace(/['"]+/g, '');
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: token,
    };
  }
  if (contentType.includes('application/json')) {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    };
    body = JSON.stringify(data);
  } else if (
    contentType.includes('application/x-www-form-urlencoded;charset=UTF-8')
  ) {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
    let formBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    body = formBody.join('&');
  } else if (contentType.includes('application/x-www-form-urlencoded')) {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    let formBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    body = formBody.join('&');
  }
  const methode =
    body === {}
      ? {
          method: 'PUT',
          headers,
        }
      : {
          method: 'PUT',
          headers,
          data: body,
        };
  return await axios(url, methode);
}

export async function del(url, accessToken = null) {
  return await axios(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function get(
  url,
  accessToken = null,
  contentType = '',
  responseType,
) {
  let headers = {
    'Content-Type': contentType,
  };
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }
  if (contentType.includes('image/png')) {
    headers = {
      ...headers,
      'Content-Type': 'image/png',
    };
  }
  if (contentType.includes('application/x-www-form-urlencoded;charset=UTF-8')) {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
  }
  const config = {
    method: 'GET',
    headers: headers,
  };
  if (responseType) {
    config.responseType = responseType;
  }
  return await axios(url, config);
}

export async function getFileContent(url, requestOptions, responseType) {
  return await axios(url, {
    method: 'GET',
    headers: requestOptions,
    responseType,
  });
}
