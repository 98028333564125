import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { configConstants } from '../../../../constants';
import { userActions } from '../../../../actions';
import messages from '../../../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import { Form, Col } from 'react-bootstrap';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import './form.css';

class FormUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      defaultV: {},
      role: this.props.user.role,
      name: this.props.user.name,
      phone: this.props.user.phone_number,
      email: this.props.user.email,
      mobileNumber: this.props.user.mobile_number,
    };
  }

  UNSAFE_componentWillMount() {
    this.handleOptionsSelect();
  }

  handleOptionsSelect = () => {
    const { company } = this.props;
    let r;
    switch (company) {
      case '1':
        r =
          this.props.language.locale === 'DE'
            ? configConstants.ROLE_AGR_DE
            : configConstants.ROLE_AGR;
        break;
      case '2':
        r =
          this.props.language.locale === 'DE'
            ? configConstants.ROLE_TRD_DE
            : configConstants.ROLE_TRD;
        break;
      case '3':
        r =
          this.props.language.locale === 'DE'
            ? configConstants.ROLE_ABA_DE
            : configConstants.ROLE_ABA;
        break;
      case '4':
        r =
          this.props.language.locale === 'DE'
            ? configConstants.ROLE_TRD_DE
            : configConstants.ROLE_TRD;
        break;
      case '5':
        r =
          this.props.language.locale === 'DE'
            ? configConstants.ROLE_VET_DE
            : configConstants.ROLE_VET;
        break;
      default:
        break;
    }
    this.renderSelectedValue(r);
    this.setState({
      options: r,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.state.role) {
      this.setState({ role: this.state.defaultV.value });
    }
    const data = {
      name: this.state.name,
      email: this.state.email,
      phone_number: this.state.phone ? this.state.phone : '',
      mobile_number: this.state.mobileNumber ? this.state.mobileNumber : '',
      role: this.state.role,
    };
    if (this.props.user._id) {
      await this.props.editUser(data, this.props.user._id);
    } else {
      await this.props.addUser(data);
    }
    this.props.handleModal();
  };

  handleSelect = (role) => {
    this.setState({ role: role.value });
  };
  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };
  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };
  handleChangePhone = (event) => {
    this.setState({ phone: event.target.value });
  };
  handleChangeMobile = (event) => {
    this.setState({ mobileNumber: event.target.value });
  };
  renderSelectedValue = (r) => {
    let def = r.find((role) => role.value === this.props.user.role);
    this.setState({ defaultV: { ...def } });
  };

  render() {
    const {
      name,
      email,
      phone,
      role,
      options,
      mobileNumber,
      defaultV,
    } = this.state;
    return (
      <Form
        className="form-user-settings add-user"
        onSubmit={this.handleSubmit}
      >
        <Col md={12}>
          <RegularInput
            name="userName"
            type="name"
            defaultValue={name}
            onChange={this.handleChangeName}
            required
            label={<FormattedMessage {...messages.Name} />}
          />
        </Col>
        <Col md={12}>
          <RegularInput
            type="email"
            name="userEmail"
            defaultValue={email}
            onChange={this.handleChangeEmail}
            required
            label={<FormattedMessage {...messages.email} />}
          />
        </Col>
        <Col md={12}>
          <RegularInput
            name="userPhone"
            type="text"
            defaultValue={phone}
            onChange={this.handleChangePhone}
            required
            label={<FormattedMessage {...messages.phoneNumber} />}
          />
        </Col>
        <Col md={12}>
          <RegularInput
            name="userMobile"
            type="test"
            defaultValue={mobileNumber}
            onChange={this.handleChangeMobile}
            // required
            label={<FormattedMessage {...messages.mobileNumber} />}
          />
        </Col>
        <Col md={12}>
          <RegularInput
            label={<FormattedMessage {...messages.Role} />}
            select
            defaultValue={defaultV}
            onChange={this.handleSelect}
            options={options}
            className={role && 'selected'}
            required
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F1FFDE',
                primary: '#7bcd10',
              },
            })}
          />
        </Col>
        <Col md={12} className="add-user-button-container">
          <NormalButton type="submit">
            <FormattedMessage {...messages.Save} />
          </NormalButton>
        </Col>
      </Form>
    );
  }
}

FormUser.propTypes = {
  addUser: PropTypes.func,
  handleModal: PropTypes.func,
  company: PropTypes.oneOf(['1', '2', '3', '4', '5']),
  user: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    addUser: (data) => dispatch(userActions.addUser(data)),
    editUser: (data, userId) => dispatch(userActions.editUser(data, userId)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUser);
