import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Card,
  ButtonGroup,
  Button,
  FormGroup,
  ListGroupItem,
  Image,
  Modal,
} from 'react-bootstrap';
import {
  getMedication,
  getdrugsPdf,
  getstockPdf,
  getPDFStock,
} from '../../../services/stock.service';
import EartagSearch from './EartagSearch';
import messages from '../messages';
import DatePicker from 'react-datepicker';
import { OrderService } from '../../../services/order.service';

// import extractData from '../../../services/data.processing';
import FileSaver from 'file-saver';

import locale from '../../../utils/DataPickerLang';
import { addLeadingZero } from '../../../helpers/order';
import searchImg from '../../../static/images/admin/download-arrow.svg';
import searchImgTwo from '../../../static/images/admin/search.png';

import moment from 'moment-es6';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../static/css/datepicker.css';

import Cv from './Cv';
import Form from '../ViewStock/Form';
import { alertActions } from '../../../actions';
import './ReportsAndSearch.css';

class ReportsAndSearch extends Component {
  constructor(props) {
    super(props);
    this.minDateRef = React.createRef();
    this.maxDateRef = React.createRef();
    this.state = {
      type: 'active',
      eartagSorted: false,
      drugSorted: false,
      qteSorted: false,
      dateSorted: false,
      displayCv: false,
      cvInfos: {},
      data: {},
      detailStock: false,
      min_date: moment().toDate(),
      max_date: moment().add(1, 'day').toDate(),
      theDay: '',
      pdfimgId: '',
      showPopup: false,
      showPopupTwo: false,
      pdfId: '',
    };
  }

  componentDidMount() {
    this.getAllMedications();
    this.setState({ theDay: this.days[moment().day()] });
  }

  getCsvFile = async () => {
    let img = await OrderService.getCvsFile(
      this.props.users.accessToken,
      this.convertDate(this.state.min_date),
      this.convertDate(this.state.max_date),
    );
    if (typeof img !== 'undefined' && img.status === 200) {
      if (img.data.length > 0) {
        let value = new Blob([img.data], {
          type: 'data:text/csv;charset=utf-8',
        });
        const title = `Viehbewegungen_${this.convertDate(
          this.state.min_date,
        )}_${this.convertDate(this.state.max_date)}.csv`;
        FileSaver.saveAs(value, title);
      } else {
        this.props.success('No report for this date');
        this.props.clear();
      }
    } else {
      this.props.error(<FormattedMessage {...messages.NetworkProblem} />);
      this.props.clear();
    }
  };

  getDrugPdf = async () => {
    let imgid = await getdrugsPdf(
      this.props.users.accessToken,
      this.convertDate(this.state.min_date),
      this.convertDate(this.state.max_date),
    );
    if (imgid.status === 200) {
      let img = await getPDFStock(
        this.props.users.accessToken,
        imgid.data.data,
      );
      FileSaver.saveAs(img, `drug_${this.convertDate(
        this.state.min_date,
      )}_${this.convertDate(this.state.max_date)}.pdf`);
    } else {
      this.props.error(<FormattedMessage {...messages.NoReportP} />);
      this.props.clear();
    }
  };

  getstockPdf = async () => {
    let imgid = await getstockPdf(
      this.props.users.accessToken,
      this.convertDate(this.state.min_date),
      this.convertDate(this.state.max_date),
    );
    if (imgid.status === 200) {
      let img = await getPDFStock(
        this.props.users.accessToken,
        imgid.data.data,
      );
      FileSaver.saveAs(img, `stocks_${this.convertDate(
        this.state.min_date,
      )}_${this.convertDate(this.state.max_date)}.pdf`);
    }
  };

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  convertDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = day + '.' + month + '.' + year;

    let nameSplit = formatted.split('.');
    // new date format 01.01.20
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = day + '.' + month + '.' + nameSplit[2].toString().substr(-2);
    return newDate;
  };

  days = [
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.sunday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.monday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.tuesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.wednesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.thursday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.friday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.saturday' }),
  ];

  async getAllMedications() {
    let token = this.props.users.accessToken;
    const medication = await getMedication(token, 0);
    if (medication.status === 200) {
    } else {
      this.props.error(<FormattedMessage {...messages.NetworkProblem} />);
      this.props.clear();
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      type: e.target.value,
    });
  };

  backgroundChange = (position) => {
    if (this.state.itemBackground === position) {
      return '#f0fae3';
    }
    return '';
  };

  newSelectedDateInput = (date) => {
    const { data } = this.props;
    return data[date] !== '' && typeof data[date] != 'undefined'
      ? `${addLeadingZero(data[date].getDate())}.${addLeadingZero(
          data[date].getMonth() + 1,
        )}.${data[date].getFullYear()}`
      : `${addLeadingZero(date.getDate())}.${addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`;
  };

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  dateChange = async (date) => {
    this.setState({
      min_date: moment(date).toDate(),
      theDay: this.days[moment(date).day()],
    });
  };

  handleChangeDate = async (date, control) => {
    let tomorrow;
    if (control === 'up') {
      tomorrow = moment(date).add(1, 'days');
    } else {
      tomorrow = moment(date).add(-1, 'days');
    }
    this.setState({
      theDay: this.days[tomorrow.day()],
      min_date: tomorrow.toDate(),
    });
  };

  dateChangeDateTwo = async (date) => {
    this.setState({
      max_date: moment(date).toDate(),
    });
  };

  handleChangeDateDateTwo = async (date, control) => {
    let tomorrow;
    if (control === 'up') {
      tomorrow = moment(date).add(1, 'days');
    } else {
      tomorrow = moment(date).add(-1, 'days');
    }
    this.setState({
      max_date: tomorrow.toDate(),
    });
  };

  showCv = (bool) => {
    this.setState({ displayCv: bool });
  };

  getCvInfo = async (cvInfos, data) => {
    this.setState({ cvInfos, data });
  };

  setShowForm = async () => {
    this.setState({
      detailStock: !this.state.detailStock,
      displayCv: !this.state.displayCv,
    });
  };

  checkBirthDatePickerState = () => {
    this.minDateRef.current.setOpen(true);
  };

  checkIncomingDatePickerState = () => {
    this.maxDateRef.current.setOpen(true);
  };

  render() {
    const { data, displayCv } = this.state;
    return (
      <Row className="orders view-drug">
        {/* PDF popup*/}
        <Modal
          show={this.state.showPopup === true}
          onHide={this.handleClose}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdfCondition ">
            <embed
              width="100%"
              height="85%"
              frameBorder="0"
              url={this.state.pdfId}
              type="application/pdf"
            />
            <div className="btn-center-Accept"></div>
          </Modal.Body>
        </Modal>
        <Col className="orders-col" md={6} sm={12}>
          <Card className="card-box left-box">
            <Card.Body>
              <Row className="panel-body-row">
                <Col className="panel-body-col" xs={12} md={12}>
                  <ButtonGroup
                    value={this.state.type}
                    onClick={this.handleChange}
                    className="order-btn-groupe"
                  >
                    <div className="button-groupe-wrapper">
                      <Button
                        className={
                          this.state.type === 'active'
                            ? 'active active-tab-icon'
                            : ''
                        }
                        value={'active'}
                      >
                        {this.props.language.locale === 'DE'
                          ? 'Ohrmarke Suche'
                          : 'Eartag Search'}{' '}
                        {/* <FormattedMessage {...messages.eartag_search} /> */}
                      </Button>
                      <Button
                        className={
                          this.state.type === 'completed' ? 'active' : ''
                        }
                        value={'completed'}
                      >
                        {this.props.language.locale === 'DE'
                          ? 'Auswertungen'
                          : 'Reports'}{' '}
                      </Button>
                    </div>
                  </ButtonGroup>
                  <FormGroup
                    controlId="formInlineName"
                    className="user-input-wrp date-time"
                  >
                    {this.state.type === 'active' && (
                      <ListGroupItem className="title-list-content">
                        <EartagSearch
                          showCv={this.showCv}
                          setShowForm={this.setShowForm}
                          getCvInfo={this.getCvInfo}
                        />
                      </ListGroupItem>
                    )}
                    {this.state.type === 'completed' && (
                      <ListGroupItem className="title-list-content">
                        {/* screen */}

                        {this.props.users.user.session.company_type === '1' && (
                          <div
                            style={{ textAlign: 'center' }}
                            className="notice-first-parag"
                          >
                            <div>
                              {' '}
                              <div
                                style={{
                                  border: '1px solid #cdf0b2',
                                  marginTop: '20px',
                                  paddingTop: '20px',
                                }}
                              >
                                <Row>
                                  <Col md={6}>
                                    <div
                                      style={{
                                        textAlign: 'left',
                                        marginLeft: '58px',
                                      }}
                                    >
                                      <FormattedMessage {...messages.LomFrom} />
                                    </div>{' '}
                                  </Col>
                                  <Col md={6}>
                                    <div
                                      style={{
                                        textAlign: 'left',
                                        marginLeft: '58px',
                                      }}
                                    >
                                      <FormattedMessage {...messages.LomTo} />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    md={6}
                                    className="text-center space-top-bottom"
                                  >
                                    <div className="date-picker-wrapper">
                                      <div
                                        className="date-picker-icon"
                                        onClick={() => {
                                          this.minDateRef.current.setOpen(true);
                                        }}
                                      ></div>
                                      <DatePicker
                                        selected={this.state.min_date}
                                        onChange={this.dateChange}
                                        dateFormat="dd.MM.yyyy"
                                        ref={this.minDateRef}
                                        locale={locale}
                                      />
                                      <button
                                        className="button-up"
                                        onClick={() =>
                                          this.handleChangeDate(
                                            this.state.min_date,
                                            'down',
                                          )
                                        }
                                      >
                                        <i className="down" />
                                      </button>
                                      <button
                                        className="button-down"
                                        onClick={() =>
                                          this.handleChangeDate(
                                            this.state.min_date,
                                            'up',
                                          )
                                        }
                                      >
                                        <i className="up" />
                                      </button>
                                    </div>
                                  </Col>

                                  <Col
                                    md={6}
                                    className="text-center space-top-bottom"
                                  >
                                    <div className="date-picker-wrapper">
                                      <div
                                        className="date-picker-icon"
                                        onClick={() => {
                                          this.maxDateRef.current.setOpen(true);
                                        }}
                                      ></div>
                                      <DatePicker
                                        selected={this.state.max_date}
                                        onChange={this.dateChangeDateTwo}
                                        dateFormat="dd.MM.yyyy"
                                        ref={this.maxDateRef}
                                        locale={locale}
                                        minDate={this.state.min_date}
                                        placeholderText="Select a day"
                                      />
                                      <button
                                        className="button-up"
                                        onClick={() =>
                                          this.handleChangeDateDateTwo(
                                            this.state.max_date,
                                            'down',
                                          )
                                        }
                                      >
                                        <i className="down" />
                                      </button>
                                      <button
                                        className="button-down"
                                        onClick={() =>
                                          this.handleChangeDateDateTwo(
                                            this.state.max_date,
                                            'up',
                                          )
                                        }
                                      >
                                        <i className="up" />
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <Row>
                                <div className="t-info-pass-sl first">
                                  <div
                                    style={{
                                      width: '95%',
                                      Align: 'center',
                                      fontSize: '17px',
                                    }}
                                    className="tourInfo-view-cattle"
                                  >
                                    <div
                                      className="text"
                                      onClick={() => {
                                        this.getstockPdf();
                                      }}
                                    >
                                      <span>
                                        {' '}
                                        <FormattedMessage
                                          {...messages.Stockreport}
                                        />
                                      </span>
                                    </div>
                                    <div className="view-cattle">
                                      <Image
                                        className=""
                                        src={searchImgTwo}
                                        onClick={() => {
                                          this.getstockPdf();
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <div className="t-info-pass-sl first">
                                  <div
                                    style={{
                                      width: '95%',
                                      Align: 'center',
                                      fontSize: '17px',
                                    }}
                                    className="tourInfo-view-cattle"
                                  >
                                    <div
                                      className="text"
                                      onClick={() => {
                                        this.getDrugPdf();
                                      }}
                                    >
                                      <span>
                                        {' '}
                                        <FormattedMessage
                                          {...messages.Medicationreport}
                                        />
                                      </span>
                                    </div>
                                    <div className="view-cattle">
                                      <Image
                                        className=""
                                        src={searchImgTwo}
                                        onClick={() => {
                                          this.getDrugPdf();
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        )}
                        {this.props.users.user.session.company_type === '2' && (
                          <div>
                            {' '}
                            <div
                              style={{
                                border: '1px solid #cdf0b2',
                                marginTop: '20px',
                                paddingTop: '20px',
                              }}
                            >
                              <Row>
                                <Col md={6}>
                                  <div
                                    style={{
                                      textAlign: 'left',
                                      marginLeft: '58px',
                                    }}
                                  >
                                    <FormattedMessage {...messages.LomFrom} />
                                  </div>{' '}
                                </Col>
                                <Col md={6}>
                                  <div
                                    style={{
                                      textAlign: 'left',
                                      marginLeft: '58px',
                                    }}
                                  >
                                    <FormattedMessage {...messages.LomTo} />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  md={6}
                                  className="text-center space-top-bottom"
                                >
                                  <div className="date-picker-wrapper">
                                    <div
                                      className="date-picker-icon"
                                      onClick={() => {
                                        this.minDateRef.current.setOpen(true);
                                      }}
                                    ></div>
                                    <DatePicker
                                      selected={this.state.min_date}
                                      onChange={this.dateChange}
                                      dateFormat="dd.MM.yyyy"
                                      ref={this.minDateRef}
                                      locale={locale}
                                    />
                                    <button
                                      className="button-up"
                                      onClick={() =>
                                        this.handleChangeDate(
                                          this.state.min_date,
                                          'down',
                                        )
                                      }
                                    >
                                      <i className="down" />
                                    </button>
                                    <button
                                      className="button-down"
                                      onClick={() =>
                                        this.handleChangeDate(
                                          this.state.min_date,
                                          'up',
                                        )
                                      }
                                    >
                                      <i className="up" />
                                    </button>
                                  </div>
                                </Col>

                                <Col
                                  md={6}
                                  className="text-center space-top-bottom"
                                >
                                  <div className="date-picker-wrapper">
                                    <div
                                      className="date-picker-icon"
                                      onClick={() => {
                                        this.maxDateRef.current.setOpen(true);
                                      }}
                                    ></div>
                                    <DatePicker
                                      selected={this.state.max_date}
                                      onChange={this.dateChangeDateTwo}
                                      dateFormat="dd.MM.yyyy"
                                      ref={this.maxDateRef}
                                      locale={locale}
                                      minDate={this.state.min_date}
                                      placeholderText="Select a day"
                                    />
                                    <button
                                      className="button-up"
                                      onClick={() =>
                                        this.handleChangeDateDateTwo(
                                          this.state.max_date,
                                          'down',
                                        )
                                      }
                                    >
                                      <i className="down" />
                                    </button>
                                    <button
                                      className="button-down"
                                      onClick={() =>
                                        this.handleChangeDateDateTwo(
                                          this.state.max_date,
                                          'up',
                                        )
                                      }
                                    >
                                      <i className="up" />
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <div className="t-info-pass-sl first">
                                <div
                                  style={{
                                    width: '95%',
                                    Align: 'center',
                                    fontSize: '17px',
                                  }}
                                  className="tourInfo-view-cattle"
                                >
                                  <div
                                    style={{
                                      textAlign: 'right',
                                    }}
                                    className="text"
                                    onClick={() => {
                                      this.getCsvFile();
                                    }}
                                  >
                                    <span>
                                      {' '}
                                      <FormattedMessage {...messages.LomCsv} />:
                                      Viehbewegungen.csv
                                    </span>
                                  </div>
                                  <div className="view-cattle">
                                    <Image
                                      className=""
                                      src={searchImg}
                                      onClick={() => {
                                        this.getCsvFile();
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        )}
                      </ListGroupItem>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {displayCv &&
          typeof this.state.cvInfos !== 'undefined' &&
          this.state.cvInfos.length > 0 && (
            <Col style={{ height: '100%' }} md={6} sm={12}>
              <Card className="buy-sell card-box">
                <Cv
                  showCv={this.showCv}
                  data={this.state.cvInfos}
                  setShowForm={this.setShowForm}
                />
              </Card>
            </Col>
          )}
        {this.state.detailStock && (
          <Col
            className="view-stock"
            style={{ height: '95%', width: '49%' }}
            md={6}
            sm={12}
          >
            <Form
              token={this.props.users.accessToken}
              showAlert={(msg) => this.props.error(msg)}
              setShowForm={this.setShowForm}
              data={data}
              passport={data.passport}
              ear_tag={data.ear_tag}
              ear_tag_output_format={data.ear_tag_output_format}
              save={true}
            />
          </Col>
        )}
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ReportsAndSearch));
