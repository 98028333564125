import React from 'react';
import { connect } from 'react-redux';
import {
  configConstants,
  states,
  country,
  qualitiesDe,
  qualitiesEn
} from '../../../../constants';
import { alertActions } from '../../../../actions';
import messages from '../../../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import MultiSelect from '../../../Shared/components/MultiSelect';

import clsx from 'clsx';
import { Form } from 'formik';

import { Col, ListGroup } from 'react-bootstrap';
import './addContact.css';

const AddContact = (props) => {
  const {
    values,
    errors,
    handleChange,
    touched,
    setFieldValue,
    handleSubmit,
    language,
  } = props;
  const optionsBT =
    language.locale === 'DE'
      ? configConstants.BUSINESS_TYPE_DE
      : configConstants.BUSINESS_TYPE;

  const stateOptions = states;
  const stateOptionsCountry = country;

  const handleChangeProgrameQuality = async (qp) => {
    let findQP = qp.find(e=>e.value === 'Noqualityprogram' );
    let findIndexQP = qp.findIndex(e=>e.value === 'Noqualityprogram' );
    let FinalQP = [];

    if (typeof findQP !== 'undefined' && findIndexQP > 0) {
      FinalQP = qp.filter((e) => e.value === 'Noqualityprogram');
    } else if (typeof findQP !== 'undefined' && findIndexQP <= 0) {
      if (qp.length > 1) {
        FinalQP = qp.filter((e) => e.value !== 'Noqualityprogram');
      } else {
        FinalQP = qp;
      }
    } else {
      FinalQP = qp;
    }
    setFieldValue('qualityProgarms', FinalQP);
  };

  return (
    <div className="contact-popup-block-b">
      <Form className="form-user-settings">
        <ListGroup className="list-stock form-contact-popup">
          <Col md={12}>
            <RegularInput
              type="text"
              id="company"
              className="form-input"
              name="company"
              value={values.company}
              onChange={handleChange}
              label={
                <span>
                  <FormattedMessage {...messages.company} /> *
                </span>
              }
            />
            {touched.company && errors.company && (
              <p className="error-message">Enter the name</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              type="number"
              id="number"
              name="number"
              value={values.number}
              onChange={handleChange}
              label={<FormattedMessage {...messages.companyNumber} />}
            />
            {errors.companyNumber && touched.companyNumber && (
              <p className="error-message">company number should be a number</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              select
              options={optionsBT}
              value={values.group}
              setFieldValue={setFieldValue}
              className={clsx(values.group && 'selected')}
              label={
                <span>
                  <FormattedMessage {...messages.businessType} /> *
                </span>
              }
              id="group"
              name="group"
              error={errors.group}
              required
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
            <span className="focus-border"></span>
            {touched.group && errors.group && (
              <p className="error-message transport-Company-error">
                {errors.group}
              </p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              type="text"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              label={<FormattedMessage {...messages.email} />}
            />
            {errors.email && touched.email && (
              <p className="error-message">E-mail address is not valid</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              type="text"
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              label={<FormattedMessage {...messages.phoneNumber} />}
            />
            {errors.phone && touched.phone && (
              <p className="error-message">Phone number is not valid</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              type="text"
              id="mobilephone"
              name="mobilephone"
              value={values.mobilephone}
              onChange={handleChange}
              label={<FormattedMessage {...messages.mobileNumber} />}
            />
            {errors.mobilephone && touched.mobilephone && (
              <p className="error-message">Mobile number is not valid</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              type="text"
              id="street"
              name="street"
              value={values.street}
              onChange={handleChange}
              label={
                <span>
                  <FormattedMessage {...messages.StreetAndHouseNumber} /> *
                </span>
              }
            />
            {errors.street && touched.street && (
              <p className="error-message">Should be an existing address</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              type="number"
              id="zipCode"
              name="zipCode"
              value={values.zipCode}
              onChange={handleChange}
              label={
                <span>
                  <FormattedMessage {...messages.zipCode} /> *
                </span>
              }
            />
            {errors.zipCode && touched.zipCode && (
              <p className="error-message">Address is not valid</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              type="text"
              id="city"
              name="city"
              value={values.city}
              onChange={handleChange}
              label={
                <span>
                  <FormattedMessage {...messages.city} /> *
                </span>
              }
            />
            {errors.city && touched.city && (
              <p className="error-message">Should be an existing address</p>
            )}
          </Col>
          <Col md={12}>
            <RegularInput
              select
              id="country"
              name="country"
              value={values.country}
              options={stateOptionsCountry}
              setFieldValue={setFieldValue}
              label={
                <span>
                  <FormattedMessage {...messages.country} /> *
                </span>
              }
              className={clsx(values.country && 'selected')}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,

                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />

            {/* <RegularInput
              type="text"
              id="country"
              name="country"
              value={values.country}
              onChange={handleChange}
              label={<FormattedMessage {...messages.country} />}
            /> */}
            {errors.country && touched.country && (
              <p className="error-message">Should be an existing address</p>
            )}
          </Col>
          {typeof values.country !== 'undefined' &&
            values.country.value === 'Deutschland' && (
              <Col md={12}>
                <RegularInput
                  select
                  id="federalState"
                  name="federalState"
                  value={
                    typeof values.country !== 'undefined' &&
                    values.country.value === 'Deutschland'
                      ? values.federalState
                      : ''
                  }
                  options={
                    typeof values.country !== 'undefined' &&
                    values.country.value === 'Deutschland'
                      ? stateOptions
                      : []
                  }
                  setFieldValue={setFieldValue}
                  label={
                    <span>
                      <FormattedMessage {...messages.federalState} /> *
                    </span>
                  }
                  className={clsx(values.federalState && 'selected')}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,

                    colors: {
                      ...theme.colors,
                      primary25: '#F1FFDE',
                      primary: '#7bcd10',
                    },
                  })}
                />
                {errors.federalState && touched.federalState && (
                  <p className="error-message">federalState is required</p>
                )}
              </Col>
            )}
          <Col md={12}>
            <MultiSelect
              filled
              label={<FormattedMessage {...messages.QualityProgram} />}
              isMulti={true}
              // value={matchQualities(qps)}
              className="selected"
              closeMenuOnSelect={false}
              // options={qualities}
              id="qualityProgarms"
              name="qualityProgarms"
              // // value={values.qualityProgarms}
              // onChange={handleChange}
              options={language.locale === 'DE' ? qualitiesDe : qualitiesEn}
              onChange={handleChangeProgrameQuality}
              value={values.qualityProgarms}
              // setFieldValue={setFieldValue}

              isSearchable={false}
              isClearable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
          </Col>
        </ListGroup>
        <Col sm={12} className="confirm-contact-add">
          <NormalButton type="submit" onClick={handleSubmit}>
            <FormattedMessage {...messages.AddContact} />
          </NormalButton>
        </Col>
      </Form>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
