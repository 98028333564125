/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *
 * LocaleToggle
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import './select.css';

function ToggleLink(props, { value, message, intl }) {
  let content = '';
  // If we have items, render them
  if (props.values) {
    content = props.values.map((value, i) => (
      <a
        key={i}
        href="#"
        className={
          props.value === value ? ' m-r-30 language active' : ' m-r-30 language'
        }
        id={value}
        onClick={props.onToggle}
      >
        {value}
      </a>
    ));
  }

  return <div className="language-content">{content}</div>;
}

ToggleLink.propTypes = {
  onToggle: PropTypes.func,
  values: PropTypes.array,
  value: PropTypes.string,
  messages: PropTypes.object,
};

export default ToggleLink;
