import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { alertActions } from '../../actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { states } from '../../constants';
import {
  newRegisterCompany,
  addRegisterCompany,
} from '../../actions/company.actions';
import { userActions } from '../../actions';
import RegularInput from '../Shared/components/inputs/RegularInput';
import NormalButton from '../Shared/components/buttons/NormalButton/NormalButton';
import { ToastContainer, toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import messages from '../Admin/messages';
import 'react-toastify/dist/ReactToastify.css';

class UserFormCompany extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formInvalid: true,
      fedState: '',
    };
  }

  handleSubmitAdd = async e => {
    e.preventDefault();
    const form = e.target;
    if (form) {
      /***
       * for session refresh
       * */
      const user_id = this.props.users.user._id;
      const {
        company_id,
        company_number,
        company_type,
      } = this.props.users.user.session;
      const device_id = this.props.users.device._id;
      const token = this.props.users.token.access_token;
      const session = {
        user_id,
        company_id,
        company_number,
        company_type,
        device_id,
      };
      /*****/
      const fullName = this.props.users.user.name.split(' ');
      const owner = {
        firstname: fullName[0],
        surname: fullName[1],
        email: this.props.users.user.email,
        company_password: '',
      };
      let address = {
        city: form.elements['city'].value,
        street: form.elements['streetNumber'].value,
        zip_code: form.elements['zipCode'].value,
        federal_state: this.state.fedState,
        country: 'Deutschland',
      };
      let company = {
        ...this.props.company,
        owner,
        address,
        business_type: this.props.company.business_type.toString(),
        name: form.elements['name'].value,
      };
      delete company.step_register;
      delete company.registering;
      delete company.user;
      delete company.token;
      delete company.client;
      delete company.accessToken;
      delete company._persist;
      delete company.locale;
      delete company.isConnected;
      delete company.device;
      delete company.plan;
      delete company.company;
      delete company.users;
      delete company.buyings;
      delete company.allstock;
      delete company.sellings;
      try {
        await this.props.addRegisterCompany(company);
        await this.props.changeUserSessionToLastCompany(session, token);
      } catch (error) {
        this.props.error(error);
        this.props.clear();
        this.notify(error);
      }
    } else {
      this.notify(
        <FormattedMessage {...messages.RegisterStepThreeEmailExist} />,
      );
    }
  };

  notify = message =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  renderOptions = list =>
    list.map((val, i) => (
      <option key={i} value={val.value}>
        {val.label}
      </option>
    ));

  handleState = event => {
    const { value } = event;
    this.setState({ fedState: value });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmitAdd} ref={form => (this.form = form)}>
        <ToastContainer />
        <Row>
          <Col sm={6}>
            <RegularInput
              label={<FormattedMessage {...messages.companyNumber} />}
              type="text"
              inputRef={number => {
                this.number = number;
              }}
              disabled={true}
              defaultValue={this.props.company.number}
              placeholder="Company Number"
            />

            <RegularInput
              label={<FormattedMessage {...messages.company} />}
              name="name"
              required
              defaultValue={this.props.company.name}
            />
            <RegularInput
              label={<FormattedMessage {...messages.faxNumber} />}
              name="faxNumber"
              type="number"
              defaultValue={this.props.company.fax_number}
            />
            <RegularInput
              label={<FormattedMessage {...messages.city} />}
              name="city"
              required
              defaultValue={
                typeof this.props.company.address !== 'undefined'
                  ? this.props.company.address.city
                  : ''
              }
            />
          </Col>
          <Col sm={6} className="add-company-second-section">
            <RegularInput
              label={<FormattedMessage {...messages.streetNumber} />}
              name="streetNumber"
              required
              defaultValue={
                typeof this.props.company.address !== 'undefined'
                  ? this.props.company.address.street
                  : ''
              }
            />
            <RegularInput
              label={<FormattedMessage {...messages.zipCode} />}
              name="zipCode"
              required
              defaultValue={
                typeof this.props.company.address !== 'undefined'
                  ? this.props.company.address.zip_code
                  : ''
              }
            />
            <RegularInput
              label={<FormattedMessage {...messages.country} />}
              select
              name="country"
              required
              defaultValue={{ value: 'Deutschland', label: 'Deutschland' }}
              isDisabled={true}
              className={'selected'}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
            <RegularInput
              label={<FormattedMessage {...messages.federalState} />}
              select
              requiredField={true}
              selectInputValue={this.state.fedState}
              required
              defaultValue={{ value: 'Select State', label: 'Select State' }}
              className="selected federal-state"
              name="state"
              onChange={this.handleState}
              options={states}
              theme={theme => ({
                ...theme,
                borderRadius: 0,

                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col className="btn-center-Accept add-company">
            <NormalButton type="submit">
              <FormattedMessage {...messages.Save} />
            </NormalButton>
          </Col>
        </Row>
      </form>
    );
  }
}

UserFormCompany.propTypes = {
  addUserCompany: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    success: msg => dispatch(alertActions.success(msg)),
    error: error => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    newRegisterCompany: company => dispatch(newRegisterCompany(company)),
    addRegisterCompany: company => dispatch(addRegisterCompany(company)),
    changeUserSessionToLastCompany: (session, token) =>
      dispatch(userActions.changeUserSessionToLastCompany(session, token)),
  };
}

const mapStateToProps = state => {
  const { company, users } = state.toJS();
  return { company, users };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserFormCompany);
