// import { fromJS } from "immutable";
import { combineReducers } from 'redux-immutable';
// import { LOCATION_CHANGE } from "react-router-redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import { registration } from './reducers/registration.reducer';
import { users } from './reducers/users.reducer';
import { alert } from './reducers/alert.reducer';
import { company } from './reducers/company.reducer';
import { buyings } from './reducers/buy.reducer';
import { sellings } from './reducers/sell.reducer';
import { invoices } from './reducers/invoice.reducer';
import { stock } from './reducers/stock.reducer';
import { admin } from './reducers/admin.reducer';
import { producer } from './reducers/producer.reducer';
import { buyingsStock} from './reducers/buyingsStock.reducer';




// Initial routing state
// const routeInitialState = fromJS({
//   location: null,
// });

//Merge route into the global application state
// export function routeReducer(state = routeInitialState, action) {
//   switch (action.type) {
//     /* istanbul ignore next */
//     case LOCATION_CHANGE:
//       return state.merge({
//         location: action.payload,
//       });
//     default:
//       return state;
//   }
// }

const persistConfig = {
  key: 'root',
  storage,
};

//Creates the main reducer with the dynamically injected ones
export default function createReducer(injectedReducers) {
  return combineReducers({
    alert: alert,
    company: persistReducer(persistConfig, company),
    users: persistReducer(persistConfig, users),
    buyings: buyings,
    sellings: sellings,
    registration: persistReducer(persistConfig, registration),
    // route: routeReducer,
    language: languageProviderReducer,
    ...injectedReducers,
    invoices: invoices,
    allstock: stock,
    panelTitle: admin,
    buyingsStock:buyingsStock,
    producer: producer,
   

  });
}
