import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import './icon.scss';

const propTypes = {
  normal: PropTypes.bool,
  solid: PropTypes.bool,
  regular: PropTypes.bool,
  rounded: PropTypes.bool,
  border: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
};

const defaultProps = {
  normal: false,
  solid: false,
  regular: false,
  rounded: false,
  border: false,
  color: 'green',
  size: '',
};

const Icon = React.forwardRef(
  (
    {
      className,
      border,
      size,
      normal,
      rounded,
      solid,
      regular,
      color,
      ...props
    },
    ref,
  ) => {
    const prefix = 'iconiz';

    const classes = classNames(
      prefix,
      color && `${prefix}-${color}`,
      border && `border`,
      normal && `${prefix}-normal`,
      rounded && `${prefix}-rounded`,
      solid && `solid`,
      regular && `regular`,
    );

    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        ref={ref}
        width={`${size}px`}
        height={`${size}px`}
        {...props}
        className={classNames(className, classes)}
      />
    );
  },
);

Icon.displayName = 'Icon';
Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
