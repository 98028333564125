export const countries = [
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Dänemark', label: 'Dänemark' },
  { value: 'Frankreich', label: 'Frankreich' },
  { value: 'Italien', label: 'Italien' },
  { value: 'Niederlande', label: 'Niederlande' },
  { value: 'Österreich', label: 'Österreich' },
  { value: 'Polen', label: 'Polen' },
  { value: 'Schweiz', label: 'Schweiz' },
  { value: 'Slowakei', label: 'Slowakei' },
  { value: 'Slowenien', label: 'Slowenien' },
  { value: 'Dänemark', label: 'Dänemark' },
  { value: 'Deutschland', label: 'Deutschland' },
];
