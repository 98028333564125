import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Card,
  FormGroup,
  Modal,
  Dropdown,
  Image,
} from 'react-bootstrap';
import clsx from 'clsx';
import moment from 'moment-es6';
import { injectIntl } from 'react-intl';
import PopUpProducer from '../Orders/tabEvents/popUpProducer';
import { OrderService } from '../../../services/order.service';
import { getAllTruck, deleteTruck } from '../../../services/company.service';
import extractData from '../../../services/data.processing';
import truckIcon from '../../../static/images/admin/Icons/Status icons/truckIcon.png';
import '../Orders/orders.css';
import { alertActions, producerActions } from '../../../actions';
import './ReportsAndSearch.css';
import './tourTruck.css';
import Bought from '../../../static/images/admin/Icons/Status icons/Bought.png';
import Delivered from '../../../static/images/admin/Icons/Status icons/Delivered.png';
import Loaded from '../../../static/images/admin/Icons/Status icons/Loaded.png';
import NegotiationBuyRequest from '../../../static/images/admin/Icons/Status icons/NegotiationBuyRequest.png';
import NegotiationSaleOffer from '../../../static/images/admin/Icons/Status icons/NegotiationSaleOffer.png';
import Offer from '../../../static/images/admin/Icons/Status icons/Offer.png';
import Offered from '../../../static/images/admin/Icons/Status icons/Offered.png';
import Ontheway from '../../../static/images/admin/Icons/Status icons/Ontheway.png';
import Planned from '../../../static/images/admin/Icons/Status icons/Planned.png';
import Rejected from '../../../static/images/admin/Icons/Status icons/Rejected.png';
import Request from '../../../static/images/admin/Icons/Status icons/Request.png';
import Resold from '../../../static/images/admin/Icons/Status icons/Resold.png';
import Slaughered from '../../../static/images/admin/Icons/Status icons/Slaughered.png';
import Sold from '../../../static/images/admin/Icons/Status icons/Sold.png';
import Tobeaccepted from '../../../static/images/admin/Icons/Status icons/Tobeaccepted.png';
import Tobeaccepted2 from '../../../static/images/admin/Icons/Status icons/Tobeaccepted2.png';
import Tobeloaded from '../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import Tobereconfirmed2 from '../../../static/images/admin/Icons/Status icons/Tobereconfirmed2.png';
import Tourplanning from '../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import Unloaded from '../../../static/images/admin/Icons/Status icons/Unloaded.png';
import Requested from '../../../static/images/admin/Icons/Status icons/Requested.png';
import Tobereconfirmed from '../../../static/images/admin/Icons/Status icons/Tobereconfirmed.png';
import TourManagementPopup from '../Orders/tourMangement/TourManagementPopup';
import TourListAsPopup from '../Orders/tourInfo/TourInfoPopup';

import { statusOrders } from '../Orders/status-orders';

import './card.css';

const getSrcImg = (status, receivers_size, topic, is_buyer) => {
  var res = status.split('_').join('');
  res = res.toLowerCase();
  switch (res) {
    case 'bought':
      return Bought;
      break;
    case 'canceled':
      return Rejected;
      break;
    case 'delivered':
      return Delivered;
      break;
    case 'loaded':
      return Loaded;
      break;
    case 'negotiationbuyrequest':
      return NegotiationBuyRequest;
      break;
    case 'negotiation':
      return NegotiationBuyRequest;
      break;
    case 'negotiationsaleoffer':
      return NegotiationSaleOffer;
      break;
    case 'offer':
      return Offer;
      break;
    case 'offered':
      return Offered;
      break;
    case 'ontheway':
      return Ontheway;
      break;
    case 'rejected':
      return Rejected;
      break;
    case 'requested':
      if (receivers_size > 1) return NegotiationBuyRequest;
      else return Request;
      break;
    case 'resold':
      return Resold;
      break;
    case 'slaughtered':
      return Slaughered;
      break;
    case 'sold':
      return Sold;
      break;
    case 'tobeaccepted':
      return Tobeaccepted;
      break;
    case 'tobeaccepted2':
      return Tobeaccepted2;
      break;
    case 'tobeloaded':
      return Tobeloaded;
      break;
    case 'tobereconfirmed':
      if (is_buyer === 1 && topic !== 'BUY_STOCK') return Tobereconfirmed2;
      else return Tobeaccepted;
      break;
    case 'tobereconfirmed2':
      return Tobereconfirmed2;
      break;
    case 'tourplanning':
      return Tourplanning;
      break;
    case 'tourassigned':
      return Tourplanning;
      break;
    case 'unloaded':
      return Unloaded;
      break;
    case 'planned':
      return Planned;
      break;
  }
};

class CardOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'active',
      eartagSorted: false,
      drugSorted: false,
      qteSorted: false,
      dateSorted: false,
      displayCv: false,
      cvInfos: {},
      data: {},
      detailStock: false,
      ordersTab: [],
      date: moment().toDate(),
      theDay: '',
      showPopup: false,
      showPopupExternal: false,
      orderDetail: {},
      truck: {},
      showDetail: false,
    };
  }
  handleClose = () => this.setState({ showPopup: false });
  handleOpen = () => this.setState({ showPopup: true, showDetail: true });
  handleCloseExternal = () => this.setState({ showPopupExternal: false });
  handleOpenExternal = () => this.setState({ showPopupExternal: true });

  componentDidMount() { }

  handleClosePanel = () => {
    this.setState({
      showDetail: false,
    });
  };

  showPassportForTourInfo = (value) => {
    this.setState({ showPassportList: value });
  };

  getOrders = async () => {
    await this.props.getProducer(this.props.producer.date);
  };

  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  render() {
    const { data, lang } = this.props;
    return (
      <div>
        {this.state.showDetail &&
          (data.status !== 'TOUR_PLANNING' ? (
            <div>
              {data.status === 'TO_BE_LOADED' ||
                data.status === 'LOADED' ||
                data.status === 'ON_THE_WAY' ||
                data.status === 'DELIVERED' ||
                data.status === 'UNLOADED' ||
                data.status === 'SLAUGHTERED' ||
                data.status === 'PLANNED' ||
                data.status === 'TOUR_ASSIGNED' ? (
                <div>
                  <TourListAsPopup
                    orderDetail={this.props.data}
                    companyId={this.props.users.user.session.company_id}
                    handleClosePanel={this.handleClosePanel}
                    showDetailForTour={this.showDetailForTour}
                    showPassportList={false}
                    showPassportForTourInfo={this.showPassportForTourInfo}
                    showPassports={false}
                    getOrders={this.getOrders}
                    truckId={this.props.data._id}
                    toursDetails={this.state.toursDetails}
                    showDetail={this.props.data}
                    handleClosePanel={this.handleClosePanel}
                  />
                </div>
              ) : (
                <PopUpProducer
                  handleClosePanel={this.handleClosePanel}
                  getOrders={this.getOrders}
                  orderDetail={this.props.data}
                  orderType={this.props.data.topics}
                  companyId={this.props.users.user.session.company_id}
                  companyNumber={this.props.users.user.session.company_number}
                  companyType={this.props.users.user.session.company_type}
                  userId={this.props.users.user._id}
                  getSrcImg={getSrcImg}
                  showDetail={this.props.data}
                  hideBuyingAction={this.hideBuyingAction}
                  // emptyTruck={}
                  handleCloseToBeload={this.handleCloseToBeload}
                  handleCloseToBeload={this.handleClose}
                />
              )}
            </div>
          ) : !data.data.resold ? (
            <TourManagementPopup
              orderDetail={this.props.data}
              setShowForm={this.setShowForm}
              orderType={this.props.data.topics}
              type={this.state.type}
              handleClosePanel={this.handleClosePanel}
              getOrders={this.getOrders}
              changeTab={this.changeTab}
              changeDate={this.dateChange}
              displayBuyRequestEdit={this.displayBuyRequestEdit}
              showDetail={this.props.data}
            />
          ) : (
            <PopUpProducer
              handleClosePanel={this.handleClosePanel}
              getOrders={this.getOrders}
              orderDetail={this.props.data}
              orderType={this.props.data.topics}
              companyId={this.props.users.user.session.company_id}
              companyNumber={this.props.users.user.session.company_number}
              companyType={this.props.users.user.session.company_type}
              userId={this.props.users.user._id}
              getSrcImg={getSrcImg}
              showDetail={this.props.data}
              hideBuyingAction={this.hideBuyingAction}
              // emptyTruck={}
              handleCloseToBeload={this.handleCloseToBeload}
              handleCloseToBeload={this.handleClose}
            />
          ))}

        <div style={{ marginTop: '-3px' }}>
          <div className="card-column-headerbal" style={{}}>
            <div className="dargcard-time">{data.delivery_time}</div>
            <div
              style={{
                width: '8%',
              }}
            >
              <div style={{ height: '40px', width: '30px' }}>
                <img
                  src={getSrcImg(data.status, 1, data.topic, data.is_buyer)}
                />
              </div>
            </div>

            <div className="dargcard-Name">
              {this.findAndReplace(data.name)}
            </div>
            <div
              style={{
                width: '8%',
              }}
            >
              <div style={{ height: '35px', width: '30px' }}>
                {data.freight_included === 1 && <Image src={truckIcon} />}
              </div>
            </div>
            <div className="dargcard-partner">{data.partner}</div>
            <div
              onClick={() => this.handleOpen()}
              style={{
                width: '5%',
                height: '100%',
              }}
              className="column-drag-handle"
            >
              <img
                style={{
                  width: '20px',
                  height: '20px',
                }}
                src="/static/media/editIcon.fc6f2b03.svg"
              />{' '}
            </div>

            <div
              style={{
                width: '4%',
                fontSize: '15px',
              }}
              className="column-drag-handle"
            >

              {typeof this.props.producer.isFinalized === 'undefined' && typeof data.status !== 'undefined' &&
                (data.status === 'BOUGHT' ||
                  (data.status === 'TOUR_ASSIGNED' &&
                    data.isBlocked === 0)) && <div> &#x2630;</div>}
            </div>
          </div>
          <div className="card-column-headerbaltwo">
            <div className="dargcard-partnerTwo">
              {
                statusOrders.find(
                  (val) => data.status.split('_').join(' ') === val.statusOrder,
                ).label
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getProducer: (date) => dispatch(producerActions.getAllProducer(date)),
  };
}

const mapStateToProps = (state) => {
  const { users, language, producer } = state.toJS();
  return {
    users,
    language,
    producer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CardOrder));
