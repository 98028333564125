import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Toggle from '../../components/Toggle';
import { appLocales } from '../../i18n';
import { changeLocale } from '../LanguageProvider/actions';
import messages from './messages';

export class LocaleToggle extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <Toggle
        value={this.props.language.locale}
        values={appLocales}
        messages={messages}
        onToggle={this.props.onLocaleToggle}
      />
    );
  }
}
LocaleToggle.propTypes = {
  onLocaleToggle: PropTypes.func,
  locale: PropTypes.string,
};
const mapStateToProps = state => {
  const { language } = state.toJS();
  return { language };
};

export function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle: evt => dispatch(changeLocale(evt.value)),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleToggle);
