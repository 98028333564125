import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import InvoicePagination from './Invoices/InvoicePagination';
import './invoice.css';
import Tabs from './Tabs.js';
import Banking from './Payment/Banking';
import { FormattedMessage } from 'react-intl';
import { alertActions } from '../../../actions';
import messages from '../messages';

const Invoicing = ({ ...props }) => {
  const [tab, setTab] = useState('tab1');
  const companyType = props.users.user.session.company_type;
  const checkCreditCardSuccessMsg = () => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const isSuccess = params.get('success') === 'true';
    if (params.get('token') && params.get('success')) {
      if (isSuccess) {
        setTab('tab2');
        props.history.push('/admin/invoices');
        const timer = setTimeout(() => {
          props.success('Payment option updated');
          props.clear();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setTab('tab2');
        props.history.push('/admin/invoices');
        const timer = setTimeout(() => {
          props.error('Problem in Card');
          props.clear();
        }, 1000);
        return () => clearTimeout(timer);
      }
      // eslint-disable-next-line no-unreachable
      return;
    }
  };

  useEffect(() => {
    checkCreditCardSuccessMsg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const content = {
    invTab: <FormattedMessage {...messages.Invoicing} />,
    bankTab: <FormattedMessage {...messages.BankDetails} />,
    tab1: <InvoicePagination />,
    tab2: <Banking setTab={setTab} />,
  };

  return (
    <div>
      <div id="box-container">
        <div className="table-header">
          <div className="header-container">
            <div className="table-header-tabs">
              <Tabs active={tab} onChange={(active) => setTab(active)}>
                <div key="tab1">{content.invTab}</div>
                <div key="tab2">{companyType !== '3' && content.bankTab}</div>
              </Tabs>
            </div>
            <div className="hr" />
          </div>
          <div className="hr" />
          <div className="header-side-title">
            <div className="side-title">
              <FormattedMessage {...messages.Accounting} />
            </div>
          </div>
        </div>
        <div>{content[tab]}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoicing);
