import React, { Component } from 'react';
import MessagePopup from '../addEartags';

class TruckManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      itemToRemove: '',
    };
  }

  renderEartagList = eartagListe => {
    const { handlePopup, setEartag } = this.props;
    return eartagListe.map((item, index) => (
      <li key={index}>
        <div className="truck-group-list-eartag to-be-loaded">
          <div className="eartag">{item.ear_tag_output_format}</div>
          <div className="icon-group">
            <div
              className="icon-position icon-eartag show-eartag"
              onClick={() => setEartag(item)}
            />
            <div
              className="icon-position icon-margin delete-eartag"
              onClick={() => {
                this.setState({ itemToRemove: item });
                handlePopup(item);
              }}
            />
          </div>
        </div>
      </li>
    ));
  };

  render() {
    const {
      eartagListe,
      checkRemove,
      handlePopup,
      deleteEartag,
      eartagListeSplite,
    } = this.props;
    const { itemToRemove } = this.state;
    return (
      <React.Fragment>
        <MessagePopup
          show={checkRemove}
          togglePopup={handlePopup}
          message={'eartags'}
          listeEartag={eartagListe}
          deleteEartag={deleteEartag}
          itemToRemove={itemToRemove}
        />
        <div className="list-eartag-block">
          {this.props.truck.has_orders_split === 0 && (
            <ul>{this.renderEartagList(eartagListe)}</ul>
          )}
          {this.props.truck.has_orders_split === 1 && (
            <ul>{this.renderEartagList(eartagListeSplite)}</ul>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default TruckManagement;
