import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { alertActions, OrderActions } from '../../../../../actions';
import { Button, ButtonGroup, Row, Modal } from 'react-bootstrap';
import clsx from 'clsx';

import SvgIcon from '../../../../Shared/components/icons/SvgIcon/SvgIcon';
import RegularInput from '../../../../Shared/components/inputs/RegularInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OrderService } from '../../../../../services/order.service';
import { getContactPreferences } from '../../../../../services/company.service';
import extractData from '../../../../../services/data.processing';
import Popup from '../../../../Popup';
import messages from '../../../messages';
import HandleConfirmButton from '../handleConfirmeButton';
import ViewStock from '../../../ViewStock';
import OpenBuyings from './openBuyings/OpenBuyings';
import handleConfirm from '../handleConfirmeButton/handleConfirm';
import { svgPaths } from '../../../../../constants';
import { addLeadingZero } from '../../../../../helpers/order';
import { getCompany } from '../../../../../services/company.service';
import NormalButton from '../../../../Shared/components/buttons/NormalButton/NormalButton';
import locale from '../../../../../utils/DataPickerLang';

import './orderDetails.css';

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery_time: '',
      delivery_date: this.props.orderDetail.delivery_date,
      name: this.props.name,
      orderDetail: this.props.orderDetail,
      confirm: true,
      showPopup: false,
      isBuyer: this.props.orderDetail.is_buyer,
      order: {},
      startDate: new Date(),
      amount: 0,
      number: 0,
      showForm: false,
      earTags: [],
      freight_included: '',
      price_per_kg: null,
      breed: [],
      race: [],
      showItemStock: true,
      senderType: '',
      recType: '',
      case: '1',
      editText: false,
      open: false,
      bottonShow: true,
      message: '',
      submitMessage: '',
      companyNumber: this.getCompanies().options[0].value,
      onDatePickerFocus: false,
      onDatePickerBlur: true,
      onAmountFocus: false,
      orderStatus: '',
      showOpenBuyings: false,
      openBuyings: [],
      openBuyingsOrderIds: [],
      trader_extra: null,
      showPopkifeupEart: false,
      mobileNumber: '',
      selectCompany: '',
      selectCompanyId: {},
      isLoaded: false,
      cattle_group: [],
    };
  }

  handleStock = () => {
    this.setState({ showItemStock: !this.state.showItemStock });
  };

  getBoughtOrders = (orderChecked, orderId) => {
    orderChecked
      ? this.setState({
          openBuyingsOrderIds: [...this.state.openBuyingsOrderIds, orderId],
        })
      : this.setState({
          openBuyingsOrderIds: this.state.openBuyingsOrderIds.filter(
            (id) => id !== orderId,
          ),
        });
  };

  togglePopupEart = () => {
    this.setState({
      showPopupEart: true,
    });
  };

  handleCloseEart = () => {
    this.setState({
      showPopupEart: false,
    });
  };

  handleChangeCompany = (e) => {
    var value = e.value;
    this.setState({ companyNumber: value });
  };

  getCurrentCompany = async () => {
    let token = await this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const company = await extractData(value);
      this.setState({
        trader_extra: company.trader_extra,
      });
    }
  };

  getCompanies = () => {
    let companies =
      typeof this.props.users.users !== 'undefined' &&
      this.props.users.users.length >= 1
        ? this.props.users.users[0].companies
        : this.props.users.user.companies;
    let options = [];
    companies.forEach(function (val, index) {
      options.push({ value: val, label: val.company_number });
    });
    return {
      options: options,
    };
  };

  renderDate = (timeStamp) => {
    const date = new Date(timeStamp);
    return `${addLeadingZero(date.getDate())}.${addLeadingZero(
      date.getMonth() + 1,
    )}.${date.getFullYear()}`;
  };

  handleChangeDate = (date) => {
    if (date) {
      const year = `${date.getFullYear()}`;
      const newDate = `${addLeadingZero(date.getDate())}.${addLeadingZero(
        date.getMonth() + 1,
      )}.${year.substr(2, 3)}`;
      this.setState({
        startDate: date,
        dateChange: true,
        delivery_date: newDate,
      });
    }
  };

  UNSAFE_componentWillMount() {
    this.setState({
      orderDetail: this.props.orderDetail,
      deliveryDate: this.props.orderDetail.delivery_date,
      isLoaded: false,
    });
    this.getcompanyper();

    if (this.props.orderDetail.status !== 'TO_BE_LOADED') {
      this.editeOrderDetails(this.props.orderDetail);
    }
  }

  getcompanyper = async () => {
    await this.SenderType().then(() => {
      let companies =
        typeof this.props.users.users !== 'undefined' &&
        this.props.users.users.length >= 1
          ? this.props.users.users[0].companies
          : this.props.users.user.companies;
      let companySelected = this.props.users.user.session;
      let options = [];

      companies.forEach(function (val, index) {
        options.push({ value: val, label: val.company_number });
      });

      if (this.state.selectCompany !== '') {
        let companyPer = options.filter(
          (id) => id.label === this.state.selectCompany,
        );

        companySelected =
          typeof companyPer[0] !== 'undefined'
            ? companyPer[0].value
            : this.props.users.user.session;
      }

      let companyPerSelected = {
        value: companySelected,
        label: companySelected.company_number,
      };

      this.setState({ selectCompanyId: companyPerSelected, isLoaded: true });
    });
  };

  componentDidMount() {
    this.getcompanyper();
    this.getCurrentCompany();

    let currentDeliveryDate = new Date();
    currentDeliveryDate.setDate(currentDeliveryDate.getDate() + 1);
    this.setState({ startDate: currentDeliveryDate });
    this.calendarRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Load new data when the dataSource property changes.
    if (this.props.orderDetail !== nextProps.orderDetail) {
      this.setState({
        orderDetail: nextProps.orderDetail,
        deliveryDate: nextProps.orderDetail.delivery_date,
        isLoaded: false,
      });
      this.getcompanyper();

      if (nextProps.orderDetail.status !== 'TO_BE_LOADED') {
        this.editeOrderDetails(nextProps.orderDetail);
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.getCurrentCompany();
      this.getcompanyper();
    }
  }

  handleDeliveryBy = (value) => {
    this.setState({
      freight_included: value,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'delivery_time':
        this.setState({ delivery_time: value });
        break;
      case 'number':
        this.setState({ number: value });
        break;
      case 'amount':
        this.setState({ amount: value ? value : 0 });
        break;
      case 'breed':
        this.setState({ breed: [...value] });
        break;
      case 'price':
        this.setState({ price_per_kg: value });
        break;
      default:
        break;
    }
  };

  handleReject = async (e, message) => {
    const { orderDetail } = this.props;
    let token = await this.props.users.accessToken;
    if (['Cancel', 'Reject'].indexOf(message) > -1) {
      await this.props.deleteOrder(orderDetail.order_id);
      await this.props.handleClosePanel();
      await this.props.getOrders();
      this.props.success(
        this.props.language.language === 'EN'
          ? `${orderDetail.name}   successfully updated`
          : `${orderDetail.name}  erfolgreich aktualisiert`,
      );
      this.props.clear();
    } else if (['Delete', 'Cancelable'].indexOf(message) > -1) {
      await OrderService.cancelOrder(
        token,
        orderDetail.order_id,
        orderDetail.receiver_id,
      );
      await this.props.handleClosePanel();
      await this.props.getOrders();
      this.props.success(
        this.props.language.language === 'EN'
          ? `${orderDetail.name}   successfully updated`
          : `${orderDetail.name}  erfolgreich aktualisiert`,
      );
      this.props.clear();
    } else if (message === 'Rejectable') {
      await OrderService.rejectOrder(
        token,
        orderDetail.order_id,
        orderDetail.receiver_id,
      );
      await this.props.handleClosePanel();
      await this.props.getOrders();
      this.props.success(
        this.props.language.language === 'EN'
          ? `${orderDetail.name} successfully updated`
          : `${orderDetail.name} erfolgreich aktualisiert`,
      );
      this.props.clear();
    }
  };

  handleSubmit = async (event, messages) => {
    event.preventDefault();
    // window.prompt('OKAY');
    const { orderDetail } = this.props;
    const {
      companyNumber,
      deliveryDate,
      delivery_date,
      delivery_time,
      price_per_kg,
      number,
      amount,
      isBuyer,
      earTags,
      freight_included,
    } = this.state;
    let token = await this.props.users.accessToken;
    const data = {
      company_number: companyNumber.company_number,
      ear_tags: earTags,
      freight_included:
        typeof freight_included !== 'undefined' && freight_included !== null
          ? freight_included
          : 0,
      delivery_date,
      delivery_time,
      number,
      amount,
      price_per_kg: !isNaN(price_per_kg) ? price_per_kg : 0,
    };
    if (messages === 'Confirm') {
      await this.props.confirmOrder(
        orderDetail.order_id,
        orderDetail.receiver_id,
        data,
        isBuyer,
      );
      await this.props.handleClosePanel();
      await this.props.getOrders();
      this.props.success(
        this.props.language.language === 'EN'
          ? `${orderDetail.name}   successfully updated`
          : `${orderDetail.name}  erfolgreich aktualisiert`,
      );
      this.props.clear();
    } else if (messages === 'toAccept') {
      await OrderService.acceptOrder(
        orderDetail.order_id,
        token,
        orderDetail.receiver_id,
        data,
      );
      await this.props.handleClosePanel();
      await this.props.getOrders();
      this.props.clear();
    } else if (messages === 'AcceptABT') {
      this.setState({ message: messages, showForm: true });
    } else if (messages === 'AcceptABTOpenBuyings') {
      this.setState({
        message: messages,
        showForm: true,
        showOpenBuyings: true,
      });
      this.getOpenBuyings();
    } else if (messages === 'Request') {
      this.setState({ message: messages, showForm: true });
    } else if (messages === 'Accept') {
      this.setState({ message: messages, showForm: true });
    } else if (messages === 'Reconfirm') {
      // delivery_time !== '' &&
        deliveryDate !== '' &&
        number !== '' &&
        amount !== '' &&
        (await OrderService.reconfirmOrder(
          orderDetail.order_id,
          token,
          orderDetail.receiver_id,
          data,
        ));
      this.props.clear();
      await this.props.handleClosePanel();
      await this.props.getOrders();
      this.props.success(
        this.props.language.language === 'EN'
          ? `${orderDetail.name}   successfully updated`
          : `${orderDetail.name}  erfolgreich aktualisiert`,
      );
      this.props.clear();
    } else if (messages === 'continueAsExternal') {
      await OrderService.continueAsExternal(
        orderDetail.order_id,
        orderDetail.receiver_id,
        token,
      );
      this.props.clear();
      await this.props.handleClosePanel();
      await this.props.getOrders();
      this.props.success(
        this.props.language.language === 'EN'
          ? `${orderDetail.name}   successfully updated`
          : `${orderDetail.name}  erfolgreich aktualisiert`,
      );
      this.props.clear();
    }
  };

  editeOrderDetails = (orderDetail) => {
    const { is_buyer, status } = orderDetail;
    const confirm = handleConfirm(status, is_buyer);
    this.setState({ confirm });
  };

  getOpenBuyings = async () => {
    let token = this.props.users.accessToken;
    const buyings = await OrderService.getOpenBuyings(token);
    const openBuyings = await extractData(buyings);
    this.setState({ openBuyings: openBuyings });
  };

  getSubmitMessage = (e, message) => {
    if (
      ['AcceptABT', 'AcceptABTOpenBuyings', 'continueAsExternal'].indexOf(
        message,
      ) > -1
    ) {
      this.handleSubmit(e, message);
    } else {
      this.handleReject(e, message);
      this.setState({ orderStatus: message });
    }
  };
  renderConfirmCancelButtons = () => {
    const {
      receiver_id,
      order_id,
      is_buyer,
      topic,
      status,
    } = this.props.orderDetail;
    const { openBuyingsOrderIds } = this.state;
    if (
      (status !== 'SOLD' &&
        status !== 'RESOLD' &&
        status !== 'TOUR_PLANNING' &&
        status !== 'TO_BE_LOADED' &&
        status !== 'LOADED' &&
        status !== 'REJECTED' &&
        status !== 'CANCELED' &&
        status !== 'DELIVERED' &&
        status !== 'ON_THE_WAY' &&
        status !== 'UNLOADED' &&
        status !== 'COMPLETED') ||
      openBuyingsOrderIds.length > 0
    ) {
      return (
        <HandleConfirmButton
          order_id={order_id}
          receiver_id={receiver_id}
          is_buyer={is_buyer}
          topic={topic}
          isCancelable={this.props.orderDetail.isCancelable}
          getSubmitMessage={this.getSubmitMessage}
          status={status}
        />
      );
    }
  };

  handleFlatRateBlur = () => {
    var num = parseFloat(this.state.amount);
    var twoDecimal = num.toFixed(2);
    this.setState({ amount: twoDecimal });
  };

  AddZero = (amount) => {
    var num = parseFloat(amount);
    var twoDecimal = num.toFixed(2);
    return twoDecimal;
  };

  handlePriceKgBlur = () => {
    var priceKg = parseFloat(this.state.price_per_kg);
    var twoDecimal = priceKg.toFixed(2);
    this.setState({ price_per_kg: twoDecimal });
  };

  renderListStocks = () => {
    this.props.handlelShow(false);
    return this.state.showOpenBuyings ? (
      <div style={{ height: '96%', overflow: 'hidden' }}>
        {' '}
        <OpenBuyings
          boughtOrders={this.state.openBuyings}
          getBoughtOrders={this.getBoughtOrders}
        />
      </div>
    ) : (
      <ViewStock
        sellOffer={true}
        showSellForm={this.showOrderForm}
        showDetailStock={this.state.showForm}
        breeds={this.state.breed}
        races={this.state.race}
        handleStock={this.handleStock}
        capacity={this.state.number}
        companyNumber={this.state.companyNumber}
      />
    );
  };

  showOrderForm = async (earTags, showForm) => {
    this.setState({
      showForm,
    });
    const earTagsList = [];
    earTags.map((item) => earTagsList.push(item.ear_tag));
    const { orderDetail, companyNumber } = this.props;
    const {
      delivery_date,
      deliveryDate,
      delivery_time,
      number,
      amount,
      message,
      openBuyingsOrderIds,
    } = this.state;
    const data = {
      freight_included: this.state.freight_included,
      company_number: companyNumber,
      delivery_date,
      delivery_time,
      number,
      amount,
    };
    if (message !== 'AcceptABTOpenBuyings') {
      data.ear_tags = earTagsList;
    } else {
      data.orders = openBuyingsOrderIds;
    }
    if (
      ['AcceptABTOpenBuyings', 'AcceptABT'].indexOf(message) > -1 ||
      message === 'Accept'
    ) {
      delivery_time !== '' &&
        deliveryDate !== '' &&
        number !== '' &&
        amount !== '' &&
        (await this.props.confirmOrder(
          orderDetail.order_id,
          orderDetail.receiver_id,
          data,
          0,
        ));
    } else {
      delivery_time !== '' &&
        deliveryDate !== '' &&
        number !== '' &&
        amount !== '' &&
        (await this.props.acceptOrder(
          orderDetail.order_id,
          orderDetail.receiver_id,
          data,
        ));
    }
    this.props.success(
      this.props.language.language === 'EN'
        ? `${orderDetail.name}   successfully updated`
        : `${orderDetail.name}  erfolgreich aktualisiert`,
    );
    await this.props.handleClosePanel();
    this.props.clear();
    await this.props.getOrders();
  };

  handleCancel = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  togglePopup = (e) => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  handleClose = () => {
    this.setState({
      showPopup: false,
    });
  };

  saveChanges = async () => {
    let token = this.props.users.accessToken;
    const { order_id, receiver_id } = await this.props.orderDetail;
    await OrderService.cancelOrder(token, order_id, receiver_id);
    this.setState({ showPopup: false });
  };

  SenderType = async () => {
    const token = this.props.users.accessToken;
    const companyId = this.props.users.user.session.company_id;
    const { order_id, receiver_id } = this.props.orderDetail;
    const comp = await OrderService.getOrderDetails(
      token,
      order_id,
      receiver_id,
    );

    const user = await extractData(comp);

    var array =
      typeof user.order.cattle_group !== 'undefined' &&
      user.order.cattle_group !== null
        ? user.order.cattle_group
        : [];

    var result = [];
    array.reduce(function (res, value) {
      if (!res[value.breed]) {
        res[value.breed] = { breed: value.breed, number: 0 };
        result.push(res[value.breed]);
      }
      res[value.breed].number += value.number;
      return res;
    }, {});

    this.setState({
      cattle_group: result,
    });

    const {
      delivery_time,
      number,
      amount,
      sender_company,
      delivery_date,
      price_per_kg,
      status,
    } = user.order;
    const { mobile_number } = user.contact;
    const flatRateResponse = await getContactPreferences(
      token,
      companyId,
      sender_company,
    );
    if (flatRateResponse.status === 200) {
      const rateValue = await extractData(flatRateResponse);
      this.setState({
        order: user.order,
        delivery_time: delivery_time,
        number,
        amount:
          rateValue && status === 'OFFER'
            ? this.AddZero(rateValue.flat_rate)
            : this.AddZero(amount),
        delivery_date,
        price_per_kg: this.AddZero(price_per_kg),
        race: user.order.race.split(', '),
        breed: user.order.breed.split(', '),
        mobileNumber: mobile_number,
        selectCompany:
          rateValue && typeof rateValue.company_number !== 'undefined'
            ? rateValue.company_number
            : '',
        freight_included:
          rateValue && status === 'OFFER' ? rateValue.freight_included : '',
      });

      if (typeof user !== 'undefined' && typeof user.order !== 'undefined')
        this.setState({
          senderType: user.order.sender_business_type,
          recType: user.order.receiver_business_type,
          freight_included: user.order.freight_included,
        });
    } else {
      this.setState({
        senderType: user.order.sender_business_type,
        recType: user.order.receiver_business_type,
        freight_included: user.order.freight_included,
      });
    }
    await this.canEdit();
    await this.canEdit();
  };

  canEdit = async () => {
    const { is_buyer, status } = this.props.orderDetail;
    let recType = this.state.recType;
    let senType = this.state.senderType;
    if (status === 'REQUEST' || status === 'OFFER') {
      this.setState({ editText: true });
    } else if (status === 'TO_BE_RECONFIRMED') {
      if (is_buyer === 1) {
        if (senType === '1' && recType === '2') {
          this.setState({ editText: false });
        }
        if (senType === '2' && recType === '1') {
          this.setState({ editText: true });
        } else {
          this.setState({ editText: senType === '3' });
        }
      } else {
        if (senType === '1' && recType === '2') {
          this.setState({ editText: false });
        }
        if (senType === '2' && recType === '1') {
          this.setState({ editText: false });
        } else {
          this.setState({ editText: senType === '1' && recType === '1' });
        }
      }
    } else {
      this.setState({ editText: false });
    }
  };
  handleFocus = (type) => {
    this.setState({ [`on${type}Blur`]: false, [`on${type}Focus`]: true });
  };

  handleBlur = (type) => {
    this.setState({ [`on${type}Blur`]: true, [`on${type}Focus`]: false });
  };

  showPopupEartgs = () => {
    this.togglePopupEart();
  };

  renderDetailsOrder = () => {
    const {
      number,
      amount,
      mobileNumber,
      delivery_time,
      onDatePickerFocus,
      onDatePickerBlur,
      onAmountFocus,
      orderStatus,
      price_per_kg,
      trader_extra,
      order,
    } = this.state;
    const companyType = this.props.users.user.session.company_type;
    const status = this.props.orderDetail.status;
    return (
      <div className="order-details">
        <Modal
          show={this.state.showPopupEart}
          onHide={this.handleCloseEart}
          style={{ opacity: 1 }}
          className="modalsize"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="deorder-ertagsp">
            <div className="flexbox">
              <div className="linestyle">
                {typeof order !== 'undefined' &&
                  typeof order.ear_tags !== 'undefined' &&
                  order.ear_tags.length > 0 && (
                    <div>
                      {' '}
                      <b style={{ color: '#7bd812' }}>
                        <FormattedMessage {...messages.EarTags} />
                      </b>{' '}
                    </div>
                  )}

                {typeof order !== 'undefined' &&
                  typeof order.ear_tags !== 'undefined' &&
                  order.ear_tags.map((e) => (
                    <div>
                      <div style={{ marginBottom: '8px', marginTop: '8px' }}>
                        {e.ear_tag_output_format + ' ' + e.breed + ' ' + e.race}
                      </div>
                      <hr className="style-border" />
                    </div>
                  ))}

                {this.state.cattle_group.length > 0 && (
                  <div>
                    {' '}
                    <b style={{ color: '#7bd812' }}>
                      {' '}
                      <FormattedMessage {...messages.NewBreed} />
                    </b>{' '}
                  </div>
                )}
                {this.state.cattle_group.length > 0 &&
                  typeof this.state.cattle_group !== 'undefined' &&
                  this.state.cattle_group.map((e) => (
                    <div>
                      <div style={{ marginBottom: '8px', marginTop: '8px' }}>
                        {e.number + ' ' + e.breed}
                      </div>
                      <hr className="style-border" />
                    </div>
                  ))}
              </div>

              <Button className="btn-filter" onClick={this.handleCloseEart}>
                Ok
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <form
          className="order-details-form-group"
          onSubmit={(event) => this.handleSubmit(event, orderStatus)}
        >
          <div className="order-details-information-fields-container">
            <div className="order-details-information-fields-top-part">
              <div className="order-details-transported-by">
                <p className="transported-by-text">
                  <FormattedMessage {...messages.TransportedBy} />
                </p>
                <ButtonGroup
                  id="delivery-button-group"
                  aria-label="Basic example"
                >
                  <Button
                    className={`btn-white ${
                      this.state.freight_included === 0 ? 'btn-active' : ''
                    }`}
                    variant="secondary"
                    onClick={(e) => this.handleDeliveryBy(0)}
                    disabled={!this.state.editText}
                  >
                    <span>
                      <FormattedMessage {...messages.Buyer} />
                    </span>
                  </Button>
                  <Button
                    className={`btn-white ${
                      this.state.freight_included === 1 ? 'btn-active' : ''
                    }`}
                    variant="secondary"
                    onClick={(e) => this.handleDeliveryBy(1)}
                    disabled={!this.state.editText}
                  >
                    <span>
                      <FormattedMessage {...messages.Seller} />
                    </span>
                  </Button>
                </ButtonGroup>
              </div>

              <Row className="form-order-details-information-fields">
                <div className="order-details-form">
                  <RegularInput
                    picker
                    label={
                      this.state.freight_included === 0 ? (
                        <FormattedMessage {...messages.DeliveryUntilBuyer} />
                      ) : (
                        <FormattedMessage {...messages.DeliveryUntil} />
                      )
                    }
                    type="text"
                    value={this.state.delivery_date}
                    name="deliveryDate"
                    onFocus={() => this.handleFocus('DatePicker')}
                    onBlur={() => this.handleBlur('DatePicker')}
                    onChange={(e) => this.handleChange(e)}
                    onClick={() => {
                      this.calendarRef.current.setOpen(true);
                    }}
                    disabled={!this.state.editText}
                  />
                  <div className="date-field picker">
                    <SvgIcon
                      color={clsx(
                        onDatePickerFocus && '#7bd811',
                        onDatePickerBlur && '#9f9f9f',
                      )}
                      onClick={this.checkDatePickerState}
                      size="30"
                      viewBox="0 0 600 600"
                    >
                      {svgPaths['calendar']}
                    </SvgIcon>

                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleChangeDate}
                      disabled={!this.state.editText}
                      dateFormat="dd.MM.yyyy"
                      ref={this.calendarRef}
                      locale={locale}
                    />
                  </div>
                </div>
                <RegularInput
                  label={
                    this.state.freight_included === 0 ? (
                      <FormattedMessage {...messages.PreferdDTBuyer} />
                    ) : (
                      <FormattedMessage {...messages.PreferdDT} />
                    )
                  }
                  value={delivery_time}
                  type="time"
                  name="delivery_time"
                  required
                  placeholder="HH:mm"
                  min="00:00"
                  max="23:59"
                  disabled={!this.state.editText}
                  onChange={(e) => this.handleChange(e)}
                />
                <RegularInput
                  inputGroup
                  label={<FormattedMessage {...messages.flateRate} />}
                  value={amount > 0 && amount}
                  type="number"
                  name="amount"
                  onBlur={this.handleFlatRateBlur}
                  onChange={(e) => this.handleChange(e)}
                  disabled={!this.state.editText}
                />
                {typeof order !== 'undefined' &&
                typeof order.ear_tags !== 'undefined' &&
                (order.ear_tags.length > 0 ||
                  this.state.cattle_group.length > 0) ? (
                  <RegularInput
                    label={<FormattedMessage {...messages.amount} />}
                    value={number}
                    earTags
                    showPopupEartgs={(e) => this.showPopupEartgs(e)}
                    lang={
                      this.props.language.language.locale === 'EN'
                        ? order.ear_tags.length > 0 &&
                          this.state.cattle_group.length > 0
                          ? 'Eartag'
                          : order.ear_tags.length === 0 &&
                            this.state.cattle_group.length > 0
                          ? 'Breed'
                          : 'Eartag'
                        : order.ear_tags.length > 0 &&
                          this.state.cattle_group.length > 0
                        ? 'Ohrmarke'
                        : order.ear_tags.length === 0 &&
                          this.state.cattle_group.length > 0
                        ? 'Gattung'
                        : 'Ohrmarke'
                    }
                    type="text"
                    name="number"
                    onFocus={() => this.handleFocus('Amount')}
                    onBlur={() => this.handleBlur('Amount')}
                    className={clsx(
                      onAmountFocus && 'focused',
                      !onAmountFocus && 'blured',
                    )}
                    onChange={(e) => this.handleChange(e)}
                    disabled={!this.state.editText}
                  />
                ) : (
                  <RegularInput
                    label={<FormattedMessage {...messages.amount} />}
                    value={number}
                    type="text"
                    name="number"
                    onFocus={() => this.handleFocus('Amount')}
                    onBlur={() => this.handleBlur('Amount')}
                    className={clsx(
                      onAmountFocus && 'focused',
                      !onAmountFocus && 'blured',
                    )}
                    onChange={(e) => this.handleChange(e)}
                    disabled={!this.state.editText}
                  />
                )}
                {((this.getCompanies().options.length > 1 &&
                  status === 'REQUEST') ||
                  (this.getCompanies().options.length > 1 &&
                    status === 'OFFER' &&
                    companyType === '2') ||
                  (this.getCompanies().options.length > 1 &&
                    status === 'OFFER' &&
                    companyType === '3')) && (
                  <RegularInput
                    label={<FormattedMessage {...messages.CompanyNumber} />}
                    select
                    className="selected company-number"
                    defaultValue={this.state.selectCompanyId}
                    placeholder="Select Company"
                    name="company number"
                    options={this.getCompanies().options}
                    onChange={this.handleChangeCompany}
                    required
                    // isDisabled={this.props.orderType === 'sellings'}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#F1FFDE',
                        primary: '#7bcd10',
                      },
                    })}
                  />
                )}
                {this.props.orderType === 'buyings' &&
                  companyType === '2' &&
                  trader_extra &&
                  !!trader_extra.is_producer && (
                    <RegularInput
                      inputGroup
                      label={<FormattedMessage {...messages.PricePerKg} />}
                      value={price_per_kg}
                      type="number"
                      name="price"
                      onBlur={this.handlePriceKgBlur}
                      className={clsx(
                        onAmountFocus && 'focused',
                        !onAmountFocus && 'blured',
                      )}
                      onChange={(e) => this.handleChange(e)}
                      disabled={!this.state.editText}
                    />
                  )}
                {/* <RegularInput
                  label={<FormattedMessage {...messages.mobileNumber} />}
                  value={mobileNumber}
                  type="number"
                  name="mobile_number"
                  disabled={true}
                /> */}
              </Row>
            </div>
            <div className="order-details-information-fields-bottom-part">
              {this.props.orderDetail.status !== 'TO_BE_LOADED' &&
                this.renderConfirmCancelButtons()}
            </div>
          </div>
        </form>
        <Popup
          show={this.state.showPopup}
          onHide={this.togglePopup}
          handleClose={this.handleClose}
          saveChanges={this.saveChanges}
          text={messages.CancelOrder}
        />
      </div>
    );
  };

  render() {
    const {
      showForm,
      openBuyingsOrderIds,
      showOpenBuyings,
      isLoaded,
    } = this.state;
    if (!isLoaded) {
      // not loaded
      return (
        <div>
          {/* <div key={0} className="loader-wrapper">
            <div className="loader" key={0}></div>
          </div> */}
        </div>
      );
    }
    return (
      <React.Fragment>
        {showForm && this.state.showItemStock
          ? this.renderListStocks()
          : this.renderDetailsOrder()}
        {showOpenBuyings && (
          <div className="open-buyings-button">
            <NormalButton
              disabled={openBuyingsOrderIds.length === 0}
              onClick={() => this.showOrderForm([], true)}
            >
              <FormattedMessage {...messages.ConfirmButton} />
            </NormalButton>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    confirmOrder: (orderId, recieverId, data) =>
      dispatch(OrderActions.confirmOrder(orderId, recieverId, data)),
    acceptOrder: (orderId, recieverId, data) =>
      dispatch(OrderActions.acceptOrder(orderId, recieverId, data)),
    deleteOrder: (orderId) => dispatch(OrderActions.deleteOrder(orderId)),
  };
}

const mapStateToProps = (state) => {
  const { users, sellings, buyings } = state.toJS();
  const language = state.toJS();
  return {
    users,
    sellings,
    buyings,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
