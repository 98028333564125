export const tags = [
  {
    value: 'Belgium',
    label: 'BE',
    iso: '56',
    inputFormat: 'BE 1 23456789',
    nbDigits: 9,
    memoryFormat: '056000123456789',
    outputFormat: 'BE123456789',
  },
  {
    value: 'Bulgaria',
    label: 'BG',
    iso: '100',
    inputFormat: 'BG12345678',
    nbDigits: 8,
    memoryFormat: '100000012345678',
    outputFormat: 'BG12345678',
  },
  {
    value: 'Denmark',
    label: 'DK',
    iso: '208',
    inputFormat: 'DK123456789',
    nbDigits: 9,
    memoryFormat: '208000123456789',
    outputFormat: 'DK123456789',
  },
  {
    value: 'Germany',
    label: 'DE',
    iso: '276',
    inputFormat: 'DE1234567890',
    nbDigits: 10,
    memoryFormat: '276001234567890',
    outputFormat: 'DE1234567890',
  },
  {
    value: 'England',
    label: 'UK',
    iso: '826',
    inputFormat: 'UK123456789012',
    nbDigits: 12,
    memoryFormat: '826123456789012',
    outputFormat: 'UK123456789012',
  },
  {
    value: 'Estonia',
    label: 'EE',
    iso: '233',
    inputFormat: 'EE1234567890',
    nbDigits: 10,
    memoryFormat: '233001234567890',
    outputFormat: 'EE1234567890',
  },
  {
    value: 'Finland',
    label: 'FI',
    iso: '246',
    inputFormat: 'FI234567890',
    nbDigits: 9,
    memoryFormat: '246000234567890',
    outputFormat: 'FI234567890',
  },
  {
    value: 'France',
    label: 'FR',
    iso: '250',
    inputFormat: 'FR1234567890',
    nbDigits: 10,
    memoryFormat: '250001234567890',
    outputFormat: 'FR1234567890',
  },
  {
    value: 'Greece',
    label: 'EL',
    iso: '300',
    inputFormat: 'EL123456789012',
    nbDigits: 12,
    memoryFormat: '300123456789012',
    outputFormat: 'EL123456789012',
  },
  {
    value: 'Ireland',
    label: 'IE',
    iso: '372',
    inputFormat: 'IE1234567 8 9012',
    nbDigits: 12,
    memoryFormat: '372123456789012',
    outputFormat: 'IE123456789012',
  },
  {
    value: 'Italy',
    label: 'IT',
    iso: '380',
    inputFormat: 'IT123456789012',
    nbDigits: 12,
    memoryFormat: '380123456789012',
    outputFormat: '',
  },
  {
    value: 'Croatia',
    label: 'HR',
    iso: '191',
    inputFormat: 'HR1234567890',
    nbDigits: 10,
    memoryFormat: '191001234567890',
    outputFormat: 'HR1234567890',
  },
  {
    value: 'Latvia',
    label: 'LV',
    iso: '428',
    inputFormat: 'LV 12345678 9012',
    nbDigits: 12,
    memoryFormat: '428123456789012',
    outputFormat: 'LV 12345678 901',
  },
  {
    value: 'Lithuania',
    label: 'LT',
    iso: '440',
    inputFormat: 'LT12345678',
    nbDigits: 8,
    memoryFormat: '440000012345678',
    outputFormat: 'LT12345678',
  },
  {
    value: 'Luxembourg',
    label: 'LU',
    iso: '442',
    inputFormat: 'LU123456',
    nbDigits: 6,
    memoryFormat: '442000000123456',
    outputFormat: 'LU123456',
  },
  {
    value: 'Malta',
    label: 'MT',
    iso: '470',
    inputFormat: 'MT123456789012',
    nbDigits: 12,
    memoryFormat: '470123456789012',
    outputFormat: 'MT123456789012',
  },
  {
    value: 'Netherlands',
    label: 'NL',
    iso: '528',
    inputFormat: 'NL123456789',
    nbDigits: 9,
    memoryFormat: '528000123456789',
    outputFormat: 'NL123456789',
  },
  {
    value: 'Austria',
    label: 'AT',
    iso: '40',
    inputFormat: 'AT123456 789',
    nbDigits: 9,
    memoryFormat: '040000123456789',
    outputFormat: 'AT123456 789',
  },
  {
    value: 'Poland',
    label: 'PL',
    iso: '616',
    inputFormat: 'PL123456789012',
    nbDigits: 12,
    memoryFormat: '616123456789012',
    outputFormat: 'PL12345678901',
  },
  {
    value: 'Portugal',
    label: 'PT',
    iso: '620',
    inputFormat: 'PT1234567890',
    nbDigits: 10,
    memoryFormat: '620001234567890',
    outputFormat: 'PT123456789',
  },
  {
    value: 'Romania',
    label: 'RO',
    iso: '642',
    inputFormat: 'RO123456785678',
    nbDigits: 12,
    memoryFormat: '642123000045678',
    outputFormat: 'RO123456785678',
  },
  {
    value: 'Sweden',
    label: 'SE',
    iso: '752',
    inputFormat: 'SE1234567890',
    nbDigits: 10,
    memoryFormat: '752001234567890',
    outputFormat: 'SE1234567890',
  },
  {
    value: 'Switzerland',
    label: 'CH',
    iso: '756',
    inputFormat: 'CH123456789012',
    nbDigits: 12,
    memoryFormat: '756123456789012',
    outputFormat: 'CH123456789012',
  },
  {
    value: 'Slovakia',
    label: 'SK',
    iso: '703',
    inputFormat: 'SK123456789',
    nbDigits: 9,
    memoryFormat: '703000123456789',
    outputFormat: 'SK123456789',
  },
  {
    value: 'Slovenia',
    label: 'SI',
    iso: '705',
    inputFormat: 'SI12345678',
    nbDigits: 8,
    memoryFormat: '705000012345678',
    outputFormat: 'SI12345678',
  },
  {
    value: 'Spain',
    label: 'ES',
    iso: '724',
    inputFormat: 'ES123456789012',
    nbDigits: 12,
    memoryFormat: '724123456789012',
    outputFormat: 'ES123456789012',
  },
  {
    value: 'CzechRepublic',
    label: 'CZ',
    iso: '203',
    inputFormat: 'CZ123456789',
    nbDigits: 9,
    memoryFormat: '203000123456789',
    outputFormat: 'CZ123456789',
  },
  {
    value: 'Hungary',
    label: 'HU',
    iso: '348',
    inputFormat: 'HU1234567890',
    nbDigits: 10,
    memoryFormat: '348001234567890',
    outputFormat: 'HU1234567890',
  },
  {
    value: 'Cyprus',
    label: 'CY',
    iso: '196',
    inputFormat: 'CY123456789',
    nbDigits: 9,
    memoryFormat: '196000123456789',
    outputFormat: 'CY123456789',
  },
];
