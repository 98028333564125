import { orderConstants } from '../constants';

const initialState = {
  sellings: {
    items: [],
    stats: [],
    dates: [],
  },
  resaleInformations: [],
};

export function sellings(state = initialState, action) {
  switch (action.type) {
    case orderConstants.SELLINGS_GET_ALL:
      return {
        ...state,
        sellings: action.sellings,
      };
    case orderConstants.SELL_CONFIRM:
      const sellConfirmed = action.order;
      return {
        ...state,
        sellings: {
          items: [...state.sellings.items, sellConfirmed],
          stats: state.sellings.stats,
        },
      };
    case orderConstants.SELL_ACCEPTED:
      const buyAccepted = action.order;
      return {
        ...state,
        sellings: {
          items: [...state.sellings.items, buyAccepted],
          stats: state.sellings.stats,
        },
      };
    case orderConstants.RESALE_OVERVIEW:
      return {
        ...state,
        resaleInformations: action.resaleInformations,
      };

    case orderConstants.RESET_SELL:
      return {
        ...state,
        sellings: action.resetSellings,
      };
    default:
      return state;
  }
}
