import Rebase from 're-base';
import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyBNyldjEt3mZOaGRZMYJz3AG-vbxpTkZfw',
  authDomain: 'agrabiz-8c47a.firebaseapp.com',
  databaseURL: 'https://agrabiz-8c47a.firebaseio.com',
  projectId: 'agrabiz-8c47a',
  storageBucket: 'agrabiz-8c47a.appspot.com',
  messagingSenderId: '117536991265',
};

firebase.initializeApp(config);
const db = firebase.database();
const base = Rebase.createClass(db);

export default base;
