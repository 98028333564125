import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Container,
  Image,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { MdLockOutline } from 'react-icons/md';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { style } from '../../utils/notifications';
import { alertActions } from '../../actions';
import extractData from '../../services/data.processing';
import { userService } from '../../services/user.service';
import img from '../../static/images/ad-user-add-new-plus-icon-512.png';
import logo from '../../static/images/logo.png';
import './main.css';

class InitUser extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
    };
  }

  addNotification = (type, message) => {
    let level;
    switch (type) {
      case 'alert-success':
        level = 'success';
        break;
      case 'alert-warning':
        level = 'warning';
        break;
      case 'alert-danger':
        level = 'error';
        break;
      case 'alert-info':
        level = 'info';
        break;
      default:
        break;
    }
    if (level) {
      this.refs.notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-bell" />,
        message: message,
        level: level,
        position: 'tc',
        autoDismiss: 5,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { alert } = this.props;
    if (alert.type) {
      this.addNotification(alert.type, alert.message);
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const passwordConfirm = this.passwordConfirm.value;
    const password = this.password.value;
    let token = this.props.match.params.token;

    try {
      if (password === passwordConfirm) {
        var value = await userService.setPassword(password, token);
        await extractData(value);

        this.props.history.push(`/login`);
      }
    } catch (error) {
      this.props.error('Something went wrong !');
      this.props.clear();
    }
  };

  redirectToHome = () => {
    this.props.history.push(`/`);
  };

  showPassword = () => {
    this.setState((prevState) => ({
      visiblePassword: !prevState.visiblePassword,
    }));
  };

  showPasswordConfirm = () => {
    this.setState((prevState) => ({
      visiblePasswordConfirm: !prevState.visiblePasswordConfirm,
    }));
  };

  render() {
    return (
      <Container id="reset-container" fluid>
        <Row className="header-reset flex-center">
          <Col sm={{ span: 3, offset: 1 }} onClick={this.redirectToHome}>
            <Image src={logo} />
          </Col>
          <Col sm={{ span: 4, offfset: 6 }}>
            <h3>
              {this.props.language.locale === 'DE'
                ? 'Registrierung abschließen'
                : 'Register User'}
            </h3>
          </Col>
        </Row>
        <Row>
          <NotificationSystem ref="notificationSystem" style={style} />
          <Col md={7} className="">
            <Row className="">
              <Col md={3} />
              <Col md={9}>
                <h6 className="color-grid">
                  {this.props.language.locale === 'DE'
                    ? 'Bitte geben Sie Ihr gewünschtes Passwort ein, um Ihre Registrierung abzuschließen und Zugang zu Agrabiz zu erhalten.'
                    : 'Please enter a password to finish the registration and login to your account.'}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col md={3} />
              <Col md={6}>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <MdLockOutline />
                      </InputGroup.Prepend>
                      <FormControl
                        size="sm"
                        type={this.state.visiblePassword ? 'text' : 'password'}
                        placeholder={
                          this.props.language.locale === 'DE'
                            ? 'Passwort'
                            : 'Password'
                        }
                        required
                        ref={(password) => {
                          this.password = password;
                        }}
                      />
                      <InputGroup.Prepend onClick={() => this.showPassword()}>
                        {this.state.visiblePassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </InputGroup.Prepend>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <MdLockOutline />
                      </InputGroup.Prepend>
                      <FormControl
                        size="sm"
                        type={
                          this.state.visiblePasswordConfirm
                            ? 'text'
                            : 'password'
                        }
                        placeholder={
                          this.props.language.locale === 'DE'
                            ? 'Passwort bestätigen'
                            : 'Confirm Password'
                        }
                        required
                        ref={(passwordConfirm) => {
                          this.passwordConfirm = passwordConfirm;
                        }}
                      />
                      <InputGroup.Prepend
                        onClick={() => this.showPasswordConfirm()}
                      >
                        {this.state.visiblePassword ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </InputGroup.Prepend>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Button type="submit" variant="success" size="large" block>
                      {this.props.language.locale === 'DE'
                        ? 'Passwort setzen'
                        : 'Set Password'}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="">
            <Image width="60%" height="60%" src={img} />
          </Col>
        </Row>
        <Row className="text-bottom">
          <Col sm={2} className="text-center color-grid">
            © Yanogo GmbH {new Date().getFullYear()}
          </Col>
          <Col sm={7} />
          <Col sm={1} className=" text-center color-grid">
            Language: {this.props.language.locale}
          </Col>
          <Col sm={1} className="color-grid">
            Contact Us
          </Col>
        </Row>
      </Container>
    );
  }
}

InitUser.propTypes = {
  alert: PropTypes.object,
  redirectToHome: PropTypes.func,
  handleSubmit: PropTypes.func,
};
function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}
const mapStateToProps = (state) => {
  const { alert } = state.toJS();
  const { language } = state.toJS();

  return { alert, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(InitUser);
