import { defineMessages } from 'react-intl';

export default defineMessages({
  LoginTitle: {
    id: 'containers.login.title',
    defaultMessage: 'Entdecken',
  },
  LoginFormText: {
    id: 'containers.login.form.text',
    defaultMessage: 'Melden Sie sich an,',
  },
  LoginFormTextForget: {
    id: 'containers.login.form.text.forget',
    defaultMessage: 'Passwort vergessen ?',
  },

  LoginFormTextSign: {
    id: 'containers.login.form.text.sign',
    defaultMessage: 'Anmeldung',
  },

  LoginFormTextRegister: {
    id: 'containers.login.form.text.register',
    defaultMessage: 'Registrieren',
  },

  LoginFormTextAccount: {
    id: 'containers.login.form.text.account',
    defaultMessage: 'Sie haben noch kein Konto?',
  },

  LoginRightColumnText: {
    id: 'containers.login.right.text',
    defaultMessage:
      'Agrabiz is the first industry-wide platform to provide seamless integration between agricultures, traders, transporters, veterenarians and abatoirs.',
  },

  LoginRightColumnTitleTop: {
    id: 'containers.login.right.title.top',
    defaultMessage: 'Easier Control,',
  },

  LoginRightColumnTitleBottom: {
    id: 'containers.login.right.title.bottom',
    defaultMessage: 'Better Management.',
  },
});
