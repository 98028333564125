export const getMessage = (
  sender_business_type,
  receiver_business_type,
  status,
  is_buyer,
  topic,
) => {
  let message;
  switch (true) {
    case status === 'REQUESTED':
      if (
        (is_buyer === 1 && topic === 'BUY_STOCK') ||
        (is_buyer === 0 && topic === 'SELL_STOCK')
      ) {
        message = 'Cancelable';
      } else message = 'Rejectable';

      break;
    case status === 'REQUEST':
      if (sender_business_type === '2' && receiver_business_type === '1') {
        message = 'RequestTrd';
      } else if (sender_business_type === '3') {
        message = 'RequestAbt';
      } else message = 'toAccept';
      break;

    case status === 'ACCEPT':
      message = 'Confirm';

      break;

    case status === 'OFFERED':
      message = null;
      break;

    case status === 'OFFER':
      if (
        (sender_business_type === '2' && receiver_business_type === '1') ||
        sender_business_type === '2' ||
        (sender_business_type === '1' && receiver_business_type === '1')
      ) {
        message = 'Confirm';
      } else message = 'Confirm';

      break;

    case status === 'TO_BE_ACCEPTED':
      message = 'toAccept';

      break;

    case status === 'TO_BE_RECONFIRMED':
      if (
        (is_buyer === 1 && topic === 'SELL_STOCK') ||
        (is_buyer === 0 && topic === 'BUY_STOCK')
      ) {
        message = 'Reconfirm';
      } else message = 'Reconfirm';

      break;
    case status === 'LOADED':
      message = null;

      break;
    case status === 'TOUR_PLANNING':
      if (
        (is_buyer === 1 && topic === 'SELL_STOCK') ||
        (is_buyer === 0 && topic === 'BUY_STOCK')
      ) {
        message = null;
      } else message = 'Complete tour planning';

      break;
    case status === 'RESOLD':
      if (
        (is_buyer === 0 && topic === 'SELL_STOCK') ||
        (is_buyer === 1 && topic === 'BUY_STOCK')
      ) {
        message = 'resold';
      } else message = 'Reconfirm';

      break;
    case status === 'BOUGHT':
      if (
        (is_buyer === 0 && topic === 'SELL_STOCK') ||
        (is_buyer === 1 && topic === 'BUY_STOCK')
      ) {
        message = 'Resale';
      } else message = 'Own Stock';

      break;
    case status === 'REJECTED':
      message = 'Rejected';
      break;
    case status === 'CANCELED':
      message = 'Cancel';
      break;

    default:
      break;
  }

  return message;
};

export const rejectCancelMessage = (is_buyer, topic) => {
  let message;

  if (topic === 'BUY_STOCK') {
    if (is_buyer === 0) {
      message = 'Cancel';
    } else message = 'Reject';
  } else if (topic === 'BUY_STOCK') {
    if (is_buyer === 1) {
      message = 'Reject';
    } else message = 'Cancel';
  } else if (topic === 'RESALE_STOCK') {
    if (is_buyer === 1) {
      message = 'Reject';
    } else message = 'Cancel';
  } else if (topic === 'RESALE_STOCK') {
    if (is_buyer === 0) {
      message = 'Reject';
    } else message = 'Cancel';
  } else if (topic === 'SELL_STOCK') {
    if (is_buyer === 1) {
      message = 'Resale';
    } else message = 'Bought';
  } else if (topic === 'SELL_STOCK') {
    if (is_buyer === 0) {
      message = 'Bought';
    } else message = 'Resale';
  } else message = null;
  return message;
};
