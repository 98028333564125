import React, { useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';
import { MdPersonOutline, MdLockOutline } from 'react-icons/md';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import {
  Row,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const LoginForm = ({ login }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const showPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const publicVapidKey =
      'BM_HaiQldLX2Elzi04z8LTT7Mgd0G_mjrOX226Sq4Grg-UNRQHpQfJM1lyxiEytmbfqDVSm3vvyQTrzRQ7yUQvA';
    if (email && password) {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
          let register = await navigator.serviceWorker.register(
            '../firebase-messaging-sw.js',
          );
          let subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
          });
          await login(email, password, subscription);
        } catch (err) {
          await login(email, password);
        }
      } else {
        await login(email, password);
      }
    }
  };

  return (
    <Row className="login-content-form">
      <Form onSubmit={handleSubmit}>
        <Row>
          <FormGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <MdPersonOutline />
              </InputGroup.Prepend>
              <FormControl
                size="sm"
                name="email"
                type="email"
                placeholder="E-Mail"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <MdLockOutline />
              </InputGroup.Prepend>
              <FormControl
                size="sm"
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Passwort"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Append onClick={() => showPassword()}>
                {passwordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
              </InputGroup.Append>
            </InputGroup>
          </FormGroup>
          <div className="forget-password-link">
            <div className="forget-switch">
              <Link to="/forgot" className="register-link">
                <FormattedMessage {...messages.LoginFormTextForget} />
              </Link>
            </div>
          </div>
        </Row>
        <Row>
          <FormGroup>
            <Button type="submit" variant="success" size="large" block>
              <FormattedMessage {...messages.LoginFormTextSign} />
            </Button>
          </FormGroup>
          <FormGroup className="register-help">
            <p className="color-grid">
              <FormattedMessage {...messages.LoginFormTextAccount} />{' '}
              <Link to="/register/step/1" className="register-link">
                <FormattedMessage {...messages.LoginFormTextRegister} />
              </Link>
            </p>
          </FormGroup>
        </Row>
      </Form>
    </Row>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    login: (username, password, subscription) =>
      dispatch(userActions.login(username, password, subscription)),
  };
}

export default connect(null, mapDispatchToProps)(LoginForm);
