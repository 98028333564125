export const invoiceConstants = {
  INVOICE_GET_ALL: 'INVOICE_GET_ALL',
  REQUEST_INVOICE_PAYMENT: 'REQUEST_INVOICE_PAYMENT',
  INVOICE_PAYMENT_SUCCESS: 'INVOICE_PAYMENT_SUCCESS',
  INVOICE_PAYMENT_FAILURE: 'INVOICE_PAYMENT_FAILURE',
  REQUEST_INVOICE_CUSTOMER: 'REQUEST_INVOICE_CUSTOMER',
  INVOICE_CUSTOMER_SUCCESS: 'INVOICE_CUSTOMER_SUCCESS',
  INVOICE_CUSTOMER_FAILURE: 'INVOICE_CUSTOMER_FAILURE',
  INVOICE_PAYMENT_EDIT: 'INVOICE_PAYMENT_EDIT',
  INVOICE_PAYMENT_EDIT_CANCEL: 'INVOICE_PAYMENT_EDIT_CANCEL',
};
