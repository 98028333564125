import React from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './caret.css';
import messages from './messages';
const HeaderLinks = () => (
  <Nav className="nav-list navbar-right" style={{ paddingRight: 0 }}>
    <a className="nav-item" eventKey={1} href="home">
      <FormattedMessage {...messages.homeMenuMessage} />
    </a>
    <a className="nav-item right7" eventKey={2} href="about">
      <FormattedMessage {...messages.aboutMenuMessage} />
    </a>
    <a className="nav-item right7" eventKey={3} href="features">
      <FormattedMessage {...messages.featuresMenuMessage} />
    </a>
  </Nav>
);

export default HeaderLinks;
