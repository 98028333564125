import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Container } from 'react-bootstrap';
import Main from './Main';
import ChooseSubscription from './ChooseSubscription';
import { userService } from '../../../services/user.service';

import extractData from '../../../services/data.processing';
import './home.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { subscription_type: this.props.users.user.subscription_type };
  }

  getUserOwnerById = async () => {
    if (this.props.users.user.subscription_type === 'free') {
      let token = this.props.users.accessToken;
      const value = await userService.getUserOwner(
        this.props.users.user.session.company_number,
        token,
      );
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        const company = await extractData(value);
        this.setState({ subscription_type: company.subscription_type });
      } else {
        this.setState({
          subscription_type: this.props.users.user.subscription_type,
        });
      }
    }
  };

  componentDidMount = () => {
    this.getUserOwnerById();
  };

  UNSAFE_componentWillMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleFreeTrial = () => {
    this.setState({ trial: true });
  };

  renderContent = () => {
    const { users } = this.props;
    if (
      this.state.subscription_type !== 'free' ||
      users.user.session.company_type === '1'
    ) {
      return <Main company={this.state.subscription_type} />;
    } else {
      return <ChooseSubscription freeTrial={this.handleFreeTrial} />;
    }
  };

  render() {
    return (
      <Container id="admin-home" fluid={true}>
        {this.renderContent()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, null)(Home);
