import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Image } from 'react-bootstrap';
import messages from '../../messages';
import img from '../../../../static/images/banking.png';
import '../invoice.css';
import PaymentForm from './Form';

export default ({ setTab }) => (
  <div>
    <Row>
      <div className="tab-list">
        <div className="Bank">
          <FormattedMessage {...messages.BankingTag} />
        </div>
      </div>
    </Row>
    <div className="banking-container">
      <div className="banking-left-section">
        <div className="banking-img">
          <Image src={img} />
        </div>
      </div>
      <div className="banking-right-section">
        <PaymentForm setTab={setTab} />
      </div>
    </div>
  </div>
);
