import React from 'react';
import clsx from 'clsx';
import { Card, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Form } from 'formik';
import { connect } from 'react-redux';
import extractData from '../../../../services/data.processing';
import { alertActions, producerActions } from '../../../../actions';
import { getAllTruck } from '../../../../services/company.service';
import BuySellRequest from '../../Orders/buySellRequest/BuySellRequest';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';

import { userService } from '../../../../services';
import DatePickerField from './DatePicker';

import './form.css';
import '../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';

import messages from '../../../../components/Admin/messages';

class TruckForm extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.calendarRefTour = React.createRef();
    this.calendarRefTourUntil = React.createRef();
    this.state = {
      optionsLicence: [],
      optionsDriver: [],
      optionsSelectedDriver: {},
      driverSelected: false,
      licenceSelected: false,
      openBuyRequests: [],
      isLoaded: false,
      showPopupExternalSale: false,
      defaultBuyRequest: this.props.producer.openBuyRequests.find(
        (req) =>
          req._id ===
          (typeof this.props.values.open_request !== 'undefined'
            ? this.props.values.open_request.id
            : ''),
      ),
    };
  }
  UNSAFE_componentWillMount() {
    this.fetchTrucks();
    this.getContacts();
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.focusTextInput();
    this.fetchTrucks();
    this.getContacts();

    if (
      typeof this.props.values.delivery_until === 'undefined' ||
      this.props.values.delivery_until === ''
    ) {
      if (this.props.values.open_request !== 'undefined') {
        this.handleByRequestChangeTimeDirect(
          typeof this.state.defaultBuyRequest !== 'undefined'
            ? this.state.defaultBuyRequest.delivery_time
            : '',
        );
      }
    }
  }

  // componentWillUnmount() {
  //   this.getContacts();
  // }

  getContacts = async () => {
    if (typeof this.props.producer !== 'undefined') {
      let openBuyRequestsProd =
        await this.props.producer.openBuyRequests.filter((e) => {
          return e.delivery_date === this.props.producer.date;
        });
      openBuyRequestsProd.map((e) => {
        e.request_name =
          this.props.language.locale === 'DE'
            ? e.request_name.replace('pieces', 'Stück ')
            : e.request_name;
      });

      this.setState({
        openBuyRequests: openBuyRequestsProd,
      });
    }
  };

  focusTextInput = () => {
    if (this.calendarRefTour.current && this.calendarRefTour.current.input) {
      this.calendarRefTour.current.input.readOnly = true;
    }
  };


  fetchData = async (transportCompany) => {

    let openBuyRequestsProd = this.props.producer.openBuyRequests.filter((e) => {
      return e.delivery_date === this.props.producer.date;
    });


    const defaultRequest = this.props.producer.openBuyRequests.find(
      (req) =>
        req._id ===
        (typeof transportCompany.id !== 'undefined' ? transportCompany.id : ''),
    );


    const openRequest = {
      id: defaultRequest._id,
      sender_name: defaultRequest.sender_name,
      request_name: defaultRequest.request_name,
      value: defaultRequest._id,
      label: `${defaultRequest.request_name} ${defaultRequest.sender_name}`

    };



    this.setState({ openBuyRequests: openBuyRequestsProd })
    this.handleByRequestChange(openRequest)

  };

  fetchTrucks = async () => {
    try {
      const newState = {};
      const token = this.props.users.accessToken;
      const name = this.props.users.user.name;
      const userId = this.props.users.user._id;


   
      const value = await getAllTruck(token);
      const trucks = await extractData(value);
      const optionsLicence = trucks.map((val) => ({
        value: val,
        label: val.licence_number,
      }));
      newState.optionsLicence = optionsLicence;
      const response = await userService.fetchUsers(0, 20, token);
      const users = await extractData(response);
      
      if (this.props.users.user.role !== "ROLE_COMPANY_OWNER") {
        try {
        const responseOwner = await userService.getUserOwner(this.props.users.user.session.company_number, token);
        const usersOwner = await extractData(responseOwner);
        users.push(usersOwner);
      }
      catch (e) {}
      }


      const optionsDriver = users
        .filter(
          (e) => e.role === 'ROLE_TRD_MANAGER' || e.role === 'ROLE_TRD_DRIVER' || e.role === "ROLE_COMPANY_OWNER",
        )
        .map((e) => ({
          value: e.name,
          label:
            e.name +
            (e.role === 'ROLE_TRD_DRIVER'
              ? this.props.language.locale === 'EN'
                ? ` Driver`
                : ` Fahrer`
              :  this.props.language.locale === 'EN'
              ? ` Manager`
              : ` Einkäufer`),
          _id: e._id,
        }));

      optionsDriver.push({
        value: name,
        label:
          name +
          (this.props.language.locale === 'EN' ? ` Manager` : ` Einkäufer`),
        _id: userId,
      });

      newState.optionsDriver = optionsDriver;

      let optionsDriverData = [];
      if (typeof this.props.values.driver_name === 'string') {
        optionsDriverData = newState.optionsDriver.filter(
          (e) =>
            e.label.substring(0, e.label.lastIndexOf(' ')) ===
            this.props.values.driver_name,
        );
      } else {
        if (typeof this.props.values.driver_name === 'undefined') {
          optionsDriverData = [];
        } else {
          optionsDriverData = newState.optionsDriver.filter(
            (e) =>
              e.label.substring(0, e.label.lastIndexOf(' ')) ===
              this.props.values.driver_name.label,
          );
        }
      }

      if (
        typeof this.props.values.driver_name !== 'undefined' ||
        typeof this.props.values.driver_name === 'string'
      ) {
        if (optionsDriverData.length > 0) this.props.setFieldValue('driver_id', optionsDriverData[0]._id);
      }

      this.setState({
        optionsLicence: newState.optionsLicence,
        optionsDriver: newState.optionsDriver,
        optionsSelectedDriver: optionsDriverData,
        isLoaded: true,
      });
    } catch (e) {}
  };

  handleByRequestChange = (transportCompany) => {
    const { setFieldValue } = this.props;
    setFieldValue('open_request', transportCompany);

    // const emptyOpenRequest = {
    //   id: '',
    //   _id: '',
    //   sender_name: '',
    //   value: '',
    //   request_name: '',
    //   label: ''
    // };

    const defaultRequest = this.props.producer.openBuyRequests.find(
      (req) =>
        req._id ===
        (typeof transportCompany.id !== 'undefined' ? transportCompany.id : ''),
    );

    if (this.state.openBuyRequests.length === 1) {

      this.setState({ defaultBuyRequest: defaultRequest })
    } else {

      // setFieldValue('open_request', emptyOpenRequest);
      
      this.setState({
        defaultBuyRequest: defaultRequest,
      });
    }

    if(defaultRequest.delivery_time !== ''){

    this.setState({
      delivery_until: defaultRequest.delivery_time,
    });

    this.handleByRequestChangeTimeDirect(defaultRequest.delivery_time);
    }
  };

  handleByRequestChangeTimeDirect = (data) => {
    const { setFieldValue } = this.props;
    setFieldValue('delivery_until', data);
  };

  handleByRequestChangeTime = (event) => {
    const { setFieldValue } = this.props;
    setFieldValue('delivery_until', event.target.value);
  };

  toggleSelectedDriver = () => {
    this.setState({ driverSelected: true });
  };

  toggleSelectedLicence = () => {
    this.setState({ licenceSelected: true });
  };
  handleExternalSaleModal = (status) => {
    this.setState({ showPopupExternalSale: status });
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      editeTruck,
      deleteTruckData,
      editTransportTruck,
      type,
      setFieldValue,
      index,
    } = this.props;
    const {
      optionsDriver,
      optionsLicence,
      driverSelected,
      licenceSelected,
      isLoaded
    } = this.state;


    if (!isLoaded) {
      // not loaded
      return (
        <div>
          <div key={0} className="loader-wrapper">
            <div className="loader" key={0}></div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <Modal
          className="producer-request-modal"
          show={this.state.showPopupExternalSale}
          onHide={() => this.handleExternalSaleModal(false)}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdfr" id="admin">
            <BuySellRequest
              companyId={this.props.users.user.session.company_id}
              orderType="buyings"
              handleClosePanel={() => this.handleExternalSaleModal(false)}
              externalRequest={true}
              orderTypeInterne={false}
              date={values.delivery_date}
              deliveryDate={values.delivery_date}
              buyRequestEdit={false}
              orderDetail={[]}
              isTourPlanningProducer={true}
              reloadProducerRequest={async () => await this.getContacts()}
              fetchData={this.fetchData}

            />
          </Modal.Body>
        </Modal>
        <Card.Body className="contact-panel-body">
          <Form className="own-carpool-form">
            <div className="flex truck-form">
              {type === 'OWN_CARPOOL' && (
                <div className="o-c-form-container">
                  <div className="inner-form-top">
                    <div className="flex-container">
                      <div className="inner-flex">
                        {this.props.users.user.session.company_type === '2' ||
                        this.props.users.user.session.company_type === '4' ? (
                          <div>
                            <RegularInput
                              select
                              options={optionsDriver}
                              label={
                                <FormattedMessage {...messages.DriverName} />
                              }
                              name="driver_name"
                              className={clsx(
                                (driverSelected || values.driver_name) &&
                                  'selected',
                              )}
                              defaultValue={this.state.optionsSelectedDriver}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  'driver_name',
                                  selectedOption.value,
                                );
                                this.toggleSelectedDriver();
                                setFieldValue('driver_id', selectedOption._id);
                              }}
                              isDisabled={
                                editeTruck && this.props.isBlockedItem
                                  ? true
                                  : false
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,

                                colors: {
                                  ...theme.colors,
                                  primary25: '#F1FFDE',
                                  primary: '#7bcd10',
                                },
                              })}
                            />
                            <span className="focus-border"></span>

                            {touched.driver_name && errors.driver_name && (
                              <p className="error-message transport-Company-error">
                                {errors.driver_name}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div>
                            <RegularInput
                              label={
                                <FormattedMessage {...messages.DriverName} />
                              }
                              type="text"
                              id="driver-name"
                              name="driver_name"
                              onChange={handleChange}
                              defaultValue={values.driver_name}
                              onBlur={handleBlur}
                            />
                          </div>
                        )}
                      </div>

                      <div className="inner-flex">
                        <RegularInput
                          name="open_request"
                          onChange={this.handleByRequestChange}
                          onBlur={handleBlur}
                          label={<FormattedMessage {...messages.Kaufanfrage} />}
                          select
                          className={clsx(values.open_request && 'selected')}
                          value={
                            this.state.defaultBuyRequest !== null && this.state.defaultBuyRequest && {
                              value:
                                this.state.defaultBuyRequest._id,
                              label:
                                this.props.language.locale === 'DE'
                                  ? `${this.state.defaultBuyRequest.request_name.replace(
                                      'pieces',
                                      'Stück ',
                                    )} ${
                                      this.state.defaultBuyRequest.sender_name
                                    }`
                                  : `${this.state.defaultBuyRequest.request_name} 
                                  ${this.state.defaultBuyRequest.sender_name}`,
                              id: this.state.defaultBuyRequest._id,
                            }
                          }
                          isDisabled={
                            editeTruck && this.props.isBlockedItem
                              ? true
                              : false
                          }
                          setFieldValue={setFieldValue}
                          options={this.state.openBuyRequests.map(
                            ({ request_name: label, sender_name, _id }) => ({
                              value: _id,
                              label: label + ' ' + sender_name,
                              id: _id,
                              sender_name: sender_name,
                            }),
                          )}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#F1FFDE',
                              primary: '#7bcd10',
                            },
                          })}
                        />

                        <span className="focus-border"></span>
                        {touched.open_request && !errors.open_request && (
                          <span />
                        )}
                        {touched.open_request && errors.open_request && (
                          <p className="error-message">Required</p>
                        )}
                        <SvgIcon
                          size="25"
                          viewBox="0 0 600 600"
                          position="absolute"
                          right="-30px"
                          top="40px"
                          onClick={() => this.handleExternalSaleModal(true)}
                        >
                          <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
                        </SvgIcon>
                      </div>
                    </div>

                    <div className="flex-container">
                      <div className="inner-flex">
                        {this.props.users.user.session.company_type === '2' ||
                        this.props.users.user.session.company_type === '4' ? (
                          <div>
                            <RegularInput
                              select
                              options={optionsLicence}
                              label={
                                <FormattedMessage {...messages.LicenceNumber} />
                              }
                              name="licence_number"
                              className={clsx(
                                (licenceSelected || values.licence_number) &&
                                  'selected',
                              )}
                              defaultValue={{
                                value: values.licence_number,
                                label: values.licence_number,
                              }}
                              isDisabled={
                                editeTruck && this.props.isBlockedItem
                                  ? true
                                  : false
                              }
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  'licence_number',
                                  selectedOption.label,
                                );
                                this.toggleSelectedLicence();
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,

                                colors: {
                                  ...theme.colors,
                                  primary25: '#F1FFDE',
                                  primary: '#7bcd10',
                                },
                              })}
                            />
                            <span className="focus-border"></span>
                            {touched.licence_number &&
                              !errors.licence_number && <span />}
                            {touched.licence_number &&
                              errors.licence_number && (
                                <p className="error-message">Required</p>
                              )}
                          </div>
                        ) : (
                          <div>
                            <RegularInput
                              label={
                                <FormattedMessage {...messages.LicenceNumber} />
                              }
                              id="licence-number"
                              name="licence_number"
                              type="text"
                              onChange={handleChange}
                              defaultValue={values.licence_number}
                            />
                          </div>
                        )}
                      </div>
                      <div className="inner-flex">
                        <DatePickerField
                          label={<FormattedMessage {...messages.PickUPDate} />}
                          name="pick_up_date"
                          value={values.pick_up_date}
                          onChange={setFieldValue}
                          dateFormat="dd.MM.yyyy"
                          ref={this.calendarRefTour}
                          onBlur={this.synchronizeDates}
                        />
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="inner-flex">
                        <DatePickerField
                          label={
                            <FormattedMessage {...messages.DeliveryDate} />
                          }
                          name="delivery_date"
                          value={values.delivery_date}
                          onChange={setFieldValue}
                          dateFormat="dd.MM.yyyy"
                          ref={this.calendarRefTourUntil}
                        />

                        <span className="focus-border"></span>
                        {touched.delivery_date && !errors.delivery_date && (
                          <span />
                        )}
                        {touched.delivery_date && errors.delivery_date && (
                          <p className="error-message">Check Date!</p>
                        )}
                      </div>
                      <div className="inner-flex">
                        <RegularInput
                          type="number"
                          id="capacity"
                          name="capacity"
                          onChange={handleChange}
                          defaultValue={values.capacity || '30'}
                          onBlur={handleBlur}
                          required
                          label={<FormattedMessage {...messages.Capacity} />}
                          disabled={
                            editeTruck && this.props.isBlockedItem
                              ? true
                              : false
                          }
                        />
                        <span className="focus-border"></span>
                        {touched.capacity && !errors.capacity && <span />}
                        {touched.capacity && errors.capacity && (
                          <p className="error-message">Capacity required</p>
                        )}
                      </div>
                    </div>
                    <div className="flex-container">
                      <div className="inner-flex">
                        <RegularInput
                          type="time"
                          id="delivery-until"
                          name="delivery_until"
                          onChange={(e) => this.handleByRequestChangeTime(e)}
                          defaultValue={
                            (typeof this.state.defaultBuyRequest !== 'undefined' && this.state.defaultBuyRequest !== null)
                              ? this.state.defaultBuyRequest.delivery_time
                              : values.delivery_until
                          }
                          value={values.delivery_until}
                          // setFieldValue={this.state.delivery_time}
                          // defaultValue={values.delivery_until}
                          onBlur={handleBlur}
                          required
                          label={
                            <FormattedMessage {...messages.DeliveryUntil} />
                          }
                        />
                        <span className="focus-border"></span>
                        {touched.delivery_until && !errors.delivery_until && (
                          <span />
                        )}
                        {touched.delivery_until && errors.delivery_until && (
                          <p className="error-message">Check Time</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tour-planning-popup-btn inner-form-top">
                    {!editeTruck ? (
                      <NormalButton type="submit">
                        <FormattedMessage {...messages.TruckAdd} />
                      </NormalButton>
                    ) : (
                      <React.Fragment>
                        <NormalButton
                          type="submit"
                          // onClick={() => editTransportTruck(index, values)}
                          className="edit-truck top"
                        >
                          <FormattedMessage {...messages.TruckSave} />
                        </NormalButton>

                        {/* <NormalButton
                        type="button"
                        className="edit-truck"
                        onClick={() => deleteTruckData(index)}
                      >
                        <FormattedMessage {...messages.TruckDelete} />
                      </NormalButton> */}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Card.Body>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getProducer: (date) => dispatch(producerActions.getAllProducer(date)),

  };
}

const mapStateToProps = (state) => {
  const { users, producer, language } = state.toJS();
  return {
    users,
    producer,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TruckForm);
