// Import all the third party stuff
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
// import { ConnectedRouter } from "react-router-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import 'sanitize.css/sanitize.css';
// Import i18n messages
import { translationMessages } from './i18n';
// push notification
// import Notification from '../src/components/PushNotification/Notification';

// Import cookieProvider: we need to get cookie on header component
import { CookiesProvider } from 'react-cookie';
// Import root app
import Container from './containers/App';

// Import Language Provider
import LanguageProvider from './containers/LanguageProvider';
import configureStore from './configureStore';
// Import CSS reset and Global Styles
import './static/css/vendor.bundle.css';
import './static/css/style.css';
import './static/css/theme-green-blue.css';
import { producerActions } from './actions';

// Create redux store with history
const initialState = {};
const history = createBrowserHistory();
const { store, persistor } = configureStore(initialState, history);
export default store;
const MOUNT_NODE = document.getElementById('root');
const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LanguageProvider messages={messages}>
          {/* <ConnectedRouter history={history}> */}
          <CookiesProvider>
            {/* <Notification /> */}
            <Container />
          </CookiesProvider>
          {/* </ConnectedRouter> */}
        </LanguageProvider>
      </PersistGate>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker
    .register('../firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker.onmessage = (event) => {
    if (
      event.data.firebaseMessagingData.push_id.toString() === '40' ||
      event.data.firebaseMessagingData.push_id.toString() === '41'
    ) {
      if (window.location.href.includes('tourPlanningProducer')) {
        store.dispatch(
          producerActions.updateProducerbByPushNotification(
            event.data.firebaseMessagingData.date,
            event.data.firebaseMessagingData.token,
          ),
        );
        store.dispatch(
          producerActions.updateProducerbByPushNotification(
            event.data.firebaseMessagingData.date,
            event.data.firebaseMessagingData.token,
          ),
        );
      }
    }
    /*Here change the document title*/
  };
}
