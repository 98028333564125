import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, FormGroup, Modal, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment-es6';
import { injectIntl } from 'react-intl';
import ToggleMenuButton from '../../Shared/components/buttons/ToggleMenuButton/ToggleMenuButton';

import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';
import { menuItems } from './dropDownOptions';

import { getMedication } from '../../../services/stock.service';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

import { alertActions, producerActions } from '../../../actions';
import './ReportsAndSearch.css';
import './tourTruck.css';

import TourList from './TourList';
import TourListRight from './TourListRight';

import BuySellRequest from './buySellRequest/BuySellRequest';
import locale from '../../../utils/DataPickerLang';
import { debounce } from 'lodash';

const mock = [
  {
    Name: 'Bulle',
    number: '20',
    subCat: [{ Name: 'GVO', number: '7' }],
  },
  {
    Name: 'Kühe',
    number: '14',
    subCat: [
      { Name: 'BIO', number: '0' },
      { Name: 'GVO', number: '0' },
    ],
  },
  {
    Name: 'Rinder',
    number: '4',
    subCat: [
      { Name: 'BIO', number: '0' },
      { Name: 'GVO', number: '15' },
      ,
      { Name: 'QS', number: '0' },
    ],
  },
  {
    Name: 'Ochsen',
    number: '0',
    subCat: [],
  },
];
class ReportsAndSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'active',
      eartagSorted: false,
      drugSorted: false,
      qteSorted: false,
      dateSorted: false,
      displayCv: false,
      cvInfos: {},
      data: {},
      detailStock: false,
      ordersTab: [],
      trucksTab: [],
      date: moment().toDate(),
      theDay: '',
      showPopup: false,
      showPopupExternal: false,
      mockDate: mock,
      groupedEartags: [],
      globalNote: '',
      getDataProgress: true,
    };
  }
  handleClose = () => this.setState({ showPopup: false });
  handleOpen = () => this.setState({ showPopup: true });
  handleCloseExternal = () => this.setState({ showPopupExternal: false });
  handleOpenExternal = () => this.setState({ showPopupExternal: true });
  days = [
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.sunday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.monday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.tuesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.wednesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.thursday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.friday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.saturday' }),
  ];

  componentDidMount() {
    this.getToursByDate();
    this.calendarRef = React.createRef();

    this.setState({ theDay: this.days[moment().day()] });
  }

  getToursByDate = async () => {
    if (typeof this.state.date !== 'undefined') {
      const date = this.convertDate(this.state.date);

      await this.props.resetProducerOrder();
      await this.props.getProducer(date);
      if (
        typeof this.props.producer.order !== 'undefined' &&
        typeof this.props.producer.order !== 'undefined' &&
        this.props.producer.order != null
      ) {
        this.setState({
          ordersTab:
            this.props.producer.order != null ? this.props.producer.order : [],
          trucksTab:
            this.props.producer.truck != null ? this.props.producer.truck : [],
          stats: this.props.producer.stats,
          groupedEartags: this.props.producer.groupedEartags,
          globalNote: this.props.producer.notes,
        });

        localStorage.setItem(
          'globalenotes',
          typeof this.props.producer.notes !== 'undefined'
            ? this.props.producer.notes
            : '',
        );
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isMounted = true;
    if (nextProps !== this.props) {
      if (
        typeof this.props.producer.order !== 'undefined' &&
        typeof this.props.producer.order !== 'undefined' &&
        this.props.producer.order != null
      ) {
        this.setState({
          ordersTab:
            this.props.producer.order != null ? this.props.producer.order : [],
          trucksTab:
            this.props.producer.truck != null ? this.props.producer.truck : [],
          stats: this.props.producer.stats,
          groupedEartags: this.props.producer.groupedEartags,
          globalNote: this.props.producer.notes,
        });
      }
    }
  }

  convertDate = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1; //getMonth is zero based;
    var day = date.getDate();
    var formatted = day + '.' + month + '.' + year;

    let nameSplit = formatted.split('.');
    // new date format 01.01.20
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = day + '.' + month + '.' + nameSplit[2].toString().substr(-2);
    return newDate;
  };

  handleChangeDate = async (date, control) => {
    await this.setState({ getDataProgress: false });

    if (!this.state.getDataProgress) {
      let tomorrow;
      if (control === 'up') {
        tomorrow = moment(date).add(1, 'days');
      } else {
        tomorrow = moment(date).add(-1, 'days');
      }
      await this.setState({
        theDay: this.days[tomorrow.day()],
        date: tomorrow.toDate(),
        page: 0,
        ordersTab: [],
        trucksTab: [],

        ordersTabAll: [],
      });
      await this.getToursByDate();

      setTimeout(() => {
        this.setState({ getDataProgress: true });
      }, 200);
    }
  };

  dateChange = async (date) => {
    await this.setState({
      date: moment(date).toDate(),
      theDay: this.days[moment(date).day()],
      page: 0,
      ordersTab: [],
      trucksTab: [],
    });
    await this.setState({ ordersTabAll: [] });
    await this.getToursByDate();
  };

  async getAllMedications() {
    let token = this.props.users.accessToken;
    const medication = await getMedication(token, 0);
    if (medication.status === 200) {
    } else {
      this.props.error(<FormattedMessage {...messages.NetworkProblem} />);
      this.props.clear();
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      type: e.target.value,
    });
  };

  backgroundChange = (position) => {
    if (this.state.itemBackground === position) {
      return '#f0fae3';
    }
    return '';
  };

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  showCv = (bool) => {
    this.setState({ displayCv: bool });
  };

  getCvInfo = async (cvInfos, data) => {
    this.setState({ cvInfos, data });
  };

  setShowForm = async () => {
    this.setState({
      detailStock: !this.state.detailStock,
      displayCv: !this.state.displayCv,
    });
  };

  updateProducerOrder = async (data) => {
    this.props.updateProducerOrder();
  };
  updateProducerTruck = async (data) => {
    this.props.updateProducerTruck(data);
  };

  render() {
    const { theDay, date } = this.state;

    return (
      <Row className="producer view-producer-prin">
        <Card style={{}} className="card-box">
          <Card.Body>
            <Modal
              show={this.state.showPopup}
              onHide={this.handleClose}
              style={{ opacity: 1 }}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="modal-pdfr ">
                <BuySellRequest
                  companyId={this.props.users.user.session.company_id}
                  orderType="buyings"
                  orderTypeInterne={true}
                  handleClosePanel={this.handleClose}
                  date={this.state.date}
                  getOrders={this.getToursByDate}
                />
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.showPopupExternal}
              onHide={this.handleCloseExternal}
              style={{ opacity: 1 }}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="modal-pdfr ">
                <BuySellRequest
                  companyId={this.props.users.user.session.company_id}
                  orderType="buyings"
                  handleClosePanel={this.handleCloseExternal}
                  externalRequest={true}
                  orderTypeInterne={false}
                  date={this.state.date}
                  getOrders={this.getToursByDate}
                />
              </Modal.Body>
            </Modal>
            <Col className="producer-col" md={6} sm={12}>
              <Row>
                <FormGroup
                  controlId="formInlineName"
                  className="user-input-wrp date-time"
                >
                  <div className="date-picker-wrapper">
                    <div
                      className="date-picker-icon"
                      onClick={() => {
                        this.calendarRef.current.setOpen(true);
                      }}
                    ></div>
                    <div className="the-day">{theDay}</div>

                    <DatePicker
                      selected={date}
                      onChange={this.dateChange}
                      dateFormat="dd.MM.yyyy"
                      ref={this.calendarRef}
                      highlightDates={this.state.alldates}
                      onChangeRaw={this.handleDateChangeRaw}
                      locale={locale}
                    />
                    <button
                      className="button-up"
                      onClick={() => this.handleChangeDate(date, 'down')}
                      disabled={!this.state.getDataProgress}
                    >
                      <i className="down" />
                    </button>
                    <button
                      className="button-down"
                      onClick={() => this.handleChangeDate(date, 'up')}
                      disabled={!this.state.getDataProgress}
                    >
                      <i className="up" />
                    </button>
                  </div>
                  <hr className="style-border" />
                </FormGroup>
              </Row>
              <Row
                style={{
                  height: '87%',
                  marginLeft: '0px',
                  background: '#F3F3F3',
                }}
              >
                <div className="view-producer-test">
                  <TourList
                    orderDetail={this.state.ordersTab}
                    setShowForm={this.props.setShowForm}
                    token={this.props.users.accessToken}
                    company_id={this.props.users.user.session.company_id}
                    orderType={this.props.orderType}
                    typeOrder={this.props.type}
                    handleClosePanel={this.props.handleClosePanel}
                    usersInfo={this.props.users}
                    changeTab={this.props.changeTab}
                    changeDate={this.props.changeDate}
                    getOrders={this.getToursByDate}
                    displayBuyRequestEdit={this.props.displayBuyRequestEdit}
                    orderTruck={this.state.trucksTab}
                    trucksShow={false}
                    groupedEartags={this.state.groupedEartags}
                    notes={this.state.globalNote}
                    date={this.convertDate(this.state.date)}
                  />
                </div>
              </Row>
              <Row className="panel-body-options bottom-part">
                <Col md={12} style={{ marginTop: '1vh', height: '10%' }}>
                  <ToggleMenuButton
                    title={<FormattedMessage {...messages.Options} />}
                    lang={this.props.language.locale}
                    widthDeutsch="45%"
                  >
                    {menuItems.map((item) =>
                      item.id === 0 ? (
                        <Dropdown.Item
                          key={item.id}
                          onClick={() => this.handleOpen()}
                        >
                          {item.path && (
                            <SvgIcon size="20" viewBox="0 0 600 600">
                              {item.path}
                            </SvgIcon>
                          )}
                          <div className="menu-item-text">{item.title}</div>
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          key={item.id}
                          onClick={() => this.handleOpenExternal()}
                        >
                          {item.path && (
                            <SvgIcon size="20" viewBox="0 0 600 600">
                              {item.path}
                            </SvgIcon>
                          )}
                          <div className="menu-item-text">{item.title}</div>
                        </Dropdown.Item>
                      ),
                    )}
                  </ToggleMenuButton>
                </Col>
              </Row>
            </Col>

            <Col className="producer-col" md={6} sm={12}>
              {this.state.trucksTab.length === 0 ? (
                <div key={0} className="loader-prodstocks">
                  <div className="loader-prodst" key={0}></div>
                </div>
              ) : (
                <div className="view-producer-testBlock2">
                  {this.state.ordersTab && (
                    <TourListRight
                      orderDetail={this.state.ordersTab}
                      setShowForm={this.props.setShowForm}
                      token={this.props.users.accessToken}
                      company_id={this.props.users.user.session.company_id}
                      orderType={this.props.orderType}
                      typeOrder={this.props.type}
                      handleClosePanel={this.props.handleClosePanel}
                      usersInfo={this.props.users}
                      changeTab={this.props.changeTab}
                      changeDate={this.props.changeDate}
                      getOrders={this.getToursByDate}
                      displayBuyRequestEdit={this.props.displayBuyRequestEdit}
                      orderTruck={this.state.trucksTab}
                      trucksShow={true}
                      updateProducerOrder={this.updateProducerOrder}
                      date={this.convertDate(this.state.date)}
                      dateSelling={this.state.date}
                    />
                  )}
                </div>
              )}
            </Col>
          </Card.Body>
        </Card>
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getProducer: (date) => dispatch(producerActions.getAllProducer(date)),
    updateProducerOrder: () => dispatch(producerActions.updateProducerOrder()),
    resetProducerOrder: () => dispatch(producerActions.updateProducerOrder()),
  };
}

const mapStateToProps = (state) => {
  const { users, language, producer } = state.toJS();
  return {
    users,
    language,
    producer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ReportsAndSearch));
