import React from 'react';
import OrderItem from './OrderItem';
import { ListGroup } from 'react-bootstrap';

const OpenBuyings = ({ boughtOrders, getBoughtOrders }) => {
  const handleBoughtOrders = (orderChecked, orderId) => {
    getBoughtOrders(orderChecked, orderId);
  };

  return boughtOrders.map((order, index) => (
    <div>
      <ListGroup key={index} id="buy-sell" className="bought-orders">
        <ul>
          <OrderItem
            order={order}
            index={index}
            handleBoughtOrders={handleBoughtOrders}
          />
        </ul>
      </ListGroup>
    </div>
  ));
};
export default OpenBuyings;
