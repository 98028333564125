import React from 'react';
import { connect } from 'react-redux';
import { SketchField, Tools } from 'react-sketch';
import whiteBackground from '../../../../../static/images/admin/whiteBackground.png';
import { userActions } from '../../../../../actions';
import { userService } from '../../../../../services/user.service';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../Admin/messages';
import NormalButton from '../../../../Shared/components/buttons/NormalButton/NormalButton';
import '../../../Setting/setting.css';

class SignatureSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stretched: true,
      stretchedX: false,
      stretchedY: false,
      originX: 'left',
      originY: 'top',
      imageSrc: '',
      setSignature: false,
    };
  }

  componentDidUpdate() {
    let sketch = this._sketch;
    let { stretched, stretchedX, stretchedY, originX, originY } = this.state;
    sketch.setBackgroundFromDataUrl(whiteBackground, {
      stretched: stretched,
      stretchedX: stretchedX,
      stretchedY: stretchedY,
      originX: originX,
      originY: originY,
    });
  }

  componentDidMount() {
    this.setState({ setSignature: true });
    const responseSignature = userService.getSignature(
      this.props.users.user._id,
      this.props.users.accessToken,
    );
    responseSignature
      .then(res => {
        const imageSrc = 'data:image/png;base64,' + res;
        this.setState({ imageSrc: imageSrc });
      })
      .catch(e => {});
  }

  _onSketchChange = val => {
    this.setState({ val });
  };

  saveSignature = async () => {
    const WhiteSignature = 1600;
    const signature = this._sketch.toDataURL();
    const blobReturn = this.dataURItoBlob(signature);
    if (blobReturn.size >= WhiteSignature) {
      await this.props.addSignature(blobReturn);
      this.props.togglePopup();
      this.props.showNote();
    }
  };

  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);

    let mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    let ab = new ArrayBuffer(byteString.length);

    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  render() {
    return (
      <div className="text-center-block-sketch">
        <div className="text-center-b1-sketch">
          {this.state.setSignature && (
            <SketchField
              style={{
                width: '100%',
              }}
              className="text-center-sketch "
              ref={c => (this._sketch = c)}
              width="50%"
              height="auto"
              tool={Tools.Pencil}
              imageFormat="png"
              lineColor="black"
              onChange={this._onSketchChange}
              lineWidth={3}
            />
          )}
        </div>
        <div className="text-center-sketch-b3">
          <div className=" flex-icons">
            <div className="btn-signature">
              <NormalButton type="submit" onClick={this.props.togglePopup}>
                <FormattedMessage {...messages.Cancel} />
              </NormalButton>
            </div>
            <div className="btn-signature">
              <NormalButton type="submit" onClick={this.saveSignature}>
                <FormattedMessage {...messages.Save} />
              </NormalButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addSignature: signature => dispatch(userActions.addSignature(signature)),
  };
}
const mapStateToProps = state => {
  const { users } = state.toJS();
  return { users };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignatureSection);
