import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Form } from 'formik';
import { connect } from 'react-redux';
import extractData from '../../../../../services/data.processing';
import { alertActions } from '../../../../../actions';
import { getContacts } from '../../../../../services/company.service';
import DatePickerField from './DatePicker';
import RegularInput from '../../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../../Shared/components/buttons/NormalButton/NormalButton';
import clsx from 'clsx';

import './form.css';
import '../../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

import messages from '../../../../../components/Admin/messages';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactList: [],
    };
  }
  componentDidMount() {
    this.calendarRefTour = React.createRef();
    this.calendarRefTourUntil = React.createRef();
    this.focusTextInput();
    this.getContacts();
  }

  focusTextInput = () => {
    if (this.calendarRefTour && this.calendarRefTour.input) {
      this.calendarRefTour.input.readOnly = true;
    }
  };

  getContacts = async () => {
    const token = this.props.users.accessToken;
    const { session } = this.props.users.user;
    const company_id = session.company_id;
    const groups = ['2', '4'];
    let isRegistered;
    if (session.company_type !== '3') {
      isRegistered = 1;
    }
    const value = await getContacts(company_id, token, groups, isRegistered);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contacts = await extractData(value);
      this.setState({ contactList: contacts });
    }
  };

  synchronizeDates = (e) => {
    const { handleBlur, values, setFieldValue } = this.props;
    handleBlur(e);
    values.delivery_date < values.pick_up_date &&
      setFieldValue('delivery_date', e.target.value);
  };
  handleTransportCompanyChange = (transportCompany) => {
    const { setFieldValue, setContact } = this.props;
    const { contactList } = this.state;
    setFieldValue('transport_company_id', transportCompany);
    const contact = contactList.find((e) => e._id === transportCompany._id);

    setContact(contact);
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      editeTruck,
      deleteTruckData,
      editTransportTruck,
      type,
      setFieldValue,
      index,
    } = this.props;

    return (
      <Card.Body className="contact-panel-body">
        <Form>
          <div className="flex truck-form">
            {type === 'MANUAL_ENTRY' && (
              <div className="second-truck-form">
                <RegularInput
                  name="transport_company_id"
                  onChange={this.handleTransportCompanyChange}
                  onBlur={handleBlur}
                  label={<FormattedMessage {...messages.TransportCompany} />}
                  select
                  className={clsx(values.transport_company_id && 'selected')}
                  defaultValue={values.transport_company_id}
                  setFieldValue={setFieldValue}
                  options={this.state.contactList.map(
                    ({ company: value, name: label, _id }) => ({
                      value,
                      label,
                      _id,
                    }),
                  )}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#F1FFDE',
                      primary: '#7bcd10',
                    },
                  })}
                />
                <span className="focus-border"></span>
                {touched.transport_company_id &&
                  errors.transport_company_id && (
                    <p className="error-message">
                      {errors.transport_company_id}
                    </p>
                  )}
                <div className="flex-container">
                  <div className="inner-flex">
                    <DatePickerField
                      label={<FormattedMessage {...messages.PickUPDate} />}
                      name="pick_up_date"
                      value={values.pick_up_date}
                      onChange={setFieldValue}
                      dateFormat="dd.MM.yyyy"
                      ref={this.calendarRefTour}
                      onBlur={this.synchronizeDates}
                    />

                    <span className="focus-border"></span>
                    {touched.pick_up_date && errors.pick_up_date && (
                      <p className="error-message">{errors.pick_up_date}</p>
                    )}
                  </div>
                  <div className="inner-flex">
                    <DatePickerField
                      label={<FormattedMessage {...messages.DeliveryDate} />}
                      name="delivery_date"
                      value={values.delivery_date}
                      onChange={setFieldValue}
                      dateFormat="dd.MM.yyyy"
                      ref={this.calendarRefTourUntil}
                    />

                    <span className="focus-border"></span>
                    {touched.delivery_date && errors.delivery_date && (
                      <p className="error-message">Check Date!</p>
                    )}
                  </div>
                </div>

                <div className="flex-container">
                  <div className="inner-flex">
                    <RegularInput
                      type="number"
                      id="capacity"
                      name="capacity"
                      defaultValue={values.capacity || '30'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      label={<FormattedMessage {...messages.Capacity} />}
                    />
                    <span className="focus-border"></span>
                    {touched.capacity && errors.capacity && (
                      <p className="error-message">Capacity required</p>
                    )}
                  </div>
                  <div className="inner-flex">
                    <RegularInput
                      type="time"
                      id="delivery-until"
                      name="delivery_until"
                      onChange={handleChange}
                      defaultValue={values.delivery_until}
                      onBlur={handleBlur}
                      required
                      label={<FormattedMessage {...messages.DeliveryUntil} />}
                    />
                    <span className="focus-border"></span>
                    {touched.delivery_until && errors.delivery_until && (
                      <p className="error-message">Check Time</p>
                    )}
                  </div>
                </div>
                <div className="flex-container last"></div>
                <div className="tour-planning-popup-btn">
                  {!editeTruck ? (
                    <NormalButton type="submit">
                      <FormattedMessage {...messages.TruckAdd} />
                    </NormalButton>
                  ) : (
                    <React.Fragment>
                      <div className="edit-transport-company">
                        <NormalButton
                          type="button"
                          onClick={() => editTransportTruck(index, values)}
                        >
                          <FormattedMessage {...messages.TruckSave} />
                        </NormalButton>
                        <NormalButton
                          type="button"
                          onClick={() => deleteTruckData(index)}
                        >
                          <FormattedMessage {...messages.TruckDelete} />
                        </NormalButton>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </Form>
      </Card.Body>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
