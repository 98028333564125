export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  REFRESH_TOKEN: 'REFRESH_TOKEN',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  USER_GET_REQUEST: 'USER_GET_SUCCESS',

  USER_GET_ALL: 'USER_GET_ALL',
  USER_ADD: 'USER_ADD',
  USER_EDIT: 'USER_EDIT',
  USER_DELETE: 'USER_DELETE',

  COMPANY_EDIT: 'COMPANY_EDIT',
  COMPANY_UPLOAD_FILE_QUALITY_PROGRAM: 'COMPANY_UPLOAD_FILE_QUALITY_PROGRAM',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  MEMBER_SHIP: 'MEMBER_SHIP',
  USER_CHANGE_SESSION: 'USER_CHANGE_SESSION',
  USER_CHANGE_SESSION_SUB: 'USER_CHANGE_SESSION_SUB',
  USER_CHANGE_SESSION_LAST_COMPANY: 'USER_CHANGE_SESSION_LAST_COMPANY',
  // User Roles
  ROLE_COMPANY_OWNER: 'ROLE_COMPANY_OWNER',
};
