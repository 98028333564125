export const specificRoles = {
  ALL_ROLES: [
    'ROLE_COMPANY_OWNER',
    'ROLE_AGR_MANAGER',
    'ROLE_AGR_STABLE_WORKER',
    'ROLE_TRT_BOOKER',
    'ROLE_TRT_DRIVER',
    'ROLE_ABA_PURCHASER',
    'ROLE_ABA_EMPLOYEE',
    'ROLE_VET_MANAGER',
    'ROLE_VET_STABLE_WORKER',
    'ROLE_VET_EMPLOYEE',
    'ROLE_TRD_MANAGER',
    'ROLE_TRD_OFFICE_WORKER',
    'ROLE_TRD_DRIVER',
  ],
  ROLE_FOR_SETTINGS: [
    'ROLE_ABA_EMPLOYEE',
    'ROLE_ABA_PURCHASER',
    'ROLE_TRD_DRIVER',
    'ROLE_TRD_OFFICE_WORKER',
    'ROLE_TRD_MANAGER',
    'ROLE_AGR_MANAGER',
    'ROLE_AGR_STABLE_WORKER',
    'ROLE_COMPANY_OWNER',
  ],
  ROLE_FOR_SETTINGS_INDES: [
    // 'ROLE_TRD_OFFICE_WORKER',
    'ROLE_TRD_MANAGER',
    'ROLE_COMPANY_OWNER',
    'ROLE_TRT_BOOKER',
  ],
  ROLE_FOR_SETTINGS_IND_SEC: [
    'ROLE_AGR_MANAGER',
    'ROLE_AGR_STABLE_WORKER',
    'ROLE_ABA_EMPLOYEE',
    'ROLE_ABA_PURCHASER',
    'ROLE_VET_MANAGER',
    'ROLE_VET_STABLE_WORKER',
    'ROLE_VET_EMPLOYEE',
    'ROLE_TRT_DRIVER',
    'ROLE_TRD_DRIVER',
  ],
  ROLE_FOR_COMP_SETTING: [
    'ROLE_ABA_EMPLOYEE',
    'ROLE_ABA_PURCHASER',
    'ROLE_TRD_DRIVER',
    'ROLE_TRD_OFFICE_WORKER',
    'ROLE_TRD_MANAGER',
  ],
  ROLE_FOR_COMP_PROFILE_DETAILS: ['ROLE_AGR_MANAGER', 'ROLE_AGR_STABLE_WORKER'],
  ROLE_FOR_SEC_SETTING: [
    'ROLE_ABA_EMPLOYEE',
    'ROLE_ABA_PURCHASER',
    'ROLE_TRD_DRIVER',
    'ROLE_TRD_OFFICE_WORKER',
  ],
  ROLE_FOR_SIDE_MENUE_MEDIUM: [
    'ROLE_ABA_EMPLOYEE',
    'ROLE_TRD_DRIVER',
    // 'ROLE_TRD_OFFICE_WORKER',
    'ROLE_AGR_STABLE_WORKER',
  ],
  ROLE_FOR_SIDE_MENUE_REDUCED: ['ROLE_AGR_MANAGER'],
  ROLE_FOR_ROLE_COMPANY_OWNER: ['ROLE_COMPANY_OWNER'],
  ROLE_FOR_ROLE_COMPANY_AGR_TRD: [
    'ROLE_COMPANY_OWNER',
    'ROLE_AGR_MANAGER',
    'ROLE_TRD_MANAGER',
  ],
  ROLE_FOR_ROLE_COMPANY_AGR_TRD_VAL: [
    'ROLE_COMPANY_OWNER',
    // 'ROLE_AGR_MANAGER',
    'ROLE_TRD_MANAGER',
    'ROLE_TRD_DRIVER',
    // 'ROLE_TRD_OFFICE_WORKER',
    // 'ROLE_AGR_STABLE_WORKER',
    'ROLE_VET_MANAGER',
  ],
  ROLE_FOR_SETTINGS_NOT_DRIVER: [
    'ROLE_TRD_OFFICE_WORKER',
    'ROLE_TRD_MANAGER',
    'ROLE_TRT_BOOKER',
    'ROLE_TRS_MANAGER',
    'ROLE_COMPANY_OWNER',
  ],
  ROLE_FOR_ROLE_COMPANY_AGR_TRD_MANAG: [
    'ROLE_AGR_MANAGER',
    'ROLE_TRD_MANAGER',
    'ROLE_AGR_STABLE_WORKER',
    'ROLE_TRD_OFFICE_WORKER',
    'ROLE_TRT_DRIVER',
    'ROLE_TRD_DRIVER',
    'ROLE_TRT_BOOKER',
  ],
  ROLE_FOR_ROLE_TRD_OFFICE_WORKER: [
    'ROLE_TRD_MANAGER',
    'ROLE_TRD_OFFICE_WORKER'
  ],
  ROLE_FOR_ROLE_TRD_OFFICE_WORKER_ADMIN: [
    'ROLE_TRD_MANAGER',
    'ROLE_TRD_OFFICE_WORKER',
    'ROLE_AGR_MANAGER',
    'ROLE_AGR_STABLE_WORKER'
  ],
  ROLE_FOR_ROLE_COMPANY_AGR_TRD_MAN: [
    'ROLE_AGR_MANAGER',
    'ROLE_TRD_MANAGER',
    'ROLE_AGR_STABLE_WORKER',
    'ROLE_TRD_OFFICE_WORKER',

  ],
};
