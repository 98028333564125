import React from 'react';
import PropTypes from 'prop-types';
import './specification.scss';

const SpecificationItem = ({ specification, ...props }) => {
  return (
    <div style={{ marginLeft:typeof specification.margin !== 'undefined' ? '30px' : typeof specification.marginT !== 'undefined' && '80px', }} id="m-section">
      <div  className="field-specification">
        <div
          style={{
            backgroundSize: '38px 38px',
            width: '55px',
            height: '45px',
            backgroundImage: `url(${specification.image})`,
            cursor: 'pointer',
            backgroundRepeat: 'no-repeat',
            // border: '1px solid red',
          }}
          
        ></div>
        <div className="specification-text">{specification.description}</div>
      </div>
    </div>
  );
};

SpecificationItem.propTypes = {
  specification: PropTypes.object,
  children: PropTypes.node,
};

export default SpecificationItem;
