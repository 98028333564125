import React, { Component } from 'react';
import { InputGroup, Modal, FormControl } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/md';
import { isEmpty } from 'lodash';
import CustomSelect from '../../../components/CustomSelect';
import { tags } from '../../../constants';
import { getStockFromLocal, getCv } from '../../../services/stock.service';
import { OrderService } from '../../../services/order.service';
import { alertActions } from '../../../actions';
import messages from '../../../components/Admin/messages';
import searchImgNotFound from '../../../static/images/admin/searchImgNotFound.png';

import './eartagSearch.css';
import extractData from '../../../services/data.processing';

class EartagSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDetails: false,
      ear_tag: '',
      earTagFields: { maxLength: 10, iso: '276', earTag: 'DE' },
      earTagNumber: { nb: null, maxLength: 10 },
      ear_tag_output_format: '',
      birth_date: '',
      race: '',
      breed: '',
      mother_ear_tag_output_format: '',
      documents: {},
      passport: '',
      displayCv: false,
      company_number_or_name: '',
      county_code_and_federal_state: '',
      date: '06.03.2014',
      is_anonyme: false,
      message: '',
      type: '',
      pdfEncoded: '',
      showPopup: false,
    };
  }

  handleChange = (event) => {
    this.setState({ ear_tag: event.target.value });
  };

  handleChangeEarTagCountry = async (event) => {
    let earTagFields = this.state.earTagFields;
    earTagFields.earTag = event.label;
    earTagFields.maxLength = event.nbDigits;
    earTagFields.iso = event.iso;

    this.setState({
      earTagFields: earTagFields,
    });
  };

  extractEartag = (str) => {
    str.slice(6, str.length);
  };

  displayDetails = async () => {
    this.setState({
      displayDetails: false,
    });
    const { ear_tag, earTagFields } = this.state;
    const token = this.props.users.accessToken;
    const value2 = await getStockFromLocal(
      token,
      earTagFields.iso + '00' + ear_tag,
    );
    this.props.showCv(true);
    if (value2.status === 200) {
      const data = await extractData(value2);
      const {
        ear_tag_output_format,
        birth_date,
        race,
        breed,
        mother_ear_tag_output_format,
        documents,
        passport,
      } = data;
      this.setState({
        displayDetails: true,
        ear_tag_output_format,
        birth_date,
        race,
        breed,
        mother_ear_tag_output_format,
        documents,
        passport,
      });
      const cv = await getCv(token, this.state.ear_tag);
      const dataCv = await extractData(cv);
      await this.props.getCvInfo(dataCv, data);
    } else {
    }
  };

  displayPDF = async (fileId) => {
    let img = await OrderService.getPDFLoad(
      this.props.users.accessToken,
      fileId,
    );
    this.setState({ pdfEncoded: 'data:application/pdf;base64,' + img });
  };

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  renderPassport = () => {
    return (
      <Modal
        show={this.state.showPopup}
        onHide={this.handleClose}
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-pdf ">
          <embed
            width="100%"
            height="94%"
            frameBorder="0"
            src={this.state.pdfEncoded}
            type="application/pdf"
          />
        </Modal.Body>
      </Modal>
    );
  };

  pdfRename = (r) => {
    const res = r.split('+');
    return res[1];
  };

  renderDocs = (item, index) => {
    return (
      <div
        key={index}
        className=" Flex-Report-search-deli-doc doc-types"
        onClick={() => {
          this.displayPDF(item);
          this.openPopUp();
        }}
      >
        <div className=" tourInfo-title-pf tourInfo-title-btn">
          <div className="text-noir-report">{this.pdfRename(item)}</div>
        </div>
        <div className="icon-eartag" />
      </div>
    );
    // })
  };

  renderDetails = () => {
    const {
      ear_tag_output_format,
      birth_date,
      race,
      breed,
      mother_ear_tag_output_format,
      documents,
    } = this.state;
    const color =
      documents.standard_declaration === '' ? { color: '#999' } : {};
    return (
      <div className="Flex-Report-search-res">
        {this.renderPassport()}
        <div className=" Flex-Report-search-res-sou">
          <div className=" Flex-Report-search-res-sou-b1 eartagS" md={6}>
            {ear_tag_output_format}
          </div>
          <div className=" Flex-Report-search-res-sou-b2 text-noir-report-b1 ">
            {birth_date}
          </div>
        </div>
        <div className="Flex-Report-search-res-sou">
          <div
            className=" Flex-Report-search-res-sou-b1  text-noir-report-b1"
            md={6}
          >
            {breed}, {race}
          </div>
          <div
            md={4}
            className=" Flex-Report-search-res-sou-b2  text-noir-report-b1"
          >
            {mother_ear_tag_output_format}
          </div>
        </div>
        <div className="style-border-di">
          <hr className="style-border-div" />
        </div>
        <div className="Flex-Report-search-deli">
          <div className="Flex-Report-search-deli-text text-noir-report" md={6}>
            <FormattedMessage {...messages.LieferschernAnzeigen} />
          </div>
          <div className="Flex-Report-search-deli-text" md={6}>
            {!isEmpty(documents) &&
              documents.delivery_notes.map((item, index) =>
                this.renderDocs(item, index),
              )}
          </div>
        </div>
        {this.state.standard_declaration ? (
          <div
            className="Flex-Report-search-stan "
            onClick={() => {
              this.displayPDF(this.state.standard_declaration);
              this.openPopUp();
            }}
          >
            <div
              className="Flex-Report-search-deli-text text-noir-report"
              style={color}
              md={6}
            >
              <FormattedMessage {...messages.StandardDeclaration} />
            </div>
            <div md={3} className="icon-eartag"></div>
          </div>
        ) : (
          <div className="Flex-Report-search-stan ">
            <div
              className="Flex-Report-search-deli-text text-noir-report"
              style={color}
              md={6}
            >
              <FormattedMessage {...messages.StandardDeclaration} />
            </div>
            <div md={3}>
              <img className="img-loopview" src={searchImgNotFound} alt="" />
            </div>
          </div>
        )}
        {this.state.standard_declaration ? (
          <div className="Flex-Report-search-stan ">
            <div className="text-noir-report" style={color} md={6}>
              <FormattedMessage {...messages.CattlePassports} />
            </div>
            <div
              md={3}
              className="icon-eartag"
              // onClick={() => {
              //   this.props.displayPDF(doc.file_id);
              //   this.openPopUp();
              // }}
            ></div>
          </div>
        ) : (
          <div className="Flex-Report-search-stan ">
            <div className=" text-noir-report" style={color} md={6}>
              <FormattedMessage {...messages.CattlePassports} />
            </div>
            <div
              md={3}
              // onClick={() => {
              //   this.props.displayPDF(doc.file_id);
              //   this.openPopUp();
              // }}
            >
              {' '}
              <img className="img-loopview" src={searchImgNotFound} alt="" />
            </div>
          </div>
        )}
        {typeof this.state.data !== 'undefined' &&
        typeof this.state.data.ear_tag !== 'undefined' ? (
          <div className="Flex-Report-search-stan ">
            <div
              onClick={this.props.setShowForm}
              className="text-noir-report"
              md={6}
            >
              <FormattedMessage {...messages.StockDetails} />
            </div>
            <div
              md={3}

              // onClick={() => {
              //   this.props.displayPDF(doc.file_id);
              //   this.openPopUp();
              // }}
            ></div>
          </div>
        ) : (
          <div className="Flex-Report-search-stan ">
            <div
              onClick={this.props.setShowForm}
              className=" text-noir-report"
              md={6}
            >
              <FormattedMessage {...messages.StockDetails} />
            </div>
            <div
              md={3}
              // onClick={() => {
              //   this.props.displayPDF(doc.file_id);
              //   this.openPopUp();
              // }}
            >
              {' '}
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { displayDetails } = this.state;

    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        color: '#7bd812',
        width: '100%',
      }),

      control: (base, state) => ({
        ...base,
        boxShadow: '',
        width: '100%',
        height: '40px',
        borderWidth: '1px',
        backgroundColor: '#F6F6F6  !important',
        borderColor: ' transparent transparent #eee',
        '&:hover': { borderColor: 'transparent transparent #7bd812' },
      }),
    };
    return (
      <div className="Flex-Report">
        <div className="Flex-Report-search">
          <div className="Flex-Report-search-b1" md={3} id="add-stock-box">
            <CustomSelect
              defaultValue={tags.find((country) => country.value === 'Germany')}
              className="state-box-tags"
              options={tags}
              styles={customStyles}
              onChange={this.handleChangeEarTagCountry}
              required
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
          </div>
          <div className="Flex-Report-search-b2">
            <InputGroup>
              <InputGroup.Prepend onClick={this.displayDetails}>
                <MdSearch />
              </InputGroup.Prepend>
              <FormControl
                type="text"
                minLength={6}
                maxLength={16}
                name="search"
                onChange={this.handleChange}
                onKeyUp={this.displayDetails}
                onKeyDown={this.displayDetails}
                className="search text-val-cv"
                placeholder={
                  this.props.language.locale === 'DE'
                    ? 'Ohrmarke suchen'
                    : 'Search'
                }
              />
            </InputGroup>
          </div>
        </div>
        {displayDetails && this.renderDetails()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EartagSearch);
