import React from 'react';
import bought from '../../../../../../static/images/admin/Icons/Status icons/Bought.png';
import { statusOrders } from '../../../status-orders';
import { Col, Row, ListGroupItem, Image } from 'react-bootstrap';
import { MdNavigateNext } from 'react-icons/md';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import '../../../orders.css';

const OrderItem = ({ order, language, handleBoughtOrders }) => {
  const translateName = e => {
    if (language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück');
    } else return e;
  };

  const handleCheckedOrder = (e, orderId) => {
    const checked = e.target.checked;
    handleBoughtOrders(checked, orderId);
  };
  return (
    <ListGroupItem className="list-group">
      <Row>
        <Col md={3} className="status">
          {order.status === 'BOUGHT' && (
            <div id="checkbox1">
              <label key={order.key} className="container">
                <input
                  type="checkbox"
                  key={order.order_id}
                  onClick={e => handleCheckedOrder(e, order.order_id)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          )}

          <Image className="status-image" src={bought} />
          <p className="status-name">
            {
              statusOrders.find(
                val => order.status.split('_').join(' ') === val.statusOrder,
              ).label
            }
          </p>
        </Col>

        <Col md={7} className="content">
          <p className="green"> {translateName(order.name)}</p>
          <p className="info-item">
            {order.delivery_date} | {order.business_partner}
          </p>
        </Col>
        {/* )} */}
        <Col md={2} className="MdNavigateNext">
          <p className="shared">
            <MdNavigateNext color="#cdcbcb" />
          </p>
        </Col>
      </Row>
      <hr className="style-border" />
    </ListGroupItem>
  );
};

const mapStateToProps = state => {
  const { language } = state.toJS();
  return {
    language,
  };
};

export default connect(
  mapStateToProps,
  null,
)(injectIntl(OrderItem));
