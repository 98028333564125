import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import { svgPaths } from '../../../../constants';
import messages from '../../messages';
import { FormattedMessage } from 'react-intl';
import './cattle-group.scss';
import clsx from 'clsx';

const CattleItem = ({
  removeCattleGroup,
  editCattleGroup,
  cattle,
  index,
  status,
  ...props
}) => {
  return (
    <div className="new-cattle">
      {status === 'PLANNED' && (
        <div className="cattle-header">
          <div className="cattle-title"></div>
          <div className="cattle-actions">
            <div
              className="edit-cattle"
              onClick={e => editCattleGroup(e, index)}
            >
              <SvgIcon size="20" color="#7bd813" viewBox="0 -70 350 580">
                {svgPaths['edit']}
              </SvgIcon>
            </div>
            <div
              className="remove-cattle"
              onClick={e => removeCattleGroup(e, index)}
            >
              x
            </div>
          </div>
        </div>
      )}
      <div className={clsx((!cattle.number && "hide-cattle-section"), "cattle-section")}>
        <div className="c-section-title">
          <FormattedMessage {...messages.amount} />
        </div>
        <div className="c-value">{cattle.number}</div>
      </div>
      <div className={clsx((!cattle.breed && "hide-cattle-section"), "cattle-section")}>
        <div className="c-section-title">
          <FormattedMessage {...messages.NewBreed} />
        </div>
        <div className="c-value">{cattle.breed}</div>
      </div>
      <div className={clsx((!cattle.race && "hide-cattle-section"), "cattle-section")}>
        <div className="c-section-title">
          <FormattedMessage {...messages.NewRace} />
        </div>
        <div className="c-value">{cattle.race}</div>
      </div>
      <div className={clsx((cattle.program_quality && cattle.program_quality.length === 0 && "hide-cattle-section"), "cattle-section")}>
        <div className="c-section-title">
          <FormattedMessage {...messages.QualityPrograms} />
        </div>
        <div className="cattle-program-qualities">
          {(cattle.program_quality || []).map((pq, index) => {
            return (
              <div key={index} className="c-value">
                {pq}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CattleItem.propTypes = {
  truckDetails: PropTypes.object,
};

export default CattleItem;
