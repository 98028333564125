import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';

import truckIcon from '../../../../../static/images/admin/Icons/Status icons/truckIcon.png';

class TruckManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
    };
  }

  render() {
    const { item, driver, vehicleNumber } = this.props;
    return (
      <React.Fragment>
        <hr className="style-border" />
        <Row className="truck-loading-container">
          <Col md={3} className="tours-image">
            <Image src={truckIcon} />
          </Col>
          <Col className="truck-text-groupe" md={6}>
            <span className="tour-name"> {item.transport_company}</span>
            <span className="tour-item">
              {driver}, {vehicleNumber}{' '}
            </span>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TruckManagement;
