const cookie = [
  {
    id: 0,
  },
  {
    id: 1,
    contents: [
      {
        id: 0,
        title: 'Letzte Aktualisierung: 01. Januar 2019',
        content: [
          {
            id: 0,
            desc:
              'Agrabiz ("oder") verwendet Cookies auf der Website https://www.agrabiz.de ("Service"). Durch die Nutzung des Dienstes stimmen Sie der Verwendung von Cookies zu.',
          },
          {
            id: 1,
            desc:
              'In unseren Cookies-Richtlinien erfahren Sie, was Cookies sind, wie wir Cookies verwenden, Ihre Entscheidungsmöglichkeiten bezüglich Cookies und weitere Informationen zu Cookies.',
          },
        ],
      },
      {
        id: 1,
        title: 'Was sind Cookies?',
        content: [
          {
            id: 0,
            desc:
              'Cookies sind kleine Textstücke, die Ihr Browser von einer von Ihnen besuchten Website sendet. Eine Cookie-Datei wird in Ihrem Webbrowser gespeichert und ermöglicht dem Dienst oder einem Drittanbieter, Sie zu erkennen, Ihren nächsten Besuch zu erleichtern und den Dienst für Sie nützlicher zu machen.',
          },
          {
            id: 1,
            desc:
              'Cookies können "Cookies oder "Sitzungscookies" sein. Dauerhafte Cookies verbleiben auf Ihrem PC oder mobilen Gerät, wenn Sie offline gehen, während Sitzungscookies gelöscht werden, sobald Sie Ihren Webbrowser schließen.',
          },
        ],
      },
      {
        id: 2,
        title: 'Wie verwendet Agrabiz Cookies?',
        content: [
          {
            id: 0,
            desc:
              'Wenn Sie den Dienst nutzen und darauf zugreifen, können wir eine Reihe von Cookie-Dateien in Ihrem Webbrowser ablegen. Wir verwenden Cookies für folgende Zwecke:',
          },
          {
            id: 1,
            desc: '-  Um bestimmte Funktionen des Dienstes zu aktivieren',
          },
          {
            id: 2,
            desc:
              '-  Wir verwenden sowohl sitzungsbasierte als auch permanente Cookies für den Dienst und verwenden verschiedene Arten von Cookies, um den Dienst auszuführen:',
          },
          {
            id: 3,
            desc:
              '-  Wesentliche Kekse. Wir verwenden möglicherweise Cookies, um Benutzer zu authentifizieren und eine betrügerische Verwendung von Benutzerkonten zu verhindern.',
          },
        ],
      },
      {
        id: 3,
        title: 'Was sind Ihre Entscheidungen bezüglich Cookies?',
        content: [
          {
            id: 0,
            desc:
              'Wenn Sie Cookies löschen möchten oder Ihren Webbrowser anweisen möchten, Cookies zu löschen oder abzulehnen, besuchen Sie die Hilfeseiten Ihres Webbrowsers. Als europäischer Bürger haben Sie im Rahmen der GDPR bestimmte individuelle Rechte. Weitere Informationen zu diesen Rechten finden Sie im DSGVO-Leitfaden.',
          },
          {
            id: 1,
            desc:
              'Beachten Sie jedoch, dass Sie möglicherweise nicht alle von uns angebotenen Funktionen nutzen können, wenn Sie Cookies löschen oder ablehnen, Ihre Präferenzen nicht speichern und einige unserer Seiten möglicherweise nicht richtig angezeigt werden.',
          },
          {
            id: 2,
            desc:
              'Besuchen Sie für den Chrome-Webbrowser diese Seite von Google:',
          },
          {
            id: 3,
            desc: '-  https://support.google.com/accounts/answer/32050',
          },
          {
            id: 4,
            desc:
              'Besuchen Sie für den Internet Explorer-Webbrowser diese Seite von Microsoft:',
          },
          {
            id: 5,
            desc: '-  http://support.microsoft.com/kb/278835',
          },
          {
            id: 6,
            desc:
              'Für den Firefox-Webbrowser besuchen Sie bitte diese Seite von Mozilla:',
          },
          {
            id: 7,
            desc:
              '-  https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored',
          },
          {
            id: 8,
            desc:
              'Besuchen Sie für den Safari-Webbrowser diese Seite von Apple:',
          },
          {
            id: 9,
            desc: '-  https://support.apple.com/kb/PH21411?locale=de_DE',
          },
          {
            id: 10,
            desc:
              ' Für jeden anderen Webbrowser besuchen Sie bitte die offiziellen Webseiten Ihres Webbrowsers.',
          },
        ],
      },
      {
        id: 4,
        title: 'Wo finden Sie weitere Informationen zu Cookies?',
        content: [
          {
            id: 0,
            desc:
              'Sie können mehr über Cookies und die folgenden Websites von Drittanbietern erfahren:',
          },
          {
            id: 1,
            desc: '-  AllAboutCookies: http://www.allaboutcookies.org/',
          },
          {
            id: 2,
            desc:
              '-  Network Advertising Initiative: http://www.networkadvertising.org/',
          },
        ],
      },
    ],
  },
  {
    id: 2,
  },
];

export default cookie;
