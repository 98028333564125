/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import AddContact from './AddContact';
import { alertActions } from '../../../../actions';

const phoneRegExp =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+\/\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
const FormWithFormik = withFormik({
  mapPropsToValues({
    group,
    number,
    company,
    street,
    city,
    zipCode,
    federalState,
    country,
    phone,
    mobilephone,
    email,
    qualityProgarms,
  }) {
    return {
      number: number,
      company: company,
      group: group,
      email: email,
      phone: phone,
      street: street,
      zipCode: zipCode,
      federalState: federalState,
      country: country,
      city: city,
      mobilephone: mobilephone,
      qualityProgarms: qualityProgarms,
    };
  },
  validationSchema: Yup.object().shape({
    number: Yup.number().optional(),
    company: Yup.string().required(),
    group: Yup.object().required(),
    email: Yup.string().email().optional(),
    phone: Yup.string().matches(phoneRegExp),
    mobilephone: Yup.string().matches(phoneRegExp),
    street: Yup.string().required(),
    zipCode: Yup.string().required(),
    federalState: Yup.object(),
    country: Yup.string().required(),
    city: Yup.string().required(),
    qualityProgarms: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    ),
  }),
  handleSubmit: (values, { props }) => {
    values['number'] =
      typeof values.number !== 'undefined' ? values.number.toString() : '';
    values['phone'] =
      typeof values.phone !== 'undefined' ? values.phone.toString() : '';
    values['mobilephone'] =
      typeof values.mobilephone !== 'undefined'
        ? values.mobilephone.toString()
        : '';
    values['zipCode'] =
      typeof values.zipCode !== 'undefined' ? values.zipCode.toString() : '';
    values['country'] =
      typeof values.country !== 'undefined'
        ? values.country.value.toString()
        : '';
    values['federalState'] =
      typeof values.federalState !== 'undefined' ? values.federalState : '';
    values['qualityProgarms'] =
      typeof values.qualityProgarms !== 'undefined' ? values.qualityProgarms : [];
    props.addContacts(values);
  },
})(AddContact);

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWithFormik);
