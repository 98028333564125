export const breeds = [
  {
    id: 'A',
    value: 'Jungbulle',
    label: 'Jungbulle',
  },
  {
    id: 'B',
    value: 'Bulle',
    label: 'Bulle',
  },
  {
    id: 'C',
    value: 'Ochse',
    label: 'Ochse',
  },
  {
    id: 'D',
    value: 'Kuh',
    label: 'Kuh',
  },
  {
    id: 'E',
    value: 'Färse',
    label: 'Färse',
  },
  {
    id: 'Z(m)',
    value: 'Fresser',
    label: 'Fresser (m)',
  },
  {
    id: 'Z(w)',
    value: 'Fresser w',
    label: 'Fresser (w)',
  },
  {
    id: 'V(m)',
    value: 'Kalb',
    label: 'Kalb (m)',
  },
  {
    id: 'V(m)',
    value: 'Kälb(er) w',
    label: 'Kalb (w)',
  },
];
