import { configConstants } from '../constants';
import { get } from './request';

export async function getBuyingsStocks(
  accessToken,
  programQualities,
  startDate,
  endDate,
) {
  try {
    let token = `${accessToken}`.replace(/['"]+/g, '');
    const contentType = 'application/json; charset=utf-8 ';
    let url = configConstants.Order + '/stocks?startDate=' + startDate;
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (programQualities.length > 0) {
      url += `&programQualities=${JSON.stringify(programQualities)}`;
    }
    return await get(url, token, contentType);
  } catch (error) {}
}
