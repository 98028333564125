import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import FormCreditCard from './FormCreditCard';
import Mandate from './Mandate';
import MandateInfo from './MandateInfo';
import { invoiceActions } from '../../../../actions/invoice.actions';
import { alertActions } from '../../../../actions';
import { editPayment, makePayment } from '../../../../services/invoice.service';
import extractData from '../../../../services/data.processing';
import messages from '../../messages';
import '../invoice.css';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      type: 'creditcard',
      showInfo: false,
      showStepOne: true,
      showStepTwo: false,
      showStepThree: false,
      showSuccesPay: false,
      customer: [],
      defaultRadioA: false,
      imageSrc: '',
    };
  }

  ShowCard = () => {
    return this.setState({ type: '', showInfo: false, showStepOne: false });
  };

  componentDidMount() {
    this.getAllData();
  }

  getAllData = async () => {
    await this.props.getCustomer();
    await this.setState({ customer: this.props.invoices.customer });
    this.setState({ type: 'creditcard' });
  };

  renderStepOne = () => {
    return (
      <>
        {this.state.customer &&
        this.state.customer.mandate &&
        this.state.customer.mandate.status === 'valid' &&
        this.state.customer.mandate.method === 'directdebit' ? (
          <Mandate
            mandate={this.state.customer.mandate}
            showForm={this.goToStepTwo}
            goToStepTwoDirect={this.goToStepTwoDirect}
            downloadPdf={this.props.downloadSepa}
          />
        ) : this.state.customer &&
          this.state.customer.mandate &&
          this.state.customer.mandate.status === 'valid' &&
          this.state.customer.mandate.method !== 'directdebit' ? (
          <MandateInfo
            mandate={this.state.customer.mandate}
            showForm={this.goToStepTwo}
            onSubmitNewVersion={this.onSubmitNewVersion}
            goToStepTwoDirectBanque={this.goToStepTwoDirect}
          />
        ) : (
          <>{this.renderStepTwo()}</>
        )}
      </>
    );
  };

  renderStepThree = () => {
    return (
      <>
        {this.state.customer &&
          this.state.customer.mandate &&
          this.state.customer.mandate.status === 'valid' &&
          this.state.customer.mandate.method !== 'directdebit' &&
          this.onSubmitCeateNew()}
      </>
    );
  };

  returnToMenu = async () => {
    this.setState({ showStepOne: true });
    this.setState({ showStepTwo: false });
    this.props.showPaymentFormCancel();
  };

  goToStepTwo = async () => {
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: true });
  };

  goToStepTwoDirect = async () => {
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: true });
    this.setState({ type: 'directdebit' });
  };

  goToStepTwoDirectBanque = async () => {
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: true });
    this.setState({ type: 'creditcard' });
  };

  goToStepThree = async () => {
    this.setState({ showStepOne: false });
    this.setState({ showStepTwo: false });
    this.setState({ showStepThree: true });
  };

  toggleCheckbox = async (event) => {
    this.setState({ type: event.currentTarget.value });
  };

  checkoutCreditCard = (url) => {
    window.location.href = url;
  };

  onSubmitNewVersion = async () => {
    const { accessToken } = this.props.users;
    if (this.state.customer && this.state.customer.mandate) {
      const value = await editPayment(
        {
          method: this.state.type,
          subscriptionType: this.props.users.user.subscription_type,
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    } else {
      const value = await makePayment(
        {
          method: this.state.type,
          subscriptionType: this.props.users.user.subscription_type,
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    }
  };

  onSubmitCeateNew = async () => {
    const { accessToken } = this.props.users;
    if (this.state.customer && this.state.customer.mandate) {
      const value = await editPayment(
        {
          method: this.state.type,
          subscriptionType: this.props.users.user.subscription_type,
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    } else {
      const value = await makePayment(
        {
          method: this.state.type,
          subscriptionType: this.props.users.user.subscription_type,
        },
        accessToken,
      );
      const customer = await extractData(value);

      if (
        typeof customer !== 'undefined' &&
        typeof customer.checkout !== 'undefined'
      ) {
        this.checkoutCreditCard(customer.checkout);
      }
    }
  };

  renderStepTwo = () => {
    return (
      <div className="form-container">
        <div className="payment-radio">
          <div className="rad-group">
            <input
              type="radio"
              value="creditcard"
              defaultChecked
              onChange={this.toggleCheckbox}
              name="credit"
              id="radio-type-one"
            />
            <label className="label1" htmlFor="radio-type-one">
              <FormattedMessage {...messages.CreditCard} />
            </label>
          </div>

          <div className="rad-group">
            <input
              type="radio"
              value="directdebit"
              onChange={this.toggleCheckbox}
              name="credit"
              id="radio-type-two"
            />
            <span className="newlabel">Lastschrift / EC</span>
          </div>
        </div>

        <FormCreditCard
          returnToMenu={this.returnToMenu}
          onSubmit={this.onSubmitCeateNew}
          loading={this.props.loading}
          method={this.state.type}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.showStepOne && this.renderStepOne()}
        {this.state.showStepTwo && this.renderStepTwo()}
        {this.state.showStepThree && this.renderStepThree()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { invoices, users } = state.toJS();
  return { invoices, users };
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getCustomer: () => dispatch(invoiceActions.fetchCustomer()),
    showPaymentFormCancel: () =>
      dispatch(invoiceActions.showEditPaymentCancel()),
    downloadSepa: () => dispatch(invoiceActions.downloadSepaPdf()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
