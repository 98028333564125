import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';
import TabCompany from './company';
import TabUsers from './Users';
import TabSecurity from './Security';
import Membership from './Membership';
import messages from '../messages';
import { connect } from 'react-redux';
import { specificRoles } from '../../../constants';
import { alertActions, userActions } from '../../../actions';

import './setting.css';
const SettingTabs = ({ users, ...props }) => {
  const [showSuccesPay, setShowSuccessPay] = useState(false);
  const [subscription, setSubscription] = useState();
  const [key, setKey] = useState('company');
  const checkCreditCardSuccessMsg = () => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const isSuccess = params.get('success') === 'true';
    const sub = params.get('subscription');
    if (params.get('token') && params.get('success') && sub) {
      if (isSuccess) {
        setSubscription(sub);
        setShowSuccessPay(true);
        props.updateSubscription(sub);
        setKey('membership');
        props.history.push('/admin/setting');
      } else {
        setKey('membership');
        props.history.push('/admin/setting');
        const timer = setTimeout(() => {
          props.error('Problem in Card');
          props.clear();
        }, 1000);
        return () => clearTimeout(timer);
      }
      return;
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  useEffect(() => {
    checkCreditCardSuccessMsg();
    // Run! Like go get some data from an API.
    if (specificRoles.ROLE_FOR_ROLE_COMPANY_AGR_TRD_MANAG.indexOf(role) > -1) {
      setKey('users');
    }
    if (specificRoles.ROLE_FOR_ROLE_TRD_OFFICE_WORKER_ADMIN.indexOf(role) > -1  || specificRoles.ROLE_FOR_SETTINGS_IND_SEC.indexOf(role) > -1) {
      setKey('security');
    }
    if (localStorage.getItem('subscription') === 'true') {
      setKey('membership');
      localStorage.setItem('subscription', 'false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { role } = users.user;
  const { company_type } = users.user.session;
  return (
    <Container fluid>
      <div className="content">
        <Row>
          <Col md={12} id="admin-setting">
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
              transition={false}
              id="controlled-tab-settings"
            >
              {/* company */}
              {specificRoles.ROLE_FOR_ROLE_COMPANY_OWNER.indexOf(role) > -1 && (
                <Tab
                  eventKey="company"
                  title={
                    <span className="tab-title">
                      <FormattedMessage {...messages.tabCompany} />
                    </span>
                  }
                  tabClassName="setting-tab tab-company"
                >
                  <TabCompany />
                </Tab>
              )}
              {/* users */}
              {specificRoles.ROLE_FOR_SETTINGS_INDES.indexOf(role) > -1 && (
                <Tab
                  eventKey="users"
                  title={
                    <span className="tab-title">
                      <FormattedMessage {...messages.ManagementSideMessage} />
                    </span>
                  }
                  tabClassName="setting-tab tab-users"
                >
                  <TabUsers />
                </Tab>
              )}
              {/* Security */}
              <Tab
                eventKey="security"
                title={
                  <span className="tab-title">
                    <FormattedMessage {...messages.SecuritySideMessage} />
                  </span>
                }
                tabClassName="setting-tab tab-security"
              >
                <TabSecurity />
              </Tab>
              {/* membership */}
              {specificRoles.ROLE_FOR_ROLE_COMPANY_OWNER.indexOf(role) > -1 &&
                (company_type === '1' || company_type === '2') && (
                  <Tab
                    eventKey="membership"
                    title={
                      <span className="tab-title">
                        <FormattedMessage {...messages.MembershipSideMessage} />
                      </span>
                    }
                    tabClassName="setting-tab tab-Member"
                  >
                    <Membership
                      showPaySucces={showSuccesPay}
                      subscription={subscription}
                    />
                  </Tab>
                )}
            </Tabs>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

function mapDispatchToProps(dispatch) {
  return {
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    updateSubscription: (sub) =>
      dispatch(userActions.changeUserSubcription(sub)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingTabs);
