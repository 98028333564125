import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { Modal } from 'react-bootstrap';
import { downloadSEPA } from '../../../../services/invoice.service';
import Loaded from '../../../../static/images/admin/loadIcon.png';

// export default ({ mandate, showForm, downloadPdf }) => {

export default class MandateInfo extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formInvalid: true,
      showPopup: false,
      showPopupTwo: false,
      pdfId: '',
      AcceptPolices: false,
      AcceptCondition: false,
    };
  }

  getDataProtectionPdf = async (fileId) => {
    let img = await downloadSEPA();
    this.setState({ pdfId: 'data:application/pdf;base64,' + img });
  };

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  getNewNumber = (number) => {
    if (typeof number !== 'undefined' && number !== null) {
      var lastFive = number.substr(number.length - 4);
      lastFive = '***' + lastFive;
      return lastFive;
    } else {
      return '';
    }
  };

  render() {
    return (
      <div className="form-container mandate">
        <div className="SEPA-Lastschriftsmandat-flex">
          <div className="SEPA-Lastschriftsmandat-flex-b1">
            <div className="tab-SEPA-Lastschriftsmandat">
              <FormattedMessage {...messages.CreditCard} />
            </div>
            <div className="tab-SEPA-Las-green">
              <FormattedMessage {...messages.Name} /> :{this.props.mandate.name}
            </div>
            <div className="tab-SEPA-Las-green">
              <FormattedMessage {...messages.Number_id} />:
              {this.getNewNumber(this.props.mandate.number)}
            </div>
          </div>
        </div>
        <div
          className="SEPA-Lastschriftsmandat-flex-loaded"
          onClick={(e) => this.props.onSubmitNewVersion()}
        >
          <div className="SEPA-Lastschriftsmandat-flex-b2">
            <div className="tab-SEPA-Lastschriftsmandat">
              <img src={Loaded} alt=""></img>
            </div>
          </div>
          <div className="SEPA-Lastschriftsmandat-flex-b1">
            <div className="tab-SEPA-Las-green tab-SEPA-Lastschriftsmandat">
              <FormattedMessage {...messages.UpdateSEPAVal} />
            </div>
          </div>
        </div>

        <div className="form label credentials-btns ">
          <Col sm={9} className="form-label col-75">
            <Col sm={9}>
              <Button
                className="btn btn-lg btn-success btn-block"
                onClick={(e) => this.props.goToStepTwoDirectBanque()}
              >
                <FormattedMessage {...messages.Zahlungsmethode} />
              </Button>
            </Col>
          </Col>
        </div>

        <div className="SEPA-contact ">
          <FormattedMessage {...messages.UpdateSEPACon} /> <br />
          <label
            htmlFor="phone"
            className="tab-SEPA-Las-green"
            style={{ cursor: 'default' }}
          >
            +49 89 122 677 91 <span> &nbsp;</span>
          </label>
          <FormattedMessage {...messages.UpdateSEPAMail} />
          <label
            htmlFor="email"
            className="tab-SEPA-Las-green"
            style={{ cursor: 'default' }}
          >
            <span> &nbsp;</span>
            abo@agrabiz.de
          </label>
          .
        </div>

        {/* PDF popup*/}
        <Modal
          show={this.state.showPopup === true}
          onHide={this.handleClose}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdfCondition ">
            <embed
              width="100%"
              height="85%"
              frameBorder="0"
              src={this.state.pdfId}
              type="application/pdf"
            />
            <div className="btn-center-Accept"></div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
