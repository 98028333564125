import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import messages from './messages';
import img from '../../../static/images/illus.png';

class ChooseSubscription extends Component {
  goToSubscription = (e) => {
    localStorage.setItem('subscription', 'true');
    this.props.history.push(`/admin/setting`);
  };
  render() {
    return (
      <Container id="admin-home" fluid={true}>
        <Row className="top contain">
          <Col sm={6}>
            <Row className="left-column">
              <Col>
                <Row>
                  <Col>
                    <div className="welcom">
                      <FormattedMessage {...messages.HomeWelcomTo} />{' '}
                      <span className="agrabiz">Agrabiz</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text">
                      <FormattedMessage
                        {...messages.HomeTextSubscriptionPlan}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col sm={6}>
                    <Button
                      onClick={(e) => this.goToSubscription()}
                      variant="success"
                      size="large"
                      style={{ padding: '10px', fontSize: '20px' }}
                      block
                    >
                      <FormattedMessage {...messages.HomeChoosePlann} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Image src={img} />
          </Col>
        </Row>
      </Container>
    );
  }
}

ChooseSubscription.propTypes = {
  freeTrial: PropTypes.func.isRequired,
};

export default withRouter(ChooseSubscription);
