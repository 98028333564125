import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import messages from '../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import { userActions } from '../../actions';

class StepTwo extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      business_type: 0,
      selectAsProducer: false,
    };
  }

  redirectToHome = () => {
    this.props.history.push(`/`);
  };

  handleChange = (business_type) => {
    this.setState({ business_type });
  };

  handleProducer = (e) => {
    this.setState({ selectAsProducer: e.target.checked });

    // this.checkNonAgrabiz();
  };
  showRole = () => {
    // eslint-disable-next-line default-case
    switch (this.state.business_type) {
      case 0:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <h2 className="heading">
              <FormattedMessage {...messages.PreRegistationTitle} />
            </h2>
            <p className="role-txt">
              <FormattedMessage {...messages.PreRegistationBody} />
            </p>
            <Col className="register-btn">
              <Button
                onClick={() => this.nextStep()}
                className="register-form-btn"
                disabled={this.state.business_type === 0}
              >
                <FormattedMessage {...messages.NextBtnReg} />
              </Button>
            </Col>
          </Col>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 1:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleAgriculture} />
            </h2>
            <p className="role-txt">
              <FormattedMessage {...messages.RegisterStepTwoTextAgriculture} />
            </p>
            <Col className="register-btn">
              <Button
                onClick={() => this.nextStep()}
                className="register-form-btn"
              >
                <FormattedMessage {...messages.NextBtnReg} />
              </Button>
            </Col>
          </Col>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 2:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleTrader} />
            </h2>
            <p className="role-txt">
              <FormattedMessage {...messages.RegisterStepTwoTextTrader} />
            </p>
            <div className="role-txtcke">
              <input
                className=" "
                type="checkbox"
                checked={this.state.selectAsProducer}
                onChange={this.handleProducer}
              />
              <span>
                <FormattedMessage {...messages.register_company_producer} />
              </span>
            </div>
            <Col className="register-btn">
              <Button
                onClick={() => this.nextStep()}
                className="register-form-btn"
              >
                <FormattedMessage {...messages.NextBtnReg} />
              </Button>
            </Col>
          </Col>
        );
        // eslint-disable-next-line no-unreachable
        break;

      case 3:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleAbatoire} />
            </h2>
            <p className="role-txt">
              <FormattedMessage {...messages.RegisterStepTwoTextAbatoire} />
            </p>
            <Col className="register-btn">
              <Button
                onClick={() => this.nextStep()}
                className="register-form-btn"
              >
                <FormattedMessage {...messages.NextBtnReg} />
              </Button>
            </Col>
          </Col>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 4:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleTransporter} />
            </h2>
            <p className="role-txt">
              <FormattedMessage {...messages.RegisterStepTwoTextTransporter} />
            </p>
            <Col className="register-btn">
              <Button
                onClick={() => this.nextStep()}
                className="register-form-btn"
              >
                <FormattedMessage {...messages.NextBtnReg} />
              </Button>
            </Col>
          </Col>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 5:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleVet} />
            </h2>
            <p className="role-txt">
              <FormattedMessage {...messages.RegisterStepTwoTextPrivVet} />
            </p>
            <Col className="register-btn">
              <Button
                onClick={() => this.nextStep()}
                className="register-form-btn"
              >
                <FormattedMessage {...messages.NextBtnReg} />
              </Button>
            </Col>
          </Col>
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 6:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitlePriVet} />
            </h2>
            <p className="role-txt">
              <FormattedMessage {...messages.RegisterStepTwoTextVet} />
            </p>
            <Col className="register-btn">
              <Button
                onClick={() => this.nextStep()}
                className="register-form-btn"
              >
                <FormattedMessage {...messages.NextBtnReg} />
              </Button>
            </Col>
          </Col>
        );
        // eslint-disable-next-line no-unreachable
        break;
    }
  };

  nextStep = () => {
    this.props.nextStepRegisterProcess(
      3,
      this.state.business_type,
      this.state.selectAsProducer,
    );
  };

  render() {
    return (
      <Row>
        {this.showRole()}
        <Col md={8}>
          <Row>
            <Col md={12}>
              <ButtonToolbar className="toolbarUser">
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  // defaultValue={1}
                  onChange={this.handleChange}
                >
                  <ToggleButton
                    value={1}
                    className="btn-card ic_p1off"
                  ></ToggleButton>
                  <ToggleButton
                    value={2}
                    className="btn-card ic_p2off"
                  ></ToggleButton>
                  <ToggleButton
                    value={3}
                    className="btn-card ic_p3off"
                  ></ToggleButton>
                  <ToggleButton
                    value={4}
                    className="btn-card ic_p4off"
                  ></ToggleButton>
                  <ToggleButton
                    value={5}
                    className="btn-card ic_p5off"
                  ></ToggleButton>
                  <ToggleButton
                    value={6}
                    className="btn-card ic_p6off"
                  ></ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
              <Col className="step-two-next-btn">
                <Button
                  onClick={() => this.nextStep()}
                  disabled={this.state.business_type === 0}
                  className="register-form-btn"
                >
                  <FormattedMessage {...messages.NextBtnReg} />
                </Button>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

StepTwo.propTypes = {
  company: PropTypes.object,
  nextStepRegister: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    nextStepRegisterProcess: (num, business_type, selectAsProducer) =>
      dispatch(
        userActions.nextStepRegisterProcess(
          num,
          business_type,
          selectAsProducer,
        ),
      ),
  };
}
const mapStateToProps = (state) => {
  const { company } = state.toJS();
  return { company };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
