import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import TruckForm from './TruckForm';
import moment from 'moment-es6';
import { alertActions } from '../../../../../actions';
import './form.css';

const FormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues({
    licence_number,
    delivery_date,
    driver_name,
    delivery_until,
    capacity,
    pick_up_date,
    transport_company_id,
    type,
  }) {
     
    let date = moment(delivery_date, 'DD.MM.YY hh:mm A').toDate();
    return {
      licence_number: licence_number,
      delivery_date: date || new Date(),
      driver_name: driver_name,
      delivery_until: delivery_until,
      capacity: capacity || 30,
      pick_up_date: pick_up_date || date,
      transport_company_id: transport_company_id,
      type: type || 'OWN_CARPOOL',
    };
  },
  validationSchema(props) {
    return Yup.object().shape({
      delivery_date: Yup.date().required(),
      delivery_until: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'delivery until is required')
        .required(),
      driver_name: ["4", "2"].indexOf(props.users.user.session.company_type) > -1 ? Yup.string().required() : Yup.string(),
      licence_number: ["4", "2"].indexOf(props.users.user.session.company_type) > -1 ? Yup.string().required() : Yup.string(),
      capacity: Yup.number().integer().min(1).default(30).required(),
      pick_up_date: Yup.date().required(),
      transport_company_id: Yup.string(),
    })
  },
  handleSubmit: (values, { props, resetForm, setErrors, setSubmitting }) => {
    if (!values.licence_number) values.licence_number = "-"
    if (!values.driver_name) values.driver_name = "-"
    props.togglePopup();
    if (props.editeTruck === false) {
      props.addTruckData(values);
    }
  },
})(TruckForm);

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWithFormik);




