/* eslint-disable */
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Col, Card } from 'react-bootstrap';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { OrderService } from '../../../services/order.service';
import extractData from '../../../services/data.processing';
const TabEvents = lazy(() => import('../Orders/tabEvents'));
import Orders from '../Orders';

class SettingsById extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      width: window.innerWidth,
      show: true,
      details: {},
      orderDetails: {},
      orderId: this.props.match.params.orderId,
      receiverId: this.props.match.params.receiverId,
      truck: {},
      truckDetail: {},
      loaded: false,
    };
  }

  componentDidMount() {
    if (typeof this.props.match.params.orderId !== 'undefined') {
      setTimeout(
        function () {
          //Start the timer
          this.setState({ loaded: true }); //After 2 second, set render to true
        }.bind(this),
        1,
      );
    }
    var newURL = window.location.protocol + "//" + window.location.host 

    localStorage.setItem('orderId', this.props.match.params.orderId);
    localStorage.setItem('orderType', 'sellings');
    window.open(`${newURL}/#/admin/sell-stock`, '_self');
  }

  renderListStocks = () => {
    return <></>;
  };

  render() {
    let renderContainer = false; //By default don't render anything
    if (this.state.loaded) {
      //If this.state.render == true, which is set to true by the timer.
      renderContainer = <>{this.renderListStocks()}</>; //Add dom elements
    } else {
      renderContainer = (
        <div>
          waiting ...
          <div key={0} className="loader-wrapper">
            <div className="loader" key={0}></div>
          </div>
        </div>
      ); //Add dom elements
    }
    return renderContainer; //Render the dom elements, or, when this.state == false, nothing.
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsById);
