import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { alertActions } from '../../../../actions';
import { getStockByIds } from '../../../../services/stock.service';
import extractData from '../../../../services/data.processing';

import searchImg from '../../../../static/images/admin/search.png';
import searchImgNotFound from '../../../../static/images/admin/searchImgNotFound.png';
import './tour.css';

class EartagsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passport: '',
    };
  }

  getPassportId = async () => {
    let value2 = await getStockByIds(
      this.props.users.accessToken,
      this.props.pass._id,
    );

    if (value2.status === 200) {
      const data = await extractData(value2);
      if (typeof data.passport !== 'undefined') {
        this.setState({
          passport: data.passport,
        });
      } else {
      }
    }
  };

  componentDidMount() {
    this.getPassportId();
  }

  render() {
    return (
      <div key={this.props.index} className="passport">
        <div className="eartag">
          <div className="">{this.props.pass.ear_tag_output_format}</div>
        </div>
        <div>
          <Image
            className=""
            src={
              (typeof this.state.passport !== 'undefined' &&
                this.state.passport.length > 0) ||
              typeof this.state.passport == 'undefined'
                ? searchImg
                : searchImgNotFound
            }
            onClick={() => {
              ((typeof this.state.passport !== 'undefined' &&
                this.state.passport.length > 0) ||
                typeof this.state.passport == 'undefined') &&
                this.props.getPassport(this.props.pass);
            }}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EartagsList);
