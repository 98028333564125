import { Component } from 'react';
import invariant from 'invariant';
import PropTypes from 'prop-types';

class WithIsScrolled extends Component {
  state = {
    isScrolled: true,
  };

  UNSAFE_componentWillMount() {
    invariant(
      typeof this.props.children === 'function',
      'The children prop is expected to be a function',
    );
  }

  render() {
    return this.props.children(this.state);
  }
}

WithIsScrolled.propTypes = {
  children: PropTypes.func.isRequired,
};

export default WithIsScrolled;
