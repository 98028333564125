import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { editCompany } from '../../../../actions/company.actions';
import extractData from '../../../../services/data.processing';
import { getCompany } from '../../../../services/company.service';

import messages from '../../../../components/Admin/messages';
import '../setting.css';
const optionsTypesUse = [
  {
    value: 'RM1 - Mastkaelber bis 8 Mo, ggf. mitteilungspfl.',
    label: 'RM1 - Mastkaelber bis 8 Mo, ggf. mitteilungspfl.',
  },
  {
    value: 'RM2 - Mast ab 8 Mo, ggf. mitteilungspfl.',
    label: 'RM2 - Mast ab 8 Mo, ggf. mitteilungspfl.',
  },
  {
    value: 'RN3 - sonstige, nicht mitteilungspflichtig.',
    label: 'RN3 - sonstige, nicht mitteilungspflichtig.',
  },
];
class ProfileDetailsOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
      company: {},
      company_owner: {},
      company_adress: {},
      agriculturist_extra: {},
      qualite_program: [],
      selectedOptionTypeUser: [],
      firstname: '',
      surname: '',
      email: '',
      Invoiceemail : '',
      phoneNumber: '',
      mobileNumber: '',
      faxNumber: '',
      isMounted: false,
    };
  }

  componentDidMount() {
    this.getCompany();
  }

  UNSAFE_componentWillMount() {
    this.getCompany();
  }

  async getCompany() {
    let token = this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;

    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const user = await extractData(value);
      if (typeof user !== 'undefined')
        this.setState({
          role: this.props.users.user.role,
          business_type:
            typeof user !== 'undefined' ? parseInt(user.business_type) : '',
          company: user,
          company_owner: user.owner,
          company_adress: user.address,
          agriculturist_extra: user.agriculturist_extra,
          qualite_program: user.program_quality,
          firstname: this.state.company_owner.firstname,
          surname: this.state.company_owner.surname,
          email: this.state.company_owner.email,
          phoneNumber: this.state.company.phone_number,
          mobileNumber: this.state.company.mobile_number,
          faxNumber: this.state.company.fax_number,
          invoiceEmail: (typeof this.state.company.invoiceEmail !== 'undefined' && this.state.company.invoiceEmail !== "") ? this.state.company.invoiceEmail : this.state.company_owner.email,
          selectedOptionTypeUser: optionsTypesUse.filter(
            (e) => user.usage.indexOf(e.label) > -1,
          ),
          isMounted: true,
        });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'firstname':
        this.setState({ firstname: value });
        break;
      case 'surname':
        this.setState({ surname: value });
        break;
      case 'email':
        this.setState({ email: value });
        break;
      case 'phoneNumber':
        this.setState({ phoneNumber: value });
        break;
      case 'mobileNumber':
        this.setState({ mobileNumber: value });
        break;
      case 'faxNumber':
        this.setState({ faxNumber: value });
        break;
        case 'invoiceEmail':
          this.setState({ invoiceEmail: value });
          break;
      default:
        break;
    }
  };

  // functions from FormCompany
  handleSubmit = async (e) => {
    e.preventDefault();
    let company = this.state.company;
    const owner = {
      firstname: this.state.firstname,
      surname: this.state.surname,
      email: this.state.email,
      company_password: '',
    };
    company.owner = owner;
    company.fax_number = this.state.faxNumber;
    company.phone_number = this.state.phoneNumber;
    company.mobile_number = this.state.mobileNumber;
    company.mobile_number = this.state.mobileNumber;
    company.invoiceEmail = this.state.invoiceEmail;
    delete company.update_pin;
    await this.props.editCompany(company);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="new-cards-owner">
          <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.firstName} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <input
                className="new-cards-ow-inner-b2 form-control"
                name="firstname"
                id="firstName"
                required
                onChange={this.handleChange}
                defaultValue={this.state.firstname}
              />
            </div>
          </div>
          <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.lastName} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <input
                className=" new-cards-ow-inner-b2 form-control"
                name="surname"
                id="lastName"
                onChange={this.handleChange}
                type="text"
                required
                defaultValue={this.state.surname}
              />
            </div>
          </div>
          <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.phoneNumber} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <input
                className=" new-cards-ow-inner-b2 form-control"
                name="phoneNumber"
                onChange={this.handleChange}
                required
                placeholder="Phone"
                defaultValue={this.state.phoneNumber}
              />
            </div>
          </div>
          <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.mobileNumber} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <input
                className=" new-cards-ow-inner-b2 form-control"
                name="mobileNumber"
                onChange={this.handleChange}
                required
                placeholder="Mobile"
                defaultValue={this.state.mobileNumber}
              />
            </div>
          </div>
          <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.faxNumber} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <input
                className=" new-cards-ow-inner-b2 form-control"
                name="faxNumber"
                id="faxNumber"
                placeholder="Fax"
                onChange={this.handleChange}
                defaultValue={this.state.faxNumber}
                required
              />
            </div>
          </div>
          <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
              <FormattedMessage {...messages.email} />
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <input
                className=" new-cards-ow-inner-b2 form-control"
                name="email"
                id="email"
                onChange={this.handleChange}
                defaultValue={this.state.email}
                required
              />
            </div>
          </div>
                    <div className="new-cards-owner-inner">
            <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.Invoiceemail} />
            
            </div>
            <div
              md={8}
              id="settings-inner-card"
              className="new-cards-owner-inner-b2"
            >
              <input
                className=" new-cards-ow-inner-b2 form-control"
                name="invoiceEmail"
                id="invoiceEmail"
                onChange={this.handleChange}
                defaultValue={this.state.invoiceEmail}
                required
              />
            </div>
          </div>
          <div className="new-cards-owner-inner-b2-btn">
            <Button
              type="submit"
              className="form-btn-valide add-s-bottom-part-cols"
            >
              <FormattedMessage {...messages.EditMessage} />
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editCompany: (data) => dispatch(editCompany(data)),
  };
}
const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileDetailsOwner);
