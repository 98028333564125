import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Container,
  Image,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { MdLockOutline } from 'react-icons/md';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { style } from '../../utils/notifications';
import { alertActions, userActions } from '../../actions';
import messages from '../../components/Admin/messages';
import logo from '../../static/images/logo.png';
import img from '../../static/images/right-reset-password-719275.png';
import LocaleToggleText from '../../containers/LocaleToggleText';
import './main.css';

class Reset extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate() {
    const { alert } = this.props;
    if (alert.type) {
      this.addNotification(alert.type, alert.message);
    }
  }
  addNotification = (type, message) => {
    let level;
    switch (type) {
      case 'alert-success':
        level = 'success';
        break;
      case 'alert-warning':
        level = 'warning';
        break;
      case 'alert-danger':
        level = 'error';
        break;
      case 'alert-info':
        level = 'info';
        break;
      default:
        break;
    }
    if (level) {
      this.refs.notificationSystem.addNotification({
        title: <span data-notify="icon" className="pe-7s-bell" />,
        message: message,
        level: level,
        position: 'tc',
        autoDismiss: 5,
      });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const passwordConfirm = this.passwordConfirm.value;
    const password = this.password.value;
    let token = this.props.match.params.token;
    try {
      if (password === passwordConfirm) {
        await this.props.resetPassword(password, token);
      } else {
        const timer = setTimeout(() => {
          this.props.error(
            <FormattedMessage {...messages.ConfirmDoublePassword} />,
          );
          this.props.clear();
        }, 500);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      const timer = setTimeout(() => {
        this.props.error(error);
        this.props.clear();
      }, 500);
      return () => clearTimeout(timer);
    }
  };

  showPassword = () => {
    this.setState((prevState) => ({
      visiblePassword: !prevState.visiblePassword,
    }));
  };

  showPasswordConfirm = () => {
    this.setState((prevState) => ({
      visiblePasswordConfirm: !prevState.visiblePasswordConfirm,
    }));
  };

  render() {
    return (
      <Container id="reset-container" fluid>
        <NotificationSystem ref="notificationSystem" style={style} />
        <div className="header-reset flex-center">
          <div className="header-reset-image">
            <Link to="/">
              <Image src={logo} />
            </Link>
          </div>
        </div>
        <Row className="reset-form">
          <Col md={7}>
            <Row>
              <Col md={3} />
              <Col md={9}>
                <h4 className="header-reset-text">
                  <FormattedMessage {...messages.ResetPassword} />
                </h4>
                <h6 className="color-grid">
                  <FormattedMessage {...messages.ResetPasswordHeadText} />
                </h6>
              </Col>
            </Row>
            <Row>
              <Col md={3} />
              <Col md={6}>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <MdLockOutline />
                      </InputGroup.Prepend>
                      <FormControl
                        size="sm"
                        type={this.state.visiblePassword ? 'text' : 'password'}
                        placeholder={
                          this.props.language.locale === 'DE'
                            ? 'Passwort'
                            : 'Password'
                        }
                        required
                        ref={(password) => {
                          this.password = password;
                        }}
                      />
                      <InputGroup.Prepend onClick={() => this.showPassword()}>
                        {this.state.visiblePassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </InputGroup.Prepend>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <MdLockOutline />
                      </InputGroup.Prepend>
                      <FormControl
                        size="sm"
                        type={
                          this.state.visiblePasswordConfirm
                            ? 'text'
                            : 'password'
                        }
                        placeholder={
                          this.props.language.locale === 'DE'
                            ? 'Passwort bestätigen'
                            : 'Confirm Password'
                        }
                        required
                        ref={(passwordConfirm) => {
                          this.passwordConfirm = passwordConfirm;
                        }}
                      />
                      <InputGroup.Prepend
                        onClick={() => this.showPasswordConfirm()}
                      >
                        {this.state.visiblePassword ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </InputGroup.Prepend>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Button type="submit" variant="success" size="large" block>
                      <FormattedMessage {...messages.Save} />
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="side-reset-image">
            <img alt="" src={img} />
          </Col>
        </Row>
        <div id="register-footer">
          <div className="footer-left-section">© Yanogo GmbH {new Date().getFullYear()}.</div>
          <div className="footer-right-section">
            <div className="language-content">
              <span className="language-text"> Language : &nbsp;</span>
            </div>
            <LocaleToggleText active={true} />
          </div>
        </div>
      </Container>
    );
  }
}

Reset.propTypes = {
  handleSubmit: PropTypes.func,
};
function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    resetPassword: (password, token) =>
      dispatch(userActions.resetPassword(password, token)),
  };
}
const mapStateToProps = (state) => {
  const { alert, language } = state.toJS();
  return { alert, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
