import React from 'react';

import PropTypes from 'prop-types';
import { Col, Card, Row, Button, Image, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import RegularInput from '../../Shared/components/inputs/RegularInput';
import { MdNavigateBefore } from 'react-icons/md';
import animal from '../../../static/images/c-users.png';
import { races, breeds, breedm, tags } from '../../../constants';
import moment from 'moment-es6';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import { addLeadingZero } from '../../../helpers/order';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';
import { svgPaths } from '../../../constants';
import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';
import '../../../static/css/datepicker.css';
import locale from '../../../utils/DataPickerLang';

const customStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#7bd812',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#7bd812',
    width: '100%',
  }),

  control: (base, state) => ({
    ...base,
    boxShadow: '',
    width: '100%',
    borderWidth: '1px',
    borderColor: ' transparent transparent #eee',
    '&:hover': { borderColor: 'transparent transparent #7bd812' },
  }),
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.newIncomingDateRef = React.createRef();
    this.calendarBirthRef = React.createRef();
    this.state = {
      type: 1,
      box: '',
      birth_date: new Date(),
      incoming_stock_date: new Date(),
      togglePopupPassword: false,
      file: null,
      earTagFieldMother: {
        maxLength: this.props.data.maxLengthEarTagMother,
        iso: '',
        earTag: this.props.data.mother_ear_tag_output_format,
      },
      earTagNumber: { nb: '', maxLength: 10 },
      raceSelected: {
        label: '',
        value: this.props.race !== '' ? this.props.race : '',
      },
      breedSelected: {
        label: '',
        value: this.props.breed !== '' ? this.props.breed : '',
      },
      incoming_weight: '',
      selling_weight: '',
    };
  }
  handleBirthDateChange = (date) => {
    if (date) {
      this.setState({ birth_date: date });
    }
  };

  handleIncomingDateChange = (date) => {
    if (date) {
      this.setState({ incoming_stock_date: date });
    }
  };

  addToListSubmit = async (e) => {
    e.preventDefault();
    const { birth_date, incoming_stock_date } = this.state;
    const formData = e.target;

    const birthDate = new Date(birth_date);
    const birth_date_sort = `${birthDate.getFullYear()}-${addLeadingZero(
      birthDate.getMonth() + 1,
    )}-${addLeadingZero(birthDate.getDate())}`;

    const birth_date_standard = `${addLeadingZero(
      birthDate.getDate(),
    )}.${addLeadingZero(birthDate.getMonth() + 1)}.${birthDate
      .getFullYear()
      .toString()
      .slice(-2)}`;

    const incomingDate = new Date(incoming_stock_date);
    const incoming = `${addLeadingZero(
      incomingDate.getDate(),
    )}.${addLeadingZero(incomingDate.getMonth() + 1)}.${incomingDate
      .getFullYear()
      .toString()
      .slice(-2)}`;
    let data = {
      ...this.props.data,
      ear_tag: this.props.ear_tag,
      type: this.props.type,
      birth_date: birth_date_standard,
      birth_date_sort: birth_date_sort,
      breed: this.state.breedSelected.value,
      race: this.state.raceSelected.value,
      incoming_stock_date: incoming,
      mother_ear_tag: this.factoryEarTag(),
      mother_ear_tag_output_format: this.completeEarTagWithZero(
        this.state.earTagFieldMother.earTag,
        this.state.earTagFieldMother.maxLength,
      ),
      previous_owner: formData.elements['previous_owner'].value,
      box: formData.elements['box'].value,
      incoming_weight: formData.elements['incoming_weight'].value,
      selling_weight: formData.elements['selling_weight'].value,
      sex: '1',
      maxLengthEarTagMother: this.state.earTagFieldMother.maxLength,
      file: this.state.file,
    };
    await this.props.addToListToHit(data);
  };

  factoryEarTag = () => {
    var earTagFieldMother = this.state.earTagFieldMother;
    var earTagNumber = this.state.earTagNumber;
    if (typeof this.state.earTagFieldMother.earTag === 'undefined') {
      earTagFieldMother.earTag = 'DE';
      earTagFieldMother.maxLength = 10;
      this.setState({ earTagFieldMother: earTagFieldMother });
    }
    earTagFieldMother.earTag = earTagFieldMother.earTag.concat(earTagNumber.nb);
    if (typeof this.state.earTagFieldMother.earTag !== 'undefined') {
      this.setState({ earTagFieldMother: earTagFieldMother });

      var earTag = this.completeEarTagWithZero(
        this.state.earTagFieldMother.earTag,
        this.state.earTagFieldMother.maxLength,
      );

      return earTag;
    }
    return '';
  };
  completeEarTagWithZero(earTag, maxLength) {
    if (typeof earTag !== 'undefined' && earTag.length <= maxLength + 2) {
      var zero = '0';
      return earTag + zero.repeat(maxLength + 2 - earTag.length);
    }
    return '';
  }

  renderBreedsOptions = (list, selected) =>
    list.map((val, i) => (
      <option
        key={i}
        value={val.name}
        selected={val.name === selected || val.id === selected}
      >
        {val.label}
      </option>
    ));

  handleEnterInput = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  togglePopupPassword = () => {
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
  };
  _clear = () => {
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
    this.setState({ file: null });
  };

  formatEarTag = (tag) => {
    var output =
      tag.substr(0, 2) +
      ' ' +
      tag.substr(2, 2) +
      ' ' +
      tag.substr(4, 3) +
      ' ' +
      tag.substr(7, tag.length - 7);
    return output;
  };

  disableInput = () => {
    return (
      this.props.type === 'incoming' || Object.keys(this.props.data).length > 0
    );
  };
  handleChangeEarTagCountry = (event) => {
    let earTagFieldMother = this.state.earTagFieldMother;
    earTagFieldMother.earTag = event.label;
    earTagFieldMother.maxLength = event.nbDigits;
    earTagFieldMother.iso = event.iso;
    this.setState({
      earTagFieldMother: earTagFieldMother,
    });
  };

  handleChangeEarTagMother = (event) => {
    let earTagNumber = this.state.earTagNumber;
    earTagNumber.nb = event.target.value;
    this.setState({
      earTagNumber: earTagNumber,
    });
  };
  handleChangeRace = (event) => {
    let raceSelected = this.state.raceSelected;
    raceSelected.label = event.label;
    raceSelected.value = event.value;

    this.setState({
      raceSelected: raceSelected,
      race: raceSelected,
    });

    this.props.handleChangeRace(raceSelected.value);
  };
  handleChangeBreed = (event) => {
    let breedSelected = this.state.breedSelected;
    breedSelected.label = event.label;
    breedSelected.value = event.value;

    this.setState({
      breedSelected: breedSelected,
      breed: breedSelected,
    });
    this.props.handleChangeBreed(breedSelected.value);
  };

  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  newIncomingSelectedDate = () => {
    const { data } = this.props;
    const { incoming_stock_date } = this.state;
    return data.incoming_stock_date !== '' &&
      typeof data.incoming_stock_date != 'undefined'
      ? moment(data.incoming_stock_date, 'DD.MM.YY hh:mm A').toDate()
      : incoming_stock_date;
  };

  newBirthSelectedDate = () => {
    const { data } = this.props;
    const { birth_date } = this.state;
    return data.birth_date !== '' && typeof data.birth_date != 'undefined'
      ? moment(data.birth_date, 'DD.MM.YY hh:mm A').toDate()
      : birth_date;
  };

  newSelectedDateInput = (date) => {
    const { data } = this.props;
    return data[date] !== '' && typeof data[date] != 'undefined'
      ? `${addLeadingZero(data[date].getDate())}.${addLeadingZero(
          data[date].getMonth() + 1,
        )}.${data[date].getFullYear()}`
      : `${addLeadingZero(date.getDate())}.${addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`;
  };

  checkBirthDatePickerState = () => {
    this.calendarBirthRef.current.setOpen(true);
  };

  checkIncomingDatePickerState = () => {
    this.newIncomingDateRef.current.setOpen(true);
  };

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      // eslint-disable-next-line jsx-a11y/alt-text
      $imagePreview = <img src={imagePreviewUrl} />;
    } else {
      $imagePreview = <div className="previewText">Please select an Image</div>;
    }

    return (
      <Card className="card-box new-stock-wrapper">
        <Modal
          show={this.state.togglePopupPassword}
          onHide={this.togglePopupPassword}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <Modal.Title />
          </Modal.Header>
          <Modal.Body className="text-center">{$imagePreview}</Modal.Body>
          <Modal.Footer>
            <Button onClick={this._clear}>Delete</Button>
          </Modal.Footer>
        </Modal>

        <div className="add-stock-top-part">
          <div className="navigate-before">
            <div md={3}>
              <MdNavigateBefore
                className="before"
                onClick={this.props.setShowForm}
              />
            </div>
          </div>
          <Row className="under-top-one">
            <div className="view-eartag-top-part">
              <div className="v-eartag-left">
                <Image src={animal} width="30%" />
                <div className="v-eartag-number">
                  {this.formatEarTag(this.props.ear_tag)}
                </div>
              </div>
              <div className="v-eartag-right v-eartag-passport passpord-photo">
                {this.state.file == null && (
                  <span
                    className={
                      this.props.type !== 'incoming'
                        ? ' passpord-photo-disabled'
                        : ' passpord-photo-avaible'
                    }
                  />
                )}

                {this.state.file != null && (
                  <span
                    onClick={
                      this.props.type !== 'incoming'
                        ? null
                        : this.togglePopupPassword
                    }
                    className={
                      this.props.type === 'incoming'
                        ? ' passpord-photo-view'
                        : ' passpord-photo-disabled'
                    }
                  />
                )}
                {this.props.type === 'incoming' && this.state.file === null && (
                  <input
                    type="file"
                    name="llaalal"
                    className="upload-file-button"
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={this.onFileChange}
                  />
                )}
                {this.state.file === null && this.props.type === 'incoming' && (
                  <span className=" orange">
                    {' '}
                    <FormattedMessage {...messages.AddPassport} />
                  </span>
                )}
                {this.state.file != null && this.props.type === 'incoming' && (
                  <span className=" avaible">
                    {' '}
                    <FormattedMessage {...messages.ViewPassport} />
                  </span>
                )}
              </div>
            </div>
          </Row>

          <form
            onSubmit={this.addToListSubmit}
            ref={(form) => (this.form = form)}
            id="stock-item-form"
            className="under-top-two"
          >
            <Row>
              <Col md={6} className="text-center space-top-bottom">
                <div className="eartag-selection">
                  <div className="bottom-inputs">
                    <RegularInput
                      select
                      label={<FormattedMessage {...messages.NewRace} />}
                      placeholder="Select race"
                      name="race"
                      id="state"
                      options={races}
                      className={'selected'}
                      defaultValue={races.find(
                        (race) => race.value === this.props.race,
                      )}
                      onChange={this.handleChangeRace}
                      required
                      // isDisabled={this.disableInput()}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#F1FFDE',
                          primary: '#7bcd10',
                        },
                      })}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} className="text-center space-top-bottom">
                <div className="eartag-selection">
                  <div className="bottom-inputs">
                    <RegularInput
                      select
                      label={<FormattedMessage {...messages.NewBreed} />}
                      defaultValue={
                        this.props.type === 'birth'
                          ? breedm.find(
                              (breed) => breed.value === this.props.breed,
                            )
                          : breeds.find(
                              (breed) => breed.value === this.props.breed,
                            )
                      }
                      placeholder="Select breed"
                      name="breed"
                      id="state"
                      options={this.props.type === 'birth' ? breedm : breeds}
                      className={'selected'}
                      onChange={this.handleChangeBreed}
                      required
                      // isDisabled={this.disableInput()}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#F1FFDE',
                          primary: '#7bcd10',
                        },
                      })}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="text-center space-top-bottom">
                {this.state.earTagFieldMother.earTag === '' ||
                this.state.earTagFieldMother.earTag === undefined ? (
                  <div className="eartag-selection mother-eartag">
                    <div className="top-label">
                      <FormattedMessage {...messages.NewEarTagMother} />
                    </div>
                    <div className="bottom-inputs eartag-input">
                      <div className="left-select">
                        <RegularInput
                          defaultValue={tags.find(
                            (country) => country.value === 'Germany',
                          )}
                          options={tags}
                          styles={customStyles}
                          onChange={this.handleChangeEarTagCountry}
                          required
                          select
                          className={'selected'}
                          // isDisabled={this.disableInput()}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#F1FFDE',
                              primary: '#7bcd10',
                            },
                          })}
                        />
                      </div>
                      <div className="right-input">
                        <RegularInput
                          name="mother_ear_tag"
                          maxLength={this.state.earTagNumber.maxLength}
                          value={this.state.earTagNumber.nb}
                          onChange={this.handleChangeEarTagMother}
                          // isDisabled={this.disableInput()}
                          required
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="eartag-selection mother-eartag">
                    <div className="top-label">
                      <FormattedMessage {...messages.NewEarTagMother} />
                    </div>
                    <div className="bottom-inputs eartag-input">
                      <div className="left-select">
                        <RegularInput
                          defaultValue={tags.find(
                            (country) =>
                              country.label ===
                              this.state.earTagFieldMother.earTag.slice(0, 2),
                          )}
                          options={tags}
                          className={'selected'}
                          styles={customStyles}
                          onChange={this.handleChangeEarTagCountry}
                          required
                          select
                          // isDisabled={this.disableInput()}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#F1FFDE',
                              primary: '#7bcd10',
                            },
                          })}
                        />
                      </div>
                      <div className="right-input">
                        <RegularInput
                          type="text"
                          name="mother_ear_tag"
                          maxLength={this.state.earTagFieldMother.maxLength}
                          onChange={this.handleChangeEarTagMother}
                          isDisabled={this.disableInput()}
                          value={
                            typeof this.props.data.mother_ear_tag ===
                            'undefined'
                              ? this.state.earTagNumber.nb
                              : this.props.data.mother_ear_tag.slice(2)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col md={6} className="text-center space-top-bottom">
                <div className="eartag-selection">
                  <div className="last-item-calender buy-sell-calendar">
                    <RegularInput
                      label={<FormattedMessage {...messages.NewBirthDate} />}
                      picker
                      type="text"
                      name="deliveryDate"
                      defaultValue={this.newSelectedDateInput(
                        this.state.birth_date,
                      )}
                      onClick={() => {
                        this.calendarBirthRef.current.setOpen(true);
                      }}
                    />
                    <div className="date-field picker">
                      <SvgIcon
                        color="#9f9f9f"
                        onClick={this.checkBirthDatePickerState}
                        size="30"
                        viewBox="0 0 600 600"
                      >
                        {svgPaths['calendar']}
                      </SvgIcon>

                      <DatePicker
                        selected={this.newBirthSelectedDate()}
                        onChange={this.handleBirthDateChange}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarBirthRef}
                        locale={locale}
                        // disabled={this.disableInput()}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className="text-center space-top-bottom previous-owner"
              >
                <div className="eartag-selection">
                  <RegularInput
                    label={<FormattedMessage {...messages.NewPreviousOwner} />}
                    type="text"
                    name="previous_owner"
                    required
                    defaultValue="N/A"
                    disabled={this.props.type !== 'incoming' ? true : false}
                  />
                </div>
              </Col>
              <Col md={6} className="text-center space-top-bottom">
                <div className="eartag-selection">
                  <div className="last-item-calender buy-sell-calendar">
                    <RegularInput
                      label={<FormattedMessage {...messages.NewIncomingDate} />}
                      picker
                      type="text"
                      name="incomingDate"
                      value={this.newSelectedDateInput(
                        this.state.incoming_stock_date,
                      )}
                      onClick={() => {
                        this.newIncomingDateRef.current.setOpen(true);
                      }}
                    />
                    <div className="date-field picker">
                      <SvgIcon
                        color="#9f9f9f"
                        onClick={this.checkIncomingDatePickerState}
                        size="30"
                        viewBox="0 0 600 600"
                      >
                        {svgPaths['calendar']}
                      </SvgIcon>

                      <DatePicker
                        selected={this.newIncomingSelectedDate(
                          this.state.incoming_stock_date,
                        )}
                        onChange={this.handleIncomingDateChange}
                        dateFormat="dd.MM.yyyy"
                        ref={this.newIncomingDateRef}
                        locale={locale}
                        // disabled={this.disableInput()}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* new  */}
            <Row style={{ height: '20%' }}>
              <Col
                md={6}
                className="text-center space-top-bottom previous-owner"
              >
                <div className="eartag-selection">
                  <RegularInput
                    label={<FormattedMessage {...messages.Einstallgewicht} />}
                    type="number"
                    name="incoming_weight"
                    required
                    step=".01"
                    onChange={this.handleEnterInput}
                    defaultValue={
                      this.props.data.incoming_weight !== '' &&
                      typeof this.props.data.incoming_weight != 'undefined'
                        ? this.props.data.incoming_weight
                        : this.state.incoming_weight
                    }
                  />
                </div>
              </Col>

              <Col
                md={6}
                className="text-center space-top-bottom previous-owner"
              >
                <div className="eartag-selection">
                  <RegularInput
                    label={<FormattedMessage {...messages.Verkaufsgewicht} />}
                    type="number"
                    name="selling_weight"
                    // required
                    step=".01"
                    onChange={this.handleEnterInput}
                    defaultValue={
                      this.props.data.selling_weight !== '' &&
                      typeof this.props.data.selling_weight != 'undefined'
                        ? this.props.data.selling_weight
                        : this.state.selling_weight
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center space-top-bottom box-number">
                <div className="eartag-selection">
                  <RegularInput
                    label={<FormattedMessage {...messages.BoxNumber} />}
                    type="number"
                    name="box"
                    onChange={this.handleEnterInput}
                    maxLength={4}
                    defaultValue={
                      this.props.data.box !== '' &&
                      typeof this.props.data.box != 'undefined'
                        ? this.props.data.box
                        : this.state.box
                    }
                  />
                </div>
              </Col>
            </Row>
          </form>
        </div>
        <div className="add-stock-bottom-part">
          <NormalButton type="submit" form="stock-item-form">
            <FormattedMessage {...messages.NewAddToList} />
          </NormalButton>
        </div>
      </Card>
    );
  }
}
Form.propTypes = {
  handleChangeEarTagMother: PropTypes.func,
  formatEarTag: PropTypes.func,
  handleEnterDate: PropTypes.func,
  renderBreedsOptions: PropTypes.func,
  factoryEarTag: PropTypes.func,
  addToListSubmit: PropTypes.func,
  handleChangeRace: PropTypes.func,
  handleChangeBreed: PropTypes.func,
};
export default Form;
