import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  uploadFileQualityProgram,
  editCompanyNewVersion,
} from '../../../../services/company.service';
import messages from '../../../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import { Form, Col } from 'react-bootstrap';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import { svgPaths } from '../../../../constants';
import DatePicker from 'react-datepicker';
import './form.css';
import locale from '../../../../utils/DataPickerLang';

class FormQualityProgram extends Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      expirationDate:
        this.props.currentQuality[0] &&
        this.props.currentQuality[0].expirationDate,
      bioKontrol:
        this.props.currentQuality[0] && this.props.currentQuality[0].bioKontrol ? this.props.currentQuality[0].bioKontrol : '',
      bioVerband:
        this.props.currentQuality[0] && this.props.currentQuality[0].bioVerband ? this.props.currentQuality[0].bioVerband : '',
      selectedFile:
        this.props.currentQuality[0] &&
        this.props.photos[
          this.findIndexQuality(this.props.currentQuality[0].name).index
        ],
      quality: [],
      file: null,
    };
  }

  findIndexQuality = (val) => {
    let find = false;
    let index = 0;
    this.props.qualities.forEach(async (element, i) => {
      if (element.name === val) {
        find = true;
        index = i;
      }
    });
    return { find: find, index: index };
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { expirationDate, bioKontrol, bioVerband, file } = this.state;
    const { qualityProgram } = this.props;
    const accessToken = this.props.users.accessToken;
    const companyId = this.props.users.user.session.company_id;
    if (!file) {
      const newQualityProgram = {
        program_quality: [
          ...this.props.qualities.filter(
            (qp) => qp.name !== this.props.currentQuality[0].name,
          ),
          {
            certificate_id: this.props.currentQuality[0].certificate_id,
            name: this.props.currentQuality[0].name,
            expirationDate: expirationDate,
            bioKontrol: typeof bioKontrol !== 'undefined' ? bioKontrol : "",
            bioVerband: typeof bioVerband !== 'undefined' ? bioVerband :"", 
          },
        ],
      };
      this.props.updateQualityProgram(
        newQualityProgram,
        companyId,
        accessToken,
      );
    } else {
      await uploadFileQualityProgram(
        companyId,
        accessToken,
        file,
        qualityProgram,
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState((prevState) => ({
            quality: [
              ...prevState.quality,
              {
                certificate_id: data.data._id,
                name: data.data.program_quality,
              },
            ],
          }));
          let newQualityProgram = {
            program_quality: [
              {
                certificate_id: data.data._id,
                name: data.data.program_quality,
                expirationDate: expirationDate,
                bioKontrol,
                bioVerband,
              },
            ],
          };
          if (this.props.currentQuality[0]) {
            newQualityProgram.program_quality.push(
              ...this.props.qualities.filter(
                (qp) => qp.name !== this.props.currentQuality[0].name,
              ),
            );
          } else {
            newQualityProgram.program_quality.push(...this.props.qualities);
          }
          this.props.updateQualityProgram(
            newQualityProgram,
            companyId,
            accessToken,
          );
        });
    }
    this.props.closeModal();
  };

  handleSelect = (role) => {
    this.setState({ role: role.value });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  formatToGermanDate = (date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear().toString().slice(-2);
    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    return [day, month, year].join('.');
  };

  getGenericDate = (str) => {
    if (str !== '' && typeof str != 'undefined' && str !== 'NaN.NaN.aN') {
      let date = str.split('.');
      date = `20${date[2]}-${date[1]}-${date[0]}`;
      return new Date(date);
    } else {
      const toDay = new Date();
      return toDay;
    }
  };

  handleBlur = (type) => {};
  handleFocus = (type) => {};

  addLeadingZero = (n) => {
    return n < 10 ? '0' + n : n;
  };

  renderDate = (timeStamp, format) => {
    const toDay = new Date();
    const date = new Date(timeStamp);
    const tomorrow = new Date(date);
    tomorrow.setDate(date.getDate() + 1);
    return date.getDate() === toDay.getDate() && format === 'input'
      ? `${this.addLeadingZero(date.getDate())}.${this.addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`
      : date.getDate() === toDay.getDate() && format === 'datepicker'
      ? date
      : date.getDate() !== toDay.getDate() && format === 'input'
      ? `${this.addLeadingZero(date.getDate())}.${this.addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`
      : date.getDate() !== toDay.getDate() && format === 'datepicker'
      ? date
      : `${this.addLeadingZero(date.getDate())}.${date.getMonth() + 1}.${date
          .getFullYear()
          .toString()
          .slice(-2)}`;
  };

  handleExpirationDate = (date) => {
    if (date instanceof Date) {
      this.setState({ expirationDate: this.formatToGermanDate(date) });
    } else {
    }
  };

  handleUpload = (event) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      this.setState({
        file: file,
        selectedFile: reader.result,
      });
    }.bind(this);
  };

  render() {
    const { selectedFile, expirationDate, bioKontrol, bioVerband } = this.state;
    let $imagePreview = null;
    $imagePreview =
      typeof selectedFile !== 'undefined' &&
      selectedFile !== '' &&
      selectedFile !== null &&
      selectedFile.includes('application/pdf') ? (
        <embed
          src={selectedFile}
          frameBorder="0"
          width="100%"
          height="100%"
          type="application/pdf"
        />
      ) : (
        <img src={selectedFile} />
      );
    return (
      <Form
        className="form-user-settings add-user edit-qp"
        onSubmit={this.handleSubmit}
      >
        <Col md={12}>
          <div className="last-item-calender buy-sell-calendar qp-expiration-date">
            <RegularInput
              picker
              label={<FormattedMessage {...messages.ExpirationDate} />}
              type="text"
              value={
                expirationDate
                  ? expirationDate
                  : this.formatToGermanDate(new Date())
              }
              name="cattleDeliveryDate"
              onFocus={() => this.handleFocus('DatePicker')}
              onBlur={() => this.handleBlur('DatePicker')}
              onChange={this.handleExpirationDate}
              onClick={() => {
                this.calendarRef.current.setOpen(true);
              }}
            />
            <div className="date-field picker">
              <SvgIcon color="#9f9f9f" size="30" viewBox="0 0 600 600">
                {svgPaths['calendar']}
              </SvgIcon>
              <DatePicker
                selected={
                  expirationDate
                    ? this.getGenericDate(expirationDate)
                    : new Date()
                }
                onChange={this.handleExpirationDate}
                dateFormat="dd.MM.yyyy"
                ref={this.calendarRef}
                locale={locale}
              />
            </div>
          </div>
        </Col>
        {this.props.qualityProgram === 'BIO' && (
          <>
            <Col md={12}>
              <RegularInput
                type="text"
                name="bioKontrol"
                defaultValue={bioKontrol}
                onChange={this.handleChange}
                required
                label="Öko-Kontrollstelle"
              />
            </Col>
            <Col md={12}>
              <RegularInput
                name="bioVerband"
                className="bio-verband"
                type="text"
                defaultValue={bioVerband}
                onChange={this.handleChange}
                required
                label="Verband"
              />
            </Col>
          </>
        )}
        <input
          type="file"
          accept="image/png, image/jpeg, application/pdf"
          name="passport"
          className="passport-image"
          onChange={(e) => this.handleUpload(e)}
        />
        {selectedFile && (
          <div className="passport-photo-edit">{$imagePreview}</div>
        )}
        <Col md={12} className="add-user-button-container">
          <NormalButton type="submit">
            <FormattedMessage {...messages.Save} />
          </NormalButton>
        </Col>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, null)(FormQualityProgram);
