import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { func, bool, string, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import messages from '../Admin/messages';
import './popup.css';

const findAndReplace = (e, language) => {
  if (language.locale === 'DE') {
    return e && e.replace('pieces', 'Stück ');
  } else return e;
};
const renderVetMessage = (messageType, item, language) => (
  <div>
    {messageType ? (
      <Modal.Body className="text-center">
        <FormattedMessage {...messages.AddVetOfficeForAgrPart1} />
        <span>{findAndReplace(item && item.name, language)}</span>
        <FormattedMessage {...messages.AddVetOfficeForAgrPart2} />
      </Modal.Body>
    ) : (
        <Modal.Body className="text-center">
          <FormattedMessage {...messages.AddResponsibleVetForAbtPart1} />
          <span>{findAndReplace(item && item.name, language)}</span>
          <FormattedMessage {...messages.AddResponsibleVetForAbtPart2} />
        </Modal.Body>
      )}
  </div>
);

const renderText = (text) => (
  <Modal.Body className="text-center">
    <FormattedMessage {...text} />
  </Modal.Body>
);

const renderQualityProgram = (imageSrc, title, handleClose) => (
  <div>
    <Modal.Header closeButton>
      <Modal.Title>
        <FormattedMessage {...title} />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-center">
      <img alt="img" src={imageSrc} />
    </Modal.Body>
  </div>
);

const Popup = ({
  show,
  closePopup,
  title,
  handleClose,
  saveChanges,
  item,
  company_type,
  text,
  vetPopup,
  imageSrc,
  language
}) => {
  const messageType = company_type === '1' ? true : false;
  return (
    <Modal show={show} onHide={handleClose} style={{ opacity: 1 }}>
      {imageSrc !== '' ? (
        renderQualityProgram(imageSrc, title, handleClose)
      ) : (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {vetPopup ? renderVetMessage(messageType, item, language) : renderText(text)}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                <FormattedMessage {...messages.No} />
              </Button>
              <Button variant="primary" onClick={(e) => saveChanges(item)}>
                <FormattedMessage {...messages.Yes} />
              </Button>
            </Modal.Footer>
          </div>
        )}
    </Modal>
  );
};

const mapStateToProps = state => {
  const { language } = state.toJS();
  return { language };
};

export default connect(mapStateToProps, null)(Popup);


Popup.propTypes = {
  show: bool,
  onHide: func,
  handleClose: func,
  saveChanges: func,
  title: PropTypes.any,
  text: object,
  item: object,
  vetPopup: bool,
  imageSrc: string,
};

Popup.defaultProps = {
  show: false,
  onHide: () => { },
  handleClose: () => { },
  saveChanges: () => { },
  title: '',
  text: {},
  item: {},
  vetPopup: false,
  imageSrc: '',
};
