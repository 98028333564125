import React from 'react';
import {
  userConstants,
  alertConstants,
  companyConstants,
  stockConstants,
} from '../constants';
import { userService } from '../services';
import { getCompany } from '../services/company.service';

import extractData from '../services/data.processing';
import { history } from '../helpers';
import messages from '../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
export const userActions = {
  login,
  getAllUsers,
  addUser,
  editUser,
  deleteUser,
  newsLetter,
  addSignature,
  logout,
  verification,
  verificationAddCompany,
  nextStepRegister,
  nextStepRegisterProcess,
  initPassword,
  resetPassword,
  changeUserSession,
  changeUserSessionToLastCompany,
  changeUserSubcription,
};

function login(username, password, subscription) {
  return async (dispatch) => {
    try {
      const response = await userService.login(
        username,
        password,
        subscription,
      );
      const user = await extractData(response);

      let token = user.token.access_token;
      let id = user.user.companies[0].company_id;
      const value = await getCompany(id, token);
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        const company = await extractData(value);

        user.user.is_producer = company.trader_extra.is_producer;
        await dispatch({ type: userConstants.LOGIN_SUCCESS, user });
        const resetStocks = [];
        await dispatch({ type: stockConstants.RESET, resetStocks });
      }
    } catch (error) {
      await dispatch({ type: userConstants.LOGIN_FAILURE, error });
      await dispatch({
        type: alertConstants.ERROR,
        message: <FormattedMessage {...messages.BadCredentiel} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function addUser(data) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      const response = await userService.add(data, accessToken);
      const user = await extractData(response);
      await dispatch({ type: userConstants.USER_ADD, user });
    } catch (error) {
      await dispatch({
        type: alertConstants.ERROR,
        message: <FormattedMessage {...messages.DefaultErrorMessage} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function editUser(data, userId) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      const response = await userService.edit(data, userId, accessToken);
      let user = await extractData(response);
      await dispatch({ type: userConstants.USER_EDIT, user });
      await dispatch({
        type: alertConstants.SUCCESS,
        message: <FormattedMessage {...messages.DefaultSuccessMessgage} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function deleteUser(userId) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    try {
      const response = await userService.remove(userId, accessToken);
      await extractData(response);
      await dispatch({ type: userConstants.USER_DELETE, userId });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function newsLetter(email) {
  return async (dispatch) => {
    try {
      const response = await userService.newsLetter(email);
      await extractData(response);
      await dispatch({
        type: alertConstants.SUCCESS,
        message: 'thank you for being subscribed to our newsletter campaign',
      });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

// eslint-disable-next-line no-unused-vars
function getSignature(signature) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    const userId = getState().toJS().users.user._id;
    try {
      const response = await userService.addSignature(userId, accessToken);
      await extractData(response);
      await dispatch({
        type: alertConstants.SUCCESS,
        message: <FormattedMessage {...messages.DefaultSuccessAddMessgage} />,
      });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function addSignature(signature) {
  return async (dispatch, getState) => {
    const accessToken = getState().toJS().users.accessToken;
    const userId = getState().toJS().users.user._id;
    try {
      const response = await userService.addSignature(
        userId,
        accessToken,
        signature,
      );
      await extractData(response);
      await dispatch({
        type: alertConstants.SUCCESS,
        message: <FormattedMessage {...messages.DefaultSuccessAddMessgage} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function getAllUsers(page = 0, limit = 20) {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().toJS().users.accessToken;
      const response = await userService.fetchUsers(page, limit, accessToken);
      const users = await extractData(response);
      await dispatch({ type: userConstants.USER_GET_ALL, users });
    } catch (error) {}
  };
}

function logout(token) {
  return async (dispatch) => {
    try {
      await userService.logout(token);
      // const user = await extractData(response);
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister();
        });
      }
    } catch (error) {
      // dispatch({ type: userConstants.LOGIN_FAILURE, error });
    }
    dispatch({ type: userConstants.LOGOUT });
  };
}

function verification(number, pin) {
  return async (dispatch) => {
    userService.verification(number, pin);
    try {
      const response = await userService.verification(number, pin);
      if (response.status === 200) {
        const company = await extractData(response);
        await dispatch({
          type: companyConstants.CHANGE_STEP,
          step: 2,
          business_type: -1,
          company,
        });
        history.push('/register/step/2');
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'HIT_BAD_CREDENTIALS';
      }
    } catch (error) {
      await dispatch({
        type: alertConstants.ERROR,
        message: error,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function verificationAddCompany(number, pin) {
  return async (dispatch) => {
    userService.verification(number, pin);
    try {
      const response = await userService.verification(number, pin);
      if (response.status === 200) {
        const company = await extractData(response);
        await dispatch({
          type: companyConstants.CHANGE_STEP,
          step: 2,
          business_type: company.business_type,
          company,
        });
        history.push('/add_company/step/2');
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'HIT_BAD_CREDENTIALS';
      }
    } catch (error) {
      await dispatch({
        type: alertConstants.ERROR,
        message: error,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function nextStepRegister(num, business_type) {
  return async (dispatch) => {
    await dispatch({
      type: companyConstants.CHANGE_STEP,
      step: num,
      business_type: business_type,
      company: {},
    });
    history.push('/register/step/3');
  };
}

function nextStepRegisterProcess(num, business_type, producer) {
  return async (dispatch) => {
    await dispatch({
      type: companyConstants.CHANGE_STEP,
      step: num,
      business_type: business_type,
      producer: producer,
      company: {},
    });
    history.push('/register/step/3');
  };
}

function initPassword(email) {
  return async (dispatch) => {
    try {
      await userService.initPassword(email);
      await dispatch({
        type: alertConstants.SUCCESS,
        message: <FormattedMessage {...messages.EmailSent} />,
      });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error.message });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
      throw error;
    }
  };
}

// eslint-disable-next-line no-unused-vars
function setPassword(password, appId) {
  return async (dispatch) => {
    try {
      const response = await userService.setPassword(password, appId);
      await extractData(response);
      await dispatch({
        type: alertConstants.SUCCESS,
        message: <FormattedMessage {...messages.DefaultSuccessSaveMessgage} />,
      });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function resetPassword(password, token) {
  return async (dispatch) => {
    try {
      const response = await userService.resetPassword(password, token);
      await extractData(response);
      await dispatch({
        type: alertConstants.SUCCESS,
        message: <FormattedMessage {...messages.DefaultSuccessMessgage} />,
      });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
      history.push('/login');
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error.message });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function changeUserSession(session, token) {
  return async (dispatch) => {
    try {
      const response = await userService.changeUserSession(session, token);
      if (response.status === 200) {
      const data = await extractData(response);
      const companies = data.user.companies;
      await dispatch({
        type: userConstants.USER_CHANGE_SESSION,
        companies: companies,
        session: session,
      });
      const resetStocks = [];
      await dispatch({ type: stockConstants.RESET, resetStocks });
    }
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function changeUserSessionToLastCompany(session, token) {
  return async (dispatch) => {
    try {
      const response = await userService.changeUserSession(session, token);
      if (response.status === 200) {
      const data = await extractData(response);
      const companies = data.user.companies;
      const { company_id, company_number, company_type } = [
        ...data.user.companies,
      ].pop();
      const lastCompanySession = {
        ...session,
        company_id: company_id,
        company_number: company_number,
        company_type: company_type,
      };
      await userService.changeUserSession(lastCompanySession, token);
      await dispatch({
        type: userConstants.USER_CHANGE_SESSION_LAST_COMPANY,
        companies: companies,
        session: lastCompanySession,
      });
      const resetStocks = [];
      await dispatch({ type: stockConstants.RESET, resetStocks });
    }
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function changeUserSubcription(subscription) {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: userConstants.USER_CHANGE_SESSION_SUB,
        subscription,
      });
    } catch (error) {
      await dispatch({ type: alertConstants.ERROR, message: error });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}
