export default function extractData(resp) {
  // try {
  if (resp === null) throw new Error('something went wrong');
  const statusCode = resp.status;
  const respBody = resp.data;
  if (statusCode >= 200 && statusCode < 300) {
    return respBody.data;
  } else {
    const extractedError = isString(respBody.data)
      ? respBody.data
      : respBody.name;
    throw new Error(extractedError || 'something went wrong');
  }
  //} catch (error) {}
}

const isString = str => typeof str === 'string' || str instanceof String;
export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
