export const todesart = [
    {
      value: '1',
      label: 'Verendung',
    },
    {
      value: '2',
      label: 'Hausschlachtung',
    },
    {
      value: '3',
      label: 'Tötung',
    },
  ];