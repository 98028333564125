import React from 'react';
import { Col, Card, Row, Modal } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import { connect } from 'react-redux';
import { alertActions } from '../../../actions';
import { MdNavigateBefore } from 'react-icons/md';
import RegularInput from '../../Shared/components/inputs/RegularInput';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';
import { qualities,   qualitiesDe,
  qualitiesEn } from '../../../constants';
import MultiSelect from '../../Shared/components/MultiSelect';
import Switch from 'react-switch';

import './form.css';
import {
  deleteContact,
  updateContact,
  editAgrabizcontact,
} from '../../../services/company.service';
import { event } from 'react-ga';
import { remove } from 'lodash';
const Form = ({
  contact,
  users,
  setShowForm,
  getContactsChild,
  success,
  language,
  isPopup,
  qpList,
  enableEdit,
  listQualities,
  addContact,
  clear,
}) => {
  const initialState = {
    companyName: '',
    companyNumber: '',
    phoneNumber: '',
    companyEmail: '',
    vetCompanyName: '',
    vetFirstName: '',
    vetLastName: '',
    vetPhoneNumber: '',
    vetZipCode: '',
    vetCity: '',
    vetStreet: '',
    city: '',
    street: '',
    zipCode: '',
    federalState: '',
    country: '',
    qualityProgarms: [],
    qps: [],
    sendchecked: false,
    mobileNumber: '',
  };
  const [contactState, setContactState] = React.useState(initialState);
  const [checked, setChecked] = React.useState(false);
  const [confirm, setconfirm] = React.useState(false);

  const {
    companyName,
    companyNumber,
    phoneNumber,
    companyEmail,
    vetCompanyName,
    vetFirstName,
    vetLastName,
    vetPhoneNumber,
    vetZipCode,
    vetCity,
    vetStreet,
    city,
    street,
    zipCode,
    federalState,
    country,
    qualityProgarms,
    qps,
    sendchecked,
    mobileNumber,
  } = contactState;
  React.useEffect(() => {
    setContactState({
      companyName: contact.name,
      companyNumber: contact.number,
      companyEmail: contact.email,
      phoneNumber:
        typeof contact.phone_number !== 'undefined' &&
        contact.phone_number !== null
          ? contact.phone_number
          : '',
      mobileNumber:
        typeof contact.mobile_number !== 'undefined' &&
        contact.mobile_number !== null
          ? contact.mobile_number
          : '',
      vetCompanyName:
        contact.agriculturist_extra &&
        contact.agriculturist_extra.veterinary_company_name,
      vetPhoneNumber:
        contact.agriculturist_extra &&
        contact.agriculturist_extra.veterinary_phone_number,

      sendchecked:
        contact.has_slaughter_informations &&
        contact.has_slaughter_informations,
      vetFirstName:
        contact.agriculturist_extra &&
        contact.agriculturist_extra.veterinary_firstname,
      vetLastName:
        contact.agriculturist_extra &&
        contact.agriculturist_extra.veterinary_lastname,
      vetStreet:
        contact.agriculturist_extra &&
        contact.agriculturist_extra.veterinary_street,
      vetZipCode:
        contact.agriculturist_extra &&
        contact.agriculturist_extra.veterinary_zip_code,
      vetCity:
        contact.agriculturist_extra &&
        contact.agriculturist_extra.veterinary_city,
      city:
        typeof contact.address !== 'undefined' &&
        typeof contact.address.city !== 'undefined' &&
        contact.address.city !== ''
          ? contact.address.city
          : '',
      street:
        typeof contact.address !== 'undefined' &&
        typeof contact.address.street !== 'undefined' &&
        contact.address.street !== ''
          ? contact.address.street
          : '',
      zipCode:
        typeof contact.address !== 'undefined' &&
        typeof contact.address.zip_code !== 'undefined' &&
        contact.address.zip_code !== ''
          ? contact.address.zip_code
          : '',
      federalState:
        typeof contact.address !== 'undefined' &&
        typeof contact.address.federal_state !== 'undefined' &&
        contact.address.federal_state !== ''
          ? contact.address.federal_state
          : '',
      country:
        typeof contact.address !== 'undefined' &&
        typeof contact.address.country !== 'undefined' &&
        contact.address.country !== ''
          ? contact.address.country
          : '',
      qps:
        typeof contact.program_quality !== 'undefined' &&
        contact.program_quality.map((qp) => ({
          value: qp.name,
          label: qp.name,
        })),
    });
    setChecked(!!contact.has_slaughter_informations ? true : false);
  }, [contact]);

  const submit = async (e) => {
    e.preventDefault();
    addContact();
  };
  const handleCloseConfirm = async () => {
    setconfirm(false);
  };

  const handleOpenConfirm = async () => {
    setconfirm(true);
  };

  const matchQualityProgram = (quality) => {
    switch (quality) {
      case '5':
        return [
          {
            value: 'QM',
            label: 'QM',
          },
          {
            value: 'QS',
            label: 'QS',
          },
        ];

      case '7':
        return [
          {
            value: 'BIO',
            label: 'BIO',
          },
        ];
      case '8':
        return [
          {
            value: 'BIO Naturland',
            label: 'BIO Naturland',
          },
        ];
      case '9':
        return [
          {
            value: 'QS',
            label: 'QS',
          },
        ];
      case '14':
        return [
          {
            value: 'GQ',
            label: 'GQ',
          },
          {
            value: 'QS',
            label: 'QS',
          },
        ];
      case 'Best Beef':
        return [
          {
            value: 'Best Beef',
            label: 'Best Beef',
          },
        ];
      case 'GQ':
        return [
          {
            value: 'GQ',
            label: 'GQ',
          },
        ];
      case 'BIO':
        return [
          {
            value: 'BIO',
            label: 'BIO',
          },
        ];
      case 'GVO':
        return [
          {
            value: 'GVO',
            label: 'GVO',
          },
        ];
      case 'QM':
        return [
          {
            value: 'QM',
            label: 'QM',
          },
        ];
      case 'QS':
        return [
          {
            value: 'QS',
            label: 'QS',
          },
        ];
      default:
        return [];
    }
  };

  const matchQualities = (qps) => {
    let lettersQualities = [];
    qps.map((qp) => {
      lettersQualities = [
        ...lettersQualities,
        ...matchQualityProgram(qp.value),
      ];
    });

    return lettersQualities;
  };

  const removeContact = async () => {
    let token = users.accessToken;
    let company_id = users.user.session.company_id;
    let contact_id = contact._id;
    let contact_name = contact.name;
    await deleteContact(company_id, token, contact_id);
    setShowForm();
    getContactsChild();
    success(
      language === 'EN'
        ? `${contact_name}  successfully updated`
        : `${contact_name}  erfolgreich aktualisiert`,
    );
    clear();
  };

  const editContact = async () => {
    const token = users.accessToken;
    const companyId = users.user.session.company_id;
    const contactId = contact._id;
    const contactName = contact.name;
    let QqualityProgarmList = qualityProgarms;

    const contactData = {
      name: companyName,
      number: companyNumber,
      phone_number: phoneNumber,
      mobile_number: mobileNumber,
      has_slaughter_informations: !!checked ? 1 : 0,
      email: companyEmail,
      agriculturist_extra: {
        veterinary_company_name: vetCompanyName,
        veterinary_firstname: vetFirstName,
        veterinary_lastname: vetLastName,
        veterinary_phone_number: vetPhoneNumber,
        veterinary_zip_code: vetZipCode,
        veterinary_city: vetCity,
        veterinary_street: vetStreet,
      },
      program_quality: qualityProgarms,
      city,
      street,
      zip_code: zipCode,
      federal_state: federalState,
      country,
    };
    const comp = await updateContact(companyId, contactId, contactData, token);
    if (
      comp !== 'null' &&
      typeof comp !== 'undefined' &&
      typeof comp.status !== 'undefined' &&
      comp.status === 200
    ) {
      setShowForm();
      getContactsChild();
      success(
        language === 'EN'
          ? `${contactName}  successfully updated`
          : `${contactName}  erfolgreich aktualisiert`,
      );
      clear();
    } else {
      const msg =
        language.locale === 'EN'
          ? `Contact was not successfully updated  `
          : `Kontakt wurde nicht erfolgreich aktualisiert `;
      success(msg);
      clear();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactState({ ...contactState, [name]: value });
  };

  const handleChangeBox = (nextChecked) => {
    setChecked(nextChecked);
    setContactState({ ...contactState, [sendchecked]: nextChecked });
  };

  const handleChangeBoxAgr = (nextChecked) => {
    setChecked(nextChecked);
    setContactState({ ...contactState, [sendchecked]: nextChecked });
    editContactAgr(nextChecked);
  };

  const editContactAgr = async (nextChecked) => {
    const token = users.accessToken;
    const companyId = users.user.session.company_id;
    const contactId = contact._id;
    const contactName = contact.name;

    const contactData = {
      has_slaughter_informations: !!nextChecked ? 1 : 0,
    };
    const comp = await editAgrabizcontact(contactId, contactData, token);
    if (
      comp !== 'null' &&
      typeof comp !== 'undefined' &&
      typeof comp.status !== 'undefined' &&
      comp.status === 200
    ) {
      setShowForm();
      getContactsChild();
      success(
        language === 'EN'
          ? `${contactName}  successfully updated`
          : `${contactName}  erfolgreich aktualisiert`,
      );
      clear();
    } else {
      const msg =
        language.locale === 'EN'
          ? `Contact was not successfully updated  `
          : `Kontakt wurde nicht erfolgreich aktualisiert `;
      success(msg);
      clear();
    }
  };

  const handleChangeProgrameQuality = async (qp) => {
    let qpTest = qp;
    let findQP = qpTest.find((e) => e.value === 'Noqualityprogram');
    let findIndexQP = qpTest.findIndex((e) => e.value === 'Noqualityprogram');
    let FinalQP = [];

    if (typeof findQP !== 'undefined' && findIndexQP > 0) {
      FinalQP = qpTest.filter((e) => e.value === 'Noqualityprogram');
    } else if (typeof findQP !== 'undefined' && findIndexQP <= 0) {
      if (qpTest.length > 1) {
        FinalQP = qpTest.filter((e) => e.value !== 'Noqualityprogram');
      } else {
        FinalQP = qpTest;
      }
    } else {
      FinalQP = qpTest;
    }

    FinalQP = FinalQP.filter((e) => e.label !== 'No quality program');
    FinalQP = FinalQP.filter((e) => e.label !== 'Keine');

    qp = FinalQP;
    setContactState({
      ...contactState,
      qps: qp || [],
      qualityProgarms: qp
        ? qp.map((qp) => ({ certificate_id: '', name: qp.value }))
        : [],
    });
  };

  return (
    <Card.Body className="contact-panel-body">
      <Modal show={confirm} onHide={handleCloseConfirm} style={{ opacity: 1 }}>
        <Modal.Body>
          <h4 style={{ textAlign: 'center' }}>
            <FormattedMessage {...messages.DeleteValidation} />
            {/* {this.state.selectedUser.name} */}
          </h4>
        </Modal.Body>

        <Modal.Footer style={{ textAlign: 'center' }}>
          <div className="view-producer">
            <NormalButton
              type="submit"
              style={{ width: '40%', marginRight: '10px' }}
              onClick={() => {
                removeContact();
                handleCloseConfirm();
              }}
            >
              <div className="sizetextbtn">
                {' '}
                <FormattedMessage {...messages.ConfirmYes} />
              </div>
            </NormalButton>

            <NormalButton
              grey
              type="submit"
              className=""
              style={{ width: '40%' }}
              onClick={() => {
                //handleSubmitsave();
                handleCloseConfirm();
              }}
            >
              <div className="sizetextbtn">
                <FormattedMessage {...messages.ConfirmBack} />
              </div>
            </NormalButton>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="navigate-before">
        <div md={3}>
          <MdNavigateBefore className="before" onClick={setShowForm} />
        </div>
      </div>
      <Row />
      {isPopup ? (
        <div className="contact-information-tab-profile"></div>
      ) : (
        <div className="contact-information-tab">
          <h5 className="cardTitle">
            <FormattedMessage {...messages.ContactInformation} />
          </h5>
        </div>
      )}
      <form
        className="form-contact-information"
        onSubmit={submit}
        ref={(form) => (form = form)}
      >
        <Row className="form-contact-information-fields">
          <div className="contact-info-forms">
            <Col md={12}>
              <RegularInput
                type="text"
                name="companyName"
                onChange={(e) => handleChange(e)}
                disabled={contact.is_registered === 1}
                value={companyName}
                label={<FormattedMessage {...messages.company} />}
              />
            </Col>
            {contact.is_registered === 0 && (
              <Col md={12}>
                <RegularInput
                  type="text"
                  name="companyEmail"
                  onChange={(e) => handleChange(e)}
                  disabled={contact.is_registered === 1}
                  value={companyEmail}
                  label={<FormattedMessage {...messages.email} />}
                />
              </Col>
            )}

            <Col md={12}>
              <RegularInput
                type="text"
                name="street"
                onChange={(e) => handleChange(e)}
                disabled={contact.is_registered === 1}
                required
                value={street}
                label={<FormattedMessage {...messages.streetNumber} />}
              />
            </Col>
            <Col md={12}>
              <RegularInput
                type="text"
                name="zipCode"
                onChange={(e) => handleChange(e)}
                required
                disabled={contact.is_registered === 1}
                value={zipCode}
                label={<FormattedMessage {...messages.zipCode} />}
              />
            </Col>
            <Col md={12}>
              <RegularInput
                type="text"
                name="city"
                onChange={(e) => handleChange(e)}
                required
                disabled={contact.is_registered === 1}
                value={city}
                label={<FormattedMessage {...messages.city} />}
              />
            </Col>
            <Col md={12}>
              <RegularInput
                type="text"
                name="federalState"
                onChange={(e) => handleChange(e)}
                value={federalState}
                required
                disabled={contact.is_registered === 1}
                label={<FormattedMessage {...messages.federalState} />}
              />
            </Col>
            <Col md={12}>
              <RegularInput
                type="text"
                name="country"
                onChange={(e) => handleChange(e)}
                value={country}
                required
                disabled={contact.is_registered === 1}
                label={<FormattedMessage {...messages.country} />}
              />
            </Col>

            {contact.is_registered === 0 && (
              <>
                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="phoneNumber"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={phoneNumber}
                    label={<FormattedMessage {...messages.phoneNumber} />}
                  />
                  {phoneNumber != null &&
                    typeof phoneNumber !== 'undefined' &&
                    phoneNumber.length > 0 &&
                    !phoneNumber.match(
                      /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+\/\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
                    ) && (
                      <p className="error-messagephonr">
                        Phone number is not valid
                      </p>
                    )}
                </Col>
                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="mobileNumber"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={mobileNumber}
                    label={<FormattedMessage {...messages.mobileNumber} />}
                  />
                  {mobileNumber !== null &&
                    typeof mobileNumber !== 'undefined' &&
                    mobileNumber.length > 0 &&
                    !mobileNumber.match(
                      /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+\/\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
                    ) && (
                      <p className="error-messagephonr">
                        Mobile number is not valid
                      </p>
                    )}
                </Col>
                <Col md={12}>
                  <MultiSelect
                    filled
                    label={<FormattedMessage {...messages.QualityProgram} />}
                    isMulti={true}
                    value={matchQualities(qps)}
                    className="selected"
                    closeMenuOnSelect={false}
                    options={language.locale === 'DE' ? qualitiesDe : qualitiesEn}
                    onChange={handleChangeProgrameQuality}
                    isSearchable={false}
                    isClearable={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#F1FFDE',
                        primary: '#7bcd10',
                      },
                    })}
                  />
                </Col>
              </>
            )}

            {contact.is_registered === 0 && contact.group === '1' && (
              <>
                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="vetCompanyName"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={vetCompanyName}
                    label={<FormattedMessage {...messages.vetCompanyName} />}
                  />
                </Col>

                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="vetFirstName"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={vetFirstName}
                    label={<FormattedMessage {...messages.vetFirstName} />}
                  />
                </Col>
                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="vetLastName"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={vetLastName}
                    label={<FormattedMessage {...messages.vetLastName} />}
                  />
                </Col>

                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="vetStreet"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={vetStreet}
                    label={<FormattedMessage {...messages.vetstreet} />}
                  />
                </Col>
                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="vetZipCode"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={vetZipCode}
                    label={<FormattedMessage {...messages.vetZipCode} />}
                  />
                </Col>
                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="vetCity"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={vetCity}
                    label={<FormattedMessage {...messages.vetCity} />}
                  />
                </Col>
                <Col md={12}>
                  <RegularInput
                    type="text"
                    name="vetPhoneNumber"
                    onChange={(e) => handleChange(e)}
                    disabled={contact.is_registered === 1}
                    value={vetPhoneNumber}
                    label={<FormattedMessage {...messages.vetPhoneNumber} />}
                    style={{ marginBottom: '1px' }}
                  />
                </Col>
              </>
            )}
            {contact.group === '1' && contact.is_registered === 0 && (
              <div className="new-cards-owner-innernew hit-use-switch">
                <div md={4} className=" new-cards-owner-innernew-b1-switch">
                  <FormattedMessage {...messages.Sendslaughterdata} />
                </div>
                <div
                  md={8}
                  id="settings-inner-card"
                  className="new-cards-owner-innernew-b2-switch"
                >
                  <Switch
                    onColor="#7bd812"
                    onChange={handleChangeBox}
                    checked={checked}
                    className="react-switch"
                    id="normal-switch-two"
                  />
                </div>
              </div>
            )}
            {contact.group === '1' && contact.is_registered === 1 && (
              <div className="new-cards-owner-innernew hit-use-switch">
                <div md={4} className=" new-cards-owner-innernew-b1-switch">
                  <FormattedMessage {...messages.Sendslaughterdata} />
                </div>
                <div
                  md={8}
                  id="settings-inner-card"
                  className="new-cards-owner-innernew-b2-switch"
                >
                  <Switch
                    onColor="#7bd812"
                    onChange={handleChangeBoxAgr}
                    checked={checked}
                    className="react-switch"
                    id="normal-switch-two"
                  />
                </div>
              </div>
            )}
            {contact.is_registered !== 0 && (
              <Col md={12} className="qp-container">
                {users.user.session.company_type !== '5' &&
                  qpList.length > 0 &&
                  listQualities}
              </Col>
            )}
          </div>

          <div className="contact-form-buttons">
            <div className="contact-buttons">
              {contact.is_registered === 0 && (
                <div className="contact-delete-submit-container">
                  <NormalButton
                    disabled={
                      (phoneNumber !== null &&
                        phoneNumber.length > 0 &&
                        !phoneNumber.match(
                          /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+\/\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
                        )) ||
                      (mobileNumber.length > 0 &&
                        !mobileNumber.match(
                          /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+\/\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
                        ))
                    }
                    type="submit"
                    onClick={editContact}
                  >
                    <FormattedMessage {...messages.Save} />
                  </NormalButton>
                </div>
              )}
              <div className="contact-delete-submit-container">
                {!isPopup && (
                  <NormalButton grey type="submit" onClick={handleOpenConfirm}>
                    <FormattedMessage {...messages.Delete} />
                  </NormalButton>
                )}
              </div>
            </div>
            <div className="contact-delete-submit-container">
              {enableEdit && isPopup && (
                <NormalButton disabled={!enableEdit} type="submit">
                  <FormattedMessage {...messages.AddContact} />
                </NormalButton>
              )}

              {/* <Button
                disabled={!this.props.enableEdit}
                className="form-btn btn btn-default contactAdd"
                type="submit"
              >
                {isPopup ? (
                  <FormattedMessage {...messages.AddContact} />
                ) : (
                  <FormattedMessage {...messages.EditMessage} />
                )}
              </Button> */}
            </div>
          </div>
        </Row>
      </form>
    </Card.Body>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
