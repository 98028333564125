import React, { Component } from 'react';
import {
  Col,
  Row,
  ListGroupItem,
  Image,
  ButtonGroup,
  Button,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdNavigateNext } from 'react-icons/md';
import { upperCaseToNomal } from '../../../../helpers/order';
import { statusOrders } from '../status-orders';

import messages from '../../messages';
import './bidders.css';

class Bidders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'all',
      receiversTab: [],
      orderId: this.props.item.order_id,
    };
  }

  componentDidMount() {
    this.setState({
      receiversTab: this.props.receiversTab,
      orderId: this.props.item.order_id,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.receiversTab !== this.props.receiversTab ||
      nextProps.item !== this.props.item
    ) {
      this.setState({
        receiversTab: nextProps.receiversTab,
        orderId: nextProps.item.order_id,
      });
    }
    if (nextProps.item !== this.props.item) {
      this.setState({
        type: 'all',
      });
    }
  }

  handleChange = async (e) => {
    e.preventDefault();
    await this.setState({ type: e.target.value });
    await this.props.getReceivers(this.state.orderId, this.state.type);
  };

  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  renderListOrders = (getSrcImg) => {
    return this.state.receiversTab.map((item, i) => (
      <ListGroupItem key={i} className="list-group">
        <Row onClick={(evt) => this.props.showOrderDetail(item)}>
          <Col md={3} className="status">
            <Image
              className="status-image"
              src={getSrcImg(
                item.status,
                item.receivers_size,
                item.topic,
                item.is_buyer,
              )}
            />
            <p className="status-name">
              {
                statusOrders.find(
                  (val) => item.status.split('_').join(' ') === val.statusOrder,
                ).label
              }
            </p>
          </Col>
          <Col md={7}>
            <p className="green"> {this.findAndReplace(item.name)}</p>
            <p className="info-item">
              {item.delivery_date} | {item.business_partner}
            </p>
          </Col>
          <Col md={2} className="MdNavigateNext">
            <p className="shared">
              <MdNavigateNext />
            </p>
          </Col>
        </Row>
        <hr className="style-border" />
      </ListGroupItem>
    ));
  };

  render() {
    const { getSrcImg, item } = this.props;
    return (
      <Row className="orders-for-bidders">
        <Col id="to-be-loaded-trucks" className="order-bidders">
          <h5 className="card-title">
            <FormattedMessage {...messages.HomeBidders} />
          </h5>
          <Row className="bidders-description-groupe">
            <Col md={6} className="description">
              <p className="description-info">
                <FormattedMessage {...messages.my_stock_sale} />
              </p>
              <p> {this.findAndReplace(item.name)}</p>
            </Col>
            <Col className="description" md={6}>
              <p className="description-info">
                <FormattedMessage {...messages.datum} />
              </p>
              <p>{item.delivery_date}</p>
            </Col>
          </Row>
          <ButtonGroup value={this.state.type} onClick={this.handleChange}>
            <Button
              className={this.state.type === 'all' ? 'active' : ''}
              value={'all'}
            >
              {this.props.language.locale === 'DE' ? 'Alle' : 'All'}
            </Button>
            <Button
              className={this.state.type === 'active' ? 'active' : ''}
              value={'active'}
            >
              {this.props.language.locale === 'DE' ? 'Offen' : 'Active'}
            </Button>
            <Button
              className={this.state.type === 'denials' ? 'active' : ''}
              value={'denials'}
            >
              {this.props.language.locale === 'DE' ? 'Abgelehnt' : 'Denials'}
            </Button>
          </ButtonGroup>
          <React.Fragment>
            <InfiniteScroll
              dataLength={this.state.receiversTab.length}
              hasMore={true}
              loader={''}
            >
              <div style={{ overflowY: 'scroll', height: '100%' }}>
                {this.renderListOrders(getSrcImg)}
              </div>
            </InfiniteScroll>
          </React.Fragment>
        </Col>
      </Row>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  const { language } = state.toJS();
  return { language };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bidders);
