import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import LocaleToggle from '../../../containers/LocaleToggle';
import { userActions } from '../../../actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../../components/CustomSelect';
import messages from '../../../components/Admin/messages';
import { specificRoles } from '../../../constants';
import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';
import RegularInput from '../../Shared/components/inputs/RegularInput';
import { svgPaths } from '../../../constants';
import './header.css';

const customStyles = {
  singleValue: (provided, state) => {
    return { ...provided, color: '#7bd812' };
  },
  menu: provided => ({
    ...provided,
    color: '#7bd812',
    width: '190px',
  }),
  control: base => ({
    ...base,
    boxShadow: '',
    width: '190px',
    borderColor: 'rgba(209, 209, 209, 0.97)',
    '&:hover': { borderColor: '#7bd812' },
  }),
};

const Header = ({
  users,
  logout,
  panelTitle,
  changeUserSession,
  verificationAddCompany,
  language,
}) => {
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [companyNumber, setCompanyNumber] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [companySelectedSession, setCompanySelectedSession] = useState(
    users.user.session,
  );
  const history = useHistory();
  const handleChange = async session => {
    /* Add company */
    const access_token = users.token.access_token;
    if (session.value === 'Add') {
      handlePopUpAddCompany();
    } else {
      /* Change  company */
      await changeUserSession(session.value, access_token);
      history.push(`/login`);
    }
  };

  useEffect(() => {
    setCompanySelectedSession(users.user.session);
  }, [users.user.session]);

  const handleSubmit = () => {
    if (companyNumber !== '' && passwordText !== '') {
      verificationAddCompany(companyNumber, passwordText);
    }
  };

  const getCompanies = () => {
    let { role } = users.user;
    let companies = users.user.companies;
    const selectedCompanyDeviceId = users.device._id;
    const selectedCompanyUserId = users.user._id;
    let options = [];
    companies.forEach(function(val, index) {
      options.push({
        value: {
          ...val,
          device_id: selectedCompanyDeviceId,
          user_id: selectedCompanyUserId,
        },
        label: val.company_number,
      });
    });
    options.push({
      value: 'Add',
      label: (
        <Button
          variant="success"
          size="large"
          block
          className={
            specificRoles.ROLE_FOR_SETTINGS.indexOf(role) > -1 ? '' : 'hidden'
          }
          onClick={() => handlePopUpAddCompany()}
        >
          <FormattedMessage {...messages.AddCompany} />
        </Button>
      ),
    });
    return {
      options: options,
    };
  };

  const handelTitle = panelTitle => {
    if (language.locale === 'DE') {
      if (panelTitle === 'Add new Stock') return 'Bestand hinzufügen';
      if (panelTitle === 'Setting') return 'Einstellungen';
      if (panelTitle === 'My contacts') return 'Meine Kontakte';
      if (panelTitle === 'Message archive') return 'Nachrichtenarchiv';
      if (panelTitle === 'Invoicing') return 'Rechnungen';
      if (panelTitle === 'View & Sort Stock') return 'Bestand verwalten';
      if (panelTitle === 'My Buyings') return 'Meine Käufe';
      if (panelTitle === 'My Sellings') return 'Meine Verkäufe';
      if (panelTitle === 'Drug Administration') return 'Arzneimittel verwalten';
      if (panelTitle === 'Reports & Search') return 'Auswertungen & Suche';
      if (panelTitle === 'Delivery & Buyings') return 'Anlieferungen & Käufe';
      if (panelTitle === 'Manage Tours') return 'Touren verwalten';
      if (panelTitle === 'Search for LOM') return 'Auswertungen & Suche';
      if (panelTitle === 'Search for ear tag') return 'Suchen nach Ohrmarke';
      if (panelTitle === 'Slaughter report') return 'Schlachtdaten hochladen';
      if (panelTitle === 'Buyings overview') return 'Einkaufsübersicht';
      if (panelTitle === 'Cattle planning') return 'Viehanmeldung';
      else {
        return panelTitle;
      }
    }
    return panelTitle;
  };

  const handleChangeForm = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'company_Number':
        setCompanyNumber(value);
        break;
      case 'passwordtext':
        setPasswordText(value);
        break;
      default:
        break;
    }
  };

  const handlePopUpAddCompany = () => {
    setShowAddCompany(true);
  };

  const togglePopupAddCompany = () => {
    setShowAddCompany(false);
  };

  const renderAddCompany = () => (
    <Modal
      className="AddCompanyNbr"
      show={showAddCompany}
      onHide={togglePopupAddCompany}
      style={{ opacity: 1 }}
    >
      <Modal.Header style={{ height: '15%' }} closeButton>
        <div className="Modal-add-truck-title">
          <h5 className="card-title new-transport">
          <FormattedMessage {...messages.register_new_company} />
          </h5>
        </div>
        <Modal.Title />
      </Modal.Header>
      <Modal.Body className="">
        <div className="view-date-one">
          <RegularInput
           label={<FormattedMessage {...messages.companyNumber} />}
            type="number"
            name="company_Number"
            style={{ width: '100%' }}
            required
            onChange={e => handleChangeForm(e)}
          />
          <RegularInput
            label={<FormattedMessage {...messages.register_password} />}
            type="password"
            name="passwordtext"
            autoComplete="new-password"
            style={{ width: '100%' }}
            required
            onChange={e => handleChangeForm(e)}
          />
          <div className="button-txt">
            <Button className="btn-filter" onClick={handleSubmit}>
              <FormattedMessage {...messages.registerStepThree} />
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  const renderCompanySelect = selectedSession => {
    const currentSession = {
      value: selectedSession,
      label: selectedSession.company_number,
    };
    return (
      <CustomSelect
        className="selectCompany"
        options={getCompanies().options}
        value={currentSession}
        defaultValue={currentSession}
        onChange={handleChange}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          borderRadius: 3,
          colors: {
            ...theme.colors,
            primary25: '#F1FFDE',
            primary: '#7bcd10',
          },
        })}
      />
    );
  };
  let name = users.user.name;
  return (
    <>
      <div className="navbar-container">
        <div className="navbar-left-section">
          {panelTitle === 'Home' ? (
            <div>
              <FormattedMessage {...messages.Hi} /> <span> {name} </span>
            </div>
          ) : (
            handelTitle(panelTitle)
          )}
        </div>
        <div className="navbar-right-section">
          <div className="navbar-item">
            {renderCompanySelect(companySelectedSession)}
          </div>
          <div className="navbar-item">
            <LocaleToggle />
          </div>
          <div
            className="logout-btn"
            onClick={e => logout(users.token.access_token)}
          >
            <div className="icon">
              <SvgIcon width="40px" height="30px" viewBox="0 0 430 430">
                {svgPaths['logout']}
              </SvgIcon>
            </div>
          </div>
        </div>
      </div>
      {showAddCompany && renderAddCompany()}
    </>
  );
};

Header.propTypes = {
  users: PropTypes.object,
  logout: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    logout: token => dispatch(userActions.logout(token)),
    changeUserSession: (session, token) =>
      dispatch(userActions.changeUserSession(session, token)),
    verificationAddCompany: (number, pin) =>
      dispatch(userActions.verificationAddCompany(number, pin)),
  };
}

const mapStateToProps = state => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
