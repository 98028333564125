/*
 *
 * LanguageToggle
 *
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ToggleLinkText from '../../components/ToggleLinkText';
import { appLocales } from '../../i18n';
import { changeLocale } from '../LanguageProvider/actions';
import germanyFlag from '../../static/images/language/germany-flag.svg';
import ukFlag from '../../static/images/language/uk-flag.svg';
import messages from './messages';

export class LocaleToggleLink extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <>
        <ToggleLinkText
          value={this.props.language.locale}
          values={appLocales}
          messages={messages}
          onToggle={this.props.onLocaleToggle}
        />
        {this.props.active &&
          (this.props.language.locale === 'DE' ? (
            <img alt="" src={germanyFlag} />
          ) : (
            <img alt="" src={ukFlag} />
          ))}
      </>
    );
  }
}

LocaleToggleLink.propTypes = {
  onLocaleToggle: PropTypes.func,
  locale: PropTypes.string,
};

const mapStateToProps = state => {
  const { language } = state.toJS();
  return { language };
};

export function mapDispatchToProps(dispatch) {
  return {
    onLocaleToggle: evt => dispatch(changeLocale(evt.target.id)),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleToggleLink);
