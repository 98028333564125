import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { states } from '../../constants';
import {
  newRegisterCompany,
  addRegisterCompany,
} from '../../actions/company.actions';
import { userService } from '../../services';
import extractData from '../../services/data.processing';
import { ToastContainer, toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import messages from '../Admin/messages';
import RegularInput from '../Shared/components/inputs/RegularInput';
import NormalButton from '../Shared/components/buttons/NormalButton/NormalButton';
import pdfIcon from '../../static/images/admin/pdf.svg';
import { history } from '../../helpers';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const UserFormCompany = ({ company, newRegisterCompany, language }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupTwo, setShowPopupTwo] = useState(false);
  const [pdfConditionsId, setPdfConditionsId] = useState('');
  const [pdfPolicyId, setPdfPolicyId] = useState('');
  const [acceptPolices, setAcceptPolices] = useState(false);
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [fedState, setFedState] = useState('');
  const form = useRef();

  const handleAcceptPolices = (e) => {
    setAcceptPolices(e.target.checked);
  };

  const handleAcceptCondition = (e) => {
    setAcceptCondition(e.target.checked);
  };

  const ConfirmAcceptPolices = (e) => {
    setAcceptPolices(true);
    handleClose();
  };

  const ConfirmAcceptCondition = (e) => {
    setAcceptCondition(true);
    handleCloseTwo();
  };

  const getPolicyPdf = async (fileId) => {
    let img = await userService.getPolicy();
    setPdfPolicyId('data:application/pdf;base64,' + img);
  };

  const getDataProtectionPdf = async (fileId) => {
    let img = await userService.getDataProtection();
    setPdfConditionsId('data:application/pdf;base64,' + img);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const openPopUpTwo = () => {
    setShowPopupTwo(true);
  };

  const handleCloseTwo = () => {
    setShowPopupTwo(false);
  };

  const openPopUp = () => {
    setShowPopup(true);
  };

  const handleState = (event) => {
    const { value } = event;
    setFedState(value);
  };

  const { height, width } = useWindowDimensions();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const value = await userService.verifEmailUser(
      form.elements['email'].value,
    );
    const resp = await extractData(value);
    if (resp === 0) {
      const owner = {
        firstname: form.elements['firstName'].value,
        surname: form.elements['lastName'].value,
        email: form.elements['email'].value,
        company_password: '',
      };

      let address = {
        city: form.elements['city'].value,
        street: form.elements['streetNumber'].value,
        zip_code: form.elements['zipCode'].value,
        federal_state: fedState,
        country: 'Deutschland',
      };
      let companyToBeRegistered = {
        ...company,
        owner,
        address,
        users: [],
        device_id: '',
        business_type: company.business_type.toString(),
        name: form.elements['name'].value,
        phone_number: form.elements['phone'].value,
        fax_number: form.elements['faxNumber'].value,
      };
      companyToBeRegistered.trader_extra.is_producer = company.producer ? 1 : 0;
      companyToBeRegistered.producer = company.producer;
      delete companyToBeRegistered.step_register;
      delete companyToBeRegistered.registering;
      delete companyToBeRegistered.user;
      delete companyToBeRegistered.producer;
      delete companyToBeRegistered.token;
      delete companyToBeRegistered.client;
      delete companyToBeRegistered.accessToken;
      delete companyToBeRegistered._persist;
      delete companyToBeRegistered.locale;
      delete companyToBeRegistered.isConnected;
      delete companyToBeRegistered.device;
      delete companyToBeRegistered.plan;
      delete companyToBeRegistered.company;
      delete companyToBeRegistered.buyings;
      delete companyToBeRegistered.allstock;
      delete companyToBeRegistered.sellings;
      await newRegisterCompany(companyToBeRegistered);
      height >= 800 && width >= 1024
        ? history.push('/admin/home')
        : history.push('/register/success');
    } else {
      notify(<FormattedMessage {...messages.RegisterStepThreeEmailExist} />);
    }
  };

  const notify = (message) => toast.error(message);

  return (
    <form onSubmit={handleSubmit} ref={form} className="register-step-three">
      <ToastContainer />
      <Row className="top-part">
        <Col sm={6}>
          <RegularInput
            label={<FormattedMessage {...messages.companyNumber} />}
            type="text"
            disabled={true}
            defaultValue={company.number}
            placeholder="Company Number"
          />

          <RegularInput
            label={<FormattedMessage {...messages.company} />}
            name="name"
            required
            defaultValue={company.name}
          />

          <RegularInput
            label={<FormattedMessage {...messages.firstName} />}
            name="firstName"
            required
            defaultValue={company.owner.firstname}
          />
          <RegularInput
            label={<FormattedMessage {...messages.lastName} />}
            name="lastName"
            required
            defaultValue={company.owner.surname}
          />

          <RegularInput
            label={<FormattedMessage {...messages.phoneNumber} />}
            name="phone"
            type="number"
            defaultValue={company.owner.phone_number}
          />

          <RegularInput
            label={<FormattedMessage {...messages.faxNumber} />}
            name="faxNumber"
            type="number"
            defaultValue={company.fax_number}
          />
          <Col className="policy-container-one">
            <div className="role-txtcke first">
              <input
                className=" "
                type="checkbox"
                required
                checked={acceptPolices}
                onChange={handleAcceptPolices}
              />
              <span>
                <FormattedMessage {...messages.accept_policies_and_usage} />
              </span>
              <span>&nbsp;</span>
              <span
                className="textColor"
                onClick={() => {
                  openPopUp();
                  getPolicyPdf();
                }}
              >
                <FormattedMessage {...messages.usage_policies} />
              </span>
            </div>
            <div className="role-txtcke second">
              <input
                className=" "
                type="checkbox"
                required
                checked={acceptCondition}
                onChange={handleAcceptCondition}
              />
              <span>
                <FormattedMessage {...messages.accept_policies_and_usage} />
              </span>
              <span>&nbsp;</span>
              <span
                className="textColor"
                onClick={() => {
                  openPopUpTwo();
                  getDataProtectionPdf();
                }}
              >
                <FormattedMessage {...messages.conditions_of_data_usage} />
              </span>
            </div>
          </Col>
        </Col>

        <Col sm={6}>
          <RegularInput
            label={<FormattedMessage {...messages.email} />}
            name="email"
            type="email"
            required
            defaultValue={company.owner.email}
          />
          <RegularInput
            label={<FormattedMessage {...messages.streetNumber} />}
            name="streetNumber"
            required
            defaultValue={
              typeof company.address !== 'undefined'
                ? company.address.street
                : ''
            }
          />
          <RegularInput
            label={<FormattedMessage {...messages.zipCode} />}
            name="zipCode"
            required
            defaultValue={
              typeof company.address !== 'undefined'
                ? company.address.zip_code
                : ''
            }
          />
          <RegularInput
            label={<FormattedMessage {...messages.city} />}
            name="city"
            required
            defaultValue={
              typeof company.address !== 'undefined' ? company.address.city : ''
            }
          />
          <RegularInput
            label={<FormattedMessage {...messages.federalState} />}
            select
            defaultValue={
              language.locale === 'DE'
                ? {
                    value: 'Bundesland auswählen',
                    label: 'Bundesland auswählen',
                  }
                : { value: 'Select State', label: 'Select State' }
            }
            className="selected"
            name="state"
            required
            requiredField={true}
            selectInputValue={fedState}
            onChange={handleState}
            options={states}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F1FFDE',
                primary: '#7bcd10',
              },
            })}
          />
          <RegularInput
            label={<FormattedMessage {...messages.country} />}
            select
            name="country"
            defaultValue={{ value: 'Deutschland', label: 'Deutschland' }}
            className={'selected'}
            isDisabled={true}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: '#F1FFDE',
                primary: '#7bcd10',
              },
            })}
          />

          <Col className="policy-container-two">
            <div className="role-txtcke first">
              <input
                className=" "
                type="checkbox"
                required
                checked={acceptPolices}
                onChange={handleAcceptPolices}
              />
              <span>
                <FormattedMessage {...messages.accept_policies_and_usage} />
              </span>
              <span>&nbsp;</span>
              <span
                className="textColor"
                onClick={() => {
                  openPopUp();
                  getPolicyPdf();
                }}
              >
                <FormattedMessage {...messages.usage_policies} />
              </span>
            </div>
            <div className="role-txtcke second">
              <input
                className=" "
                type="checkbox"
                required
                checked={acceptCondition}
                onChange={handleAcceptCondition}
              />
              <span>
                <FormattedMessage {...messages.accept_policies_and_usage} />
              </span>
              <span>&nbsp;</span>
              <span
                className="textColor"
                onClick={() => {
                  openPopUpTwo();
                  getDataProtectionPdf();
                }}
              >
                <FormattedMessage {...messages.conditions_of_data_usage} />
              </span>
            </div>
          </Col>
        </Col>
      </Row>
      <Col sm={12} className="bottom-part">
        <NormalButton type="submit">
          <FormattedMessage {...messages.registerStepThree} />
        </NormalButton>
      </Col>
      <Modal
        className="register-step-three-docs"
        show={showPopup === true}
        onHide={handleClose}
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-pdfCondition">
          <object
            aria-labelledby="step-three-policy"
            width="100%"
            height="85%"
            data={pdfPolicyId}
            type="application/pdf"
          />
          <div className="download-pdf">
            <div className="file-icon">
              <img alt="" src={pdfIcon} />
            </div>
            <div className="browse-file">
              <a href={pdfPolicyId} download>
                <FormattedMessage {...messages.DownloadFile} />
              </a>
            </div>
          </div>
          <div className="btn-center-Accept">
            <NormalButton onClick={ConfirmAcceptPolices}>
              <FormattedMessage {...messages.Confirm} />
            </NormalButton>
          </div>
        </Modal.Body>
      </Modal>
      {/* show pdf condition*/}
      <Modal
        className="register-step-three-docs"
        show={showPopupTwo === true}
        onHide={handleCloseTwo}
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-pdfCondition ">
          <object
            aria-labelledby="step-three-policy"
            width="100%"
            height="85%"
            data={pdfConditionsId}
            type="application/pdf"
          />
          <div className="download-pdf">
            <div className="file-icon">
              <img alt="" src={pdfIcon} />
            </div>
            <div className="browse-file">
              <a href={pdfConditionsId} download>
                <FormattedMessage {...messages.DownloadFile} />
              </a>
            </div>
          </div>
          <div className="btn-center-Accept">
            <NormalButton onClick={ConfirmAcceptCondition}>
              <FormattedMessage {...messages.Confirm} />
            </NormalButton>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

UserFormCompany.propTypes = {
  addUserCompany: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    newRegisterCompany: (company) => dispatch(newRegisterCompany(company)),
    addRegisterCompany: (company) => dispatch(addRegisterCompany(company)),
  };
}

const mapStateToProps = (state) => {
  const { company, language } = state.toJS();
  return { company, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFormCompany);
