import { producerConstants, alertConstants } from '../constants';

import { OrderService } from '../services';
import extractData from '../services/data.processing';
import orderBy from 'lodash/orderBy';

function getAllProducer(date) {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().toJS().users.accessToken;
      let orderProd;
      let order;
      let truck;
      let groupedEartags;
      let openBuyRequests;
      let notes;
      let isFinalized;
      
      const data = await OrderService.getToursManagement(accessToken, date);
      if (typeof data !== 'undefined' && data.status === 200) {
        orderProd = await extractData(data);
        const orderSorter = (order) => order.delivery_time;
        let sortedList = orderBy(
          orderProd.tourManagementItem.orders,
          [orderSorter],
          ['asc'],
        );
        order = sortedList;
        truck = orderProd.tourManagementItem.trucks;
        groupedEartags = orderProd.tourManagementItem.groupedEartags;
        openBuyRequests = orderProd.tourManagementItem.openBuyRequests;
        notes = orderProd.tourManagementItem.notes;
        isFinalized = orderProd.tourManagementItem.isFinalized;
      }

      await dispatch({
        type: producerConstants.PRODUCER_GET_ALL,
        orderProd,
        date,
        order,
        truck,
        groupedEartags,
        openBuyRequests,
        notes,
        isFinalized
      });
    } catch (error) {
      // let errorMsg = 'Network Error' + error;
      // dispatch({ type: alertConstants.ERROR, errorMsg });
      // await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

function updateProducerbByreq() {
  return async (dispatch, getState) => {
    try {
      let order = getState().toJS().producer.order;
      let orderProd = getState().toJS().producer.orderProd;
      let truck = getState().toJS().producer.truck;
      let date = getState().toJS().producer.date;
      let groupedEartags = getState().toJS().producer.groupedEartags;
      let openBuyRequests = getState().toJS().producer.openBuyRequests;
      let notes = getState().toJS().producer.notes;
      let isFinalized = getState().toJS().producer.isFinalized;
      await dispatch({
        type: producerConstants.GET_PRODUCER,
        orderProd,
        date,
        order,
        truck,
        groupedEartags,
        openBuyRequests,
        notes,
        isFinalized
      });
    } catch (error) {
    }
  };
}

function updateProducerbByPushNotification(dateByOtherUsed, accessTokenByOtherUsed) {
  return async (dispatch, getState) => {
    try {
      let date = getState().toJS().producer.date;
      const accessToken = getState().toJS().users.accessToken;
      let orderProd;
      let order;
      let truck;
      let groupedEartags;
      let openBuyRequests;
      let notes;
      let isFinalized;

      let userTokenSession = 'Bearer' + accessTokenByOtherUsed;
      if (accessToken !== userTokenSession) {
        if (dateByOtherUsed === date) {
          const data = await OrderService.getToursManagement(accessToken, date);
          if (typeof data !== 'undefined' && data.status === 200) {
            orderProd = await extractData(data);
            const orderSorter = (order) => order.delivery_time;
            let sortedList = orderBy(
              orderProd.tourManagementItem.orders,
              [orderSorter],
              ['asc'],
            );
            order = sortedList;
            truck = orderProd.tourManagementItem.trucks;
            groupedEartags = orderProd.tourManagementItem.groupedEartags;
            openBuyRequests = orderProd.tourManagementItem.openBuyRequests;
            notes = orderProd.tourManagementItem.notes;
            isFinalized = orderProd.tourManagementItem.isFinalized;
          }

          await dispatch({
            type: producerConstants.PRODUCER_GET_ALL,
            orderProd,
            date,
            order,
            truck,
            groupedEartags,
            openBuyRequests,
            notes,
            isFinalized
          });
        }
      }
    } catch (error) {
    }
  };
}

function updateProducerOrder() {
  return async (dispatch, getState) => {
    try {
      let order = getState().toJS().producer.order;
      let orderProd = getState().toJS().producer.orderProd;
      let truck = getState().toJS().producer.truck;
      let date = getState().toJS().producer.date;
      let openBuyRequests = getState().toJS().producer.openBuyRequests;
      let groupedEartags = getState().toJS().producer.groupedEartags;
      let notes = getState().toJS().producer.notes;
      let isFinalized = getState().toJS().producer.isFinalized;

      await dispatch({
        type: producerConstants.UPDATE_PRODUCER,
        orderProd,
        date,
        order,
        truck,
        groupedEartags,
        openBuyRequests,
        notes,
        isFinalized
      });
    } catch (error) {
      let errorMsg = 'Network Error' + error;
      dispatch({ type: alertConstants.ERROR, errorMsg });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}



function resetProducerOrder() {
  return async (dispatch, getState) => {
    try {
      let order = [];
      let orderProd = [];
      let truck = [];
      let date = getState().toJS().producer.date;
      let openBuyRequests = getState().toJS().producer.openBuyRequests;
      let groupedEartags = getState().toJS().producer.groupedEartags;
      let notes ='';
      let isFinalized = getState().toJS().producer.isFinalized;

      await dispatch({
        type: producerConstants.RESET_PRODUCER,
        orderProd,
        date,
        order,
        truck,
        groupedEartags,
        openBuyRequests,
        notes,
        isFinalized
      });
    } catch (error) {
      let errorMsg = 'Network Error' + error;
      dispatch({ type: alertConstants.ERROR, errorMsg });
      await dispatch({ type: alertConstants.CLEAR, message: '' });
    }
  };
}

export const producerActions = {
  getAllProducer,
  updateProducerOrder,
  updateProducerbByreq,
  updateProducerbByPushNotification,
  resetProducerOrder
};
