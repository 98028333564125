import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  isConnected,
  component: Component,
  // eslint-disable-next-line no-useless-rename
  users: users,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isConnected ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
