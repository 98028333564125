import { adminConstants } from '../constants';

function handleTitleChange(panelName) {
  return {
    type: adminConstants.HANDLE_TITLE_CHANGE,
    panelName,
  };
}

export const adminActions = {
  handleTitleChange,
};
