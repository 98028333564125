/* eslint-disable */
import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';
import { Col, Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { applyDrag, generateItems } from './utils';
import { alertActions, OrderActions } from '../../../actions';
import { sumBy } from 'lodash';

import moment from 'moment-es6';
import messages from '../messages';

import { getCompany, getContacts } from '../../../services/company.service';
import { OrderService } from '../../../services/order.service';

import extractData from '../../../services/data.processing';
// import messages from '../messages';
import ModalNumber from './ModalNumber';
import CardOrder from './card';
import useModal from './useModal';
// import { addLeadingZero } from '../../../helpers/order';
// import truckIcon from '../../../static/images/admin/Icons/Status icons/truckIcon.png';
import emailIcon from '../../../static/images/admin/Icons/Status icons/email-symbol.svg';

import './tour.css';
import './tourManagementInfo.css';
// import ExternerVerkauf from '../../../static/images/admin/Icons/Status icons/Externer Verkauf.png';
// import Nutztierverkauf from '../../../static/images/admin/Icons/Status icons/Nutztierverkauf.png';

// import Selbstfahre from '../../../static/images/admin/Icons/Status icons/Selbstfahrer.png';
import ReSellRequest from '../Orders/Resell/ReSellRequest';
import RegularInput from '../../Shared/components/inputs/RegularInput';

const getId = () => new Date().getTime();

const getColumnSize = (column) =>
  column.children.reduce((a, b) => a + b.data.number, 0);

const updateOrderName = (name, number) => name.replace(/(\d+)/, number);

const renderOrderData = (data, lang, getOrders) => (
  <CardOrder getOrders={getOrders} data={data}></CardOrder>
);

const regroupDuplicates = (column) => {
  const result = column.children.reduce((a, b) => {
    const itemIdx = a.findIndex((e) => e.id === b.id);
    if (itemIdx > -1) {
      const number = a[itemIdx].data.number + b.data.number;
      a[itemIdx] = {
        ...a[itemIdx],
        data: {
          ...a[itemIdx].data,
          name: updateOrderName(a[itemIdx].data.name, number),
          number,
        },
      };
    } else {
      a.push(b);
    }
    return a;
  }, []);
  column.children = result;
  return column;
};

const getCompanyInfo = async (companyid, token) => {
  const comp = await getCompany(companyid, token);
  if (
    comp !== 'null' &&
    typeof comp !== 'undefined' &&
    typeof comp.status !== 'undefined' &&
    comp.status === 200
  ) {
    const info = await extractData(comp);
    return info;
  }
};

const TourList = ({
  orderDetail,
  token,
  company_id,
  success,
  clear,
  error,
  users,
  getOrders,
  orderType,
  typeOrder,
  handleClosePanel,
  assigned,
  getToursDate,
  changeTab,
  changeDate,
  deleteOrder,
  language,
  displayBuyRequestEdit,
  orderTruck,
  trucksShow,
  groupedEartags,
  notes,
  date,
  producer
}) => {
  const [scene, setScene] = useState(null);
  const [animals, setAnimals] = useState(0);
  // submit btn is active when not assigned length = 0
  const submitBtnIsdisabled = animals !== 0;
  const { isShowing, toggle } = useModal();
  const [column, setColumn] = useState(null);
  const [lang, setLang] = useState(language);
  const [type, setType] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [contact, setContact] = useState(null);
  const [company, setCompany] = useState(null);
  const [NumberEartags, setNumberEartags] = useState(0);
  const [dropResult, setDropResult] = useState(null);
  const [btnAddTruckIsDiable, disableBtn] = useState(false);
  const [notAssigned, setNotAssigned] = useState([]);
  const [notAssignedTruck, setNotAssignedTruck] = useState([]);
  const [contactList, setcontactList] = useState([]);
  const [showResell, setshowResell] = useState(false);
  const [orderSelling, setorderSelling] = useState([]);
  const [orderSellingT, setorderSellingT] = useState({});
  const [totalNumber, setTotalNumber] = useState(0);
  const [globalenotes, setNotes] = useState('');
  const [showEmail, setShowEmail] = useState(false);
  const [emailFormatEmail, setemailFormatEmail] = useState(true);
  const [emailText, setemailText] = useState('');

  const { isShowing: isShowingModalNumber, toggle: toggleModalNumber } =
    useModal();

  useEffect(() => {
    async function fetchData() {
      setNotes(typeof notes !== 'undefined' ? notes : '');
      getTourPlanning(orderDetail, orderTruck);
      const infos = await getCompanyInfo(company_id, token);
      setCompany(infos);
      typeof orderDetail !== 'undefined'
        ? setTotalNumber(
          sumBy(orderDetail, function (rec, i) {
            return rec.number;
          }),
        )
        : setTotalNumber(0);
      setemailText(' ');
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  const initializeScene = (not_assigned, trucks) => {
    let initialScene;

    if (!trucksShow) {
      initialScene = {
        type: 'container',
        props: {
          orientation: 'horizontal',
        },
        children: generateItems(1, (i) => ({
          id: `column${getId()}`,
          level: 'order',
          className: 'card-wrapper',
          type: 'container',
          props: {
            orientation: 'vertical',
            className: 'card-contatrucksShowiner',
          },
          children: not_assigned.map((item, j) => ({
            type: 'draggable',
            id: `${getId()}-${j}${i}`,
            props: {
              className: 'cardTwo',
            },
            data: {
              order_id: item.order_id,
              name: item.name,
              number: item.number,
              delivery_time: item.delivery_time,
              date: item.delivery_date,
              partner: item.business_partner,
              status: item.status,
              sender_name: item.sender_name,
              receiver_id: item.receiver_id,
              topic: item.topic,
              is_buyer: item.is_buyer,
              freight_included: item.freight_included,
              notes: item.notes,
              data: item,
            },
          })),
        })),
      };
    } else {
      initialScene = {
        type: '',
        props: {
          orientation: 'horizontal',
        },
        children: generateItems(1, (i) => ({
          id: `column${getId()}`,
          level: 'order',
          className: 'card-wrapperTwo',
          type: 'container',
          props: {
            orientation: 'vertical',
            className: 'card-container',
          },
          children: [].map((item, j) => ({
            type: 'draggable',
            id: `${getId()}-${i}${j}`,
            props: {
              className: 'cardTwo',
            },
            data: {
              order_id: item.order_id,
              name: item.name,
              number: item.number,
              delivery_time: item.delivery_time,
              date: item.delivery_date,
              partner: item.business_partner,
              status: item.status,
              receiver_id: item.receiver_id,
              topic: item.topic,
              is_buyer: item.is_buyer,
              freight_included: item.freight_included,
              data: item,
            },
          })),
        })),
      };
    }

    const numberOfanimals = getColumnSize(initialScene.children[0]);
    setAnimals(numberOfanimals);

    let data = {};
    if (trucksShow && typeof trucks !== 'undefined') {
      for (let i = 0; i < trucks.length; i++) {
        if (typeof trucks[i].transport_company_id !== 'undefined') {
          trucks[i].transport_company =
            typeof contact.name !== 'undefined' ? contact.name : '';
          trucks[i].transport_company_number = contact.number;
        }
        if (
          trucks[i].type === 'OWN_CARPOOL' ||
          trucks[i].type === 'OWN_DRIVER'
        ) {
          const driverName = trucks[i].driver_name;
          // when driver is selected from list then driver_name is like {label, value, _id}
          // when driver is written manually then driver_name is string
          if (typeof driverName === 'string') {
            trucks[i].driver_name = driverName;
            trucks[i].driver_id = '';
          } else {
            trucks[i].driver_name =
              typeof trucks[i].transport_company !== 'undefined'
                ? trucks[i].transport_company
                : '';
            trucks[i].driver_id = '';
          }
        }
        if (trucks[i].type === 'EXTERNAL_DRIVER') {
          trucks[i].transport_company = '';
          trucks[i].transport_company_number = '';
          trucks[i].transport_company_id = '';
        }
        data.capacity = trucks[i].capacity;
        data.delivery_date = moment(new Date(), 'hh:mm A');

        data.delivery_until = moment(new Date(), 'hh:mm A').toDate();
        data.driver_id = '';
        data.driver_name =
          typeof trucks[i].transport_company !== 'undefined'
            ? trucks[i].transport_company
            : '';
        data.licence_number =
          typeof trucks[i].licence_number !== 'undefined'
            ? trucks[i].licence_number
            : '';
        data.pick_up_date = moment(new Date(), 'DD.MM.YY hh:mm A').toDate();
        data.transport_company =
          typeof trucks[i].transport_company !== 'undefined'
            ? trucks[i].transport_company
            : '';

        data.transport_company_id = {
          id: trucks[i].transport_company_id,
          value: trucks[i].transport_company_id,
          label: trucks[i].transport_company,
        };

        data.transport_company_number =
          typeof trucks[i].transport_company_number !== 'undefined'
            ? trucks[i].transport_company_number
            : '';
        data.type =
          trucks[i].type === 'OWN_CARPOOL' ? 'OWN_CARPOOL' : 'MANUAL_ENTRY';
        data.iconType = trucks[i].type;
        data.name = typeof trucks[i].name ? trucks[i].name : '';

        const truck = generateItems(1, (j) => ({
          id: `column${getId()}-${i}${j}`,
          level: 'truck',
          className: 'card-wrapper',
          type: 'container',
          data: {
            ...data,

            size: 0,
          },
          props: {
            orientation: 'vertical',
            className: 'card-container',
          },
          children: [],
        }));
        initialScene.children.push(truck[0]);
      }
      initialScene.children = initialScene.children.filter(
        (value) => value.level != 'order',
      );
    }
    setScene(initialScene);
  };

  const getTourPlanning = async (orderDetail, orderTruck) => {
    const { session } = users.user;

    const groups = ['2', '4'];
    let isRegistered;
    if (session.company_type !== '3') {
      isRegistered = 1;
    }

    const value = await getContacts(company_id, token, groups, isRegistered);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contactList = await extractData(value);

      setcontactList(contactList);
    }

    if (orderDetail && orderTruck) {
      setNotAssigned(orderDetail);

      initializeScene(orderDetail, orderTruck);
    }

    // if (orderTruck) {
    //   const contact = contactList.find(
    //     (e) => '' + e.number === '' + orderTruck[0].company_number,
    //   );

    //   setContact(contact);
    // }
  };

  const togglePopup = (type, column = null) => {
    toggle();
    setColumn(column);
    setType(type);
  };

  const increaseNumber = () => {
    let parsed = parseInt(NumberEartags);
    if (column.data.capacity - column.data.size - 1 >= parsed)
      setNumberEartags(parsed + 1);
  };

  const decreaseNumber = () => {
    let parsed = parseInt(NumberEartags);
    if (parsed > 0) setNumberEartags(parsed - 1);
  };

  const splitOrder = () => {
    const { payload, addedIndex } = dropResult;
    const newScene = Object.assign({}, scene);
    let newColumn = Object.assign({}, column);
    const columnId = newScene.children.findIndex((e) => e.id === newColumn.id);
    const copyOrder1 = {
      ...payload,
      data: {
        ...payload.data,
        number: parseInt(NumberEartags),
        name: updateOrderName(payload.data.name, parseInt(NumberEartags)),
      },
    };
    const copyOrder2 = {
      ...payload,
      data: {
        ...payload.data,
        number: payload.data.number - parseInt(NumberEartags),
        name: updateOrderName(
          payload.data.name,
          payload.data.number - parseInt(NumberEartags),
        ),
      },
    };

    newColumn.children[addedIndex] = copyOrder1;
    const newSize = getColumnSize(newColumn);
    newColumn.data.size = newSize;

    newColumn = regroupDuplicates(newColumn);
    newScene.children[columnId] = newColumn;
    newScene.children[0].children.push(copyOrder2);
    newScene.children[0] = regroupDuplicates(newScene.children[0]);
    setAnimals(getColumnSize(newScene.children[0]));
    setNumberEartags(0);
    setScene(newScene);
    toggleModalNumber();
  };

  const handleCloseModalNumber = () => {
    const { payload } = dropResult;
    const newScene = Object.assign({}, scene);
    const newColumn = Object.assign({}, column);
    const columnId = newScene.children.findIndex((e) => e.id === newColumn.id);
    newColumn.children = newColumn.children.filter((e) => e.id !== payload.id);
    newScene.children[0].children.push(payload);
    newScene.children[0] = regroupDuplicates(newScene.children[0]);
    newScene.children[columnId] = newColumn;
    // setAnimals(getColumnSize(newScene.children[0]));
    // setNumberEartags(0);
    // setScene(newScene);
    // toggleModalNumber();
  };

  const renderModalNumber = () => {
    return isShowingModalNumber ? (
      <ModalNumber
        isShowing={isShowingModalNumber}
        togglePopupNumber={handleCloseModalNumber}
        increaseNumber={increaseNumber}
        decreaseNumber={decreaseNumber}
        NumberEartags={NumberEartags}
        splitOrder={splitOrder}
        maxValue={column.data.capacity - column.data.size}
      />
    ) : null;
  };

  const handleClose = () => {
    setshowResell(false);
    //orderSelling
  };

  const togglePopupEmail = () => {
    setShowEmail(true);
    setemailText('');
  };

  const handleCloseEmail = () => {
    setShowEmail(false);
  };

  const sendMail = async () => {
    await isValiEmail(emailText);
    let emailTextData = await isValiEmailTest(emailText);
    if (emailTextData) {
      const res = await OrderService.sendEmail(token, date, emailText);
      if (typeof res !== 'undefined' && res.status === 200) {
        const msg =
          language.locale === 'EN'
            ? `Mail has been successfully sent`
            : `E-Mail wurde erfolgreich versendet`;
        success(msg);
        await handleCloseEmail();

        clear();
      } else {
        const msg =
          language.locale === 'EN'
            ? `Mail was not successfully sent  `
            : `E-Mail konnte nicht versendet werden `;
        success(msg);
        clear();
      }
    }
  };

  const isValiEmail = (val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      setemailFormatEmail(false);
    } else {
      setemailFormatEmail(true);
    }
  };

  const isValiEmailTest = (val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangeEmail = (event) => {
    const { name, value } = event.target;
    // eslint-disable-next-line default-case
    switch (name) {
      case 'userEmail':
        // this.setState({ notes: value });

        setemailText(value);
        // setNotes(value)
        // localStorage.setItem('globalenotes', value);
        break;
    }
  };

  const getEmpty = () => {
    getOrders();
  };

  const renderResselse = (data, item) => {
    setshowResell(true);
    setorderSellingT(item);
    setorderSelling([item.order_id]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // eslint-disable-next-line default-case
    switch (name) {
      case 'globalenotes':
        // this.setState({ notes: value });
        setNotes(value);
        localStorage.setItem('globalenotes', value);
        break;
    }
  };

  const onCardDrop = (target, dropResult) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      if (isShowingModalNumber) return false;
      const newScene = Object.assign({}, scene);

      const columnIndex = newScene.children.indexOf(target);
      let newColumn = Object.assign({}, target);

      newColumn.children = applyDrag(newColumn.children, dropResult);
      newScene.children.splice(columnIndex, 1, newColumn);
      if (newColumn.level === 'truck') {
        const newSize = getColumnSize(newColumn);
        if (newSize > newColumn.data.capacity) {
          setColumn(newColumn);
          setDropResult(dropResult);
          setNumberEartags(newColumn.data.capacity - newColumn.data.size);
          toggleModalNumber();
          return false;
        }
        newColumn.data.size = newSize;
      } else {
        newScene.children[0] = regroupDuplicates(newScene.children[0]);
        setAnimals(getColumnSize(newScene.children[0]));
      }
      if (
        dropResult.addedIndex !== null &&
        typeof newColumn.data !== 'undefined' &&
        typeof newColumn.data !== 'undefined' &&
        newColumn.data.iconType === 'EXTERNAL_DRIVER'
      ) {
        setshowResell(true);

        renderResselse(newColumn.data, dropResult.payload.data);
      }
      localStorage.setItem('Allorder', JSON.stringify(newScene));
      let number = 0;
      newScene.children[0].children.map((e) => (number = number + e.data.number));

      setTotalNumber(number);

      setScene(newScene);
    }
  };
  const getCardPayload = (columnId, index) => {
    return scene.children.filter((p) => p.id === columnId)[0].children[index];
  };

  if (!scene) {
    return (
      <div key={0} className="loader-prodstocks">
        <div className="loader-prodst" key={0}></div>
      </div>
    );
  }

  const truckItem = column ? column.data : null;
  const companyType = users.user.session.company_type;
  return (
    <Col style={{ height: '100%', width: '100%' }} md={6} sm={12}>
      <Modal show={showResell} onHide={handleClose} style={{ opacity: 1 }}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-resell ">
          <div>
            <ReSellRequest
              externalRequest={true}
              orderDetail={orderSelling}
              companyId={users.user.session.company_id}
              orderType={'buyings'}
              getOrders={getEmpty}
              orders={orderSelling}
              getEmpty={getEmpty}
              handleClosePanel={handleClose}
              checked={false}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEmail}
        onHide={handleCloseEmail}
        style={{ opacity: 1 }}
        className="modalsizeTwop"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="deorder-ertagsp">
          <div className="flexbox">
            <div className="linestyle">
              <div>
                <FormattedMessage {...messages.Sendcurrentcattle} />
              </div>
              <RegularInput
                type="email"
                name="userEmail"
                defaultValue={emailText}
                onChange={handleChangeEmail}
                required
                label={<FormattedMessage {...messages.Mailrecipient} />}
              />
              <div style={{ color: 'red' }}>
                {!emailFormatEmail && emailText !== '' ? (
                  <FormattedMessage {...messages.EnterValidemail} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <Button
              // disabled={!emailFormatEmail}
              className="btn-filter"
              onClick={sendMail}
            >
              <FormattedMessage {...messages.SendmailSended} />
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <div style={{ width: '95% !important' }}>
        <div
          style={{
            width: '100%',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            marginTop: '20px',
            overflowScrolling: 'touch',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <div
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              marginTop: '20px',
              overflowScrolling: 'touch',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
            className="outer"
          >
            {groupedEartags && groupedEartags.length > 0 && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    alignContent: 'stretch',
                  }}
                >
                  {groupedEartags
                    .filter((group, i) => i < groupedEartags.length - 1)
                    .map((val, i) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          justifyContent: 'flex-start',
                          alignItems: 'stretch',
                          alignContent: 'stretch',
                          marginRight: '10px',
                        }}
                        key={i}
                      >
                        <div
                          style={{
                            width: '62px',
                          }}
                          className="breedTextT"
                        >
                          {' '}
                          {val.breed}{' '}
                        </div>

                        {val.qps.map((val, i) => (
                          <div
                            style={{
                              width: '55px',
                            }}
                            className="breedTextNotBold"
                            key={i}
                          >
                            {val.type}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    marginTop: '5px',
                    marginBottom: '5px',
                    border: '1px solid #CACBCD',
                    height: '1px',
                    width: '99%',
                  }}
                ></div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    alignContent: 'stretch',
                  }}
                >
                  {groupedEartags
                    .filter((group, i) => i < groupedEartags.length - 1)
                    .map((val, i) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          justifyContent: 'flex-start',
                          alignItems: 'stretch',
                          alignContent: 'stretch',
                          marginRight: '10px',
                        }}
                        key={i}
                      >
                        <div
                          style={{
                            width: '62px',
                          }}
                          className="breedTextNotBold"
                        >
                          {' '}
                          {val.number}{' '}
                        </div>

                        {val.qps.map((val, i) => (
                          <div
                            style={{
                              width: '55px',
                              textAlign: 'center',
                              fontWeight: '',
                              marginLeft: '2px',
                            }}
                            key={i}
                            className="breedTextNotBold"
                          >
                            {val.number}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div onClick={togglePopupEmail}>
            <img
              style={{ width: '25px', height: '25px' }}
              src={emailIcon}
            ></img>
          </div>
        </div>

        <div className="tourInfo-title-pfT">
          <div className="tourInfo-title-btn-for">
            <textarea
              name="globalenotes"
              className="form-input"
              placeholder={
                lang.locale === 'DE'
                  ? 'Notizen des Tages...'
                  : 'Additional notes...'
              }
              style={{ border: '1px solid #bbbbbb' }}
              cols={18}
              rows={4}
              //Additional notes..
              value={globalenotes}
              onChange={handleChange}
            // disabled={this.state.loadingTimeAndNotes}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'stretch',
          }}
        >
          <div>
            <span>
              <FormattedMessage {...messages.Nichtzugeordnet} />:{' '}
            </span>
            <span
              style={{
                color: '#79d70f',
                fontWeight: 'bold',

                fontSize: '15px',
              }}
            >
              {totalNumber}
            </span>
          </div>
          <div>
            <span>
              <FormattedMessage {...messages.NichtTotal} />:{' '}
            </span>

            <span
              style={{
                color: '#79d70f',
                fontWeight: 'bold',

                fontSize: '15px',
              }}
            >
              {sumBy(groupedEartags, function (rec, i) {
                return rec.number;
              })}
            </span>
          </div>
        </div>
      </div>
      <div className="newfocuesertags">
        {typeof scene.children !== 'undefined' &&
          scene.children.map((column) => (
            <Column
              key={column.id}
              column={column}
              trucksShow={trucksShow}
              lang={lang}
              getOrders={getEmpty}
              onCardDrop={onCardDrop}
              getCardPayload={getCardPayload}
              renderOrderData={renderOrderData}
              groupedEartags={groupedEartags}
              isfinished={typeof producer.isFinalized !== 'undefined'}

            />
          ))}
      </div>
      <div className="button-truck-field btn-complete-outer tour-planning-bottom-part"></div>
    </Col>
  );
};

const Column = memo(
  ({
    column,
    renderCardHeader,
    onCardDrop,
    getCardPayload,
    renderOrderData,
    lang,
    trucksShow,
    getOrders,
    groupedEartags,
    isfinished
  }) => (
    <div className={column.className} style={{ height: '100%' }}>
      <div className={column.props.className} style={{ height: '100%' }}>
        {trucksShow && renderCardHeader()}

        <Container
          style={{ height: '100%' }}
          {...column.props}
          columnId={column.id}
          level={column.level}
          data={column.data}
          groupName="col"
          onDragStart={() => { }}
          onDragEnd={() => { }}
          onDrop={(e) => onCardDrop(column, e)}
          getChildPayload={(index) => getCardPayload(column.id, index)}
          dragClass="opacity-ghost"
          dropClass="opacity-ghost-drop"
          onDragEnter={(e) => { }}
          onDragLeave={(e) => { }}
          onDropReady={(e) => { }}
          dropPlaceholder={{
            animationDuration: 150,
            showOnTop: true,
            className: 'drop-preview',
          }}
          dropPlaceholderAnimationDuration={200}
          shouldAcceptDrop={(sourceContainerOptions, payload) => {
            if (
              sourceContainerOptions.level === 'truck' &&
              column.level === 'truck' &&
              sourceContainerOptions.columnId !== column.id
            ) {
              return false;
            }

            if (
              typeof column.data !== 'undefined' &&
              column.data.capacity < payload.data.number
            ) {
              return false;
            }

            if (
              typeof payload.data.status !== 'undefined' &&
              payload.data.status !== 'BOUGHT' &&
              payload.data.status !== 'TOUR_ASSIGNED'
            ) {
              return false;
            }
            if (
              isfinished === true
            ) {
              return false
            }

            // if (
            //   typeof payload.data.status !== 'undefined' &&
            //   payload.data.status !== 'BOUGHT'
            // ) {
            //   return false;
            // }
            if (
              column.level === 'truck' &&
              sourceContainerOptions.level === 'order'
            ) {
              return column.data.size < column.data.capacity;
            }
            return true;
          }}
        >
          {column.children.map((card) => (
            <DraggableItem
              key={card.id}
              card={card}
              lang={lang}
              renderOrderData={renderOrderData}
              getOrders={getOrders}
            />
          ))}
        </Container>
      </div>
    </div>
  ),
);

const DraggableItem = ({ card, renderOrderData, lang, getOrders }) => (
  <Draggable>
    <div {...card.props}>
      {renderOrderData(card.data, lang, card, getOrders)}
    </div>
  </Draggable>
);

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    deleteOrder: (orderId) => dispatch(OrderActions.deleteOrder(orderId)),
    getOrders: (orderType, type, page, date) =>
      dispatch(OrderActions.getOrders(orderType, type, page, date)),
  };
}

const mapStateToProps = (state) => {
  const { users, language, producer } = state.toJS();
  return {
    producer,
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourList);
