import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import LocaleToggleText from '../../containers/LocaleToggleText';
import logo from '../../static/images/logo.png';
import loginBackground from '../../static/images/55.png';
import ForgetPasswordForm from './ForgetPasswordForm';
import messages from './messages';
import { style } from '../../utils/notifications';
import './main.css';

const addNotification = (notifRef, type, message) => {
  let level;
  switch (type) {
    case 'alert-success':
      level = 'success';
      break;
    case 'alert-warning':
      level = 'warning';
      break;
    case 'alert-danger':
      level = 'error';
      break;
    case 'alert-info':
      level = 'info';
      break;
    default:
      break;
  }
  if (level) {
    notifRef.current.addNotification({
      title: <span data-notify="icon" className="pe-7s-bell" />,
      message: message,
      level: level,
      position: 'tc',
      autoDismiss: 5,
    });
  }
};

const Forget = (props) => {
  const notifRef = useRef();
  // This is equivalent to our componentDidMount
  useEffect(() => {
    const { alert } = props;
    if (alert.type) {
      addNotification(notifRef, alert.type, alert.message);
    }
  });

  return (
    <Container id="login-container" fluid>
      <NotificationSystem ref={notifRef} style={style} />
      <Row>
        <Col md={4} className="login-content">
          <Row className="login-logo-top">
            <Col sm={6} className="login-logo-container">
              <Link to="/">
                <Image className="login-logo" src={logo} />
              </Link>
            </Col>
          </Row>

          <div className="login-content-top">
            <Row className="main-content">
              <h6 className="text-header">
                <FormattedMessage {...messages.ResetPasswordMailText} />
              </h6>
              <ForgetPasswordForm history={props.history} />
            </Row>
          </div>

          <Row className="login-content-bottom">
            <Col className="text-bottom end">
              <div className="login-language-screen">
                <div className="language-content">Language:&nbsp;</div>
                <div className="language-content">
                  <LocaleToggleText active={false} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={8} className="login-description column-space-arround-center">
          <Image className="bg" src={loginBackground} />
          <Row className="login-text  column-space-between">
            <p className="title">
              <FormattedMessage {...messages.LoginRightColumnTitleTop} />{' '}
            </p>
            <p className="title">
              <FormattedMessage {...messages.LoginRightColumnTitleBottom} />
            </p>

            <p className="text" style={{ color: '#fdfdfc' }}>
              <FormattedMessage {...messages.LoginRightColumnText} />
            </p>
            <p className="text-footer">© Yanogo GmbH {new Date().getFullYear()}</p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

Forget.propTypes = {
  alert: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { alert } = state.toJS();
  return { alert };
};

export default connect(mapStateToProps, null)(Forget);
