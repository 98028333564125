import React from 'react';
import { connect } from 'react-redux';
import './chat.css';
import fileImage from '../../../../../static/images/admin/chat/69410.png';
import sendImage from '../../../../../static/images/admin/chat/calque-1023.png';
import { db } from './firebase';


const getTime = (dateFormat) =>{

  var date = new Date(dateFormat)

  var year = date.getFullYear();
  var month = date.getMonth() + 1; //getMonth is zero based;
  var day = date.getDate();
  var time = date.getHours() + ':' + date.getHours();
  var formatted = day + '.' + month + '.' + year + ' ' + time;
  return formatted;
} 

const MessageSent = ({ sentMessage, message }) => {


  return <div className="message sent">
    {sentMessage}
    <span className="metadata">
      <span className="time">{ getTime(message.date) } </span>
    </span>
  </div>
};

const MessageRecieved = ({ receivedMessage,       message }) => {

  var date = new Date(message.date)

  var year = date.getFullYear();
  var month = date.getMonth() + 1; //getMonth is zero based;
  var day = date.getDate();
  var time = date.getHours() + ':' + date.getHours();
  var formatted = day + '.' + month + '.' + year + ' ' + time;


 return  <div className="message received">
    {receivedMessage}
    <span className="metadata">
      <span className="time"> { getTime(message.date) } </span>
    </span>
  </div>
};

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      details: [],
    };
  }

  componentDidMount() {
    db.ref(`Channel/${this.props.details.order.channel_id}/Messages`).on(
      'value',
      snapshot => {
        const messages = snapshot.val();
        messages && this.setState({ messages: [...Object.values(messages)] });
      },
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Load new data when the dataSource property changes.
    if (nextProps.details !== this.props.details) {
      this.setState({ details: nextProps.details });
      db.ref(`Channel/${nextProps.details.order.channel_id}/Messages`).on(
        'value',
        snapshot => {
          const messages = snapshot.val();
          messages
            ? this.setState({ messages: [...Object.values(messages)] })
            : this.setState({ messages: [] });
        },
      );
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const value = e.target.elements[0].value;
    const date = new Date().toISOString();
    const message = {
      date: date,
      message: value,
      seen: false,
      senderID: this.props.userId,
    };
    db.ref(`Channel/${this.props.details.order.channel_id}/Messages`).push(
      message,
    );
    e.target.reset();
  };

  render() {
    const { messages } = this.state;
    const { userId } = this.props;
    const procuderSize = typeof this.props.producer === 'undefined' ? true : false; 
    return (
      <div className={procuderSize ? "page" : "page1"}>
        <div className="chat">
          <div className="chat-container">
            <div className="conversation">
              <div className="conversation-container">
                {messages.map((message, index) => {
                  return message.senderID === userId ? (
                    <MessageSent key={index} sentMessage={message.message}       message={message} />
                  ) : (
                    <MessageRecieved
                      key={index}
                      receivedMessage={message.message}
                      message={message}
                    />
                  );
                })}
              </div>
              <form
                className="conversation-compose"
                onSubmit={e => this.handleSubmit(e)}
              >
                <input
                  className="input-msg"
                  name="input"
                  placeholder={
                    this.props.language.locale === 'DE'
                      ? 'Nachricht schreiben'
                      : 'Type a message '
                  }
                  autoComplete="off"
                  autoFocus
                />
                <div className="photo">
                  <img src={fileImage} alt="" />
                </div>
                <button className="send">
                  <div className="circle">
                    <img src={sendImage} alt="" />
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = state => {
  const { language } = state.toJS();
  return {
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
