import { producerConstants } from '../constants';

const initialState = {
  orderProd: {},
  order:[],
  truck:[],
  date:'',
  groupedEartags:[],
  openBuyRequests:[],
  notes: '',
  isFinalized: 0
};

export function producer(state = initialState, action) {
  switch (action.type) {
    
    case producerConstants.PRODUCER_GET_ALL:
      return {
        ...state,
        orderProd: action.orderProd,
        order: action.order,
        truck: action.truck,
        date: action.date,
        groupedEartags: action.groupedEartags,
        openBuyRequests: action.openBuyRequests,
        notes: action.notes,
        isFinalized: action.isFinalized
      };
      case producerConstants.GET_PRODUCER:
        return {
          ...state,
          orderProd: action.orderProd,
          order: action.order,
          truck: action.truck,
          date: action.date,
          groupedEartags: action.groupedEartags,
          openBuyRequests: action.openBuyRequests,
          notes: action.notes,
          isFinalized: action.isFinalized
        };
      case producerConstants.UPDATE_PRODUCER:
      return {
        ...state,
        orderProd: action.orderProd,
        order: action.order,
        truck: action.truck,
        date: action.date,
        groupedEartags: action.groupedEartags,
        openBuyRequests: action.openBuyRequests,
        notes: action.notes,
        isFinalized: action.isFinalized
      }; 
      case producerConstants.RESET_PRODUCER:
        return {
          ...state,
          orderProd: action.orderProd,
          order: action.order,
          truck: action.truck,
          date: action.date,
          groupedEartags: action.groupedEartags,
          openBuyRequests: action.openBuyRequests,
          notes: action.notes,
          isFinalized: action.isFinalized
        }; 
    default:
      return state;
  }
}
