import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../../components/Admin/messages';
import SignatureSection from '../toBeLoadedNextStep/AddSignature';

const renderEartags = (togglePopup, deleteEartag, itemToRemove) => {
  return (
    <div className="eartag-list-remove-modal" id="message-accept-deny">
      <Modal.Header className="eartag-header-modal" closeButton>
        <div className="text-choose-eartag">Agrabiz</div>
      </Modal.Header>
      <Modal.Body className="eartag-modal-body">
        <div className="text-choose-eartag">
          <FormattedMessage {...messages.CheckRemoveEartagsPart1} />
          {typeof itemToRemove !== 'undefined' && (
            <span>{itemToRemove.ear_tag_output_format}</span>
          )}
          <FormattedMessage {...messages.CheckRemoveEartagsPart2} />
        </div>
      </Modal.Body>
      <Modal.Footer className="eartag-modal-footer">
        <div className="remove-selected-eartag">
          <div className="text-submit" onClick={() => togglePopup()}>
            <FormattedMessage {...messages.Cancel} />
          </div>
          <div className="text-submit" onClick={() => deleteEartag()}>
            <FormattedMessage {...messages.ConfirmButton} />
          </div>
        </div>
      </Modal.Footer>
    </div>
  );
};

const renderSignature = (togglePopup, deleteEartag) => {
  return (
    <div id="message-accept-deny">
      <Modal.Header
        className="eartag-header-modal"
        style={{ height: '0%' }}
        closeButton
      >
        <div className="text-choose-eartag">Agrabiz</div>
      </Modal.Header>
      <Modal.Body className="eartag-modal-body">
        <div className="text-choose-eartag">
          <FormattedMessage {...messages.CheckSignature} />
        </div>
      </Modal.Body>
      {
        <div className="submit-field add-signature">
          <div className="text-submit" onClick={() => togglePopup()}>
            <FormattedMessage {...messages.No} />
          </div>
          <div className="text-submit" onClick={() => deleteEartag()}>
            <FormattedMessage {...messages.Yes} />
          </div>
        </div>
      }
    </div>
  );
};

const renderCapacity = (togglePopup, vehicleNumber) => {
  return (
    <div id="message-accept-deny">
      <Modal.Header
        className="eartag-header-modal"
        style={{ height: '0%' }}
        closeButton
      >
        <div className="text-choose-eartag">Agrabiz</div>
      </Modal.Header>
      <Modal.Body className="eartag-modal-body">
        <div className="text-choose-eartag">
          <span>{vehicleNumber} &nbsp;</span>
          <FormattedMessage {...messages.CheckCapacity} />
        </div>
      </Modal.Body>
      <div className="submit-field" style={{ marginBottom: '20px' }}>
        <button className="button-splite" onClick={() => togglePopup()}>
          <FormattedMessage {...messages.OkUnder} />
        </button>
      </div>
    </div>
  );
};
const addSignature = (togglePopup, showNote) => {
  return (
    <div>
      <Modal.Header
        className="Modal-add-truck-title"
        style={{ height: '0%' }}
        closeButton
      >
        <h5 class="card-title new-transport">
          <FormattedMessage {...messages.AddSiganture} />
        </h5>
      </Modal.Header>
      <Modal.Body>
        <SignatureSection togglePopup={togglePopup} showNote={showNote} />
      </Modal.Body>
    </div>
  );
};
const MessagePopup = ({
  show,
  togglePopup,
  message,
  vehicleNumber,
  deleteEartag,
  itemToRemove,
  showNote,
}) => {
  return (
    <Modal
      className={
        message === 'capacity'
          ? 'capacity-modal'
          : message === 'addsignature'
          ? 'add-signature'
          : 'eartag-modal'
      }
      show={show}
      onHide={togglePopup}
      style={{ opacity: 1 }}
    >
      {message === 'eartags' &&
        renderEartags(togglePopup, deleteEartag, itemToRemove)}
      {message === 'signature' && renderSignature(togglePopup, deleteEartag)}
      {message === 'addsignature' && addSignature(togglePopup, showNote)}
      {message === 'capacity' && renderCapacity(togglePopup, vehicleNumber)}
    </Modal>
  );
};

export default MessagePopup;
