import React from 'react';
import { connect } from 'react-redux';
import { alertActions, OrderActions } from '../../../../../actions';
import { Image, Col, Row, ListGroupItem, ListGroup } from 'react-bootstrap';

import { statusOrders } from '../../status-orders';
import '../orderDetails/orderDetails.css';

const ResaleOverview = ({
  users,
  sellings,
  buyings,
  getResales,
  orderDetail,
  getSrcImg,
  getOrders,
  orderType,
  name,
  language,
  ...props
}) => {
  const [resaleList, setResaleList] = React.useState([]);
  const getResaleList = async () => {
    try {
      if (
        orderDetail.topic === 'RESALE_STOCK' &&
        users.user.session.company_type === '2'
      ) {
        const resales = await getResales(orderDetail.order_id);
        setResaleList(resales);
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    getResaleList();
  }, [orderDetail.topic]);

  const findAndReplace = (e) => {
    if (language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  const renderListResale = () => {
    return (
      resaleList.length > 0 &&
      resaleList.map(
        (item, i) => (
          <ListGroupItem key={i}>
            <Row>
              <Col md={6} className="status">
                <Image
                  className="status-image"
                  src={getSrcImg(item.status, 1, 'SELL_STOCK', 0)}
                />
                <p className="status-name">
                  {
                    statusOrders.find(
                      (val) =>
                        item.status.split('_').join(' ') === val.statusOrder,
                    ).label
                  }
                </p>
              </Col>
              <Col md={6}>
                <p className="green"> {findAndReplace(item.name)}</p>
                <p className="info-item">
                  {item.delivery_date} | {item.business_partner}
                </p>
              </Col>
            </Row>
            <hr className="style-border" />
          </ListGroupItem>
        ),
        // ),
      )
    );
  };
  return (
    <div>
      {orderType === 'sellings' && (
        <ListGroup className="list-stock">
          <ul className="resale-list">{renderListResale()}</ul>
        </ListGroup>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    getResales: (orderId) => dispatch(OrderActions.getResales(orderId)),
  };
}

const mapStateToProps = (state) => {
  const { users, sellings, buyings, language } = state.toJS();
  return {
    users,
    sellings,
    buyings,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResaleOverview);
