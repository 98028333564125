import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import ToBeLoadedForm from './ToBeLoadedForm';
import { alertActions } from '../../../../../actions';

const FormWithFormik = withFormik({
  mapPropsToValues({ transportCompany, vehicleNumber, driverName }) {
    return {
      transportCompany: transportCompany,
      vehicleNumber: vehicleNumber,
      driverName: driverName,
    };
  },
  validationSchema: Yup.object().shape({
    vehicleNumber: Yup.string().required(),
    driverName: Yup.string().required(),
  }),
  handleSubmit: (values, { props, resetForm, setErrors, setSubmitting }) => {
    props.handleSubmit(values);
  },
})(ToBeLoadedForm);

function mapDispatchToProps(dispatch) {
  return {
    success: msg => dispatch(alertActions.success(msg)),
    error: error => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = state => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWithFormik);
