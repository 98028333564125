import React from 'react';
import './notice.scss';

const Notice = ({ sectionPosition }) => {
  return (
    <div className="notice-container">
      {sectionPosition === 'top' ? (
        <div className="notice-first-parag">
          * Agrabiz Pro basiert auf einem fairen, transparenten Preismodell: Sie
          zahlen monatlich nur 49,99€ pro Nutzer Basispauschale (zzgl. MwSt.),
          um Zugang zu allen Funktionen zu erhalten und darin bereits bis zu 250
          Tiere über unsere App zu verkaufen.
        </div>
      ) : (
        <>
          <div className="notice-second-paragTwo">
            Für jedes darüber hinaus über Agrabiz verkaufte Tier zahlen Sie
            einen kleinen Beitrag von maximal 20 Cent. Dies wird einfach und
            bequem in Ihrer monatlichen Rechnung kalkuliert und abgerechnet.
          </div>
          <div className="notice-second-paragTwo">
            Bei größeren Nutzerzahlen und Tierbewegungen profitieren Sie zudem
            von nochmals günstigeren, mengengestaffelten Preisen:{' '}
          </div>
          <div className="notice-table">
            <div className="n-t-header">
              <div className="h-left">
                <span>Verkaufte Tiere</span>
                <span>über Agrabiz</span>
              </div>
              <div className="h-right">
                <span>Preis</span>
                <span>pro Tier</span>
              </div>
            </div>
            <div className="n-t-separator" />
            <div className="n-t-content">
              <div className="content-left">
                ab 251 bis 1.000 <br />
                ab 1.001 bis 2.500 <br />
                ab 2.501 bis 5.000 <br />
                ab 5.001
              </div>
              <div className="content-right">
                20 Ct. <br />
                19 Ct. <br />
                18 Ct. <br />
                17 Ct.
              </div>
            </div>
          </div>
          <div className="notice-second-paragTwo"></div>
          <div className="n-t-separator" />
          <div className="notice-table">
            <div className="n-t-header">
              <div className="h-left">
                <span>Anzahl</span>
                <span>Nutzer</span>
              </div>
              <div className="h-right">
                <span>Monatliche</span>
                <span>Nutzer-Pauschale</span>
              </div>
            </div>
            <div className="n-t-separator" />
            <div className="n-t-content">
              <div className="content-left">
                bis 5 <br />
                ab 6 bis 10 <br />
                ab 11 bis 20 <br />
                ab 21 bis 30
                <br />
                ab 31 bis 40 <br />
                ab 41
              </div>
              <div className="content-right">
                49,99 € <br />
                39,99 € <br />
                29,99 € <br />
                24,99 €<br />
                22,99 € <br />
                21,99 €
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Notice;
