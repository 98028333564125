import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PrivateRoute from '../../privateRoute/PrivateRoute';
import ChatAdmin from '../../components/ChatAdmin';
import Admin from '../../containers/AdminPage/Admin';
import './app.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import logo from '../../static/images/logo.png';

const ScreenSupport = ({ isConnected }) => {
  const [hideUnsupportedSizeScreen, setHideUnsupportedSizeScreen] = useState(
    null,
  );
  const location = useLocation();

  const { width } = useWindowDimensions();

  const browserCurrentPathName = location.pathname;

  const validRoutes = ['/login', '/', '/forgot'];
  useEffect(() => {
    setHideUnsupportedSizeScreen(
      validRoutes.some(path => browserCurrentPathName === path) ||
        browserCurrentPathName.startsWith('/register') ||
        browserCurrentPathName.startsWith('/reset'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {width >= 1224 ? (
        <>
          <PrivateRoute
            path="/admin/:path"
            component={Admin}
            isConnected={isConnected}
          />
          <PrivateRoute
            path="/chatAdmin"
            component={ChatAdmin}
            isConnected={isConnected}
          />
        </>
      ) : (
        !hideUnsupportedSizeScreen && (
          <div className="non-supported-screen">
            <div className="screen-container">
              <img className="agrabiz-logo" src={logo} alt="" />
              <div className="first-message">
                Sie verwenden für unsere Webanwendung eine nicht unterstützte
                Bildschirmgröße.
              </div>
              <a href="https://www.agrabiz.de/">
                <div className="second-message">
                  Bitte laden Sie die mobile Version herunter.
                </div>
              </a>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ScreenSupport;
