import { configConstants } from '../constants';
import { get, post, put } from './request';

export async function getListOfInvoices(accessToken, page) {
  try {
    const contentType = 'application/json';
    return await get(
      `${configConstants.Invoices}?page=${page}`,
      accessToken,
      contentType,
    );
  } catch (error) {}
}

export async function downloadInvoiceDetails(accessToken, invoiceId) {
  try {
    const resp = await get(
      `${configConstants.Invoices}/${invoiceId}/detail`,
      accessToken,
      '',
      'blob',
    );
    return new Blob([resp.data], { type: 'application/pdf;charset=utf-8' });
  } catch (error) {}
}

export async function makePayment(data, token) {
  try {
    const contentType = 'application/json';
    return await post(
      `${configConstants.Invoices}/payment`,
      contentType,
      token,
      data,
    );
  } catch (error) {}
}

export async function getCustomer(token) {
  try {
    return await get(`${configConstants.Invoices}/customer`, token);
  } catch (error) {}
}

export async function cancelPayement(data, token) {
  try {
    const contentType = 'application/json';
    return await post(
      `${configConstants.Invoices}/cancel_payment?Subscription_type=${data}`,
      contentType,
      token,
    );
  } catch (error) {}
}

export async function editPayment(data, token) {
  try {
    const contentType = 'application/json';
    return await put(
      `${configConstants.Invoices}/payment`,
      contentType,
      token,
      data,
    );
  } catch (error) {}
}

export async function downloadSEPA(accessToken) {
  try {
    const resp = await get(
      `${configConstants.Invoices}/sepa`,
      accessToken,
      '',
      'blob',
    );

    return new Blob([resp.data], { type: 'application/pdf;charset=utf-8' });
  } catch (error) {}
}
