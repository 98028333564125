import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cookie from './mock';
import Menu from '../Navbar';
import Footer from './Footer';
import './cookiePolicy.css';
import WithIsScrolled from '../WithIsScrolled';

class CookiePloicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      margin: 20,
      selectedIndex: 1,
    };
  }

  renderCookieDetails = (content) => {
    content.map((item) => {
      return <p className="cookie-details">{item.desc}</p>;
    });
  };

  render() {
    const { selectedIndex } = this.state;
    return (
      <div>
        <WithIsScrolled>
          {({ isScrolled }) => (
            <div
              id="navigation"
              className={
                isScrolled
                  ? 'navigation is-transparent affix'
                  : 'navigation is-transparent'
              }
              data-spy="affix"
              data-offset-top="0"
            >
              <Menu margin={this.state.margin} isFromCookie />
              <div>
                <div className="cookie__list-selections">
                  <Link
                    to="/impressum"
                    className={`${
                      selectedIndex === 0
                        ? 'cookie__btn-tab cookie__activeTab'
                        : 'cookie__btn-tab'
                    }`}
                  >
                    Impressum
                  </Link>

                  <Link
                    to="/cookie-richtlinie"
                    className={`${
                      selectedIndex === 1
                        ? 'cookie__btn-tab cookie__activeTab'
                        : 'cookie__btn-tab'
                    }`}
                  >
                    Cookie-Richtlinie
                  </Link>

                  <Link
                    to="/datenschutz"
                    className={`${
                      selectedIndex === 2
                        ? 'cookie__btn-tab cookie__activeTab'
                        : 'cookie__btn-tab'
                    }`}
                  >
                    Datenschutzerklärung
                  </Link>
                </div>
              </div>
            </div>
          )}
        </WithIsScrolled>
        <Container className="cookie-content">
          {cookie[this.state.selectedIndex].contents.map((item) => {
            return (
              <Row>
                <span className="cookie-title">{item.title}</span>
                {item.content.map((item) => {
                  return <p className="cookie-details">{item.desc}</p>;
                })}
              </Row>
            );
          })}
        </Container>
        <Footer />
      </div>
    );
  }
}

export default CookiePloicy;
