import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from './Popup';

class QualityItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      width: window.innerWidth,
      showPopup: false,
    };
    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup(e) {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }
  editQualityProgram = (qp) => {
    this.props.edit(qp);
  };
  render() {
    const imageSrc = this.props.photo;
    if (this.props.find === true) {
      return (
        <div className="col-md-2 col-sm-6">
          <div className="box-action">
            <div className="box-icons">
              <label className="inner-box">
                <div
                  className="update-icon-two"
                  onClick={() => this.editQualityProgram(this.props.quality)}
                ></div>
              </label>
            </div>
            <div className="update-icon-two-Bar"></div>
            <div
              className="box-icons reload-icon"
              onClick={(e) => this.props.delete(this.props.index)}
            ></div>
          </div>
          <div
            className="box wow fadeInUp active"
            data-wow-duration=".5s"
            onClick={this.togglePopup}
          >
            <label className="text-centered box-contain-img">
              {this.props.quality}
            </label>
            <span className="ok-icon"></span>
          </div>
          {this.state.showPopup ? (
            <Popup
              text="Close Me"
              closePopup={this.togglePopup}
              togglePopup={this.state.showPopup}
              src={imageSrc}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="col-md-2 col-sm-6">
          <div
            className="box wow fadeInUp"
            data-wow-duration=".5s"
            data-wow-delay=".3s"
          >
            <label className="text-centered" htmlFor="file">
              {this.props.quality}
            </label>
            <label className="input-file text-center">
              <span
                className="add-icon"
                onClick={() => this.editQualityProgram(this.props.quality)}
              ></span>
            </label>
          </div>
        </div>
      );
    }
  }
}

QualityItem.propTypes = {
  users: PropTypes.object,
};
function mapDispatchToProps() {
  return {};
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityItem);
