import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  htmlColor: PropTypes.string,
  title: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
};

const SvgIcon = React.forwardRef(function SvgIcon(props, ref) {
  const {
    children,
    className,
    color = '#7bd811',
    size = '50',
    htmlColor,
    title,
    width,
    height,
    position,
    left,
    right,
    top,
    bottom,
    viewBox = '0 0 24 24',
    ...other
  } = props;
  const prefix = 'svg-iconiz';

  const classes = clsx(prefix, color && `${prefix}-${color}`);

  const styles = {
    color: color,
    position: position,
    right: right,
    left: left,
    top: top,
    bottom: bottom,
    width: width ? `${width}` : `${size}px`,
    height: height ? `${height}` : `${size}px`,
  };
  return (
    <svg
      className={clsx(classes, className)}
      focusable="false"
      viewBox={viewBox}
      aria-hidden={title ? 'false' : 'true'}
      role={title ? 'img' : 'presentation'}
      style={styles}
      ref={ref}
      {...other}
    >
      {children}
      {title ? <title>{title}</title> : null}
    </svg>
  );
});

SvgIcon.displayName = 'SvgIcon';
SvgIcon.propTypes = propTypes;

export default SvgIcon;
