import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from '../../Popup';
import './qualityItem.css';
import searchIcon from '../../../static/images/admin/white.svg';
import clsx from 'clsx';
import messages from '../../../components/Admin/messages';

class QualityItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      width: window.innerWidth,
      showPopup: false,
    };
    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup(e) {
    this.setState({
      showPopup: !this.state.showPopup && !this.props.disabled,
    });
  }
  render() {
    const imageSrc = 'data:image/png;base64,' + this.props.photo;
    if (this.props.find === true) {
      return (
        <div
          className={clsx(
            this.props.disabled && 'disabled-qp',
            'col-md-2 col-sm-6 qpHeight',
          )}
        >
          <div
            className="box wow fadeInUp active"
            data-wow-duration=".5s"
            onClick={this.togglePopup}
          >
            <label className="text-center qp-text-color">
              {this.props.quality}
            </label>
            <img src={searchIcon} alt="icon" width="50%"></img>
          </div>
          {this.state.showPopup ? (
            <Popup
              title={messages.Certificate}
              handleClose={this.togglePopup}
              show={this.state.showPopup}
              imageSrc={imageSrc}
            />
          ) : null}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

QualityItem.propTypes = {
  users: PropTypes.object,
};

function mapDispatchToProps() {
  return {};
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityItem);
