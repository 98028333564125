export const isDate = (date) => {
  const isDate = Object.prototype.toString.call(date) === '[object Date]';
  const isValidDate = date && !Number.isNaN(date.valueOf());
  return isDate && isValidDate;
};

export const addLeadingZero = (n) => {
  return n < 10 ? '0' + n : n;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const upperCaseToNomal = (str) => {
  return capitalize(str.toLowerCase()).replace(/_/g, ' ');
};

export const removeDuplicates = (arr) => {
  return Array.from(new Set(arr));
};
