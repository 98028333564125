/* eslint-disable */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import FormCompany from './FormCompany';
import messages from '../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class StepThree extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      number: '',
      pin: '',
      visiblePassword: false,
      submitted: false,
      visible: false,
    };
  }
  showRole = () => {
    <Col md={{ span: 3, offset: 1 }}>
      <p className="titleStep3 buisness-text">
        <FormattedMessage {...messages.RegisterStepThreeBuisnessType} />
      </p>
      <h2 className="heading">
        <FormattedMessage {...messages.RegisterStepTwoTitleAgriculture} />
      </h2>
    </Col>;
  };
  render() {
    return (
      <Row>
        <ToastContainer />
        <Col className="titleStep3Form" md={{ span: 6, offset: 3 }}>
          <FormCompany role={this.state.role} />
        </Col>
      </Row>
    );
  }
}
StepThree.propTypes = {
  company: PropTypes.object,
  nextStepRegister: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { company } = state.toJS();
  return { company };
};

export default connect(mapStateToProps, null)(StepThree);
