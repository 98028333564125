import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Button,
  ListGroup,
  FormControl,
  FormGroup,
  InputGroup,
  ListGroupItem,
  Modal,
  Jumbotron,
  Dropdown,
  Image,
} from 'react-bootstrap';
import { MdNavigateBefore } from 'react-icons/md';
import ReactGA from 'react-ga';
import FileSaver from 'file-saver';
// import InfiniteScroll from 'react-infinite-scroller';
// import Slider from 'rc-slider';
import { svgPaths } from '../../../constants';
import uniqBy from 'lodash/uniqBy';
// import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import RegularInput from '../../Shared/components/inputs/RegularInput';
import { todesart } from '../../../constants';



import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

import {
  getStocks,
  searchStocks,
  moveToNextStep,
  getPassportStock,
  createPdf,
  getPDFStock,
  getLastUpdateDate,
  hitSynchronize,
  reportDeath,
  reportLeaving,
  editStock,
} from '../../../services/stock.service';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import extractData from '../../../services/data.processing';
import { connect } from 'react-redux';
import StockItem from './StockItem';
import moment from 'moment-es6';
import { MdSearch, MdClose } from 'react-icons/md';
import Form from './Form';
import { alertActions } from '../../../actions';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import sordIcon from '../../../static/images/arrow_copie_2.svg';
import sordIconTop from '../../../static/images/arrow-copie-11.svg';

import { stocksActions } from '../../../actions/stock.actions';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';
import ToggleMenuButton from '../../Shared/components/buttons/ToggleMenuButton/ToggleMenuButton';
import calendar from '../../../static/images/calque-1953.png';
import { menuItems } from './dropDownOptions';
import ConfirmMoveToNextBox from './ConfirmMoveToNextBox';
import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';
import './viewStock.css';
import locale from '../../../utils/DataPickerLang';
// import { stock } from '../../../reducers/stock.reducer';

// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);

class ViewStock extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    });
  }
  state = {
    ear_tag_search: '',
    box_search: '',
    stocks: [],
    listChecked: [],
    stockSelected: {},
    displayChecked: [],
    ear_tag: '',
    ear_tag_output_format: '',
    sort_ear_tag: '1',
    sort_race: '1',
    sort_breed: '1',
    sort_birth_date: '1',
    sort_box: '1',
    showForm: false,
    showFilter: false,
    showEartagDetails: false,
    passport: '',
    box_number: 0,
    searchEart: '',
    page: 0,
    allStocks: [],
    pageMax: 0,
    eartagSorted: false,
    ageSorted: false,
    raceSorted: false,
    breedSorted: false,
    boxSorted: false,
    newBlock: false,
    detailStock: false,
    block_api: false,
    stocksPagination: [],
    items: [],
    hasMoreItem: true,
    cursor: 0,
    Update: false,
    addBloqued: false,
    animateListButton: false,
    showDeath: false,
    showLeave: false,
    date: moment().toDate(),
    theDay: '',
    death: true,
    selectAll: false,
    selectAllValidate: false,
    changeBoxModal: false,
    boxToBeChanged: 0,
    openMoveModal: false,
    raceType: [],
    BreedType: [],
    currentIS: '',
    checkedItems: new Map(),
    checkedItemsBreed: new Map(),
    checkedItemsBreedDetect: new Map(),
    boxes: [],
    boxSelected: {
      label: '',
      value: '',
    },
    todesartSelected: {
      label: 'Verendung',
      value: '1',
    },
  };

  hideMoveAction = () => {
    this.setState({ openMoveModal: false });
  };

  confirmMoveAction = () => {
    this.setState({ openMoveModal: true });
  };

  lastUpdate = async () => {
    let token = this.props.users.accessToken;
    const vals = await getLastUpdateDate(token);
    let date = await extractData(vals);
    let dateToUpdateStock = localStorage.getItem('dateToUpdateStock');
    if (dateToUpdateStock === '') {
      localStorage.setItem('dateToUpdateStock', new Date(date));
      this.setState({ Update: true });
      await this.getAllStocks();
    } else {
      let Date1 = moment(
        new Date(localStorage.getItem('dateToUpdateStock')),
        'DD.MM.YY hh:mm A',
      ).toDate();
      let Date2 = moment(new Date(date), 'DD.MM.YY hh:mm A').toDate();

      const currentTime = new Date(Date1).valueOf();

      const expiryTime = new Date(Date2).valueOf();

      if (expiryTime - currentTime > 1000) {
        localStorage.setItem('dateToUpdateStock', new Date(Date2));
        this.setState({ Update: true });
        await this.getAllStocks();
      } else {
        this.setState({ Update: false });
        await this.getAllStocks();
      }
    }
  };

  UNSAFE_componentWillMount() {
    this.calendarRef = React.createRef();
    ReactGA.pageview(window.location.pathname + window.location.search);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.calendarRef = React.createRef();

    this.lastUpdate();
  }

  moveToNextBox = async () => {
    try {
      let token = this.props.users.accessToken;
      const tabEarTag = this.state.listChecked.map(({ ear_tag }) => ear_tag);
      const value = await moveToNextStep(token, tabEarTag);
      extractData(value);
      this.setState({
        stocks: [],
        allStocks: [],
        items: [],
        openMoveModal: false,
      });

      this.props.resetStock();
      var page = 0;
      var stocksPagination = [];
      var block_api = false;
      var stocks = [];
      await this.props.setStocks(page, stocksPagination, block_api, stocks);
      this.setState({
        stocks: this.props.allstock.allstock,
        allStocks: this.props.allstock.allstock,
        items: this.props.allstock.allstock,
      });

      this.setState({
        Update: true,
        listChecked: [],
        displayChecked: [],
        newBlock: false,
        openMoveModal: false,
      });
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  changeBox = async () => {
    this.toggleModalBoxChange();
  };

  createPdf = async () => {
    try {
      let token = this.props.users.accessToken;
      const tabEarTag = this.state.listChecked.map(({ ear_tag }) => ear_tag);
      let value = await createPdf(token, tabEarTag);
      const id = await extractData(value);
      value = await getPDFStock(token, id);
      FileSaver.saveAs(value, 'stocks.pdf');
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  createReportDeath = async () => {
    this.setState({ showDeath: true });
  };

  registerDeathFunc = async () => {
    this.setState({ showDeath: false });
    let token = this.props.users.accessToken;
    var dateObj = this.state.date;
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newdate = day + '.' + month + '.' + year.toString().substr(2, 3);
    const tabEarTag = this.state.listChecked.map(({ ear_tag }) => ear_tag);
    const newdata = { ear_tags: tabEarTag, death_date: newdate, TOD_ART: this.state.todesartSelected.value };
    let value = await reportDeath(token, newdata);
    if (value.status === 200) {
      this.props.success(<FormattedMessage {...messages.DeathSaved} />);
      this.props.clear();
      this.lastUpdate();
      this.setState({
        listChecked: [],
        displayChecked: [],
        newBlock: false,
      });
    } else {
      this.props.error(<FormattedMessage {...messages.DeathNotSaved} />);
      this.props.clear();
    }
  };

  createReportLeave = async () => {
    this.setState({ showLeave: true });
  };

  registerLeaveFunc = async () => {
    this.setState({ showLeave: false });
    let token = this.props.users.accessToken;
    var dateObj = this.state.date;
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newdate = day + '.' + month + '.' + year.toString().substr(2, 3);
    const tabEarTag = this.state.listChecked.map(({ ear_tag }) => ear_tag);
    const newdata = { ear_tags: tabEarTag, leaving_date: newdate };
    let value = await reportLeaving(token, newdata);
    if (value.status === 200) {
      this.props.success(<FormattedMessage {...messages.LeaveSaved} />);
      this.props.clear();
      this.lastUpdate();
      this.setState({
        listChecked: [],
        displayChecked: [],
        newBlock: false,
      });
    } else {
      this.props.error(<FormattedMessage {...messages.LeaveNotSaved} />);
      this.props.clear();
    }
  };

  // fetchMoreData = async () => {
  //   if (this.state.cursor * 1000 + 1000 > this.props.allstock.allstock.length) {
  //     this.setState({ hasMoreItem: false });
  //   } else {
  //     this.setState({
  //       items: this.state.items.concat(
  //         this.props.allstock.allstock.slice(
  //           this.state.cursor * 1000,
  //           this.state.cursor * 1000 + 1000,
  //         ),
  //       ),
  //       cursor: this.state.cursor + 1,
  //     });
  //   }
  // };

  setShowForm = async () => {
    this.setState({
      showForm: !this.state.showForm,
      detailStock: true,
    });
    // this.getStocks('all');
  };

  setShowFormAllStock = async () => {
    this.setState({
      showForm: !this.state.showForm,
      detailStock: true,
    });
    await this.getAllStocksNew();
  };

  handleChange = (e) => {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  handleCheckboxChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  handleCheckAll = (e) => {
    if (e.target.checked) {
      this.checkAllAnimal();
    } else {
      this.removeAllAnimalFromListChecked();
    }
  };

  handleCheckboxChangeBreed = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;

    this.setState(() => ({
      checkedItemsBreed: this.state.checkedItemsBreed.set(item, isChecked),
    }));
  };

  resetFormFilter = () => {
    this.setState({
      box_number: 0,
      boxSelected: {
        label: '',
        value: '',
      },
      checkedItems: new Map(),
      checkedItemsBreed: new Map(),
    });
    this.getAllStocks();
    this.toggleFilterPopup();
  };

  resetBox = () => {
    this.setState({
      newBlock: false,
    });
  };

  async getAllStocks() {
    var page = 0;
    var stocksPagination = [];
    var block_api = false;
    var stocks = [];
    if (!this.props.sellOffer) {
      if (this.props.allstock.allstock.length === 0 || this.state.Update) {
        await this.props.setStocks(page, stocksPagination, block_api, stocks);
      }
      const raceType = uniqBy(this.props.allstock.allstock, 'race');
      const BreedType = uniqBy(this.props.allstock.allstock, 'breed');
      const boxesList = uniqBy(this.props.allstock.allstock, 'box');

      const boxes = [];
      boxesList.map((e) => {
        let empty = this.props.language.locale === 'DE' ? 'leer' : 'empty';

        if (e.box === '') {
          boxes.push({
            label: empty,
            value: empty,
          });
        } else {
          boxes.push({
            label: e.box,
            value: e.box,
          });
        }
      });
      this.setState({
        stocks: this.props.allstock.allstock,
        allStocks: this.props.allstock.allstock,
        items: this.props.allstock.allstock,
        raceType,
        BreedType,
        boxes,
      });
    } else {
      if (typeof this.props.companyNumber !== 'undefined') {
        this.getStocks('all', this.props.companyNumber.company_number, 1);
      } else {
        await this.props.setStocks(page, stocksPagination, block_api, stocks);
        const raceType = uniqBy(this.props.allstock.allstock, 'race');
        const BreedType = uniqBy(this.props.allstock.allstock, 'breed');
        const boxesList = uniqBy(this.props.allstock.allstock, 'box');
        const boxes = [];
        boxesList.map((e) => {
          let empty = this.props.language.locale === 'DE' ? 'leer' : 'empty';

          if (e.box === '') {
            boxes.push({
              label: empty,
              value: empty,
            });
          } else {
            boxes.push({
              label: e.box,
              value: e.box,
            });
          }
        });

        this.setState({
          stocks: this.props.allstock.allstock,
          allStocks: this.props.allstock.allstock,
          items: this.props.allstock.allstock,
          raceType,
          BreedType,
          boxes,
        });
      }
    }
  }

  async getAllStocksNew() {
    var page = 0;
    var stocksPagination = [];
    var block_api = false;
    var stocks = [];
    if (!this.props.sellOffer) {
      await this.props.setStocks(page, stocksPagination, block_api, stocks);

      const raceType = uniqBy(this.props.allstock.allstock, 'race');
      const BreedType = uniqBy(this.props.allstock.allstock, 'breed');
      this.setState({
        stocks: this.props.allstock.allstock,
        allStocks: this.props.allstock.allstock,
        items: this.props.allstock.allstock,
        raceType,
        BreedType,
      });
    } else {
      if (typeof this.props.companyNumber !== 'undefined') {
        this.getStocks('all', this.props.companyNumber.company_number, 1);
      } else {
        await this.props.setStocks(page, stocksPagination, block_api, stocks);
        const raceType = uniqBy(this.props.allstock.allstock, 'race');
        const BreedType = uniqBy(this.props.allstock.allstock, 'breed');
        this.setState({
          stocks: this.props.allstock.allstock,
          allStocks: this.props.allstock.allstock,
          items: this.props.allstock.allstock,
          raceType,
          BreedType,
        });
      }
    }
  }

  dateChange = async (date) => {
    await this.setState({
      date: moment(date).toDate(),
    });
  };

  async getStocks(filter = 'all', companyNumber, isAvailable) {
    const token = this.props.users.accessToken;
    const company_number =
      companyNumber || this.props.users.user.session.company_number;
    let sort_filter;
    // eslint-disable-next-line default-case
    switch (filter) {
      case 'ear_tag':
        this.setState({ sort_ear_tag: this.state.sort_ear_tag * -1 });
        sort_filter = { ear_tag: this.state.sort_ear_tag };
        break;
      case 'race':
        sort_filter = { race: '-1' };
        break;
      case 'breed':
        this.setState({ sort_breed: this.state.sort_breed * -1 });
        sort_filter = { breed: this.state.sort_breed };
        break;
      case 'box':
        this.setState({ sort_box: this.state.sort_box * -1 });
        sort_filter = { box: this.state.sort_box };
        break;
      case 'birth_date':
        this.setState({ sort_birth_date: this.state.sort_birth_date * -1 });
        sort_filter = { birth_date: this.state.sort_birth_date };
        break;
      case 'all':
        sort_filter = {
          ear_tag: this.state.sort_ear_tag,
          race: this.state.sort_race,
          breed: this.state.sort_breed,
          box: this.state.sort_box,
          birth_date: this.state.sort_birth_date,
        };
        break;
    }
    const filterStock = isAvailable ? { is_available: 1 } : null;
    const value = await getStocks(
      JSON.stringify(sort_filter),
      token,
      company_number,
      0,
      this.props.breeds,
      this.props.races,
      filterStock,
    );
    const stocks = await extractData(value);
    const raceType = uniqBy(stocks, 'race');
    const BreedType = uniqBy(stocks, 'breed');
    if (this._isMounted) {
      this.setState({
        stocks: stocks,
        allStocks: stocks,
        raceType,
        BreedType,
      });
    }
  }

  async getStocksFilter(filter = 'all', companyNumber, isAvailable) {
    const token = this.props.users.accessToken;
    const company_number =
      companyNumber || this.props.users.user.session.company_number;

    let sort_filter;
    // eslint-disable-next-line default-case
    switch (filter) {
      case 'ear_tag':
        await this.setState({ sort_ear_tag: this.state.sort_ear_tag * -1 });
        sort_filter = { ear_tag: this.state.sort_ear_tag };
        break;
      case 'race':
        sort_filter = { race: '-1' };
        await this.setState({ sort_race: this.state.sort_race * -1 });
        sort_filter = { sort_race: this.state.sort_race };
        break;
      case 'breed':
        await this.setState({ sort_breed: this.state.sort_breed * -1 });
        sort_filter = { breed: this.state.sort_breed };
        break;
      case 'box':
        await this.setState({ sort_box: this.state.sort_box * -1 });
        sort_filter = { box: this.state.sort_box };
        break;
      case 'birth_date':
        await this.setState({
          sort_birth_date: this.state.sort_birth_date * -1,
        });
        sort_filter = { birth_date: this.state.sort_birth_date };
        break;
      case 'all':
        sort_filter = {
          ear_tag: this.state.sort_ear_tag,
          race: this.state.sort_race,
          breed: this.state.sort_breed,
          box: this.state.sort_box,
          birth_date: this.state.sort_birth_date,
        };
        break;
    }

    let alllist = this.state.allStocks;

    // sort sort_breed
    if (filter === 'breed') {
      if (this.state.sort_breed === 1) {
        const firstNameSorter = (user) => user.breed;

        let sortedList = orderBy(alllist, [firstNameSorter], ['desc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: 1,
          sort_breed: this.state.sort_breed,
          sort_box: 1,
          sort_birth_date: 1,
        });
      }
      if (this.state.sort_breed === -1) {
        const firstNameSorter = (user) => user.breed;

        let sortedList = orderBy(alllist, [firstNameSorter], ['asc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: 1,
          sort_breed: this.state.sort_breed,
          sort_box: 1,
          sort_birth_date: 1,
        });
      }
    }
    // sort sort_ear_tag
    if (filter === 'ear_tag') {
      if (this.state.sort_ear_tag === 1) {
        const firstNameSorter = (user) => user.ear_tag_output_format;

        let sortedList = orderBy(alllist, [firstNameSorter], ['desc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: this.state.sort_ear_tag,
          sort_race: 1,
          sort_breed: 1,
          sort_box: 1,
          sort_birth_date: 1,
        });
      }
      if (this.state.sort_ear_tag === -1) {
        const firstNameSorter = (user) => user.ear_tag_output_format;

        let sortedList = orderBy(alllist, [firstNameSorter], ['asc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: this.state.sort_ear_tag,
          sort_race: 1,
          sort_breed: 1,
          sort_box: 1,
          sort_birth_date: 1,
        });
      }
    }
    // sort sort_race
    if (filter === 'race') {
      if (this.state.sort_race === 1) {
        const firstNameSorter = (user) => user.race;

        let sortedList = orderBy(alllist, [firstNameSorter], ['desc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: this.state.sort_race,
          sort_breed: 1,
          sort_box: 1,
          sort_birth_date: 1,
        });
      }
      if (this.state.sort_race === -1) {
        const firstNameSorter = (user) => user.race;

        let sortedList = orderBy(alllist, [firstNameSorter], ['asc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: this.state.sort_race,
          sort_breed: 1,
          sort_box: 1,
          sort_birth_date: 1,
        });
      }
    }
    // sort sort_box
    if (filter === 'box') {
      if (this.state.sort_box === 1) {
        const firstNameSorter = (user) => user.box;

        let sortedList = orderBy(alllist, [firstNameSorter], ['desc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: 1,
          sort_breed: 1,
          sort_box: this.state.sort_box,
          sort_birth_date: 1,
        });
      }
      if (this.state.sort_box === -1) {
        const firstNameSorter = (user) => user.box;

        let sortedList = orderBy(alllist, [firstNameSorter], ['asc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: 1,
          sort_breed: 1,
          sort_box: this.state.sort_box,
          sort_birth_date: 1,
        });
      }
    }
    // sort sort_birth_date
    if (filter === 'birth_date') {
      if (this.state.sort_birth_date === 1) {
        const firstNameSorter = (user) =>
          typeof user.birth_date_sort !== 'undefined'
            ? new Date(user.birth_date_sort)
            : new Date('');
        let sortedList = orderBy(alllist, [firstNameSorter], ['asc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: 1,
          sort_breed: 1,
          sort_box: 1,
          sort_birth_date: this.state.sort_birth_date,
        });
      }
      if (this.state.sort_birth_date === -1) {
        const firstNameSorter = (user) =>
          typeof user.birth_date_sort !== 'undefined'
            ? new Date(user.birth_date_sort)
            : new Date('');

        let sortedList = orderBy(alllist, [firstNameSorter], ['desc']);
        await this.setState({
          stocks: sortedList,
          allStocks: sortedList,
          sort_ear_tag: 1,
          sort_race: 1,
          sort_breed: 1,
          sort_box: 1,
          sort_birth_date: this.state.sort_birth_date,
        });
      }
    }
  }

  getSynchronize = async () => {
    let token = this.props.users.accessToken;
    const hitMessage = await hitSynchronize(token);
    if (hitMessage.status === 200) {
      this.props.success(<FormattedMessage {...messages.HitSynch} />);
      this.props.clear();
      this.getAllStocks();
    } else {
      this.props.error(<FormattedMessage {...messages.HitNotSynch} />);
      this.props.clear();
    }
  };

  searchStocks = async () => {
    try {
      let token = this.props.users.accessToken;
      let sort_filter = { box: this.state.sort_ear_tag };
      let filter = {};
      this.toggleFilterPopup();
      if (this.state.ear_tag_search === '' && this.state.box_search === '') {
        this.setState({
          stocks: this.state.allStocks,
          allStocks: this.state.allStocks,
        });
        return false;
      } else if (this.state.ear_tag_search === '') {
        filter = { box: this.state.box_search };
      } else if (this.state.box_search === '') {
        filter = { ear_tag: this.state.ear_tag_search };
      } else {
        filter = {
          ear_tag: this.state.ear_tag_search,
          box: this.state.box_search,
        };
      }
      const value = await searchStocks(
        JSON.stringify(sort_filter),
        JSON.stringify(filter),
        token,
      );
      const stocks = await extractData(value);
      this.setState({
        stocks: stocks,
        allStocks: stocks,
      });
    } catch (error) {
      this.props.error(error);
      this.props.clear();
      this.getStocks('all');
    }
  };

  searchStocksFilter = async () => {
    const { box_number, checkedItems, checkedItemsBreed, boxSelected } =
      this.state;
    try {
      let checkedItemsBreedList = this.state.checkedItemsBreed;
      checkedItemsBreedList.forEach(function (item, key) {
        if (item === false) checkedItemsBreedList.delete(key);
      });
      let checkedItemsList = this.state.checkedItems;
      checkedItems.forEach(function (item, key) {
        if (item === false) checkedItemsList.delete(key);
      });

      const token = this.props.users.accessToken;
      const companyNumber = this.props.users.user.session.company_number;
      const sort = { ear_tag: 1 };
      const box =
        boxSelected.label === 'empty' || boxSelected.label === 'leer'
          ? ''
          : boxSelected.label;
      const filterStock = box ? { box: box + '' } : null;
      if (
        Array.from(checkedItemsBreedList.keys()).length > 0 ||
        Array.from(checkedItemsList.keys()).length > 0 ||
        (filterStock && filterStock !== '' && filterStock !== null)
      ) {
        const value = await getStocks(
          JSON.stringify(sort),
          token,
          companyNumber,
          0,
          Array.from(checkedItemsBreedList.keys()),
          Array.from(checkedItemsList.keys()),
          filterStock,
        );
        const stocks = await extractData(value);

        let stocksFilter = stocks;
        if (boxSelected.label === 'empty' || boxSelected.label === 'leer') {
          stocksFilter = stocks.filter((e) => e.box === '');
        }
        this.setState({
          stocks: stocksFilter,
          allStocks: stocksFilter,
        });
      } else if (
        Array.from(checkedItemsBreedList.keys()).length > 0 ||
        Array.from(checkedItemsList.keys()).length > 0 ||
        boxSelected.label === 'empty' ||
        boxSelected.label === 'leer'
      ) {
        const value = await getStocks(
          JSON.stringify(sort),
          token,
          companyNumber,
          0,
          Array.from(checkedItemsBreedList.keys()),
          Array.from(checkedItemsList.keys()),
          filterStock,
        );
        const stocks = await extractData(value);
        let stocksFilter = stocks;
        if (boxSelected.label === 'empty' || boxSelected.label === 'leer') {
          stocksFilter = stocks.filter((e) => e.box === '');
        }
        this.setState({
          stocks: stocksFilter,
          allStocks: stocksFilter,
        });
      } else {
        this.resetFormFilter();
      }
      this.toggleFilterPopup();
    } catch (error) {}
  };

  clearFilterBox = async () => {
    try {
      this.setState({ box_search: '' });
      this.getStocks('all');
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  searchStocksByEarTag = async (event) => {
    try {
      let allStocks = this.props.allstock.allstock;
      this.setState({ searchEart: event.target.value.toLowerCase() });
      if (event.target.value.length > 2) {
        let valErtag = event.target.value.toLowerCase();
        allStocks = allStocks.filter((w) =>
          w.ear_tag_output_format
            .replace(/\s/g, '')
            .toLowerCase()
            .includes(valErtag.replace(/\s/g, '')),
        );
      }
      if (!this.props.sellOffer) {
        this.setState({
          stocks: allStocks,
          allStocks: allStocks,
        });
      } else {
        this.setState({
          stocks: allStocks.filter((e) => e.is_available === 1),
          allStocks: allStocks.filter((e) => e.is_available === 1),
        });
        if (
          typeof this.props.breeds !== 'undefined' &&
          this.props.breeds.length > 0 &&
          typeof this.props.races !== 'undefined' &&
          this.props.races.length > 0
        ) {
          this.setState({
            stocks: allStocks
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  this.props.breeds.indexOf(e.breed) > -1 &&
                  this.props.races.indexOf(e.race) > -1,
              ),
            allStocks: allStocks
              .filter((e) => e.is_available === 1)
              .filter(
                (e) =>
                  this.props.breeds.indexOf(e.breed) > -1 &&
                  this.props.races.indexOf(e.race) > -1,
              ),
          });
        }
      }
      if (event.target.value.length <= 2) {
        if (!this.props.sellOffer) {
          this.setState({
            stocks: allStocks,
            allStocks: allStocks,
          });
        } else {
          this.setState({
            stocks: allStocks.filter((e) => e.is_available === 1),
            allStocks: allStocks.filter((e) => e.is_available === 1),
          });
          if (
            typeof this.props.breeds !== 'undefined' &&
            this.props.breeds.length > 0 &&
            typeof this.props.races !== 'undefined' &&
            this.props.races.length > 0
          ) {
            this.setState({
              stocks: allStocks
                .filter((e) => e.is_available === 1)
                .filter(
                  (e) =>
                    this.props.breeds.indexOf(e.breed) > -1 &&
                    this.props.races.indexOf(e.race) > -1,
                ),
              allStocks: allStocks
                .filter((e) => e.is_available === 1)
                .filter(
                  (e) =>
                    this.props.breeds.indexOf(e.breed) > -1 &&
                    this.props.races.indexOf(e.race) > -1,
                ),
            });
          }
        }
      }
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  editItem = async (type, i) => {
    try {
      let animal =
        type === 'all'
          ? Object.create(this.state.stocks[i])
          : type === 'selected'
          ? Object.create(this.state.displayChecked[i])
          : Object.create(this.props.toBeLoadedEartags[i]);
      let token = this.props.users.accessToken;
      // eslint-disable-next-line no-unused-vars
      // let id = this.props.users.user.session.company_id;
      let passport = await getPassportStock(animal._id, token, animal.passport);
      this.setState({
        ear_tag: animal.ear_tag,
        ear_tag_output_format: animal.ear_tag_output_format,
      });
      if (passport) this.setState({ passport: passport });
      this.setState({ stockSelected: animal });
      this.setState({
        indexSelected: i,
        showForm: true,
      });

      this.props.handleStock(animal);
    } catch {}
  };

  checkAnimal = async (animal, index) => {
    const clone = { ...animal };
    let animalList = this.state.listChecked;
    animalList = await [...animalList, clone];

    const newStock = await this.state.allStocks.filter(
      (el) => el.ear_tag !== animal.ear_tag,
    );

    await this.checkAllAnimalListAll(animalList);

    this.setState({
      allStocks: newStock,
    });
  };

  checkAllAnimalListAll = async (animalList) => {
    this.setState({
      newBlock: true,
      displayChecked: animalList,
      listChecked: animalList,
    });
  };

  checkAllAnimal = async () => {
    const { stocks } = this.state;
    this.setState({
      selectAll: true,
      displayChecked: stocks,
      listChecked: stocks,
      stocks: [],
      allStocks: [],
      items: [],
      newBlock: true,
    });
  };

  removeAnimalFromListChecked = (animal) => {
    const { listChecked, stocks } = this.state;
    const newCheckedList = listChecked.filter(
      (el) => el.ear_tag !== animal.ear_tag,
    );
    stocks.push(animal);
    this.setState({
      listChecked: newCheckedList,
      displayChecked: newCheckedList,
      stocks: stocks,
      allStocks: stocks,
      items: stocks,
      newBlock: newCheckedList.length > 0,
    });
  };

  removeAllAnimalFromListChecked = async () => {
    // await this.props.setStocks(0, [], false, []);

    const { listChecked, stocks } = this.state;
    stocks.push([...listChecked]);
    await this.getAllStocks();
    await this.setState({
      selectAll: false,
      listChecked: [],
      displayChecked: [],
      newBlock: false,
    });
  };

  renderListChecked = (animal, i, index) => {
    const type = 'selected';
    return (
      <>
        {typeof animal !== 'undefined' && typeof animal.box !== 'undefined' && (
          <StockItem
            key={i}
            editItem={() => this.editItem(type, index)}
            checkAnimal={() => this.checkAnimal(animal, index)}
            removeAnimalFromListChecked={() =>
              this.removeAnimalFromListChecked(animal)
            }
            checked={this.state.listChecked.findIndex(
              (val) => animal.ear_tag === val.ear_tag,
            )}
            data={animal}
            sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
          />
        )}
      </>
    );
  };

  /* add death */
  handlePopUpDeath = () => {
    this.setState({ showDeath: true });
  };

  togglePopupDeath = () => {
    this.setState({ showDeath: false });
  };

  hideModalBoxChange = () => {
    this.setState({ changeBoxModal: false });
  };

  toggleModalBoxChange = () => {
    this.setState({
      boxToBeChanged: Math.max.apply(
        Math,
        this.state.listChecked.map((eartag) => eartag.box),
      ),
      changeBoxModal: true,
    });
  };
  handleBoxChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'number':
        this.setState({ boxToBeChanged: value });
        break;
      default:
    }
  };

  changeEartagBox = async (event) => {
    event.preventDefault();
    const { listChecked, boxToBeChanged } = this.state;
    const token = this.props.users.accessToken;
    try {
      const file = null;
      listChecked.forEach(async (eartag) => {
        await editStock(token, eartag._id, boxToBeChanged, file);
      });
      this.props.success(<FormattedMessage {...messages.BoxChangedSuccess} />);
      this.props.clear();
      this.setState({
        stocks: [],
        allStocks: [],
        items: [],
        changeBoxModal: false,
      });
      this.props.resetStock();
      var page = 0;
      var stocksPagination = [];
      var block_api = false;
      var stocks = [];
      await this.props.setStocks(page, stocksPagination, block_api, stocks);
      this.setState({
        stocks: this.props.allstock.allstock,
        allStocks: this.props.allstock.allstock,
        items: this.props.allstock.allstock,
      });
      this.setState({
        Update: true,
        listChecked: [],
        displayChecked: [],
        newBlock: false,
      });
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };
  renderBoxChangeModal = () => {
    const { boxToBeChanged, changeBoxModal } = this.state;
    return (
      <React.Fragment>
        <Modal
          className="Number-Popup-modal"
          show={changeBoxModal}
          onHide={this.hideModalBoxChange}
          style={{ opacity: 1 }}
          id="stockviewer"
        >
          <Modal.Header style={{ height: '15%' }} closeButton>
            <div className="Modal-add-truck-title">
              <h5 className="card-title new-transport">
                <FormattedMessage {...messages.ChangeBox} />
              </h5>
            </div>
            <Modal.Title />
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="Number-Flex">
              <div className="Number-Flex-b1">
                <div className="inputNumber">
                  <input
                    name="number"
                    className="inputNumberStyle"
                    onChange={this.handleBoxChange}
                    type="text"
                    value={boxToBeChanged}
                  ></input>
                </div>
                <div className="Number-Flex-b1-arrow">
                  <div className="svg-arrow" onClick={this.increaseBoxNumber}>
                    <SvgIcon size="50" color="#7bd812" viewBox="0 0 560 560">
                      {svgPaths['arrowCircleUp']}
                    </SvgIcon>
                  </div>
                  <div className="svg-arrow" onClick={this.decreaseBoxNumber}>
                    <SvgIcon size="50" color="#7bd812" viewBox="0 0 560 560">
                      {svgPaths['arrowCircleDown']}
                    </SvgIcon>
                  </div>
                </div>
              </div>
              <div className="Number-Flex-b2">
                <Button className="add-Number" onClick={this.changeEartagBox}>
                  <FormattedMessage {...messages.ConfirmOther} />
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };

  increaseBoxNumber = () => {
    this.setState({ boxToBeChanged: this.state.boxToBeChanged + 1 });
  };

  decreaseBoxNumber = () => {
    if (this.state.boxToBeChanged > 0)
      this.setState({ boxToBeChanged: this.state.boxToBeChanged - 1 });
  };

  renderDeath = () => {
    return (
      <React.Fragment>
        <Modal
          className="Number-Popup-modal"
          show={this.handlePopUpDeath}
          onHide={this.togglePopupDeath}
          style={{ opacity: 1 }}
          id="stockviewer"
        >
          <Modal.Header style={{ height: '15%' }} closeButton>
            <div className="Modal-add-truck-title">
              <h5 className="card-title new-transport">
                <FormattedMessage {...messages.Deathregistration} />
              </h5>
            </div>
            <Modal.Title />
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="view-date-one">
              <div className="view-date-text ">
                <FormattedMessage {...messages.Deathtxt} />
              </div>


              <div className="deathBlock-sev">
              <div className="view-date-deathb1">
                <FormattedMessage {...messages.Deathdate} />
              </div>
              <div className="view-date-deathb2">
                <div className="view-date-flex">
                  <DatePicker
                    onChange={this.dateChange}
                    dateFormat="dd.MM.yyyy"
                    ref={this.calendarRef}
                    selected={this.state.date}
                    locale={locale}
                  />
                </div>
                <div className="view-date-flex">
                  <Image
                    onClick={() => {
                      this.calendarRef.current.setOpen(true);
                    }}
                    className="calendar-imagee"
                    src={calendar}
                  />
                </div>
                </div>
              </div>
              
              <div className='deathBlock-sev'>
              <div className="view-date-deathb1">
                <FormattedMessage {...messages.DeathTodesart} />
              </div>
              <div className="view-date-deathb3">
              <RegularInput
              // label={<FormattedMessage {...messages.country} />}
         
              select
              name="todesart"
              id="state"
              required
              defaultValue={{ value: '1', label: 'Verendung' }}
              // isDisabled={true}
              options={todesart}
              onChange={this.handleChangeTodesart}

              className={'selected selectedwidth'}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#F1FFDE',
                  primary: '#7bcd10',
                },
              })}
            />
                </div>
              </div>

              <div className="button-txtDeath " id="generic-btn-modal">
                <Button className="btn-filter" onClick={this.registerDeathFunc}>
                  <FormattedMessage {...messages.Registerdeath} />
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };

  /* add Leave */
  handlePopUpLeave = () => {
    this.setState({ showLeave: true });
  };

  togglePopupLeave = () => {
    this.setState({ showLeave: false });
  };

  renderLeave = () => {
    return (
      <React.Fragment>
        <div>
          <Modal
            className="Number-Popup-modal"
            show={this.handlePopUpLeave}
            onHide={this.togglePopupLeave}
            style={{ opacity: 1 }}
            id="stockviewer"
          >
            <Modal.Header style={{ height: '15%' }} closeButton>
              <div className="Modal-add-truck-title">
                <h5 className="card-title new-transport">
                  <FormattedMessage {...messages.Leaveregistration} />
                </h5>
              </div>
              <Modal.Title />
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="view-date-one">
                <div className="view-date-text ">
                  <FormattedMessage {...messages.Leavetxt} />
                </div>
                <div className="view-date-flex">
                  <FormattedMessage {...messages.Leavedate} />
                </div>
                <div className="view-date">
                  <div className="view-date-flex">
                    <DatePicker
                      onChange={this.dateChange}
                      dateFormat="dd.MM.yyyy"
                      ref={this.calendarRef}
                      selected={this.state.date}
                      locale={locale}
                    />
                  </div>
                  <div className="view-date-flex">
                    <Image
                      onClick={() => {
                        this.calendarRef.current.setOpen(true);
                      }}
                      className="calendar-imagee"
                      src={calendar}
                    />
                  </div>
                </div>
                <div className="button-txt" id="generic-btn-modal">
                  <Button
                    className="btn-filter"
                    onClick={this.registerLeaveFunc}
                  >
                    <FormattedMessage {...messages.RegisterLeave} />
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </React.Fragment>
    );
  };

  renderOneStocks = (animal, i, index) => {
    const type = 'all';
    return (
      <>
        {typeof animal !== 'undefined' && typeof animal.box !== 'undefined' && (
          <StockItem
            key={i}
            editItem={() => this.editItem(type, index)}
            checkAnimal={() => this.checkAnimal(animal, index)}
            removeAnimalFromListChecked={() =>
              this.removeAnimalFromListChecked(animal)
            }
            checked={this.state.listChecked.findIndex(
              (val) => animal.ear_tag === val.ear_tag,
            )}
            data={animal}
            sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
            hande={
              typeof this.props.capacity !== 'undefined'
                ? !(this.props.capacity > this.state.listChecked.length)
                : false
            }
          />
        )}
      </>
    );
  };

  renderListToBeLoadedEartags = () => {
    let listEartags = this.state.stocks;
    this.props.toBeLoadedEartags.length > 0 &&
      // eslint-disable-next-line array-callback-return
      this.props.toBeLoadedEartags.map((c, index) => {
        listEartags = this.state.stocks.filter(
          (tag, i) => tag.ear_tag !== c.ear_tag,
        );
      });
    return listEartags.map((animal, i) => (
      <StockItem
        editItem={() => this.editItem(i)}
        checkAnimal={() => this.checkAnimalTobLoaded(animal)}
        removeAnimalFromListChecked={() =>
          this.removeAnimalFromListChecked(animal)
        }
        key={i}
        checked={this.state.listChecked.findIndex(
          (val) => animal.ear_tag === val.ear_tag,
        )}
        data={animal}
        sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
      />
    ));
  };

  renderOneToBeLoadedEartags = (animal, i, index) => {
    let listEartags = this.state.stocks;
    this.props.toBeLoadedEartags.length > 0 &&
      // eslint-disable-next-line array-callback-return
      this.props.toBeLoadedEartags.map((c, index) => {
        listEartags = this.state.stocks.filter(
          (tag, i) => tag.ear_tag !== c.ear_tag,
        );
      });
    return (
      <>
        {typeof animal !== 'undefined' && typeof animal.box !== 'undefined' && (
          <StockItem
            editItem={() => this.editItem(index)}
            checkAnimal={() => this.checkAnimalTobLoaded(animal)}
            removeAnimalFromListChecked={() =>
              this.removeAnimalFromListChecked(animal)
            }
            key={i}
            checked={this.state.listChecked.findIndex(
              (val) => animal.ear_tag === val.ear_tag,
            )}
            data={animal}
            sellOffer={this.props.sellOffer ? this.props.sellOffer : false}
          />
        )}
      </>
    );
  };

  toggleFilterPopup = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  toggleEartagDetails = () => {
    this.setState({ showEartagDetails: !this.state.showEartagDetails });
  };

  setSelectedEartags = () => {
    const { animateListButton } = this.state;
    this.setState({ animateListButton: !animateListButton });
  };

  handleSelectedAction = (func) => {
    (func === 'createPdf' ||
      func === 'confirmMoveAction' ||
      func === 'getSynchronize' ||
      func === 'createReportDeath' ||
      func === 'createReportLeave' ||
      func === 'changeBox') &&
      this[func]();
  };

  menuItemDisabled = (item) => {
    const { listChecked } = this.state;
    const moreThanItemSelected = [
      'createPdf',
      'confirmMoveAction',
      'changeBox',
    ];
    const oneItemSelected = ['createReportDeath', 'createReportLeave'];
    return (
      (listChecked.length < 1 && moreThanItemSelected.includes(item)) ||
      (listChecked.length !== 1 && oneItemSelected.includes(item))
    );
  };

  handleChangeBox = (event) => {
    let boxSelected = this.state.boxSelected;
    boxSelected.label = event.label;
    boxSelected.value = event.value;

    this.setState({
      boxSelected: boxSelected,
    });
  };

  handleChangeTodesart = (event) => {
    let todesartSelected = this.state.todesartSelected;
    todesartSelected.label = event.label;
    todesartSelected.value = event.value;

    this.setState({
      boxSelected: todesartSelected,
    });
  };


  getOption = () => {
    const { listChecked } = this.state;

    return (
      <ToggleMenuButton
        selectNumber
        title={<FormattedMessage {...messages.Options} />}
        lang={this.props.language.locale}
        widthDeutsch="55%"
        animateListButton={this.animateListButton}
      >
        {menuItems.map((item) => (
          <Dropdown.Item
            key={item.id}
            onClick={() =>
              !this.menuItemDisabled(item.func) &&
              this.handleSelectedAction(item.func)
            }
            disabled={this.menuItemDisabled(item.func)}
          >
            {item.pathOne ? (
              <div className="dropdown-item-svg-icon">
                <SvgIcon size="20" viewBox={item.viewBox}>
                  {item.pathOne}
                  {item.pathTwo && item.pathTwo}
                  {item.pathThree && item.pathThree}
                  {item.pathFour && item.pathFour}
                  {item.pathFive && item.pathFive}
                </SvgIcon>
              </div>
            ) : 'selectedEartagNumber' in item ? (
              <div className="selected-eartag-number">{listChecked.length}</div>
            ) : (
              <div className="icon-text">{item.text}</div>
            )}
            <div className="menu-item-text">{item.title}</div>
          </Dropdown.Item>
        ))}
      </ToggleMenuButton>
    );
  };

  render() {
    const { sellOffer, eartagSelect } = this.props;
    const {
      listChecked,
      showForm,
      stockSelected,
      ear_tag,
      ear_tag_output_format,
      passport,
      box_number,
      openMoveModal,
      raceType,
      BreedType,
      checkedItems,
    } = this.state;
    return (
      <Row className="view-stock ">
        <Modal
          id="addfilter"
          className="search-popup stockM  modal in"
          show={this.state.showFilter}
          onHide={this.toggleFilterPopup}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <Modal.Title />
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="form-Flexto">
              <div className="Filter-Search-Resultsto">
                <FormattedMessage {...messages.FilterSearchResults} />
              </div>
              <div className="Clear-Alll" onClick={this.resetFormFilter}>
                <FormattedMessage {...messages.ClearAll} />
              </div>
            </div>

            <FormGroup>
              <div className="form-Flext">
                <div className="form-FlexTaille">
                  <div className="Race-Class">
                    <FormattedMessage {...messages.NewRace} />
                  </div>
                  <div className="checkboxFlex-Block">
                    <div className="block-flex-race">
                      {raceType.map((item, key) => (
                        <div key={key} className="bloc-ch-div main-check">
                          <input
                            className="bloc-ch "
                            type="checkbox"
                            name={item.race}
                            checked={this.state.checkedItems.get(item.race)}
                            onChange={this.handleCheckboxChange}
                          />
                          <label className="bloc-chLabel Angle" key={item.key}>
                            {item.race}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="form-FlexTaille new-margin">
                  <div className="Race-Class">
                    <FormattedMessage {...messages.NewBreed} />
                  </div>
                  <div className="checkboxFlex-Block">
                    <div className="block-flex-race">
                      {BreedType.map((item, key) => (
                        <div key={key} className="bloc-ch-div main-check">
                          <input
                            className="bloc-ch"
                            type="checkbox"
                            name={item.breed}
                            checked={this.state.checkedItemsBreed.get(
                              item.breed,
                            )}
                            onChange={this.handleCheckboxChangeBreed}
                          />
                          <label className="bloc-chLabel Angle" key={item.key}>
                            {item.breed}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-Flexone">
                <div style={{ width: '40%' }} className="Race-Class">
                  <FormattedMessage {...messages.BoxNumber} />
                </div>
                <div style={{ width: '55%' }}>
                  <RegularInput
                    style={{ width: '100%', margin: '0px' }}
                    select
                    name="box_number"
                    id="state"
                    options={this.state.boxes}
                    className={'selected'}
                    defaultValue={this.state.boxSelected}
                    onChange={this.handleChangeBox}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#F1FFDE',
                        primary: '#7bcd10',
                      },
                    })}
                  />
                </div>
              </div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-save" id="generic-btn-modal">
              <Button className="btn-filter" onClick={this.searchStocksFilter}>
                <FormattedMessage {...messages.Search} />
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <ConfirmMoveToNextBox
          openMoveModal={openMoveModal}
          hideMoveAction={this.hideMoveAction}
          moveToNextBoxConfirm={this.moveToNextBox}
        />
        {
          <Col
            className={clsx(
              'view-stock-in-sell-offer',
              !sellOffer && 'not-sell-offer view-stock-in-sell-offer',
            )}
            md={!sellOffer && 6}
            sm={!sellOffer && 12}
          >
            <Col
              className={`${sellOffer && 'card-list-stock-custom'} ${
                !sellOffer && 'card-box'
              } ${
                sellOffer || this.state.listChecked.length === 0
                  ? sellOffer && this.state.listChecked.length === 0
                    ? 'stockSellB'
                    : 'stockSell'
                  : 'viewStock'
              }`}
            >
              <div
                className={clsx(
                  !sellOffer ? 'top-part-view-stock' : 'sell-offer-box',
                )}
              >
                {eartagSelect && (
                  <div
                    className="navigate-before-stock"
                    onClick={this.props.showSellForm}
                  >
                    <div md={3}>
                      <MdNavigateBefore className="before" />
                    </div>
                  </div>
                )}
                <div className="search-content">
                  <div className="search-content-text " xs={8} md={8}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <MdSearch />
                        </InputGroup.Prepend>
                        <FormControl
                          type="text"
                          minLength={0}
                          maxLength={20}
                          name="search"
                          onChange={this.searchStocksByEarTag}
                          className="search-txt"
                          placeholder={
                            this.props.language.locale === 'DE'
                              ? 'Ohrmarke suchen'
                              : 'Search Eartag'
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <div xs={3} md={3}>
                    <Button
                      onClick={() => {
                        this.toggleFilterPopup();
                      }}
                      className="form-btn btn btn-default"
                      type="button"
                    >
                      <FormattedMessage {...messages.AddFilter} />
                    </Button>
                  </div>
                </div>
                <Row className="well-content">
                  <Col xs={2} md={2}>
                    {this.state.box_search && (
                      <Jumbotron>
                        Box {this.state.box_search}
                        <MdClose onClick={this.clearFilterBox} />
                      </Jumbotron>
                    )}
                  </Col>
                </Row>
                {/* First  */}
                <ListGroupItem className="title-list-content">
                  <div className="view-Stock-line-pri ">
                    <div xs={1} md={1} className=" view-Stock-line-b1 ">
                      <div className="main-checkAll">
                        <input
                          className=" "
                          type="checkbox"
                          checked={this.state.selectAll}
                          onChange={this.handleCheckAll}
                        />
                      </div>
                    </div>
                    <div
                      xs={3}
                      onClick={(e) => {
                        this.getStocksFilter('ear_tag');
                        this.setState({
                          eartagSorted: !this.state.eartagSorted,
                          currentIS: 'eartagSorted',
                          ageSorted: false,
                          raceSorted: false,
                          breedSorted: false,
                          boxSorted: false,
                          arrowA: true,
                        });
                      }}
                      md={3}
                      className={
                        this.state.eartagSorted ||
                        this.state.currentIS === 'eartagSorted'
                          ? 'view-Stock-line-b2  title content-center borderBo'
                          : 'view-Stock-line-b2  title content-center '
                      }
                    >
                      <FormattedMessage {...messages.EarTag} />
                      <span>
                        {this.state.eartagSorted &&
                          this.state.currentIS === 'eartagSorted' && (
                            <img className="imageSort" src={sordIcon} alt="" />
                          )}
                        {!this.state.eartagSorted &&
                          this.state.currentIS === 'eartagSorted' && (
                            <img
                              className="imageSort"
                              src={sordIconTop}
                              alt=""
                            />
                          )}
                      </span>
                    </div>

                    <div
                      xs={2}
                      md={2}
                      onClick={(e) => {
                        this.getStocksFilter('birth_date');
                        this.setState({
                          eartagSorted: false,
                          ageSorted: !this.state.ageSorted,
                          raceSorted: false,
                          breedSorted: false,
                          boxSorted: false,
                          currentIS: 'ageSorted',
                        });
                      }}
                      className="view-Stock-line-b1  title content-center "
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      className={
                        this.state.ageSorted ||
                        this.state.currentIS === 'ageSorted'
                          ? 'view-Stock-line-b3  title content-center borderBo'
                          : 'view-Stock-line-b3  title content-center '
                      }
                    >
                      <FormattedMessage {...messages.Age} />
                      <span>
                        {this.state.ageSorted &&
                          this.state.currentIS === 'ageSorted' && (
                            <img
                              className="imageSorttwo"
                              src={sordIcon}
                              alt=""
                            />
                          )}
                        {!this.state.ageSorted &&
                          this.state.currentIS === 'ageSorted' && (
                            <img
                              className="imageSorttwo"
                              src={sordIconTop}
                              alt=""
                            />
                          )}
                      </span>
                    </div>
                    <div
                      xs={2}
                      md={2}
                      onClick={(e) => {
                        this.getStocksFilter('race');
                        this.setState({
                          eartagSorted: false,
                          ageSorted: false,
                          raceSorted: !this.state.raceSorted,
                          breedSorted: false,
                          boxSorted: false,
                          currentIS: 'raceSorted',
                        });
                      }}
                      className={
                        this.state.raceSorted ||
                        this.state.currentIS === 'raceSorted'
                          ? 'view-Stock-line-b4  title content-center borderBo'
                          : 'view-Stock-line-b4  title content-center '
                      }
                    >
                      <FormattedMessage {...messages.NewRace} />
                      <span>
                        {this.state.raceSorted &&
                          this.state.currentIS === 'raceSorted' && (
                            <img
                              className="imageSorttwo"
                              src={sordIcon}
                              alt=""
                            />
                          )}
                        {!this.state.raceSorted &&
                          this.state.currentIS === 'raceSorted' && (
                            <img
                              className="imageSorttwo"
                              src={sordIconTop}
                              alt=""
                            />
                          )}
                      </span>
                    </div>
                    <div
                      xs={2}
                      md={2}
                      onClick={(e) => {
                        this.getStocksFilter('breed');
                        this.setState({
                          eartagSorted: false,
                          ageSorted: false,
                          raceSorted: false,
                          breedSorted: !this.state.breedSorted,
                          boxSorted: false,
                          currentIS: 'breedSorted',
                        });
                      }}
                      className={
                        this.state.breedSorted ||
                        this.state.currentIS === 'breedSorted'
                          ? 'view-Stock-line-b5  title content-center borderBo'
                          : 'view-Stock-line-b5  title content-center '
                      }
                    >
                      <FormattedMessage {...messages.NewBreed} />
                      <span>
                        {this.state.breedSorted &&
                          this.state.currentIS === 'breedSorted' && (
                            <img className="imageSort" src={sordIcon} alt="" />
                          )}
                        {!this.state.breedSorted &&
                          this.state.currentIS === 'breedSorted' && (
                            <img
                              className="imageSort"
                              src={sordIconTop}
                              alt=""
                            />
                          )}
                      </span>
                    </div>
                    <div
                      xs={1}
                      md={1}
                      onClick={(e) => {
                        this.getStocksFilter('box');
                        this.setState({
                          eartagSorted: false,
                          ageSorted: false,
                          raceSorted: false,
                          breedSorted: false,
                          boxSorted: !this.state.boxSorted,
                          currentIS: 'boxSorted',
                        });
                      }}
                      className={
                        this.state.boxSorted ||
                        this.state.currentIS === 'boxSorted'
                          ? 'view-Stock-line-b6  title content-center borderBo'
                          : 'view-Stock-line-b6  title content-center '
                      }
                    >
                      <FormattedMessage {...messages.Box} />
                      <span>
                        {this.state.boxSorted &&
                          this.state.currentIS === 'boxSorted' && (
                            <img
                              className="imageSorttwo"
                              src={sordIcon}
                              alt=""
                            />
                          )}
                        {!this.state.boxSorted &&
                          this.state.currentIS === 'boxSorted' && (
                            <img
                              className="imageSorttwo"
                              src={sordIconTop}
                              alt=""
                            />
                          )}
                      </span>
                    </div>
                  </div>
                </ListGroupItem>
                {/* end of */}
                <ListGroup
                  id="contentStock"
                  className={this.state.newBlock ? 'list-groupp' : 'list-group'}
                >
                  <div style={{ width: '100%', height: '100%' }}>
                    <ListGroup style={{ width: '100%', height: '100%' }}>
                      <AutoSizer>
                        {({ width, height }) => (
                          <List
                            width={width}
                            height={height}
                            deferredMeasurementCache={this.cache}
                            rowHeight={this.cache.rowHeight}
                            rowCount={this.state.allStocks.length}
                            overscanRowCount={3}
                            showScrollingPlaceholder={false}
                            useDynamicRowHeight={false}
                            rowRenderer={({ key, index, style, parent }) => {
                              const stock = this.state.allStocks[index];
                              return (
                                <CellMeasurer
                                  key={key}
                                  cache={this.cache}
                                  columnIndex={0}
                                  rowIndex={index}
                                  parent={parent}
                                >
                                  <div
                                    style={{
                                      ...style,
                                      height: '180',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {!eartagSelect &&
                                      this.state.allStocks.length > 0 &&
                                      this.renderOneStocks(stock, key, index)}
                                    {eartagSelect &&
                                      // this.props.toBeLoadedEartags.length > 0 &&
                                      this.renderOneToBeLoadedEartags()}
                                  </div>
                                </CellMeasurer>
                              );
                            }}
                          />
                        )}
                      </AutoSizer>
                    </ListGroup>
                  </div>
                </ListGroup>

                {listChecked.length > 0 && (
                  <div className="selected-stock-item">
                    <div className="selected-stock-item-b1">
                      <hr className="style-border" />

                      <div className="selected-flex">
                        <div className="Eartag-selected ">
                          ({listChecked.length}){' '}
                          <FormattedMessage {...messages.SelectedAnimals} />{' '}
                        </div>
                        <div
                          className="Eartag-selected "
                          onClick={() => {
                            this.removeAllAnimalFromListChecked();
                          }}
                        >
                          <FormattedMessage {...messages.DeselectAll} />
                        </div>
                      </div>
                    </div>
                    <div className="selected-stock-item-v">
                      <div style={{ width: '100%', height: '100%' }}>
                        <div
                        style={{
                          minHeight:200
                        }}
                        >
                            <List
                              width={500}
                              height={600}
                              deferredMeasurementCache={this.cache}
                              rowHeight={this.cache.rowHeight}
                              rowCount={this.state.displayChecked.length}
                              overscanRowCount={3}
                              showScrollingPlaceholder={false}
                              useDynamicRowHeight={false}
                              rowRenderer={({ key, index, style, parent }) => {
                                const stock = this.state.displayChecked[index];
                                return (
                                  <CellMeasurer
                                    key={key}
                                    cache={this.cache}
                                    columnIndex={0}
                                    rowIndex={index}
                                    parent={parent}
                                  >
                                    <div
                                      style={{
                                        ...style,
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {this.renderListChecked(
                                        stock,
                                        key,
                                        index,
                                      )}
                                    </div>
                                  </CellMeasurer>
                                );
                              }}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {eartagSelect && (
                  <NormalButton
                    className={
                      listChecked.length > 0
                        ? 'view-stock-button select-confirm'
                        : 'view-stock-button'
                    }
                    onClick={() =>
                      this.props.showSellForm(listChecked, showForm)
                    }
                  >
                    <FormattedMessage {...messages.ConfirmButton} />
                  </NormalButton>
                )}
              </div>

              <Row />
              {!sellOffer && (
                <div
                  id="view-stock-dropdown"
                  className="not-sell-offer bottom-part-view-stock"
                >
                  {this.getOption()}
                </div>
              )}
            </Col>
          </Col>
        }
        {sellOffer && (
          <div className="bottom-part-view-stock custom-btn">
            {listChecked.length === 0 && !eartagSelect && (
              <NormalButton
                disabled
                onClick={() => this.props.showSellForm(listChecked, showForm)}
              >
                <FormattedMessage {...messages.ConfirmButton} />
              </NormalButton>
            )}
            {listChecked.length > 0 && !eartagSelect && (
              <NormalButton
                // active
                onClick={() => this.props.showSellForm(listChecked, showForm)}
              >
                <FormattedMessage {...messages.ConfirmButton} />
              </NormalButton>
            )}
          </div>
        )}
        {this.state.showForm && !this.props.sellOffer && (
          // ||
          // (this.props.showDetailStock && detailStock)
          <Col style={{ height: '98%', width: '49%' }} md={6} sm={12}>
            <Form
              token={this.props.users.accessToken}
              showAlert={(msg) => this.props.error(msg)}
              setShowForm={this.setShowForm}
              data={stockSelected}
              passport={passport}
              ear_tag={ear_tag}
              ear_tag_output_format={ear_tag_output_format}
              setShowFormAllStock={this.setShowFormAllStock}
            />
          </Col>
        )}
        <div>{this.state.showDeath && this.renderDeath()}</div>
        <div>{this.state.showLeave && this.renderLeave()}</div>
        <div>{this.state.changeBoxModal && this.renderBoxChangeModal()}</div>
      </Row>
    );
  }
}

ViewStock.propTypes = {
  users: PropTypes.object,
  renderListStocks: PropTypes.func,
  sendToHit: PropTypes.func,
  choseType: PropTypes.func,
  factoryEarTagInverse: PropTypes.func,
  editItem: PropTypes.func,
  addToListToHit: PropTypes.func,
  setShowForm: PropTypes.func,
  getStockFromHit: PropTypes.func,
  handleChangeEarTag: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    setStocks: (page, stocksPagination, block_api, stocks) =>
      dispatch(
        stocksActions.setStocks(page, stocksPagination, block_api, stocks),
      ),
    resetStock: () => dispatch(stocksActions.resetStock()),
  };
}

const mapStateToProps = (state) => {
  const { users, allstock, language } = state.toJS();
  return { users, allstock, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewStock);
