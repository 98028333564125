import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import greenLoc from '../../../../static/images/admin/icons_final/marker.png';
import grayLoc from '../../../../static/images/admin/icons_final/marker_gray.png';
import arrowImg from '../../../../static/images/admin/arrow-copie-2.png';
import { Modal } from 'react-bootstrap';
import clsx from 'clsx';
import './tour.css';
import searchImg from '../../../../static/images/admin/search.png';
import { connect } from 'react-redux';
import { alertActions } from '../../../../actions';

import { getDocCompany } from '../../../../services/company.service';

class LocationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departureTime: '',
      showPopup: false,
      showPopupCertificate: false,
      certificateList: [],
      certifcatetype: 'picture',
    };
  }
  renderDocument = (documentType) => {
    if (documentType === 'DELIVERY_NOTE') {
      return <FormattedMessage {...messages.DeliveryNote} />;
    } else if (documentType === 'STANDARD_DECLARATION') {
      return <FormattedMessage {...messages.StandardDeclaration} />;
    } else if (documentType === 'BIO_CERTIFICATE') {
      return <FormattedMessage {...messages.BioQualityprograms} />;
    } else {
      return <FormattedMessage {...messages.BioNote} />;
    }
  };

  handleCloseCertificate = () => {
    this.setState({ showPopupCertificate: false });
  };

  openPopUpCertificate = () => {
    this.setState({ showPopupCertificate: true });
  };

  openPopUpCertificate = () => {
    this.setState({ showPopupCertificate: true });
  };

  getEncodedCertificate = async (certificateId, companyId) => {
    let img = await await getDocCompany(
      companyId,
      this.props.users.accessToken,
      certificateId,
    );

    if (img.substring(0, 25).includes('png') === true) {
      this.setState({ certifcatetype: 'picture' });
      this.setState({ certifcate: 'data:image/png;base64' + img });
    } else if (img.substring(0, 25).includes('jpeg')) {
      this.setState({ certifcatetype: 'picture' });
      this.setState({ certifcate: 'data:image/jpeg;base64' + img });
    } else if (img.substring(0, 25).includes('pdf')) {
      this.setState({ certifcatetype: 'pdf' });
      this.setState({ certifcate: 'data:application/pdf;base64' + img });
    }
  };

  renderDocForm = (item) => {
    return (
      <div className="tourInfo-title-btnscroll">
        {typeof item !== 'undefined' &&
          item.loading_data_documents.map((doc) =>
            doc.type !== 'BIO_CERTIFICATE' ? (
              <div key={doc.file_id} className="tourInfo-title-pf doc-types">
                <div className="tourInfo-title-btn">
                  <div className="text">{this.renderDocument(doc.type)}</div>
                </div>
                <div
                  className="icon-eartag"
                  onClick={() => {
                    this.props.displayPDF(doc.file_id);
                    this.openPopUp();
                  }}
                ></div>
              </div>
            ) : (
              (this.props.users.user.session.company_type === '3' ||
                this.props.users.user.session.company_type === '2') && (
                <div key={doc.file_id} className="tourInfo-title-pf doc-types">
                  <div className="tourInfo-title-btn">
                    <div className="text">{this.renderDocument(doc.type)}</div>
                  </div>
                  <div
                    className="icon-eartag"
                    onClick={() => {
                      this.openPopUpCertificate();
                      this.getEncodedCertificate(
                        doc.certificate_id,
                        doc.companyId,
                      );
                    }}
                  ></div>
                </div>
              )
            ),
          )}
      </div>
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    // eslint-disable-next-line default-case
    switch (name) {
      case 'departureTime':
        this.setState({ departureTime: value });

        break;
    }
  };

  handleChangeDate = () => {
    this.props.changeDe(this.state.departureTime);
    this.props.GetKey(this.props.item._id);
  };

  ColorIcon = () => {
    if (typeof this.props.item.status !== 'undefined') {
      if (
        this.props.item.status === 'LOADED' ||
        this.props.item.status === 'TO_BE_LOADED'
      ) {
        return 'tourInfo-title-span-texttwo';
      } else {
        return 'tourInfo-title-span-text';
      }
    }
  };
  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  renderLocForm = () => {
    return (
      <div key={this.props.item.key}>
        <div className="tourInfo-title-pf target-loc-times">
          <div className="tourInfo-title-b1-form"></div>
          <div className="tourInfo-title-btn-form">
            <div className="tourInfo-title-btn-form-row">
              <div
                className={clsx(
                  'dep-arr-text',
                  !this.props.edit && 'tourInfo-edit',
                )}
              >
                <FormattedMessage {...messages.DepartureTime} />{' '}
              </div>
              <div className="dep-arr-time-input">
                <input
                  type="time"
                  className="form-input"
                  id="departureTime"
                  name="departureTime"
                  onChange={this.handleChange}
                  onBlur={this.handleChangeDate}
                  value={
                    this.props.item.departure_time
                      ? this.props.item.departure_time
                      : this.state.departureTime
                  }
                  disabled={this.props.edit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      idDoc,
      id,
      showDoc,
      showLocForm,
      showLocForms,
      showMoreOptions,
      item,
      loc,
      truckStatus,
    } = this.props;
    return (
      <div className="location-item">
        <Modal
          show={this.state.showPopupCertificate === true}
          onHide={this.handleCloseCertificate}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          {this.state.certifcatetype === 'pdf' ? (
            <Modal.Body className="modal-pdf ">
              <embed
                width="100%"
                height="90%"
                frameBorder="0"
                src={this.state.certifcate}
                type="application/pdf"
              />
            </Modal.Body>
          ) : (
            <Modal.Body>
              <img
                style={{ objectFit: 'cover' }}
                width="100%"
                height="100%"
                src={this.state.certifcate}
              />
            </Modal.Body>
          )}
        </Modal>
        <Modal
          show={this.state.showPopup === true}
          onHide={this.handleClose}
          style={{ opacity: 1 }}
          id="modaldoc"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdf ">
            <embed
              width="100%"
              height="94%"
              frameBorder="0"
              src={this.props.pdfEncoded}
              type="application/pdf"
            />
          </Modal.Body>
        </Modal>
        <div key={item.key} className="tourInfo-title">
          <div className="tourInfo-title-b1">
            <Image
              className="status-image-icon-marker"
              src={
                item.departure_time !== '' ||
                item.status === 'PLANNED' ||
                item.status === 'UNLOADED'
                  ? greenLoc
                  : grayLoc
              }
            />
          </div>
          <div className="tourInfo-title-btn tour-info-list-cattle">
            <div className={this.ColorIcon()}>
              {this.props.item.departure_time
                ? this.props.item.departure_time
                : this.props.item.pick_up_time}{' '}
              {this.props.item.seller}
            </div>
            <div className="location-address">
              {typeof item.address !== 'undefined' && item.address.street},{' '}
              {typeof item.address !== 'undefined' && item.address.zip_code}{' '}
              {typeof item.address !== 'undefined' && item.address.city}
            </div>
          </div>
          {truckStatus !== 'PLANNED' && !this.props.edit && (
            <div
              onClick={() =>
                loc === '' ? showMoreOptions(item._id) : showLocForms(item._id)
              }
              className="arrow-down-more"
            >
              <Image className="" src={arrowImg} />
            </div>
          )}
        </div>
        {showDoc &&
          loc === '' &&
          idDoc === item._id &&
          this.renderDocForm(item)}
        {!this.props.edit &&
          loc !== '' &&
          !showLocForm &&
          this.renderLocForm(item)}
        {/* <hr className="style-border" /> */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationItem);
