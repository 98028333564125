/* eslint-disable */
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  ListGroupItem,
  Image,
  Card,
  ButtonGroup,
  Button,
  FormGroup,
  ListGroup,
  MenuItem,
} from 'react-bootstrap';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import clsx from 'clsx';
import { OrderService } from '../../../services/order.service';
import extractData from '../../../services/data.processing';
import TourInfoDirect from '../Orders/tourInfo/TourInfoDirect';
import { Redirect } from 'react-router-dom';
import '../Orders/orders.css';

import Bought from '../../../static/images/admin/Icons/Status icons/Bought.png';
import Delivered from '../../../static/images/admin/Icons/Status icons/Delivered.png';
import Loaded from '../../../static/images/admin/Icons/Status icons/Loaded.png';
import NegotiationBuyRequest from '../../../static/images/admin/Icons/Status icons/NegotiationBuyRequest.png';
import NegotiationSaleOffer from '../../../static/images/admin/Icons/Status icons/NegotiationSaleOffer.png';
import Offer from '../../../static/images/admin/Icons/Status icons/Offer.png';
import Offered from '../../../static/images/admin/Icons/Status icons/Offered.png';
import Ontheway from '../../../static/images/admin/Icons/Status icons/Ontheway.png';
import Rejected from '../../../static/images/admin/Icons/Status icons/Rejected.png';
import Request from '../../../static/images/admin/Icons/Status icons/Request.png';
import Requested from '../../../static/images/admin/Icons/Status icons/Requested.png';
import Resold from '../../../static/images/admin/Icons/Status icons/Resold.png';
import Slaughered from '../../../static/images/admin/Icons/Status icons/Slaughered.png';
import Sold from '../../../static/images/admin/Icons/Status icons/Sold.png';
import Tobeaccepted from '../../../static/images/admin/Icons/Status icons/Tobeaccepted.png';
import Tobeaccepted2 from '../../../static/images/admin/Icons/Status icons/Tobeaccepted2.png';
import Tobeloaded from '../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import Tobereconfirmed from '../../../static/images/admin/Icons/Status icons/Tobereconfirmed.png';
import Tobereconfirmed2 from '../../../static/images/admin/Icons/Status icons/Tobereconfirmed2.png';
import Tourplanning from '../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import Unloaded from '../../../static/images/admin/Icons/Status icons/Unloaded.png';
import TourInfo from '../Orders/tourInfo';

class TourInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      width: window.innerWidth,
      show: true,
      details: {},
      orderDetails: {},
      orderId: this.props.match.params.orderId,
      truckId: this.props.match.params.truckId,
      truck: {},
      trucks: [],
      truckDetail: {},
      showPassportList: false,
      showTitle: true,
      showTourInfo: false,
      showDetail: false,
      loaded: false,
    };
  }

  getSrcImg = (status, receivers_size, topic, is_buyer) => {
    var res = status.split('_').join('');
    res = res.toLowerCase();
    switch (res) {
      case 'bought':
        return Bought;
        break;
      case 'canceled':
        return Rejected;
        break;
      case 'delivered':
        return Delivered;
        break;
      case 'loaded':
        return Loaded;
        break;
      case 'negotiationbuyrequest':
        return NegotiationBuyRequest;
        break;
      case 'negotiation':
        return NegotiationBuyRequest;
        break;
      case 'negotiationsaleoffer':
        return NegotiationSaleOffer;
        break;
      case 'offer':
        return Offer;
        break;
      case 'offered':
        if (receivers_size > 1) return NegotiationBuyRequest;
        else return Offered;
        break;
      case 'ontheway':
        return Ontheway;
        break;
      case 'rejected':
        return Rejected;
        break;
      case 'request':
        return Requested;
        break;
      case 'requested':
        return Requested;
        break;
      case 'resold':
        return Resold;
        break;
      case 'slaughtered':
        return Slaughered;
        break;
      case 'sold':
        return Sold;
        break;
      case 'tobeaccepted':
        if (
          topic === 'SELL_STOCK' ||
          (topic === 'RESALE_STOCK' && is_buyer === 0)
        )
          return Tobeaccepted;
        else return Tobeaccepted2;
        break;
      case 'tobeloaded':
        return Tobeloaded;
        break;
      case 'tobereconfirmed':
        if (
          topic === 'SELL_STOCK' ||
          (topic === 'RESALE_STOCK' && is_buyer === 0)
        )
          return Tobereconfirmed;
        else return Tobereconfirmed2;
        break;
      case 'tourplanning':
        return Tourplanning;
        break;
      case 'tourassigned':
        return Tourplanning;
        break;
      case 'unloaded':
        return Unloaded;
        break;
    }
  };

  componentDidMount() {
    if (typeof this.state.orderId !== 'undefined') {
      this.getToursDetails(this.state.orderId);
      this.getOrderDetails(this.state.orderId);
      setTimeout(
        function () {
          //Start the timer
          this.setState({ loaded: true }); //After 2 second, set render to true
        }.bind(this),
        2000,
      );
    }
  }

  UNSAFE_componentWillMount() {
    if (typeof this.state.orderId !== 'undefined') {
      this.getToursDetails(this.state.orderId);
      this.getOrderDetails(this.state.orderId);
    }
  }

  getToursDetails = async (item) => {
    let token = await this.props.users.accessToken;
    let value = await OrderService.getTours(
      token,
      this.state.orderId,
      this.state.truckId,
      this.props.users
    );
    if (value.status === 200) {
      const data = await extractData(value);
      if (typeof data[0] !== 'undefined') {
        await this.setState({
          orderDetails: data,
        });
      }
    }
  };

  getOrderDetails = async (orderId) => {
    // let token = await this.props.users.accessToken;
    // let value = await OrderService.getOrderForOneBidder(
    //   orderId,
    //   token,
    // );
    // let data = await extractData(value);
    // data.order.order_id= await this.state.orderId;
    // data.order.receiver_id= await this.state.receiverId;
    // await this.setState({
    //   details: data,
    //   orderDetails: data.order
    // });
  };

  handleClosePanel = () => {};

  getOrders = () => {};

  handleCloseToBeload = () => {
    window.open('/admin/home', '_self');
  };
  getOrders = () => {};

  emptyTruck = () => {
    var props = Object.keys(this.state.truck);
    for (var i = 0; i < props.length; i++) {
      delete this.state.truck[props[i]];
    }
    this.setState({ truck: Object.assign({}) });
  };

  showPassportForTourInfo = (value) => {
    this.setState({ showPassportList: value });
  };

  showDetailForTour = (truck, truckDetail) => {
    this.setState({
      showTourInfo: false,
      showDetail: true,
      truck,
      truckDetail,
    });
  };

  emptyTruck = () => {
    var props = Object.keys(this.state.truck);
    for (var i = 0; i < props.length; i++) {
      delete this.state.truck[props[i]];
    }
    this.setState({ truck: Object.assign({}) });
  };

  showTitle = () => {
    this.setState({ showTitle: false });
  };

  renderListStocks = () => {
    let orderDetail = { order_id: this.state.orderId };
    let truckDetails = { _id: this.state.truckId };
    return (
      <Row className="">
        <TourInfo
          orderDetail={orderDetail}
          companyId={this.props.users.user.session.company_id}
          handleClosePanel={this.handleClosePanel}
          showDetailForTour={this.showDetailForTour}
          // showPassportList={showPassportList}
          showPassportForTourInfo={this.showPassportForTourInfo}
          // showPassports={showPassports}
          truckId={truckDetails}
          toursDetails={this.state.toursDetails}
          getOrders={this.getOrders}
        />
      </Row>
    );
  };

  render() {
    let renderContainer = false; //By default don't render anything
    if (this.state.loaded) {
      //If this.state.render == true, which is set to true by the timer.
      renderContainer = <div>{this.renderListStocks()}</div>; //Add dom elements
    } else {
      renderContainer = (
        <div>
          waiting ...
          <div key={0} className="loader-wrapper">
            <div className="loader" key={0}></div>
          </div>
        </div>
      ); //Add dom elements
    }
    return renderContainer; //Render the dom elements, or, when this.state == false, nothing.
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourInformation);
