import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../static/images/logo.png';
import NavContent from './NavContent';
import messages from '../../../components/Admin/messages';
// import { getCompany } from '../../../services/company.service';
import { tab_role_enter_my_stock, specificRoles } from '../../../constants/';
import { connect } from 'react-redux';

const validPathForSideMenu = [
  '/admin/new-stock',
  '/admin/view-stock',
  '/admin/drug-administration',
  '/admin/buy-stock',
  '/admin/sell-stock',
  '/admin/eartag-search',
  '/admin/live-stock',
  '/admin/my-tours',
  '/admin/offer-tours',
  '/admin/live-stock',
  '/admin/search-tours',
  '/admin/manage-tours',
  '/admin/slaughter-report',
  '/admin/reports',
  '/admin/setting',
  '/admin/invoices',
  '/admin/contact',
  '/admin/messages',
  '/admin/buyings-overview',
  '/admin/choose-subscription',
  '/admin/tourPlanningProducer',
];

const Side = ({ users, width, changeHeaderTitle, match, subscription_type }) => {
  const subscriptionType = subscription_type;
  const companyType = users.user.session.company_type;
  let linkSideMenu = [
    {
      index: 3,
      className: 'addStock',
      path: '/admin/new-stock',
      text: <FormattedMessage {...messages.HomeNewStock} />,
    },
    {
      index: 4,
      className: 'viewStock-menu',
      path: '/admin/view-stock',
      text: <FormattedMessage {...messages.HomeViewStock} />,
    },
    {
      index: 5,
      className: 'myBuying',
      path: '/admin/buy-stock',
      text: <FormattedMessage {...messages.HomeBuyings} />,
    },
    {
      index: 6,
      className: 'mySelings',
      path: '/admin/sell-stock',
      text: <FormattedMessage {...messages.HomeSellings} />,
    },
    // {
    //   index: 7,
    //   className: 'drug',
    //   path:
    //     subscriptionType === 'free' && companyType === '1'
    //       ? '/admin/choose-subscription'
    //       : '/admin/drug-administration',
    //   text: <FormattedMessage {...messages.HomeDrugAdministration} />,
    // },
    {
      index: 8,
      className: 'search',
      path:
        subscriptionType === 'free' && companyType === '1'
          ? '/admin/choose-subscription'
          : '/admin/reports',
      text: <FormattedMessage {...messages.HomeReportsSearch} />,
    },
  ];
  let linkSideMenu_agr_worker = [
    {
      index: 3,
      className: 'addStock',
      path: '/admin/new-stock',
      text: <FormattedMessage {...messages.HomeNewStock} />,
    },
    {
      index: 4,
      className: 'viewStock-menu',
      path: '/admin/view-stock',
      text: <FormattedMessage {...messages.HomeViewStock} />,
    },
    {
      index: 7,
      className: 'manageTours',
      path: '/admin/manage-tours',
      text: <FormattedMessage {...messages.HomeManageTours} />,
    },
  ];

  let linkSideMenuTrader = [
    {
      index: 3,
      className: 'addStock',
      path: '/admin/new-stock',
      text: <FormattedMessage {...messages.HomeNewStock} />,
    },
    {
      index: 4,
      className: 'viewStock-menu',
      path: '/admin/view-stock',
      text: <FormattedMessage {...messages.HomeViewStock} />,
    },
    {
      index: 5,
      className: 'myBuying',
      path: '/admin/buy-stock',
      text: <FormattedMessage {...messages.HomeBuyings} />,
    },
    {
      index: 6,
      className: 'mySelings',
      path: '/admin/sell-stock',
      text: <FormattedMessage {...messages.HomeSellings} />,
    },
    {
      index: 7,
      className: 'manageTours',
      path: '/admin/manage-tours',
      text: <FormattedMessage {...messages.HomeManageTours} />,
    },
    {
      index: 8,
      className: 'search',
      path: '/admin/reports',
      text: <FormattedMessage {...messages.HomeSearchLom} />,
    },
    {
      index: 9,
      className: 'tourPlanningProducer',
      path: '/admin/tourPlanningProducer',
      text: <FormattedMessage {...messages.HomeManageViehanmeldung} />,
    },
    {
      index: 10,
      className: 'slaughterReport',
      path: '/admin/slaughter-report',
      text: <FormattedMessage {...messages.slaughter_report} />,
    },
  ];

  let linkSideMenuTraderProducer = [
    {
      index: 3,
      className: 'addStock',
      path: '/admin/new-stock',
      text: <FormattedMessage {...messages.HomeNewStock} />,
    },
    {
      index: 4,
      className: 'viewStock-menu',
      path: '/admin/view-stock',
      text: <FormattedMessage {...messages.HomeViewStock} />,
    },
    {
      index: 5,
      className: 'myBuying',
      path: '/admin/buy-stock',
      text: <FormattedMessage {...messages.HomeBuyings} />,
    },
    {
      index: 6,
      className: 'mySelings',
      path: '/admin/sell-stock',
      text: <FormattedMessage {...messages.HomeSellings} />,
    },
    {
      index: 7,
      className: 'manageTours',
      path: '/admin/manage-tours',
      text: <FormattedMessage {...messages.HomeManageTours} />,
    },
    {
      index: 8,
      className: 'search',
      path: '/admin/reports',
      text: <FormattedMessage {...messages.HomeSearchLom} />,
    }
  ];

  let linkSideMenuTrader_drv = [
    {
      index: 7,
      className: 'manageTours',
      path: '/admin/manage-tours',
      text: <FormattedMessage {...messages.HomeManageTours} />,
    },
  ];
  let linkSideMenuTrader_off_wor = [
    {
      index: 3,
      className: 'addStock',
      path: '/admin/new-stock',
      text: <FormattedMessage {...messages.HomeNewStock} />,
    },
    {
      index: 4,
      className: 'viewStock-menu',
      path: '/admin/view-stock',
      text: <FormattedMessage {...messages.HomeViewStock} />,
    },

    {
      index: 7,
      className: 'manageTours',
      path: '/admin/manage-tours',
      text: <FormattedMessage {...messages.HomeManageTours} />,
    },
    {
      index: 8,
      className: 'search',
      path: '/admin/reports',
      text: <FormattedMessage {...messages.HomeSearchLom} />,
    },
  ];

  let linkSideMenuAbatoire = [
    {
      index: 3,
      className: 'myBuying',
      path: '/admin/buy-stock',
      text: <FormattedMessage {...messages.HomeDeliveryStock} />,
    },
    {
      index: 4,
      className: 'search',
      path: '/admin/eartag-search',
      text: <FormattedMessage {...messages.HomeSearchEarTag} />,
    },
    {
      index: 5,
      className: 'slaughterReport',
      path: '/admin/slaughter-report',
      text: <FormattedMessage {...messages.slaughter_report} />,
    },
    {
      index: 6,
      className: 'buyingsOverview',
      path: '/admin/buyings-overview',
      text: <FormattedMessage {...messages.buyings_overview} />,
    },
  ];

  let linkSideMenuVet = [
    {
      index: 3,
      className: 'live',
      path: '/admin/live-stock',
      text: <FormattedMessage {...messages.HomeLiveStock} />,
    },
    {
      index: 4,
      className: 'search',
      path: '/admin/reports',
      text: <FormattedMessage {...messages.HomeSearchEarTag} />,
    },
  ];
  let linkSideMenuTransportor = [
    {
      index: 3,
      className: 'manageTours',
      path: '/admin/my-tours',
      text: <FormattedMessage {...messages.HomeMyTours} />,
    },
    {
      index: 4,
      className: 'search',
      path: '/admin/reports',
      text: <FormattedMessage {...messages.RequestTours} />,
    },
  ];

  const links_abt_emp = [
    {
      index: 0,
      path: '/admin/home',
      text: <FormattedMessage {...messages.HomeSideMessage} />,
    },
    {
      index: 1,
      path: '/admin/setting',
      text: <FormattedMessage {...messages.SettingMessage} />,
    },
  ];
  const link_abt_per = [
    {
      index: 0,
      path: '/admin/home',
      text: <FormattedMessage {...messages.HomeSideMessage} />,
    },
    {
      index: 1,
      path: '/admin/setting',
      text: <FormattedMessage {...messages.SettingMessage} />,
    },
    {
      index: 2,
      path: '/admin/contact',
      text: <FormattedMessage {...messages.MyContact} />,
    },
    {
      index: 3,
      path: '/admin/messages',
      text: <FormattedMessage {...messages.MessageArchive} />,
    },
  ];
  const links = [
    {
      index: 0,
      path: '/admin/home',
      text: <FormattedMessage {...messages.HomeSideMessage} />,
    },
    {
      index: 1,
      path: '/admin/setting',
      text: <FormattedMessage {...messages.SettingMessage} />,
    },
    {
      index: 2,
      path: '/admin/contact',
      text: <FormattedMessage {...messages.MyContact} />,
    },
    {
      index: 3,
      path: '/admin/messages',
      text: <FormattedMessage {...messages.MessageArchive} />,
    },
    {
      index: 4,
      path: '/admin/invoices',
      text: <FormattedMessage {...messages.Invoicing} />,
    },
  ];

  let location = useLocation();

  const selectLinks = (index, title) => {
    changeHeaderTitle(title);
  };

  const mainMenu =  () => {
    const companyType = users.user.session.company_type;
    let { role } = users.user;
    let  subscription_type  = subscription_type;
    // let token = users.user.accessToken;
    // const value = await getCompany(users.user.session.company_id, token);

    let linksToParse = links;
    if (specificRoles.ROLE_FOR_SIDE_MENUE_MEDIUM.indexOf(role) > -1) {
      linksToParse = links_abt_emp;
    }
    if (specificRoles.ROLE_FOR_ROLE_TRD_OFFICE_WORKER.indexOf(role) > -1) {
      linksToParse = link_abt_per;
    }
    if (
      specificRoles.ROLE_FOR_SIDE_MENUE_REDUCED.indexOf(role) > -1 ||
      subscription_type === 'free'
    ) {
      linksToParse = link_abt_per;
    }
    // remove invoice tab from companies with type in [4, 5, 6]
    if (['4', '5', '6'].indexOf(companyType) === 0) {
      linksToParse.pop();
    }

    return linksToParse.map((link, i) => (
      <NavContent
        text={link.text}
        path={link.path}
        key={i}
        index={link.index}
        active={link.path.includes(match.params.path)}
        selectLinks={selectLinks}
      />
    ));
  };

  const sideMenu = (company_type, role) => {
    if (tab_role_enter_my_stock.indexOf(role) === -1) {
      linkSideMenu = linkSideMenu.filter((e) => e.index !== 3);
    }

    // eslint-disable-next-line default-case
    switch (company_type) {
      case '1':
        let agrLinks;
        if (role === 'ROLE_AGR_STABLE_WORKER') {
          agrLinks = linkSideMenu_agr_worker;
        } else {
          agrLinks = linkSideMenu;
        }
        return agrLinks.map((link, i) => (
          <NavContent
            key={i}
            text={link.text}
            path={link.path}
            index={link.index}
            className={link.className}
            active={link.path.includes(match.params.path)}
            selectLinks={selectLinks}
          />
        ));
        // eslint-disable-next-line no-unreachable
        break;
      case '2':
        let traderLinks;
        if (role === 'ROLE_TRD_DRIVER') {
          traderLinks = linkSideMenuTrader_drv;
        }
         else if (role === 'ROLE_TRD_OFFICE_WORKER') {
          traderLinks = linkSideMenuTrader;
        } 
        else {
         if(users.user.is_producer ===  1) {
            traderLinks = linkSideMenuTrader;
           }else {
             traderLinks =  linkSideMenuTraderProducer
         }

        }
        return traderLinks.map((link, i) => (
          <NavContent
            key={i}
            text={link.text}
            path={link.path}
            index={link.index}
            className={link.className}
            active={link.path.includes(match.params.path)}
            selectLinks={selectLinks}
          />
        ));
        // eslint-disable-next-line no-unreachable
        break;
      case '3':
        return linkSideMenuAbatoire.map((link, i) => (
          <NavContent
            key={i}
            text={link.text}
            path={link.path}
            index={link.index}
            className={link.className}
            active={link.path.includes(match.params.path)}
            selectLinks={selectLinks}
          />
        ));
        // eslint-disable-next-line no-unreachable
        break;
      case '4':
        return linkSideMenuTransportor.map((link, i) => (
          <NavContent
            key={i}
            text={link.text}
            path={link.path}
            index={link.index}
            className={link.className}
            active={link.path.includes(match.params.path)}
            selectLinks={selectLinks}
          />
        ));
        // eslint-disable-next-line no-unreachable
        break;
      case '5':
        return linkSideMenuVet.map((link, i) => (
          <NavContent
            key={i}
            text={link.text}
            path={link.path}
            index={link.index}
            className={link.className}
            active={link.path.includes(match.params.path)}
            selectLinks={selectLinks}
          />
        ));
        // eslint-disable-next-line no-unreachable
        break;
    }
  };

  const company_type = users.user.session.company_type;
  const role = users.user.role;
  return (
    <div id="sidebar" className="sidebar top-bar">
      <div className="logo">
        <Link to="/admin/home" className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="logo_image" />
          </div>
        </Link>
      </div>
      <div className="sidebar-wrapper">
        <ul className={width >= 992 ? 'nav-top pull-right' : 'nav-top'}>
          {mainMenu()}
        </ul>
        <ul className={width >= 992 ? 'nav  pull-right' : 'nav nav-bottom'}>
          {validPathForSideMenu.indexOf(location.pathname) !== -1 &&
            sideMenu(company_type, role)}
        </ul>
        <br />
      </div>
    </div>
  );
};

Side.propTypes = {
  users: PropTypes.object,
  subscription_type: PropTypes.any
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(Side);
