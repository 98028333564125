exports.isVETDetailsChanged = (oldVet, newVet) => {
  return (
    oldVet.veterinary_address !== newVet.veterinary_address ||
    oldVet.veterinary_city !== newVet.veterinary_city ||
    oldVet.veterinary_firstname !== newVet.veterinary_firstname ||
    oldVet.veterinary_lastname !== newVet.veterinary_lastname ||
    oldVet.veterinary_practitioner !== newVet.veterinary_practitioner ||
    oldVet.veterinary_zip_code !== newVet.veterinary_zip_code
  );
};
