/*
 *
 * LanguageProvider actions
 *
 */

import { CHANGE_LOCALE } from './constants';
import { userService } from '../../services/user.service';

export function changeLocale(languageLocale) {
  return async (dispatch, getState) => {
    try {
      const token = getState().toJS().users.accessToken;
      if (token) {
        const deviceId = getState().toJS().users.device._id;
        const userId = getState().toJS().users.user._id;
        // change langauage
        userService.updateLanguage(
          token,
          userId,
          deviceId,
          languageLocale.toLowerCase(),
        );
      }

      localStorage.setItem('language', languageLocale);
      await dispatch({
        type: CHANGE_LOCALE,
        locale: languageLocale,
      });
    } catch (error) {}
  };
}
