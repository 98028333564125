import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';
import { Col, Card, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { MdNavigateBefore } from 'react-icons/md';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import { svgPaths } from '../../../../constants';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import { applyDrag, generateItems } from './utils';
import { alertActions, OrderActions } from '../../../../actions';
import { OrderService } from '../../../../services/order.service';
import { getCompany } from '../../../../services/company.service';
import extractData from '../../../../services/data.processing';
import messages from '../../messages';
import ModalTruck from './ModalTruck';
import ModalNumber from './ModalNumber';
import useModal from './useModal';
import { addLeadingZero } from '../../../../helpers/order';
import truckIcon from '../../../../static/images/admin/Icons/Status icons/truckIcon.png';
import TourManagementIcon from '../../../../static/images/admin/Icons/Status icons/TourManagementIcon.png';
import './tour.css';

const getId = () => new Date().getTime();

const renderCardHeader = (column, animals, deleteTruck, togglePopup) => {
  if (column.level === 'order') {
    return (
      <div className="card-column-header">
        <div className="tour-management-info">
          <span className="info-not-assigned">
            <FormattedMessage {...messages.NotAssigned} />:
          </span>
          <span className="info-number">{animals}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="card-column-header loader-section">
      <div className="card-icon">
        <Image src={truckIcon} />
      </div>
      <div className="order-details">
        <span className="tour-item-two">
          {column.data.type === 'OWN_CARPOOL' ? (
            <span>
              {column.data.licence_number} | {column.data.driver_name}
            </span>
          ) : (
            <span> {column.data.transport_company} </span>
          )}
        </span>
      </div>
      <div className="card-details">
        <span className="truck-capacity">{column.data.size}</span>
        <span className="truck-full-capacity">/{column.data.capacity}</span>
      </div>
      <div className="card-action">
        <button
          className="edit-action"
          onClick={(e) => togglePopup(column.data.type, column)}
        >
          <SvgIcon size="30" color="#9f9f9f" viewBox="0 -70 350 580">
            {svgPaths['edit']}
          </SvgIcon>
        </button>
        <button
          className="delete-action"
          onClick={(e) => deleteTruck(column.id)}
        >
          <span>&#10005;</span>
        </button>
      </div>
    </div>
  );
};

const findAndReplace = (e, lang) => {
  if (lang.locale === 'DE') {
    return e && e.replace('pieces', 'Stück ');
  } else return e;
};

const getColumnSize = (column) =>
  column.children.reduce((a, b) => a + b.data.number, 0);

const updateOrderName = (name, number) => name.replace(/(\d+)/, number);

const renderOrderData = (data, lang) => (
  <div className="order-wrapper">
    <div className="order-icon">
      <Image
        style={{ width: '60px', height: '60px' }}
        src={TourManagementIcon}
      />
    </div>
    <div className="order-details">
      <div className="tour-name">{findAndReplace(data.name, lang)}</div>
      <div className="tour-item">
        {data.date} | {data.partner}
      </div>
    </div>
    <div className="order-action">
      <span
        className="column-drag-handle"
        style={{ float: 'right', padding: '0 10px' }}
      >
        &#x2630;
      </span>
    </div>
  </div>
);

const regroupDuplicates = (column) => {
  const result = column.children.reduce((a, b) => {
    const itemIdx = a.findIndex((e) => e.id === b.id);
    if (itemIdx > -1) {
      const number = a[itemIdx].data.number + b.data.number;
      a[itemIdx] = {
        ...a[itemIdx],
        data: {
          ...a[itemIdx].data,
          name: updateOrderName(a[itemIdx].data.name, number),
          number,
        },
      };
    } else {
      a.push(b);
    }
    return a;
  }, []);
  column.children = result;
  return column;
};

const getCompanyInfo = async (companyid, token) => {
  const comp = await getCompany(companyid, token);
  const info = await extractData(comp);
  return info;
};

const renderDate = (date) => {
  const newDate = new Date(date);
  const finalDate = `${addLeadingZero(newDate.getDate())}.${addLeadingZero(
    newDate.getMonth() + 1,
  )}.${newDate.getFullYear().toString().slice(-2)}`;
  return finalDate;
};

/**
 * format date to long format
 * @function
 * @param {string} date - date format like DD.MM.YY
 */
const formatDateToLongFormat = (date) => {
  if (date) {
    const dates = date.split('.');
    return `20${dates[2]}-${dates[1]}-${dates[0]}`;
  }
  return '';
};

const TourListAsPopup = ({
  orderDetail,
  token,
  company_id,
  success,
  clear,
  error,
  users,
  getOrders,
  orderType,
  typeOrder,
  handleClosePanel,
  assigned,
  getToursDate,
  changeTab,
  changeDate,
  deleteOrder,
  language,
  displayBuyRequestEdit,
}) => {
  const [scene, setScene] = useState(null);
  const [animals, setAnimals] = useState(0);
  // submit btn is active when not assigned length = 0
  const submitBtnIsdisabled = animals !== 0;
  const { isShowing, toggle } = useModal();
  const [column, setColumn] = useState(null);
  const [lang, setLang] = useState(language);
  const [type, setType] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [contact, setContact] = useState(null);
  const [company, setCompany] = useState(null);
  const [NumberEartags, setNumberEartags] = useState(0);
  const [dropResult, setDropResult] = useState(null);
  const [btnAddTruckIsDiable, disableBtn] = useState(false);
  const [notAssigned, setNotAssigned] = useState([]);
  const { isShowing: isShowingModalNumber, toggle: toggleModalNumber } =
    useModal();

  useEffect(() => {
    async function fetchData() {
      getTourPlanning(orderDetail);
      const infos = await getCompanyInfo(company_id, token);
      setCompany(infos);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  const initializeScene = (delivery_time, not_assigned) => {
    const initialScene = {
      type: 'container',
      props: {
        orientation: 'horizontal',
      },
      children: generateItems(1, (i) => ({
        id: `column${getId()}`,
        level: 'order',
        className: 'card-wrapper',
        type: 'container',
        props: {
          orientation: 'vertical',
          className: 'card-container',
        },
        children: not_assigned.map((item, j) => ({
          type: 'draggable',
          id: `${getId()}-${i}${j}`,
          props: {
            className: 'card',
          },
          data: {
            order_id: item.order_id,
            name: item.name,
            number: item.number,
            date: item.delivery_date,
            partner: item.business_partner,
            status: item.status,
          },
        })),
      })),
    };
    const numberOfanimals = getColumnSize(initialScene.children[0]);
    setAnimals(numberOfanimals);
    setDeliveryTime(delivery_time);
    setScene(initialScene);
  };
  const getTourPlanning = async (orderDetail) => {
    if (typeof assigned === 'undefined') {
      const value = await OrderService.getTourPlanning(
        token,
        orderDetail.order_id,
        1,
      );
      if (value.status === 200) {
        const data = await extractData(value);
        const { delivery_time, not_assigned } = data;
        setNotAssigned(not_assigned);
        initializeScene(delivery_time, not_assigned);
      }
    } else {
      const value = await OrderService.getTourPlanningById(
        token,
        orderDetail.order_id,
        orderDetail._id,
      );
      if (value.status === 200) {
        const data = await extractData(value);
        const { not_assigned, delivery_time } = data;
        setNotAssigned(not_assigned);
        initializeScene(delivery_time, not_assigned);
      }
    }
  };

  const addTruck = (data) => {
    if (typeof data.transport_company_id !== 'undefined') {
      data.transport_company = contact.name;
      data.transport_company_number = contact.number;
    } else {
      data.transport_company = company.name;
      data.transport_company_number = company.number;
      data.transport_company_id = company_id;
    }
    if (data.type === 'OWN_CARPOOL') {
      const driverName = data.driver_name;
      // when driver is selected from list then driver_name is like {label, value, _id}
      // when driver is written manually then driver_name is string
      if (typeof driverName === 'string') {
        data.driver_name = driverName;
        data.driver_id = '';
      } else {
        data.driver_name = driverName.label;
        data.driver_id = driverName._id;
      }
    }
    const newScene = Object.assign({}, scene);
    const cattleNumber = notAssigned[0].number;
    const truck = generateItems(1, (i) => ({
      id: `column${getId()}`,
      level: 'truck',
      className: 'card-wrapper',
      type: 'container',
      data: {
        ...data,
        size: 0,
      },
      props: {
        orientation: 'vertical',
        className: 'card-container',
      },
      children:
        data.capacity > cattleNumber
          ? scene.children.find((child) => child.level === 'order').children
          : [],
    }));
    newScene.children.push(truck[0]);
    if (data.capacity > cattleNumber) {
      newScene.children.find((child) => child.level === 'order').children = [];
      newScene.children.find((child) => child.level === 'truck').data.size =
        cattleNumber;
      setAnimals(0);
    }
    setScene(newScene);
    if (
      company.business_type === '3' &&
      orderDetail.receiver_is_registered === 0
    ) {
      disableBtn(true);
    }
  };

  const deleteTruck = (columnId) => {
    const newScene = Object.assign({}, scene);
    const truckId = newScene.children.findIndex((p) => p.id === columnId);
    const trcukChildren = newScene.children[truckId].children;
    if (trcukChildren.length > 0) {
      newScene.children[0].children.push(...trcukChildren);
      newScene.children[0] = regroupDuplicates(newScene.children[0]);
      setAnimals(animals + newScene.children[truckId].data.size);
    }
    newScene.children.splice(truckId, 1);
    setScene(newScene);
    setColumn(null);
    if (isShowing) {
      toggle();
    }
    disableBtn(false);
  };

  const editTruck = (columnId, data) => {
    if (typeof data.transport_company_id !== 'undefined') {
      data.transport_company = contact.name;
      data.transport_company_number = contact.number;
    } else {
      data.transport_company = company.name;
      data.transport_company_number = company.number;
      data.transport_company_id = company_id;
    }

    const newScene = Object.assign({}, scene);
    const truckId = newScene.children.findIndex((p) => p.id === columnId);
    if (truckId < 0) return false;
    const truck = newScene.children[truckId];
    const trcukChildren = truck.children;
    let truckSize = truck.data.size;
    if (truckSize > data.capacity && trcukChildren.length > 0) {
      truckSize = 0;
      newScene.children[0].children.push(...trcukChildren);
      newScene.children[0] = regroupDuplicates(newScene.children[0]);
      setAnimals(animals + newScene.children[truckId].data.size);
      truck.children = [];
    }
    truck.data = {
      ...data,
      size: truckSize,
    };
    newScene.children[truckId] = truck;
    setScene(newScene);
    toggle();
  };
  const getCardPayload = (columnId, index) => {
    return scene.children.filter((p) => p.id === columnId)[0].children[index];
  };

  const onCardDrop = (target, dropResult) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      if (isShowingModalNumber) return false;
      const newScene = Object.assign({}, scene);
      const columnIndex = newScene.children.indexOf(target);
      let newColumn = Object.assign({}, target);
      newColumn.children = applyDrag(newColumn.children, dropResult);
      newScene.children.splice(columnIndex, 1, newColumn);
      if (newColumn.level === 'truck') {
        const newSize = getColumnSize(newColumn);
        if (newSize > newColumn.data.capacity) {
          setColumn(newColumn);
          setDropResult(dropResult);
          setNumberEartags(newColumn.data.capacity - newColumn.data.size);
          toggleModalNumber();
          return false;
        }
        newColumn.data.size = newSize;
      } else {
        newScene.children[0] = regroupDuplicates(newScene.children[0]);
        setAnimals(getColumnSize(newScene.children[0]));
      }
      setScene(newScene);
    }
  };

  const togglePopup = (type, column = null) => {
    toggle();
    setColumn(column);
    setType(type);
  };

  const increaseNumber = () => {
    let parsed = parseInt(NumberEartags);
    if (column.data.capacity - column.data.size - 1 >= parsed)
      setNumberEartags(parsed + 1);
  };

  const decreaseNumber = () => {
    let parsed = parseInt(NumberEartags);
    if (parsed > 0) setNumberEartags(parsed - 1);
  };

  const splitOrder = () => {
    const { payload, addedIndex } = dropResult;
    const newScene = Object.assign({}, scene);
    let newColumn = Object.assign({}, column);
    const columnId = newScene.children.findIndex((e) => e.id === newColumn.id);
    const copyOrder1 = {
      ...payload,
      data: {
        ...payload.data,
        number: parseInt(NumberEartags),
        name: updateOrderName(payload.data.name, parseInt(NumberEartags)),
      },
    };
    const copyOrder2 = {
      ...payload,
      data: {
        ...payload.data,
        number: payload.data.number - parseInt(NumberEartags),
        name: updateOrderName(
          payload.data.name,
          payload.data.number - parseInt(NumberEartags),
        ),
      },
    };
    newColumn.children[addedIndex] = copyOrder1;
    const newSize = getColumnSize(newColumn);
    newColumn.data.size = newSize;

    newColumn = regroupDuplicates(newColumn);
    newScene.children[columnId] = newColumn;
    newScene.children[0].children.push(copyOrder2);
    newScene.children[0] = regroupDuplicates(newScene.children[0]);
    setAnimals(getColumnSize(newScene.children[0]));
    setNumberEartags(0);
    setScene(newScene);
    toggleModalNumber();
  };

  const handleCloseModalNumber = () => {
    const { payload } = dropResult;
    const newScene = Object.assign({}, scene);
    const newColumn = Object.assign({}, column);
    const columnId = newScene.children.findIndex((e) => e.id === newColumn.id);
    newColumn.children = newColumn.children.filter((e) => e.id !== payload.id);
    newScene.children[0].children.push(payload);
    newScene.children[0] = regroupDuplicates(newScene.children[0]);
    newScene.children[columnId] = newColumn;
    setAnimals(getColumnSize(newScene.children[0]));
    setNumberEartags(0);
    setScene(newScene);
    toggleModalNumber();
  };

  const renderModalNumber = () => {
    return isShowingModalNumber ? (
      <ModalNumber
        isShowing={isShowingModalNumber}
        togglePopupNumber={handleCloseModalNumber}
        increaseNumber={increaseNumber}
        decreaseNumber={decreaseNumber}
        NumberEartags={NumberEartags}
        splitOrder={splitOrder}
        maxValue={column.data.capacity - column.data.size}
      />
    ) : null;
  };

  const deleteTourPlanningOrder = async (e, orderId) => {
    await deleteOrder(orderId);
    handleClosePanel();
    getOrders();
    success(
      language === 'EN'
        ? `${orderDetail.name}   successfully updated`
        : `${orderDetail.name}  erfolgreich aktualisiert`,
    );
    clear();
  };

  const handleSubmit = async () => {
    const trucks = scene.children.filter((e) => e.children.length > 0);
    const trucksData = trucks.map((e) => ({
      capacity: parseInt(e.data.capacity),
      type: e.data.type || type,
      pick_up_date: renderDate(e.data.pick_up_date),
      delivery_date: renderDate(e.data.delivery_date),
      delivery_until: e.data.delivery_until,
      licence_number:
        e.data.type === 'OWN_CARPOOL' ? e.data.licence_number : '',
      driver_name: e.data.type === 'OWN_CARPOOL' ? e.data.driver_name : '',
      driver_id: e.data.type === 'OWN_CARPOOL' ? e.data.driver_id : '',
      transport_company:
        e.data.type === 'OWN_CARPOOL' ? company.name : e.data.transport_company,
      transport_company_id:
        e.data.type === 'OWN_CARPOOL'
          ? company_id
          : e.data.transport_company_id.value,
      transport_company_number:
        e.data.type === 'OWN_CARPOOL'
          ? company.number
          : e.data.transport_company_number,
      ear_tags: e.children.map((item) => ({
        order_id: item.data.order_id,
        number: item.data.number,
      })),
    }));

    let data = {
      trucks: trucksData,
    };
    if (typeof assigned !== 'undefined') {
      data._id = orderDetail._id;
    }

    data.isCattleModuleRelated = 1;

    const res = await OrderService.editTourPlanningByNumber(
      token,
      orderDetail.order_id,
      data,
    );

    if (res.status === 200) {
      await getOrders();
      handleClosePanel();
      const msg =
        language.locale === 'EN'
          ? `${orderDetail.name}   successfully updated`
          : `${orderDetail.name}  erfolgreich aktualisiert`;

      success(msg);
      clear();
      // await this.props.handleClosePanel();
    } else {
      error(<FormattedMessage {...messages.TourPlnError} />);
      clear();
    }
  };

  if (!scene) {
    return <>Loading</>;
  }

  const truckItem = column ? column.data : null;
  const companyType = users.user.session.company_type;

  return (
    <div>
      <ModalTruck
        isShowing={isShowing}
        hide={toggle}
        values={truckItem}
        addTruck={addTruck}
        deleteTruck={deleteTruck}
        editTruck={editTruck}
        deliveryDate={orderDetail.date || ''}
        deliveryUntil={deliveryTime}
        type={type}
        index={column ? column.id : null}
        setContact={setContact}
        togglePopup={togglePopup}
      />
      {renderModalNumber()}
      <Card className="card-box tour-planning-box">
        <div className="navigate-before">
          <div md={3}>
            <MdNavigateBefore className="before" onClick={handleClosePanel} />
          </div>
        </div>

        <div className="tour-planning-top-part">
          <div className="truc-title-block">
            <h5 className="card-title first-block">
              <FormattedMessage {...messages.TourInformation} />
            </h5>

            <div
              className={
                btnAddTruckIsDiable
                  ? ['accordions', 'disabled'].join(' ')
                  : 'accordions'
              }
            >
              <div href="#" className="accordions-toggle">
                <FormattedMessage {...messages.TruckAdd} />
              </div>
              <div className="accordions-inner">
                <div
                  className="accordions-text"
                  onClick={() => togglePopup('OWN_CARPOOL')}
                >
                  <FormattedMessage {...messages.OwnCarpool} />
                </div>
                <div
                  className="accordions-text"
                  onClick={() => togglePopup('MANUAL_ENTRY')}
                >
                  <FormattedMessage {...messages.TransportCompany} />
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: '540px' }} className="card-scenet">
            {scene.children.map((column) => (
              <Column
                key={column.id}
                column={column}
                renderCardHeader={() =>
                  renderCardHeader(column, animals, deleteTruck, togglePopup)
                }
                lang={lang}
                onCardDrop={onCardDrop}
                getCardPayload={getCardPayload}
                renderOrderData={renderOrderData}
              />
            ))}
          </div>
        </div>
        <div className="button-truck-field btn-complete-outer tour-planning-bottom-part">
          <NormalButton
            disabled={submitBtnIsdisabled}
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            <FormattedMessage {...messages.complete_tours} />
          </NormalButton>
          {/* <NormalButton className="edit-button" grey onClick={(e) => displayBuyRequestEdit(e)}>
            <SvgIcon color="white" size="22" viewBox="0 0 550 550">
              {svgPaths['edit']}
            </SvgIcon>
            <FormattedMessage {...messages.Edit} />
          </NormalButton> */}
          {companyType === '3' && (
            <NormalButton
              grey
              type="submit"
              onClick={(e) => deleteTourPlanningOrder(e, orderDetail.order_id)}
            >
              <SvgIcon color="white" size="22" viewBox="0 0 550 550">
                {svgPaths['trash']}
              </SvgIcon>
              <FormattedMessage {...messages.Delete} />
            </NormalButton>
          )}
        </div>
      </Card>
    </div>
  );
};

const Column = memo(
  ({
    column,
    renderCardHeader,
    onCardDrop,
    getCardPayload,
    renderOrderData,
    lang,
  }) => (
    <div className={column.className}>
      <div className={column.props.className}>
        {renderCardHeader()}
        <div>
          <Container
            // lockAxis="y"
            {...column.props}
            columnId={column.id}
            level={column.level}
            data={column.data}
            groupName="col"
            onDragStart={() => {}}
            onDragEnd={() => {}}
            onDrop={(e) => onCardDrop(column, e)}
            getChildPayload={(index) => getCardPayload(column.id, index)}
            dragClass="opacity-ghost2"
            dropClass="opacity-ghost-drop"
            onDragEnter={(e) => {}}
            onDragLeave={(e) => {}}
            onDropReady={(e) => {}}
            dropPlaceholder={{
              animationDuration: 150,
              showOnTop: true,
              className: 'drop-preview',
            }}
            dropPlaceholderAnimationDuration={200}
            shouldAcceptDrop={(sourceContainerOptions, payload) => {
              if (
                sourceContainerOptions.level === 'truck' &&
                column.level === 'truck' &&
                sourceContainerOptions.columnId !== column.id
              ) {
                return false;
              }

              if (
                column.level === 'truck' &&
                sourceContainerOptions.level === 'order'
              ) {
                return column.data.size < column.data.capacity;
              }
              return true;
            }}
          >
            {column.children.map((card) => (
              <DraggableItem
                key={card.id}
                card={card}
                lang={lang}
                renderOrderData={renderOrderData}
              />
            ))}
          </Container>
        </div>
      </div>
    </div>
  ),
);

const DraggableItem = ({ card, renderOrderData, lang }) => (
  <Draggable>
    <div {...card.props}>{renderOrderData(card.data, lang)}</div>
  </Draggable>
);

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    deleteOrder: (orderId) => dispatch(OrderActions.deleteOrder(orderId)),
    // getOrders: (orderType, type, page, date) =>
    //   dispatch(OrderActions.getOrders(orderType, type, page, date)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourListAsPopup);
