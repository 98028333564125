import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';
import { Col, Image, Modal, Row, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { svgPaths } from '../../../constants';
import { applyDrag, generateItems } from './utils';
import { alertActions, OrderActions, producerActions } from '../../../actions';
import { OrderService } from '../../../services/order.service';
import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';


import moment from 'moment-es6';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';

import {
  getCompany,
  getContacts,
  getAllTruck,
} from '../../../services/company.service';
import extractData from '../../../services/data.processing';
import messages from '../messages';
import ModalTruck from './ModalTruck';
import ModalNumber from './ModalNumber';
import CardOrder from './card';
import useModal from './useModal';
import { addLeadingZero } from '../../../helpers/order';
import truckIcon from '../../../static/images/admin/Icons/Status icons/truckIcon.png';
import './tour.css';
import './tourManagementInfo.css';
import ExternerVerkauf from '../../../static/images/admin/Icons/Status icons/Externer Verkauf.png';
import Nutztierverkauf from '../../../static/images/admin/Icons/Status icons/Nutztierverkauf.png';
import clock from '../../../static/images/admin/member_ship/clock.png';
import clock2 from '../../../static/images/admin/member_ship/clock2.png';



import UpArrow from '../../../static/images/admin/Icons/Status icons/up-arrow-8.svg';
import Selbstfahre from '../../../static/images/admin/Icons/Status icons/Selbstfahrer.png';
// import Tourplanning from '../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import ReSellRequestAllContact from '../Orders/Resell/ReSellRequestAllContact';
import ScrollMenu from 'react-horizontal-scrolling-menu';

const getId = () => new Date().getTime();

const getSrcImg = (status) => {
  var res = status;

  switch (res) {
    case 'OWN_DRIVER':
      return Selbstfahre;
      break;
    case 'EXTERNAL_DRIVER':
      return ExternerVerkauf;
      break;
    case 'TRANSPORT_COMPANY':
      return Nutztierverkauf;
      break;
    case 'OWN_CARPOOL':
      return truckIcon;
      break;
  }
};
const renderCardHeader = (
  column,
  animals,
  deleteTruck,
  togglePopup,
  handleOpenOnly,
  trucksShow,
  producer,
) => {
  if (column.level === 'order') {
    return (
      <div className="card-column-header">
        <div className="tour-management-info"></div>
      </div>
    );
  }

  return (
    typeof column.data !== 'undefined' && (
      <div className="card-column-header loader-section" style={{ marginBottom: '8px' }}>
        <div className="loader-section-image" style={{ width: '80px', marginRight: '30px' }}>
          <Image className="truckimdw" src={getSrcImg(column.data.iconType)} />
        </div>
        <div className="order-details">
          <span className="tour-item-twoval">
            {column.data.iconType === 'OWN_CARPOOL' ? (
              <span>
                {' '}
                <FormattedMessage {...messages.OwnCarpool} />:{' '}
                {column.data.licence_number}
                <br></br>
                <span>
                  {' '}
                  {column.data.open_request.value !== '' ? (
                    <span style={{ color: '#c4c4c4' }}>
                      {' '}
                      <FormattedMessage {...messages.UserName} />:{' '}
                      {column.data.open_request.value}
                    </span>
                  ) : (
                    <span style={{ color: '#F9BE63' }}>
                      {' '}
                      <FormattedMessage {...messages.Selectreceiver} />{' '}
                    </span>
                  )}{' '}
                </span>
                <br></br>
                <span>
                  {column.data.delivery_until !== '' ? (
                    <span style={{ color: '#c4c4c4' }}>
                      <img src={clock} />&nbsp;

                      {column.data.delivery_until}
                    </span>
                  ) : (
                    <span style={{ color: '#F9BE63' }}>
                      <img src={clock2} />&nbsp;
                      --:--
                    </span>
                  )}{' '}
                </span>
              </span>
            ) : (
              column.data.iconType === 'TRANSPORT_COMPANY' && (
                <span>
                  {' '}
                  {column.data.transport_company}
                  <br></br>
                  <span>
                    {' '}
                    {column.data.open_request.value !== '' ? (
                      <span style={{ color: '#c4c4c4' }}>
                        {' '}
                        <FormattedMessage {...messages.UserName} />:{' '}
                        {column.data.open_request.value}
                      </span>
                    ) : (
                      <span style={{ color: '#F9BE63' }}>
                        {' '}
                        <FormattedMessage {...messages.Selectreceiver} />{' '}
                      </span>
                    )}{' '}
                  </span>
                  <br></br>

                  <span>

                    {column.data.delivery_until !== '' ? (
                      <span style={{ color: '#c4c4c4' }}>
                        <img src={clock} /> &nbsp;


                        {column.data.delivery_until}
                      </span>
                    ) : (
                      <span style={{ color: '#F9BE63' }}> &nbsp;
                        <img src={clock2} />

                        --:--
                      </span>
                    )}{' '}
                  </span>
                </span>
              )
            )}
            {(column.data.iconType === 'EXTERNAL_DRIVER' ||
              column.data.iconType === 'OWN_DRIVER') && (
                <span>
                  {' '}
                  {column.data.name === 'Own driver' && (
                    <FormattedMessage {...messages.Selbstfahrer} />
                  )}
                  {column.data.name === 'External' && (
                    <FormattedMessage {...messages.Externaler} />
                  )}
                  <br></br>
                  {column.data.iconType === 'OWN_DRIVER' && (
                    <span>
                      {' '}
                      {column.data.open_request.value !== '' ? (
                        <span style={{ color: '#c4c4c4' }}>
                          {' '}
                          <FormattedMessage {...messages.UserName} />:{' '}
                          {column.data.open_request.value}
                        </span>
                      ) : (
                        <span style={{ color: '#F9BE63' }}>
                          {' '}
                          <FormattedMessage {...messages.Selectreceiver} />{' '}
                        </span>
                      )}{' '}
                    </span>
                  )}
                </span>
              )}
          </span>
        </div>
        <div className="card-details">
          <span style={{ fontSize: '40px' }} className="truck-capacity">
            {column.data.size}
          </span>
          {(column.data.type === 'OWN_CARPOOL' ||
            column.data.iconType === 'TRANSPORT_COMPANY') && (
              <span className="truck-full-capacity">/{column.data.capacity}</span>
            )}
        </div>
        <div className="card-action">
          {(column.data.type === 'OWN_CARPOOL' ||
            column.data.iconType === 'TRANSPORT_COMPANY') && (
              <button
                className="edit-action"
                onClick={(e) => togglePopup(column.data.type, column)}
                disabled={
                  typeof producer.isFinalized !== 'undefined' &&
                    producer.isFinalized === 1
                    ? true
                    : false
                }
              >
                <SvgIcon size="30" color="#9f9f9f" viewBox="0 -70 350 580">
                  {svgPaths['edit']}
                </SvgIcon>
              </button>
            )}
          {column.data.iconType === 'OWN_DRIVER' && (
            <button
              disabled={
                typeof producer.isFinalized !== 'undefined' &&
                  producer.isFinalized === 1 &&
                  column.data.open_request.id !== '' &&
                  column.data.trucksData.orders.length >= 1
                  ? true
                  : false
              }
              className="edit-action"
              onClick={(e) => handleOpenOnly(column.data.type, column)}
            >
              <SvgIcon size="30" color="#9f9f9f" viewBox="0 -70 350 580">
                {svgPaths['edit']}
              </SvgIcon>
            </button>
          )}
          <div className="delete-action">
            <span>
              <button className="truckimdwrr">
                <Image className="truckimdwrr" src={UpArrow} />
              </button>
            </span>
          </div>
        </div>
      </div>
    )
  );
};

const findAndReplace = (e, language) => {
  if (language.locale === 'DE') {
    return e && e.replace('pieces', 'Stück ');
  } else return e;
};

const getColumnSize = (column) =>
  column.children.reduce((a, b) => a + b.data.number, 0);

const updateOrderName = (name, number) => name.replace(/(\d+)/, number);
const LoadData = () => {
  this.props.getOrders();
};
const renderOrderData = (data, lang, getOrders) => (
  <CardOrder getOrders={getOrders} data={data}></CardOrder>
);

const regroupDuplicates = (column) => {
  const result = column.children.reduce((a, b) => {
    const itemIdx = a.findIndex((e) => e.id === b.id);
    if (itemIdx > -1) {
      const number = a[itemIdx].data.number + b.data.number;
      a[itemIdx] = {
        ...a[itemIdx],
        data: {
          ...a[itemIdx].data,
          name: updateOrderName(a[itemIdx].data.name, number),
          number,
        },
      };
    } else {
      a.push(b);
    }
    return a;
  }, []);
  column.children = result;
  return column;
};

const getCompanyInfo = async (companyid, token) => {
  const comp = await getCompany(companyid, token);
  if (
    comp !== 'null' &&
    typeof comp !== 'undefined' &&
    typeof comp.status !== 'undefined' &&
    comp.status === 200
  ) {
    const info = await extractData(comp);
    return info;
  }
};

const renderDate = (date) => {
  const newDate = new Date(date);
  const finalDate = `${addLeadingZero(newDate.getDate())}.${addLeadingZero(
    newDate.getMonth() + 1,
  )}.${newDate.getFullYear().toString().slice(-2)}`;
  return finalDate;
};

/**
 * format date to long format
 * @function
 * @param {string} date - date format like DD.MM.YY
 */
const formatDateToLongFormat = (date) => {
  if (date) {
    const dates = date.split('.');
    return `20${dates[2]}-${dates[1]}-${dates[0]}`;
  }
  return '';
};

const TourListRight = ({
  orderDetail,
  token,
  company_id,
  success,
  clear,
  error,
  users,
  getOrders,
  orderType,
  typeOrder,
  handleClosePanel,
  assigned,
  getToursDate,
  changeTab,
  changeDate,
  deleteOrder,
  language,
  displayBuyRequestEdit,
  orderTruck,
  trucksShow,
  updateProducerOrder,
  producer,
  date,
  getProducer,
  resetProducer,
  dateSelling,
}) => {
  const [scene, setScene] = useState(null);
  const [animals, setAnimals] = useState(0);
  // submit btn is active when not assigned length = 0
  const submitBtnIsdisabled = animals !== 0;
  const { isShowing, toggle } = useModal();
  const [column, setColumn] = useState(null);
  const [lang, setLang] = useState(language);
  const [type, setType] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [contact, setContact] = useState(null);

  const [company, setCompany] = useState(null);
  const [NumberEartags, setNumberEartags] = useState(0);
  const [dropResult, setDropResult] = useState(null);
  const [btnAddTruckIsDiable, disableBtn] = useState(false);
  const [notAssigned, setNotAssigned] = useState([]);
  const [notAssignedTruck, setNotAssignedTruck] = useState([]);
  const [contactList, setcontactList] = useState([]);
  const [showResell, setshowResell] = useState(false);

  const [orderSelling, setorderSelling] = useState([]);
  const [orderSellingT, setorderSellingT] = useState({});
  const [Accecpt, setAccept] = useState(false);
  const [showResellOnly, setshowResellOnly] = useState(false);
  const [confirm, setconfirm] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const [openByrequestCattel, setOpenByrequestCattel] = useState([]);
  const [openByrequestByDate, setOpenByrequestByDate] = useState([]);

  const [orderIdExternal, setorderIdExternal] = useState('');
  const { isShowing: isShowingModalNumber, toggle: toggleModalNumber } =
    useModal();

  useEffect(() => {
    async function fetchData() {
      await getTourPlanning(orderDetail, orderTruck);
      await openBuyRequestsDate();
      const infos = await getCompanyInfo(company_id, token);
      await setCompany(infos);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  const initializeScene = (not_assigned, trucks) => {
    let initialScene;

    if (!trucksShow) {
      initialScene = {
        type: 'container',
        props: {
          orientation: 'horizontal',
        },
        children: generateItems(1, (i) => ({
          id: `column${i}`,
          level: 'order',
          className: 'card-wrapper',
          type: 'container',
          props: {
            orientation: 'vertical',
            className: 'card-contatrucksShowiner',
          },
          children: not_assigned.map((item, j) => ({
            type: 'draggable',
            id: `${i}-${j}${i}`,
            props: {
              className: 'cardTwo',
            },

            data: {
              order_id: item.order_id,
              name: item.name,
              number: item.number,
              delivery_time: item.delivery_time,
              date: item.delivery_date,
              partner: item.business_partner,
              status: item.status,
              sender_name: item.sender_name,
              receiver_id: item.receiver_id,
              topic: item.topic,
              is_buyer: item.is_buyer,
              freight_included: item.freight_included,
              notes: item.notes,
              data: item,
            },
          })),
        })),
      };
    } else {
      initialScene = {
        type: '',
        props: {
          orientation: 'horizontal',
        },
        children: generateItems(1, (i) => ({
          id: `column${i}`,
          level: 'order',
          className: 'card-wrapperTwo',
          type: 'container',
          props: {
            orientation: 'vertical',
            className: 'card-container',
          },
          children: [].map((item, j) => ({
            type: 'draggable',
            id: `${i}-${i}${j}`,
            props: {
              className: 'cardTwo',
            },
            data: {
              order_id: item.order_id,
              name: item.name,
              number: item.number,
              delivery_time: item.delivery_time,
              date: item.delivery_date,
              partner: item.business_partner,
              status: item.status,
              sender_name: item.sender_name,
              receiver_id: item.receiver_id,
              topic: item.topic,
              is_buyer: item.is_buyer,
              freight_included: item.freight_included,
              notes: item.notes,
              data: item,
            },
          })),
        })),
      };
    }

    const numberOfanimals = getColumnSize(initialScene.children[0]);
    setAnimals(numberOfanimals);

    let data = {};
    if (trucksShow && typeof trucks !== 'undefined') {
      for (let i = 0; i < trucks.length; i++) {
        let driverNameGlobal;
        if (
          trucks[i].type === 'OWN_CARPOOL' ||
          trucks[i].type === 'OWN_DRIVER'
        ) {
          const driverName = trucks[i].driver_name;
          // when driver is selected from list then driver_name is like {label, value, _id}
          // when driver is written manually then driver_name is string
          if (typeof trucks[i].driver_name === 'string') {
            driverNameGlobal = trucks[i].driver_name;
          } else {
            trucks[i].driver_name =
              typeof trucks[i].transport_company !== 'undefined'
                ? trucks[i].transport_company
                : '';
            driverNameGlobal =
              typeof trucks[i].transport_company !== 'undefined'
                ? trucks[i].transport_company
                : '';

            trucks[i].driver_id =
              typeof trucks[i].driver_id !== 'undefined'
                ? trucks[i].driver_id
                : '';
          }
        }
        if (trucks[i].type === 'EXTERNAL_DRIVER') {
          trucks[i].transport_company = '';
          trucks[i].transport_company_number = '';
          trucks[i].transport_company_id = '';
        }

        data.capacity = trucks[i].capacity;
        data.driver_id = '';
        data.driver_name =
          typeof driverNameGlobal !== 'undefined' ? driverNameGlobal : '';
        if (trucks[i].type === 'OWN_CARPOOL') {
          data.driver_name =
            typeof trucks[i].driver_name !== 'undefined'
              ? trucks[i].driver_name
              : '';
        }
        data.licence_number =
          typeof trucks[i].licence_number !== 'undefined'
            ? trucks[i].licence_number
            : '';
        data.driver_name =
          typeof trucks[i].driver_name !== 'undefined'
            ? trucks[i].driver_name
            : '';
        data.pick_up_date =
          typeof trucks[i].pick_up_date === 'undefined' ||
            trucks[i].pick_up_date === '' ||
            trucks[i].pick_up_date === 'Invalid Date'
            ? moment(new Date(dateNewFormat()), 'DD.MM.YY hh:mm A').toDate()
            : moment(trucks[i].pick_up_date, 'DD.MM.YY hh:mm A').toDate();

        data.delivery_date =
          typeof trucks[i].delivery_date === 'undefined' ||
            trucks[i].delivery_date === '' ||
            trucks[i].pick_up_date === 'Invalid Date'
            ? moment(new Date(dateNewFormat()), 'DD.MM.YY hh:mm A').toDate()
            : moment(trucks[i].delivery_date, 'DD.MM.YY hh:mm A').toDate();

        data.delivery_until =
          typeof trucks[i].delivery_until === 'undefined' ||
            trucks[i].delivery_until === ''
            ? ''
            : trucks[i].delivery_until;

        data.transport_company =
          typeof trucks[i].transport_company !== 'undefined'
            ? trucks[i].transport_company
            : '';

        data.transport_company_id = {
          id: trucks[i].transport_company_id,
          value: trucks[i].transport_company_id,
          label: trucks[i].transport_company,
        };

        let openBuyRequestsProd = producer.openBuyRequests.filter((e) => {
          return e.delivery_date === date;
        });

        if (
          trucks[i].type === 'TRANSPORT_COMPANY' ||
          trucks[i].type === 'OWN_CARPOOL'
        ) {
          if (
            typeof trucks[i].open_buy_request !== 'undefined' &&
            typeof trucks[i].open_buy_request._id !== 'undefined' &&
            trucks[i].open_buy_request._id !== ''
          ) {
            data.open_request = {
              id: trucks[i].open_buy_request._id,
              value: trucks[i].open_buy_request.name,
              label: trucks[i].open_buy_request.name,
            };
          } else {
            if (
              openBuyRequestsProd.length > 1 ||
              openBuyRequestsProd.length === 0
            ) {
              data.open_request = {
                id: '',
                value: '',
                label: '',
              };
            } else {
              data.open_request = {
                id: openBuyRequestsProd[0]._id,
                value: openBuyRequestsProd[0].sender_name,
                label: openBuyRequestsProd[0].sender_name,
              };
              data.delivery_until = openBuyRequestsProd[0].delivery_time;
            }
          }
        }
        if (
          trucks[i].type === 'OWN_DRIVER' ||
          trucks[i].type === 'EXTERNAL_DRIVER'
        ) {
          if (
            typeof trucks[i].receiver !== 'undefined' &&
            typeof trucks[i].receiver._id !== 'undefined' &&
            trucks[i].receiver._id !== ''
          ) {
            data.open_request = {
              id: trucks[i].receiver._id,
              value: trucks[i].receiver.name,
              label: trucks[i].receiver.name,
            };
          } else {
            data.open_request = {
              id: '',
              value: '',
              label: '',
            };
          }
        }

        if (trucks[i].type === 'OWN_CARPOOL') {
          data.driver_id =
            typeof trucks[i].driver_id !== 'undefined'
              ? trucks[i].driver_id
              : '';
        }

        data.transport_company_number =
          typeof trucks[i].transport_company_number !== 'undefined'
            ? trucks[i].transport_company_number
            : '';
        data.type =
          trucks[i].type === 'OWN_CARPOOL' ? 'OWN_CARPOOL' : 'MANUAL_ENTRY';
        data.iconType = trucks[i].type;
        data.name = typeof trucks[i].name ? trucks[i].name : '';
        data.trucksData = trucks[i];
        let newSiza = 0;
        trucks[i].orders.map((e) => (newSiza = newSiza + e.number));
        data.size = newSiza;
        let block = trucks[i].isBlocked;

        const truck = generateItems(1, (j) => ({
          id: `column${i}-${i}${j}`,
          level: 'truck',
          className: 'card-wrapper',
          type: 'container',
          data: {
            ...data,
          },
          props: {
            orientation: 'vertical',
            className: 'card-container',
          },
          children: trucks[i].orders.map((item, j) => ({
            type: 'draggable',
            id: `${i}-${i}${j}`,
            props: {
              className: 'cardTwo',
            },
            data: {
              order_id: item.order_id,
              name: item.name,
              number: item.number,
              delivery_time: item.delivery_time,
              date: item.delivery_date,
              partner: item.business_partner,
              status: item.status,
              receiver_id: item.receiver_id,
              topic: item.topic,
              is_buyer: item.is_buyer,
              freight_included: item.freight_included,
              data: item,
              notes: item.notes,
              isBlocked: block,
              right: 'true',
            },
          })),
        }));

        initialScene.children.push(truck[0]);
      }

      initialScene.children = initialScene.children.filter(
        (value) => value.level != 'order',
      );
    }

    setScene(initialScene);

  };

  const openBuyRequestsDate = async () => {
    let openBuyRequestsProd = await producer.openBuyRequests.filter((e) => {
      return e.delivery_date === date;
    });
    await setOpenByrequestByDate(openBuyRequestsProd);
    await groupEartagsByBreed();
  };

  const groupEartagsByBreed = async (truck = []) => {
    try {
      var truckOrder = [];
      if (truck.length === 0) {
        truckOrder = orderTruck.filter((r) => {
          return r.orders.length > 0;
        });
      } else {
        truckOrder = truck.filter((r) => {
          return r.orders.length > 0;
        });
      }

      var truckOrderValid = truckOrder.filter((r) => {
        return (
          typeof r.open_buy_request !== 'undefined' &&
          typeof r.open_buy_request.name !== 'undefined'
        );
      });

      let OrdersGroupBreed = truckOrderValid;

      // open buy request opertaion
      let openBuyRequestsProd = await producer.openBuyRequests.filter((e) => {
        return e.delivery_date === date;
      });

      var resulArray = openBuyRequestsProd;

      let finalArray = [];
      await resulArray.map((e) => {
        let cattle_group = [];

        if (typeof e.cattle_group !== 'undefined' && e.cattle_group !== null) {
          e.cattle_group &&
            e.cattle_group.map((f) => {
              cattle_group = [...cattle_group, f];
            });
        } else {
          cattle_group = [
            {
              breed: e.breed,
              number: e.number,
            },
          ];
        }

        let dateOpenBuy = {
          sender_name: e.sender_name,
          request_name: e.request_name,
          cattle_group: cattle_group,
          id: e._id,
        };
        finalArray.push(dateOpenBuy);
      });

      let calttelGroupBreed = [];

      await finalArray.map((e) => {
        var result = [];
        e.cattle_group.reduce(function (res, value) {
          if (!res[value.breed]) {
            res[value.breed] = { breed: value.breed, qty: 0 };
            result.push(res[value.breed]);
          }
          res[value.breed].qty += value.number;
          res[value.breed].nbr = 0;
          return res;
        }, {});
        let newData = {
          sender_name: e.sender_name,
          request_name: e.request_name,
          id: e.id,
          cattelGroup: result,
        };
        calttelGroupBreed = [...calttelGroupBreed, newData];
      });

      await calttelGroupBreed.map((e) => {
        let orders = OrdersGroupBreed.filter((r) => {
          return r.open_buy_request._id === e.id;
        });

        if (orders.length > 0) {
          e.cattelGroup.map((l) => {
            orders.map((ra) => {
              ra.orders.map((f) => {
                if (l.breed === f.breed) {
                  l.nbr = l.nbr + f.number;
                }
              });
            });
          });
        }
      });

      setOpenByrequestCattel(calttelGroupBreed);
      setOpenByrequestCattel(calttelGroupBreed);
    } catch (err) {
      console.log(err);
    }
  };

  const getTourPlanning = async (orderDetail, orderTruck) => {
    const { session } = users.user;

    const groups = ['2', '4'];
    let isRegistered;
    if (session.company_type !== '3') {
      isRegistered = 1;
    }

    const value = await getContacts(company_id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contactList = await extractData(value);

      setcontactList(contactList);
    }
    if (orderDetail && orderTruck) {
      setNotAssigned(orderDetail);
      initializeScene(orderDetail, orderTruck);
    }

    if (
      typeof orderTruck !== 'undefined' &&
      orderTruck.length > 0 &&
      typeof orderTruck[0] !== 'undefined'
    ) {
      const contact = contactList.find(
        (e) => '' + e.number === '' + orderTruck[0].transport_company_number,
      );
      setContact(contact);
    }
  };

  const addTruck = (data) => {
    if (typeof data.transport_company_id !== 'undefined') {
      data.transport_company =
        typeof contact !== 'undefined' ? contact.name : '';
      data.transport_company_number =
        typeof contact !== 'undefined' ? contact.number : '';
    } else {
      data.transport_company = company.name;
      data.transport_company_number = company.number;
      data.transport_company_id = company_id;
    }
    if (data.type === 'OWN_CARPOOL') {
      const driverName = data.driver_name;
      // when driver is selected from list then driver_name is like {label, value, _id}
      // when driver is written manually then driver_name is string
      if (typeof driverName === 'string') {
        data.driver_name = driverName;
        data.driver_id = '';
      } else {
        data.driver_name = driverName.label;
        data.driver_id = driverName._id;
      }
    }
    const newScene = Object.assign({}, scene);
    if (typeof data.open_request === 'undefined') {
      data.open_request = {
        id: '',
        value: '',
        label: '',
      };
    } else {
      data.open_request = {
        id: data.open_request.id,
        value: data.open_request.sender_name,
        label: data.open_request.sender_name,
      };
    }

    data.type = data.type === 'OWN_CARPOOL' ? 'OWN_CARPOOL' : 'MANUAL_ENTRY';
    data.iconType =
      data.type === 'OWN_CARPOOL' ? 'OWN_CARPOOL' : 'TRANSPORT_COMPANY';

    const truck = generateItems(1, (i) => ({
      id: `column${i}`,
      level: 'truck',
      className: 'card-wrapper',
      type: 'container',
      data: {
        ...data,
        size: 0,
      },
      props: {
        orientation: 'vertical',
        className: 'card-container',
      },
      children: [],
    }));
    newScene.children.push(truck[0]);
    let TRANSPORT_COMPANY = [];
    let OWN_CARPOOL = [];
    let OWN_DRIVER = [];
    let EXTERNAL_DRIVER = [];
    let OrderTruck = [];
    newScene.children.map((e) => {
      if (e.data.iconType === 'TRANSPORT_COMPANY') {
        TRANSPORT_COMPANY.push(e);
      }
      if (e.data.iconType === 'OWN_CARPOOL') {
        OWN_CARPOOL.push(e);
      }
      if (e.data.iconType === 'OWN_DRIVER') {
        OWN_DRIVER.push(e);
      }
      if (e.data.iconType === 'EXTERNAL_DRIVER') {
        EXTERNAL_DRIVER.push(e);
      }
    });
    OrderTruck = [
      ...TRANSPORT_COMPANY,
      ...OWN_CARPOOL,
      ...OWN_DRIVER,
      ...EXTERNAL_DRIVER,
    ];
    newScene.children = OrderTruck;
    setScene(newScene);
  };

  const deleteTruck = (columnId) => { };

  const editTruck = async (columnId, data) => {


    await toggle();

    if (typeof data.transport_company_id !== 'undefined') {
      let companyInfo = await getCompanyInfo(
        data.transport_company_id.value,
        token,
      );

      data.transport_company =
        typeof companyInfo !== 'undefined'
          ? companyInfo.name
          : data.transport_company_id.label;
      data.transport_company_number =
        typeof companyInfo !== 'undefined' ? companyInfo.number : '';
    } else {
      data.transport_company = company.name;
      data.transport_company_number = company.number;
      data.transport_company_id = company_id;
    }

    if (
      typeof data.open_request !== 'undefined' &&
      typeof data.open_request.sender_name !== 'undefined'
    ) {
      data.open_request.value = data.open_request.sender_name;
    }


    const newScene = Object.assign({}, scene);


    const truckId = newScene.children.findIndex((p) => p.id === columnId);
    if (truckId < 0) return false;

    const truck = newScene.children[truckId];
    const trcukChildren = truck.children;
    let truckSize = truck.data.size;
    if (truckSize > data.capacity && trcukChildren.length > 0) {
      truckSize = 0;
      newScene.children[0].children.push(...trcukChildren);
      newScene.children[0] = regroupDuplicates(newScene.children[0]);
      setAnimals(animals + newScene.children[truckId].data.size);
      truck.children = [];
    }


    truck.data = {
      ...data,
      size: truckSize,
    };

    newScene.children[truckId] = truck;
    await setScene(newScene);
    await handleSubmitsave(false);

  };

  const editTruckExternal = async (columnId, data) => {
    const newScene = Object.assign({}, scene);
    const truckId = newScene.children.findIndex((p) => p.id === columnId);
    if (truckId < 0) return false;
    const truck = newScene.children[truckId];
    const trcukChildren = truck.children;
    let truckSize = truck.data.size;
    if (truckSize > data.capacity && trcukChildren.length > 0) {
      truckSize = 0;
      newScene.children[0].children.push(...trcukChildren);
      newScene.children[0] = regroupDuplicates(newScene.children[0]);
      setAnimals(animals + newScene.children[truckId].data.size);
      truck.children = [];
    }

    let receiver = {
      _id:
        typeof data.open_request.id !== 'undefined' ? data.open_request.id : '',
      name:
        typeof data.open_request.value !== 'undefined'
          ? data.open_request.value
          : '',
    };

    let indexOfdrag = 0;
    newScene.children.map((p) =>
      p.children.map((e, key) => {
        if (e.data.order_id === orderIdExternal) indexOfdrag = key;
      }),
    );
    if (typeof trcukChildren[indexOfdrag] !== 'undefined') {
      trcukChildren[indexOfdrag].data.receiver = receiver;
      trcukChildren[indexOfdrag].data.data.receiver = receiver;

      trcukChildren[indexOfdrag].data.delivery_date = data.delivery_date;
      trcukChildren[indexOfdrag].data.data.delivery_date = data.delivery_date;
    }
    truck.data = {
      ...data,
      size: truckSize,
    };

    newScene.children[truckId] = truck;
    setScene(newScene);
    handleCloseOnly();
    await handleSubmitsave(false);
  };

  const getCardPayload = (columnId, index) => {
    return scene.children.filter((p) => p.id === columnId)[0].children[index];
  };

  const onCardDrop = (target, dropResult) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      if (isShowingModalNumber) return false;
      const newScene = Object.assign({}, scene);

      const columnIndex = newScene.children.indexOf(target);
      let newColumn = Object.assign({}, target);

      newColumn.children = applyDrag(newColumn.children, dropResult);
      newScene.children.splice(columnIndex, 1, newColumn);
      if (newColumn.level === 'truck') {
        const newSize = getColumnSize(newColumn);
        if (newSize > newColumn.data.capacity) {
          setColumn(newColumn);
          setDropResult(dropResult);
          setNumberEartags(newColumn.data.capacity - newColumn.data.size);
          toggleModalNumber();
          return false;
        }
        newColumn.data.size = newSize;
      } else {
        newScene.children[0] = regroupDuplicates(newScene.children[0]);
        setAnimals(getColumnSize(newScene.children[0]));
      }
      if (
        dropResult.addedIndex !== null &&
        typeof newColumn.data !== 'undefined' &&
        typeof newColumn.data !== 'undefined' &&
        newColumn.data.iconType === 'EXTERNAL_DRIVER'
      ) {
        // setshowResell(true);
        setorderIdExternal(dropResult.payload.data.order_id);
        renderResselse(
          newColumn.data,
          dropResult.payload.data,
          newColumn,
          columnIndex,
        );
      }

      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        // setshowResell(true);
        handleDragBreed();
      }

      setScene(newScene);
    }
  };

  const togglePopup = (type, column = null) => {
    toggle();
    setColumn(column);
    setType(type);
  };

  const increaseNumber = () => {
    let parsed = parseInt(NumberEartags);
    if (column.data.capacity - column.data.size - 1 >= parsed)
      setNumberEartags(parsed + 1);
  };

  const decreaseNumber = () => {
    let parsed = parseInt(NumberEartags);
    if (parsed > 0) setNumberEartags(parsed - 1);
  };

  const splitOrder = () => {
    const { payload, addedIndex } = dropResult;
    const newScene = Object.assign({}, scene);
    let newColumn = Object.assign({}, column);
    const columnId = newScene.children.findIndex((e) => e.id === newColumn.id);
    const copyOrder1 = {
      ...payload,
      data: {
        ...payload.data,
        number: parseInt(NumberEartags),
        name: updateOrderName(payload.data.name, parseInt(NumberEartags)),
      },
    };
    const copyOrder2 = {
      ...payload,
      data: {
        ...payload.data,
        number: payload.data.number - parseInt(NumberEartags),
        name: updateOrderName(
          payload.data.name,
          payload.data.number - parseInt(NumberEartags),
        ),
      },
    };

    newColumn.children[addedIndex] = copyOrder1;
    const newSize = getColumnSize(newColumn);
    newColumn.data.size = newSize;

    newColumn = regroupDuplicates(newColumn);
    newScene.children[columnId] = newColumn;
    newScene.children[0].children.push(copyOrder2);
    newScene.children[0] = regroupDuplicates(newScene.children[0]);
    setAnimals(getColumnSize(newScene.children[0]));
    setNumberEartags(0);
    setScene(newScene);
    toggleModalNumber();
  };

  const handleCloseModalNumber = () => {
    const { payload } = dropResult;
    const newScene = Object.assign({}, scene);
    const newColumn = Object.assign({}, column);
    const columnId = newScene.children.findIndex((e) => e.id === newColumn.id);
    newColumn.children = newColumn.children.filter((e) => e.id !== payload.id);
    newScene.children[0].children.push(payload);
    newScene.children[0] = regroupDuplicates(newScene.children[0]);
    newScene.children[columnId] = newColumn;
  };

  const renderModalNumber = () => {
    return isShowingModalNumber ? (
      <ModalNumber
        isShowing={isShowingModalNumber}
        togglePopupNumber={handleCloseModalNumber}
        increaseNumber={increaseNumber}
        decreaseNumber={decreaseNumber}
        NumberEartags={NumberEartags}
        splitOrder={splitOrder}
        maxValue={column.data.capacity - column.data.size}
      />
    ) : null;
  };

  const handleClose = async () => {
    await updateProducerOrder();
    await updateProducerOrder();

    setshowResell(false);
  };

  const handleCloseOnly = async () => {
    setshowResellOnly(false);
  };

  const handleOpenOnly = async (type, column = null) => {
    await setColumn(column);
    await setType(type);
    setshowResellOnly(true);
  };

  const handleCloseTwo = async () => {
    setshowResell(false);
  };

  const getEmpty = () => {
    getOrders();
  };

  const handleCloseConfirm = async () => {
    setconfirm(false);
  };

  const handleOpenConfirm = async () => {
    setconfirm(true);
  };

  const renderResselse = async (data, item, newColumn = null, columnIndex) => {
    setshowResell(true);
    setorderSellingT(item);
    await localStorage.setItem('splitaData', JSON.stringify(item));
    await setColumn(newColumn);
    await setType(newColumn.data.type);
    setorderSelling([item.order_id]);
  };

  const deleteTourPlanningOrder = async (e, orderId) => {
    await deleteOrder(orderId);
    handleClosePanel();
    getOrders(orderType, typeOrder, 0, '');
    success(
      language === 'EN'
        ? `${orderDetail.name}   successfully updated`
        : `${orderDetail.name}  erfolgreich aktualisiert`,
    );
    clear();
  };

  const handleSubmit = async () => {
    const trucks = scene.children.filter((e) => e.children);
    const trucksData = [];
    let errorProblem = false;
    let messageErrorOne = '';
    let messageErrorTwo = ''


    trucks.map((e, index) => {
      let NewOrder = [];
      typeof e.children !== 'undefined' &&
        e.children.map((item) => {
          NewOrder.push(item.data.data);
        });

      if (e.data.iconType === 'TRANSPORT_COMPANY') {
        if (
          e.data.delivery_until === '' ||
          typeof e.data.open_request === 'undefined' ||
          typeof e.data.open_request.id === 'undefined' ||
          e.data.open_request.id === ''
        ) {
          if (NewOrder.length > 0) {
            errorProblem = true;
            messageErrorOne = `${e.data.name}`
            messageErrorTwo = `${index + 1}`
          }
        }
        trucksData.push({
          type: e.data.iconType,
          name: e.data.name,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          transport_company_id: e.data.transport_company_id.value,
          transport_company: e.data.transport_company_id.label,
          transport_company_number: e.data.transport_company_number,
          licence_number: '',
          driver_name: '',
          pick_up_date: renderDate(e.data.pick_up_date),
          delivery_date: renderDate(e.data.delivery_date),
          delivery_until: e.data.delivery_until,
          open_buy_request: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }
      if (e.data.iconType === 'OWN_CARPOOL') {
        if (
          e.data.delivery_until === '' ||
          typeof e.data.open_request === 'undefined' ||
          typeof e.data.open_request.id === 'undefined' ||
          e.data.open_request.id === ''
        ) {
          if (NewOrder.length > 0) {
            errorProblem = true;
            messageErrorOne = `${e.data.name}`
            messageErrorTwo = `${index + 1}`
          }
        }
        trucksData.push({
          type: e.data.iconType,
          name: e.data.name,
          status: '',
          orders: NewOrder,
          licence_number: e.data.licence_number,
          driver_name: e.data.driver_name || '',
          driver_id:
            typeof e.data.driver_id !== 'undefined' ? e.data.driver_id : '',
          level_number: '1',
          capacity: parseInt(e.data.capacity),
          transport_company_id:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company_id
              : e.data.transport_company_id.value,
          transport_company:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company
              : e.data.transport_company_id.label,
          transport_company_number:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company_number
              : e.data.transport_company_number,
          pick_up_date: renderDate(e.data.pick_up_date),
          delivery_date: renderDate(e.data.delivery_date),
          delivery_until: e.data.delivery_until,
          open_buy_request: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }

      if (e.data.iconType === 'OWN_DRIVER') {
        trucksData.push({
          name: 'Own driver',
          type: e.data.iconType,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          receiver: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }

      if (e.data.iconType === 'EXTERNAL_DRIVER') {
        trucksData.push({
          name: 'External',
          type: e.data.iconType,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          prefered_delivery_date: '',
        });
      }
    });
    let data = {
      trucks: trucksData,
      company_number: users.user.session.company_number,
      date: date,
      notes:
        typeof localStorage.getItem('globalenotes') !== 'undefined'
          ? localStorage.getItem('globalenotes')
          : '',
    };
    if (!errorProblem) {
      const res = await OrderService.SaveTourPlanning(token, date, '0', data);
      if (typeof res !== 'undefined' && res.status === 200) {
        const msg =
          language.locale === 'EN'
            ? `Successfully saved`
            : `Erfolgreich Eingetragen`;
        await resetProducer();

        await getProducer(date);

        success(msg);
        clear();
      } else {
        const msg =
          language.locale === 'EN'
            ? `Error - Please fill out the missing mandatory fields regarding to your trucks. `
            : `Fehler - Bitte füllen Sie die fehlenden Pflichtfelder innerhalb Ihrer LKWs aus.`;
        success(msg);
        clear();
      }
    } else {
      const msg =
        language.locale === 'EN'
          ? `Error - Please fill out the missing mandatory fields regarding to your trucks. ${messageErrorOne} at position [${messageErrorTwo}]   `
          : `Fehler - Bitte füllen Sie die fehlenden Pflichtfelder innerhalb Ihrer LKWs aus. ${messageErrorOne} auf Platz [${messageErrorTwo}] `;
      await resetProducer();

      await getProducer(date);
      error(msg);
      clear();
    }
  };

  const dateNewFormat = () => {
    let nameSplit = date.split('.');

    // new date format 01.01.20
    let day;
    let month;
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = month + '.' + day + '.' + nameSplit[2];

    let currentDeliveryDate = new Date(newDate);
    return currentDeliveryDate;
  };

  const handleSubmitsave = async (showNotification = true) => {
    const trucks = scene.children.filter((e) => e.children);
    const trucksData = [];

    trucks.map((e) => {
      let NewOrder = [];
      typeof e.children !== 'undefined' &&
        e.children.map((item) => {
          NewOrder.push(item.data.data);
        });

      if (e.data.iconType === 'TRANSPORT_COMPANY') {
        trucksData.push({
          type: e.data.iconType,
          name: e.data.name,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          transport_company_id: e.data.transport_company_id.value,
          transport_company: e.data.transport_company_id.label,
          transport_company_number: e.data.transport_company_number,
          licence_number: '',
          driver_name: '',
          pick_up_date: renderDate(e.data.pick_up_date),
          delivery_date: renderDate(e.data.delivery_date),
          delivery_until: e.data.delivery_until,
          open_buy_request: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }
      if (e.data.iconType === 'OWN_CARPOOL') {
        trucksData.push({
          type: e.data.iconType,
          name: e.data.name,
          status: '',
          orders: NewOrder,
          licence_number: e.data.licence_number,
          driver_name: e.data.driver_name || '',
          driver_id:
            typeof e.data.driver_id !== 'undefined' ? e.data.driver_id : '',
          level_number: '1',
          capacity: parseInt(e.data.capacity),
          transport_company_id:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company_id
              : e.data.transport_company_id.value,
          transport_company:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company
              : e.data.transport_company_id.label,
          transport_company_number:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company_number
              : e.data.transport_company_number,
          pick_up_date: renderDate(e.data.pick_up_date),
          delivery_date: renderDate(e.data.delivery_date),
          delivery_until: e.data.delivery_until,
          open_buy_request: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }

      if (e.data.iconType === 'OWN_DRIVER') {
        trucksData.push({
          name: 'Own driver',
          type: e.data.iconType,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          receiver: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }

      if (e.data.iconType === 'EXTERNAL_DRIVER') {
        trucksData.push({
          name: 'External',
          type: e.data.iconType,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          prefered_delivery_date: '',
        });
      }
    });
    let data = {
      trucks: trucksData,
      company_number: users.user.session.company_number,
      date: date,
      notes:
        typeof localStorage.getItem('globalenotes') !== 'undefined'
          ? localStorage.getItem('globalenotes')
          : '',
    };
    const res = await OrderService.SaveTourPlanning(token, date, '1', data);

    if (showNotification) {
      if (res.status === 200) {
        const msg =
          language.locale === 'EN'
            ? `Successfully updated`
            : `Erfolgreich aktualisiert`;
        await resetProducer();

        await getProducer(date);

        success(msg);
        clear();
      } else {
        const msg =
          language.locale === 'EN'
            ? `Error - Please fill out the missing mandatory fields regarding to your trucks. `
            : `Fehler - Bitte füllen Sie die fehlenden Pflichtfelder innerhalb Ihrer LKWs aus.`;
        success(msg);
        clear();
      }
    } else {
      await resetProducer();

      await getProducer(date);

    }
  };

  const handleDragBreed = async () => {
    const trucks = scene.children.filter((e) => e.children);

    const trucksData = [];

    trucks.map((e) => {
      let NewOrder = [];
      typeof e.children !== 'undefined' &&
        e.children.map((item) => {
          NewOrder.push(item.data.data);
        });

      if (e.data.iconType === 'TRANSPORT_COMPANY') {
        trucksData.push({
          type: e.data.iconType,
          name: e.data.name,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          transport_company_id: e.data.transport_company_id.value,
          transport_company: e.data.transport_company_id.label,
          transport_company_number: e.data.transport_company_number,
          licence_number: '',
          driver_name: '',
          pick_up_date: renderDate(e.data.pick_up_date),
          delivery_date: renderDate(e.data.delivery_date),
          delivery_until: e.data.delivery_until,
          open_buy_request: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }
      if (e.data.iconType === 'OWN_CARPOOL') {
        trucksData.push({
          type: e.data.iconType,
          name: e.data.name,
          status: '',
          orders: NewOrder,
          licence_number: e.data.licence_number,
          driver_name: e.data.driver_name || '',
          driver_id:
            typeof e.data.driver_id !== 'undefined' ? e.data.driver_id : '',
          level_number: '1',
          capacity: parseInt(e.data.capacity),
          transport_company_id:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company_id
              : e.data.transport_company_id.value,
          transport_company:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company
              : e.data.transport_company_id.label,
          transport_company_number:
            typeof e.data.transport_company_id === 'string'
              ? e.data.transport_company_number
              : e.data.transport_company_number,
          pick_up_date: renderDate(e.data.pick_up_date),
          delivery_date: renderDate(e.data.delivery_date),
          delivery_until: e.data.delivery_until,
          open_buy_request: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }

      if (e.data.iconType === 'OWN_DRIVER') {
        trucksData.push({
          name: 'Own driver',
          type: e.data.iconType,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          receiver: {
            _id:
              typeof e.data.open_request.id !== 'undefined'
                ? e.data.open_request.id
                : '',
            name:
              typeof e.data.open_request.value !== 'undefined'
                ? e.data.open_request.value
                : '',
          },
        });
      }

      if (e.data.iconType === 'EXTERNAL_DRIVER') {
        trucksData.push({
          name: 'External',
          type: e.data.iconType,
          status: '',
          orders: NewOrder,
          capacity: parseInt(e.data.capacity),
          prefered_delivery_date: '',
        });
      }
    });
    groupEartagsByBreed(trucksData);
  };

  if (!scene) {
    return (
      <div key={0} className="loader-prodstocks">
        <div className="loader-prodst" key={0}></div>
      </div>
    );
  }

  const truckItem = column ? column.data : null;
  let isBlockedLogic = false;
  if (column && column.data) {
    isBlockedLogic =
      typeof producer.isFinalized !== 'undefined' &&
      producer.isFinalized === 1 &&
      column.data.open_request.id !== '' &&
      column.data.trucksData.orders &&
      column.data.trucksData.orders.length >= 1;
  }

  const companyType = users.user.session.company_type;
  return (
    <Col style={{ height: '100%', width: '100%' }} md={6} sm={12}>
      <ModalTruck
        isShowing={isShowing}
        hide={toggle}
        values={truckItem}
        addTruck={addTruck}
        deleteTruck={deleteTruck}
        editTruck={editTruck}
        deliveryDate={new Date(dateNewFormat())}
        deliveryUntil={'02:00'}
        type={type}
        index={column ? column.id : null}
        setContact={setContact}
        togglePopup={togglePopup}
        isBlockedItem={isBlockedLogic}
      />
      <Modal show={showResell} onHide={handleClose} style={{ opacity: 1 }}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-resellTwo ">
          <div>
            <ReSellRequestAllContact
              externalRequest={true}
              orderDetail={orderSelling}
              companyId={users.user.session.company_id}
              orderType={'buyings'}
              getOrders={getEmpty}
              orders={orderSelling}
              getEmpty={getEmpty}
              handleClosePanel={handleClose}
              handleCloseTwo={handleCloseTwo}
              checked={false}
              executeService={false}
              showAll={true}
              editTruck={editTruckExternal}
              deliveryDate={new Date(dateNewFormat())}
              values={truckItem}
              index={column ? column.id : null}
              cond={true}
              date={dateSelling}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showResellOnly}
        onHide={handleCloseOnly}
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modal-resellopen ">
          <div>
            <ReSellRequestAllContact
              externalRequest={true}
              orderDetail={orderSelling}
              companyId={users.user.session.company_id}
              orderType={'buyings'}
              getOrders={getEmpty}
              orders={orderSelling}
              getEmpty={getEmpty}
              handleClosePanel={handleCloseOnly}
              handleCloseTwo={handleCloseTwo}
              checked={false}
              executeService={false}
              showAll={false}
              editTruck={editTruckExternal}
              deliveryDate={new Date(dateNewFormat())}
              values={truckItem}
              index={column ? column.id : null}
              cond={false}
              date={dateSelling}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={confirm} onHide={handleCloseConfirm} style={{ opacity: 1 }}>
        <Modal.Body>
          <h4>
            <FormattedMessage {...messages.ConfirmValidation} />
            {/* {this.state.selectedUser.name} */}
          </h4>
        </Modal.Body>

        <Modal.Footer style={{ textAlign: 'center' }}>
          <div className="view-producer">
            <NormalButton
              type="submit"
              style={{ width: '40%', marginRight: '10px' }}
              onClick={() => {
                handleSubmit();
                handleCloseConfirm();
              }}
            >
              <div className="sizetextbtn">
                {' '}
                <FormattedMessage {...messages.ConfirmYes} />
              </div>
            </NormalButton>

            <NormalButton
              grey
              type="submit"
              className=""
              style={{ width: '40%' }}
              onClick={() => {
                //handleSubmitsave();
                handleCloseConfirm();
              }}
            >
              <div className="sizetextbtn">

                <FormattedMessage {...messages.ConfirmBack} />
              </div>
            </NormalButton>
          </div>
        </Modal.Footer>
      </Modal>

      {renderModalNumber()}

      <div style={{ height: '100%' }}>
        <Row className="panel-body-row">
          <Col className="panel-body-col" xs={12} md={12}>
            <div style={{ height: '8%' }} className="view-producer">

              {typeof producer.isFinalized !== 'undefined' &&
                producer.isFinalized === 1
                ?
                (

                  <NormalButton
                    grey
                    type="submit"
                    className="view-producer-b1-new "
                    onClick={handleSubmitsave}
                    disabled={
                      typeof producer.isFinalized !== 'undefined' &&
                        producer.isFinalized === 1
                        ? true
                        : false
                    }
                  >
                    <div className='sizetextbtn'>
                      <FormattedMessage {...messages.TruckAdd} />
                    </div>
                  </NormalButton>)

                : (

                  <div className="view-producer-b1-new">
                    <div
                      className={
                        btnAddTruckIsDiable
                          ? ['accordions2', 'disabled'].join(' ')
                          : 'accordions2'
                      }
                    >

                      <div href="#" className="accordions-toggle2">
                        <FormattedMessage {...messages.TruckAdd} />
                      </div>
                      <div className="accordions-inner2">
                        <div
                          className="accordions-text2"
                          onClick={() => togglePopup('OWN_CARPOOL')}
                        >
                          <FormattedMessage {...messages.OwnCarpool} />
                        </div>
                        <div
                          className="accordions-text2"
                          onClick={() => togglePopup('MANUAL_ENTRY')}
                        >
                          <FormattedMessage {...messages.TransportCompany} />
                        </div>
                      </div>

                    </div></div>)}



              <NormalButton
                grey
                type="submit"
                className="view-producer-b1-new "
                onClick={handleSubmitsave}
                disabled={
                  typeof producer.isFinalized !== 'undefined' &&
                    producer.isFinalized === 1
                    ? true
                    : false
                }
              >
                <div className="sizetextbtn">
                  <FormattedMessage {...messages.cattleUpdate} />
                </div>
              </NormalButton>

              <NormalButton type="submit" onClick={handleOpenConfirm} disabled={
                typeof producer.isFinalized !== 'undefined' &&
                  producer.isFinalized === 1
                  ? true
                  : false
              }>
                <div className="sizetextbtn">
                  {' '}
                  <FormattedMessage {...messages.hplanning} />{' '}
                </div>
              </NormalButton>
            </div>
            <div
              className="cattelgroupitem"
              // onClick={(e) => setIsReadMore(!isReadMore)}
              style={{
                height:
                  openByrequestCattel.length === 0
                    ? '0px'
                    : openByrequestCattel.length === 1
                      ? '5%'
                      : '9%',
              }}
            >
              <div>
                {openByrequestCattel.length > 0 &&
                  openByrequestCattel.map((e, i) => (
                    <div key={e.sender_name + '' + i} className="scrollBoxhemp">
                      <div
                        // style={{ width: '45%' }}
                        className="tour-item-twovaltourPart"
                      >
                        <span className="tour-item-twovaltour scroll-boxitemText1">
                          {' '}
                          <FormattedMessage
                            {...messages.ProducerBuyrequest}
                          />{' '}
                          {findAndReplace(e.request_name, language)}{' '}
                          <FormattedMessage
                            {...messages.ProducerBuyrequestFom}
                          />{' '}
                          {`${e.sender_name}: `}
                        </span>
                      </div>
                      <div className="scroll-boxitem ">
                        <ScrollMenu
                          alignCenter={false}
                          data={
                            e.cattelGroup &&
                            e.cattelGroup.map((child, i) => (
                              <div
                                key={child.sender_name + '' + child.breed}
                                className="scrollboxitems "
                              >
                                <div className="scroll-boxitemText2">
                                  <span>
                                    {` ${child.nbr}/${child.qty} ${child.breed} `}
                                  </span>
                                </div>
                              </div>
                            ))
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div
              style={{
                height:
                  openByrequestCattel.length === 0
                    ? '90.1%'
                    : openByrequestCattel.length === 1
                      ? '89.1%'
                      : '85.1%',
              }}
            >
              <div className="sizeofdrag">
                {typeof scene.children !== 'undefined' &&
                  scene.children.map((column) => (
                    <Column
                      key={column.id}
                      column={column}
                      renderCardHeader={() =>
                        renderCardHeader(
                          column,
                          animals,
                          deleteTruck,
                          togglePopup,
                          handleOpenOnly,
                          trucksShow,
                          producer,
                        )
                      }
                      trucksShow={trucksShow}
                      lang={lang}
                      getOrders={getEmpty}
                      onCardDrop={onCardDrop}
                      getCardPayload={getCardPayload}
                      renderOrderData={renderOrderData}
                      isfinished={typeof producer.isFinalized !== 'undefined'}
                    />
                  ))}
              </div>
            </div>
            <div className="button-truck-field btn-complete-outer tour-planning-bottom-part"></div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const Column = memo(
  ({
    column,
    renderCardHeader,
    onCardDrop,
    getCardPayload,
    renderOrderData,
    lang,
    trucksShow,
    getOrders,
    isfinished
  }) => (
    <div
      style={
        column.data.trucksData && !!column.data.trucksData.isBlocked
          ? { filter: 'grayscale(100%)' }
          : {}
      }
      className={column.className}
    >
      <div className={column.props.className}>
        {trucksShow && renderCardHeader()}

        <Container
          {...column.props}
          columnId={column.id}
          level={column.level}
          data={column.data}
          groupName="col"
          onDragStart={() => { }}
          onDragEnd={() => { }}
          onDrop={(e) => onCardDrop(column, e)}
          getChildPayload={(index) => getCardPayload(column.id, index)}
          dragClass="opacity-ghost"
          dropClass="opacity-ghost-drop"
          onDragEnter={(e) => { }}
          onDragLeave={(e) => { }}
          onDropReady={(e) => { }}
          dropPlaceholder={{
            animationDuration: 150,
            showOnTop: true,
            className: 'drop-preview',
          }}
          dropPlaceholderAnimationDuration={200}
          shouldAcceptDrop={(sourceContainerOptions, payload) => {
            if (
              sourceContainerOptions.level === 'truck' &&
              column.level === 'truck' &&
              sourceContainerOptions.columnId !== column.id
            ) {
              return false;
            }

            if (
              typeof column.data !== 'undefined' &&
              column.data.capacity < payload.data.number
            ) {
              return false;
            }
            if (
              (((typeof payload.data.status !== 'undefined' &&
                payload.data.status !== 'BOUGHT' &&
                payload.data.status !== 'TOUR_ASSIGNED') ||
                (column.data.trucksData &&
                  column.data.trucksData.isBlocked &&
                  column.data.trucksData.isBlocked === 1)))
            ) {
              return false;
            }

            if (
              isfinished === true
            ) {
              return false
            }

            if (
              column.level === 'truck' &&
              sourceContainerOptions.level === 'order'
            ) {
              return column.data.size < column.data.capacity;
            }
            return true;
          }}
        >
          {column.children.map((card) => (
            <DraggableItem
              key={card.id}
              card={card}
              lang={lang}
              renderOrderData={renderOrderData}
              getOrders={getOrders}
            />
          ))}
        </Container>
      </div>
    </div>
  ),
);

const DraggableItem = ({ card, renderOrderData, lang, getOrders }) => (
  <Draggable>
    <div {...card.props}>
      {renderOrderData(card.data, lang, card, getOrders)}
    </div>
  </Draggable>
);

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    deleteOrder: (orderId) => dispatch(OrderActions.deleteOrder(orderId)),
    getOrders: (orderType, type, page, date) =>
      dispatch(OrderActions.getOrders(orderType, type, page, date)),
    getProducer: (date) => dispatch(producerActions.getAllProducer(date)),
    resetProducer: () => dispatch(producerActions.resetProducerOrder()),
  };
}

const mapStateToProps = (state) => {
  const { users, language, producer } = state.toJS();
  return {
    users,
    language,
    producer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourListRight);
