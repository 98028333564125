import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { alertActions } from '../../../../../actions';
import { OrderService } from '../../../../../services/order.service';
import { userService } from '../../../../../services/user.service';
import extractData from '../../../../../services/data.processing';
import EartagListe from './EartagListe';
import TruckItem from './TruckItem';
import DocumentationListe from './DocumentationListe';
import NormalButton from '../../../../Shared/components/buttons/NormalButton/NormalButton';
import messages from '../../../messages';
import MessagePopup from '../addEartags';
import { Col, Row } from 'react-bootstrap';

class TobeLoadedNextStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      include_signature: 0,
      imageSrc: '',
      docs: [],
      signatureLength: 0,
      showNote: false,
    };
  }

  UNSAFE_componentWillMount() {
    const responseSignature = userService.getSignature(
      this.props.users.user._id,
      this.props.users.accessToken,
    );
    responseSignature
      .then((res) => {
        this.setState({ signatureLength: res.length });
        const imageSrc = 'data:image/png;base64,' + res;
        this.setState({ imageSrc: imageSrc });
      })
      .catch((e) => {});
  }
  setShowNote = () => {
    this.setState({ showNote: !this.state.showNote });
  };
  setSignature = async () => {
    await this.setState({ showPopup: !this.state.showPopup });
    this.state.showNote && this.prepareDocuments() && this.props.showLastPage();
  };
  loadDeliveryNote = async () => {
    try {
      await this.prepareDocuments();
      this.props.showLastPage();
      if (this.state.showPopup) {
        this.setState({ showPopup: !this.state.showPopup });
      }
    } catch (error) {}
  };
  handleSignaturePopup = () => {
    this.state.signatureLength > 600
      ? this.loadDeliveryNote()
      : this.setSignature();
  };

  prepareDocuments = async () => {
    const {
      orderId,
      vehicleNumber,
      driver,
      truck,
      ear_tags,
      ear_tags_splite,
    } = this.props;

    const listEartags = truck.has_orders_split ? ear_tags_splite : ear_tags;
    if (listEartags.length === 0) {
      await this.props.error('Bitte Ohrmarken auswählen');
      await this.props.clear();
      throw new Error('Eartags not selected');
    }
    const CurrentTime = new Date().toLocaleTimeString('en-US', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric',
    });
    const token = this.props.users.accessToken;

    const eartags = listEartags.map((t) => ({
      ear_tag: t.ear_tag,
      order_id: orderId,
    }));

    if (eartags.length > 0) {
      const serviceData = {
        trucks: [
          {
            _id: truck._id,
            vehicle_number: vehicleNumber,
            driver: driver,
            loading_time: CurrentTime,
            ear_tags: eartags,
          },
        ],
        // base64 to String
        signature: '',
      };
      const valueL = await OrderService.postLoadingDataPrepareDocumentation(
        token,
        orderId,
        serviceData,
      );
      if (valueL.status === 200) {
        const data = await extractData(valueL);
        this.setState({ docs: [...data.trucks[0].loading_data_documents] });
      }
    }
  };

  render() {
    const {
      setEartag,
      ear_tags,
      truck,
      deleteEartag,
      driver,
      vehicleNumber,
      showAddEartagPanel,
      showLastPAge,
      handlePopup,
      checkRemove,
      handleTobeLoadingService,
      ear_tags_splite,
    } = this.props;
    return (
      <React.Fragment>
        {this.state.showPopup && (
          <MessagePopup
            show={this.state.showPopup}
            togglePopup={this.setSignature}
            showNote={this.setShowNote}
            message={'addsignature'}
          />
        )}
        <TruckItem item={truck} vehicleNumber={vehicleNumber} driver={driver} />
        {!showLastPAge && (
          <React.Fragment>
            <Row className="tours-top-container">
              <Col md={3} className="tours-image">
                <span className="tour-name">
                  {this.props.ear_tags.length}{' '}
                  {this.props.ear_tags.length === 1 ? (
                    <FormattedMessage {...messages.EarTag} />
                  ) : (
                    <FormattedMessage {...messages.EarTags} />
                  )}
                </span>
              </Col>
              <Col className="tour-text-groupe" md={6}>
                <NormalButton block onClick={() => showAddEartagPanel()}>
                  <FormattedMessage {...messages.AddEartag} />
                </NormalButton>
              </Col>
            </Row>
            <EartagListe
              eartagListe={ear_tags}
              eartagListeSplite={ear_tags_splite}
              setEartag={setEartag}
              deleteEartag={deleteEartag}
              checkRemove={checkRemove}
              handlePopup={handlePopup}
              truck={truck}
            />
            <div className="button-block-field">
              <NormalButton
                onClick={() => {
                  this.handleSignaturePopup();
                }}
              >
                <FormattedMessage {...messages.Next} />
              </NormalButton>
            </div>
          </React.Fragment>
        )}
        {showLastPAge && (
          <div className="add-eartag-block-field-docs">
            <DocumentationListe docs={this.state.docs} />
            <div className="button-block-field">
              <NormalButton onClick={handleTobeLoadingService}>
                <FormattedMessage {...messages.CompleteLoadingProcess} />
              </NormalButton>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TobeLoadedNextStep);
