import React, { useState, useRef } from 'react';
import Select from 'react-select';
import useOutsideClick from '../useOutsideClick';

const CustomSelect = ({
  name,
  className,
  value,
  defaultValue,
  options,
  onChange,
  styles,
  theme,
  ...props
}) => {
  const ref = useRef();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  useOutsideClick(
    ref,
    () => !props.isDisabled && setMenuIsOpen(!menuIsOpen),
    () => setMenuIsOpen(false),
  );
  return (
    <div ref={ref}>
      <Select
        name={name}
        menuIsOpen={menuIsOpen}
        className={className}
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        styles={styles}
        components={{
          IndicatorSeparator: () => null,
        }}
        theme={theme}
        {...props}
      />
    </div>
  );
};

export default CustomSelect;
