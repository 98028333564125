import React from 'react';
import clsx from 'clsx';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Form } from 'formik';
import { connect } from 'react-redux';
import extractData from '../../../../../services/data.processing';
import { alertActions } from '../../../../../actions';
import { getAllTruck } from '../../../../../services/company.service';

import { userService } from '../../../../../services';
import DatePickerField from './DatePicker';

import './form.css';
import '../../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import RegularInput from '../../../../Shared/components/inputs/RegularInput';
import NormalButton from '../../../../Shared/components/buttons/NormalButton/NormalButton';

import messages from '../../../../../components/Admin/messages';

class TruckForm extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      optionsLicence: [],
      optionsDriver: [],
      optionsSelectedDriver: {},
      driverSelected: false,
      licenceSelected: false,
    };
  }
  UNSAFE_componentWillMount() {
    this.fetchTrucks();
  }
  componentDidMount() {
    this._isMounted = true;
    this.calendarRefTour = React.createRef();
    this.calendarRefTourUntil = React.createRef();
    this._isMounted && this.focusTextInput();
    this.fetchTrucks();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  focusTextInput = () => {
    if (this.calendarRefTour && this.calendarRefTour.input) {
      this.calendarRefTour.input.readOnly = true;
    }
  };

  fetchTrucks = async () => {
    try {
      const newState = {};
      const token = this.props.users.accessToken;
      const name = this.props.users.user.name;
      const userId = this.props.users.user._id;
      const value = await getAllTruck(token);
      const trucks = await extractData(value);
      const optionsLicence = trucks.map((val) => ({
        value: val,
        label: val.licence_number,
      }));
      newState.optionsLicence = optionsLicence;
      const response = await userService.fetchUsers(0, 20, token);
      const users = await extractData(response);
      const optionsDriver = users
        .filter(
          (e) => e.role === 'ROLE_TRD_MANAGER' || e.role === 'ROLE_TRD_DRIVER',
        )
        .map((e) => ({
          value: e.name,
          label:
            e.name +
            (e.role === 'ROLE_TRD_DRIVER'
              ? this.props.language.locale === 'EN'
                ? ` Driver`
                : ` Fahrer`
              : this.props.language.locale === 'EN'
              ? ` Manager`
              : ` Einkäufer`),
          _id: e._id,
        }));

      optionsDriver.push({
        value: name,
        label:
          name +
          (this.props.language.locale === 'EN' ? ` Manager` : ` Einkäufer`),
        _id: userId,
      });

      newState.optionsDriver = optionsDriver;
      this.setState(newState);

      let optionsDriverData = [];
      if (typeof this.props.values.driver_name === 'string') {
        optionsDriverData = newState.optionsDriver.filter(
          (e) => e.value === this.props.values.driver_name,
        );
      } else {
        if (typeof this.props.values.driver_name === 'undefined') {
          optionsDriverData = [];
        } else {
          optionsDriverData = newState.optionsDriver.filter(
            (e) =>
              e.label.substring(0, e.label.lastIndexOf(' ')) ===
              this.props.values.driver_name.label,
          );
        }
      }

      this.setState({
        optionsLicence: newState.optionsLicence,
        optionsDriver: newState.optionsDriver,
        optionsSelectedDriver: optionsDriverData[0],
        isLoaded: true,
      });
    } catch (e) {}
  };

  toggleSelectedDriver = () => {
    this.setState({ driverSelected: true });
  };

  toggleSelectedLicence = () => {
    this.setState({ licenceSelected: true });
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      editeTruck,
      deleteTruckData,
      editTransportTruck,
      type,
      setFieldValue,
      index,
    } = this.props;
    const { optionsDriver, optionsLicence, driverSelected, licenceSelected } =
      this.state;
    return (
      <Card.Body className="contact-panel-body">
        <Form className="own-carpool-form">
          <div className="flex truck-form">
            {type === 'OWN_CARPOOL' && (
              <div className="o-c-form-container">
                <div className="inner-form-top">
                  <div className="flex-container">
                    <div className="inner-flex">
                      {this.props.users.user.session.company_type === '2' ||
                      this.props.users.user.session.company_type === '4' ? (
                        <div>
                          <RegularInput
                            select
                            options={optionsDriver}
                            label={
                              <FormattedMessage {...messages.DriverName} />
                            }
                            name="driver_name"
                            className={clsx(
                              (driverSelected || values.driver_name) &&
                                'selected',
                            )}
                            defaultValue={{
                              value: values.driver_name,
                              label: values.driver_name,
                              _id: values.driver_id,
                            }}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                'driver_name',
                                selectedOption.label,
                              );
                              this.toggleSelectedDriver();
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,

                              colors: {
                                ...theme.colors,
                                primary25: '#F1FFDE',
                                primary: '#7bcd10',
                              },
                            })}
                          />
                          <span className="focus-border"></span>

                          {touched.driver_name && errors.driver_name && (
                            <p className="error-message transport-Company-error">
                              {errors.driver_name}
                            </p>
                          )}
                        </div>
                      ) : (
                        <div>
                          <RegularInput
                            label={
                              <FormattedMessage {...messages.DriverName} />
                            }
                            type="text"
                            id="driver-name"
                            name="driver_name"
                            onChange={handleChange}
                            defaultValue={values.driver_name}
                            onBlur={handleBlur}
                          />
                        </div>
                      )}
                    </div>
                    <div className="inner-flex">
                      <DatePickerField
                        label={<FormattedMessage {...messages.PickUPDate} />}
                        name="pick_up_date"
                        value={values.pick_up_date}
                        onChange={setFieldValue}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRefTour}
                        onBlur={this.synchronizeDates}
                      />

                      <span className="focus-border"></span>
                      {touched.pick_up_date && !errors.pick_up_date && <span />}
                      {touched.pick_up_date && errors.pick_up_date && (
                        <p className="error-message">{errors.pick_up_date}</p>
                      )}
                    </div>
                  </div>

                  <div className="flex-container">
                    <div className="inner-flex">
                      {this.props.users.user.session.company_type === '2' ||
                      this.props.users.user.session.company_type === '4' ? (
                        <div>
                          <RegularInput
                            select
                            options={optionsLicence}
                            label={
                              <FormattedMessage {...messages.LicenceNumber} />
                            }
                            name="licence_number"
                            className={clsx(
                              (licenceSelected || values.licence_number) &&
                                'selected',
                            )}
                            defaultValue={{
                              value: values.licence_number,
                              label: values.licence_number,
                            }}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                'licence_number',
                                selectedOption.label,
                              );
                              this.toggleSelectedLicence();
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,

                              colors: {
                                ...theme.colors,
                                primary25: '#F1FFDE',
                                primary: '#7bcd10',
                              },
                            })}
                          />
                          <span className="focus-border"></span>
                          {touched.licence_number && !errors.licence_number && (
                            <span />
                          )}
                          {touched.licence_number && errors.licence_number && (
                            <p className="error-message">Required</p>
                          )}
                        </div>
                      ) : (
                        <div>
                          <RegularInput
                            label={
                              <FormattedMessage {...messages.LicenceNumber} />
                            }
                            id="licence-number"
                            name="licence_number"
                            type="text"
                            onChange={handleChange}
                            defaultValue={values.licence_number}
                          />
                        </div>
                      )}
                    </div>
                    <div className="inner-flex">
                      <DatePickerField
                        label={<FormattedMessage {...messages.DeliveryDate} />}
                        name="delivery_date"
                        value={values.delivery_date}
                        onChange={setFieldValue}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRefTourUntil}
                      />

                      <span className="focus-border"></span>
                      {touched.delivery_date && !errors.delivery_date && (
                        <span />
                      )}
                      {touched.delivery_date && errors.delivery_date && (
                        <p className="error-message">Check Date!</p>
                      )}
                    </div>
                  </div>
                  <div className="flex-container">
                    <div className="inner-flex">
                      <RegularInput
                        type="number"
                        id="capacity"
                        name="capacity"
                        onChange={handleChange}
                        defaultValue={values.capacity || '30'}
                        onBlur={handleBlur}
                        required
                        label={<FormattedMessage {...messages.Capacity} />}
                      />
                      <span className="focus-border"></span>
                      {touched.capacity && !errors.capacity && <span />}
                      {touched.capacity && errors.capacity && (
                        <p className="error-message">Capacity required</p>
                      )}
                    </div>
                    <div className="inner-flex">
                      <RegularInput
                        type="time"
                        id="delivery-until"
                        name="delivery_until"
                        onChange={handleChange}
                        defaultValue={values.delivery_until}
                        onBlur={handleBlur}
                        required
                        label={<FormattedMessage {...messages.DeliveryUntil} />}
                      />
                      <span className="focus-border"></span>
                      {touched.delivery_until && !errors.delivery_until && (
                        <span />
                      )}
                      {touched.delivery_until && errors.delivery_until && (
                        <p className="error-message">Check Time</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="tour-planning-popup-btn inner-form-top">
                  {!editeTruck ? (
                    <NormalButton type="submit">
                      <FormattedMessage {...messages.TruckAdd} />
                    </NormalButton>
                  ) : (
                    <React.Fragment>
                      <NormalButton
                        type="button"
                        onClick={() => editTransportTruck(index, values)}
                        className="edit-truck top"
                      >
                        <FormattedMessage {...messages.TruckSave} />
                      </NormalButton>

                      <NormalButton
                        type="button"
                        className="edit-truck"
                        onClick={() => deleteTruckData(index)}
                      >
                        <FormattedMessage {...messages.TruckDelete} />
                      </NormalButton>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </Form>
      </Card.Body>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TruckForm);
