import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdNavigateNext } from 'react-icons/md';
import { Col, Row, ListGroupItem, Image } from 'react-bootstrap';
import truckToBeLoaded from '../../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import truckLoaded from '../../../../static/images/admin/Icons/Status icons/Loaded.png';
import truckOnTheWay from '../../../../static/images/admin/Icons/Status icons/Ontheway.png';
import truckDelivered from '../../../../static/images/admin/Icons/Status icons/Delivered.png';
import truckSlaughered from '../../../../static/images/admin/Icons/Status icons/Slaughered.png';
import truckUnloaded from '../../../../static/images/admin/Icons/Status icons/Unloaded.png';
import Tourplanning from '../../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import Unloaded from '../../../../static/images/admin/Icons/Status icons/Unloaded.png';
import Planned from '../../../../static/images/admin/Icons/Status icons/Planned.png';

import { upperCaseToNomal } from '../../../../helpers/order';
import { statusOrders } from '../status-orders';

import './tour.css';
class TruckItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleTruckIcon();
  }

  ColorIcon = (status) => {
    if (
      status === 'LOADED' ||
      status === 'TO_BE_LOADED' ||
      status === 'TOUR_ASSIGNED'
    )
      return 'tourInf-title-span-texttwo';
    if (status === 'ON_THE_WAY') return 'tourInf-title-span-textthree';
    else {
      return 'tourInf-title-span-text';
    }
  };

  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  handleTruckIcon = () => {
    const { status } = this.props.truck;
    let truckIcon;
    // eslint-disable-next-line default-case
    switch (status) {
      case 'TO_BE_LOADED':
        truckIcon = truckToBeLoaded;
        break;
      case 'LOADED':
        truckIcon = truckLoaded;
        break;
      case 'ON_THE_WAY':
        truckIcon = truckOnTheWay;
        break;
      case 'ULOADED':
        truckIcon = truckUnloaded;
        break;
      case 'DELIVERED':
        truckIcon = truckDelivered;
        break;
      case 'SLAUGHTERED':
        truckIcon = truckSlaughered;
        break;
      case 'TOUR_ASSIGNED':
        truckIcon = Tourplanning;
        break;
      case 'UNLOADED':
        truckIcon = Unloaded;
        break;
      case 'PLANNED':
        truckIcon = Planned;
        break;
    }
    this.setState({ truckIcon });
  };

  render() {
    const { truck, showTruckDetail } = this.props;
    return (
      <ListGroupItem key={truck._id} className="list-group truck-item">
        <Row onClick={() => showTruckDetail(truck)}>
          <Col md={3} className="status">
            <Image className="status-image" src={this.state.truckIcon} />
            <p className="status-name">
              {
                statusOrders.find(
                  (val) =>
                    truck.status.split('_').join(' ') === val.statusOrder,
                ).label
              }
            </p>
          </Col>
          <Col md={7}>
            <div className={`truck-name ${this.ColorIcon(truck.status)}`}>
              {' '}
              {truck.delivery_until} {truck.partner}
            </div>
            <div className="info-item">
              {this.findAndReplace(truck.name)} | {truck.vehicle_number}
            </div>
          </Col>
          <Col md={2} className="MdNavigateNext">
            <p className="shared">
              <MdNavigateNext />
            </p>
          </Col>
        </Row>
        <hr className="style-border" />
      </ListGroupItem>
    );
  }
}

const mapStateToProps = (state) => {
  const { language } = state.toJS();
  return { language };
};

export default connect(mapStateToProps, null)(TruckItem);
