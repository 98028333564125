/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import './css/main.css';
import base from '../../components/Chat/base';
import ChatList from './ChatList';
import { ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { userActions } from '../../actions';

class ChatAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      messages: {},
      peopleDisplay: '',
      showChat: 'none',
      peoples: 'none',
      chatStatus: false,
    };
  }

  async componentDidMount() {
    this.refMessages = await base.syncState('/messages', {
      context: this,
      state: 'messages',
    });
    this.refChatStatus = await base.syncState('/chatStatus', {
      context: this,
      state: 'chatStatus',
    });
  }

  createMessage = event => {
    event.preventDefault();
    let pseudo = null;
    pseudo = 'admin';
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = dd + '/' + mm + '/' + yyyy;
    const message = {
      pseudo: pseudo,
      message: this.message.value,
      id_user: this.state.peopleDisplay,
      createAt: today,
    };
    if (this.message.value !== '') {
      this.addMessage(message);
    }

    // Reset
    this.messageForm.reset();
  };

  addMessage = message => {
    // 1 MAJ state
    const messages = { ...this.state.messages };
    const length = Object.keys(this.state.messages).length + 1;
    // 2 On ajoute le message avec une clé timestamp
    const timestamp = Date.now();
    messages[`${timestamp}-message-${length}`] = message;

    // 3 Set State
    this.setState({ messages });
  };

  selectPeople = id => {
    this.state.peopleDisplay = id;
    this.setState({ peopleDisplay: id });
  };

  removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  changeStatusChat = e => {
    this.setState({ chatStatus: e });
  };

  clearBase = e => {
    base.remove('messages', function(err) {
      if (!err) {
      }
    });
  };

  getHistory = e => {
    const peoples = [];
    for (var key in this.state.messages) {
      peoples.push({
        id: key,
        id_user: this.state.messages[key].id_user,
        message: this.state.messages[key].message,
        createAt: this.state.messages[key].createAt,
      });
    }
    var uniqueArray = this.removeDuplicates(peoples, 'id_user');
    this.state.peoples = uniqueArray;

    return this.state.peoples.map((person, index) => (
      <div key={index} className="chat_people">
        <div className="chat_img"> </div>
        <div className="chat_ib">
          <p
            onClick={e => this.selectPeople(person.id_user)}
            className="chat_title"
          >
            {person.id_user}{' '}
            <span className="chat_date">{person.createAt}</span>
          </p>
          <p>{person.message}</p>
        </div>
      </div>
    ));
  };

  getMessages = e => {
    return Object.keys(this.state.messages).map(key => (
      <ChatList
        key={key}
        details={this.state.messages[key]}
        idUser={this.state.messages[key].id_user}
        isUser={this.state.messages[key].pseudo}
        selected={this.state.peopleDisplay}
      />
    ));
  };

  render() {
    return (
      <div id="chat_container">
        {this.state.chatStatus && (
          <ToggleButtonGroup
            type="radio"
            name="options"
            defaultValue={true}
            onChange={this.changeStatusChat}
          >
            <ToggleButton value={true}>Chat Activate</ToggleButton>
            <ToggleButton value={false}>Chat Not Activate</ToggleButton>
          </ToggleButtonGroup>
        )}
        {!this.state.chatStatus && (
          <ToggleButtonGroup
            type="radio"
            name="options"
            defaultValue={false}
            onChange={this.changeStatusChat}
          >
            <ToggleButton value={true}>Chat Activate</ToggleButton>
            <ToggleButton value={false}>Chat Not Activate</ToggleButton>
          </ToggleButtonGroup>
        )}
        <Button onClick={this.clearBase}>Clear data</Button>

        <h3 className=" text-center">Messaging {this.state.peopleDisplay}</h3>
        <div className="messaging">
          <div className="inbox_msg">
            <div className="inbox_people">
              <div className="inbox_chat">
                <div className="chat_list">{this.getHistory()}</div>
              </div>
            </div>
            <div className="mesgs">
              <div className="msg_history">{this.getMessages()}</div>
              <div className="type_msg">
                <div className="input_msg_write">
                  <form
                    className="form"
                    onSubmit={e => this.createMessage(e)}
                    ref={input => (this.messageForm = input)}
                  >
                    <div className="bottom_wrapper clearfix">
                      <div className="message_input_wrapper">
                        <input
                          className="message_input"
                          placeholder="Type your message here..."
                          maxLength="140"
                          ref={input => (this.message = input)}
                        />
                      </div>

                      <div className="send_message">
                        <div className="icon"></div>
                        <div className="text">
                          <button className="msg_send_btn" type="submit">
                            <i
                              className="fa fa-paper-plane-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ActivateChat: val => dispatch(userActions.ActivateChat(val)),
  };
}

const mapStateToProps = state => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatAdmin);
