import React from 'react';
import './style.css';
export default props => (
  <div className={'card' + (props.plain ? ' card-plain' : '')}>
    <div className={'header' + (props.hCenter ? ' text-center' : '')}>
      <h4 className="title">{props.title}</h4>
    </div>
    <div
      className={
        'content' +
        (props.ctAllIcons ? ' all-icons' : '') +
        (props.ctTableFullWidth ? ' table-full-width' : '') +
        (props.ctTableResponsive ? ' table-responsive' : '') +
        (props.ctTableUpgrade ? ' table-upgrade' : '')
      }
    >
      {props.content}

      <div className="footer">
        {props.legend}
        {props.stats != null ? <hr /> : ''}
      </div>
    </div>
  </div>
);
