/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  HomeViewStock: {
    id: 'agrabiz.components.home.view.stock',
    defaultMessage: 'View & Sort Stock',
  },
  HomeDrugAdministration: {
    id: 'agrabiz.components.home.drug.administration',
    defaultMessage: 'Drug Administration',
  },
  HomeBuyings: {
    id: 'agrabiz.components.home.buyings',
    defaultMessage: 'Buy Request',
  },
  HomeSellings: {
    id: 'agrabiz.components.home.sellings',
    defaultMessage: 'Sell Offer',
  },
  HomeReportsSearch: {
    id: 'agrabiz.components.home.reports.search',
    defaultMessage: 'Reports & Search',
  },
  HomeShareYour: {
    id: 'agrabiz.components.home.share.your',
    defaultMessage: 'Share your',
  },
  HomeWelcomTo: {
    id: 'agrabiz.components.home.welcom.to',
    defaultMessage: 'Welcome To',
  },
  HomeTextSubscriptionPlan: {
    id: 'agrabiz.components.home.subscription.plan',
    defaultMessage:
      'Choose a subscription plan to profit from our full features',
  },
  HomeChoosePlann: {
    id: 'agrabiz.components.home.choose.plan',
    defaultMessage: 'Choose Plan',
  },
  HomeContinueYour: {
    id: 'agrabiz.components.home.continue.your',
    defaultMessage: 'Continue your',
  },
  HomeFree: {
    id: 'agrabiz.components.home.free',
    defaultMessage: 'free',
  },
  HomeTrial: {
    id: 'agrabiz.components.home.trial',
    defaultMessage: 'trial',
  },
  HomeOr: {
    id: 'agrabiz.components.home.or',
    defaultMessage: 'or',
  },
});
