import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row, ListGroupItem, Image, Card } from 'react-bootstrap';
import { pullAllBy } from 'lodash';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import { alertActions } from '../../../../actions';
import { OrderService } from '../../../../services/order.service';
import { userService } from '../../../../services/user.service';
import extractData from '../../../../services/data.processing';
import messages from '../../messages';
import ToBeLoadedForm from './form';
import TourInformation from '../tourMangement/TourInformation';
import EartagForm from '../../ViewStock/Form';
import ViewStock from '../../ViewStock';
import ViewStockLoaded from '../../ViewStockLoaded';
import TourInfoDirect from '../tourInfo/TourInfoDirect';
import ToBeLoadedNextStep from './toBeLoadedNextStep';

import MessagePopup from './addEartags';

import truckIcon from '../../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import truckLoaded from '../../../../static/images/admin/Icons/Status icons/Loaded.png';
import truckOnTheWay from '../../../../static/images/admin/Icons/Status icons/Ontheway.png';
import truckDelivered from '../../../../static/images/admin/Icons/Status icons/Delivered.png';
import truckSlaughered from '../../../../static/images/admin/Icons/Status icons/Slaughered.png';
import truckUnloaded from '../../../../static/images/admin/Icons/Status icons/Unloaded.png';
import Tourplanning from '../../../../static/images/admin/Icons/Status icons/Tourplanning.png';
import { upperCaseToNomal } from '../../../../helpers/order';
import { statusOrders } from '../status-orders';

import './toBeLoaded.css';

class TobeLoaded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'active',
      trucks: [],
      orderId: this.props.orderDetail.order_id,
      data: {},
      isValid: false,
      ear_tags: [],
      ear_tags_splite: [],
      show: false,
      vehicleNumber: '',
      driver: '',
      showForm: false,
      earTagItem: null,
      showEarTagItem: false,
      showAddearTag: false,
      showTruckList: false,
      formData: {},
      showLastPAge: false,
      listAddEartag: [],
      message: 'Select Eartag before go to next page',
      checkRemove: false,
      itemToRemove: {},
      truck: {},
      checkCapacity: false,
      isToBeLoaded: true,
      showPassportList: false,
      showTitle: true,
      imageSrc: '',
    };
  }

  showOrderDetail = (truck) => {
    this.setState({
      showTruckList: false,
      showForm: true,
      ear_tags: truck.ear_tags,
      ear_tags_splite: truck.has_orders_split === 0 ? truck.ear_tags : [],
      final_ear_tags: [],
      truck,
      vehicleNumber: truck.vehicle_number,
      driver: truck.driver,
    });
  };

  getIcon = (status) => {
    if (status === 'LOADED') return truckLoaded;
    if (status === 'ON_THE_WAY') return truckOnTheWay;
    if (status === 'DELIVERED') return truckDelivered;
    if (status === 'UNLOADED') return truckUnloaded;
    if (status === 'SLAUGHTERED') return truckSlaughered;
    if (status === 'SLAUGHTERED') return truckSlaughered;
    if (status === 'TOUR_ASSIGNED') return Tourplanning;
    if (status === 'TO_BE_LOADED') {
      return truckIcon;
    } else {
      return Tourplanning;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.orderDetail !== this.props.orderDetail) {
      this.setState({
        orderId: nextProps.orderDetail.order_id,
        show: false,
        showLastPAge: false,
      });
      this.getLoadingData(this.props.orderDetail.order_id);
      this.setState({ showTitle: true });
    }
  }

  UNSAFE_componentWillMount() {
    const responseSignature = userService.getSignature(
      this.props.users.user._id,
      this.props.users.accessToken,
    );
    responseSignature
      .then((res) => {
        const imageSrc = 'data:image/png;base64,' + res;
        this.setState({ imageSrc: imageSrc });
      })
      .catch((e) => {});
    this.getLoadingData(this.state.orderId);
    this.setState({ showTitle: true });
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      type: e.target.value,
    });
  };

  getLoadingData = async (orderId) => {
    const token = this.props.users.accessToken;
    const value = await OrderService.getLoadingData(token, orderId);
    if (value.status === 200) {
      const data = await extractData(value);
      if (typeof data.trucks[0] !== 'undefined') {
        const tags = data.trucks[0].ear_tags;
        data.trucks.length > 1
          ? this.setState({
              showTruckList: true,
            })
          : this.setState({
              truck: data.trucks[0],
              ear_tags: [...tags],
              data: data.trucks[0],
              showForm: true,
            });
        this.setState({
          trucks: data.trucks,
          data,
        });
      } else {
        this.props.handleClosePanel();
        this.props.error('Order not found');
        this.props.clear();
      }
    } else {
      this.props.handleClosePanel();
      this.props.error('Order not found');
      this.props.clear();
    }
  };

  handleSubmit = async (data) => {
    if (data) {
      await this.setState({
        vehicleNumber: data.vehicleNumber,
        driver: data.driverName,
        formData: data,
        show: true,
      });
    }
  };

  handleTobeLoadingService = async () => {
    const {
      orderId,
      vehicleNumber,
      driver,
      truck,
      ear_tags,
      ear_tags_splite,
    } = this.state;
    const token = this.props.users.accessToken;
    const CurrentTime = new Date().toLocaleTimeString('en-US', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric',
    });
    let eartags =
      this.state.truck.has_orders_split === 0
        ? ear_tags.map((t) => ({
            ear_tag: t.ear_tag,
            order_id: orderId,
          }))
        : ear_tags_splite.map((t) => ({
            ear_tag: t.ear_tag,
            order_id: orderId,
          }));
    if (eartags.length > 0) {
      let serviceData = {
        trucks: [
          {
            _id: truck._id,
            vehicle_number: vehicleNumber,
            driver: driver,
            loading_time: CurrentTime,
            ear_tags: eartags,
          },
        ],
        // include_signature: data.user_has_signature,
        // if no agrabiz user you need to add
        // signateur: btoa(this.state.imageSrc),
      };
      const valueL = await OrderService.editLoadingData(
        token,
        orderId,
        serviceData,
      );

      if (valueL.status === 200) {
        this.props.handleClosePanel();
        this.props.success(<FormattedMessage {...messages.LoadedOrder} />);
        this.props.clear();
        this.props.getOrders();
      } else {
        this.props.error(<FormattedMessage {...messages.NotLoadedOrder} />);
        this.props.clear();
      }
    }
  };

  showLastPage = () => {
    this.setState({ showLastPAge: true, showForm: false, show: false });
  };

  showPassportForTourInfo = (value) => {
    this.setState({ showPassportList: value });
  };

  showTitle = () => {
    this.setState({ showTitle: false });
  };

  setShowForm = async () => {
    this.setState({
      showEarTagItem: !this.state.showEarTagItem,
    });
  };

  setEartag = (earTagItem) => {
    this.setState({
      earTagItem,
      showEarTagItem: true,
    });
  };

  handlePopup = (item) => {
    this.setState({ checkRemove: !this.state.checkRemove });
    this.setState({ itemToRemove: item });
  };

  deleteEartag = () => {
    let { ear_tags, itemToRemove, ear_tags_splite } = this.state;
    if (this.state.truck.has_orders_split === 1) {
      const newEartagList = ear_tags_splite.filter(
        (eartag) => eartag.ear_tag !== itemToRemove.ear_tag,
      );
      this.setState({ checkRemove: !this.state.checkRemove });
      let listAddEartag = [];
      listAddEartag.push(itemToRemove);
      this.setState({
        ear_tags_splite: newEartagList,
        listAddEartag,
      });
    }
    if (this.state.truck.has_orders_split === 0) {
      const newEartagList = ear_tags.filter(
        (eartag) => eartag.ear_tag !== itemToRemove.ear_tag,
      );
      this.setState({ checkRemove: !this.state.checkRemove });
      let listAddEartag = [];
      listAddEartag.push(itemToRemove);
      this.setState({
        ear_tags: newEartagList,
        listAddEartag,
      });
    }
  };

  renderEartagForm = () => {
    const { earTagItem } = this.state;
    return (
      earTagItem && (
        <Col className="view-stock view-stock-to-be-loaded" md={6} sm={12}>
          {true && (
            <EartagForm
              showAlert={(msg) => this.props.error(msg)}
              setShowForm={this.setShowForm}
              data={earTagItem}
              passport={earTagItem.passport}
              ear_tag={earTagItem.ear_tag}
              ear_tag_output_format={earTagItem.ear_tag_output_format}
            />
          )}
        </Col>
      )
    );
  };

  handleCloseToBeload = () => {
    const { show, showLastPAge, showAddearTag } = this.state;
    this.setState({
      showTitle: true,
    });

    if (showAddearTag) {
      this.setState({
        showAddearTag: false,
      });
    } else if (show) {
      this.setState({
        show: false,
        showForm: true,
        showTitle: true,
      });
    } else if (showLastPAge) {
      this.setState({ showLastPAge: false, show: true, showForm: false });
    } else if (this.state.trucks.length > 1) {
      this.setState({ showTruckList: true });
    } else {
      this.props.handleClosePanel();
    }
  };

  showAddEartagPanel = () => {
    const { truck, ear_tags, ear_tags_splite } = this.state;
    if (this.state.truck.has_orders_split === 0) {
      if (truck.capacity > ear_tags.length) {
        this.setState({
          showAddearTag: true,
        });
      } else {
        this.setState({ checkCapacity: true });
      }
    }
    if (this.state.truck.has_orders_split === 1) {
      if (truck.capacity > ear_tags_splite.length) {
        this.setState({
          showAddearTag: true,
        });
      } else {
        this.setState({ checkCapacity: true });
      }
    }
  };

  handleSignaturePopup = () => {
    this.setState({ checkCapacity: !this.state.checkCapacity });
  };

  showSellForm = (earTagsList) => {
    earTagsList.length > 0 &&
      this.setState({
        ear_tags: [...this.state.ear_tags, ...earTagsList],
        ear_tags_splite: [...this.state.ear_tags_splite, ...earTagsList],
      });
    this.setState({ showAddearTag: false });
  };

  renderViewEartags() {
    return (
      <div>
        {this.state.truck.has_orders_split === 0 && (
          <Col
            className="to-be-loaded"
            style={{ height: '100%' }}
            md={6}
            sm={12}
          >
            <Card className="card-box to-be-loaded-panel">
              <ViewStock
                sellOffer={true}
                showSellForm={this.showSellForm}
                toBeLoadedEartags={this.state.ear_tags}
                capacity={
                  this.state.truck.capacity - this.state.ear_tags.length
                }
              />
              <div className="navigate-before">
                <div md={3}>
                  <MdNavigateBefore
                    className="before"
                    onClick={() => this.handleCloseToBeload()}
                  />
                </div>
              </div>
            </Card>
          </Col>
        )}
        {this.state.truck.has_orders_split === 1 && (
          <Col
            className="to-be-loaded"
            style={{ height: '100%' }}
            md={6}
            sm={12}
          >
            <Card className="card-box to-be-loaded-panel">
              <ViewStockLoaded
                sellOffer={true}
                showSellForm={this.showSellForm}
                toBeLoadedEartags={
                  this.state.ear_tags_splite.length > 0
                    ? pullAllBy(
                        this.state.ear_tags,
                        this.state.ear_tags_splite,
                        'ear_tag',
                      )
                    : this.state.ear_tags
                }
                capacity={this.state.truck.capacity}
              />
            </Card>
          </Col>
        )}
      </div>
    );
  }

  ColorIcon = (status) => {
    if (
      status === 'LOADED' ||
      status === 'TO_BE_LOADED' ||
      status === 'TOUR_ASSIGNED'
    )
      return 'tourInf-title-span-texttwo';
    if (status === 'ON_THE_WAY') return 'tourInf-title-span-textthree';
    else {
      return 'tourInf-title-span-text';
    }
  };

  getItemTime = (item) => {
    let time = '';
    this.props.toursDetails.forEach((tours) => {
      if (tours._id === item._id) {
        time = tours.delivery_until;
      }
    });
    return time;
  };

  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  getItemPartner = (item) => {
    let partner = '';
    this.props.toursDetails.forEach((tours) => {
      if (tours._id === item._id) {
        partner = tours.partner;
      }
    });
    return partner;
  };

  renderTruckList = () => {
    return (
      <Col className={'to-be-loaded'} style={{ height: '100%' }} md={12} sm={6}>
        <Card id="to-be-loaded-trucks" className="card-box to-be-loaded-panel">
          <div className="tobeloade-title-block">
            <h5 className="card-title first-block">
              <FormattedMessage {...messages.Tours} />
            </h5>
          </div>
          <div className="navigate-before">
            <div md={3}>
              <MdNavigateBefore
                className="before"
                onClick={() => this.props.handleClosePanel()}
              />
            </div>
          </div>
          <Col>
            <hr className="style-border" />
          </Col>
          {this.state.trucks.map((item, i) => (
            <ListGroupItem
              key={i}
              className="list-group truck-item to-be-loaded-tours"
            >
              <Row onClick={(evt) => this.showOrderDetail(item)}>
                <Col md={3} className="status">
                  <Image
                    className="status-image"
                    src={this.getIcon(item.status)}
                  />
                  <p className="status-name">
                    {
                      statusOrders.find(
                        (val) =>
                          item.status.split('_').join(' ') === val.statusOrder,
                      ).label
                    }
                  </p>
                </Col>

                <Col md={7}>
                  <div className={`truck-name ${this.ColorIcon(item.status)}`}>
                    {' '}
                    {this.getItemTime(item)} {this.getItemPartner(item)}
                  </div>
                  <div className="info-item">
                    {this.findAndReplace(item.name)} |{' '}
                    {item.vehicle_number || item.transport_company}{' '}
                  </div>
                </Col>
                <Col md={2} className="MdNavigateNext">
                  <p className="shared">
                    <MdNavigateNext />
                  </p>
                </Col>
              </Row>
              <hr className="style-border" />
            </ListGroupItem>
          ))}
        </Card>
      </Col>
    );
  };

  renderToBeLoaded = () => {
    const {
      ear_tags,
      show,
      driver,
      showAddearTag,
      showLastPAge,
      orderId,
      formData,
      truck,
      showForm,
      vehicleNumber,
      isToBeLoaded,
    } = this.state;
    let orderDetail = { order_id: orderId };
    return (
      <Col
        className={
          showAddearTag ? 'to-be-loaded to-be-loaded-none' : 'to-be-loaded'
        }
        style={{ height: '100%' }}
        md={6}
        sm={12}
      >
        <Card className="card-box to-be-loaded-panel">
          <div className="tobeloade-title-block">
            <h5 className="card-title first-block">
              {this.state.showTitle && (
                <FormattedMessage {...messages.LoadingProcess} />
              )}
            </h5>
          </div>
          <div className="navigate-before">
            <div md={3}>
              <MdNavigateBefore
                className="before"
                onClick={() => this.handleCloseToBeload()}
              />
            </div>
          </div>

          <TourInformation item={this.state.data} isToBeLoaded={isToBeLoaded} />
          {this.state.checkCapacity && (
            <MessagePopup
              show={this.state.checkCapacity}
              togglePopup={this.handleSignaturePopup}
              message={'capacity'}
              vehicleNumber={truck.vehicle_number}
            />
          )}
          {!show && showForm && truck.status === 'TO_BE_LOADED' && (
            <ToBeLoadedForm
              handleSubmit={this.handleSubmit}
              transportCompany={truck.transport_company}
              vehicleNumber={truck.vehicle_number}
              driverName={truck.driver}
            />
          )}
          {!show && showForm && truck.status !== 'TO_BE_LOADED' && (
            <TourInfoDirect
              orderDetail={orderDetail}
              truckDetails={truck}
              showPassportList={true}
              showPassportForTourInfo={this.showPassportForTourInfo}
              showTitle={this.showTitle}
            />
          )}
          {(show || showLastPAge) && (
            <ToBeLoadedNextStep
              truck={truck}
              vehicleNumber={vehicleNumber}
              driver={driver}
              setEartag={this.setEartag}
              ear_tags={ear_tags}
              showAddEartagPanel={this.showAddEartagPanel}
              deleteEartag={this.deleteEartag}
              handleTobeLoadingService={this.handleTobeLoadingService}
              showLastPAge={showLastPAge}
              handlePopup={this.handlePopup}
              checkRemove={this.state.checkRemove}
              orderId={orderId}
              showLastPage={this.showLastPage}
              ear_tags_splite={this.state.ear_tags_splite}
              formData={formData}
            />
          )}
        </Card>
      </Col>
    );
  };

  render() {
    const { showEarTagItem, showAddearTag, showTruckList } = this.state;
    return (
      <React.Fragment>
        <div className="to-be-loaded-container">
          {showTruckList && (
            <InfiniteScroll
              style={{ height: '100%' }}
              dataLength={this.state.trucks.length}
              hasMore={true}
              loader={''}
            >
              {this.renderTruckList()}
            </InfiniteScroll>
          )}
          {!showEarTagItem && !showTruckList && this.renderToBeLoaded()}
          {showEarTagItem && this.renderEartagForm()}
          {showAddearTag && this.renderViewEartags()}
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TobeLoaded);