import React from 'react';
import DatePicker from 'react-datepicker';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import '../../../../static/css/datepicker.css';
import { svgPaths } from '../../../../constants';
import { addLeadingZero } from '../../../../helpers/order';
import 'react-datepicker/dist/react-datepicker.css';
import './form.css';
import clsx from 'clsx';
import locale from '../../../../utils/DataPickerLang';

// const DateCompare = value => {
//   var d1 = new Date(value);
//   var d2 = new Date();
//   if (d1 < d2) return false;
//   else {
//     return true;
//   }
// };
const renderDate = (date) => {
  return `${addLeadingZero(date.getDate())}.${addLeadingZero(
    date.getMonth() + 1,
  )}.${date.getFullYear()}`;
};
const handleDateChangeRaw = (e) => {
  e.preventDefault();
};

const DatePickerField = ({
  name,
  value,
  label,
  onChange,
  dateFormat,
  // ref,
  onBlur,
}) => {
  const ref = React.createRef();
  return (
    <div className="generic-datepicker">
      <RegularInput
        picker
        label={label}
        type="text"
        value={(value && renderDate(new Date(value))) || renderDate(new Date())}
        name={name}
        onChange={() => {}}
        // onFocus={() => this.handleFocus('DatePicker')}
        // onBlur={() => this.handleBlur('DatePicker')}
        onClick={() => {
          ref.current.setOpen(true);
        }}
      />
      <div className="date-field picker">
        <SvgIcon
          color={clsx('#7bd811')}
          onClick={() => {
            ref.current.setOpen(true);
          }}
          size="30"
          viewBox="0 0 600 600"
        >
          {svgPaths['calendar']}
        </SvgIcon>

        <DatePicker
          selected={(value && new Date(value)) || new Date()}
          onChange={(val) => {
            onChange(name, val);
          }}
          dateFormat={dateFormat}
          ref={ref}
          onBlur={onBlur}
          onChangeRaw={handleDateChangeRaw}
          locale={locale}
        />
      </div>
    </div>
  );
};

export default DatePickerField;
