import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextInput } from 'react-bootstrap4-form-validation';
import messages from '../../messages';

const VeterinaryOfficeDetails = (props) => {
  const {
    company,
    //businessType,
    // selectedOptionTypeUser,
    // optionsTypesUse,
    // onChange,
  } = props;

  return (
    <div>
      <div className="new-cards-owner">
        <div className="new-cards-owner-inner">
          <div md={4} className=" new-cards-owner-inner-b1">
            <FormattedMessage {...messages.vetPractitioner} />
          </div>
          <div
            md={8}
            id="settings-inner-card"
            className="new-cards-owner-inner-b2"
          >
            <TextInput
              name="veterinary_practitioner"
              type="text"
              disabled={true}
              defaultValue={company.abbatoir_extra.veterinary_office}
            />
          </div>
        </div>

        {/* <div className="new-cards-owner-inner">
          <div className="new-cards-owner-inner-b2-btn">
            <Button
              type="submit"
              className="form-btn-valide add-s-bottom-part-cols"
            >
              <FormattedMessage {...messages.EditMessage} />
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

VeterinaryOfficeDetails.propTypes = {
  company: PropTypes.object,
};

export default VeterinaryOfficeDetails;
