import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import MultiSelect from '../../../Shared/components/MultiSelect';
import { races, breeds, qualities } from '../../../../constants';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import './cattle-group.scss';

const CattleGroupForm = ({
  addCattleGroup,
  updateCattleGroup,
  hideEditForm,
  oldCattle,
  ...props
}) => {
  const [number, setNumber] = useState(null);
  const [breed, setBreed] = useState('');
  const [race, setRace] = useState('');
  const [programQuality, setProgramQuality] = useState([]);
  const [selectIdentifiedBreed, setSelectIdentifiedBreed] = useState(false);
  const [selectIdentifiedRace, setSelectIdentifiedRace] = useState(false);
  const [selectIdentifiedPQ, setSelectIdentifiedPQ] = useState(false);

  useEffect(() => {
    if (Object.entries(oldCattle).length > 0) {
      setRace(oldCattle.cattle_group.race);
      setBreed(oldCattle.cattle_group.breed);
      setProgramQuality(oldCattle.cattle_group.program_quality);
      setNumber(oldCattle.cattle_group.number);
    }
  }, [oldCattle]);

  const resetCattle = () => {
    setRace('');
    setBreed('');
    setProgramQuality([]);
    setNumber(null);
  };
  const handleAddEditCattleGroup = evt => {
    const cattle = {
      race: race,
      breed: breed,
      number: parseInt(number),
      program_quality: programQuality,
    };
    if (Object.entries(oldCattle).length === 0) {
      addCattleGroup(cattle);
    } else {
      updateCattleGroup(oldCattle.index, cattle);
      resetCattle();
    }
    hideEditForm();
  };

  const handleChangeBreed = e => {
    const value = e.label;
    setBreed(value);
    toggleSelectIdentifierBreed();
  };
  const handleChangeRace = e => {
    const value = e.value;
    setRace(value);
    toggleSelectIdentifierRace();
  };
  const handleChangeNumber = e => {
    const { value } = e.target;
    setNumber(value);
  };

  const handleChangeProgrameQuality = programQuality => {
    if (programQuality) {
      let programQualityList = programQuality.map(pq => pq.value);
      if (programQualityList.length > 0) {
        setProgramQuality(programQualityList);
      }
    }
    toggleSelectIdentifierPQ(!!programQuality);
  };
  const toggleSelectIdentifierBreed = () => {
    setSelectIdentifiedBreed(true);
  };
  const toggleSelectIdentifierRace = () => {
    setSelectIdentifiedRace(true);
  };
  const toggleSelectIdentifierPQ = value => {
    setSelectIdentifiedPQ(value);
  };
  return (
    <div className="edit-cattle-container non-agrabiz-external-request">
      <div className="">
        <RegularInput
          label={<FormattedMessage {...messages.NewBreed} />}
          select
          className={clsx(
            (oldCattle.hasOwnProperty('cattle_group') ||
              selectIdentifiedBreed) &&
              'selected',
          )}
          defaultValue={
            oldCattle.hasOwnProperty('cattle_group') &&
            breeds.find(brd => brd.label === oldCattle.cattle_group.breed)
          }
          name="breed"
          options={breeds.sort((a, b) => a.value.localeCompare(b.value))}
          onChange={handleChangeBreed}
          required
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#F1FFDE',
              primary: '#7bcd10',
            },
          })}
        />
      </div>
      <div className="">
        <RegularInput
          label={<FormattedMessage {...messages.NewRace} />}
          select
          className={clsx(
            (oldCattle.hasOwnProperty('cattle_group') ||
              selectIdentifiedRace) &&
              'selected',
          )}
          name="race"
          options={races}
          onChange={handleChangeRace}
          defaultValue={
            oldCattle.hasOwnProperty('cattle_group') &&
            races.find(rc => rc.value === oldCattle.cattle_group.race)
          }
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#F1FFDE',
              primary: '#7bcd10',
            },
          })}
        />
      </div>

      <div className="">
        <MultiSelect
          filled
          label={<FormattedMessage {...messages.QualityProgram} />}
          isMulti={true}
          className={clsx(
            ((oldCattle.hasOwnProperty('cattle_group') &&
              oldCattle.cattle_group.program_quality.length > 0) ||
              selectIdentifiedPQ) &&
              'selected',
          )}
          closeMenuOnSelect={false}
          defaultValue={
            oldCattle.hasOwnProperty('cattle_group') &&
            oldCattle.cattle_group.program_quality.map(pq => ({
              value: pq,
              label: pq,
            }))
          }
          options={qualities}
          onChange={handleChangeProgrameQuality}
          isSearchable={false}
          isClearable={false}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#F1FFDE',
              primary: '#7bcd10',
            },
          })}
        />
      </div>
      <div className="">
        <RegularInput
          className="amount-input"
          label={<FormattedMessage {...messages.amount} />}
          defaultValue={
            oldCattle.hasOwnProperty('cattle_group') &&
            oldCattle.cattle_group.number
          }
          type="number"
          name="number"
          required
          onChange={e => handleChangeNumber(e)}
        />
      </div>
      {Object.entries(oldCattle).length > 0 ? (
        <div className="add-new-cattle">
          <div
            className="add-new-cattle-btn"
            onClick={e => handleAddEditCattleGroup(e)}
          >
            <FormattedMessage {...messages.UpdateCattles} />
          </div>
        </div>
      ) : (
        <div className="add-new-cattle">
          <div
            className="add-new-cattle-btn"
            onClick={e => handleAddEditCattleGroup(e)}
          >
            <FormattedMessage {...messages.AddMoreCattles} />
          </div>
        </div>
      )}
    </div>
  );
};

CattleGroupForm.propTypes = {
  hideEditForm: PropTypes.func,
};

export default CattleGroupForm;
