import React from 'react';
import { qualitie } from '../../../../constants';
import QualityItem from './QualityItem';

class ListQuality extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  findIndexQuality(val) {
    let find = false;
    let index = 0;

    this.props.qualities.forEach(async (element, i) => {
      if (element.name === val) {
        find = true;
        index = i;
      }
    });
    return { find: find, index: index };
  }

  renderQuality = () =>
    qualitie.map((val, i) => {
      return (
        <QualityItem
          key={i}
          uploadQualitiesList={this.props.uploadQualities}
          delete={this.props.deleteFile}
          find={this.findIndexQuality(val).find}
          quality={val}
          photo={this.props.photos[this.findIndexQuality(val).index]}
          index={this.findIndexQuality(val).index}
        />
      );
    });

  render() {
    return <div className="row text-center">{this.renderQuality()}</div>;
  }
}

export default ListQuality;
