import { stockConstants } from '../constants';
const initialState = {
  allstock: [],
  dateUpdate: new Date(),
};

export function stock(state = initialState, action) {
  switch (action.type) {
    case stockConstants.ALL_STOCK:
      return {
        ...state,
        allstock: action.allStocks,
      };
    case stockConstants.RESET:
      return {
        ...state,
        allstock: action.resetStocks,
      };
    default:
      return state;
  }
}
