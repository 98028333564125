/* eslint-disable */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import FormCompany from './FormCompany';
import messages from '../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
class StepThree extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      number: '',
      pin: '',
      visiblePassword: false,
      submitted: false,
      visible: false,
    };
  }
  showRole = () => {
    switch (this.props.company.business_type) {
      case 1:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <p className="titleStep3 buisness-text">
              <FormattedMessage {...messages.RegisterStepThreeBuisnessType} />
            </p>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleAgriculture} />
            </h2>
          </Col>
        );
        break;
      case 2:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <p className="titleStep3 buisness-text">
              <FormattedMessage {...messages.RegisterStepThreeBuisnessType} />
            </p>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleTrader} />
            </h2>
          </Col>
        );
        break;

      case 3:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <p className="titleStep3 buisness-text">
              <FormattedMessage {...messages.RegisterStepThreeBuisnessType} />
            </p>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleAbatoire} />
            </h2>
          </Col>
        );
        break;
      case 4:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <p className="titleStep3 buisness-text">
              <FormattedMessage {...messages.RegisterStepThreeBuisnessType} />
            </p>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleTransporter} />
            </h2>
          </Col>
        );
        break;
      case 5:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <p className="titleStep3 buisness-text">
              <FormattedMessage {...messages.RegisterStepThreeBuisnessType} />
            </p>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitleVet} />
            </h2>
          </Col>
        );
        break;
      case 6:
        return (
          <Col md={{ span: 3, offset: 1 }}>
            <p className="titleStep3 buisness-text">
              <FormattedMessage {...messages.RegisterStepThreeBuisnessType} />
            </p>
            <h2 className="heading">
              <FormattedMessage {...messages.RegisterStepTwoTitlePriVet} />
            </h2>
          </Col>
        );
        break;
    }
  };
  render() {
    return (
      <Row>
        <ToastContainer />
        {this.showRole()}
        <Col className="titleStep3Form" md={6}>
          <FormCompany role={this.state.role} />
        </Col>
      </Row>
    );
  }
}
StepThree.propTypes = {
  company: PropTypes.object,
  nextStepRegister: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {};
}
const mapStateToProps = (state) => {
  const { company } = state.toJS();
  return { company };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
