import { buyingsStockConstants } from '../constants';

const initialState = {
  buyingsStock: null,
  buyingsDetails: null,
  dateDetails: null,
};

export function buyingsStock(state = initialState, action) {
  switch (action.type) {
    case buyingsStockConstants.GET_BUYINGS:
      return {
        ...state,
        buyingsStock: action.buyingsStock,
      };

    case buyingsStockConstants.GET_BUYINGS_DETAILS:
      return {
        ...state,
        buyingsDetails: action.data.buyingsDetails,
        dateDetails: action.data.date,
      };
    case buyingsStockConstants.CLEAR_BUYINGS_DETAILS:
      return {
        ...state,
        buyingsDetails: null,
        dateDetails: null,
      };
    default:
      return state;
  }
}
