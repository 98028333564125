import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, injectIntl } from 'react-intl';
import { renameBreed } from '../utils';
import messages from '../../messages';
import './details.css';

const Details = ({ buyingsStock, intl }) => {
  const details = buyingsStock.buyingsDetails
    .map(e => ({
      ...e,
      breed: renameBreed(e.breed),
    }))
    .sort((a, b) => (a.breed > b.breed ? 1 : -1));
  const totalPieces = buyingsStock.buyingsDetails.reduce(
    (previousTotal, currentTotal) => previousTotal + currentTotal.total,
    0,
  );
  const dayArray = buyingsStock.dateDetails.split(',');
  const translatedDate =
    intl.formatMessage({
      id: `agrabiz.components.order.${dayArray[0].toLowerCase()}`,
    }) +
    ',' +
    dayArray[1];
  return (
    <Row className="details-row">
      <Col className="details">
        <div className="right-section-header">
          <div className="today">{translatedDate}</div>
          <div className="day-total">
            <FormattedMessage {...messages.SlaughterText} />
            <span className="total-span">
              {totalPieces + ' '}
              <FormattedMessage {...messages.Pieces} />
            </span>
          </div>
        </div>

        <Row className="panel-body-options bottom-part">
          <Col xs={12} md={12}>
            <div className="details-table">
              {/* <Table width="100%" responsive> */}
              {details.map(({ breed, total, races }, id) => (
                <div key={breed + id} className="details-container">
                  <div className="details-line">
                    <div className="details-item-breed">
                      <span className="breed">{breed}</span>
                      <span className="total-breed">{total}</span>
                    </div>
                    {races.map((data, j) => (
                      <div key={data.race + j} className="details-item-race">
                        {data.race} {data.number}
                      </div>
                    ))}
                  </div>
                  <hr className="style-border" />
                </div>
              ))}
              {/* </Table> */}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default injectIntl(Details);
