import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

export default () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <div>
      <a
        className="title"
        href="https://analytics.google.com/analytics/web/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to go to Google Analytics
      </a>
    </div>
  ); 
};
