const monthsDe = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];
const daysDe = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

const monthsEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const daysEN = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const locale = {
  localize: {
    month: (n) =>
      typeof localStorage.getItem('language') !== 'undefined' &&
      localStorage.getItem('language') === 'DE'
        ? monthsDe[n]
        : monthsEN[n],
    day: (n) =>
      typeof localStorage.getItem('language') !== 'undefined' &&
      localStorage.getItem('language') === 'DE'
        ? daysDe[n]
        : daysEN[n],
  },
  formatLong: {},
};

export default locale;
