import { adminConstants } from '../constants';

const initialState = {
  panelName: 'Home',
};

export function admin(state = initialState, action) {
  switch (action.type) {
    case adminConstants.HANDLE_TITLE_CHANGE:
      return {
        ...state,
        panelName: action.panelName,
      };
    default:
      return state;
  }
}
