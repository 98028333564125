import React from 'react';
import reload from '../../../../static/images/membership/reload.png';
import euro from '../../../../static/images/membership/euro.png';
import house from '../../../../static/images/membership/house.png';
import cowEat from '../../../../static/images/membership/cow-eat.png';
import cows from '../../../../static/images/membership/cows.png';
import checkedLighten from '../../../../static/images/membership/checked-lighten.png';
import checkedDarken from '../../../../static/images/membership/checked-darken.png';
import clockLighten from '../../../../static/images/membership/clock-lighten.png';
import clockDarken from '../../../../static/images/membership/clock-darken.png';
import close from '../../../../static/images/membership/close.png';

const membershipPlans = {
  BASIC: 'basic',
  PREMIUM: 'premium',
  BUSINESS: 'business',
};

const membershipInformations = [
  {
    id: 1,
    image: reload,
    sectionOne: (
      <div className="second-section">
        Agrabiz 'Pro' <span className="highlight"> 30 Tage</span> kostenlos und
        unverbindlich testen
      </div>
    ),

    sectionTwo:
      'Nutzen Sie automatisch unseren gesamten Funktionsumfang für 30 Tage kostenlos und unverbindlich. Nach Ablauf des Testphase können Sie Agrabiz weiter kostenfrei mit eingeschränkter ‘Free’ Mitgliedschaft nutzen oder zu unseren attraktiven, funktionsreicheren Abonnements wechseln.',
  },
  {
    id: 2,
    image: euro,
    sectionOne: (
      <div className="second-section">
        Agrabiz 'Free' kostenlos ohne Zahlungsverpflichtung. Zahlungspflichtige
        Abonnements <span className="highlight"> monatlich</span> kündbar.
      </div>
    ),
    sectionTwo:
      "Unsere 'Free' Mitgliedschaft ist zu 100% kostenlos und verpflichtet Sie zu keinem zahlungspflichtigen Abonnement. Wenn Sie von all unseren Vorteilen und Funktionen profitieren wollen und sich für ein funktionsreicheres Abonnement entscheiden, können Sie bequem zwischen Lastschrift oder Kreditkarte wählen. Sie können unsere Abonnements jederzeit zum Ende des laufenden Monats kündigen.",
  },
];

const membershipArgTypes = [
  {
    id: 1,
    image: cowEat,
    displayType: 'FREE',
    type: 'free',
    plan: membershipPlans.BASIC,
    description:
      'Die kostenlose Lösung für einfache digitale Kontaktverwaltung und eingeschränkte Viehkaufangebote.',
    price: {
      status: 'Kostenlos',
    },
  },
  // {
  //   id: 2,
  //   image: cows,
  //   displayType: 'BASIC',
  //   type: 'basic',
  //   plan: membershipPlans.BUSINESS,
  //   description:
  //     'Die Lösung mit allen wesentlichen Funktionen für kleinere Betriebe mit geringeren Dokumentationsanforderungen.',
  //   price: {
  //     priceNumber: '9,99',
  //   },
  // },
  {
    id: 3,
    image: house,
    displayType: 'PRO',
    type: 'pro',
    plan: membershipPlans.PREMIUM,
    description:
      'Die professionelle Lösung für Ihren Betrieb mit unbegrenztem Zugang zu allen App- und Webfunktionen.',
    price: {
      priceNumber: '9,99',
    },
  },
];

const membershipTrdTypes = [
  {
    id: 1,
    image: cowEat,
    displayType: 'FREE',
    type: 'free',
    plan: membershipPlans.BASIC,
    withTag: false,
    withNotice: false,
    description:
      'Die kostenlose Lösung für einfache digitale Kontaktverwaltung und eingeschränkte Viehkaufangebote.',
    price: {
      status: 'Kostenlos',
    },
  },
  {
    id: 2,
    image: house,
    displayType: 'PRO',
    type: 'pro',
    plan: membershipPlans.PREMIUM,
    withTag: true,
    withNotice: true,
    description:
      'Die professionelle Lösung für Ihren Betrieb mit unbegrenztem Zugang zu allen App- und Webfunktionen.',
    price: {
      priceNumber: '49,99',
    },
  },
];

const membershipAgrSpecifications = [
  {
    id: 1,
    content: [
      {
        image: null,
        description: 'Mobile App (iOS & Android)',
      },
      {
        image: checkedLighten,
        description: 'Eingeschränkte Funktionen',
        margin:'true',
      },
      // {
      //   image: checkedLighten,
      //   description: 'Erweiterte Funktionen',
      // },
      {
        image: checkedDarken,
        description: 'Alle Funktionen',
        marginT:'true',
      },
    ],
  },
  {
    id: 2,
    content: [
      {
        image: null,
        description: 'Bestandseinsicht & Kontakte',
      },
      {
        image: checkedDarken,
        description: '',
        margin:'true',
      },
      // {
      //   image: checkedDarken,
      //   description: '',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
  {
    id: 3,
    content: [
      {
        image: null,
        description: 'Erweiterte Bestandsverwaltung',
      },
      {
        image: close,
        description: '',
        margin:'true',
      },
      // {
      //   image: checkedLighten,
      //   description: 'Abgänge und Verendungen',
      // },
      {
        image: checkedDarken,
        description: 'inkl. Bestandsregister',
        marginT:'true',
      },
    ],
  },
  {
    id: 4,
    content: [
      {
        image: null,
        description: 'Neuzugänge & Geburtenmeldung',
      },
      {
        image: close,
        description: '',
        margin:'true',
      },
      // {
      //   image: checkedDarken,
      //   description: '',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
  {
    id: 5,
    content: [
      {
        image: null,
        description: 'Verkäufe des eigenen Bestands',
      },
      {
        image: clockLighten,
        description: 'Nur heute und Vortag',
        margin:'true',
      },
      // {
      //   image: clockDarken,
      //   description: 'Nur letzte 30 Tage',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
  {
    id: 6,
    content: [
      {
        image: null,
        description: 'Lieferdokumente & Schlachtdaten',
      },
      {
        image: clockLighten,
        description: 'Nur heute und Vortag',
        margin:'true',
      },
      // {
      //   image: clockDarken,
      //   description: 'Nur letzte 30 Tage',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
  {
    id: 7,
    content: [
      {
        image: null,
        description: 'Automatische HI-Tier Meldungen',
      },
      {
        image: checkedLighten,
        description: 'Nur Abgänge aus Verkauf',
        margin:'true',
      },
      // {
      //   image: checkedDarken,
      //   description: '',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
  {
    id: 8,
    content: [
      {
        image: null,
        description: 'Ankauf von Nutztieren',
      },
      {
        image: close,
        description: '',
        margin:'true',
      },
      // {
      //   image: clockDarken,
      //   description: 'Nur letzte 30 Tage',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
  {
    id: 9,
    content: [
      {
        image: null,
        description: 'Web-Version über Browser',
      },
      {
        image: close,
        description: 'Nur Einstellungen & Kontakte',
        margin:'true',
      },
      // {
      //   image: close,
      //   description: 'Nur Einstellungen & Kontakte  ',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
  {
    id: 10,
    content: [
      {
        image: null,
        description: 'Auswertungen & Ohrmarkensuche',
      },
      {
        image: close,
        description: '',
        margin:'true',
      },
      // {
      //   image: close,
      //   description: '',
      // },
      {
        image: checkedDarken,
        description: '',
        marginT:'true',
      },
    ],
  },
];

const membershipTrdSpecifications = [
  {
    id: 1,
    content: [
      {
        image: null,
        description: 'Mobile App (iOS & Android)',
      },
      {
        image: checkedLighten,
        description: 'Eingeschränkte Funktionen',
      },
      {
        image: checkedDarken,
        description: 'Alle Funktionen',
      },
    ],
  },
  {
    id: 2,
    content: [
      {
        image: null,
        description: 'Bestandseinsicht & Kontakte',
      },
      {
        image: checkedDarken,
        description: '',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
  {
    id: 3,
    content: [
      {
        image: null,
        description: 'Erweiterte Bestandsverwaltung',
      },
      {
        image: clockLighten,
        description: 'Nur heute und Vortag',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
  {
    id: 4,
    content: [
      {
        image: null,
        description: 'Neuzugänge & Geburtenmeldung',
      },
      {
        image: close,
        description: '',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
  {
    id: 5,
    content: [
      {
        image: null,
        description: 'Verkäufe des eigenen Bestands',
      },
      {
        image: close,
        description: '',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
  {
    id: 6,
    content: [
      {
        image: null,
        description: 'Lieferdokumente & Schlachtdaten',
      },
      {
        image: close,
        description: '',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
  {
    id: 7,
    content: [
      {
        image: null,
        description: 'Automatische HI-Tier Meldungen',
      },
      {
        image: close,
        description: '',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
  {
    id: 8,
    content: [
      {
        image: null,
        description: 'Ankauf von Nutztieren',
      },
      {
        image: close,
        description: 'Nur Einstellungen & kontakte',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
  {
    id: 9,
    content: [
      {
        image: null,
        description: 'Web-Version über Browser',
      },
      {
        image: close,
        description: '',
      },
      {
        image: checkedDarken,
        description: '  ',
      },
    ],
  },
  {
    id: 10,
    content: [
      {
        image: null,
        description: 'Auswertungen & Ohrmarkensuche',
      },
      {
        image: close,
        description: '',
      },
      {
        image: checkedDarken,
        description: '',
      },
    ],
  },
];

export {
  membershipPlans,
  membershipInformations,
  membershipArgTypes,
  membershipTrdTypes,
  membershipAgrSpecifications,
  membershipTrdSpecifications,
};
