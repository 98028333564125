export const orderConstants = {
  BUYINGS_GET_ALL: 'BUYINGS_GET_ALL',
  SELLINGS_GET_ALL: 'SELLINGS_GET_ALL',
  BUY_CONFIRM: 'BUY_CONFIRM',
  SELL_CONFIRM: 'SELL_CONFIRM',
  BUY_ACCEPTED: 'BUY_ACCEPTED',
  SELL_ACCEPTED: 'SELL_ACCEPTED',
  ORDERS_FAILURE: 'ORDERS_GET_FAILURE',
  RESALE_OVERVIEW: 'RESALE_OVERVIEW',
  ERROR: 'ERROR',
  BUY_DELETE: 'BUY_DELETE',
  RESET_BUY: 'RESET_BUY',
  RESET_SELL: 'RESET_SELL',
};
