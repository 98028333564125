import React from 'react';
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { qualities } from '../../../constants';

const Filters = ({ filters, setFilters }) => {
  return (
    <ButtonToolbar>
      <ToggleButtonGroup
        type="checkbox"
        value={filters}
        onChange={setFilters}
        className="btn-flex-center"
      >
        {qualities.map(e => (
          <ToggleButton
            key={`buyings-overview-pq-${e.value}`}
            className="group-btn-filter"
            value={e.value}
          >
            {e.label}
            <span className="checkmark"></span>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

export default Filters;
