import { configConstants } from '../constants';
import { put, get } from './request';

export async function getArchives(accessToken, lg, page) {
  try {
    const contentType = 'application/json';
    return await get(
      `${configConstants.Notification}/archive?filter=all&page=${page}&language=${lg}`,
      accessToken,
      contentType,
    );
  } catch (error) {}
}

export async function asRead(accessToken, tab) {
  try {
    const contentType = 'application/x-www-form-urlencoded';
    const data = { messages: JSON.stringify(tab) };
    return put(
      `${configConstants.Notification}/archive/is_read`,
      contentType,
      accessToken,
      data,
    );
  } catch (error) {}
}
