import React from 'react';

import PropTypes from 'prop-types';
import { userActions, alertActions } from '../../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  Button,
  InputGroup,
} from 'react-bootstrap';
import stepOne from '../../static/images/stepOne.png';
import messages from '../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import { MdPersonOutline, MdLockOutline } from 'react-icons/md';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css';
class StepOne extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      number: '',
      pin: '',
      visiblePassword: false,
      submitted: false,
      visible: false,
    };

    // reset login status
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.alert !== this.props.alert) {
      //Perform some operation here
      if (this.props.alert.message) {
        if (
          this.props.alert.message.includes('COMPANY_HIT_CHECK_PARAMS_INVALID')
        ) {
          this.notify(<FormattedMessage {...messages.RegisterError} />);
        }
        if (
          this.props.alert.message.includes('COMPANY_NUMBER_ALREADY_REGISTERED')
        ) {
          this.notify('COMPANY ALREADY REGISTERED');
        }
        if (this.props.alert.message.includes('HIT_BAD_CREDENTIALS')) {
          this.notify('HIT BAD CREDENTIALS');
        }
      }
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { number, pin } = this.state;
    if (number && pin) {
      this.props.verification(number, pin);
    }
  }

  notify = message =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  showPassword = () => {
    this.setState(prevState => ({
      visiblePassword: !prevState.visiblePassword,
    }));
  };

  render() {
    // const { number, submitted } = this.state;
    return (
      <Row>
        <ToastContainer />

        <Col md={{ span: 3 }} className="register-left-side col-sm-offset-1">
          <img src={stepOne} alt="register step 1" />
        </Col>
        <Col md={8} className="registration">
          <Row>
            <div className="registration-top-part">
              <div className="registration-top-container">
                <h4 className="registration-top-title">
                  <FormattedMessage {...messages.RegisterStepOneTitle1} />
                </h4>
                <div className="registration-top-sub-parag">
                  <FormattedMessage {...messages.RegisterStepOneTitle2} />
                </div>
                <div className="registration-top-sub-parag">
                  <FormattedMessage {...messages.RegisterStepOneTitle3} />
                </div>
              </div>
            </div>
            <Form
              onSubmit={this.handleSubmit}
              className="registration-step-one-form"
            >
              <FormGroup>
                <Col
                  sm={4}
                  className="col-sm-offset-2 input-margin register-number"
                >
                  <div className="registration-flex-inner-box">
                    <InputGroup.Prepend>
                      <MdPersonOutline />
                    </InputGroup.Prepend>
                    <FormattedMessage {...messages.companyNumber}>
                      {msg => (
                        <FormControl
                          type=" text"
                          onChange={this.handleChange}
                          placeholder={msg}
                          name="number"
                        />
                      )}
                    </FormattedMessage>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup className="registration-flex-inner-box">
                <Col sm={8} className="register-pin">
                  <div className="registration-flex-inner-box">
                    <InputGroup.Prepend>
                      <MdLockOutline />
                    </InputGroup.Prepend>
                    <FormControl
                      type={this.state.visiblePassword ? 'text' : 'password'}
                      name="pin"
                      placeholder="Hi-Tier PIN"
                      onChange={this.handleChange}
                    />
                    <InputGroup.Prepend onClick={() => this.showPassword()}>
                      {this.state.visiblePassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </InputGroup.Prepend>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup controlId="formInlineEmail">
                <Col sm={4} className="col-sm-offset-4 register-submit">
                  <Button
                    type="submit"
                    className="register-form-btn margin-btn-top"
                  >
                    <FormattedMessage {...messages.RegisterStepOneButtton} />
                  </Button>
                </Col>
              </FormGroup>
              <Col sm={6} className="col-sm-offset-3 register-bottom-section">
                <div className="register-button-sub-text">
                  <div>
                    <FormattedMessage
                      {...messages.RegisterStepOneTextSignIn1}
                    />
                  </div>
                  <Link to="/login" className="sign-in-link">
                    <FormattedMessage
                      {...messages.RegisterStepOneTextSignIn2}
                    />
                  </Link>
                </div>
              </Col>
              <Col sm={6} className="col-sm-offset-3 register-bottom-section">
                <div className="register-bottom-text">
                  <FormattedMessage
                    {...messages.RegisterStepOneTransporterTextOne}
                  />
                  <div className="register-bottom-second-text">
                    <FormattedMessage
                      {...messages.RegisterStepOneTransporterTextTwo}
                    />
                    <span>
                      <a
                        style={{ color: '#7ad819', paddingLeft: '8px' }}
                        href="mailto:service@agrabiz.de"
                      >
                        service@agrabiz.de
                      </a>
                    </span>
                  </div>
                </div>
              </Col>
            </Form>
          </Row>
        </Col>
      </Row>
    );
  }
}

StepOne.propTypes = {
  alert: PropTypes.object,
  verification: PropTypes.func,
};
function mapDispatchToProps(dispatch) {
  return {
    verification: (number, pin) =>
      dispatch(userActions.verification(number, pin)),
    error: error => dispatch(alertActions.error(error)),
  };
}

const mapStateToProps = state => {
  const { alert } = state.toJS();
  return { alert };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepOne);
