import React from 'react';
import { Modal } from 'react-bootstrap';

export default class Popup extends React.Component {
  render() {
    let $imagePreview = null;
    $imagePreview = 
    typeof this.props.src !== 'undefined' &&
    this.props.src !== '' &&
    this.props.src !== null &&
    this.props.src.includes('application/pdf') ? (
      <div style={{ height: '660px' }}>
        <embed
          src={this.props.src}
          frameBorder="0"
          width="100%"
          height="100%"
          type="application/pdf"
        />
      </div>
    ) : (
      <img src={this.props.src} />
    );

    return (
      <Modal
        show={this.props.togglePopup}
        onHide={this.props.closePopup}
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{$imagePreview}</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
