var tableData = {
  columns: [
    'Number',
    'Title',
    'Year',
    'Month',
    'Amount net',
    'Amount gross',
    'Payed at',
    'Download',
  ],
  columnsj: [
    'Rechnungsnr',
    'Rechnungstitel',
    'Jahr',
    'Monat',
    'Rechnungsbetrag netto',
    'Rechnungsbetrag brutto',
    'Buchungsdatum',
    'Download',
  ],
  rows: [],
};
export default tableData;
