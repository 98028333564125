import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

export const statusOrders = [
  {
    statusOrder: 'REQUEST',
    label: <FormattedMessage {...messages.RequestOrder} />,
  },
  {
    statusOrder: 'REQUESTED',
    label: <FormattedMessage {...messages.RequestedOrder} />,
  },
  {
    statusOrder: 'TO BE RECONFIRMED',
    label: <FormattedMessage {...messages.ToBeReConfirmedOrder} />,
  },
  {
    statusOrder: 'TOUR PLANNING',
    label: <FormattedMessage {...messages.TourPlanningOrder} />,
  },
  {
    statusOrder: 'PLANNED',
    label: <FormattedMessage {...messages.Planned} />,
  },
  {
    statusOrder: 'OFFER',
    label: <FormattedMessage {...messages.OfferOrder} />,
  },
  {
    statusOrder: 'OFFERED',
    label: <FormattedMessage {...messages.OfferedOrder} />,
  },
  {
    statusOrder: 'TO BE ACCEPTED',
    label: <FormattedMessage {...messages.ToBeAcceptedOrder} />,
  },
  {
    statusOrder: 'TO BE LOADED',
    label: <FormattedMessage {...messages.ToBeLoadedOrder} />,
  },
  {
    statusOrder: 'LOADED',
    label: <FormattedMessage {...messages.LoadedOrder} />,
  },
  { statusOrder: 'SOLD', label: <FormattedMessage {...messages.SoldOrder} /> },
  {
    statusOrder: 'REJECTED',
    label: <FormattedMessage {...messages.RejectedOrder} />,
  },
  {
    statusOrder: 'DELIVERED',
    label: <FormattedMessage {...messages.DeliveredOrder} />,
  },
  {
    statusOrder: 'ON THE WAY',
    label: <FormattedMessage {...messages.OnthewayOrder} />,
  },
  {
    statusOrder: 'NEGOTIATION',
    label: <FormattedMessage {...messages.NegotiationOrder} />,
  },
  {
    statusOrder: 'CANCELED',
    label: <FormattedMessage {...messages.CanceledOrder} />,
  },
  {
    statusOrder: 'BOUGHT',
    label: <FormattedMessage {...messages.BoughtOrder} />,
  },
  {
    statusOrder: 'UNLOADED',
    label: <FormattedMessage {...messages.UnloadedOrder} />,
  },
  {
    statusOrder: 'SLAUGHTERED',
    label: <FormattedMessage {...messages.Slaughtered} />,
  },
  {
    statusOrder: 'TOUR ASSIGNED',
    label: <FormattedMessage {...messages.TourAssigned} />,
  },
];
