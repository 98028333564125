import React, { Component } from 'react';
import messages from '../../../../components/Admin/messages';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import truckIcon from '../../../../static/images/truck.svg';
export default class TruckItem extends Component {
  render() {
    const { allTrucks, edit, del } = this.props;
    const getwork = (label) => {
      if (label === 'A') return <FormattedMessage {...messages.Articulated} />;
      if (label === 'B') return <FormattedMessage {...messages.Lorry} />;
      if (label === 'C') return <FormattedMessage {...messages.Trailer} />;
      else return '';
    };

    return (
      <div className="col-md-2 truck-item">
        <div className="box-action">
          <div className="row">
            <div className="col-sm-6 cursor-pointer">
              <div className="box-icon1">
                <div className=" update-icon padding-icon" onClick={edit}></div>
              </div>
            </div>
            <div className="col-sm-6 cursor-pointer">
              <div className="box-icon2">
                <div className=" delete-icon" onClick={del}></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="box wow fadeInUp active"
          data-wow-duration=".5s"
          data-wow-delay=".3s"
        >
          <div className="contact-info">
            <div className="row person-title-container">
              <div className="col-sm-4 ">
                <img
                  style={{ width: '40px', height: '40px', marginLeft: '10px' }}
                  src={truckIcon}
                  alt=""
                />
              </div>
              <div className="col-sm-8 person-role-txt">
                <h4>{allTrucks && allTrucks.licence_number}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <p className="person-txt">
                  {allTrucks && getwork(allTrucks.type)} |{' '}
                  <FormattedMessage {...messages.TLevels} />:{' '}
                  {allTrucks && allTrucks.level_number}
                </p>
                <p className="person-txt">
                  <FormattedMessage {...messages.Capacity} />:{' '}
                  {allTrucks && allTrucks.capacity} m<sup>2</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TruckItem.propTypes = {
  edit: PropTypes.func,
  del: PropTypes.func,
};
