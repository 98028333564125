import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Image, FormGroup, Card, Modal, Button } from 'react-bootstrap';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';
import extractData from '../../../services/data.processing';
import img from '../../../static/images/ad-user-add-new-plus-icon-512.png';
import { alertActions } from '../../../actions';
import { inviteContact } from '../../../services/company.service';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import RegularInput from '../../Shared/components/inputs/RegularInput';

class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      email: '',
      showExist: false,
      data: [],
    };
  }

  handleSubmit = async () => {
    const { accessToken } = this.props.users;
    const { email, message } = this.state;
    const data = { message: message };
    const res = await inviteContact(email, accessToken, data);
    if (
      res !== 'null' &&
      typeof res !== 'undefined' &&
      typeof res.status !== 'undefined' &&
      res.status === 200
    ) {
      if (res.name === '') {
        this.props.success(<FormattedMessage {...messages.ContactInvalid} />);
        this.props.clear();
        this.props.handleModal();
      } else {
        await this.handlePopUpExist();
        const data = await extractData(res);
        await this.setState({ data });
      }
    } else {
      this.props.handleModal();
      this.props.error(<FormattedMessage {...messages.ContactEmailError} />);
      this.props.clear();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'transportCompany':
        this.setState({ email: value });
        break;
      case 'message':
        this.setState({ message: value });
        break;
      default:
        break;
    }
  };

  handlePopUpExist = () => {
    this.setState({ showExist: true });
  };

  togglePopupExist = () => {
    this.setState({ showExist: false });
  };

  togglePopupExistPopuo = (data) => {
    this.props.getContactById(data._id);
  };

  renderExistCompany = () => (
    <Modal
      show={this.handlePopUpExist}
      onHide={this.togglePopupExist}
      style={{ opacity: 1 }}
    >
      <div>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <FormattedMessage {...messages.invite_confirmation_message} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.togglePopupExist}>
            <FormattedMessage {...messages.cancelbtn} />
          </Button>
          <Button
            variant="primary"
            onClick={(e) => this.togglePopupExistPopuo(this.state.data)}
          >
            <FormattedMessage {...messages.Confirm} />
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
  render() {
    return (
      <Card.Body className="contact-panel-body">
        <div className="send-invitation-image">
          <Image
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            width="50%"
            height="50%"
            src={img}
          />
        </div>
        <div className="container-size">
          <div className="send-invitation-description">
            <FormattedMessage {...messages.invitation_description_trader} />
          </div>
          <div className="send-invitation-input">
            <FormGroup className="user-input-wrp">
              <RegularInput
                id="transportCompany"
                name="transportCompany"
                value={this.state.email}
                onChange={this.handleChange}
                required
                label="Email address"
                type="text"
              />

              <span className="focus-border"></span>
            </FormGroup>
          </div>
          <div className="send-invitation-textarea">
            <textarea
              name="message"
              className="form-input"
              placeholder="Additional notes..."
              style={{ border: '1px solid #bbbbbb' }}
              cols={18}
              rows={4}
              value={this.state.notes}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="send-invitation-button">
            <FormGroup>
              <NormalButton
                type="submit"
                disabled={this.state.email === '' || this.state.message === ''}
                onClick={this.handleSubmit}
              >
                <FormattedMessage {...messages.Add_btn} />
              </NormalButton>
            </FormGroup>
          </div>
        </div>

        <div>{this.state.showExist && this.renderExistCompany()}</div>
      </Card.Body>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invitation);
