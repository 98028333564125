import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Button,
  FormControl,
  FormGroup,
  InputGroup,
  ButtonGroup,
} from 'react-bootstrap';
import { MdStarBorder, MdStar } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import Switch from 'react-switch';
import { filter, isEqual, uniqBy, difference } from 'lodash';
import messages from '../../messages';
import { getContacts } from '../../../../services/company.service';
import { OrderService } from '../../../../services/order.service';
import {
  getCompany,
  getContactPreferences,
} from '../../../../services/company.service';
import extractData from '../../../../services/data.processing';
import { addLeadingZero } from '../../../../helpers/order';
import { races, breeds, qualities } from '../../../../constants';
import ViewStock from '../../ViewStock';
import { alertActions, producerActions } from '../../../../actions';
import { removeDuplicates } from '../../../../helpers/order';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import MultiSelect from '../../../Shared/components/MultiSelect';
import { svgPaths } from '../../../../constants';
import '../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import './buySell.css';
import locale from '../../../../utils/DataPickerLang';

class BuySellRequest extends Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.calendarRefUntil = React.createRef();
    this.breedSelectRef = React.createRef();

    this.state = {
      deliveryUntil: '',
      deliveryDate: this.props.orderDetail.delivery_date,
      piece: '',
      amount: this.AddZero(this.props.orderDetail.amount) || 0,
      breed: [],
      race: [],
      programQuality: [],
      orderDetail: this.props.orderDetail,
      companyId: this.props.companyId,
      contacts: [],
      agriculturists: [],
      traders: [],
      abattoirs: [],
      showTrd: false,
      showAgr: false,
      showAbt: false,
      receivers: [],
      showStock: true,
      earTags: [],
      src: {},
      startDate: new Date(),
      endDate: new Date(),
      freight_included: 0,
      businessType: null,
      isChecked: false,
      search_favorit: false,
      items: [],
      search_alphabet: null,
      role_filter: [],
      inputValue: '',
      isEditing: false,
      selectIdentifiedBreed: false,
      selectIdentifiedRace: false,
      onDatePickerFocus: false,
      onDatePickerBlur: true,
      onAmountFocus: false,
      selectIdentifiedPQ: false,
      checkItem: {
        checkId: 0,
        checkType: '',
      },
      cattleGroups: [],
      selectedPq: null,
      isExternalAbattoir: false,
      externalAbattoir: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.getFavouriteContacts();
  }

  componentDidMount() {
    let currentDeliveryDate = new Date();
    if (!!this.props.isTourPlanningProducer) {
      currentDeliveryDate = this.props.deliveryDate;
    } else {
      currentDeliveryDate.setDate(currentDeliveryDate.getDate() + 1);
    }
    this.setState({ startDate: currentDeliveryDate });
    this.getCompany();
  }

  checkButtonSelection = () => {
    const { businessType } = this.state;
    const { orderType } = this.props;
    if (
      (orderType === 'sellings' && businessType === '1') ||
      (orderType === 'buyings' &&
        (businessType === '1' || businessType === '2'))
    )
      this.setState({ freight_included: 0 });
    else if (
      (orderType === 'sellings' && businessType === '2') ||
      (orderType === 'buyings' && businessType === '3')
    )
      this.setState({ freight_included: 1 });
  };

  getCompany = async () => {
    let token = await this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const user = await extractData(value);
      //format programQ
      let programQ = '';
      programQ = user.program_quality.map((e) => e.name).join(',');

      this.setState({
        programQuality: programQ,
      });
      this.setState({ businessType: user.business_type });
      this.props.orderType === 'buyings' &&
        this.setState({ programQuality: '' });
      this.checkButtonSelection();
    }
  };

  renderDate = (timeStamp, format) => {
    const toDay = new Date();
    const date = new Date(timeStamp);
    const tomorrow = new Date(date);
    tomorrow.setDate(date.getDate() + 1);
    return date.getDate() === toDay.getDate() && format === 'input'
      ? `${addLeadingZero(date.getDate())}.${addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`
      : date.getDate() === toDay.getDate() && format === 'datepicker'
      ? date
      : date.getDate() !== toDay.getDate() && format === 'input'
      ? `${addLeadingZero(date.getDate())}.${addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`
      : date.getDate() !== toDay.getDate() && format === 'datepicker'
      ? date
      : `${addLeadingZero(date.getDate())}.${date.getMonth() + 1}.${date
          .getFullYear()
          .toString()
          .slice(-2)}`;
  };

  renderDateDeliveryUntil = (timeStamp) => {
    const date = new Date(timeStamp);
    return `${addLeadingZero(date.getDate())}.${addLeadingZero(
      date.getMonth() + 1,
    )}.${date.getFullYear()}`;
  };

  handleChangeDelivery = (date) => {
    if (date) {
      const year = `${date.getFullYear()}`;
      const newDate = `${date.getDate()}.${date.getMonth() + 1}.${year.substr(
        2,
        3,
      )}`;
      this.setState({
        startDate: date,
        dateChange: true,
        deliveryDate: newDate,
      });
    }
  };

  handleChangeDeliveryUntil = (date) => {
    if (date) {
      const year = `${date.getFullYear()}`;
      const newDate = `${date.getDate()}.${date.getMonth() + 1}.${year.substr(
        2,
        3,
      )}`;
      this.setState({
        endDate: date,
        dateChangeUntil: true,
        deliveryUntil: newDate,
      });
    }
  };

  handleFlatRateBlur = () => {
    var num = parseFloat(this.state.amount);
    var twoDecimal = num.toFixed(2);
    this.setState({ amount: twoDecimal });
  };

  AddZero = (amount) => {
    var num = parseFloat(amount);
    var twoDecimal = num.toFixed(2);
    return twoDecimal;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    const { startDate } = this.state;
    this.setState({ deliveryDate: this.renderDate(startDate, 'toBeStored') });

    // eslint-disable-next-line react/no-direct-mutation-state

    // eslint-disable-next-line default-case
    switch (name) {
      case 'deliveryUntil':
        this.setState({ deliveryUntil: value });
        break;
      case 'deliveryDate':
        this.setState({ deliveryDate: value });
        break;
      case 'piece':
        this.setState({ piece: value });
        break;
      case 'amount':
        this.setState({ amount: value ? value : 0 });
        break;
      case 'breed':
        this.setState({ breed: value });
        break;
      case 'race':
        this.setState({ race: value });
      case 'externalAbattoir':
        this.setState({ externalAbattoir: value });
        break;
    }
  };
  handleChangeBreed = (e) => {
    const value = e.label;
    this.setState({ breed: value });
    this.setState({ selectIdentifiedBreed: true });
  };
  handleChangeRace = (e) => {
    if (e) {
      const value = e.value;
      this.setState({ race: value });
      this.setState({ selectIdentifiedRace: true });
    } else {
      this.setState({ race: '' });
      this.setState({ selectIdentifiedRace: false });
    }
  };

  handleChangeProgrameQuality = async (programQuality) => {
    if (programQuality.length === 0) {
      this.setState({ programQuality: [], selectedPq: null });
    }
    let programQualityListCheck = undefined;
    if (programQuality) {
      let programQualityList = programQuality.map((pq) => pq.value);
      programQualityListCheck = programQualityList;
      if (programQualityList.length > 0) {
        this.setState({
          programQuality: programQualityList,
          selectedPq: programQuality,
        });
      }
    } else {
      this.setState({ selectedPq: null });
    }
    const { externalRequest } = this.props;
    if (!externalRequest) {
      await this.filterByRole(programQualityListCheck);
    }
    this.toggleSelectIdentifierPQ(!!programQuality);
  };

  extractcontactName = (contacts) => {
    const agr = filter(contacts, (o) => {
      return o.group === '1';
    });
    const trd = filter(contacts, (o) => {
      return o.group === '2';
    });
    const abt = filter(contacts, (o) => {
      return o.group === '3';
    });
    this.setState({ agriculturists: agr });
    this.setState({ traders: trd });
    this.setState({ abattoirs: abt });
  };

  getFavouriteContacts = async () => {
    let accessToken = this.props.users.accessToken;
    const value = await getContacts(this.state.companyId, accessToken);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contacts = await extractData(value);
      await this.setState({ contacts });
      await this.extractcontactName(this.state.contacts);
    }
  };

  handleOpenClose = (e, type) => {
    e.preventDefault();
    const { showTrd, showAgr, showAbt } = this.state;
    if (type === 'trd') {
      this.setState({ showTrd: !showTrd });
    } else if (type === 'agr') {
      this.setState({ showAgr: !showAgr });
    } else {
      this.setState({ showAbt: !showAbt });
    }
  };

  handleDefaultFlatRate = async (checked, receivers) => {
    const token = this.props.users.accessToken;
    const companyId = this.props.users.user.session.company_id;
    if (receivers.length === 1) {
      const flatRateResponse = await getContactPreferences(
        token,
        companyId,
        receivers[0].number,
      );

      if (flatRateResponse.status === 200) {
        const rateValue = await extractData(flatRateResponse);
        this.setState({
          amount:
            rateValue !== null
              ? rateValue !== null && rateValue.flat_rate !== null
                ? this.AddZero(rateValue.flat_rate)
                : this.state.amount
              : typeof this.props.orderDetail.amount !== 'undefined'
              ? this.AddZero(this.props.orderDetail.amount)
              : this.state.amount || this.state.amount,
          freight_included:
            rateValue !== null &&
            rateValue.freight_included !== null &&
            typeof rateValue.freight_included !== 'undefined'
              ? rateValue.freight_included
              : 0,
        });
      } else {
        this.setState({
          amount:
            this.AddZero(this.props.orderDetail.amount) || this.state.amount,
        });
      }
    } else {
      this.setState({
        amount:
          this.AddZero(this.props.orderDetail.amount) || this.state.amount,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      deliveryDate,
      deliveryUntil,
      piece,
      amount,
      breed,
      race,
      programQuality,
      receivers,
      earTags,
      freight_included,
      externalAbattoir,
    } = this.state;
    const { externalRequest } = this.props;
    let token = await this.props.users.accessToken;
    const companyType = this.props.users.user.session.company_type;
    let accessToken = `${token}`.replace(/['"]+/g, '');
    let breedValidation = [];
    let raceValidation = [];
    let qteValidation = [];
    let date = '';
    if (typeof deliveryDate !== 'undefined') {
      date = deliveryDate;
    } else {
      var nextDay = new Date();
      nextDay.setDate(nextDay.getDate() + 1);
      var dd = String(nextDay.getDate()).padStart(2, '0');
      var mm = String(nextDay.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yy = nextDay.getFullYear().toString().substr(-2);

      date = dd + '.' + mm + '.' + yy;
    }

    let nameSplit = date.split('.');

    // new date format 01.01.20
    let day;
    let month;
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = day + '.' + month + '.' + nameSplit[2];

    if (breed.length === 0) {
      breedValidation.push('');
    }

    if (race.length === 0) {
      raceValidation.push('');
    }

    if (programQuality === '') {
      qteValidation.push();
    }

    let cattle = { cattle_group: [...this.state.cattleGroups] };
    const cattleData = {
      breed: breed.length === 0 ? breedValidation[0] : breed,
      race:
        race.length === 0
          ? raceValidation[0]
          : this.props.orderType !== 'buyings'
          ? race
          : races.find((o) => o.value === race).value,
      program_quality: programQuality === '' ? [] : programQuality,
      number: piece,
    };
    if (externalRequest || companyType === '3') {
      // if we have external request and user didnt add cattle
      cattle.cattle_group =
        !!cattleData.number && !!cattleData.breed
          ? [...cattle.cattle_group, cattleData]
          : [...cattle.cattle_group];
    } else {
      cattle = { ...cattleData };
    }

    let dataBuy = {
      freight_included,
      delivery_date: newDate,
      receivers: receivers.map((receiver) => receiver.name),
      delivery_time: deliveryUntil,
      amount: amount > 0 ? amount : 0,
      is_producer_request: this.props.isTourPlanningProducer ? 1 : 0,
      ...cattle,
    };
    if (externalAbattoir !== '') {
      dataBuy.external_abattoir = externalAbattoir;
    }
    const earTagsList = [];
    earTags.map((item) => earTagsList.push(item.ear_tag));
    const dataSell = {
      freight_included,
      delivery_date: newDate,
      breed: removeDuplicates(breed).join(','),
      race: removeDuplicates(race).join(','),
      program_quality:
        this.props.orderType === 'buyings'
          ? programQuality
          : programQuality.length === 0
          ? []
          : programQuality.split(','),
      receivers: receivers.map((receiver) => receiver.name),
      ear_tags: earTagsList,
    };
    const order = await (this.props.orderType === 'buyings'
      ? this.handleCase1(accessToken, dataBuy)
      : this.handleCase2(accessToken, dataSell));

    if (externalRequest && !this.props.isTourPlanningProducer) {
      if (this.props.orderType === 'buyings') {
        if (companyType === '3') {
          this.props.goToTourPlanning(order._id);
          this.props.changeTab('open');
        } else {
          this.props.changeTab('bought');
        }
      } else {
        this.props.changeTab('open');
      }
    }
    if (this.props.isTourPlanningProducer) {
      await this.props.getProducer(newDate);
      // await this.props.reloadProducerRequest();
      await this.props.fetchData({id: order._id});
      // // await this.props.handleByRequestChange({
      //   id: order._id,
      // });
    }
  };

  handleCase1 = async (accessToken, dataBuy) => {
    const { buyRequestEdit, orderDetail } = this.props;
    const buyRequestEditData = buyRequestEdit
      ? await OrderService.editBuyStock(
          accessToken,
          orderDetail.order_id,
          dataBuy,
        )
      : await OrderService.buyStock(accessToken, dataBuy);

    if (
      typeof buyRequestEditData !== 'undefined' &&
      buyRequestEditData.status === 200
    ) {
      const value = await extractData(buyRequestEditData);
      this.props.success(<FormattedMessage {...messages.BuySaved} />);
      this.props.clear();
      await this.props.handleClosePanel();
      if (!this.props.isTourPlanningProducer) {
        await this.props.getOrders();
      }
      return value;
    } else {
      this.props.error(<FormattedMessage {...messages.NetworkProblem} />);
      this.props.clear();
      return null;
    }
  };

  handleCase2 = async (accessToken, dataSell) => {
    const sellStockData = await OrderService.sellStock(accessToken, dataSell);
    if (typeof sellStockData !== 'undefined' && sellStockData.status === 200) {
      const value = await extractData(sellStockData);
      await this.props.handleClosePanel();
      if (!this.props.isTourPlanningProducer) {
        await this.props.getOrders();
      }
      this.props.success(<FormattedMessage {...messages.SellSaved} />);
      this.props.clear();
      return value;
    } else {
      this.props.error(<FormattedMessage {...messages.InvalidEarTag} />);
      this.props.clear();
      return null;
    }
  };

  handleDeliveryBy = (value) => {
    const companyType = this.props.users.user.session.company_type;
    // if (companyType === '2') {
    //   this.setState({
    //     freight_included: 0,
    //   });
    // } else {
    this.setState({
      freight_included: value,
    });
    // }
  };

  handleRecipient = (e, name, index, companyType, number, companyId) => {
    const { receivers } = this.state;
    this.setState({
      checkItem: {
        checkId: companyId,
        checkType: companyType,
      },
    });
    const filtered = receivers.filter((value) => value.name !== name);
    const { checked } = e.target;
    if (this.props.externalRequest) {
      this.setState({ receivers: [...[], { name, number }] });
    } else {
      checked
        ? this.setState({
            receivers: [...this.state.receivers, { name, number }],
          })
        : this.setState({ receivers: filtered });
    }
    this.handleDefaultFlatRate(
      checked,
      checked ? [...this.state.receivers, { name, number }] : filtered,
    );
  };

  renderFav = (item, index, companyType) => {
    const { _id, number, name } = item;
    let companyId = _id;
    const { checkId, checkType } = this.state.checkItem;
    const { externalRequest } = this.props;
    return (
      <div key={index} className="order-group-list-recipient buy-sell">
        {externalRequest ? (
          <input
            checked={companyId === checkId && checkType === companyType}
            type="checkbox"
            id={_id}
            onChange={(e) =>
              this.handleRecipient(
                e,
                _id,
                index,
                companyType,
                number,
                companyId,
              )
            }
          />
        ) : (
          <input
            type="checkbox"
            id={_id}
            onChange={(e) =>
              this.handleRecipient(
                e,
                _id,
                index,
                companyType,
                number,
                companyId,
              )
            }
          />
        )}
        <label for={_id} className="favName">
          {name}
        </label>
      </div>
    );
  };

  showSellForm = (earTagsList, showForm) => {
    this.setState({
      earTags: [...earTagsList],
      breed: earTagsList.map((item) => item.breed),
      race: earTagsList.map((item) => item.race),
    });
    this.setState({ showStock: showForm });
  };

  filterByFavorit = async () => {
    const { programQuality } = this.state;
    this.setState((prevState) => ({
      search_favorit: !prevState.search_favorit,
    }));
    let updatedList = this.state.contacts;
    if (!this.state.search_favorit) {
      if (this.state.search_alphabet) {
        updatedList = updatedList.filter((item) => {
          return (
            item.is_favourite === 1 &&
            item.name.charAt(0) === this.state.search_alphabet
          );
        });
        if (
          this.props.orderType === 'buyings' &&
          this.state.role_filter.length > 0
        ) {
          updatedList = updatedList.filter((item) => {
            let result = item.program_quality.map((a) => a.name);
            return isEqual(result, programQuality);
          });
        }
      } else {
        updatedList = updatedList.filter((item) => {
          let result = item.program_quality.map((a) => a.name);
          if (
            (this.props.orderType === 'buyings',
            this.state.role_filter.length === 0)
          ) {
            return this.state.role_filter.length === 0
              ? item.is_favourite === 1
              : item.is_favourite === 1 && isEqual(result, programQuality);
          } else {
            return item.is_favourite === 1;
          }
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else if (this.state.search_favorit) {
      if (this.state.search_alphabet === null) {
        this.state.role_filter.length === 0
          ? this.setState({ items: this.state.contacts })
          : (updatedList = updatedList.filter((item) => {
              let result = item.program_quality.map((a) => a.name);
              return isEqual(result, programQuality);
            }));
      } else {
        updatedList = updatedList.filter((item) => {
          let result = item.program_quality.map((a) => a.name);
          return this.props.orderType === 'buyings' &&
            this.state.role_filter.length === 0
            ? item.name.charAt(0) === this.state.search_alphabet
            : item.name.charAt(0) === this.state.search_alphabet &&
                isEqual(result, programQuality);
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    }
    await this.extractcontactName(updatedList);
  };

  filterByText = async (event) => {
    const {
      contacts,
      search_favorit,
      search_alphabet,
      role_filter,
      programQuality,
    } = this.state;
    let updatedList = contacts;
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;

    this.setState({
      inputValue: event.target.value.replace(invalid, '').toLowerCase(),
    });

    updatedList = contacts;
    var name = event.target.value.replace(invalid, '').toLowerCase(); // try "Paul", "Paul Steve"
    var first_name = name.split(' ')[0];
    var last_name = name.substring(first_name.length).trim();

    let updatedList1 = [];

    updatedList1 = updatedList.filter((item) => {
      var nameItem = item.name.replace(invalid, '').toLowerCase(); // try "Paul", "Paul Steve"
      var first_nameItem = nameItem.split(' ')[0];
      var inverseNameItem =
        nameItem.substring(first_nameItem.length).trim() +
        ' ' +
        nameItem.split(' ')[0];

      return (
        item.name.replace(invalid, '').toLowerCase().trim().includes(name) !==
          false ||
        inverseNameItem
          .replace(invalid, '')
          .toLowerCase()
          .trim()
          .includes(name) !== false
      );
    });

    let updatedList2 = [];

    if (last_name.length === 0) {
      updatedList2 = updatedList.filter((item) => {
        return (
          item.name.toLowerCase().replace(invalid, '').trim().includes(name) !==
          false
        );
      });
    }

    updatedList = uniqBy([...updatedList1, ...updatedList2], '_id');

    if (search_favorit) {
      updatedList = updatedList.filter((item) => {
        return item.is_favourite === 1;
      });
    }

    if (search_alphabet) {
      updatedList = updatedList.filter((item) => {
        return item.name.charAt(0) === search_alphabet;
      });
    }

    if (this.props.orderType === 'buyings' && role_filter.length > 0) {
      updatedList = updatedList.filter((item) => {
        let result = item.program_quality.map((a) => a.name);
        return isEqual(result, programQuality);
      });
    }
    await this.extractcontactName(updatedList);
    this.setState({ items: updatedList });
  };

  filterByRole = async (programQuality = []) => {
    const { search_favorit, search_alphabet, contacts, inputValue } =
      this.state;
    let updatedList = contacts;
    this.setState({ role_filter: programQuality });
    if (programQuality.length > 0) {
      updatedList = updatedList.filter((item) => {
        let result = item.program_quality.map((a) => a.name);
        return difference(programQuality, result).length === 0;
      });
      if (search_alphabet !== null) {
        let alphabet = search_alphabet;
        updatedList = updatedList.filter((item) => {
          return item.name.charAt(0) === alphabet;
        });
      }
      if (search_favorit) {
        updatedList = updatedList.filter((item) => {
          return item.is_favourite === 1;
        });
      }
      if (inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else {
      if (search_alphabet !== null) {
        let alphabet = search_alphabet;
        updatedList = updatedList.filter((item) => {
          return item.name.charAt(0) === alphabet;
        });
      }
      if (search_favorit) {
        updatedList = updatedList.filter((item) => {
          return item.is_favourite === 1;
        });
      }
      this.setState({ items: updatedList });
    }
    await this.extractcontactName(updatedList);
  };

  renderOptionsProgramQuality = (list) => {
    const { orderType } = this.props;
    const { selectIdentifiedPQ, programQuality, selectedPq } = this.state;
    let defaultPqs = selectedPq;
    if (!selectedPq && typeof programQuality === 'string') {
      const pqList = programQuality.split(',');
      defaultPqs =
        orderType === 'sellings'
          ? list.filter((e) => pqList.indexOf(e.label) > -1)
          : null;
    }

    const isDisabled = orderType === 'sellings';
    const labelIsSelected = selectIdentifiedPQ || orderType === 'sellings';
    return (
      <MultiSelect
        filled
        label={<FormattedMessage {...messages.QualityProgram} />}
        isMulti={true}
        value={defaultPqs}
        isDisabled={isDisabled}
        className={clsx(labelIsSelected && 'selected')}
        closeMenuOnSelect={false}
        options={list}
        onChange={this.handleChangeProgrameQuality}
        isSearchable={false}
        isClearable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#F1FFDE',
            primary: '#7bcd10',
          },
        })}
      />
    );
  };

  toggleSelectIdentifierPQ = (val) => {
    this.setState({ selectIdentifiedPQ: val });
  };

  renderOptionsBreed = () => {
    const { selectIdentifiedBreed, breed, cattleGroups, piece } = this.state;
    // const companyType = this.props.users.user.session.company_type;
    const { orderType } = this.props;
    const isDisabled = orderType === 'sellings';
    return orderType === 'buyings' ? (
      <RegularInput
        label={<FormattedMessage {...messages.NewBreed} />}
        select
        className={clsx(selectIdentifiedBreed && 'selected')}
        value={
          breed.length > 0
            ? breeds.find(
                (breed) =>
                  breed.label === removeDuplicates(this.state.breed).join(', '),
              )
            : null
        }
        required
        requiredField={cattleGroups.length === 0 || !!piece}
        selectInputValue={breed}
        defaultValue=""
        name="breed"
        options={breeds}
        onChange={this.handleChangeBreed}
        isDisabled={isDisabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#F1FFDE',
            primary: '#7bcd10',
          },
        })}
      />
    ) : (
      <div className="render-breed-race breeds">
        <div className="label-sell">
          <FormattedMessage {...messages.NewBreed} />
        </div>
        <div className="tags">
          {removeDuplicates(breed)
            .filter(Boolean)
            .map((element, index) => {
              return (
                <div className="inner-tag" key={index}>
                  {element}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  renderOptionsRaces = () => {
    const { selectIdentifiedRace, race } = this.state;
    const { orderType } = this.props;
    const isDisabled = orderType === 'sellings';
    return orderType === 'buyings' ? (
      <RegularInput
        label={<FormattedMessage {...messages.NewRace} />}
        select
        className={clsx(selectIdentifiedRace && 'selected')}
        name="race"
        options={races}
        onChange={this.handleChangeRace}
        isClearable={true}
        selectInputValue={this.state.race}
        defaultValue=""
        isDisabled={isDisabled}
        value={
          this.state.race.length > 0
            ? races.find(
                (race) =>
                  race.value === removeDuplicates(this.state.race).join(', '),
              )
            : null
        }
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#F1FFDE',
            primary: '#7bcd10',
          },
        })}
      />
    ) : (
      <div className="render-breed-race races">
        <div className="label-sell">
          <FormattedMessage {...messages.NewRace} />
        </div>
        <div className="tags">
          {removeDuplicates(race)
            .filter(Boolean)
            .map((element, index) => {
              return (
                <div className="inner-tag" key={index}>
                  {element}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  toCurrency = (number) => {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });
    return number && formatter.format(number);
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleFocus = (type) => {
    this.setState({ [`on${type}Blur`]: false, [`on${type}Focus`]: true });
  };

  handleBlur = (type) => {
    this.setState({ [`on${type}Blur`]: true, [`on${type}Focus`]: false });
  };

  checkDatePickerState = () => {
    this.calendarRef.current.setOpen(true);
  };

  resetCattleForm = () => {
    this.setState({
      selectIdentifiedRace: false,
      selectIdentifiedBreed: false,
      selectIdentifiedPQ: false,
      piece: '',
      breed: [],
      race: [],
      programQuality: [],
    });
  };

  addNewCattleGroup = () => {
    const { piece, breed, race, programQuality } = this.state;
    const cattle = {
      race: typeof race === 'string' ? race : '',
      breed: breed,
      number: parseInt(piece),
      program_quality: programQuality === '' ? [] : programQuality,
    };
    this.setState({
      cattleGroups: [...this.state.cattleGroups, cattle],
      selectedPq: null,
    });
    this.resetCattleForm();
  };

  removeCattleGroup = (event, cattleIndex) => {
    this.setState({
      cattleGroups: this.state.cattleGroups.filter((cattle, index) => {
        return index !== cattleIndex;
      }),
    });
  };

  renderBuyRequestAmout = (companyType, orderType) => {
    const { cattleGroups, earTags, piece, breed } = this.state;
    return (
      <div className="">
        <RegularInput
          className="amount-input"
          label={<FormattedMessage {...messages.amount} />}
          value={orderType === 'buyings' ? piece : earTags.length}
          type="number"
          name="piece"
          min="1"
          required={cattleGroups.length === 0 || breed.length > 0}
          disabled={orderType !== 'buyings' ? true : false}
          onChange={(e) => this.handleChange(e)}
        />
      </div>
    );
  };

  renderDeliveryTime = (companyType, orderType) => {
    const { deliveryUntil, amount } = this.state;
    return (
      <div className="order-group-list">
        {orderType === 'buyings' && !this.props.isTourPlanningProducer && (
          <div>
            <RegularInput
              label={
                this.state.freight_included === 0 ? (
                  <FormattedMessage {...messages.PreferdDTBuyer} />
                ) : (
                  <FormattedMessage {...messages.PreferdDT} />
                )
              }
              value={deliveryUntil}
              type="time"
              name="deliveryUntil"
              required
              placeholder="HH:mm"
              min="00:00"
              max="23:59"
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        )}
      </div>
    );
  };

  renderDeliveryAndFlatRate = (companyType, orderType) => {
    const { deliveryUntil, amount } = this.state;
    return (
      <div className="">
        {orderType === 'buyings' && (
          <div>
            <RegularInput
              inputGroup
              label={<FormattedMessage {...messages.flateRate} />}
              value={amount > 0 ? amount : ''}
              type="number"
              name="amount"
              step="any"
              required={companyType === '3'}
              onBlur={this.handleFlatRateBlur}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        )}
      </div>
    );
  };

  handleExternalAbtCheckbox = () => {
    this.setState((prevState) => ({
      isExternalAbattoir: !prevState.isExternalAbattoir,
    }));
  };

  handleConfirmValidation = () => {
    const { deliveryDate, piece, receivers, cattleGroups, breed, amount } =
      this.state;
    const { externalRequest, orderType } = this.props;
    if (orderType === 'buyings') {
      if (
        (!!externalRequest && cattleGroups.length === 0) ||
        !externalRequest
      ) {
        return (
          deliveryDate === '' ||
          receivers.length === 0 ||
          piece === '' ||
          breed.length === 0 ||
          amount === 0
        );
      } else {
        return deliveryDate === '' || receivers.length === 0;
      }
    } else {
      return receivers.length === 0;
    }
  };

  renderFormRequest = (orderType) => {
    const {
      deliveryDate,
      piece,
      traders,
      agriculturists,
      abattoirs,
      showTrd,
      showAgr,
      showAbt,
      receivers,
      freight_included,
      search_favorit,
      onDatePickerBlur,
      onDatePickerFocus,
      cattleGroups,
      breed,
    } = this.state;
    const companyType = this.props.users.user.session.company_type;
    const { externalRequest } = this.props;

    const registeredAgr = agriculturists.filter((e) => e.is_registered === 1);
    const notRegisteredAgr = agriculturists.filter(
      (e) => e.is_registered === 0,
    );
    const showAgrContatcs = !externalRequest
      ? registeredAgr.length > 0
      : notRegisteredAgr.length > 0;
    const registeredTrd = traders.filter((e) => e.is_registered === 1);
    const notRegisteredTrd = traders.filter((e) => e.is_registered === 0);
    const showtrdContatcs = !externalRequest
      ? registeredTrd.length > 0
      : notRegisteredTrd.length > 0;

    const registeredAbt = abattoirs.filter((e) => e.is_registered === 1);
    const notRegisteredAbt = abattoirs.filter((e) => e.is_registered === 0);
    const showAbtContatcs = !externalRequest
      ? registeredAbt.length > 0
      : notRegisteredAbt.length > 0;

    const addNewCattleIsDisabled = breed.length === 0 || piece <= 0;
    return (
      <div className="order-by-sell buy-sell-request">
        <div className="buy-request-top-part">
          <h5 className="card-title">
            {
              orderType === 'sellings' || this.props.isTourPlanningProducer ? (
                <FormattedMessage {...messages.HomeSellings} /> //Sell Offer
              ) : (
                <FormattedMessage {...messages.HomeBuyings} />
              ) //Buy Request
            }
          </h5>
          <div className="form-group" style={{ height: '95%' }}>
            <form
              onSubmit={this.handleSubmit}
              style={{ height: '100%' }}
              id="buy-request-form"
            >
              <div
                className={`form-wrapper users-listing ${
                  orderType === 'sellings' ? 'sellings' : 'buyings'
                }`}
              >
                {((!externalRequest && companyType === '3') ||
                  companyType !== '3') && (
                  <div className="order-details-transported-by">
                    <div className="transported-by-text">
                      <FormattedMessage {...messages.TransportedBy} />
                    </div>
                    <ButtonGroup
                      id="delivery-button-group"
                      aria-label="Basic example"
                    >
                      <Button
                        className={`btn-white ${
                          freight_included === 0 ? 'btn-active btn-bold' : ''
                        }`}
                        onClick={(e) => this.handleDeliveryBy(0)}
                        variant="secondary"
                      >
                        <FormattedMessage {...messages.Buyer} />
                      </Button>
                      <Button
                        className={`btn-white ${
                          freight_included === 1 ? 'btn-active btn-bold' : ''
                        }`}
                        variant="secondary"
                        onClick={(e) => this.handleDeliveryBy(1)}
                      >
                        <FormattedMessage {...messages.Seller} />
                      </Button>
                    </ButtonGroup>
                  </div>
                )}
                <div className="order-group-list order-group-calendar regular-picker">
                  <div className="last-item-calender buy-sell-calendar">
                    <RegularInput
                      picker
                      label={
                        this.state.freight_included === 0 &&
                        !this.props.isTourPlanningProducer ? (
                          <FormattedMessage {...messages.DeliveryUntilBuyer} />
                        ) : (
                          <FormattedMessage {...messages.DeliveryUntil} />
                        )
                      }
                      type="text"
                      value={this.renderDate(this.state.startDate, 'input')}
                      name="deliveryDate"
                      onFocus={() => this.handleFocus('DatePicker')}
                      onBlur={() => this.handleBlur('DatePicker')}
                      onChange={(e) => this.handleChange(e)}
                      onClick={() => {
                        this.calendarRef.current.setOpen(true);
                      }}
                    />
                    <div className="date-field picker">
                      <SvgIcon
                        color={clsx(
                          onDatePickerFocus && '#7bd811',
                          onDatePickerBlur && '#9f9f9f',
                        )}
                        onClick={this.checkDatePickerState}
                        size="30"
                        viewBox="0 0 600 600"
                      >
                        {svgPaths['calendar']}
                      </SvgIcon>
                      <DatePicker
                        selected={this.renderDate(
                          this.state.startDate,
                          'datepicker',
                        )}
                        onChange={this.handleChangeDelivery}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRef}
                        locale={locale}
                      />
                    </div>
                  </div>
                </div>

                {this.renderDeliveryTime(companyType, orderType)}

                <div
                  className={clsx(
                    (companyType === '3' ||
                      (externalRequest && companyType === '2')) &&
                      'non-agrabiz-external-request',
                  )}
                >
                  {(companyType === '3' ||
                    (externalRequest && companyType === '2')) && (
                    <div className="order-group-list">
                      {this.renderBuyRequestAmout(companyType, orderType)}
                    </div>
                  )}

                  <div className="order-group-list">
                    <div className="select-container-bsr">
                      {this.renderOptionsBreed()}
                    </div>
                  </div>
                  <div className="order-group-list">
                    <div className="select-container-bsr">
                      {this.renderOptionsRaces()}
                    </div>
                  </div>

                  <div className="order-group-list">
                    <div
                      className={`last-item quality-program ${
                        orderType === 'buyings' ? 'buyings' : ''
                      }`}
                    >
                      {this.renderOptionsProgramQuality(qualities)}
                    </div>
                    {!externalRequest && companyType !== '3' && (
                      <div className="">
                        {this.renderBuyRequestAmout(companyType, orderType)}
                      </div>
                    )}
                    {this.renderDeliveryAndFlatRate(companyType, orderType)}

                    {(companyType === '3' ||
                      (externalRequest && companyType === '2')) && (
                      <div className="add-new-cattle">
                        <button
                          disabled={addNewCattleIsDisabled}
                          className="add-new-cattle-btn"
                          onClick={this.addNewCattleGroup}
                        >
                          <FormattedMessage {...messages.AddMoreCattles} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="cattle-group-container">
                  {cattleGroups.map((cattle, index) => {
                    return (
                      <div className="new-cattle" key={index}>
                        <div className="cattle-header">
                          <div className="cattle-title"></div>
                          <div
                            className="remove-cattle"
                            onClick={(e) => this.removeCattleGroup(e, index)}
                          >
                            x
                          </div>
                        </div>
                        <div
                          className={clsx(
                            !cattle.number && 'hide-cattle-section',
                            'cattle-section',
                          )}
                        >
                          <div className="c-section-title">
                            <FormattedMessage {...messages.amount} />
                          </div>
                          <div className="c-value">{cattle.number}</div>
                        </div>
                        <div
                          className={clsx(
                            !cattle.breed && 'hide-cattle-section',
                            'cattle-section',
                          )}
                        >
                          <div className="c-section-title">
                            <FormattedMessage {...messages.NewBreed} />
                          </div>
                          <div className="c-value">{cattle.breed}</div>
                        </div>
                        <div
                          className={clsx(
                            !cattle.race && 'hide-cattle-section',
                            'cattle-section',
                          )}
                        >
                          <div className="c-section-title">
                            <FormattedMessage {...messages.NewRace} />
                          </div>
                          <div className="c-value">{cattle.race}</div>
                        </div>
                        <div
                          className={clsx(
                            cattle.program_quality &&
                              cattle.program_quality.length === 0 &&
                              'hide-cattle-section',
                            'cattle-section',
                          )}
                        >
                          <div className="c-section-title">
                            <FormattedMessage {...messages.QualityPrograms} />
                          </div>
                          <div className="cattle-program-qualities">
                            {(cattle.program_quality || []).map((pq, index) => {
                              return (
                                <div key={index} className="c-value">
                                  {pq}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {externalRequest &&
                  (companyType === '3' || companyType === '2') &&
                  !this.props.isTourPlanningProducer && (
                    <div className="recipient-bottom-container">
                      <div className="recipient-list">
                        <div className="first-header-part">
                          <div className="recipient-title">
                            <FormattedMessage {...messages.externalAbattoir} />
                          </div>
                        </div>
                        <div
                          className="second-header-part recipient-search"
                          style={{ textAlign: 'right' }}
                        >
                          <Switch
                            onColor="#7bd812"
                            onChange={this.handleExternalAbtCheckbox}
                            checked={!!this.state.isExternalAbattoir}
                            className="react-switch"
                            id="normal-switch"
                          />
                        </div>
                      </div>
                      <div className="custom-users-list">
                        {this.state.isExternalAbattoir && (
                          <div className="order-group-list">
                            <RegularInput
                              className="amount-input"
                              label={
                                <FormattedMessage {...messages.tabCompany} />
                              }
                              value={this.state.externalAbattoir}
                              name="externalAbattoir"
                              required={false}
                              disabled={!this.state.isExternalAbattoir}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                <div className="recipient-bottom-container">
                  <div className="recipient-list">
                    <div className="first-header-part">
                      <div className="recipient-title">
                        <FormattedMessage {...messages.Recipient} />
                      </div>
                      <div className="recipient-favorite">
                        {search_favorit ? (
                          <MdStar onClick={this.filterByFavorit} />
                        ) : (
                          <MdStarBorder onClick={this.filterByFavorit} />
                        )}
                      </div>
                    </div>
                    <div className="second-header-part recipient-search">
                      <FormGroup>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <SvgIcon
                              size="20"
                              color="#b7b6b6"
                              viewBox="0 0 60 60"
                            >
                              {svgPaths['search']}
                            </SvgIcon>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            style={{ textAlign: 'left' }}
                            name="search"
                            onChange={this.filterByText}
                            placeholder={
                              this.props.language.locale === 'DE'
                                ? 'Suchen'
                                : 'Search'
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                  {showAgrContatcs && (
                    <div className="custom-users-list">
                      <div
                        className="order-group-list recipientList"
                        onClick={(e) => this.handleOpenClose(e, 'agr')}
                      >
                        <span className="trd">
                          {<FormattedMessage {...messages.Agriculturist} />}
                          {!externalRequest
                            ? ` (${registeredAgr.length})`
                            : ` (${notRegisteredAgr.length})`}
                        </span>
                        <div className="last-itemt">
                          {showAgr ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'agr')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'agr')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {showAgr &&
                        (!externalRequest
                          ? registeredAgr.map((item, index) =>
                              this.renderFav(item, index, 'agr'),
                            )
                          : notRegisteredAgr.map((item, index) =>
                              this.renderFav(item, index, 'agr'),
                            ))}
                    </div>
                  )}
                  {showtrdContatcs && (
                    <div className="custom-users-list">
                      <div
                        className="order-group-list recipientList"
                        onClick={(e) => this.handleOpenClose(e, 'trd')}
                      >
                        <span className="trd">
                          {<FormattedMessage {...messages.Trader} />}
                          {!externalRequest
                            ? ` (${registeredTrd.length})`
                            : ` (${notRegisteredTrd.length})`}
                        </span>
                        <div className="last-itemt">
                          {showTrd ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'trd')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'trd')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {showTrd &&
                        (!externalRequest
                          ? registeredTrd.map((item, index) =>
                              this.renderFav(item, index, 'trd'),
                            )
                          : notRegisteredTrd.map((item, index) =>
                              this.renderFav(item, index, 'trd'),
                            ))}
                    </div>
                  )}

                  {(orderType === 'sellings' ||
                    this.props.isTourPlanningProducer) &&
                    showAbtContatcs && (
                      <div className="custom-users-list">
                        <div
                          className="order-group-list recipientList"
                          onClick={(e) => this.handleOpenClose(e, 'abt')}
                        >
                          <span className="trd">
                            {<FormattedMessage {...messages.Abbaitor} />}
                            {!externalRequest
                              ? ` (${registeredAbt.length})`
                              : ` (${notRegisteredAbt.length})`}
                          </span>
                          <div className="last-itemt">
                            {showAbt ? (
                              <label
                                onClick={(e) => this.handleOpenClose(e, 'abt')}
                              >
                                <SvgIcon
                                  size="20"
                                  color="#808080"
                                  viewBox="0 0 580 580"
                                >
                                  {svgPaths['arrowCircleUp']}
                                </SvgIcon>
                              </label>
                            ) : (
                              <label
                                onClick={(e) => this.handleOpenClose(e, 'abt')}
                              >
                                <SvgIcon
                                  size="20"
                                  color="#808080"
                                  viewBox="0 0 580 580"
                                >
                                  {svgPaths['arrowCircleDown']}
                                </SvgIcon>
                              </label>
                            )}
                          </div>
                        </div>

                        {showAbt &&
                          (!externalRequest
                            ? registeredAbt.map((item, index) =>
                                this.renderFav(item, index, 'abt'),
                              )
                            : notRegisteredAbt.map((item, index) =>
                                this.renderFav(item, index, 'abt'),
                              ))}
                      </div>
                    )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className={clsx(
            this.props.isTourPlanningProducer && 'cattle-related-button',
            'button-group buy-sell buy-sell-req confirm buy-request-bottom-part',
          )}
        >
          <NormalButton
            disabled={this.handleConfirmValidation()}
            type="submit"
            form="buy-request-form"
          >
            <FormattedMessage {...messages.ConfirmButton} />
          </NormalButton>
        </div>
      </div>
    );
  };

  renderListStocks = () => (
    <ViewStock sellOffer={true} showSellForm={this.showSellForm} />
  );

  render() {
    const { orderType } = this.props;
    const { showStock } = this.state;
    return (
      <React.Fragment>
        {(orderType === 'buyings' ||
          (orderType === 'sellings' && !showStock)) &&
          this.renderFormRequest(orderType)}
        {orderType === 'sellings' && showStock && this.renderListStocks()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    getProducer: (date) => dispatch(producerActions.getAllProducer(date)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuySellRequest);
