import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import messages from '../../../components/Admin/messages';
import addStock from '../../../static/images/admin/icons_final/add.png';
import viewStock from '../../../static/images/admin/icons_final/viewstock.png';
import drug from '../../../static/images/admin/icons_final/meds.png';
import buystock from '../../../static/images/admin/icons_final/buy.png';
import sellstock from '../../../static/images/admin/icons_final/sell.png';
import reports from '../../../static/images/admin/icons_final/reports.png';
import cattleplanning from '../../../static/images/admin/icons_final/CattlePlanning.png';

import slaughterReport from '../../../static/images/admin/icons_final/slaughter_150.png';
import overview from '../../../static/images/admin/icons_final/Overview_150.png';

import manageTours from '../../../static/images/admin/icons_final/Transporter.png';
import { adminActions } from '../../../actions';
import { tab_role_enter_my_stock } from '../../../constants/';

class Main extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  changeHeaderTitle = (title) => {
    this.props.handleTitleChange(title);
  };

  sideMenu = (company_type, role, subscriptionType, is_producer) => {
    let linkSideMenu = [
      {
        index: 3,
        src: addStock,
        path: '/admin/new-stock',
        text: <FormattedMessage {...messages.HomeNewStock} />,
      },
      {
        index: 4,
        src: viewStock,
        path: '/admin/view-stock',
        text: <FormattedMessage {...messages.HomeViewStock} />,
      },
      {
        index: 5,
        src: buystock,
        path: '/admin/buy-stock',
        text: <FormattedMessage {...messages.HomeBuyings} />,
      },
      {
        index: 6,
        src: sellstock,
        path: '/admin/sell-stock',
        text: <FormattedMessage {...messages.HomeSellings} />,
      },
      // {
      //   index: 7,
      //   src: drug,
      //   path:
      //     subscriptionType === 'free' && company_type === '1'
      //       ? '/admin/choose-subscription'
      //       : '/admin/drug-administration',
      //   text: <FormattedMessage {...messages.HomeDrugAdministration} />,
      // },
      {
        index: 8,
        src: reports,
        path:
          subscriptionType === 'free' && company_type === '1'
            ? '/admin/choose-subscription'
            : '/admin/reports',
        text: <FormattedMessage {...messages.HomeReportsSearch} />,
      },
    ];
    let linkSideMenu_agr_st_wor = [
      {
        index: 3,
        src: addStock,
        path: '/admin/new-stock',
        text: <FormattedMessage {...messages.HomeNewStock} />,
      },
      {
        index: 4,
        src: viewStock,
        path: '/admin/view-stock',
        text: <FormattedMessage {...messages.HomeViewStock} />,
      },
      {
        index: 7,
        src: manageTours,
        path: '/admin/manage-tours',
        text: <FormattedMessage {...messages.HomeManageTours} />,
      },
    ];

    let linkSideMenuTrader = [
      {
        index: 3,
        src: addStock,
        path: '/admin/new-stock',
        text: <FormattedMessage {...messages.HomeNewStock} />,
      },
      {
        index: 4,
        src: viewStock,
        path: '/admin/view-stock',
        text: <FormattedMessage {...messages.HomeViewStock} />,
      },
      {
        index: 5,
        src: buystock,
        path: '/admin/buy-stock',
        text: <FormattedMessage {...messages.HomeBuyings} />,
      },
      {
        index: 6,
        src: sellstock,
        path: '/admin/sell-stock',
        text: <FormattedMessage {...messages.HomeSellings} />,
      },
      {
        index: 7,
        src: manageTours,
        path: '/admin/manage-tours',
        text: <FormattedMessage {...messages.HomeManageTours} />,
      },
      {
        index: 8,
        src: reports,
        path: '/admin/reports',
        text: <FormattedMessage {...messages.HomeSearchLom} />,
      },
      {
        index: 9,
        src: cattleplanning,
        path: '/admin/tourPlanningProducer',
        text: <FormattedMessage {...messages.HomeManageViehanmeldung} />,
      },
      {
        index: 10,
        src: slaughterReport,
        path: '/admin/slaughter-report',
        text: <FormattedMessage {...messages.slaughter_report_big} />,
      },
    ];
    let linkSideMenuTraderprod = [
      {
        index: 3,
        src: addStock,
        path: '/admin/new-stock',
        text: <FormattedMessage {...messages.HomeNewStock} />,
      },
      {
        index: 4,
        src: viewStock,
        path: '/admin/view-stock',
        text: <FormattedMessage {...messages.HomeViewStock} />,
      },
      {
        index: 5,
        src: buystock,
        path: '/admin/buy-stock',
        text: <FormattedMessage {...messages.HomeBuyings} />,
      },
      {
        index: 6,
        src: sellstock,
        path: '/admin/sell-stock',
        text: <FormattedMessage {...messages.HomeSellings} />,
      },
      {
        index: 7,
        src: manageTours,
        path: '/admin/manage-tours',
        text: <FormattedMessage {...messages.HomeManageTours} />,
      },
      {
        index: 8,
        src: reports,
        path: '/admin/reports',
        text: <FormattedMessage {...messages.HomeSearchLom} />,
      },
    ];
    let linkSideMenuTrader_drv = [
      {
        index: 7,
        src: manageTours,
        path: '/admin/manage-tours',
        text: <FormattedMessage {...messages.HomeManageTours} />,
      },
    ];
    let linkSideMenuTrader_off_wor = [
      {
        index: 3,
        src: addStock,
        path: '/admin/new-stock',
        text: <FormattedMessage {...messages.HomeNewStock} />,
      },
      {
        index: 4,
        src: viewStock,
        path: '/admin/view-stock',
        text: <FormattedMessage {...messages.HomeViewStock} />,
      },
      {
        index: 7,
        src: manageTours,
        path: '/admin/manage-tours',
        text: <FormattedMessage {...messages.HomeManageTours} />,
      },
      {
        index: 8,
        src: reports,
        path: '/admin/reports',
        text: <FormattedMessage {...messages.HomeSearchLom} />,
      },
    ];

    let linkSideMenuAbatoire = [
      {
        index: 3,
        src: buystock,
        path: '/admin/buy-stock',
        text: <FormattedMessage {...messages.HomeDeliveryStock} />,
      },
      {
        index: 8,
        src: reports,
        path: '/admin/reports',
        text: <FormattedMessage {...messages.HomeSearchEarTag} />,
      },
      {
        index: 9,
        src: slaughterReport,
        path: '/admin/slaughter-report',
        text: <FormattedMessage {...messages.slaughter_report_big} />,
      },

      {
        index: 9,
        src: overview,
        path: '/admin/buyings-overview',
        text: <FormattedMessage {...messages.buyings_overview} />,
      },
    ];

    let linkSideMenuVet = [
      {
        index: 3,
        src: manageTours,
        path: '/admin/live-stock',
        text: <FormattedMessage {...messages.HomeLiveStock} />,
      },
      {
        index: 4,
        src: reports,
        path: '/admin/reports',
        text: <FormattedMessage {...messages.HomeSearchEarTag} />,
      },
    ];

    let linkSideMenuPrivateVet = [
        {
        index: 7,
        src: drug,
        path:
          subscriptionType === 'free' && company_type === '1'
            ? '/admin/choose-subscription'
            : '/admin/drug-administration',
        text: <FormattedMessage {...messages.HomeDrugAdministration} />,
      },
    ];

    let linkSideMenuTransportor = [
      {
        index: 3,
        src: manageTours,
        path: '/admin/my-tours',
        text: <FormattedMessage {...messages.HomeMyTours} />,
      },
      {
        index: 4,
        src: reports,
        path: '/admin/reports',
        text: <FormattedMessage {...messages.RequestTours} />,
      },
    ];

    if (tab_role_enter_my_stock.indexOf(role) === -1) {
      linkSideMenu = linkSideMenu.filter((e) => e.index !== 3);
    }

    // eslint-disable-next-line default-case
    switch (company_type) {
      case '1':
        let roleShowingAgr = linkSideMenu;
        if (role === 'ROLE_AGR_STABLE_WORKER') {
          roleShowingAgr = linkSideMenu_agr_st_wor;
        }
        return roleShowingAgr.map((link, i) => (
          <Col key={i} className="column-space-arround-center" md={4}>
            <div
              onClick={() => this.changeHeaderTitle(link.text)}
              className="home-thumbnails"
            >
              <Link to={link.path}>
                <Image className="link" src={link.src} />
                <p className="text-center">{link.text}</p>
              </Link>
            </div>
          </Col>
        ));
        // eslint-disable-next-line no-unreachable
        break;
      case '2':
        let roleShowingTrd =
          is_producer === 1 ? linkSideMenuTrader : linkSideMenuTraderprod;

        if (role === 'ROLE_TRD_DRIVER') {
          roleShowingTrd = linkSideMenuTrader_drv;
        }
        if (role === 'ROLE_TRD_OFFICE_WORKER') {
          roleShowingTrd = roleShowingTrd;
        }
        return roleShowingTrd.map((link, i) => (
          <Col key={i} className="column-space-arround-center" md={4}>
            <div
              onClick={() => this.changeHeaderTitle(link.text)}
              className="home-thumbnails"
            >
              <Link to={link.path}>
                <Image className="link" src={link.src} />
                <p className="text-center">{link.text}</p>
              </Link>
            </div>
          </Col>
        ));
        // eslint-disable-next-line no-unreachable
        break;
      case '3':
        return linkSideMenuAbatoire.map((link, i) => (
          <Col key={i} className="column-space-arround-center" md={4}>
            <div
              onClick={() => this.changeHeaderTitle(link.text)}
              className="home-thumbnails"
            >
              <Link to={link.path}>
                <Image className="link" src={link.src} />
                <p className="text-center">{link.text}</p>
              </Link>
            </div>
          </Col>
        ));
        // eslint-disable-next-line no-unreachable
        break;
      case '4':
        return linkSideMenuTransportor.map((link, i) => (
          <Col key={i} className="column-space-arround-center" md={4}>
            <div
              onClick={() => this.changeHeaderTitle(link.text)}
              className="home-thumbnails"
            >
           <Link to={link.path}>
                <Image className="link" src={link.src} />
                <p className="text-center">{link.text}</p>
              </Link>
            </div>
          </Col>
        ));
        // eslint-disable-next-line no-unreachable
        break;

      case '5':
        return linkSideMenuVet.map((link, i) => (
          <Col key={i} className="column-space-arround-center" md={4}>
          <div
            onClick={() => this.changeHeaderTitle(link.text)}
            className="home-thumbnails"
          >
         <Link to={link.path}>
              <Image className="link" src={link.src} />
              <p className="text-center">{link.text}</p>
            </Link>
          </div>
        </Col>
        ));
        case '6':
          return linkSideMenuPrivateVet.map((link, i) => (
            <Col key={i} className="column-space-arround-center" md={4}>
            <div
              // onClick={() => this.changeHeaderTitle(link.text)}
              className="home-thumbnails"
            >
           <Link>
                <Image className="link" src={link.src} />
                <p className="text-center">{link.text}</p>
              </Link>
            </div>
          </Col>
          ));
        // eslint-disable-next-line no-unreachable
        break;
    }
  };

  render() {
    const company_type = this.props.users.user.session.company_type;
    const subscriptionType = this.props.subscription_type;
    const role = this.props.users.user.role;
    const is_producer = this.props.users.user.is_producer;
    return (
      <Row className="column-space-between contain">
        <Col className="container">
          <Row>
            {this.sideMenu(company_type, role, subscriptionType, is_producer)}
          </Row>
        </Col>
      </Row>
    );
  }
}

Main.propTypes = {
  users: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    handleTitleChange: (panelTitle) =>
      dispatch(adminActions.handleTitleChange(panelTitle)),
  };
}

const mapStateToProps = (state) => {
  const { users, panelTitle } = state.toJS();
  return { users, panelTitle };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
