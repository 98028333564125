import React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
class StockItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCheck = e => {
    if (e.target.checked) {
      this.props.checkAnimal();
    } else {
      this.props.removeAnimalFromListChecked();
    }
  };

  monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  getText(text) {
    if (text.length > 7) {
      return text.slice(0, 8);
    } else return text;
  }

  getWeek() {
    if (this.props.data.birth_date.length > 5) {
      var myarr = this.props.data.birth_date.split('.');
      //get todays date
      var current_date = new Date();
      var currentY = current_date
        .getFullYear()
        .toString()
        .substr(2, 4);
      let past_date;
      if (parseInt(myarr[2]) > parseInt(currentY))
        past_date = new Date(
          `${'19' + myarr[2] + '/' + myarr[1] + '/' + myarr[0]}`,
        );
      else
        past_date = new Date(
          `${'20' + myarr[2] + '/' + myarr[1] + '/' + myarr[0]}`,
        );
      var difference = this.monthDiff(past_date, current_date);
      return difference;
    } else {
      return this.props.data.birth_date;
    }
  }

  getItem = (box, passport) => {
    var id = 'checkbox' + this.props.data.ear_tag_output_format;
    const { editItem } = this.props;
    const passpord = passport !== '' && typeof passport !== 'undefined';
    const sellOffer = this.props.data.is_available;
    return (
      <React.Fragment>
        {((passport && sellOffer === 0) || (!passport && sellOffer === 0)) && (
          <Row onClick={editItem}>
            <Col xs={1} md={1} className="">
              <div className="round"></div>
            </Col>
            <Col xs={3} md={3} className="content-center not-available">
              {this.formatEarTag(this.props.data.ear_tag_output_format)}
            </Col>
            <Col xs={2} md={2} className=" content-center not-available">
              {this.getWeek()} m
            </Col>
            <Col xs={2} md={2} className=" content-center not-available">
              {this.props.data.race}
            </Col>
            <Col xs={2} md={2} className=" content-center not-available">
              {this.props.data.breed}
            </Col>
            <Col xs={1} md={1} className="blockB content-center not-available ">
              {this.props.data.box}
            </Col>
          </Row>
        )}
        {passpord && sellOffer === 1 && (
          <Row>
            <Col xs={1} md={1} className="">
              <div className="round">
                <input
                  type="checkbox"
                  checked={this.props.checked === -1 ? false : true}
                  id={id}
                  onChange={this.handleCheck}
                  disabled={this.props.capacity}
                />
                <label htmlFor={id} />
              </div>
            </Col>
            <Col
              onClick={editItem}
              xs={3}
              md={3}
              className="with-passport content-center"
            >
              {this.formatEarTag(this.props.data.ear_tag_output_format)}
            </Col>
            <Col
              onClick={editItem}
              xs={2}
              md={2}
              className=" with-passport content-center"
            >
              {this.getWeek()} m
            </Col>
            <Col
              onClick={editItem}
              xs={2}
              md={2}
              className=" with-passport content-center"
            >
              {this.props.data.race}
            </Col>
            <Col
              onClick={editItem}
              xs={2}
              md={2}
              className=" with-passport content-center"
            >
              {this.props.data.breed}
            </Col>
            <Col
              onClick={editItem}
              xs={1}
              md={1}
              className="blockB with-passport content-center"
            >
              {this.props.data.box}
            </Col>
          </Row>
        )}

        {!passpord && sellOffer === 1 && (
          <Row>
            <Col xs={1} md={1} className="">
              <div className="round">
                <input
                  type="checkbox"
                  checked={this.props.checked === -1 ? false : true}
                  id={id}
                  onChange={this.handleCheck}
                  disabled={this.props.capacity}
                />
                <label htmlFor={id} />
              </div>
            </Col>
            <Col onClick={editItem} xs={3} md={3} className="content-center">
              {this.formatEarTag(this.props.data.ear_tag_output_format)}
            </Col>
            <Col onClick={editItem} xs={2} md={2} className="content-center">
              {this.getWeek()} m
            </Col>
            <Col onClick={editItem} xs={2} md={2} className="content-center">
              {this.props.data.race}
            </Col>
            <Col onClick={editItem} xs={2} md={2} className="content-center">
              {this.props.data.breed}
            </Col>
            <Col
              onClick={editItem}
              xs={1}
              md={1}
              className="blockB content-center"
            >
              {this.props.data.box}
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  };

  formatEarTag = tag => {
    tag = tag.replace(/\s/g, '');
    var output =
      tag.substr(0, 2) +
      ' ' +
      tag.substr(2, 2) +
      ' ' +
      tag.substr(4, 3) +
      ' ' +
      tag.substr(7, tag.length - 6);
    return output;
  };

  render() {
    const { passport, box } = this.props.data;
    return (
      <React.Fragment>
        <ListGroupItem>{this.getItem(box, passport)}</ListGroupItem>
      </React.Fragment>
    );
  }
}

export default StockItem;
