import FileSaver from 'file-saver';
import { invoiceConstants, alertConstants } from '../constants';
import {
  getListOfInvoices,
  downloadInvoiceDetails,
  makePayment,
  getCustomer,
  editPayment,
  downloadSEPA,
} from '../services/invoice.service';
import extractData from '../services/data.processing';

function getAllInvoices(page) {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().toJS().users.accessToken;
      const value = await getListOfInvoices(accessToken, page);
      const invoices = await extractData(value);
      await dispatch({ type: invoiceConstants.INVOICE_GET_ALL, invoices });
    } catch (error) {
      dispatch({ type: alertConstants.ERROR, error });
    }
  };
}

function downloadDetails(id) {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().toJS().users.accessToken;
      const value = await downloadInvoiceDetails(accessToken, id);
      FileSaver.saveAs(value, 'invoice.pdf');
    } catch (error) {
      dispatch({ type: alertConstants.ERROR, error });
    }
  };
}

function createPayment(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: invoiceConstants.REQUEST_INVOICE_PAYMENT,
      });
      const accessToken = getState().toJS().users.accessToken;
      const value = await makePayment(data, accessToken);
      const customer = await extractData(value);
      dispatch({
        type: invoiceConstants.INVOICE_PAYMENT_SUCCESS,
        customer,
      });
    } catch (error) {
      dispatch({
        type: invoiceConstants.INVOICE_PAYMENT_FAILURE,
        error: error.message,
      });
    }
  };
}

function fetchCustomer() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: invoiceConstants.REQUEST_INVOICE_CUSTOMER,
      });
      const accessToken = getState().toJS().users.accessToken;
      const value = await getCustomer(accessToken);
      const customer = await extractData(value);
      dispatch({
        type: invoiceConstants.INVOICE_CUSTOMER_SUCCESS,
        customer,
      });
    } catch (error) {
      dispatch({
        type: invoiceConstants.INVOICE_CUSTOMER_FAILURE,
        error: error.message,
      });
    }
  };
}

function showEditPayment() {
  return async (dispatch, getState) => {
    dispatch({
      type: invoiceConstants.INVOICE_PAYMENT_EDIT,
    });
  };
}

function showEditPaymentCancel() {
  return async (dispatch, getState) => {
    dispatch({
      type: invoiceConstants.INVOICE_PAYMENT_EDIT_CANCEL,
    });
  };
}

function updatePayment(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: invoiceConstants.REQUEST_INVOICE_PAYMENT,
      });
      const accessToken = getState().toJS().users.accessToken;
      const value = await editPayment(data, accessToken);
      const customer = await extractData(value);
      dispatch({
        type: invoiceConstants.INVOICE_PAYMENT_SUCCESS,
        customer,
      });
    } catch (error) {
      dispatch({
        type: invoiceConstants.INVOICE_PAYMENT_FAILURE,
        error: error.message,
      });
    }
  };
}

function downloadSepaPdf() {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().toJS().users.accessToken;
      await downloadSEPA(accessToken);
      // FileSaver.saveAs('data:application/pdf;base64,' + value, 'sepa.pdf');
    } catch (error) {
      dispatch({ type: alertConstants.ERROR, error });
    }
  };
}

export const invoiceActions = {
  getAllInvoices,
  downloadDetails,
  createPayment,
  fetchCustomer,
  showEditPayment,
  showEditPaymentCancel,
  updatePayment,
  downloadSepaPdf,
};
