/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-self-compare */
import React, { Component } from 'react';
import {
  Col,
  Row,
  ListGroup,
  FormControl,
  FormGroup,
  InputGroup,
  ListGroupItem,
  Card,
  Modal,
  Dropdown,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { MdSearch, MdStarBorder, MdStar } from 'react-icons/md';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import { findIndex } from 'lodash';
import Form from './Form';
import AddForm from './formContact';
import Invitation from './Invitation';
import { alertActions } from '../../../actions';
import extractData from '../../../services/data.processing';
import ToggleMenuButton from '../../Shared/components/buttons/ToggleMenuButton/ToggleMenuButton';
import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';
import {
  getContacts,
  setFavorit,
  putResponsible,
  getDocCompany,
  searchContacts,
  getCompany,
  addContact,
} from '../../../services/company.service';
import ListQuality from './ListQuality';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../components/Admin/messages';
import Popup from '../../Popup';
import Select from 'react-select';
import './contact.css';
import 'react-virtualized/styles.css';

const alphabetString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const alphabets = alphabetString.split('');

class Contact extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      search_favorit: false,
      search_alphabet: null,
      initialItems: [],
      items: [],
      listCompany: [],
      showForm: false,
      selected: {},
      searchCompany: false,
      enableEdit: false,
      tabPhotos: [],
      qualite_program: [],
      showPopup: false,
      item: {},
      itemSplite: [],
      company_type: '',
      inputValue: '',
      role_filter: '0',
      showDetail: false,
      cursor: 1,
      hasMore: true,
      itemBackground: null,
      showAddContact: false,
      showInviteContact: false,
      selectNonAgrabiz: false,
      isReloaded: false,
      isLoaded: false,
      disableFav: false,
      roles: [
        {
          value: '1',
          role: this.props.intl.formatMessage({
            id: 'agrabiz.components.contact.agr',
          }),
          label: (
            <FormattedMessage {...messages.RegisterStepTwoTitleAgriculture} />
          ),
        },
        {
          value: '2',
          role: this.props.intl.formatMessage({
            id: 'agrabiz.components.contact.trd',
          }),
          label: <FormattedMessage {...messages.RegisterStepTwoTitleTrader} />,
        },
        {
          value: '3',
          role: this.props.intl.formatMessage({
            id: 'agrabiz.components.contact.abt',
          }),
          label: (
            <FormattedMessage {...messages.RegisterStepTwoTitleAbatoire} />
          ),
        },
        {
          value: '4',
          role: this.props.intl.formatMessage({
            id: 'agrabiz.components.contact.trs',
          }),
          label: (
            <FormattedMessage {...messages.RegisterStepTwoTitleTransporter} />
          ),
        },
        {
          value: '5',
          role: this.props.intl.formatMessage({
            id: 'agrabiz.components.contact.vetOffice',
          }),
          label: <FormattedMessage {...messages.VeterinarianOffice} />,
        },
        {
          value: '6',
          role: this.props.intl.formatMessage({
            id: 'agrabiz.components.contact.vetP',
          }),
          label: <FormattedMessage {...messages.VeterinarianPrivate} />,
        },
      ],
      menuItems: [
        {
          id: 0,
          title: <FormattedMessage {...messages.SendInvitation} />,
          selected: false,
          path: (
            <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
          ),
          func: 'sendInvitation',
          allowedUsers: ['1', '2', '3', '4', '5', '6'],
        },
        {
          id: 1,
          title: <FormattedMessage {...messages.AddCsvContact} />,
          selected: false,
          path: (
            <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
          ),
          func: 'importCsvContact',
          allowedUsers: ['2'],
        },
        {
          id: 2,
          title: <FormattedMessage {...messages.AddManualContact} />,
          selected: false,
          path: (
            <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
          ),
          func: 'addManualContact',
          allowedUsers: ['1', '2', '3', '4', '5', '6'],
        },
        {
          id: 3,
          title: <FormattedMessage {...messages.AddContact} />,
          selected: false,
          path: (
            <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469s132.667969-26.628906 181.019531-74.980469c48.351563-48.351562 74.980469-112.640625 74.980469-181.019531s-26.628906-132.667969-74.980469-181.019531zm-181.019531 397.019531c-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216-96.898438 216-216 216zm20-236.019531h90v40h-90v90h-40v-90h-90v-40h90v-90h40zm0 0" />
          ),
          func: 'addCompany',
          allowedUsers: ['1', '2', '3', '4', '5', '6'],
        },
      ],

      popupTitle: <FormattedMessage {...messages.SearchContact} />,
    };
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    });
  }

  UNSAFE_componentWillMount(nextProps) {
    this._isMounted = true;
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.getContacts();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ isLoaded: false });
  }

  getContacts = async () => {
    let token = this.props.users.accessToken;
    let company_id = this.props.users.user.session.company_id;
    const value = await getContacts(company_id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contacts = await extractData(value);
      if (typeof contacts !== 'undefined') {
        this.setState({ initialItems: contacts });
        this.setState({ items: contacts });
        this.setState({ itemSplite: contacts });
        this.setState({ isLoaded: true });
      }
    }
  };

  getContactsChild = async () => {
    let token = this.props.users.accessToken;
    let company_id = this.props.users.user.session.company_id;
    const value = await getContacts(company_id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contacts = await extractData(value);
      if (typeof contacts !== 'undefined') {
        this.setState({ initialItems: contacts });
        this.setState({ items: contacts.slice(0, 1000) });
        this.setState({ itemSplite: contacts });
      }
    }
  };

  fetchMoreData = async () => {
    if (
      this.state.cursor * 1000 + 1000 >= this.state.itemSplite.length ||
      this.state.items.length === 0
    ) {
      this.setState({ hasMore: false });
    } else {
      this.setState({
        items: this.state.items.concat(
          this.state.itemSplite.slice(
            this.state.cursor * 1000,
            this.state.cursor * 1000 + 1000,
          ),
        ),
        cursor: this.state.cursor + 1,
      });
    }
  };

  setFavIcon = async (item) => {
    if (item.is_favourite === 1) {
      item.is_favourite = 0;
    } else {
      item.is_favourite = 1;
    }

    return item;
  };

  setFavorit = async (item) => {
    const { initialItems, search_favorit, search_alphabet, role_filter } =
      this.state;
    let updatedList = initialItems;

    let token = this.props.users.accessToken;
    let company_id = this.props.users.user.session.company_id;
    let value = null;
    const company_type = this.props.users.user.session.company_type;

    if (
      new Set(['1', '3']).has(company_type) &&
      item.group === '5' &&
      !item.is_favourite &&
      this.state.search_favorit
    ) {
      this.setState({
        showPopup: true,
        company_type,
        item,
      });
      await setFavorit(company_id, token, item._id, 1);
      await extractData(value);
      await this.getContacts();
    } else if (item.is_favourite && !this.state.search_favorit) {
      value = await setFavorit(company_id, token, item._id, 0);
      await extractData(value);
      await this.getContacts();
      if (
        this.state.role_filter > 0 ||
        this.state.inputValue !== '' ||
        (this.state.inputValue !== '' && this.state.search_alphabet !== null)
      ) {
        await this.getFilteredList();
      }
      await this.getNotAgrabizList();
    } else if (item.is_favourite && this.state.search_favorit) {
      value = await setFavorit(company_id, token, item._id, 0);
      await extractData(value);
      await this.getContacts();
      this.filterByFavorit();
      this.setState({ search_favorit: !this.state.search_favorit });
    } else if (item.is_favourite === 0 && this.state.search_favorit === false) {
      value = await setFavorit(company_id, token, item._id, 1);
      await extractData(value);
      await this.getContacts();
      if (
        this.state.role_filter > 0 ||
        this.state.inputValue !== '' ||
        (this.state.inputValue !== '' && this.state.search_alphabet !== null)
      ) {
        await this.getFilteredList();
      }
    }
    await this.getNotAgrabizList();

    if (search_alphabet) {
      updatedList = this.state.initialItems.filter((item) => {
        return item.name.charAt(0) === search_alphabet;
      });
      this.setState({ items: updatedList });
    }
  };

  filterByText = (event) => {
    const { initialItems, search_favorit, search_alphabet, role_filter } =
      this.state;
    let updatedList = initialItems;
    let invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;

    if (this.state.selectNonAgrabiz) {
      updatedList = updatedList.filter((item) => {
        return this.state.role_filter === '0'
          ? item.is_registered === 0
          : item.is_registered === 0 && item.group === this.state.role_filter;
      });
    }
    if (
      this.state.selectNonAgrabiz === false &&
      this.state.role_filter === '8'
    ) {
      updatedList = updatedList.filter((item) => {
        return item.is_registered === 1;
      });
    }
    this.setState({
      inputValue: event.target.value.replace(invalid, '').toLowerCase(),
    });
    updatedList = updatedList.filter((item) => {
      return (
        item.name
          .toLowerCase()
          .search(event.target.value.replace(invalid, '').toLowerCase()) !== -1
      );
    });

    if (search_favorit) {
      updatedList = updatedList.filter((item) => {
        return item.is_favourite === 1;
      });
    }

    if (search_alphabet) {
      updatedList = updatedList.filter((item) => {
        return item.name.charAt(0) === search_alphabet;
      });
    }

    if (role_filter !== '0' && role_filter !== '8') {
      updatedList = updatedList.filter((item) => {
        return item.group === role_filter;
      });
    }

    this.setState({ items: updatedList });
  };

  getNotAgrabizList = () => {
    let updatedList = this.state.initialItems;
    if (this.state.selectNonAgrabiz) {
      updatedList = updatedList.filter((item) => {
        return this.state.role_filter === '0'
          ? item.is_registered === 0
          : item.is_registered === 0 && item.group === this.state.role_filter;
      });
    }
    this.setState({ items: updatedList });
  };

  getFilteredList = async () => {
    let updatedList = this.state.initialItems;
    // Selected : role selected
    if (
      this.state.search_alphabet === null &&
      this.state.inputValue === '' &&
      this.state.role_filter > 0
    ) {
      updatedList = updatedList.filter((item) => {
        return item.group === this.state.role_filter;
      });
    } // Selected : role  + search_alphabet
    else if (
      this.state.search_alphabet !== null &&
      this.state.inputValue === '' &&
      this.state.role_filter > 0
    ) {
      updatedList = updatedList.filter((item) => {
        return (
          item.group === this.state.role_filter &&
          item.name.charAt(0).toUpperCase() === this.state.search_alphabet
        );
      });
    } // Selected : role  + search_alphabet  + search_bar
    else if (
      this.state.search_alphabet !== null &&
      this.state.inputValue !== '' &&
      this.state.role_filter > 0
    ) {
      updatedList = updatedList.filter((item) => {
        return (
          item.group === this.state.role_filter &&
          item.name.charAt(0).toUpperCase() === this.state.search_alphabet &&
          item.name.toLowerCase().search(this.state.inputValue) !== -1
        );
      });
    } // Selected : role  +  search_bar
    else if (this.state.inputValue !== '' && this.state.role_filter > 0) {
      updatedList = updatedList.filter((item) => {
        return (
          item.group === this.state.role_filter &&
          item.name.toLowerCase().search(this.state.inputValue) !== -1
        );
      });
    } // Selected : search_bar
    else if (
      this.state.search_alphabet === null &&
      this.state.inputValue !== '' &&
      this.state.role_filter === '0'
    ) {
      updatedList = updatedList.filter((item) => {
        return item.name.toLowerCase().search(this.state.inputValue) !== -1;
      });
    } // Selected : search_bar + search_alphabet
    else if (
      this.state.search_alphabet !== null &&
      this.state.inputValue !== '' &&
      this.state.role_filter === '0'
    ) {
      updatedList = updatedList.filter((item) => {
        return (
          item.name.charAt(0).toUpperCase() === this.state.search_alphabet &&
          item.name.toLowerCase().search(this.state.inputValue) !== -1
        );
      });
    }
    if (this.state.selectNonAgrabiz) {
      updatedList = updatedList.filter((item) => {
        return this.state.role_filter === '0'
          ? item.is_registered === 0
          : item.is_registered === 0 && item.group === this.state.role_filter;
      });
    }
    this.setState({ items: updatedList });
  };

  getFilteredListByAlpha = async (alpha) => {
    let updatedList = this.state.initialItems;

    updatedList = updatedList.filter((item) => {
      return (
        item.group === this.state.role_filter &&
        item.name.charAt(0).toUpperCase() === alpha
      );
    });
    this.setState({ items: updatedList });
  };

  changeFilter = async (e) => {
    if (e === null || e.length === 0) {
      this.renderAllList();
    } else if (e.length > 2) {
      this.renderNotFound();
    } else {
      e !== undefined &&
        (await e.map(async (item) => {
          item.value === '7' || item.value === '8'
            ? e.length === 1
              ? this.testUserType(item)
              : e.length === 2
              ? (e[0].value === '7' && e[1].value === '8') ||
                (e[0].value === '8' && e[1].value === '7')
                ? this.renderNotFound()
                : await this.handleChangeRoleg(item)
              : await this.handleChangeRoleg(item)
            : e.length === 2
            ? e[0].value !== '7' &&
              e[1].value !== '7' &&
              e[0].value !== '8' &&
              e[1].value !== '8'
              ? this.renderNotFound()
              : await this.filterByRole(item)
            : this.renderAllListByType(e, item);
        }));
    }
  };

  testUserType = async (item) => {
    if (item.value === '7') {
      await this.setState({ role_filter: '0' });
    } else {
      await this.setState({ role_filter: '8' });
    }
    await this.handleChangeRoleg(item);
  };
  renderAllListByType = async (e, item) => {
    await this.setState({ selectNonAgrabiz: false });
    await this.filterByRole(item);
  };
  renderAllList = async () => {
    this.setState({ role_filter: '0' });
    await this.handleChangeRoleg('10');
  };
  renderNotFound = async () => {
    await this.setState({ selectNonAgrabiz: false });
    await this.filterByRole({ value: '9' });
  };
  handleChangeRoleg = async (e) => {
    e.value === '7'
      ? await this.setState({ selectNonAgrabiz: true })
      : await this.setState({ selectNonAgrabiz: false });
    this.checkNonAgrabiz();
  };

  filterByRole = (event) => {
    const { search_favorit, search_alphabet, inputValue } = this.state;
    const { value } = event;
    this.setState({ role_filter: value });
    let updatedList = this.state.initialItems;
    if (event.value !== '0') {
      if (this.state.selectNonAgrabiz === true) {
        updatedList = updatedList.filter((item) => {
          return this.state.role_filter === '0'
            ? item.is_registered === 0
            : item.is_registered === 0 && item.group === value;
        });
      }
      updatedList = updatedList.filter((item) => {
        return item.group === value;
      });
      if (search_alphabet !== null) {
        let alphabet = search_alphabet;
        updatedList = updatedList.filter((item) => {
          return item.name.charAt(0) === alphabet;
        });
      }
      if (search_favorit) {
        updatedList = updatedList.filter((item) => {
          return item.is_favourite === 1;
        });
      }

      if (inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(inputValue) !== -1;
        });
      }

      this.setState({ items: updatedList });
    } else {
      if (search_alphabet !== null) {
        let alphabet = search_alphabet;
        updatedList = updatedList.filter((item) => {
          return item.name.charAt(0) === alphabet;
        });
      }
      if (search_favorit) {
        updatedList = updatedList.filter((item) => {
          return item.is_favourite === 1;
        });
      }
      if (this.state.selectNonAgrabiz) {
        updatedList = updatedList.filter((item) => {
          return this.state.role_filter === '0'
            ? item.is_registered === 0
            : item.is_registered === 0 && item.group === value;
        });
      }
      this.setState({ items: updatedList });
    }
  };

  filterByFavorit = () => {
    this.setState((prevState) => ({
      search_favorit: !prevState.search_favorit,
    }));
    let updatedList = this.state.initialItems;
    if (this.state.selectNonAgrabiz) {
      updatedList = updatedList.filter((item) => {
        return this.state.role_filter === '0'
          ? item.is_registered === 0
          : item.is_registered === 0 && item.group === this.state.role_filter;
      });
    }
    if (
      this.state.selectNonAgrabiz === false &&
      this.state.role_filter === '8'
    ) {
      updatedList = updatedList.filter((item) => {
        return item.is_registered === 1;
      });
    }

    if (!this.state.search_favorit) {
      if (this.state.search_alphabet) {
        updatedList = updatedList.filter((item) => {
          return (
            item.is_favourite === 1 &&
            item.name.charAt(0) === this.state.search_alphabet
          );
        });
        if (this.state.role_filter !== '0' && this.state.role_filter !== '8') {
          updatedList = updatedList.filter((item) => {
            return item.group === this.state.role_filter;
          });
        }
      } else {
        updatedList = updatedList.filter((item) => {
          return this.state.role_filter === '0' ||
            this.state.role_filter === '8'
            ? item.is_favourite === 1
            : item.is_favourite === 1 && item.group === this.state.role_filter;
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else if (this.state.search_favorit) {
      if (this.state.search_alphabet === null) {
        this.state.role_filter === '0' || this.state.role_filter === '8'
          ? this.setState({ items: this.state.initialItems })
          : (updatedList = updatedList.filter((item) => {
              return item.group === this.state.role_filter;
            }));
      } else {
        updatedList = updatedList.filter((item) => {
          return this.state.role_filter === '0' ||
            this.state.role_filter === '8'
            ? item.name.charAt(0) === this.state.search_alphabet
            : item.name.charAt(0) === this.state.search_alphabet &&
                item.group === this.state.role_filter;
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    }
  };

  filterByAlphabet = (alphabet) => {
    let updatedList = this.state.initialItems;
    if (this.state.selectNonAgrabiz) {
      updatedList = updatedList.filter((item) => {
        return this.state.role_filter === '0'
          ? item.is_registered === 0
          : item.is_registered === 0 && item.group === this.state.role_filter;
      });
    }
    if (
      this.state.selectNonAgrabiz === false &&
      this.state.role_filter === '8'
    ) {
      updatedList = updatedList.filter((item) => {
        return item.is_registered === 1;
      });
    }
    if (this.state.search_alphabet !== alphabet) {
      updatedList = updatedList.filter((item) => {
        return this.state.search_favorit
          ? this.state.role_filter === '0' || this.state.role_filter === '8'
            ? item.name.charAt(0).toUpperCase() === alphabet &&
              item.is_favourite === 1
            : item.group === this.state.role_filter &&
              item.name.charAt(0).toUpperCase() === alphabet &&
              item.is_favourite === 1
          : this.state.role_filter === '0' || this.state.role_filter === '8'
          ? item.name.charAt(0).toUpperCase() === alphabet
          : item.group === this.state.role_filter &&
            item.name.charAt(0).toUpperCase() === alphabet;
      });
      this.setState({ search_alphabet: alphabet });
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else if (!this.state.search_favorit) {
      this.setState({ search_alphabet: null });
      updatedList = updatedList.filter((item) => {
        return this.state.role_filter !== '0' && this.state.role_filter !== '8'
          ? item.group === this.state.role_filter
          : item === item;
      });
      this.setState({ items: updatedList });
    } else if (this.state.search_favorit) {
      this.setState({ search_alphabet: null });
      updatedList = updatedList.filter((item) => {
        return this.state.role_filter === '0' && this.state.role_filter !== '8'
          ? item.is_favourite === 1
          : item.is_favourite === 1 && item.group === this.state.role_filter;
      });
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else {
      this.setState({ search_alphabet: null });
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: this.state.initialItems });
    }
  };

  showDetail = async (selected) => {
    const { items } = this.state;
    let id = selected.company;
    let token = this.props.users.accessToken;
    let contactindex = findIndex(items, (item) => item.company === id);
    const contact = items[contactindex];
    const docs =
      contact.program_quality !== [] &&
      contact.program_quality.map(
        async (i) => await getDocCompany(id, token, i.certificate_id),
      );
    this.setState({
      qualite_program: selected.program_quality,
    });
    this.setState({ tabPhotos: [] });
    for (let index = 0; index < docs.length; index++) {
      const element = await docs[index];
      await this.setState((prevState) => ({
        tabPhotos: [...prevState.tabPhotos, element],
      }));
    }
    this.setState({
      showForm: true,
      enableEdit: false,
      selected: selected,
    });
  };

  addManualContact = () => {
    this.setState({
      searchCompany: true,
      showInviteContact: false,
      showAddContact: true,
      showForm: false,
      popupTitle: <FormattedMessage {...messages.NewContact} />,
    });
  };

  sendInvitation = () => {
    this.setState({
      searchCompany: true,
      showInviteContact: true,
      showAddContact: false,
      showForm: false,
      popupTitle: <FormattedMessage {...messages.InviteContact} />,
    });
  };

  addCompany = async () => {
    this.setState({
      searchCompany: true,
      showForm: false,
      showAddContact: false,
      showInviteContact: false,
    });
  };

  importCsvContact = () => {
    this.props.history.push('/admin/contact/import');
  };

  toggleFilterPopup = () => {
    this.setState({ listCompany: [] });
    this.setState({
      enableEdit: false,
      showDetail: false,
    });
    this.setState({
      searchCompany: false,
      showAddContact: false,
      popupTitle: '',
    });
  };

  handleNonAgrabiz = (e) => {
    this.setState({ selectNonAgrabiz: e.target.checked });
    this.checkNonAgrabiz();
  };

  checkNonAgrabiz = () => {
    // this.setState(prevState => ({
    //   selectNonAgrabiz: !prevState.selectNonAgrabiz,
    // }));

    let updatedList = this.state.initialItems;
    if (this.state.role_filter === '8') {
      updatedList = updatedList.filter((item) => {
        return item.is_registered === 1;
      });
    }
    if (this.state.selectNonAgrabiz === true) {
      if (this.state.search_alphabet) {
        updatedList = updatedList.filter((item) => {
          return (
            item.is_registered === 0 &&
            item.name.charAt(0) === this.state.search_alphabet
          );
        });
        if (this.state.role_filter !== '0') {
          updatedList = updatedList.filter((item) => {
            return item.group === this.state.role_filter;
          });
        }
      } else {
        updatedList = updatedList.filter((item) => {
          return this.state.role_filter === '0'
            ? item.is_registered === 0
            : item.is_registered === 0 && item.group === this.state.role_filter;
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else if (this.state.selectNonAgrabiz === false) {
      if (this.state.search_alphabet === null) {
        this.state.role_filter === '0' || this.state.role_filter === '8'
          ? this.setState({ items: this.state.initialItems })
          : (updatedList = updatedList.filter((item) => {
              return (
                item.is_registered === 1 &&
                item.group === this.state.role_filter
              );
            }));
      } else {
        updatedList = updatedList.filter((item) => {
          return this.state.role_filter === '0' ||
            this.state.role_filter === '8'
            ? item.name.charAt(0) === this.state.search_alphabet
            : item.name.charAt(0) === this.state.search_alphabet &&
                item.group === this.state.role_filter;
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    }
  };

  searchCompany = async (event) => {
    if (event.target.value.length >= 3) {
      let token = this.props.users.accessToken;
      let company_id = this.props.users.user.session.company_id;
      const value = await searchContacts(company_id, token, event.target.value);
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        const contacts = await extractData(value);
        this.setState({ listCompany: contacts });
      }
    }
  };

  getContactById = async (id) => {
    let token = this.props.users.accessToken;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contact = await extractData(value);
      const docs = contact.program_quality.map(
        async (i) => await getDocCompany(id, token, i.certificate_id),
      );
      this.setState({
        qualite_program: contact.program_quality,
      });
      this.setState({ tabPhotos: [] });
      for (let index = 0; index < docs.length; index++) {
        const element = await docs[index];
        await this.setState((prevState) => ({
          tabPhotos: [...prevState.tabPhotos, element],
        }));
      }
      this.setState({ selected: contact });
      this.setState({
        enableEdit: true,
        showDetail: true,
        popupTitle: <FormattedMessage {...messages.ContactProfile} />,
      });
    }
  };

  getContactByIdInvitation = async (id) => {
    let token = this.props.users.accessToken;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const contact = await extractData(value);
      const docs = contact.program_quality.map(
        async (i) => await getDocCompany(id, token, i.certificate_id),
      );
      this.setState({
        qualite_program: contact.program_quality,
      });
      this.setState({ tabPhotos: [] });
      for (let index = 0; index < docs.length; index++) {
        const element = await docs[index];

        await this.setState((prevState) => ({
          tabPhotos: [...prevState.tabPhotos, element],
        }));
      }
      this.setState({ selected: contact });
      this.setState({
        enableEdit: true,
        showDetail: true,
        showInviteContact: false,
      });
    } else {
      // const msg =
      //   this.props.language.locale === 'EN'
      //     ? `Contact not Found  `
      //     : `Kontakt nicht gefunden `;
      // this.props.error(msg);
      // this.props.clear();
    }
  };

  togglePopup = (e) => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  handleClose = () => {
    this.setState({
      showPopup: false,
    });
  };

  setResponsibleVet = async (item) => {
    let token = this.props.users.accessToken;
    let company_id = this.props.users.user.session.company_id;
    let value = await putResponsible(company_id, token, item.name, item._id);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      await extractData(value);
      value = await setFavorit(company_id, token, item._id, 1);
      this.setState({
        search_favorit: true,
      });
      await this.setFavorit(item);
      this.setState({ showPopup: false });
    } else {
      this.setState({ showPopup: false });
    }
  };

  renderListQualities = () => {
    return (
      <div className="quality-program-content">
        <ListQuality
          qualities={this.state.qualite_program}
          photos={this.state.tabPhotos}
        />
      </div>
    );
  };

  addContact = async () => {
    try {
      let token = this.props.users.accessToken;
      let company_id = this.props.users.user.session.company_id;
      let data = {
        group: this.state.selected.business_type,
        company: this.state.selected._id,
        number: this.state.selected.number,
        name: this.state.selected.name,
        street: this.state.selected.address.street,
        city: this.state.selected.address.city,
        zip_code: this.state.selected.address.zip_code,
        phone_number: this.state.selected.phone_number,
        email: this.state.selected.owner.email,
        is_favourite: 0,
        has_slaughter_informations:
          this.state.selected.business_type === '1' ? 1 : 0,
      };
      const value = await addContact(company_id, token, data);

      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        await extractData(value);
        this.props.success(<FormattedMessage {...messages.ContactRegister} />);
        this.props.clear();
        this.setState({
          showForm: false,
        });
        this.setState({
          selected: {},
        });
        this.getContacts();
        this.setState({ items: this.state.initialItems });
        this.toggleFilterPopup();
      } else {
        const msg =
          this.props.language.locale === 'EN'
            ? `Contact already exist   `
            : `Kontakt bereits existieren `;
        this.props.error(msg);
        this.toggleFilterPopup();
        this.props.clear();
      }
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  toggleBackground = (position) => {
    if (this.state.itemBackground === position) {
      this.setState({ itemBackground: null });
    } else {
      this.setState({ itemBackground: position });
    }
  };

  backgroundChange = (position) => {
    if (this.state.itemBackground === position) {
      return '#f0fae3';
    }
    return '';
  };

  findAndReplace = (e) => {
    if (this.props.language.locale === 'DE') {
      return e && e.replace('pieces', 'Stück ');
    } else return e;
  };

  renderOneContact = (item, index) => (
    <div>
      <div key={index} onClick={(evt) => this.showDetail(item)}>
        <ListGroupItem
          key={index}
          style={{ background: this.backgroundChange(index) }}
          onClick={() => this.toggleBackground(index)}
        >
          <div className="contact-container">
            <Row>
              <Col md={10} xs={10}>
                <a>
                  <p
                    className={
                      this.state.search_alphabet === item.name.charAt(0)
                        ? 'name parag'
                        : 'parag'
                    }
                  >
                    {this.findAndReplace(item && item.name)}
                  </p>
                </a>
              </Col>
              <Col md={1} xs={1}>
                <button
                  className="shared"
                  disabled={this.state.disableFav}
                  onClick={(evt) => {
                    this.setFavIcon(item).then((values) => {
                      item.is_favourite = values.is_favourite;
                      this.setFavIcon(item).then((values) => {
                        this.setFavorit(values).then(() => {
                          this.setState({
                            disableFav: false,
                          });
                        });
                      });
                    });
                  }}
                >
                  {item.is_favourite === 0 ? <MdStarBorder /> : <MdStar />}
                </button>
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={10}>
                <p className="role-form-controled">
                  {
                    this.state.roles.find((val) => item.group === val.value)
                      .label
                  }
                </p>
              </Col>
            </Row>
          </div>
        </ListGroupItem>
        <hr className="style-border" />
      </div>

      <Popup
        show={this.state.showPopup}
        onHide={this.togglePopup}
        handleClose={this.handleClose}
        saveChanges={this.setResponsibleVet}
        item={this.state.item}
        title={this.state.item.name}
        company_type={this.state.company_type}
        vetPopup={true}
      />
    </div>
  );

  renderListCompany = () =>
    this.state.listCompany.map((item, i) => (
      <div key={i}>
        <ListGroupItem key={i}>
          <Row>
            <Col md={10} xs={10}>
              <a onClick={(evt) => this.getContactById(item._id)}>
                <p className="name">{this.findAndReplace(item && item.name)}</p>
              </a>
            </Col>
          </Row>
        </ListGroupItem>
      </div>
    ));

  renderListAlphabets = () => {
    return alphabets.map((val, i) => (
      <li
        onClick={(evt) => this.filterByAlphabet(val)}
        className={
          this.state.search_alphabet === val ? 'show selectted' : 'show'
        }
        key={i}
      >
        {val}
      </li>
    ));
  };

  renderListOptions = () => {
    let res = '';
    const company_type = this.props.users.user.session.company_type;
    if (company_type === '1') {
      res = this.state.roles.filter((item) => {
        return item.value !== '5' && item.value !== '3';
      });
      return [
        {
          label: <FormattedMessage {...messages.UserType} />,
          options: [
            {
              value: '8',
              role: 'agrabiz',
              label: <FormattedMessage {...messages.AgrabizUsers} />,
            },
            {
              value: '7',
              role: 'non-agrabiz',
              label: <FormattedMessage {...messages.ExternalUsers} />,
            },
          ],
        },
        {
          label: <FormattedMessage {...messages.CompanyType} />,
          options: res,
        },
      ];
    } else if (company_type === '3') {
      res = this.state.roles.filter((item) => {
        return item.value !== '6' && item.value !== '3';
      });
      return [
        {
          label: <FormattedMessage {...messages.UserType} />,
          options: [
            {
              value: '8',
              role: 'agrabiz',
              label: <FormattedMessage {...messages.AgrabizUsers} />,
            },
            {
              value: '7',
              role: 'non-agrabiz',
              label: <FormattedMessage {...messages.ExternalUsers} />,
            },
          ],
        },
        {
          label: <FormattedMessage {...messages.CompanyType} />,
          options: res,
        },
      ];
    }
    return [
      {
        label: <FormattedMessage {...messages.UserType} />,
        options: [
          {
            value: '8',
            role: 'agrabiz',
            label: <FormattedMessage {...messages.AgrabizUsers} />,
          },
          {
            value: '7',
            role: 'non-agrabiz',
            label: <FormattedMessage {...messages.ExternalUsers} />,
          },
        ],
      },
      {
        label: <FormattedMessage {...messages.CompanyType} />,
        options: this.state.roles,
      },
    ];
  };

  setShowForm = async () => {
    this.setState({
      showForm: !this.state.showForm,
      showAddContact: false,
    });
  };

  handleModal = () => {
    this.setState({
      showAddContact: false,
      showInviteContact: false,
    });
  };

  handleSubmit = async (dataa) => {
    const {
      group,
      number,
      company,
      street,
      city,
      zipCode,
      federalState,
      country,
      phone,
      email,
      mobilephone,
      qualityProgarms,
    } = dataa;
    const { accessToken, user } = this.props.users;
    const { company_id } = user.session;
    let QqualityProgarmList = [];

    typeof qualityProgarms !== 'undefined' &&
      qualityProgarms.map((e) => {
        QqualityProgarmList.push({
          name: e.label,
          certificate_id: '',
        });
      });

    if (typeof qualityProgarms !== 'undefined') {
      QqualityProgarmList = QqualityProgarmList.filter(
        (e) => e.name !== 'No quality program',
      );
      QqualityProgarmList = QqualityProgarmList.filter(
        (e) => e.name !== 'Keine',
      );
    }

    const data = {
      group: group.value,
      company: '',
      number: number,
      name: company,
      street: street,
      city: city,
      zip_code: zipCode,
      federal_state: federalState.value,
      country: country,
      phone_number: phone,
      email: email,
      is_favourite: 1,
      mobile_number: mobilephone,
      has_slaughter_informations: group.value === '1' ? 1 : 0,
      program_quality: QqualityProgarmList,
    };
    const res = await addContact(company_id, accessToken, data);
    if (res.status === 200) {
      this.props.success(<FormattedMessage {...messages.ContactAdded} />);
      this.props.clear();
      this.handleModal();
      this.toggleFilterPopup();
      await this.getContacts();
    } else {
      this.handleModal();
      this.toggleFilterPopup();
      this.props.error(<FormattedMessage {...messages.ContactExist} />);
      this.props.clear();
    }
  };

  handleSelectedAction = (func) => {
    this[func]();
  };

  renderContactList = () => {
    const {
      searchCompany,
      showDetail,
      qualite_program,
      tabPhotos,
      enableEdit,
      selected,
      search_favorit,
      showForm,
      showAddContact,
      showInviteContact,
      menuItems,
    } = this.state;
    const companyType = this.props.users.user.session.company_type;
    return (
      <>
        <Row className="contact main-contact-box">
          <Modal
            show={searchCompany}
            onHide={this.toggleFilterPopup}
            id="contactSearch"
            style={{ opacity: 1 }}
          >
            <Modal.Header closeButton>
              <div className="Modal-add-truck-title">
                <h5 className="card-title new-transport">
                  {this.state.popupTitle}
                </h5>
              </div>
              <Modal.Title />
            </Modal.Header>
            <Modal.Body className="search-company">
              {showAddContact ? (
                <AddForm
                  getContacts={this.getContacts}
                  addContacts={this.handleSubmit}
                />
              ) : showInviteContact ? (
                <Invitation
                  handleModal={this.handleModal}
                  getContactById={this.getContactByIdInvitation}
                />
              ) : showDetail ? (
                <Form
                  qpList={qualite_program}
                  listQualities={this.renderListQualities()}
                  photos={tabPhotos}
                  addContact={this.addContact}
                  getContactsChild={this.getContactsChild}
                  enableEdit={enableEdit}
                  contact={selected}
                  isPopup={true}
                  getContacts={this.getContacts}
                />
              ) : (
                <div className="contact-popup-block-b">
                  <FormControl
                    type="text"
                    minLength={50}
                    maxLength={50}
                    name="search"
                    onChange={this.searchCompany}
                    className="search"
                    placeholder="Search"
                  />
                  <ListGroup className="list-stock list-contact-popup">
                    <ul className="">{this.renderListCompany()}</ul>
                  </ListGroup>
                  <div className="add-contact-text">
                    <div className="contactCheck">
                      <FormattedMessage {...messages.add_favorite_contact} />
                    </div>
                    <div
                      className="contactCheck1"
                      onClick={this.sendInvitation}
                    >
                      <FormattedMessage {...messages.send_invitation} />
                    </div>
                    <div className="contactCheck2">or</div>
                    <div
                      className="contactCheck1"
                      onClick={this.addManualContact}
                    >
                      <FormattedMessage {...messages.add_no_agrabiz_contact} />
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Col md={6} sm={12} style={{ height: '95%' }}>
            <Card className="cardBoxContact">
              {/* <div className="contact-list-title">
          <h5 className="cardTitle">
            {' '}
            <FormattedMessage {...messages.MyContact} />
          </h5>
        </div> */}
              <Card.Body className="panel-body-contact">
                <div className="contact-list-top-side">
                  <Row className="card-block">
                    <Col className="alphabetFilter">
                      <ul id="list-alphat">
                        <li className="start" onClick={this.filterByFavorit}>
                          {search_favorit ? (
                            <MdStar style={{ color: '#7bd812 ' }} />
                          ) : (
                            <MdStarBorder />
                          )}
                        </li>
                        {this.renderListAlphabets()}
                      </ul>
                    </Col>
                    <Row className="contact-list-stock-block-a">
                      <Col className="" xs={10} md={12}>
                        <FormGroup className="search-contact">
                          <InputGroup>
                            <FormControl
                              type="text"
                              minLength={50}
                              maxLength={50}
                              name="search"
                              onChange={this.filterByText}
                              className="search"
                              placeholder={
                                this.props.language.locale === 'DE'
                                  ? 'Suchen'
                                  : 'Search'
                              }
                            />
                            <InputGroup.Prepend>
                              <MdSearch />
                            </InputGroup.Prepend>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      {/* Filter block */}
                      <div className="block-container">
                        {/* <div className="left-block"></div> */}
                        <Col md={12}>
                          <Col md={3}>
                            <span className="text-grey">
                              <FormattedMessage {...messages.FilterAfter} />
                            </span>
                          </Col>
                          <Col md={9}>
                            <Select
                              isMulti={true}
                              options={this.renderListOptions()}
                              onChange={this.changeFilter}
                              placeholder={
                                <FormattedMessage
                                  {...messages.SelectAgrabizUsers}
                                />
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#F1FFDE',
                                  primary: '#7bcd10',
                                },
                              })}
                            />
                          </Col>
                        </Col>
                      </div>
                      {/* Filter result */}
                      <Col
                        className="contact-list-stock-block-b"
                        xs={10}
                        md={12}
                      >
                        <ListGroup id="contactStock" className="list-contact">
                          <div style={{ width: '100%', height: '100%' }}>
                            <AutoSizer>
                              {({ width, height }) => (
                                <List
                                  className="list-group"
                                  width={width}
                                  height={height}
                                  deferredMeasurementCache={this.cache}
                                  rowHeight={this.cache.rowHeight}
                                  rowCount={this.state.items.length}
                                  overscanRowCount={20}
                                  showScrollingPlaceholder={false}
                                  useDynamicRowHeight={false}
                                  rowRenderer={({
                                    key,
                                    index,
                                    style,
                                    parent,
                                  }) => {
                                    const person = this.state.items[index];
                                    return (
                                      <CellMeasurer
                                        key={key}
                                        cache={this.cache}
                                        columnIndex={0}
                                        rowIndex={index}
                                        parent={parent}
                                      >
                                        <div
                                          style={{
                                            ...style,
                                            height: 120,
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {this.renderOneContact(person, key)}
                                        </div>
                                      </CellMeasurer>
                                    );
                                  }}
                                />
                              )}
                            </AutoSizer>
                          </div>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Row>
                </div>
                <div className="contact-list-bottom-side">
                  <ToggleMenuButton
                    title={<FormattedMessage {...messages.Options} />}
                    lang={this.props.language.locale}
                    widthDeutsch="45%"
                  >
                    {menuItems
                      .filter(
                        (item) => item.allowedUsers.indexOf(companyType) > -1,
                      )
                      .map((item) => (
                        <Dropdown.Item
                          key={item.id}
                          onClick={() => this.handleSelectedAction(item.func)}
                        >
                          {item.path && (
                            <SvgIcon size="20" viewBox="0 0 600 600">
                              {item.path}
                            </SvgIcon>
                          )}
                          <div className="menu-item-text">{item.title}</div>
                        </Dropdown.Item>
                      ))}
                  </ToggleMenuButton>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {showForm && (
            <Col md={6} sm={12} className="contact-optional-tab">
              <Form
                qpList={qualite_program}
                listQualities={this.renderListQualities()}
                photos={tabPhotos}
                addContact={this.addContact}
                getContactsChild={this.getContactsChild}
                setShowForm={this.setShowForm}
                enableEdit={enableEdit}
                contact={selected}
                getContacts={this.getContacts}
              />
            </Col>
          )}
        </Row>
      </>
    );
  };

  render() {
    if (!this.state.isLoaded) {
      // not loaded
      return (
        <div>
          <div key={0} className="loader-wrapper">
            <div className="loader" key={0}></div>
          </div>
        </div>
      );
    }
    return this.renderContactList();
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return { users, language };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Contact));
