import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, FormGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment-es6';
import { FormattedMessage, injectIntl } from 'react-intl';
import Filters from './Filters';
import BuyingsTable from './BuyingsTable';
import Details from './details';
import { buyingsStockActions } from '../../../actions';
import messages from '../../../components/Admin/messages';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../static/css/datepicker.css';
import './buyingOverview.css';
import locale from '../../../utils/DataPickerLang';

const convertDate = (date) => {
  return date.format('DD.MM.YY');
};

class BuyingsOverview extends Component {
  constructor(props) {
    super(props);
    const startOfWeek = moment().startOf('week').add(1, 'days');
    const endOfWeek = moment().endOf('week');
    const convStart = convertDate(startOfWeek);
    const convEnd = convertDate(endOfWeek);
    this.state = {
      date: moment().toDate(),
      numWeek: moment().week(),
      startDate: startOfWeek,
      endDate: endOfWeek,
      convertedStartDate: convStart,
      convertedEndDate: convEnd,
      displayDate: `(${convStart}-${convEnd})`,
      filters: [],
    };
  }
  days = [
    // this.props.intl.formatMessage({ id: 'agrabiz.components.order.sunday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.monday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.tuesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.wednesday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.thursday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.friday' }),
    this.props.intl.formatMessage({ id: 'agrabiz.components.order.saturday' }),
  ];

  componentDidMount() {
    this.calendarRef = React.createRef();
    this.getBuyingsStock();
  }

  handleChangeDate = async (control) => {
    const { startDate, endDate } = this.state;
    const days = control === 'up' ? 7 : -7;
    const newStartWeek = startDate.add(days, 'days');
    const newEndWeek = endDate.add(days, 'days');
    const convStart = convertDate(newStartWeek);
    const convEnd = convertDate(newEndWeek);
    await this.setState({
      date: newStartWeek.toDate(),
      startDate: newStartWeek,
      endDate: newEndWeek,
      convertedStartDate: convStart,
      convertedEndDate: convEnd,
      displayDate: `(${convStart}-${convEnd})`,
      numWeek: newStartWeek.week(),
    });
    this.getBuyingsStock();
  };

  getBuyingsStock = () => {
    const { convertedStartDate, convertedEndDate, filters } = this.state;
    const accessToken = this.props.users.accessToken;
    this.props.getBuyingsStock(
      accessToken,
      filters,
      convertedStartDate,
      convertedEndDate,
    );
  };

  dateChange = async (date) => {
    const startOfWeek = moment(date).startOf('week').add(1, 'days');
    const endOfWeek = moment(date).endOf('week');
    const convStart = convertDate(startOfWeek);
    const convEnd = convertDate(endOfWeek);
    await this.setState({
      ...this.state,
      date: moment(date).toDate(),
      numWeek: moment(date).week(),
      startDate: startOfWeek,
      endDate: endOfWeek,
      convertedStartDate: convStart,
      convertedEndDate: convEnd,
      displayDate: `(${convStart}-${convEnd})`,
    });
    this.getBuyingsStock();
  };

  setFilters = async (val) => {
    await this.setState({ ...this.state, filters: val });
    await this.props.clearBuyingsDetails();
    this.getBuyingsStock();
  };

  showDetails = (index) => {
    const { startDate, filters } = this.state;
    const newStartDate = moment(startDate.toISOString()).add(index, 'days');
    const convDate = convertDate(newStartDate);
    const detailsDate = newStartDate.format('dddd, DD.MM.YYYY');
    const accessToken = this.props.users.accessToken;
    this.props.getBuyingsDetails(accessToken, convDate, detailsDate, filters);
  };

  totalPerDay = (day) => {
    const { buyingsStock } = this.props;
    const total = buyingsStock.buyingsStock
      ? buyingsStock.buyingsStock.reduce(
          (a, b) => a + b.days.find((d) => d.day === day).number,
          0,
        )
      : 0;
    return total;
  };

  render() {
    const { date, numWeek, filters } = this.state;
    const { buyingsStock } = this.props;
    const totalPieces = buyingsStock.buyingsStock
      ? buyingsStock.buyingsStock.reduce((a, b) => a + b.total, 0)
      : 0;
    return (
      <Row className="overviews">
        <Col className="overviews-col" md={6} sm={12}>
          <Card className="card-box left-box">
            <Card.Body>
              <Row className="panel-body-row ">
                <Col xs={12} md={12}>
                  <FormGroup
                    controlId="formInlineName"
                    className="user-input-wrp date-time"
                  >
                    <div className="date-picker-wrapper">
                      <div
                        className="date-picker-icon"
                        onClick={() => {
                          this.calendarRef.current.setOpen(true);
                        }}
                      ></div>
                      <div className="the-day">
                        <FormattedMessage {...messages.Week} /> {numWeek}
                      </div>
                      <DatePicker
                        selected={date}
                        value={this.state.displayDate}
                        onChange={this.dateChange}
                        dateFormat="dd.MM.yy"
                        ref={this.calendarRef}
                        showWeekNumbers
                        highlightDates={this.state.alldates}
                        locale={locale}
                      />
                      <button
                        className="button-up"
                        onClick={() => this.handleChangeDate('down')}
                      >
                        <i className="down" />
                      </button>
                      <button
                        className="button-down"
                        onClick={() => this.handleChangeDate('up')}
                      >
                        <i className="up" />
                      </button>
                    </div>
                  </FormGroup>
                </Col>

                <Col xs={12} md={12}>
                  <Filters filters={filters} setFilters={this.setFilters} />
                </Col>
                <Col xs={12} md={12}>
                  <BuyingsTable
                    days={this.days}
                    buyingsStock={buyingsStock}
                    endDate={this.state.endDate}
                    showDetails={this.showDetails}
                  />
                </Col>
              </Row>

              <Row className="card-total bottom-part">
                <Col md={12}>
                  <div className="buying-totals">
                    <div className="total-number">{totalPieces}</div>
                    {this.days.map((day, index) => (
                      <div className="total-number" key={index}>
                        {this.totalPerDay(index + 1)}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {buyingsStock.buyingsDetails && (
          <Col style={{ height: '100%' }} md={6} sm={12}>
            <Card className="card-box">
              <Card.Body className="panel-body-details">
                <Details days={this.days} buyingsStock={buyingsStock} />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBuyingsStock: (accessToken, programQualities, startDate, endDate) =>
      dispatch(
        buyingsStockActions.getBuyingsStock(
          accessToken,
          programQualities,
          startDate,
          endDate,
        ),
      ),
    getBuyingsDetails: (
      accessToken,
      startDate,
      detailsDate,
      programQualities,
    ) =>
      dispatch(
        buyingsStockActions.getBuyingsDetails(
          accessToken,
          startDate,
          detailsDate,
          programQualities,
        ),
      ),
    clearBuyingsDetails: () =>
      dispatch(buyingsStockActions.clearBuyingsDetails()),
  };
}

const mapStateToProps = (state) => {
  const { buyingsStock, users } = state.toJS();

  return {
    users,
    buyingsStock,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(BuyingsOverview));
