import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './normalButton.scss';

const propTypes = {
  nature: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  grey: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit', null]),
};

const defaultProps = {
  nature: 'green',
  active: false,
  disabled: false,
  type: 'button',
};

const NormalButton = React.forwardRef(
  (
    { tooltip, nature, size, active, className, block, type, grey, ...props },
    ref,
  ) => {
    const prefix = 'btn-normal';
    const tooltipiz = (
      <Tooltip bsClass="tooltipiz" id="tooltip">
        {tooltip}
      </Tooltip>
    );
    const classes = classNames(
      className,
      prefix,
      active && 'active',
      nature && `${prefix}-${nature}`,
      block && `${prefix}-block`,
      size && `${prefix}-${size}`,
      grey && `${prefix}-grey`,
    );

    if (ref) {
      props.ref = ref;
    }

    const Component = 'button';
    return tooltip ? (
      <OverlayTrigger placement="bottom" overlay={tooltipiz}>
        <Component {...props} className={classes} />
      </OverlayTrigger>
    ) : type ? (
      <Component {...props} className={classes} type={type} />
    ) : (
      <Component {...props} className={classes} />
    );
  },
);

NormalButton.displayName = 'NormalButton';
NormalButton.propTypes = propTypes;
NormalButton.defaultProps = defaultProps;

export default NormalButton;
