import React from 'react';
import { connect } from 'react-redux';
import { SketchField, Tools } from 'react-sketch';
import { MdDone, MdClose, MdSettingsBackupRestore } from 'react-icons/md';
import Redo from '../../../../static/images/admin/redo.png';
import whiteBackground from '../../../../static/images/admin/whiteBackground.png';
import { Col, Row, Image, Container } from 'react-bootstrap';
import { userActions } from '../../../../actions';
import { userService } from '../../../../services/user.service';
import FormPassword from './FormPassword';
import FormDisableReconfirmation from './FormDisableReconfirmation';
import { editCompany } from '../../../../actions/company.actions';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../components/Admin/messages';
import Card from '../../../../components/Card';
import { specificRoles } from '../../../../constants';
import ProfileDetailsOwner from './ProfileDetails';
import '../setting.css';

class TabSecurity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawings: [],
      canUndo: false,
      canRedo: false,
      stretched: true,
      stretchedX: false,
      stretchedY: false,
      originX: 'left',
      originY: 'top',
      imageSrc: '',
      stepSignature: 1,
    };
  }

  componentDidUpdate() {
    if (this.state.stepSignature === 2) {
      let sketch = this._sketch;
      let { stretched, stretchedX, stretchedY, originX, originY } = this.state;
      sketch.setBackgroundFromDataUrl(whiteBackground, {
        stretched: stretched,
        stretchedX: stretchedX,
        stretchedY: stretchedY,
        originX: originX,
        originY: originY,
      });
    }
  }

  componentDidMount() {
    const responseSignature = userService.getSignature(
      this.props.users.user._id,
      this.props.users.accessToken,
    );
    responseSignature
      .then((res) => {
        if (res !== 'null' && res !== '' && typeof res !== 'undefined') {
          const imageSrc = 'data:image/png;base64,' + res;
          this.setState({ imageSrc: imageSrc });
        } else {
          this.setState({
            imageSrc:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
          });
        }
      })
      .catch((e) => {});
  }

  _onSketchChange = (val) => {
    this.setState({ val });
  };

  saveSignature = async () => {
    const signature = this._sketch.toDataURL();
    const blobReturn = this.dataURItoBlob(signature);
    await this.props.addSignature(blobReturn);
  };

  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);

    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);

    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  _clear = () => {
    this.setState({ stepSignature: 2 });
  };

  goBack = async () => {
    await userService.deleteSignature(
      this.props.users.user._id,
      this.props.users.accessToken,
    );

    this.setState({
      stepSignature: 1,
      imageSrc:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
    });

    this._clear();
  };
  render() {
    let { role } = this.props.users.user;
    const companyType =
      ['1', '2'].indexOf(this.props.users.user.session.company_type) > -1;
    return (
      <Container fluid>
        <Row>
          <Col md={6} sm={12}>
            {specificRoles.ROLE_FOR_ROLE_COMPANY_OWNER.indexOf(role) > -1 && (
              <Card
                title={
                  <Col md={6} className="card-title-sec">
                    <FormattedMessage {...messages.CompanyOwner} />
                  </Col>
                }
                content={
                  <Row style={{ height: '100%' }}>
                    <Col style={{ width: '100%' }}>
                      <ProfileDetailsOwner />
                    </Col>
                  </Row>
                }
              />
            )}
            {specificRoles.ROLE_FOR_ROLE_COMPANY_AGR_TRD_VAL.indexOf(role) >
              -1 && (
              <div>
                <Card
                  title={
                    <Col md={6} className="card-title-sec">
                      <FormattedMessage {...messages.SignatureMessage} />
                    </Col>
                  }
                  content={
                    <div className="text-center-block-sketch">
                      <div
                        className="text-center-b1-sketch"
                        // style={{ position: 'relative' }}
                      >
                        {this.state.stepSignature === 2 && (
                          <SketchField
                            style={{
                              width: '100%',
                            }}
                            className="text-center-sketch "
                            ref={(c) => (this._sketch = c)}
                            width="50%"
                            height="auto"
                            tool={Tools.Pencil}
                            imageFormat="png"
                            lineColor="black"
                            onChange={this._onSketchChange}
                            lineWidth={3}
                          />
                        )}

                        {this.state.stepSignature === 1 && (
                          <div className="text-center-sketch-b1">
                            <Image
                              height="120px"
                              className=""
                              alt="img"
                              src={this.state.imageSrc}
                              id=""
                              onClick={this._clear}
                            />
                          </div>
                        )}
                      </div>
                      <div className="text-center-sketch-b2">
                        {this.state.stepSignature === 1 && (
                          <div className="right-icon">
                            <MdSettingsBackupRestore
                              onClick={this._clear}
                              src={Redo}
                            />
                            <MdClose onClick={this.goBack} />
                          </div>
                        )}
                        {this.state.stepSignature === 2 && (
                          <div className="right-icon">
                            <MdDone onClick={this.saveSignature} />
                            <MdClose onClick={this.goBack} />
                          </div>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            )}
          </Col>
          <Col md={6} sm={12}>
            <Card
              title={
                <Col md={6} className="card-title-sec">
                  <FormattedMessage {...messages.PasswordManagementMessage} />
                </Col>
              }
              content={
                <Row style={{ height: '100%' }}>
                  <Col style={{ width: '100%' }}>
                    <FormPassword />
                  </Col>
                </Row>
              }
            />
          </Col>
          {companyType &&
            specificRoles.ROLE_FOR_ROLE_TRD_OFFICE_WORKER_ADMIN.indexOf(
              role,
            ) === -1 && (
              <Col md={6} sm={12}>
                <Card
                  title={
                    <Col md={6} className="card-title-sec">
                      <FormattedMessage {...messages.DisableReconfirmation} />
                    </Col>
                  }
                  content={
                    <Row style={{ height: '100%' }}>
                      <Col style={{ width: '100%' }}>
                        <FormDisableReconfirmation />
                      </Col>
                    </Row>
                  }
                />
              </Col>
            )}
        </Row>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addSignature: (signature) => dispatch(userActions.addSignature(signature)),
    editCompany: (data) => dispatch(editCompany(data)),
  };
}
const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};
export default connect(mapStateToProps, mapDispatchToProps)(TabSecurity);
