export const renameBreed = (breed) => {
  switch (breed) {
    case 'Jungbulle':
      return 'A';
    case 'Bulle':
      return 'B';
    case 'Ochse':
      return 'C';
    case 'Kuh':
      return 'D';
    case 'Färse':
      return 'E';
    case 'Kalb (m)':
      return 'V (m)';
    case 'Kalb (w)':
      return 'V (w)';
    case 'Fresser (m)':
      return 'Z (m)';
    case 'Fresser (w)':
      return 'Z (w)';
    default:
      return breed;
  }
};
