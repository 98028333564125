import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdPersonOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
  Row,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { userActions } from '../../actions';
import messages from './messages';

const ForgetForm = ({ history, initPassword }) => {
  const [email, setEmail] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        await initPassword(email);
        history.push('/login');
      } catch (error) {}
    }
  };

  return (
    <Row className="login-content-form">
      <Form onSubmit={handleSubmit}>
        <Row>
          <FormGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <MdPersonOutline />
              </InputGroup.Prepend>
              <FormControl
                size="sm"
                name="email"
                type="email"
                placeholder="E-Mail"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormGroup>

          <div className="forget-password-link">
            <div className="forget-switch">
              <Link to="/login">
                <FormattedMessage {...messages.BackToLogin} />
              </Link>
            </div>
          </div>
        </Row>
        <Row>
          <FormGroup>
            <Button type="submit" variant="success" size="large" block>
              <FormattedMessage {...messages.SendMail} />
            </Button>
          </FormGroup>
          <FormGroup className="register-help">
            <p className="color-grid">
              <FormattedMessage {...messages.LoginFormTextAccount} />{' '}
              <Link to="/register/step/1" className="register-link">
                <FormattedMessage {...messages.LoginFormTextRegister} />
              </Link>
            </p>
          </FormGroup>
        </Row>
      </Form>
    </Row>
  );
};

ForgetForm.prototype = {
  history: PropTypes.object,
};
function mapDispatchToProps(dispatch) {
  return {
    initPassword: (email) => dispatch(userActions.initPassword(email)),
  };
}

export default connect(null, mapDispatchToProps)(ForgetForm);
