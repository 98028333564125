import React from 'react';
import { Col, Card, Row, Button, Image, Modal } from 'react-bootstrap';
import { MdNavigateBefore } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import messages from '../../../components/Admin/messages';
import animal from '../../../static/images/c-users.png';
import { editStock } from '../../../services/stock.service';
import extractData from '../../../services/data.processing';
import { connect } from 'react-redux';
import { alertActions } from '../../../actions';
import NormalButton from '../../Shared/components/buttons/NormalButton/NormalButton';
import RegularInput from '../../Shared/components/inputs/RegularInput';
import SvgIcon from '../../Shared/components/icons/SvgIcon/SvgIcon';
import { svgPaths } from '../../../constants';
import './viewStock.css';
import locale from '../../../utils/DataPickerLang';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      box: this.props.data.box,
      animal: this.props.data,
      togglePopupPassword: false,
      file: this.props.data.passport === '' ? null : this.props.data.passport,
      passport: this.props.passport,
      selectIdentifiedBreed: false,
      selectIdentifiedRace: false,
      incoming_weight: this.props.data.incoming_weight,
      selling_weight: this.props.data.selling_weight,
    };
  }

  submit = async (e) => {
    e.preventDefault();
    const token = this.props.users.accessToken;
    try {
      let file = null;
      if (typeof this.state.file === 'object') file = this.state.file;
      const value = await editStock(
        token,
        this.props.data._id,
        this.state.box,
        file,
        typeof this.state.incoming_weight !== 'undefined'
          ? this.state.incoming_weight
          : '',
        typeof this.state.selling_weight !== 'undefined'
          ? this.state.selling_weight
          : '',
      );
      // eslint-disable-next-line no-unused-vars
      const data = await extractData(value);
      this.props.success(<FormattedMessage {...messages.RegisterSuccess} />);
      this.props.clear();
      this.props.setShowFormAllStock();
    } catch (error) {
      this.props.error(error);
      this.props.clear();
    }
  };

  handleEnterInput = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  togglePopupPassword = () => {
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
  };

  _clear = () => {
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
    this.setState({ file: null });
  };

  handleincoming_weightBlur = () => {
    var num = parseFloat(this.state.incoming_weight);
    var twoDecimal = num.toFixed(2);
    this.setState({ incoming_weight: twoDecimal });
  };

  handleselling_weightBlur = () => {
    var num = parseFloat(this.state.selling_weight);
    var twoDecimal = num.toFixed(2);
    this.setState({ selling_weight: twoDecimal });
  };

  handleChangeEarTagMother = (event) => {
    let earTagFieldMother = this.state.earTagFieldMother;
    earTagFieldMother.earTag = event.target.value;
    this.setState({
      earTagFieldMother: earTagFieldMother,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        box: nextProps.data.box == null ? '' : nextProps.data.box,
      });
      this.setState({
        incoming_weight:
          nextProps.data.incoming_weight == null
            ? ''
            : nextProps.data.incoming_weight,
      });
      this.setState({
        selling_weight:
          nextProps.data.selling_weight == null
            ? ''
            : nextProps.data.selling_weight,
      });
      this.setState({
        file: nextProps.data.passport === '' ? null : nextProps.data.passport,
      });
      this.setState({
        passport: this.props.passport,
      });
    }
  }

  onFileChange = (e) => {
    let name = this.props.ear_tag;
    let ext = e.target.files[0].name.split('.').pop();
    // eslint-disable-next-line no-unused-vars
    var newFile = new File([e.target.files[0]], name + '.' + ext);
    this.setState({ file: e.target.files[0] });
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        passport: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  formatEarTag = (tag) => {
    tag = tag.replace(/\s/g, '');
    var output =
      tag.substr(0, 2) +
      ' ' +
      tag.substr(2, 2) +
      ' ' +
      tag.substr(4, 3) +
      ' ' +
      tag.substr(7, tag.length - 7);
    return output;
  };

  render() {
    let $imagePreview = null;
    if (this.state.passport) {
      // eslint-disable-next-line jsx-a11y/alt-text
      $imagePreview =
      typeof this.state.passport !== 'undefined' &&
      this.state.passport !== '' &&
      this.state.passport !== null &&
      this.state.passport.includes('application/pdf') ? (
        <div style={{ height: '660px' }}>
          <embed
            src={this.state.passport}
            frameBorder="0"
            width="100%"
            height="100%"
            type="application/pdf"
          />
        </div>
      ) : (
        <img src={this.state.passport} />
      );
    } else {
      $imagePreview = <div className="previewText">Please select an Image</div>;
    }
    return (
      <Card className="card-box view-stock-wrapper">
        <Modal
          show={this.state.togglePopupPassword}
          onHide={this.togglePopupPassword}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <Modal.Title />
          </Modal.Header>
          <Modal.Body
            className={
              this.state.passport.includes('application/pdf')
                ? 'modal-pdfstock'
                : ''
            }
          >
            {' '}
            {$imagePreview}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._clear}>Delete</Button>
          </Modal.Footer>
        </Modal>
        <div className="navigate-before-stock">
          <div md={3}>
            <MdNavigateBefore
              className="before"
              onClick={this.props.setShowForm}
            />
          </div>
        </div>
        <Row />
        <div className="view-stock-panel-body">
          <Card.Body className="stock-panel">
            <div className="top-view-stock-detail">
              <Row style={{ height: '25%' }}>
                <div className="form-flex-stock">
                  <div className="form-flex-stock-h1">
                    <Col md={12} className="text-center">
                      <Image src={animal} className="stock-show-image" />
                    </Col>
                  </div>
                  <div className="form-flex-stock-h2">
                    <div className="">
                      <div className="passpord-photo form-flex-stock-h2-block">
                        {this.state.file == null && (
                          <span className=" passpord-photo-avaible" />
                        )}
                        {this.state.file !== null && (
                          <span
                            onClick={this.togglePopupPassword}
                            className="passpord-photo-view"
                          />
                        )}
                        {this.state.file === null && !this.props.save && (
                          <input
                            type="file"
                            name=""
                            className="upload-file-button"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={this.onFileChange}
                          />
                        )}
                        {this.state.file == null && !this.props.save && (
                          <div className=" orange form-flex-stock-h2-texte ">
                            <FormattedMessage {...messages.AddPassport} />
                          </div>
                        )}
                        {this.state.file != null ||
                          (this.props.save && this.props.data.passport !== '' && (
                            <div className=" greenStock form-flex-stock-h2-texte ">
                              <FormattedMessage {...messages.ViewPassport} />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row style={{ height: '15%' }}>
                <div className="margin-text-stock">
                  <Col md={12} className="text-center space-top-bottom">
                    <h2 className="num-passpord">
                      {this.props.data.ear_tag_output_format}
                    </h2>
                  </Col>
                </div>
              </Row>
              <Row style={{ height: '60%' }}>
                <form
                  onSubmit={this.submit}
                  id="stock-item-form"
                  className="stock-item-view"
                  ref={(form) => (this.form = form)}
                  style={{ height: '100%' }}
                >
                  <Row style={{ height: '20%' }}>
                    <Col md={6} className="text-center space-top-bottom">
                      <div className="eartag-selection">
                        <div className="bottom-inputs">
                          {/* <RegularInput
                            select
                            grey
                            label={<FormattedMessage {...messages.NewRace} />}
                            defaultValue={races.find(
                              race => race.value === this.props.data.race,
                            )}
                            options={races}
                            required
                            className={'selected'}
                            isDisabled={true}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,

                              colors: {
                                ...theme.colors,
                                primary25: '#F1FFDE',
                                primary: '#7bcd10',
                              },
                            })}
                          /> */}
                          <RegularInput
                            label={<FormattedMessage {...messages.NewRace} />}
                            value={this.props.data.race}
                            disabled={true}
                            type="text"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={6} className="text-center space-top-bottom">
                      <div className="eartag-selection">
                        <div className="bottom-inputs">
                          {/* <RegularInput
                            select
                            grey
                            isDisabled={true}
                            label={<FormattedMessage {...messages.NewBreed} />}
                            className={'selected'}
                            defaultValue={this.props.data.breed}
                            options={breeds}
                            required
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,

                              colors: {
                                ...theme.colors,
                                primary25: '#F1FFDE',
                                primary: '#7bcd10',
                              },
                            })}
                          /> */}
                          <RegularInput
                            label={<FormattedMessage {...messages.NewBreed} />}
                            value={this.props.data.breed}
                            disabled={true}
                            type="text"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ height: '20%' }}>
                    <Col md={6} className="text-center space-top-bottom">
                      <div className="eartag-selection">
                        <div className="bottom-inputs">
                          <RegularInput
                            label={
                              <FormattedMessage {...messages.NewEarTagMother} />
                            }
                            name="mother_ear_tag"
                            value={this.props.data.mother_ear_tag_output_format}
                            disabled={true}
                            type="text"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={6} className="text-center space-top-bottom">
                      <div className="eartag-selection">
                        <div className="last-item-calender buy-sell-calendar">
                          <RegularInput
                            label={
                              <FormattedMessage {...messages.NewBirthDate} />
                            }
                            picker
                            type="text"
                            name="birthDate"
                            value={this.props.data.birth_date}
                            disabled={true}
                          />
                          <div className="date-field picker">
                            <SvgIcon
                              color="#9f9f9f"
                              size="30"
                              viewBox="0 0 600 600"
                            >
                              {svgPaths['calendar']}
                            </SvgIcon>

                            <DatePicker
                              dateFormat="dd.MM.yyyy"
                              disabled={true}
                              locale={locale}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ height: '20%' }}>
                    <Col
                      md={6}
                      className="text-center space-top-bottom previous-owner"
                    >
                      <div className="eartag-selection">
                        <RegularInput
                          label={
                            <FormattedMessage {...messages.NewPreviousOwner} />
                          }
                          type="text"
                          name="previous_owner"
                          required
                          defaultValue="N/A"
                          disabled={
                            this.props.type !== 'incoming' ? true : false
                          }
                        />
                      </div>
                    </Col>
                    <Col md={6} className="text-center space-top-bottom">
                      <div className="eartag-selection">
                        <div className="last-item-calender buy-sell-calendar">
                          <RegularInput
                            label={
                              <FormattedMessage {...messages.NewIncomingDate} />
                            }
                            picker
                            type="text"
                            name="birthDate"
                            value={this.props.data.incoming_stock_date}
                            disabled={true}
                          />
                          <div className="date-field picker">
                            <SvgIcon
                              color="#9f9f9f"
                              size="30"
                              viewBox="0 0 600 600"
                            >
                              {svgPaths['calendar']}
                            </SvgIcon>

                            <DatePicker
                              dateFormat="dd.MM.yyyy"
                              disabled={true}
                              locale={locale}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* new  */}
                  <Row style={{ height: '20%' }}>
                    <Col
                      md={6}
                      className="text-center space-top-bottom previous-owner"
                    >
                      <div className="eartag-selection">
                        <RegularInput
                          label={
                            <FormattedMessage {...messages.Einstallgewicht} />
                          }
                          type="number"
                          name="incoming_weight"
                          // required
                          defaultValue=""
                          onBlur={this.handleincoming_weightBlur}
                          onChange={this.handleEnterInput}
                          value={this.state.incoming_weight}
                        />
                      </div>
                    </Col>

                    <Col
                      md={6}
                      className="text-center space-top-bottom previous-owner"
                    >
                      <div className="eartag-selection">
                        <RegularInput
                          label={
                            <FormattedMessage {...messages.Verkaufsgewicht} />
                          }
                          type="number"
                          name="selling_weight"
                          // required
                          defaultValue=""
                          onBlur={this.handleselling_weightBlur}
                          onChange={this.handleEnterInput}
                          value={this.state.selling_weight}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ height: '20%' }}>
                    <Col
                      md={12}
                      className="text-center space-top-bottom box-number"
                    >
                      <div className="eartag-selection">
                        <RegularInput
                          label={<FormattedMessage {...messages.BoxNumber} />}
                          name="box"
                          // maxLength={4}
                          onChange={this.handleEnterInput}
                          value={this.state.box}
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
              </Row>
            </div>
            <div className="bottom-view-stock-detail">
              <NormalButton type="submit" form="stock-item-form">
                <FormattedMessage {...messages.EditMessage} />
              </NormalButton>
            </div>
          </Card.Body>
        </div>
      </Card>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return { users };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
