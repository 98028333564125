import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { invoiceActions } from '../../../../actions';
import MembershipTypeSection from './MembershipTypeSection/MembershipTypeSection';
import InformationSection from './InformationSection/InformationSection';
import MembershipSpecifications from './MembershipSpecifications/MembershipSpecifications';
import ConfirmCancel from './ConfirmCancel/ConfirmCancel';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import { svgPaths } from '../../../../constants';
import {
  membershipInformations,
  membershipArgTypes,
  membershipAgrSpecifications,
  membershipTrdSpecifications,
  membershipTrdTypes,
  membershipPlans,
} from './membership.data';
import Notice from './Notice/Notice';
import ChangePlan from './ChangPlan';
import PaiementSuccess from '../../Invoicing/Payment/PaiementSuccess';
import { cancelPayement } from '../../../../services/invoice.service';
import './membership.scss';

const MemberShip = ({
  users,
  invoices,
  getCustomer,
  showPaySucces,
  subscription,
}) => {
  const [showMembershipDetails, setShowMembershipDetails] = useState(false);
  const [showSuccesPay, setShowSuccessPay] = useState(false);
  const [showPayement, setShowPayment] = useState(true);
  const [confirmMembershipCancel, setConfirmMembershipCancel] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    membershipPlans.BASIC,
  );

  useEffect(() => {
    if (subscription) {
      setSubscriptionPlan(subscription);
    }
    setShowSuccessPay(showPaySucces);
  }, [showPaySucces, subscription]);

  useEffect(() => {
    setShowSuccessPay(showPaySucces);
    async function fetchData() {
      await getCustomer();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { customer } = invoices;

  const companyType = users.user.session.company_type;
  const subscriptionType = customer
    ? customer.currentSubscription
    : users.user.subscription_type;
  const { accessToken } = users;
  const membershipWithType =
    companyType === '1' ? membershipArgTypes : membershipTrdTypes;
  const membershipWithSpecification =
    companyType === '1'
      ? membershipAgrSpecifications
      : membershipTrdSpecifications;

  const renderPayement = (newSubscriptionType) => {
    setShowPayment(!showPayement);
    setSubscriptionPlan(newSubscriptionType);
  };

  const hideMembershipConfirm = () => {
    setConfirmMembershipCancel(false);
  };

  const showMembershipConfirm = (currentSubscriptionType) => {
    setConfirmMembershipCancel(true);
    setSubscriptionPlan(currentSubscriptionType);
  };

  const cancelSubscriptionPlan = async () => {
    await cancelPayement('free', accessToken);
    setConfirmMembershipCancel(false);
    window.location.reload();
  };

  const showSuccess = () => {
    setShowSuccessPay(true);
  };

  return (
    <div>
      {!showSuccesPay ? (
        <div>
          {showPayement ? (
            <div id="membership" className="m-container">
              {membershipInformations.map((membership) => (
                <div className="col col-md-6" key={membership.id}>
                  <InformationSection membership={membership} />
                </div>
              ))}
              <div className="col col-md-12 text-separation">
                <div className="top-text">
                  Das passende Angebot für Ihren Bedarf:
                </div>
                <div className="bottom-text">
                  Wählen Sie hier Ihr Abonnement aus.
                </div>
              </div>
              <div className="membership-types-specifications col col-md-12">
                <div className=" membership-types col col-md-12">
                  <div className="col col-md-3">
                    {showMembershipDetails ? (
                      <label
                        className="svg-icon-label"
                        onClick={() => setShowMembershipDetails(false)}
                      >
                        <SvgIcon
                          size="20"
                          color="#808080"
                          viewBox="0 0 540 580"
                        >
                          {svgPaths['arrowDown']}
                        </SvgIcon>
                      </label>
                    ) : (
                      <label
                        className="svg-icon-label"
                        onClick={() => setShowMembershipDetails(true)}
                      >
                        <SvgIcon
                          size="20"
                          color="#808080"
                          viewBox="0 0 540 580"
                        >
                          {svgPaths['arrowUp']}
                        </SvgIcon>
                      </label>
                    )}
                  </div>
                  <div
                    className={clsx(
                      'col',
                      companyType === '1' ? 'col-md-9' : 'col-md-6',
                    )}
                  >
                    {membershipWithType.map((membership) => (
                      <div
                        key={membership.id}
                        className={clsx(
                          'col',
                          companyType === '1' ? 'col-md-4' : 'col-md-6',
                        )}
                        style={{ marginLeft:companyType === '1' && '30px', marginRight: companyType === '1' &&'30px'}}
                      >
                        {customer && (
                          <MembershipTypeSection
                            isNewRegistered={customer.isNewRegistered}
                            subscriptionType={subscriptionType}
                            nextSubscriptionType={customer.nextSubscription}
                            expirationDate={customer.expirationDate}
                            renderPayement={renderPayement}
                            showMembershipConfirm={showMembershipConfirm}
                            companyType={companyType}
                            membershipType={membership}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  {companyType === '2' && (
                    <div className="col col-md-3">
                      <Notice sectionPosition="top" />
                    </div>
                  )}
                </div>
                {companyType === '1' && (
                  <div style={{marginLeft:'-250px'}} className="col col-md-12 tax">* exkl. MwSt.</div>
                )}
                {showMembershipDetails && (
                  <>
                    <div
                    
                      className={clsx(
                        'membership-specifications col',
                        companyType === '1' ? 'col-md-12' : 'col-md-9',
                      )}
                    >
                      {membershipWithSpecification.map((spec) => (
                        <div className="m-item col-md-12" key={spec.id}>
                          <MembershipSpecifications
                            companyType={companyType}
                            specifications={spec.content}
                          />
                        </div>
                      ))}
                    </div>
                    {companyType === '2' && (
                      <div className="col col-md-3">
                        <Notice sectionPosition="bottom" />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              {customer && (
                <ChangePlan
                  customer={customer}
                  type={subscriptionPlan}
                  ShowSucces={showSuccess}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <PaiementSuccess subscriptionPlan={subscriptionPlan} />
      )}
      <ConfirmCancel
        subscriptionPlan={subscriptionPlan}
        confirmCancel={confirmMembershipCancel}
        hideMembershipConfirm={hideMembershipConfirm}
        cancelSubscriptionPlan={cancelSubscriptionPlan}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { invoices, users } = state.toJS();
  return { users, invoices };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomer: () => dispatch(invoiceActions.fetchCustomer()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberShip);
