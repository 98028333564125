import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  FormGroup,
  InputGroup,
  ButtonGroup,
} from 'react-bootstrap';
import { MdStarBorder, MdStar } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import { filter } from 'lodash';
import messages from '../../messages';
import { getContacts } from '../../../../services/company.service';
import { OrderService } from '../../../../services/order.service';
import { getCompany } from '../../../../services/company.service';
import extractData from '../../../../services/data.processing';
import { addLeadingZero } from '../../../../helpers/order';
import NormalButton from '../../../Shared/components/buttons/NormalButton/NormalButton';
import SvgIcon from '../../../Shared/components/icons/SvgIcon/SvgIcon';
import RegularInput from '../../../Shared/components/inputs/RegularInput';
import ViewStock from '../../ViewStock';
import { isEqual } from 'lodash';
import clsx from 'clsx';
import { svgPaths } from '../../../../constants';
import { alertActions, adminActions } from '../../../../actions';
import { withRouter } from 'react-router-dom';
import '../../../../static/css/datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

import './resell.css';
import locale from '../../../../utils/DataPickerLang';

class ReSellRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryUntil: '',
      deliveryDate: this.props.orderDetail.delivery_date,
      piece: 0,
      amount: this.props.orderDetail.amount,
      race: [],
      programQuality: [],
      orderDetail: this.props.orderDetail,
      companyId: this.props.companyId,
      contacts: [],
      agriculturists: [],
      traders: [],
      abattoirs: [],
      showTrd: false,
      showAgr: false,
      showAbt: false,
      isBuyer: this.props.orderDetail.is_buyer,
      receivers: [],
      receivedOrder: this.props.orders,
      getEmpty: this.props.getEmpty,
      showStock: true,
      earTags: [],
      src: {},
      startDate: new Date(this.props.orderDetail.delivery_date),
      endDate: new Date(),
      freight_included: 1,
      businessType: null,
      onDatePickerFocus: false,
      onDatePickerBlur: true,
      search_favorit: false,
      role_filter: [],
      search_alphabet: null,
      inputValue: '',
      checkItem: {
        checkId: 0,
        checkType: '',
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.getFavouriteContacts();
    let nameSplit = this.props.orderDetail.delivery_date.split('.');

    // new date format 01.01.20
    let day;
    let month;
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = month + '.' + day + '.' + nameSplit[2];

    let currentDeliveryDate = new Date(newDate);
    // currentDeliveryDate.setDate(currentDeliveryDate.getDate() + 1);
    this.handleChangeDelivery(currentDeliveryDate);
  }

  filterByFavorit = async () => {
    const { programQuality } = this.state;
    this.setState((prevState) => ({
      search_favorit: !prevState.search_favorit,
    }));
    let updatedList = this.state.contacts;
    if (!this.state.search_favorit) {
      if (this.state.search_alphabet) {
        updatedList = updatedList.filter((item) => {
          return (
            item.is_favourite === 1 &&
            item.name.charAt(0) === this.state.search_alphabet
          );
        });
        if (
          this.props.orderType === 'buyings' &&
          this.state.role_filter.length > 0
        ) {
          updatedList = updatedList.filter((item) => {
            let result = item.program_quality.map((a) => a.name);
            return isEqual(result, programQuality);
          });
        }
      } else {
        updatedList = updatedList.filter((item) => {
          let result = item.program_quality.map((a) => a.name);
          if (
            (this.props.orderType === 'buyings',
            this.state.role_filter.length === 0)
          ) {
            return this.state.role_filter.length === 0
              ? item.is_favourite === 1
              : item.is_favourite === 1 && isEqual(result, programQuality);
          } else {
            return item.is_favourite === 1;
          }
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    } else if (this.state.search_favorit) {
      if (this.state.search_alphabet === null) {
        this.props.orderType === 'buyings' &&
        this.state.role_filter.length === 0
          ? this.setState({ items: this.state.contacts })
          : (updatedList = updatedList.filter((item) => {
              let result = item.program_quality.map((a) => a.name);
              return isEqual(result, programQuality);
            }));
      } else {
        updatedList = updatedList.filter((item) => {
          let result = item.program_quality.map((a) => a.name);
          return this.props.orderType === 'buyings' &&
            this.state.role_filter.length === 0
            ? item.name.charAt(0) === this.state.search_alphabet
            : item.name.charAt(0) === this.state.search_alphabet &&
                isEqual(result, programQuality);
        });
      }
      if (this.state.inputValue) {
        updatedList = updatedList.filter((item) => {
          return item.name.toLowerCase().search(this.state.inputValue) !== -1;
        });
      }
      this.setState({ items: updatedList });
    }
    await this.extractcontactName(updatedList);
  };

  componentDidMount() {
    let nameSplit = this.props.orderDetail.delivery_date.split('.');

    // new date format 01.01.20
    let day;
    let month;
    if (nameSplit[0].length === 1) day = '0' + nameSplit[0];
    else {
      day = nameSplit[0];
    }

    if (nameSplit[1].length === 1) month = '0' + nameSplit[1];
    else {
      month = nameSplit[1];
    }

    let newDate = month + '.' + day + '.' + nameSplit[2];

    let currentDeliveryDate = new Date(newDate);
    // currentDeliveryDate.setDate(currentDeliveryDate.getDate() + 1);
    this.handleChangeDelivery(currentDeliveryDate);
    this.calendarRef = React.createRef();
    this.calendarRefUntil = React.createRef();
    this.getCompany();
  }
  handleCancel = async () => {
    await this.props.handleClosePanel();
    await this.props.getOrders();
  };
  resaleStock = async () => {
    if (typeof this.props.executeService === 'undefined') {
      let token = this.props.users.accessToken;
      const resaleData = {
        delivery_date: this.state.deliveryDate,
        freight_included: this.state.freight_included,
        orders: this.props.orders,
        receivers: this.state.receivers,
      };
      const resold = await OrderService.Resale(token, resaleData);
      if (resold.status === 200) {
        this.props.getEmpty();
        this.props.success(<FormattedMessage {...messages.ResellSuccess} />);
        this.props.clear();
        await this.props.handleClosePanel();
        await this.props.getOrders();
        this.props.handleTitleChange('My Sellings');
        this.props.history.push('/admin/sell-stock');
        this.setState({ checked: false });
      }
    } else {
      await this.props.handleCloseTwo();
    }
  };

  getCompany = async () => {
    let token = this.props.users.accessToken;
    let id = this.props.users.user.session.company_id;
    const value = await getCompany(id, token);
    if (
      value !== 'null' &&
      typeof value !== 'undefined' &&
      typeof value.status !== 'undefined' &&
      value.status === 200
    ) {
      const user = await extractData(value);
      this.setState({
        programQuality:
          typeof user.program_quality[0] !== 'undefined'
            ? user.program_quality[0].name
            : [],
      });
      this.setState({ businessType: user.business_type });
    }
  };

  renderDate = (timeStamp, format) => {
    const toDay = new Date();
    const date = new Date(timeStamp);
    const tomorrow = new Date(date);
    tomorrow.setDate(date.getDate());
    return date.getDate() === toDay.getDate()
      ? format === 'input'
        ? `${addLeadingZero(tomorrow.getDate())}.${addLeadingZero(
            date.getMonth() + 1,
          )}.${date.getFullYear()}`
        : tomorrow
      : format === 'input'
      ? `${addLeadingZero(date.getDate())}.${addLeadingZero(
          date.getMonth() + 1,
        )}.${date.getFullYear()}`
      : date;
  };

  handleChangeDelivery = (date) => {
    if (date) {
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = String(date.getFullYear()).substr(2, 3);

      let newDate = dd + '.' + mm + '.' + yyyy;

      this.setState({
        startDate: date,
        dateChange: true,
        deliveryDate: newDate,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'deliveryDate':
        this.setState({ deliveryDate: value });
        break;
      default:
        break;
    }
  };

  extractcontactName = () => {
    const agr = filter(this.state.contacts, (o) => {
      return o.group === '1';
    });
    const trd = filter(this.state.contacts, (o) => {
      return o.group === '2';
    });
    const abt = filter(this.state.contacts, (o) => {
      return o.group === '3';
    });
    this.setState({ agriculturists: agr });
    this.setState({ traders: trd });
    this.setState({ abattoirs: abt });
  };

  getFavouriteContacts = async () => {
    let accessToken = this.props.users.accessToken;
    const value = await getContacts(this.state.companyId, accessToken);
    const contacts = await extractData(value);
    await this.setState({ contacts });
    await this.extractcontactName();
  };

  handleOpenClose = (e, type) => {
    e.preventDefault();
    const { showTrd, showAgr, showAbt } = this.state;
    if (type === 'trd') {
      this.setState({ showTrd: !showTrd });
    } else if (type === 'agr') {
      this.setState({ showAgr: !showAgr });
    } else {
      this.setState({ showAbt: !showAbt });
    }
  };

  handleRecipient = (e, name, index, companyType, companyId) => {
    const { receivers } = this.state;
    this.setState({
      checkItem: {
        checkId: companyId,
        checkType: companyType,
      },
    });
    const filtered = receivers.filter((value) => value !== name);
    const { checked } = e.target;

    if (this.props.externalRequest) {
      this.setState({ receivers: [...[], name] });
    } else {
      checked
        ? this.setState({ receivers: [...this.state.receivers, name] })
        : this.setState({ receivers: filtered });
    }
  };

  renderFav = (item, index, companyType) => {
    const { name, _id } = item;
    let companyId = _id;
    const { checkId, checkType } = this.state.checkItem;
    const { externalRequest } = this.props;
    return (
      <div key={index} className="order-group-list-recipient buy-sell">
        {externalRequest ? (
          <input
            checked={companyId === checkId && checkType === companyType}
            type="checkbox"
            id={_id}
            onChange={(e) =>
              this.handleRecipient(e, _id, index, companyType, companyId)
            }
          />
        ) : (
          <input
            type="checkbox"
            id={_id}
            onChange={(e) =>
              this.handleRecipient(e, _id, index, companyType, companyId)
            }
          />
        )}
        <label for={_id} className="favName">
          {name}
        </label>
      </div>
    );
  };

  showSellForm = (earTagsList, showForm) => {
    this.setState({
      earTags: [...earTagsList],
      race: earTagsList.map((item) => item.race),
    });
    this.setState({ showStock: showForm });
  };
  handleFocus = (type) => {
    this.setState({ [`on${type}Blur`]: false, [`on${type}Focus`]: true });
  };

  handleBlur = (type) => {
    this.setState({ [`on${type}Blur`]: true, [`on${type}Focus`]: false });
  };

  handleDeliveryBy = (value) => {
    this.setState({
      freight_included: value,
    });
  };

  renderFormRequest = (orderType) => {
    const {
      deliveryDate,
      piece,
      traders,
      agriculturists,
      abattoirs,
      showTrd,
      showAgr,
      showAbt,
      receivers,
      freight_included,
      onDatePickerFocus,
      onDatePickerBlur,
      search_favorit,
    } = this.state;
    const { externalRequest } = this.props;
    const registeredAgr = agriculturists.filter((e) => e.is_registered === 1);
    const notRegisteredAgr = agriculturists.filter(
      (e) => e.is_registered === 0,
    );
    const showAgrContatcs = !externalRequest
      ? registeredAgr.length > 0
      : notRegisteredAgr.length > 0;
    const registeredTrd = traders.filter((e) => e.is_registered === 1);
    const notRegisteredTrd = traders.filter((e) => e.is_registered === 0);
    const showtrdContatcs = !externalRequest
      ? registeredTrd.length > 0
      : notRegisteredTrd.length > 0;

    const registeredAbt = abattoirs.filter((e) => e.is_registered === 1);
    const notRegisteredAbt = abattoirs.filter((e) => e.is_registered === 0);
    const showAbtContatcs = !externalRequest
      ? registeredAbt.length > 0
      : notRegisteredAbt.length > 0;
    return (
      <div className="order-by-sell resell" id="resale">
        <div className="resale-top-part">
          <h5 className="card-title">
            <FormattedMessage {...messages.Resale} />
          </h5>
          <div className="form-group" style={{ height: '95%' }}>
            <form onSubmit={this.handleSubmit} style={{ height: '100%' }}>
              <div className="form-wrapper">
                <div className="order-details-transported-by">
                  <div className="transported-by-text">
                    <FormattedMessage {...messages.TransportedBy} />
                  </div>
                  <ButtonGroup
                    id="delivery-button-group"
                    aria-label="Basic example"
                  >
                    <Button
                      className={`btn-white ${
                        freight_included === 0 ? 'btn-active btn-bold' : ''
                      }`}
                      onClick={(e) => this.handleDeliveryBy(0)}
                      variant="secondary"
                    >
                      <FormattedMessage {...messages.Buyer} />
                    </Button>
                    <Button
                      className={`btn-white ${
                        freight_included === 1 ? 'btn-active btn-bold' : ''
                      }`}
                      variant="secondary"
                      onClick={(e) => this.handleDeliveryBy(1)}
                    >
                      <FormattedMessage {...messages.Seller} />
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="order-group-list order-group-calendar regular-picker">
                  <div className="last-item-calender buy-sell-calendar">
                    <RegularInput
                      type="text"
                      value={this.renderDate(this.state.startDate, 'input')}
                      name="deliveryDate"
                      onChange={(e) => this.handleChange(e)}
                      onClick={() => {
                        this.calendarRef.current.setOpen(true);
                      }}
                      picker
                      label={
                        <FormattedMessage {...messages.PreferedDeliveryDate} />
                      }
                      onFocus={() => this.handleFocus('DatePicker')}
                      onBlur={() => this.handleBlur('DatePicker')}
                    />
                    <div className="date-field picker">
                      <SvgIcon
                        color={clsx(
                          onDatePickerFocus && '#7bd811',
                          onDatePickerBlur && '#9f9f9f',
                        )}
                        onClick={() => {
                          this.calendarRef.current.setOpen(true);
                        }}
                        size="30"
                        viewBox="0 0 600 600"
                      >
                        {svgPaths['calendar']}
                      </SvgIcon>

                      <DatePicker
                        selected={this.renderDate(
                          this.state.startDate,
                          'datepicker',
                        )}
                        onChange={this.handleChangeDelivery}
                        dateFormat="dd.MM.yyyy"
                        ref={this.calendarRef}
                        locale={locale}
                      />
                    </div>
                  </div>
                </div>

                <div className="recipient-bottom-container">
                  <div className="recipient-list">
                    <div className="first-header-part">
                      <div className="recipient-title">
                        <FormattedMessage {...messages.Recipient} />
                      </div>
                      <div className="recipient-favorite">
                        {search_favorit ? (
                          <MdStar onClick={this.filterByFavorit} />
                        ) : (
                          <MdStarBorder onClick={this.filterByFavorit} />
                        )}
                      </div>
                    </div>
                    <div className="second-header-part recipient-search">
                      <FormGroup>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <SvgIcon
                              size="20"
                              color="#b7b6b6"
                              viewBox="0 0 60 60"
                            >
                              {svgPaths['search']}
                            </SvgIcon>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            minLength={50}
                            maxLength={50}
                            style={{ textAlign: 'left' }}
                            name="search"
                            onChange={this.filterByText}
                            placeholder={
                              this.props.language.locale === 'DE'
                                ? 'Suchen'
                                : 'Search'
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                  {showAgrContatcs && (
                    <div className="custom-users-list">
                      <div
                        className="order-group-list recipientList"
                        onClick={(e) => this.handleOpenClose(e, 'agr')}
                      >
                        <span className="trd">
                          {<FormattedMessage {...messages.Agriculturist} />}
                          {!externalRequest
                            ? ` (${registeredAgr.length})`
                            : ` (${notRegisteredAgr.length})`}
                        </span>
                        <div className="last-itemt">
                          {showAgr ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'agr')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'agr')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {showAgr &&
                        (!externalRequest
                          ? registeredAgr.map((item, index) =>
                              this.renderFav(item, index, 'agr'),
                            )
                          : notRegisteredAgr.map((item, index) =>
                              this.renderFav(item, index, 'agr'),
                            ))}
                    </div>
                  )}
                  {showtrdContatcs && (
                    <div className="custom-users-list">
                      <div
                        className="order-group-list recipientList"
                        onClick={(e) => this.handleOpenClose(e, 'trd')}
                      >
                        <span className="trd">
                          {<FormattedMessage {...messages.Trader} />}
                          {!externalRequest
                            ? ` (${registeredTrd.length})`
                            : ` (${notRegisteredTrd.length})`}
                        </span>
                        <div className="last-itemt">
                          {showTrd ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'trd')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'trd')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {showTrd &&
                        (!externalRequest
                          ? registeredTrd.map((item, index) =>
                              this.renderFav(item, index, 'trd'),
                            )
                          : notRegisteredTrd.map((item, index) =>
                              this.renderFav(item, index, 'trd'),
                            ))}
                    </div>
                  )}

                  {showAbtContatcs && (
                    <div className="custom-users-list">
                      <div
                        className="order-group-list recipientList"
                        onClick={(e) => this.handleOpenClose(e, 'abt')}
                      >
                        <span className="trd">
                          {<FormattedMessage {...messages.Abbaitor} />}
                          {!externalRequest
                            ? ` (${registeredAbt.length})`
                            : ` (${notRegisteredAbt.length})`}
                        </span>
                        <div className="last-itemt">
                          {showAbt ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'abt')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'abt')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>

                      {showAbt &&
                        (!externalRequest
                          ? registeredAbt.map((item, index) =>
                              this.renderFav(item, index, 'abt'),
                            )
                          : notRegisteredAbt.map((item, index) =>
                              this.renderFav(item, index, 'abt'),
                            ))}
                    </div>
                  )}

                  {/* {(!externalRequest
                    ? agriculturists.length > 0
                    : agriculturists.filter(
                        (agriculturist) => agriculturist.is_registered === 0,
                      ).length > 0) && (
                    <div className="custom-users-list">
                      <div className="order-group-list recipientList">
                        <span className="trd">
                          {<FormattedMessage {...messages.Agriculturist} />}
                          {!externalRequest
                            ? ` (${agriculturists.length})`
                            : ` (${
                                agriculturists.filter(
                                  (agriculturist) =>
                                    agriculturist.is_registered === 0,
                                ).length
                              })`}
                        </span>
                        <div className="last-itemt">
                          {showAgr ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'agr')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'agr')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {showAgr &&
                        agriculturists.map((item, index) =>
                          !externalRequest
                            ? this.renderFav(item, index, 'agr')
                            : item.is_registered === 0 &&
                              this.renderFav(item, index, 'agr'),
                        )}
                    </div>
                  )}
                  {(!externalRequest
                    ? traders.length > 0
                    : traders.filter((trader) => trader.is_registered === 0)
                        .length > 0) && (
                    <div className="custom-users-list">
                      <div className="order-group-list recipientList">
                        <span className="trd">
                          {<FormattedMessage {...messages.Trader} />}
                          {!externalRequest
                            ? ` (${traders.length})`
                            : ` (${
                                traders.filter(
                                  (trader) => trader.is_registered === 0,
                                ).length
                              })`}
                        </span>
                        <div className="last-itemt">
                          {showTrd ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'trd')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'trd')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {showTrd &&
                        traders.map((item, index) =>
                          !externalRequest
                            ? this.renderFav(item, index, 'trd')
                            : item.is_registered === 0 &&
                              this.renderFav(item, index, 'trd'),
                        )}
                    </div>
                  )}
                  {(!externalRequest
                    ? abattoirs.length > 0
                    : abattoirs.filter(
                        (abattoir) => abattoir.is_registered === 0,
                      ).length > 0) && (
                    <div className="custom-users-list">
                      <div className="order-group-list recipientList">
                        <span className="trd">
                          {<FormattedMessage {...messages.Abbaitor} />}
                          {!externalRequest
                            ? ` (${abattoirs.length})`
                            : ` (${
                                abattoirs.filter(
                                  (abattoir) => abattoir.is_registered === 0,
                                ).length
                              })`}
                        </span>
                        <div className="last-itemt">
                          {showAbt ? (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'abt')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleUp']}
                              </SvgIcon>
                            </label>
                          ) : (
                            <label
                              onClick={(e) => this.handleOpenClose(e, 'abt')}
                            >
                              <SvgIcon
                                size="20"
                                color="#808080"
                                viewBox="0 0 580 580"
                              >
                                {svgPaths['arrowCircleDown']}
                              </SvgIcon>
                            </label>
                          )}
                        </div>
                      </div>
                      {showAbt &&
                        abattoirs.map((item, index) =>
                          !externalRequest
                            ? this.renderFav(item, index, 'abt')
                            : item.is_registered === 0 &&
                              this.renderFav(item, index, 'abt'),
                        )}
                    </div>
                  )} */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="button-group buy-sell resale-bottom-part confirm">
          <NormalButton
            disabled={
              !(deliveryDate !== '' && piece !== '' && receivers.length > 0)
            }
            onClick={() => {
              this.resaleStock();
            }}
          >
            <FormattedMessage {...messages.ConfirmButton} />
          </NormalButton>
        </div>
      </div>
    );
  };

  renderListStocks = () => (
    <ViewStock sellOffer={true} showSellForm={this.showSellForm} />
  );

  render() {
    const { orderType } = this.props;
    const { showStock } = this.state;
    return (
      <React.Fragment>
        {(orderType === 'buyings' ||
          (orderType === 'sellings' && !showStock)) &&
          this.renderFormRequest(orderType)}
        {orderType === 'sellings' && showStock && this.renderListStocks()}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
    handleTitleChange: (panelTitle) =>
      dispatch(adminActions.handleTitleChange(panelTitle)),
  };
}

const mapStateToProps = (state) => {
  const { users, language } = state.toJS();
  return {
    users,
    language,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReSellRequest),
);
