/* eslint-disable array-callback-return */
/* eslint-disable no-fallthrough */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Image, ButtonGroup, Modal } from 'react-bootstrap';
import FileSaver from 'file-saver';
import clsx from 'clsx';
import markergDrapo from '../../../../static/images/admin/icons_final/2.png';
import greenTarget from '../../../../static/images/admin/icons_final/1.png';
import grayLoc from '../../../../static/images/admin/icons_final/marker_gray.png';
import greenLoc from '../../../../static/images/admin/icons_final/marker.png';
import yellowTarget from '../../../../static/images/admin/icons_final/yellow.png';
import arrowImg from '../../../../static/images/admin/arrow-copie-2.png';
import searchImg from '../../../../static/images/admin/search.png';
import searchImgNotFound from '../../../../static/images/admin/searchImgNotFound.png';

import { alertActions } from '../../../../actions';
import { OrderService } from '../../../../services/order.service';
import { getcontactByNumber } from '../../../../services/company.service';
import { getPassportStock } from '../../../../services/stock.service';
import extractData from '../../../../services/data.processing';
import messages from '../../messages';

import LocationItem from './LocationItem';

import truckToBeLoaded from '../../../../static/images/admin/Icons/Status icons/Tobeloaded.png';
import truckLoaded from '../../../../static/images/admin/Icons/Status icons/Loaded.png';
import truckOnTheWay from '../../../../static/images/admin/Icons/Status icons/Ontheway.png';
import truckDelivered from '../../../../static/images/admin/Icons/Status icons/Delivered.png';
import truckSlaughered from '../../../../static/images/admin/Icons/Status icons/Slaughered.png';
import truckUnloaded from '../../../../static/images/admin/Icons/Status icons/Unloaded.png';
import Tourplanning from '../../../../static/images/admin/Icons/Status icons/Tourplanning.png';

import './tour.css';

class TourInfoDirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showTourInfo: true,
      showDocInfo: false,
      showDoc: false,
      showLocForm: false,
      trucks: [],
      orderId: this.props.orderDetail.order_id,
      data: {},
      isValid: false,
      ear_tags: [],
      vehicleNumber: null,
      driver: '',
      showForm: false,
      showTruckList: false,
      formData: {},
      truck: {},
      truckDetail: {},
      loadingTime: '',
      departureTime: '',
      showDetail: false,
      togglePopupPassword: false,
      passport: '',
      id: '',
      idDoc: '',
      srcTarget: '',
      editLocForm: true,
      editTargetForm: true,
      truckIcon: '',
      unLoadingTime: '',
      deliveryTime: '',
      showTargetForm: false,
      showConfirm: false,
      enableDeliveryTime: false,
      enableDepartureTime: false,
      enableUnloadingTimeAndNotes: false,
      note: '',
      unloadToast: '',
      deliverToast: '',
      departureTimeEdit: true,
      deliveryTimeEdit: true,
      loadingTimeAndNotes: true,
      buttonText: '',
      DepartureId: '',
      displaySlaugher: false,
      pdfEncoded: '',
      showPopup: false,
      showPopupSlaughter: false,
      has_slaughter_informations: 0,

    };
  }

  ColorIcon = () => {
    if (typeof this.state.truck !== 'undefined') {
      if (
        this.state.truck.status === 'LOADED' ||
        this.state.truck.status === 'TO_BE_LOADED' ||
        this.state.truck.status === 'TOUR_ASSIGNED'
      )
        return 'tourInfo-title-span-texttwo';
      if (this.state.truck.status === 'ON_THE_WAY')
        return 'tourInfo-title-span-textthree';
      else {
        return 'tourInfo-title-span-text';
      }
    }
  };

  ColorTxt = () => {
    if (typeof this.state.truck !== 'undefined') {
      if (
        this.state.truck.status === 'LOADED' ||
        this.state.truck.status === 'TO_BE_LOADED' ||
        this.state.truck.status === 'TOUR_ASSIGNED'
      )
        return 'tourInfo-title-span-texttwo';
      else {
        return 'tourInfo-title-span-text';
      }
    }
  };

  handleClose = () => {
    this.setState({ showPopup: false });
  };

  openPopUp = () => {
    this.setState({ showPopup: true });
  };

  handleCloseSlautherPdf = () => {
    this.setState({ showPopupSlaughter: false });
  };

  openPopUpSlautherPdf = () => {
    this.setState({ showPopupSlaughter: true });
  };

  getEncodedPdf = async () => {
    let img = await OrderService.getPDFTour(
      this.props.users.accessToken,
      this.props.orderDetail.order_id,
      this.props.truckDetails._id,
    );
    this.setState({ pdfId: 'data:application/pdf;base64,' + img });
  };

  getEncodedPdfSlaugther = async () => {
    let img = await OrderService.getPDFSlaugther(
      this.props.users.accessToken,
      this.props.orderDetail.order_id,
      this.state.truckDetail.truck._id,
    );
    this.setState({ pdfSlau: 'data:application/pdf;base64,' + img });
  };

  componentDidMount() {
    this.getTours(this.props.orderDetail.order_id);
    this.getTourInfo(
      this.props.orderDetail.order_id,
      this.props.truckDetails._id,
    );

    this.props.showTitle();
  }

  getContactsById = async () => {
    let token = this.props.users.accessToken;
    if (this.props.users.user.session.company_type !== '3' &&  this.props.users.user.session.company_type !== '2') {
      const value = await getcontactByNumber(
        this.state.truckDetail.buyer_company,
        this.state.truckDetail.seller_number,
        token,
      );
      if (
        value !== 'null' &&
        typeof value !== 'undefined' &&
        typeof value.status !== 'undefined' &&
        value.status === 200
      ) {
        const contacts = await extractData(value);
        this.setState({
          has_slaughter_informations: contacts.has_slaughter_informations,
        });
      }
    } else {
      this.setState({
        has_slaughter_informations: 1,
      });
    }
  };

  GetKey = (id) => {
    this.setState({ DepartureId: id });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.orderDetail.order_id !== this.props.orderDetail.order_id) {
      this.setState({
        orderId: nextProps.orderDetail.order_id,
        show: true,
        departureTime: '',
        loadingTime: '',
        note: '',
        unloadToast: '',
        deliverToast: '',
        showTruckList: false,
        showLocForm: false,
        showTargetForm: false,
        showConfirm: false,
      });
      this.getTours(nextProps.orderDetail.order_id);
      this.getTourInfo(
        nextProps.orderDetail.order_id,
        nextProps.truckDetails._id,
      );
    }
  }

  togglePopupPassword = () => {
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
  };

  showMoreOption = () => {
    this.setState({
      showDoc: !this.state.showDoc,
    });
  };

  showLocForm = () => {
    this.setState({
      showLocForm: !this.state.showLocForm,
    });
  };

  getTours = async (orderId) => {
    const token = this.props.users.accessToken;
    await OrderService.getTours(token, orderId, "", this.props.users);
  };

  getTourInfo = async (orderId, truckId) => {
    const token = this.props.users.accessToken;
    const value = await OrderService.getTourInfo(token, orderId, truckId);
    if (value.status === 200) {
      const data = await extractData(value);
      this.handleTargetLocation(data.truck.status);
      this.handleEditLocForm(data);
      this.handleEditDepartureTime(data);
      this.handleEditDeliveryTime(data);
      this.handleEditLoadingTimeAndNotes(data);
      this.handleConfirmBtn(data);
      this.setState({
        truckDetail: data,
        truck: data.truck,
        locations: data.truck.locations,
      });
    }
    await    this.getContactsById();
  };

  showMoreOptions = (idDoc) => {
    idDoc !== this.state.idDoc && this.state.showDoc === true
      ? this.setState({
          idDoc,
        })
      : this.setState({
          showDoc: !this.state.showDoc,
          idDoc,
        });
  };

  showLocForms = (id) => {
    id !== this.state.id && this.state.showLocForm === true
      ? this.setState({
          id,
        })
      : this.setState({
          showLocForm: !this.state.showLocForm,
          id,
        });
    this.state.showTargetForm && this.setState({ showTargetForm: false });
  };

  showTargetLocForm = () => {
    this.setState({ showTargetForm: !this.state.showTargetForm });
    this.state.showLocForm && this.setState({ showLocForm: false });
  };

  showTruckDetail = (truck) => {
    this.getTourInfo(this.state.orderId, truck._id);
    this.setState({ showTruckList: false, show: true });
  };

  handleTargetLocation = (status) => {
    if (status === 'ON_THE_WAY') {
      this.setState({ srcTarget: yellowTarget });
    } else if (['LOADED', 'TO_BE_LOADED', 'TOUR_ASSIGNED'].includes(status)) {
      this.setState({ srcTarget: markergDrapo });
    } else {
      this.setState({ srcTarget: greenTarget });
    }
  };

  handleEditLocForm = (truckDetail) => {
    truckDetail.loading_time === '' &&
      truckDetail.status === 'TO_BE_LOADED' &&
      truckDetail.is_tour_planning_responsible === 1 &&
      this.setState({ editLocForm: false });
    truckDetail.status === 'TO_BE_LOADED' &&
      truckDetail.is_tour_planning_responsible === 1 &&
      this.setState({ editTargetForm: false });
  };

  handleEditDepartureTime = (truckDetail) => {
    if (
      truckDetail.status === 'TO_BE_LOADED' ||
      truckDetail.status === 'LOADED'
    ) {
      if (
        typeof truckDetail.truck.locations[0] !== 'undefined' &&
        typeof truckDetail.truck.locations[0].loading_time !== 'undefined' &&
        truckDetail.truck.locations[0].loading_time !== '' &&
        truckDetail.truck.is_transporter === 1 &&
        this.props.users.user.role !== 'ROLE_VET_EMPLOYEE' &&
        typeof depart === 'undefined' &&
        ['LOADED', 'TO_BE_LOADED'].includes(truckDetail.truck.status)
      )
        this.setState({ departureTimeEdit: false });
      else this.setState({ departureTimeEdit: true });
    }
  };

  handleEditDeliveryTime = (truckDetail) => {
    if (
      truckDetail.truck.is_transporter === 1 &&
      truckDetail.truck.status === 'ON_THE_WAY'
    )
      this.setState({ deliveryTimeEdit: false });
    else this.setState({ deliveryTimeEdit: true });
  };

  handleEditLoadingTimeAndNotes = (truckDetail) => {
    let enableUnloadingTimeAndNotes = false;
    if (truckDetail.truck.status === 'DELIVERED') {
      switch (this.props.users.user.session.company_type) {
        case '5':
          enableUnloadingTimeAndNotes =
            this.props.users.user.role !== 'ROLE_VET_EMPLOYEE';
        case '3':
          enableUnloadingTimeAndNotes = true;
        default:
          enableUnloadingTimeAndNotes = truckDetail.truck.is_buyer === 1;
      }
    }
    this.setState({ loadingTimeAndNotes: !enableUnloadingTimeAndNotes });
  };

  handleTargetLocation = (status) => {
    if (status === 'ON_THE_WAY') {
      this.setState({ srcTarget: yellowTarget });
    } else if (['LOADED', 'TO_BE_LOADED', 'TOUR_ASSIGNED'].includes(status)) {
      this.setState({ srcTarget: markergDrapo });
    } else {
      this.setState({ srcTarget: greenTarget });
    }
  };

  handleCloseToBeload = () => {
    const { show, showDetail, trucks } = this.state;
    const { showPassportList, showPassportForTourInfo } = this.props;
    // this.props.handleClosePanel();
    if (show) {
      if (trucks.length > 1) {
        this.setState({ showTruckList: true });
        this.setState({
          show: false,
        });
      } else if (showPassportList) {
        this.setState({ show: true });
        showPassportForTourInfo(false);
      } else {
        this.setState({
          show: false,
        });
      }
    } else if (showDetail) {
      this.setState({ showDetail: false, show: true });
    } else {
      this.props.handleClosePanel();
    }
  };
  //btn
  enableBtnDepTime = (truckDetail) => {
    let btnEnable = false;
    truckDetail.truck.locations.map((item) => {
      if (
        item.loading_time !== undefined &&
        item.loading_time !== '' &&
        item.departure_time === ''
      ) {
        btnEnable = true;
        return btnEnable;
      }
    });
    return btnEnable;
  };

  enableBtn = (truckDetail) => {
    if (
      this.enableBtnDepTime(truckDetail) &&
      truckDetail.truck.is_transporter === 1 &&
      this.props.users.user.role !== 'ROLE_VET_EMPLOYEE' &&
      typeof depart === 'undefined' &&
      ['LOADED', 'TO_BE_LOADED'].includes(truckDetail.truck.status)
    ) {
      return true;
    } else return false;
  };

  //text
  enableDepTimetext = (item) => {
    let btnEnable = false;
    if (item.loading_time !== undefined && item.loading_time !== '') {
      btnEnable = true;
    }

    return btnEnable;
  };

  enableDepTime = (item) => {
    if (
      this.enableDepTimetext(item) &&
      this.state.buttonText === 'Confirm departure time'
    ) {
      return false;
    } else return true;
  };

  handleConfirmBtn = (truckDetail) => {
    let enableDeliveryTime =
      truckDetail.truck.is_transporter === 1 &&
      truckDetail.truck.status === 'ON_THE_WAY';

    let enableDepartureTime = this.enableBtn(truckDetail);

    let enableUnloadingTimeAndNotes = false;
    if (truckDetail.truck.status === 'DELIVERED') {
      switch (this.props.users.user.session.company_type) {
        case '5':
          enableUnloadingTimeAndNotes =
            this.props.users.user.role !== 'ROLE_VET_EMPLOYEE';
        case '3':
          enableUnloadingTimeAndNotes = true;
        default:
          enableUnloadingTimeAndNotes = truckDetail.truck.is_buyer === 1;
      }
    }

    let buttonCompleteVisibility =
      enableDeliveryTime || enableDepartureTime || enableUnloadingTimeAndNotes;
    this.setState({ showConfirm: buttonCompleteVisibility });
    if (enableDepartureTime) {
      this.setState({ buttonText: 'Confirm departure time' });
    }
    if (enableDeliveryTime) {
      this.setState({ buttonText: 'Confirm delivery time' });
    }
    if (enableUnloadingTimeAndNotes) {
      this.setState({ buttonText: 'Confirm unloading time' });
    }
  };

  changeState = () => {
    this.setState({
      showTourInfo: !this.state.showTourInfo,
      showDocInfo: !this.state.showDocInfo,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    // eslint-disable-next-line default-case
    switch (name) {
      case 'loadingTime':
        this.setState({ loadingTime: value });
        break;
      case 'departureTime':
        this.setState({ departureTime: value });
        break;
      case 'deliveryTime':
        this.setState({ deliveryTime: value });
        break;
      case 'unLoadingTime':
        this.setState({ unLoadingTime: value });
        break;
      case 'notes':
        this.setState({ notes: value });
        break;
    }
  };

  showOrderDetail = () => {
    const { truck, truckDetail } = this.state;
    this.props.showDetailForTour(truck, truckDetail);
    this.setState({ showDetail: true, show: false });
  };

  closeSlaugher = () => {
    this.setState({
      show: true,
      displaySlaugher: false,
      showDocInfo: true,
    });
  };

  pdfRename = (r) => {
    const res = r.split('+');
    return res[1];
  };

  downloadPDF = async (fileId) => {
    let img = await OrderService.getPDFLoad(
      this.props.users.accessToken,
      fileId,
    );
    FileSaver.saveAs(
      'data:application/pdf;base64,' + img,
      `${this.pdfRename(fileId)}.pdf`,
    );
  };

  displayPDF = async (fileId) => {
    let img = await OrderService.getPDFLoad(
      this.props.users.accessToken,
      fileId,
    );

    this.setState({ pdfEncoded: 'data:application/pdf;base64,' + img });
  };

  handlePassports = () => {
    const { showPassportForTourInfo } = this.props;
    showPassportForTourInfo(true);
  };

  renderPassports = (truckDetail) => {
    let passportList = [];
    truckDetail.truck.locations.map((passport) => {
      passportList.push({
        _id: passport._id,
        passport: passport.passport,
        pick_up_time: passport.pick_up_time,
        seller: passport.seller,
        loading_time: passport.loading_time,
        address: passport.address,
        ear_tags: passport.ear_tags,
      });
    });

    return (
      <div className="passports-container passport-handler">
        {passportList.map((item) => (
          <div className="passports-by-seller">
            <div className="pass">
              <div className="pass-inner-image">
                <Image
                  className="status-image-icon-marker"
                  src={item.departure_time ? greenLoc : grayLoc}
                />
              </div>
              <div className="pass-inner-content">
                <div className={item.departure_time ? 'green' : 'gray'}>
                  {item.pick_up_time} {item.seller}
                </div>
                <div>{`${item.address.street}, ${item.address.zip_code} ${item.address.city}`}</div>
              </div>
            </div>
            <div className="passport-list">
              {item.ear_tags.map((pass) => (
                <div className="passport">
                  <div className="eartag">
                    <div className="">{pass.ear_tag_output_format}</div>
                  </div>
                  <div>
                    <Image
                      className=""
                      src={
                        (typeof pass.passport !== 'undefined' &&
                          pass.passport.length > 0) ||
                        typeof pass.passport == 'undefined'
                          ? searchImg
                          : searchImgNotFound
                      }
                      onClick={() => {
                        ((typeof pass.passport !== 'undefined' &&
                          pass.passport.length > 0) ||
                          typeof pass.passport == 'undefined') &&
                          this.getPassport(pass);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  changeDe = (deliveries) => {
    this.setState({ departureTime: deliveries });
  };

  getPassport = async (eartag) => {
    this.setState({ togglePopupPassword: !this.state.togglePopupPassword });
    const token = this.props.users.accessToken;
    let passport = await getPassportStock(eartag._id, token, eartag.passport);
    this.setState({
      ear_tag: eartag.ear_tag,
      ear_tag_output_format: eartag.ear_tag_output_format,
    });
    if (passport) {
      this.setState({ passport: 'data:image/png;base64,' + passport });
      this.setState({
        file: eartag.passport === '' ? null : eartag.passport,
      });
    }
  };

  //logic of confirm
  handleSubmit = async () => {
    const { deliveryTime, departureTime, unLoadingTime, truckDetail } =
      this.state;
    const { accessToken } = this.props.users;

    if (this.state.buttonText === 'Confirm departure time') {
      if (this.state.departureTime === '') {
        this.setState({ departureToast: 'Please enter departure time' });
      } else {
        const value = await OrderService.editTour(
          accessToken,
          this.state.orderId,
          truckDetail.truck._id,
          [
            {
              _id: this.state.DepartureId,
              departure_time: departureTime,
            },
          ],
        );
        if (value.status === 200) {
          this.props.handleClosePanel();
          this.props.success(<FormattedMessage {...messages.DepTimeUpdated} />);
          this.props.clear();
          this.props.getOrders();
        } else {
          this.props.error(
            <FormattedMessage {...messages.DepTimeNotUpdated} />,
          );
          this.props.clear();
        }
      }
    }
    if (this.state.buttonText === 'Confirm delivery time') {
      if (deliveryTime === '' || typeof deliveryTime === 'undefined') {
        this.setState({ deliverToast: 'Please enter delivery time' });
      } else {
        const value = await OrderService.editTourDelivery(
          accessToken,
          this.state.orderId,
          truckDetail.truck._id,
          deliveryTime,
        );
        if (value.status === 200) {
          this.props.handleClosePanel();
          this.props.success(
            <FormattedMessage {...messages.DeliveryTimeUpdated} />,
          );
          this.props.clear();
          this.props.getOrders();
        } else {
          this.props.error(
            <FormattedMessage {...messages.DeliveryTimeNotUpdated} />,
          );
          this.props.clear();
        }
      }
    }
    if (this.state.buttonText === 'Confirm unloading time') {
      if (unLoadingTime === '') {
        this.setState({ unloadToast: 'Please enter unloading time' });
      } else {
        const value = await OrderService.editTourDeliveryAndNotes(
          accessToken,
          this.state.orderId,
          truckDetail.truck._id,
          unLoadingTime,
          this.state.notes,
        );
        if (value.status === 200) {
          this.props.handleClosePanel();
          this.props.success(
            <FormattedMessage {...messages.UnloadingTimeUpdated} />,
          );
          this.props.clear();
          this.props.getOrders();
        } else {
          this.props.error(
            <FormattedMessage {...messages.UnloadingTimeNotUpdated} />,
          );
          this.props.clear();
        }
      }
    } else {
      this.props.clear();
    }
  };

  renderTargetLocForm = () => {
    return (
      <div className="tour-info-form-container">
        <div className="tourInfo-title-pf">
          <div className="tourInfo-title-b1-form"></div>
          <div className="tourInfo-title-btn-form">
            <div className="tourInfo-title-btn-form-row">
              <div
                className={clsx(
                  'dep-arr-text',
                  !this.state.deliveryTimeEdit && 'tourInfo-edit',
                )}
              >
                {' '}
                <FormattedMessage {...messages.DeliveryTime} />
              </div>
              <div className="dep-arr-time-input">
                <input
                  id="deliveryTime"
                  className="form-input"
                  name="deliveryTime"
                  type="time"
                  onChange={this.handleChange}
                  value={
                    this.state.truck.delivery_time
                      ? this.state.truck.delivery_time
                      : this.state.deliveryTime
                  }
                  disabled={this.state.deliveryTimeEdit}
                />
              </div>
            </div>
            <input
              style={{ color: 'red' }}
              value={this.state.deliverToast}
              disabled
            />
          </div>
        </div>
        <div className="tourInfo-title-pf">
          <div className="tourInfo-title-b1-form"></div>
          <div className="tourInfo-title-btn-form">
            <div className="tourInfo-title-btn-form-row">
              <div
                className={clsx(
                  'dep-arr-text',
                  !this.state.loadingTimeAndNotes && 'tourInfo-edit',
                )}
              >
                <FormattedMessage {...messages.Unloading} />
              </div>
              <div className="dep-arr-time-input">
                <input
                  className="form-input"
                  id="unLoadingTime"
                  name="unLoadingTime"
                  type="time"
                  onChange={this.handleChange}
                  value={
                    this.state.truck.unloading_time
                      ? this.state.truck.unloading_time
                      : this.state.unLoadingTime
                  }
                  disabled={this.state.loadingTimeAndNotes}
                />
              </div>
            </div>
            <input
              style={{ color: 'red' }}
              value={this.state.unloadToast}
              disabled
            />
          </div>
        </div>
        <div className="tourInfo-title-pf">
          <div className="tourInfo-title-b1-form"></div>
          <div className="tourInfo-title-btn-form">
            <textarea
              name="notes"
              className="form-input"
              placeholder="Additional notes..."
              style={{ border: '1px solid #bbbbbb' }}
              cols={18}
              rows={4}
              value={
                this.state.truck.notes
                  ? this.state.truck.notes
                  : this.state.notes
              }
              onChange={this.handleChange}
              disabled={this.state.loadingTimeAndNotes}
            >
              {this.state.note}
            </textarea>
          </div>
        </div>
        <input value={this.state.t1} disabled />
      </div>
    );
  };

  renderLocations = () => {
    const { locations, idDoc, id, showDoc, showLocForm, seller } = this.state;
    return (
      typeof locations !== 'undefined' &&
      locations.map((item, index) => (
        <div key={item.order_id} className="locations-container">
          <hr className="style-border" />
          <LocationItem
            key={index}
            idDoc={idDoc}
            id={id}
            showDoc={showDoc}
            showLocForm={showLocForm}
            showLocForms={this.showLocForms}
            showMoreOptions={this.showMoreOptions}
            item={item}
            seller={seller}
            loc={'info'}
            renderDocForm={this.renderDocForm}
            renderLocForm={this.renderLocForm}
            handleChange={this.handleChange}
            loadingTime={this.state.loadingTime}
            departureTime={this.state.departureTime}
            edit={this.enableDepTime(item)}
            changeDe={this.changeDe}
            GetKey={this.GetKey}
          />
        </div>
      ))
    );
  };

  getIcon = () => {
    if (typeof this.state.truck !== 'undefined') {
      if (this.state.truck.status === 'LOADED') return truckLoaded;
      if (this.state.truck.status === 'ON_THE_WAY') return truckOnTheWay;
      if (this.state.truck.status === 'DELIVERED') return truckDelivered;
      if (this.state.truck.status === 'UNLOADED') return truckUnloaded;
      if (this.state.truck.status === 'SLAUGHTERED') return truckSlaughered;
      if (this.state.truck.status === 'SLAUGHTERED') return truckSlaughered;
      if (this.state.truck.status === 'TOUR_ASSIGNED') return Tourplanning;
      else return truckToBeLoaded;
    }
    return truckToBeLoaded;
  };

  displaySlaughterDetail = () => {
    this.setState({
      displaySlaugher: true,
      showDocInfo: false,
      showDoc: true,
    });
  };

  renderInfoList = (truckDetail) => {
    return (
      <div>
        <div className="tourInfo-title t-info">
          <div className="tourInfo-title-b1">
            <Image className="status-image-iconTour" src={this.getIcon()} />
          </div>
          <div className="tourInfo-title-b2">
            <div className="tourInfo-position-time">
              <div className={this.ColorTxt()}>
                {this.state.truck.departure_time}
              </div>
              <div className="tourInfo-title-span-text-points">.......</div>
              <div className={this.ColorIcon()}>
                {this.state.truck.delivery_time
                  ? this.state.truck.delivery_time
                  : this.state.truck.delivery_until}
              </div>
            </div>
            <div className="block-time">
              <div className="text-Hr">
                {this.state.truck.vehicle_number} | {this.state.truck.driver}
              </div>
            </div>
          </div>
        </div>
        {/* */}
        {this.renderLocations()}

        <div
          className="tourInfo-title t-info-title"
          onClick={() => this.showTargetLocForm()}
        >
          <div className="tourInfo-title-b1">
            <Image
              className="status-image-icon-marker"
              src={this.state.srcTarget}
            />
          </div>
          <div className="tourInfo-title-btn tour-info-list-cattle">
            <div className={this.ColorIcon()}>
              {this.state.truckDetail.delivery_until}{' '}
              {this.state.truckDetail.buyer}
            </div>
            <div>{this.state.truckDetail.buyer_place}</div>
          </div>
          <div>
            <Image
              className=""
              src={arrowImg}
              onClick={() => this.showTargetLocForm()}
            />
          </div>
        </div>
        {this.state.showTargetForm && this.renderTargetLocForm()}
      </div>
    );
  };

  renderDocuments = () => {
    const { locations, idDoc, id, showDoc, showLocForm, seller, truckDetail } =
      this.state;
    return (
      <div className="doc-inner">
        <Modal
          show={this.state.showPopup === true}
          onHide={this.handleClose}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdf ">
            <embed
              width="100%"
              height="90%"
              frameBorder="0"
              src={this.state.pdfId}
              type="application/pdf"
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showPopupSlaughter === true}
          onHide={this.handleCloseSlautherPdf}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modal-pdf ">
            <embed
              width="100%"
              height="90%"
              frameBorder="0"
              src={this.state.pdfSlau}
              type="application/pdf"
            />
          </Modal.Body>
        </Modal>
        <div className="t-info-pass-sl first">
          <div className="tourInfo-view-cattle">
            <div
              className="text"
              onClick={() => {
                this.openPopUp();
                this.getEncodedPdf();
              }}
            >
              <FormattedMessage {...messages.HomeManageViehanmeldung} />:{' '}
              {truckDetail.delivery_date}
            </div>
            <div className="view-cattle">
              <Image
                className=""
                src={searchImg}
                onClick={() => {
                  this.openPopUp();
                  this.getEncodedPdf();
                }}
              />
            </div>
          </div>
        </div>
        {(this.props.users.user.session.company_type === '2' ||
          this.props.users.user.session.company_type === '3' ||
          this.props.users.user.session.company_type === '4') && (
          <div className="t-info-pass-sl first">
            <div className="tourInfo-view-cattle">
              <div
                className="text"
                onClick={() => {
                  this.handlePassports(truckDetail);
                }}
              >
                <FormattedMessage {...messages.Passport} />
              </div>
              <div className="view-cattle">
                <Image
                  className=""
                  src={searchImg}
                  onClick={() => {
                    this.handlePassports(truckDetail);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {/* {truckDetail.status === 'SLAUGHTERED' &&
          this.state.has_slaughter_informations === 1 && (
            <div className="t-info-pass-sl second">
              <div className="tourInfo-view-cattle">
                <div className="text">
                  <FormattedMessage {...messages.Slaughtered} />
                </div>
                <div className="view-cattle">
                  <Image
                    className=""
                    src={searchImg}
                    onClick={this.displaySlaughterDetail}
                  />
                </div>
              </div>
            </div>
          )} */}
                  {truckDetail.status === 'SLAUGHTERED' &&
          this.state.has_slaughter_informations === 1 && (
            <div
              onClick={() => {
                this.openPopUpSlautherPdf();
                this.getEncodedPdfSlaugther();
              }}
              className="t-info-pass-sl second"
            >
              <div className="tourInfo-view-cattle">
                <div className="text">
                  <FormattedMessage {...messages.Slaughterreport} />
                </div>
                <div className="view-cattle">
                  <Image
                    className=""
                    src={searchImg}
                    // onClick={this.displaySlaughterDetail}
                  />
                </div>
              </div>
            </div>
          )}
        {locations.map((item, index) => (
          <div key={item.order_id}>
            <hr className="style-border" />
            <LocationItem
              idDoc={idDoc}
              id={id}
              showDoc={showDoc}
              showLocForm={showLocForm}
              showLocForms={this.showLocForms}
              showMoreOptions={this.showMoreOptions}
              item={item}
              seller={seller}
              loc={''}
              renderDocForm={this.renderDocForm}
              renderLocForm={this.renderLocForm}
              downloadPDF={this.downloadPDF}
              displayPDF={this.displayPDF}
              pdfEncoded={this.state.pdfEncoded}
            />
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { truckDetail } = this.state;

    return (
      <div className="tour-info-block t-info-sub">
        <ButtonGroup
          value={this.state.type}
          onClick={this.handleChange}
          className="tour-btn-groupe"
        >
          <div className="button-tour-groupe-wrapper">
            <button
              className={
                this.state.showTourInfo
                  ? 'Tour-Doc-class'
                  : 'Tour-Information-class'
              }
              value={'open'}
              onClick={this.changeState}
            >
              <FormattedMessage {...messages.TourInformation} />
            </button>
            <button
              className={
                this.state.showDocInfo
                  ? 'Tour-Doc-class'
                  : 'Tour-Doc-class-active'
              }
              value={'deliveries'}
              onClick={this.changeState}
            >
              <FormattedMessage {...messages.Documents} />
            </button>
          </div>
        </ButtonGroup>

        <div className="tour-info-div cattle">
          {this.state.showTourInfo && this.renderInfoList(truckDetail)}
          {this.state.showDocInfo && this.renderDocuments(truckDetail)}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    success: (msg) => dispatch(alertActions.success(msg)),
    error: (error) => dispatch(alertActions.error(error)),
    clear: () => dispatch(alertActions.clear()),
  };
}

const mapStateToProps = (state) => {
  const { users } = state.toJS();
  return {
    users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TourInfoDirect);
